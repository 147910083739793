import { IReviewValidationState } from "amm-review-interface";
import { checkAffordabilityError, checkApplicantErrors } from "./reviewUtility";
import { TAppStateType } from "../store/reducers/reducers";
import { checkEmploymentFieldHandler } from "../store/thunk/utility";

export const checkIfAllValidationIsCorrect: (
  page: string,
  state: TAppStateType
) => IReviewValidationState = (page: string, state) => {
  const validations: IReviewValidationState = {
    affordability: false,
    applicant: false,
    objective: false,
    solution: false,
    fundsToCompleteSum: 0,
  };

  if (page === "objective") {
    for (const property of state.objective.properties) {
      if (
        (property.purchaseOrRefinance === "Refinance" ||
          property.purchaseType === "I've found my property") &&
        property.propertyValue > 0 &&
        property.estimatedValue > 0
      ) {
        validations.objective = true;
      } else if (property.purchaseType === "I'm still looking" && property.propertyValue > 0) {
        validations.objective = true;
      } else {
        validations.objective = false;
      }
    }
  } else if (page === "solution") {
    const loanAmountCheck = state.loan.loanAmount === state.loan.accountTotalAmount;
    const accountNameValidCheck = !state.loan.account.find((account) => {
      return account.isInValidAccountName;
    });
    const isBalanceZero = state.loan.account.findIndex((acc) => acc.amount === 0) >= 0;
    // const cashOutNoteCheck =
    //   state.loan.doYouRequireCashOut === "No" ||
    //   (state.loan.doYouRequireCashOut === "Yes" && Boolean(state.loan.cashOutNote));
    validations.solution = loanAmountCheck && accountNameValidCheck && !isBalanceZero;
  } else if (page === "applicant") {
    const applicantDocumentsArray: boolean[] = new Array(state.applicant.applicants.length);
    const applicantEmploymentsArray: boolean[] = new Array(state.applicant.applicants.length);
    const applicantsErrorArray = checkApplicantErrors(
      state.applicant.applicantsRequiredField,
      state.applicant.applicants
    );
    const applicants = state.applicant.applicants;

    for (let i = 0; i < applicants.length; i++) {
      const privacyConsent = applicants[i].privacyConsent;
      for (let index = 0; index < applicants[i].employment.length; index++) {
        if (checkEmploymentFieldHandler(applicants[i].employment[index])) {
          applicantDocumentsArray[i] = true;
        } else {
          applicantDocumentsArray[i] = false;
        }
      }
      if (
        applicants[i].applicantDocuments.length >= 2 &&
        privacyConsent.creditScore !== 0 &&
        privacyConsent.formSubmit &&
        privacyConsent.idsMatrix
      ) {
        applicantDocumentsArray[i] = false;
      } else if (!privacyConsent.formSubmit) {
        applicantDocumentsArray[i] = true;
      } else {
        applicantDocumentsArray[i] = true;
      }
    }
    if (
      !applicantsErrorArray.includes(true) &&
      !applicantDocumentsArray.includes(true) &&
      !applicantEmploymentsArray.includes(true)
    ) {
      validations.applicant = true;
    } else {
      validations.applicant = false;
    }
  } else if (page === "affordability") {
    const assetsFundsToComplete = new Array();
    state.applicant.applicants.forEach((applicant) => {
      if (applicant.isApplicantGuarantor === "No") {
        assetsFundsToComplete.push(
          ...applicant.assets.filter((asset) => asset.myContribution && !asset.isDeleted)
        );
      }
    });
    let areArraysNotEmpty = false;
    for (const asset of assetsFundsToComplete) {
      if (asset.length !== 0) {
        areArraysNotEmpty = true;
        break;
      }
    }
    if (areArraysNotEmpty) {
      const shareProductValue = assetsFundsToComplete.map(
        (asset) => (asset.share * asset.value) / 100
      );
      const sumOfShareProductValue = shareProductValue.reduce((a, b) => a + b, 0);
      validations.fundsToCompleteSum = sumOfShareProductValue;
      if (sumOfShareProductValue >= state.review.fundsToCompleteTotal.totalFee) {
        validations.affordability = true;
      } else {
        validations.affordability = false;
      }
    } else {
      validations.fundsToCompleteSum = 0;
      validations.affordability = true;
    }

    if (
      !Object.values(state.applicant.primaryAffordabilityRequiredFields.required).includes(true) &&
      Object.values(state.applicant.primaryAffordabilityRequiredFields.count).some(
        (count) => count > 0
      )
    ) {
      validations.affordability = false;
    }
    if (
      Object.values(state.applicant.primaryAffordabilityRequiredFields.required).includes(true) &&
      !state.review.isVisited
    ) {
      validations.affordability = false;
    }

    if (
      checkAffordabilityError(
        state.applicant.applicants,
        state.applicant.primaryAffordabilityRequiredFields,
        state.review.isVisited
      )
    ) {
      validations.affordability = false;
    }
  }

  return validations;
};
