import { ILRAS } from "amm-objective-interface";
import React from "react";
import { connect } from "react-redux";
import Header from "../components/common/header";
import { ReviewNextAndBackButtonSection } from "../components/common/nextAndBackButtonSection";
import PropertyAccountSummary from "../components/review/propertyAccountSummary";
import Repayments from "../components/review/repayments";
import { LoanAmountSectionReview } from "../components/solution/loanAmountSection";
import { helpFundsToCompleteAction } from "../store/actions/helpActions/helpAction";
import { HelpFundsToCompleteActionType } from "../store/actions/helpActions/helpActionType";
import Footer from "../components/footer/footer";
import Styled from "styled-components";
import { TAppStateType } from "../store/reducers/reducers";
import ReviewNotes from '../components/common/reviewNotes';
import { ReviewNotesScreens } from "../constants"

interface StateProps {
  accountTotalAmount: number;
  formInvalidArray: boolean[];
  loanAmount: number;
  lras: ILRAS;
  suburbCheckedIndicator: boolean;
  showReviewScreen: boolean;
}
interface DispatchProps {
  helpFundsToCompleteAction: HelpFundsToCompleteActionType;
}
type ReviewProps = StateProps & DispatchProps;

const Review: React.FC<ReviewProps> = (props: ReviewProps) => {
  const reviewRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    reviewRef.current!.scrollTo(0, 0);
  }, [props.showReviewScreen]);

  return (
    <>
      <div className="review-page" ref={reviewRef}>
        <Header />
        <h3 className="page-title">Review</h3>
        <LoanAmountSectionReview
          className={
            props.lras.lrasOutputs.mortgageScore < 700
              ? "md-text--error"
              : Math.floor(props.accountTotalAmount) !== Math.floor(props.loanAmount)
              ? "text-amber"
              : "md-text--theme-secondary"
          }
          reviewPage={true}
          label="Loan amount"
        />
        <Repayments />
        <PropertyAccountSummary />
        <ReviewNotes currentPage={ReviewNotesScreens.review} key={ReviewNotesScreens.review}/>
        <ReviewNextAndBackButtonSection hideReview={true} preApprove={true} nextBtnName="Approve" />
      </div>
      <ReviewFooter />
    </>
  );
};

const mapStateToProps = (state: TAppStateType) => {
  return {
    accountTotalAmount: state.loan.accountTotalAmount,
    formInvalidArray: state.review.formInvalidArray,
    loanAmount: state.loan.loanAmount,
    lras: state.help.lras,
    suburbCheckedIndicator: state.objective.suburbCheckedIndicator,
    showReviewScreen: state.help.showReviewScreen,
  };
};

export default connect(mapStateToProps, { helpFundsToCompleteAction })(Review);

const ReviewFooter = Styled(Footer)({
  position: "absolute",
});
