import { Reducer } from "redux";
import { ILRAS } from "amm-objective-interface";
import { moduleConstant } from "../../../constants";

import { deepCopyObject } from "../../../utility";
import {
  HELP_FUNDS_TO_COMPLETE,
  HELP_LOAD_ENTIRE_STATE,
  HELP_SAVE_LRAS_ACTION,
  HELP_SET_AFFORDABILITY_VISIT_COUNT,
  HELP_SHOW_REVIEW_SCREEN,
  HELP_SIDEBAR_ANIMATION_CLASS,
  HELP_STATE_UPDATE,
  HelpActions,
  HELP_FOOTER_PROGRESS_WIDTH,
  HELP_MENU_OPEN,
  HELP_LRAS_METRIC_VALID,
  // HELP_MENU_OPEN
} from "../../actions/helpActions/helpActionType";
import { LOGIN_USER_LOGOUT, LoginUserLogoutAction } from "../../actions/login/loginActionType";
import { isLRASMetricValid } from "./helpReducerUtility";

export interface IHelpLinksInterface {
  heading: string;
  url: string;
  objectID: string;
}
export interface IHelpReviewObject {
  helpSectionHeading: string;
  helpSectionContent: string;
  helpSectionLinks?: IHelpLinksInterface[];
  helpSubcontent: string;
  helpSubheading: string;
}
export interface IHelpStateInterface {
  affordabilityPageVisitCount: number;
  reviewObject: IHelpReviewObject;
  showReviewScreen: boolean;
  showAffordabilityScreen: boolean;
  fundsToCompleteCall: boolean;
  fundsToCompleteIsCached: boolean;
  sidebarAnimationClassName: string;
  count: number;
  lras: ILRAS;
  isLRASMetricValid: boolean;
  footerProgressWidth: number;
  menuOpen: boolean;
}
export interface IHelpActionsInterface {
  isSubheading: boolean;
  type: string;
  module: string[];
  responseObject: IHelpReviewObject;
  showReviewScreen: boolean;
  count: number;
  className: string;
  loader: boolean;
  data: ILRAS;
}

export const helpInitialState: IHelpStateInterface = {
  affordabilityPageVisitCount: 0,
  count: 0,
  lras: {
    interestSaving: {
      interestSavingsAnnual: 0,
      interestSavingsOverLife: 0,
    },
    disposableIncome: 0,
    expectedLoss: 0,
    loanMetrics: {
      debtToIncomePercentage: 0,
      loanToIncome: 0,
    },
    lrasOutputs: {
      lras: 0,
      rateComparison: 0,
      rateVariable: 0,
      mortgageScore: 0,
      rateVariablePerAccount: {},
    },

    lvr: 0,
    monthlyPayments: 0,
    monthlyPaymentsPerAccount: [0],
    monthlyNSRRepayments: 0,
    monthlyNSRRepaymentsPerAccount: [0],
    nsrValues: {
      nsrHem: 0,
      nsrHemMultiple: 0,
      nsrLoanAmount: 0,
      nsrLoanTerm: 0,
      nsrTotal: 0,
      nsrTotalExpenses: 0,
      nsrTotalIncome: 0,
      nsrTotalLoanPayments: 0,
      nsrTotalTax: 0,
      nsrAssessmentRateBuffer: 0,
      nsrAssessmentRateFloor: 0,
      nsrAssessmentRate: 0,
      lvrMaxLoanAmount: 0,
      maxLoanAmount: 0,
      nsrMaxLoanAmount: 0,
      applicantIndividualHem: [],
    },
    nvr: 0,
    nsr: 0,
    yearlyPayments: 0,
    baseLoadingINV: 0,
    mortgageManagerMargin: 0,
    baseLoadingLVR: 0,
    baseLoadingIO: 0,
    baseLoadingIOOO: 0,
    cashRate: 0,
    variableFundingMargin: 0,
    fixedRateFundingMarginForTwoYears: 0,
    loadingINV: 0,
    loadingIO: 0,
    loadingIOOO: 0,
    applicantsIncomeInformation: { assessedIncome: [], taxableIncome: [] },
    totals: {
      propertiesCombinedTotal: 0,
      applicantsCombinedTotals: {
        applicantsCombinedTotalsCreditCard: 0,
        applicantsCombinedTotalsCreditCardWithoutFactored: 0,
        applicantsCombinedTotalsExpectedTaxableIncome: 0,
        applicantsCombinedTotalsExpense: 0,
        applicantsCombinedTotalsIncome: 0,
        applicantsCombinedTotalsIncomeFactored: 0,
        applicantsCombinedTotalsObligation: 0,
        applicantsCombinedTotalsObligationForDTI: 0,
        incomeSourcesAssessedPayments: [],
        creditCardAssessedPayments: {},
        financialObligationAssessedPayments: {},
      },
      loanAmount: 0,
    },
    applicantTaxBreakUp: {},
  },
  isLRASMetricValid: false,
  reviewObject: {
    helpSectionContent: "Default Content",
    helpSectionHeading: "Default Heading",
    helpSectionLinks: [],
    helpSubcontent: "",
    helpSubheading: "",
  },
  showReviewScreen: false,
  sidebarAnimationClassName: "",
  showAffordabilityScreen: false,
  fundsToCompleteCall: false,
  fundsToCompleteIsCached: false,
  footerProgressWidth: 100 * 0.25,
  menuOpen: false,
};

const helpReducer: Reducer<IHelpStateInterface, HelpActions | LoginUserLogoutAction> = (
  state: IHelpStateInterface = deepCopyObject(helpInitialState),
  action: HelpActions | LoginUserLogoutAction
): IHelpStateInterface => {
  if (
    action &&
    action.module &&
    action.module.filter((currentModule: any) =>
      Object.values(moduleConstant).includes(currentModule)
    ).length === 0
  ) {
    return { ...state };
  }
  const currentState = Object.assign({}, state);
  switch (action.type) {
    case HELP_STATE_UPDATE:
      if (action.isSubheading) {
        currentState.reviewObject.helpSubheading = action.responseObject.helpSubheading;
        currentState.reviewObject.helpSubcontent = action.responseObject.helpSubcontent;
      } else {
        currentState.reviewObject.helpSubheading = "";
        currentState.reviewObject.helpSubcontent = "";
        currentState.reviewObject.helpSectionHeading = action.responseObject.helpSectionHeading;
        currentState.reviewObject.helpSectionContent = action.responseObject.helpSectionContent;
        currentState.reviewObject.helpSectionLinks =
          action.responseObject.helpSectionLinks !== []
            ? action.responseObject.helpSectionLinks
            : [];
      }

      return { ...currentState };

    case HELP_SHOW_REVIEW_SCREEN:
      currentState.showReviewScreen = action.showReviewScreen;
      return { ...currentState };

    case HELP_SET_AFFORDABILITY_VISIT_COUNT:
      if (action.showAffordabilityScreen) {
        currentState.affordabilityPageVisitCount = currentState.affordabilityPageVisitCount + 1;
      }

      currentState.showAffordabilityScreen = action.showAffordabilityScreen;
      return { ...currentState };

    case HELP_SIDEBAR_ANIMATION_CLASS:
      let sidebarAnimationClassName = currentState.sidebarAnimationClassName;
      if (action.count === 3 || action.count === 1) {
        sidebarAnimationClassName = action.className;
      } else if (action.count === 2) {
        sidebarAnimationClassName = sidebarAnimationClassName + action.className;
      }
      currentState.count = action.count;
      return { ...currentState, sidebarAnimationClassName };

    case HELP_FUNDS_TO_COMPLETE:
      currentState.fundsToCompleteCall = action.fundsToCompleteCall;
      currentState.fundsToCompleteIsCached = action.isCached;
      return { ...currentState };

    case LOGIN_USER_LOGOUT:
      return { ...helpInitialState };

    case HELP_LOAD_ENTIRE_STATE:
      // so that the sidebar lras metrics animation (lvr, nsr, score animation behavior is consistent)
      action.state.count = 0;
      action.state.sidebarAnimationClassName = "";

      return action.state;

    case HELP_SAVE_LRAS_ACTION:
      const lrasResult = action.data;
      lrasResult.lrasOutputs.mortgageScore = isLRASMetricValid(lrasResult)
        ? lrasResult.lrasOutputs.mortgageScore
        : 0;
      return {
        ...currentState,
        lras: lrasResult,
        isLRASMetricValid: isLRASMetricValid(lrasResult),
      };

    case HELP_LRAS_METRIC_VALID:
      return { ...currentState, isLRASMetricValid: isLRASMetricValid(action.lrasMetrics) };

    case HELP_FOOTER_PROGRESS_WIDTH:
      return {
        ...state,
        footerProgressWidth: action.width,
      };
    case HELP_MENU_OPEN:
      return {
        ...state,
        menuOpen: action.menuOpen,
      };
    default:
      return { ...currentState };
  }
};

export default helpReducer;
