import {
  UPLOAD_FILE_MODAL_CLOSE,
  UPLOAD_FILE_MODAL_OPEN,
  UPLOAD_HISTORY_UPDATE,
  UPLOAD_LOADER,
  UploadFileModalCloseActionType,
  DeleteFileModalCloseActionType,
  DeleteFileModalOpenActionType,
  UploadFileModalOpenActionType,
  UploadLoaderActionType,
  UploadLoadEntireStateActionType,
  UPLOAD_LOAD_ENTIRE_STATE,
  UploadUpdateNumberOfUploadsActionType,
  UPLOAD_UPDATE_NUMBER_OF_UPLOADS,
  DELETE_FILE_MODAL_CLOSE,
  DELETE_FILE_MODAL_OPEN,
  DisableSubmitButtonActionType,
  DISABLE_SUBMIT_BUTTON,
  UploadIsSubmitModalVisibleActionType,
  UPDATE_IS_SUBMIT_MODAL_VISIBILE,
  UpdateSettlementDateActionType,
  UPDATE_SETTLEMENT_DATE,
  UploadDocumentPreviewActionType,
  UPLOAD_DOCUMENT_PREVIEW,
  UploadModalVisibleActionType,
  IS_UPLOAD_MODAL_VISIBLE,
  FILE_CHUNKS,
  FileChunksActionType,
  SetToastsActionType,
  SET_TOASTS,
  AttachedFilesActionType,
  ATTACHED_FILES,
  IS_ALL_FILES_UPLOADED,
  isAllFilesAttachedActionType,
  UpdateTagsActionType,
  UPDATE_TAGS_ACTION,
  SelectedTagsActionType,
  SELECTED_TAGS_ACTION,
  SetUploadingFilesActionType,
  SET_UPLOADING_FILES,
} from "./uploadDocumentsActionType";
import { IAttachedFile, moduleConstant } from "../../../constants";
import { IToastsInterface } from "../../reducers/successSnackbarReducer";

export const uploadFileModalCloseAction: UploadFileModalCloseActionType = (
  tagsModalVisibility: boolean
) => {
  return {
    module: [moduleConstant.upload],
    tagsModalVisibility,
    type: UPLOAD_FILE_MODAL_CLOSE,
  };
};

export const deleteFileModalCloseAction: DeleteFileModalCloseActionType = (
  deleteModalVisibility: boolean
) => {
  return {
    module: [moduleConstant.upload],
    deleteModalVisibility,
    type: DELETE_FILE_MODAL_CLOSE,
  };
};

export const updateSettlementDateAction: UpdateSettlementDateActionType = (
  settlementDate: Date
) => {
  return {
    module: [moduleConstant.upload],
    settlementDate,
    type: UPDATE_SETTLEMENT_DATE,
  };
};

export const uploadIsSubmitModalVisibleAction: UploadIsSubmitModalVisibleActionType = (
  isSubmitModalVisible: boolean
) => {
  return {
    module: [moduleConstant.upload],
    isSubmitModalVisible,
    type: UPDATE_IS_SUBMIT_MODAL_VISIBILE,
  };
};

export const deleteFileModalOpenAction: DeleteFileModalOpenActionType = (
  deleteModalVisibility: boolean,
  deleteFileId: any
) => {
  return {
    deleteFileId,
    module: [moduleConstant.upload],
    deleteModalVisibility,
    type: DELETE_FILE_MODAL_OPEN,
  };
};

export const uploadFileModalOpenAction: UploadFileModalOpenActionType = (
  tagsModalVisibility: boolean,
  scenarioId: string
) => {
  return {
    scenarioId,
    module: [moduleConstant.upload],
    tagsModalVisibility,
    type: UPLOAD_FILE_MODAL_OPEN,
  };
};

export const uploadModalVisibleAction: UploadModalVisibleActionType = (
  isUploadModalVisible: boolean,
  scenarioId: string
) => {
  return {
    module: [moduleConstant.upload],
    isUploadModalVisible,
    scenarioId,
    type: IS_UPLOAD_MODAL_VISIBLE,
  };
};

export const uploadUpdateNumberOfUploadsAction: UploadUpdateNumberOfUploadsActionType = (
  numberOfUploads: number
) => {
  return {
    numberOfUploads,
    module: [moduleConstant.upload],
    type: UPLOAD_UPDATE_NUMBER_OF_UPLOADS,
  };
};

export const uploadLoaderAction: UploadLoaderActionType = (showUploadLoader: boolean) => {
  return {
    module: [moduleConstant.upload],
    showUploadLoader,
    type: UPLOAD_LOADER,
  };
};

export const uploadHistoryUpdateAction = (fileName: string) => {
  return {
    fileName,
    module: [moduleConstant.upload],
    type: UPLOAD_HISTORY_UPDATE,
  };
};

export const uploadLoadEntireStateAction: UploadLoadEntireStateActionType = (state: any) => {
  return {
    state,
    module: [moduleConstant.upload],
    type: UPLOAD_LOAD_ENTIRE_STATE,
  };
};

export const disableSubmitButtonAction: DisableSubmitButtonActionType = (isDisabled: boolean) => {
  return {
    isDisabled,
    module: [moduleConstant.upload],
    type: DISABLE_SUBMIT_BUTTON,
  };
};

export const uploadDocumentPreviewAction: UploadDocumentPreviewActionType = (
  isOpen: boolean,
  fileName: string,
  url: string,
  fileType: string
) => {
  return {
    isOpen,
    fileName,
    url,
    fileType,
    type: UPLOAD_DOCUMENT_PREVIEW,
    module: [moduleConstant.upload],
  };
};

export const fileChunksAction: FileChunksActionType = (index: number, chunks: number) => {
  return {
    index,
    chunks,
    type: FILE_CHUNKS,
    module: [moduleConstant.upload],
  };
};

export const setToastsAction: SetToastsActionType = (
  toasts: IToastsInterface[],
  isFileSuccess: boolean
) => {
  return {
    toasts,
    isFileSuccess,
    type: SET_TOASTS,
    module: [moduleConstant.upload],
  };
};

export const attachedFilesAction: AttachedFilesActionType = (attachedFiles: IAttachedFile[]) => {
  return {
    attachedFiles,
    type: ATTACHED_FILES,
    module: [moduleConstant.upload],
  };
};

export const updateTagsAction: UpdateTagsActionType = (
  fileName: string,
  uploadTime: string,
  tags: string[],
  uploadId: number
) => {
  return {
    fileName,
    uploadTime,
    tags,
    uploadId,
    type: UPDATE_TAGS_ACTION,
    module: [moduleConstant.upload],
  };
};

export const selectedTagsAction: SelectedTagsActionType = (selectedTags: string[]) => {
  return {
    selectedTags,
    type: SELECTED_TAGS_ACTION,
    module: [moduleConstant.upload],
  };
};

export const isAllFilesAttachedAction: isAllFilesAttachedActionType = (
  allFilesAttached: boolean
) => {
  return {
    allFilesAttached,
    type: IS_ALL_FILES_UPLOADED,
    module: [moduleConstant.upload],
  };
};

export const setUploadingFilesAction: SetUploadingFilesActionType = (allFilesUploaded: number) => {
  return {
    allFilesUploaded,
    type: SET_UPLOADING_FILES,
    module: [moduleConstant.upload],
  };
};
