import { IApplicant, IEmploymentObject } from "amm-applicant-interface";
import moment from "moment";
import { DURATION_TYPE } from "../../constants";
import { scenarioDashboardSaveAction } from "../../constants/userActions";

interface IGetSaveSceanrioAction {
  isReviewer: boolean;
  saveTeamScenarioPermission: boolean;
  currentUserID: number;
  userIDForScenario: number;
  saveAsOwnScenarioModalShow?: boolean;
}


export const getSaveScearioUserAction = ({
  isReviewer,
  saveTeamScenarioPermission,
  currentUserID,
  userIDForScenario,
  saveAsOwnScenarioModalShow,
}: IGetSaveSceanrioAction) => {
  let userAction = scenarioDashboardSaveAction[0];

  if (isReviewer) {
    userAction = scenarioDashboardSaveAction[3];
    return userAction;
  }

  return saveTeamScenarioPermission &&
    currentUserID !== userIDForScenario &&
    !saveAsOwnScenarioModalShow
    ? scenarioDashboardSaveAction[1]
    : scenarioDashboardSaveAction[0];
};

export const getTotalLivingExpense = (total: number,applicant: IApplicant) =>{
  applicant.livingExpenses.forEach((expense: any) => {
    if (!expense.isDeleted && expense.amount) {
      if (expense.duration === DURATION_TYPE.Week) {
        total += (expense.amount * 52) / 12;
      } else if (expense.duration === DURATION_TYPE.Year) {
        total += expense.amount / 12;
      } else {
        total += expense.amount;
      }
    }
  });

  if (
    applicant.affordabilityTotals["livingExpenses"].duration ===
    DURATION_TYPE.Week
  ) {
    total = (total * 12) / 52;
  }
  if (
    applicant.affordabilityTotals["livingExpenses"].duration ===
    DURATION_TYPE.Year
  ) {
    total = total * 12;
  }
  return total;
}

export const employmentProbationValidator = (employmentList: IEmploymentObject[]) => {
  let today = new Date();
  try {
    if(employmentList.length ===1){
      let months = Math.floor(moment(today).diff(moment(employmentList[0].startDate), "months", true));
      return months < 6;
    }else{
      return false;
    }
  } catch (e) {
    return false;
  }
};