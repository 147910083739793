import React from "react";

const ApplicantIcon: React.FC = () => {
  return (
    <svg
      className="footer-addApplicant-icon icon footer-icon"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 35 35"
    >
      <title>icon-applicants</title>
      <path
        className="cls-1"
        d="M17.5,2A15.5,15.5,0,1,1,2,17.5,15.5176,15.5176,0,0,1,17.5,2m0-2A17.5,17.5,0,1,0,35,17.5,17.5,17.5,0,0,0,17.5,0Z"
      />
      <rect className="cls-2" x="8" y="12" width="2" height="8" />
      <path
        className="cls-2"
        d="M20,17a4,4,0,1,0-4-4A3.9989,3.9989,0,0,0,20,17Zm0,2c-2.67,0-8,1.34-8,4v2H28V23C28,20.34,22.67,19,20,19Z"
      />
      <rect className="cls-2" x="5" y="15" width="8" height="2" />
    </svg>
  );
};

export default ApplicantIcon;
