import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import React, { useState } from "react";
import { Button, FontIcon, Snackbar } from "react-md";
import { TickIcon } from "../common/icons/tickIcon";
import UploadDocumentHistory from "./uploadDocumentHistory";
import { IScenarioByIdData } from "amm-scenario-interface";
import { ITeam } from "amm-admin-interface";
import { getUploadedFilesByIdAction } from "../../constants/userActions";
import { connect } from "react-redux";
import { useQuery } from "react-apollo-hooks";
import { SCENARIO_BY_ID } from "../../constants/queryAndMutations";
import { TAppStateType } from "../../store/reducers/reducers";
import DeleteModal from "./deleteModal";
import UploadFileMultiple from "./uploadFileMultiple";
import Dropzone, { FileRejection } from "react-dropzone";
import {
  uploadModalVisibleAction,
  setToastsAction,
} from "../../store/actions/uploadDocumentsActions/uploadDocumentsAction";
import {
  AttachedFilesActionType,
  SetToastsActionType,
  UploadModalVisibleActionType,
  isAllFilesAttachedActionType,
} from "../../store/actions/uploadDocumentsActions/uploadDocumentsActionType";
import { IToastsInterface } from "../../store/reducers/successSnackbarReducer";
import SupportingDocs from "./supportingDocs";
import { STATUS_NAMES } from "../../constants/index";
import { handleSubmit, isUploadDisabled } from "./uploadHandler";
import {
  attachedFilesAction,
  setUploadingFilesAction,
  isAllFilesAttachedAction,
} from "../../store/actions/uploadDocumentsActions/uploadDocumentsAction";
import {
  uploadFileScenarioThunk,
  UploadFileScenarioThunkType,
} from "../../store/thunk/scenarioThunk/uploadFileScenarioThunk";
import { acceptedUploadTypes } from "../../constants";
import { SetUploadingFilesActionType } from "../../store/actions/uploadDocumentsActions/uploadDocumentsActionType";
import { errorGenericErrorHandleAction } from "../../store/actions/errorActions/errorAction";
import { ErrorGenericErrorHandleActionType } from "../../store/actions/errorActions/errorActionType";
interface StateProps {
  token: string;
  brandName: string;
  currentUserId: number;
  teams: ITeam;
  currentTeamId: number;
  currentUserRole: string;
  isSubmitModalVisible: boolean;
  tagsModalVisibility: boolean;
  total: number;
  isUploadModalVisible: boolean;
  scenarioID: number;
  currentScenarioOwnerID: number;
  reviewerID?: number;
  toasts: IToastsInterface[];
  isFileSuccess: boolean;
  numberOfUploads: number;
  currentScenarioUserId: number;
  currentScenarioReviwerID: number;
  isFileUploading: boolean;
  allFilesUploaded: number;
  allFilesAttached: boolean;
  uploadDocumentsForAllScenariosAsReviewer: boolean;
  currentScenarioStatus: string;
}

interface DispatchProps {
  uploadModalVisibleAction: UploadModalVisibleActionType;
  setToastsAction: SetToastsActionType;
  uploadFileScenarioThunk: UploadFileScenarioThunkType;
  attachedFilesAction: AttachedFilesActionType;
  setUploadingFilesAction: SetUploadingFilesActionType;
  isAllFilesAttachedAction: isAllFilesAttachedActionType;
  errorGenericErrorHandleAction: ErrorGenericErrorHandleActionType;
}

type UploadDocumentsProps = StateProps & DispatchProps;

export const UploadDocumentModal = (props: UploadDocumentsProps) => {
  const isReviewer = props.reviewerID === props.currentUserId;

  const isOwnScenario = props.currentScenarioOwnerID === props.currentUserId;

  const isTeamMemberOrOwnScenario = (data?: IScenarioByIdData) => {
    return data && data.scenarioById && data.scenarioById.isTeamMember ? true : isOwnScenario;
  };

  const isScenarioUnderReview =
    props.currentScenarioStatus === STATUS_NAMES.creditReview ||
    props.currentScenarioStatus === STATUS_NAMES.approvedPending;

  const isChooseFileButtonEnabled = (data?: IScenarioByIdData) => {
    return isReviewer || (props.uploadDocumentsForAllScenariosAsReviewer && isScenarioUnderReview)
      ? true
      : isTeamMemberOrOwnScenario(data);
  };

  const [isDragEnter, setIsDragEnter] = useState<boolean>(false);

  let isSubmitDisabledForDocuSign = true;
  interface IQueryVariable {
    userAction: {
      actionPassed: string;
    };
    id: number;
  }

  const actionPassedForScenarioID = () => {
    if (isReviewer) {
      return getUploadedFilesByIdAction[2];
    } else if (props.currentScenarioOwnerID === props.currentUserId) {
      return getUploadedFilesByIdAction[0];
    } else {
      return getUploadedFilesByIdAction[1];
    }
  };

  const areAllFilesSubmitted = (data: IScenarioByIdData) =>
    data.scenarioById && data.scenarioById.uploadToCapitalStatus;

  const areAllFilesTagged = (data: IScenarioByIdData) => {
    return (
      data.scenarioById && data.scenarioById.taggedFilesCount === data.scenarioById.uploadCount
    );
  };

  const isWarning = (data: IScenarioByIdData) =>
    !(data.scenarioById && data.scenarioById.uploadToCapitalStatus);

  const dismiss = () => {
    const [, ...toasts] = props.toasts;
    props.setToastsAction(toasts, false);
  };

  const handleDrop = (acceptedFiles: any) => {
    handleSubmit(
      acceptedFiles,
      props.numberOfUploads,
      props.uploadFileScenarioThunk,
      props.token,
      props.scenarioID.toString(),
      props.currentUserId,
      props.currentScenarioUserId,
      props.currentScenarioReviwerID,
      props.attachedFilesAction,
      props.setToastsAction,
      props.setUploadingFilesAction,
      props.allFilesUploaded,
      props.isAllFilesAttachedAction,
      props.uploadDocumentsForAllScenariosAsReviewer
    );
    setIsDragEnter(false);
  };

  const dropRejectHandler = (files: FileRejection[]) => {
    files.forEach((file) => {
      if (file.errors[0].code === "file-invalid-type") {
        props.errorGenericErrorHandleAction(
          `File upload failed: File - ${file.file.name} invalid type`
        );
      } else if (file.errors[0].code === "file-too-large") {
        props.errorGenericErrorHandleAction(
          `File upload failed: File - ${file.file.name} too large`
        );
      }
    });
    setIsDragEnter(false);
  };

  const { data, error } = useQuery<IScenarioByIdData, IQueryVariable>(SCENARIO_BY_ID, {
    variables: {
      id: props.scenarioID,
      userAction: {
        actionPassed: actionPassedForScenarioID(),
      },
    },
    pollInterval: 10000,
    fetchPolicy: "no-cache",
  });

  const docusignStatus = JSON.parse(
    data && data.scenarioById && data.scenarioById.docusignStatus !== "{}"
      ? data.scenarioById.docusignStatus
      : "[]"
  );

  isSubmitDisabledForDocuSign =
    docusignStatus.length > 0
      ? Boolean(docusignStatus.find((docusign: any) => docusign.status !== "Completed"))
      : true;

  if (error) return <></>;

  return (
    <div id="uploadDocumentModal">
      <Dialog
        open={props.isUploadModalVisible}
        className="upload-modal"
        fullWidth={true}
        maxWidth={"lg"}
        onClose={() => props.uploadModalVisibleAction(false, props.scenarioID.toString())}
        aria-labelledby="max-width-dialog-title"
      >
        <Snackbar
          className={`${props.isFileSuccess ? "success-snackbar" : "error-snackbar"}`}
          id="file-upload"
          toasts={props.toasts}
          onDismiss={dismiss}
        />
        <DialogTitle className="upload-modal">SUPPORTING DOCUMENTS</DialogTitle>
        <div
          className={
            data && data.scenarioById && data.scenarioById.scenarioStatus !== STATUS_NAMES.scenario
              ? "top-container"
              : ""
          }
        >
          <DialogContent className="left-container">
            <div>
              <div className="upload-content">
                <Dropzone
                  onDropAccepted={(acceptedFiles) => handleDrop(acceptedFiles)}
                  noClick={true}
                  multiple={true}
                  noKeyboard={true}
                  onDragEnter={() => {
                    setIsDragEnter(true);
                  }}
                  onDragLeave={() => {
                    setIsDragEnter(false);
                  }}
                  accept={acceptedUploadTypes}
                  maxSize={2.5e7}
                  onDropRejected={dropRejectHandler}
                  onError={() => {
                    props.setToastsAction([{ text: "File upload failed" }], false);
                  }}
                  disabled={isUploadDisabled(
                    props.isFileUploading,
                    props.numberOfUploads,
                    isChooseFileButtonEnabled(data),
                    props.allFilesAttached
                  )}
                >
                  {({ getRootProps }) => (
                    <div {...getRootProps()}>
                      {isDragEnter && (
                        <div className="drag-enter-box">
                          <p>DRAG FILES HERE TO ATTACH</p>
                        </div>
                      )}
                      <div className={`${isDragEnter ? "drag-enter-change" : ""}`}>
                        <UploadFileMultiple
                          scenarioId={props.scenarioID.toString()}
                          chooseFileButtonEnabled={isChooseFileButtonEnabled(data)}
                        />
                        <UploadDocumentHistory
                          scenarioId={props.scenarioID}
                          currentScenarioUserId={props.currentScenarioOwnerID}
                          currentScenarioReviewerId={props.reviewerID}
                          isTeamMemberOrOwnScenario={isTeamMemberOrOwnScenario(data)}
                          isDocusignCompleted={!isSubmitDisabledForDocuSign}
                          scenarioStatus={
                            data && data.scenarioById && data.scenarioById.scenarioStatus
                              ? data.scenarioById.scenarioStatus
                              : ""
                          }
                          chooseFileButtonEnabled={isChooseFileButtonEnabled(data)}
                          owner={data ? data.scenarioById.owner : " "}
                          isScenarioUnderReview={isScenarioUnderReview}
                        />
                      </div>
                    </div>
                  )}
                </Dropzone>
              </div>
              <DeleteModal
                isClickedByReviewer={isReviewer}
                scenarioId={props.scenarioID}
                currentScenarioUserId={props.currentScenarioOwnerID}
              />
              <div style={{ display: "flex" }}>
                <div className="information">
                  {data && data.scenarioById.uploadCount > 0 && (
                    <>
                      {areAllFilesTagged(data) && (
                        <div className="tick">
                          <TickIcon className={"credit-check-tick-icon"} width={35} height={35} />
                          <span className="tick-span">All Uploaded files have been tagged.</span>
                        </div>
                      )}
                      {!areAllFilesTagged(data) && (
                        <div className="tick">
                          <span
                            data-testid="category-4"
                            className={
                              data.scenarioById.taggedFilesCount === 0 ? "" : "show-warning"
                            }
                          />
                          {data.scenarioById.taggedFilesCount === 0 ? (
                            <FontIcon className="error-block-icon2">block</FontIcon>
                          ) : (
                            <></>
                          )}
                          {data.scenarioById.uploadCount - data.scenarioById.taggedFilesCount}/
                          {data.scenarioById.uploadCount} files have not been tagged.
                          <br /> Supporting documents must be tagged before submitting.
                        </div>
                      )}
                    </>
                  )}
                </div>
                <div className="information">
                  {data && data.scenarioById.uploadCount > 0 && (
                    <>
                      {areAllFilesSubmitted(data) && (
                        <div className="tick">
                          <TickIcon className={"credit-check-tick-icon"} width={35} height={35} />
                          <span className="tick-span">All Uploaded files have been submitted.</span>
                        </div>
                      )}
                      {isWarning(data) && (
                        <div className="tick">
                          <span data-testid="category-4" className="show-warning" />
                          {data.scenarioById.capitalUploadNotCompleteCount}/
                          {data.scenarioById.uploadCount} files have not been submitted.
                          <br /> Uploaded files are submitted on submit page
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
            <div>
              <Button
                id="back-button"
                className="upload-back-button"
                flat={true}
                swapTheming={true}
                onClick={() => props.uploadModalVisibleAction(false, props.scenarioID.toString())}
              >
                Back
              </Button>
            </div>
          </DialogContent>
          {data &&
            data.scenarioById &&
            data.scenarioById.scenarioStatus !== STATUS_NAMES.scenario &&
            data.scenarioById.scenarioStatus !== STATUS_NAMES.reWork && (
              <DialogContent className="right-container">
                <SupportingDocs
                  supportingDocs={
                    data && data.scenarioById && data.scenarioById.supportingDocs
                      ? data.scenarioById.supportingDocs
                      : []
                  }
                />
              </DialogContent>
            )}
        </div>
      </Dialog>
    </div>
  );
};

export const mapStateToProps = (
  state: TAppStateType,
  props: { scenarioID: number; currentScenarioOwnerID: number }
) => {
  const currentUserId = state.login.currentUser.id;
  const role = state.login.rolesForThisInstance.find(
    (role) => role.value === state.login.currentUser.roleId
  );
  return {
    token: state.login.token ? state.login.token : "",
    currentUserId,
    brandName: state.branding.brandName,
    teams: state.login.teams,
    currentTeamId: state.login.currentTeamID,
    currentUserRole: role ? role.label : "",
    isSubmitModalVisible: state.upload.isSubmitModalVisible,
    tagsModalVisibility: state.upload.tagsModalVisibility,
    total: state.upload.numberOfUploads,
    isUploadModalVisible: state.upload.isUploadModalVisible,
    toasts: state.upload.toasts,
    isFileSuccess: state.upload.isFileSuccess,
    numberOfUploads: state.upload.numberOfUploads,
    currentScenarioReviwerID: state.scenario.reviewerID ? state.scenario.reviewerID : 0,
    currentScenarioUserId: state.scenario.ownerID ? state.scenario.ownerID : 0,
    isFileUploading: state.upload.showUploadLoader,
    allFilesUploaded: state.upload.allFilesUploaded,
    allFilesAttached: state.upload.allFilesAttached,
    uploadDocumentsForAllScenariosAsReviewer:
      state.login.userPermissions.uploadDocumentsForAllScenariosAsReviewer,
    currentScenarioStatus: state.scenario.currentScenarioStatus,
  };
};

export const mapDispatchToProps = {
  uploadModalVisibleAction,
  setToastsAction,
  uploadFileScenarioThunk,
  attachedFilesAction,
  setUploadingFilesAction,
  isAllFilesAttachedAction,
  errorGenericErrorHandleAction,
};

// ToDo: Check and fix object element placement wrt linting rules
export default connect(mapStateToProps, mapDispatchToProps)(UploadDocumentModal);
