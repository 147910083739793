import { moduleConstant } from "../../../constants";
import {
  SCENARIO_DELETE,
  SCENARIO_DUPLICATE,
  SCENARIO_LIST,
  SCENARIO_LOAD,
  SCENARIO_LOADER,
  SCENARIO_MODAL_BUTTON_LOADER,
  SCENARIO_MODAL_HIDE,
  SCENARIO_MODAL_SHOW,
  SCENARIO_RESET_CURRENT_SCENARIO_ID,
  SCENARIO_SAVE,
  SCENARIO_SAVE_BUTTON_LOADER,
  START_DOCUSIGN_DISABLED,
  SET_QUERY_DATA_LOADER,
  ScenarioDeleteActionType,
  ScenarioDuplicateActionType,
  ScenarioListActionType,
  ScenarioLoadActionType,
  ScenarioLoaderActionType,
  ScenarioModalButtonLoaderActionType,
  ScenarioModalHideActionType,
  ScenarioModalShowActionType,
  ScenarioResetCurrentScenarioIdType,
  ScenarioSaveActionType,
  ScenarioSaveButtonLoaderActionType,
  ScenarioSaveDiscardModalShowActionType,
  SCENARIO_SAVE_DISCARD_MODAL_SHOW,
  ScenarioSaveAsOwnScenarioModalShowActionType,
  SCENARIO_SAVE_AS_OWN_SCENARIO_MODAL_SHOW,
  ScenarioSetCurrentScenarioHistoryActionType,
  SCENARIO_SET_CURRENT_SCENARIO_HISTORY,
  IScenarioLoadParams,
  StartDocusignDisabledActionType,
  SetQueryDataLoaderActionType,
  ScenariosUpdateCapitalStatusType,
  IScenarioCapitalStatusUpdate,
  SCENARIO_CAPITAL_STATUS_UPDATE,
  SetReviewerIdReWorkActionType,
  SET_REVIEWER_ID_RE_WORK,
} from "./scenarioActionType";
import { CurrentScenarioHistory } from "../../reducers/scenarioReducers/scenarioReducer";

export interface IScenarioActionsInterface extends IModalShowAction {
  [key: string]: any;
}

export interface IModalShowAction {
  cancelAction?: () => void;
  okayAction?: () => void;
  button1Name?: string;
  button2Name?: string;
  description?: string;
  loadingText?: string;
}

export const scenarioDeleteAction: ScenarioDeleteActionType = (state: any) => {
  return {
    module: [moduleConstant.scenario],
    state,
    type: SCENARIO_DELETE,
  };
};

export const scenarioDuplicateAction: ScenarioDuplicateActionType = (state: any) => {
  return {
    module: [moduleConstant.scenario],
    state,
    type: SCENARIO_DUPLICATE,
  };
};

export const scenarioLoadAction: ScenarioLoadActionType = ({
  scenarioId,
  scenarioStatus,
  reviewerID,
  ownerID,
  newSavedScenario,
}: IScenarioLoadParams) => {
  return {
    module: [moduleConstant.scenario],
    scenarioId,
    reviewerID,
    ownerID,
    scenarioStatus,
    newSavedScenario,
    type: SCENARIO_LOAD,
  };
};

export const scenariosUpdateCapitalStatus: ScenariosUpdateCapitalStatusType = ({
  scenarioId,
  capitalStatus,
}: IScenarioCapitalStatusUpdate) => {
  return {
    module: [moduleConstant.scenario],
    scenarioId,
    capitalStatus,
    type: SCENARIO_CAPITAL_STATUS_UPDATE,
  };
};
export const scenarioSaveAction: ScenarioSaveActionType = (state: any) => {
  return {
    module: [moduleConstant.scenario],
    state,
    type: SCENARIO_SAVE,
  };
};

export const scenarioListAction: ScenarioListActionType = (data: any, from?: string) => {
  return {
    data,
    from,
    module: [moduleConstant.scenario],
    type: SCENARIO_LIST,
  };
};

export const scenarioLoaderAction: ScenarioLoaderActionType = (visibility: boolean) => {
  return {
    module: [moduleConstant.scenario],
    type: SCENARIO_LOADER,
    visibility,
  };
};

export const scenarioSaveButtonLoaderAction: ScenarioSaveButtonLoaderActionType = (
  loader: boolean
) => {
  return {
    loader,
    module: [moduleConstant.scenario],
    type: SCENARIO_SAVE_BUTTON_LOADER,
  };
};
export const scenarioModalHideAction: ScenarioModalHideActionType = () => {
  return {
    module: [moduleConstant.scenario],
    type: SCENARIO_MODAL_HIDE,
  };
};
export const scenarioModalButtonLoaderAction: ScenarioModalButtonLoaderActionType = (
  loader: boolean
) => {
  return {
    loader,
    module: [moduleConstant.scenario],
    type: SCENARIO_MODAL_BUTTON_LOADER,
  };
};

export const scenarioModalShowAction: ScenarioModalShowActionType = (key: IModalShowAction) => {
  return {
    button1Name: key.button1Name,
    button2Name: key.button2Name,
    cancelAction: key.cancelAction,
    description: key.description,
    module: [moduleConstant.scenario],
    okayAction: key.okayAction,
    loadingText: key.loadingText,

    type: SCENARIO_MODAL_SHOW,
  };
};

export const scenarioResetCurrentScenarioIdAction: ScenarioResetCurrentScenarioIdType = () => {
  return {
    module: [moduleConstant.scenario],
    type: SCENARIO_RESET_CURRENT_SCENARIO_ID,
  };
};

export const scenarioSaveDiscardModalShowAction: ScenarioSaveDiscardModalShowActionType = (
  show: boolean
) => {
  return {
    show,
    module: [moduleConstant.scenario],
    type: SCENARIO_SAVE_DISCARD_MODAL_SHOW,
  };
};
export const scenarioSaveAsOwnScenarioModalShowAction: ScenarioSaveAsOwnScenarioModalShowActionType = (
  show: boolean,
  description: string
) => {
  return {
    show,
    description,
    module: [moduleConstant.scenario],
    type: SCENARIO_SAVE_AS_OWN_SCENARIO_MODAL_SHOW,
  };
};

export const scenarioSetCurrentScenarioHistoryAction: ScenarioSetCurrentScenarioHistoryActionType = (
  currentScenarioHistory: CurrentScenarioHistory[]
) => {
  return {
    currentScenarioHistory,
    module: [moduleConstant.scenario],
    type: SCENARIO_SET_CURRENT_SCENARIO_HISTORY,
  };
};

export const startDocusignDisabledAction: StartDocusignDisabledActionType = (
  setIsStartDocusignDisabled: boolean
) => {
  return {
    setIsStartDocusignDisabled,
    module: [moduleConstant.scenario],
    type: START_DOCUSIGN_DISABLED,
  };
};

export const setQueryDataLoaderAction: SetQueryDataLoaderActionType = (
  queryDataLoader: boolean
) => {
  return {
    queryDataLoader,
    module: [moduleConstant.scenario],
    type: SET_QUERY_DATA_LOADER,
  };
};

export const setReviewerIdReWorkAction: SetReviewerIdReWorkActionType = (reviewerID: number) => {
  return {
    reviewerID,
    module: [moduleConstant.scenario],
    type: SET_REVIEWER_ID_RE_WORK,
  };
};
