import { objectiveLoadStateAction } from "../store/actions/objectivePageActions/objectivePageActions";
import { objectiveInitialState } from "../store/reducers/objectivePageReducers/objectiveReducer";
import { solutionLoadEntireStateAction } from "../store/actions/solutionPageActions/solutionPageActions";
import { solutionInitialState } from "../store/reducers/solutionReducers/solutionInitialState";
import { applicantLoadEntireStateAction } from "../store/actions/applicantActions/applicantActions";
import { applicantInitialState } from "../store/reducers/applicantReducers/applicantInitialState";
import { errorLoadEntireStateAction } from "../store/actions/errorActions/errorAction";
import { errorInitialState } from "../store/reducers/errorReducers/errorReducer";
import { reviewLoadEntireStateAction } from "../store/actions/reviewActions/reviewActions";
import { reviewInitialState } from "../store/reducers/reviewReducers/reviewReducer";
import { helpInitialState } from "../store/reducers/helpReducers/helpReducer";
import {
  helpLoadEntireStateAction,
  helpShowReviewScreenAction,
} from "../store/actions/helpActions/helpAction";
import { scenarioResetCurrentScenarioIdAction } from "../store/actions/scenarioActions/scenarioActions";
import { ThunkDispatch } from "redux-thunk";
import { deepCopyObject } from ".";
import store from "../store/configureStore";
import { cashOutUseDropdown } from "../constants";
import { adminBrokerAddNotes } from "../store/actions/adminActions/adminActions";

// function to clear scenario from state
export const clearCurrentScenario = () => {
  return (dispatch: ThunkDispatch<any, any, any>) => {
    dispatch(adminBrokerAddNotes("<p><br></p>"));
    dispatch(objectiveLoadStateAction(deepCopyObject(objectiveInitialState)));
    dispatch(solutionLoadEntireStateAction(deepCopyObject(solutionInitialState)));
    dispatch(applicantLoadEntireStateAction(deepCopyObject(applicantInitialState)));
    let isCashOutError: boolean =
      !cashOutUseDropdown.includes(solutionInitialState.cashOutUse) &&
      solutionInitialState.doYouRequireCashOut === "Yes";
    dispatch(errorLoadEntireStateAction(deepCopyObject(errorInitialState), isCashOutError));
    dispatch(reviewLoadEntireStateAction(deepCopyObject(reviewInitialState)));
    helpInitialState.reviewObject = store.getState().help.reviewObject;
    dispatch(helpLoadEntireStateAction(deepCopyObject(helpInitialState)));
    dispatch(scenarioResetCurrentScenarioIdAction());
    dispatch(helpShowReviewScreenAction(false));
  };
};
