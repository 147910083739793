import React from "react";

interface IProps {
  className?: string;
  width: string;
  height: string;
}

export const CheckTickIcon = ({ className, width, height }: IProps) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      data-name="Layer 1"
      viewBox="0 0 16 16"
    >
      <circle className="check-tick-icon-cls-1" cx="8" cy="8" r="8" />
      <rect
        className="check-tick-icon-cls-2"
        x="3.5812"
        y="7.737"
        width="11.192"
        height="2"
        transform="translate(-3.49 9.0483) rotate(-45)"
      />
      <rect
        className="check-tick-icon-cls-2"
        x="1.5131"
        y="9.5727"
        width="6"
        height="2"
        transform="translate(0.2284 21.2399) rotate(-135)"
      />
    </svg>
  );
};
