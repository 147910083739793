import React from "react";

const BathIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="bathroom-icon"
      width="12"
      height="10"
      viewBox="0 0 18 15"
    >
      <g id="Bath_Icon" data-name="Bath Icon">
        <rect x="6" y="8" width="6" height="5" />
        <rect
          id="Rounded_Rectangle_8_copy"
          data-name="Rounded Rectangle 8 copy"
          y="8"
          width="8"
          height="5"
          rx="3"
          ry="3"
        />
        <rect
          id="Rounded_Rectangle_8_copy_2"
          data-name="Rounded Rectangle 8 copy 2"
          x="10"
          y="8"
          width="8"
          height="5"
          rx="3"
          ry="3"
        />
        <rect
          id="Rectangle_708_copy_5"
          data-name="Rectangle 708 copy 5"
          x="4"
          y="11"
          width="2"
          height="4"
        />
        <rect
          id="Rectangle_708_copy_6"
          data-name="Rectangle 708 copy 6"
          x="12"
          y="11"
          width="2"
          height="4"
        />
        <rect y="6" width="18" height="1" />
        <rect x="2" width="1" height="7" />
        <path d="M876,1316h4l-4,4v-4Z" transform="translate(-872 -1316)" />
        <rect x="2" width="3" height="1" />
      </g>
    </svg>
  );
};

export default BathIcon;
