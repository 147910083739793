import {
  TRIGGER_OPEN_MY_SCENARIOS_DASHBOARD,
  TRIGGER_OPEN_SCENARIO,
  TRIGGER_OPEN_USER_SCENARIOS_DASHBOARD,
  TRIGGER_PROFILE_LOAD,
  TriggerOpenUserScenariosDashboardType,
  TriggerOpenMyScenariosDashboardType,
  TriggerProfileLoadActionType,
  TriggerOpenScenarioActionType,
} from "./triggerActionType";

import { moduleConstant } from "../../../constants";

export const triggerProfileLoadAction: TriggerProfileLoadActionType = () => {
  return {
    module: [moduleConstant.trigger],
    type: TRIGGER_PROFILE_LOAD,
  };
};

export const triggerOpenScenarioAction: TriggerOpenScenarioActionType = () => {
  return {
    module: [moduleConstant.trigger],
    type: TRIGGER_OPEN_SCENARIO,
  };
};

export const triggerOpenMyScenariosDashboard: TriggerOpenMyScenariosDashboardType = () => {
  return {
    module: [moduleConstant.trigger],
    type: TRIGGER_OPEN_MY_SCENARIOS_DASHBOARD,
  };
};

export const triggerOpenUserScenariosDashboard: TriggerOpenUserScenariosDashboardType = (
  userId: number
) => {
  return {
    module: [moduleConstant.trigger],
    type: TRIGGER_OPEN_USER_SCENARIOS_DASHBOARD,
    userId,
  };
};
