import {
  IApplicant,
  IApplicantRequiredField,
  IApplicantInitialState,
} from "amm-applicant-interface";
import { IPropertyArray } from "amm-objective-interface";
import React, { useEffect, useState } from "react";
import { FontIcon } from "react-md";
import { connect } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import {
  helpSidebarAnimationClassAction,
  helpShowReviewScreenAction,
} from "../../store/actions/helpActions/helpAction";
import {
  HelpSidebarAnimationClassActionType,
  HelpShowReviewScreenActionType,
} from "../../store/actions/helpActions/helpActionType";
import {
  checkPropertiesHasAddress,
  checkPropertiesHaveValue,
  checkApplicantTotalIncome,
} from "../../utility/index";
import { checkAffordabilityError, checkApplicantErrors } from "../../utility/reviewUtility";
import ReviewIcon from "./icon/icon-tick";
import ReviewIconSucess from "./icon/icon-tick-fill";
import AffordabilityIcon from "./icon/icon-affordability";
import ApplicantIcon from "./icon/icon-applicants";
import PropertyIcon from "./icon/icon-property";
import SolutionIcon from "./icon/icon-solution";
import { invalidCategory } from "../../constants";
import { IAccount } from "amm-solution-interface";
import {
  fullFormValidationThunk,
  FullFormValidationThunkType,
} from "../../store/thunk/reviewThunk/fullFormValidationThunk";
import { IErrorStateInterface } from "../../store/reducers/errorReducers/errorReducer";
import { TAppStateType } from "../../store/reducers/reducers";
import { IReviewValidationState } from "amm-review-interface";
import { checkIfAllValidationIsCorrect } from "../../utility/validationPerPage";
import { reviewSetValidationAction } from "../../store/actions/reviewActions/reviewActions";
import { ReviewSetValidationActionType } from "../../store/actions/reviewActions/reviewActionTypes";

interface StateProps {
  affordabilityErrorIndicator: boolean;
  affordabilityPageVisitCount: number;
  affordabilityValidations: boolean;
  applicantValidations: boolean;
  applicants: IApplicant[];
  applicantsRequiredField: IApplicantRequiredField[];
  count: number;
  creditCardIndicator: boolean;
  incomeSourcesRowAdded: boolean[];
  loanErrorIndicator: boolean;
  loanValidations: boolean;
  objectiveErrorIndicator: boolean;
  objectiveValidations: boolean;
  properties: IPropertyArray[];
  showReviewScreen: boolean;
  account: IAccount[];
  isApplicantFormNotSubmitted: boolean;
  helpShowReviewScreenAction: HelpShowReviewScreenActionType;
  footerProgressWidth: number;
  applicantState: IApplicantInitialState;
  error: IErrorStateInterface;
  totalState: TAppStateType;
  formInvalidArray: boolean[];
  formInvalidationArrayForSendQuote: boolean[];
  totalFee: number;
  fundsToCompleteSum: number;
  footerValidations: IReviewValidationState;
  isLRASMetricValid: boolean;
  cashOutNote: string;
  doYouRequireCashOut: string;
}
interface DispatchProps {
  helpSidebarAnimationClassAction: HelpSidebarAnimationClassActionType;
  fullFormValidationThunk: FullFormValidationThunkType;
  reviewSetValidationAction: ReviewSetValidationActionType;
}

type FooterProps = StateProps & DispatchProps;
export const Footer: React.FC<FooterProps> = (props: FooterProps) => {
  const { pathname } = useLocation();
  let applicantErrorIndicator: boolean[] = [];

  useEffect(() => {
    updateReviewPageClass();
  }, [pathname, props.showReviewScreen, props.incomeSourcesRowAdded]);

  useEffect(() => {
    props.helpShowReviewScreenAction(false);
  }, [pathname]);

  const [propertyCategoryIsInvalid, setPropertyCategoryIsInvalid] = useState<boolean>(false);

  // useeffect everytime there is a change in category
  useEffect(() => {
    // setPropertyCategoryIs4(
    //   Boolean(
    //     props.properties.filter((property) => invalidCategory.includes(property.propertyCategory))
    //       .length
    //   )
    // );

    setPropertyCategoryIsInvalid(
      Boolean(
        props.properties.filter(
          (property) => invalidCategory.includes(property.propertyCategory) || property.highDensity
        ).length
      )
    );
  }, [props.properties.map((property) => property.propertyCategory)]);

  const updateReviewPageClass = () => {
    if (props.totalState.scenario.currentScenarioStatus !== "Scenario" && props.totalState.scenario.currentScenarioId) {
      if (
        pathname.includes("affordability") ||
        pathname.includes("applicants") ||
        pathname.includes("property") ||
        pathname.includes("solution")
      ) {
        props.helpSidebarAnimationClassAction(
          "sidebar_show-score sidebar_show-nsr sidebar_score-animation-complete",
          3
        );
        return;
      }
    } else {
      if (
        pathname.includes("affordability") ||
        pathname.includes("applicants") ||
        pathname.includes("solution") ||
        props.showReviewScreen
      ) {
        if (props.count === 0) {
          props.helpSidebarAnimationClassAction("sidebar_show-score", 1);
        }
        if (
          props.incomeSourcesRowAdded &&
          props.incomeSourcesRowAdded.includes(true) &&
          props.showReviewScreen
        ) {
          props.helpSidebarAnimationClassAction(
            "sidebar_show-score sidebar_show-nsr sidebar_score-animation-complete",
            3
          );
          return;
        }
        if (props.incomeSourcesRowAdded && props.incomeSourcesRowAdded.includes(true)) {
          props.helpSidebarAnimationClassAction(" sidebar_show-nsr", 2);
          return;
        }
        if (props.showReviewScreen) {
          props.helpSidebarAnimationClassAction(
            "sidebar_show-score sidebar_show-nsr sidebar_score-animation-complete",
            3
          );
          return;
        }
      }
    }
  };

  applicantErrorIndicator = checkApplicantErrors(props.applicantsRequiredField, props.applicants);
  const affordabilityErrors = checkAffordabilityError(
    props.applicants,
    props.applicantState.primaryAffordabilityRequiredFields,
    props.totalState.review.isVisited
  );

  const hasPropertiesAddress = checkPropertiesHasAddress(props.properties);
  const hasPropertiesValue = checkPropertiesHaveValue(props.properties);
  const checkApplicantValue = checkApplicantTotalIncome(props.applicants);

  const ReviewBtnClass = () => {
    const isEnable =
      hasPropertiesAddress ||
      propertyCategoryIsInvalid ||
      !hasPropertiesValue ||
      !checkApplicantValue;

    const isApproveDisabled = () => {
      return (
        props.formInvalidArray.includes(true) ||
        (props.fundsToCompleteSum < props.totalFee && !props.affordabilityValidations) ||
        !props.footerValidations.applicant ||
        !props.footerValidations.solution
      );
    };

    const state = {
      isEnable: isEnable,
      isFormComplete: props.isLRASMetricValid && !isApproveDisabled(),
    };
    return state;
  };

  const onClickReviewButton = () => {
    if (!hasPropertiesAddress) {
      props.helpShowReviewScreenAction(true);
      props.reviewSetValidationAction(
        checkIfAllValidationIsCorrect("objective", props.totalState),
        "objective"
      );
      props.fullFormValidationThunk(
        props.applicantState,
        props.applicants,
        props.applicantsRequiredField,
        props.properties,
        props.error,
        props.totalState
      );
    } else {
      props.helpShowReviewScreenAction(false);
    }
  };
  const isAccountNameValid = () =>
    props.account && props.account.find((account) => account.isInValidAccountName as boolean);

  const isCashOutNoteValid = () =>
    props.doYouRequireCashOut === "No" ||
    (props.doYouRequireCashOut === "Yes" && Boolean(props.cashOutNote));

  const isBalanceZero = props.account.findIndex((acc) => acc.amount === 0) >= 0;
  return (
    <footer className="footer-navigation">
      <div className="footerProgress" style={{ width: `${props.footerProgressWidth}%` }} />
      <ol className="formNavigationSteps">
        <li id="property-footer" onClick={() => props.helpShowReviewScreenAction(false)}>
          <Link to="/scenario/property">
            <PropertyIcon />
            <div className="primary footer-heading">
              Property
              <span
                data-testid="category-4"
                className={`${
                  props.objectiveErrorIndicator || propertyCategoryIsInvalid
                    ? "show-error-indicator"
                    : "hide-error-indicator"
                }`}
              />
              {!propertyCategoryIsInvalid &&
                props.objectiveValidations &&
                !props.objectiveErrorIndicator && (
                  <FontIcon secondary={true} className="show-valid-icon">
                    done
                  </FontIcon>
                )}
            </div>
          </Link>
        </li>
        <li
          id="solution-footer"
          onClick={() => props.helpShowReviewScreenAction(false)}
          className={hasPropertiesAddress || propertyCategoryIsInvalid ? "disable-menu" : ""}
        >
          <Link to={!hasPropertiesAddress ? "/scenario/solution" : "/scenario/property"}>
            <SolutionIcon />
            <div className="primary footer-heading">
              Solution
              <span
                data-testid="solution-page-error-icon"
                className={`${
                  props.loanErrorIndicator ||
                  isAccountNameValid() ||
                  !isCashOutNoteValid() ||
                  isBalanceZero
                    ? "show-error-indicator"
                    : "hide-error-indicator"
                }`}
              />
              {props.loanValidations &&
                !props.loanErrorIndicator &&
                !isAccountNameValid() &&
                isCashOutNoteValid() &&
                !hasPropertiesAddress && (
                  <FontIcon
                    data-testid="solution-page-valid-icon"
                    primary={true}
                    className="show-valid-icon"
                  >
                    done
                  </FontIcon>
                )}
            </div>
          </Link>
        </li>
        <li
          id="applicants-footer"
          onClick={() => props.helpShowReviewScreenAction(false)}
          data-testid="applicant-symbol"
          className={hasPropertiesAddress || propertyCategoryIsInvalid ? "disable-menu" : ""}
        >
          <Link to={!hasPropertiesAddress ? "/scenario/applicants" : "/scenario/property"}>
            <ApplicantIcon />
            <div className="primary footer-heading">
              Applicants
              <span
                data-testid="applicant-page-error-icon"
                className={`${
                  applicantErrorIndicator.includes(true) || props.isApplicantFormNotSubmitted
                    ? "show-error-indicator"
                    : "hide-error-indicator"
                }`}
              />
              {props.applicantValidations && !applicantErrorIndicator.includes(true) && (
                <FontIcon
                  data-testid="applicant-page-valid-icon"
                  primary={true}
                  className="show-valid-icon"
                >
                  done
                </FontIcon>
              )}
            </div>
          </Link>
        </li>
        <li
          id="affordability-footer"
          onClick={() => props.helpShowReviewScreenAction(false)}
          className={hasPropertiesAddress || propertyCategoryIsInvalid ? "disable-menu" : ""}
        >
          <Link to={!hasPropertiesAddress ? "/scenario/affordability" : "/scenario/property"}>
            <AffordabilityIcon />
            <div className="primary footer-heading">
              Affordability
              <span
                data-testid="affordability-page-error-icon"
                className={`${
                  props.affordabilityErrorIndicator ||
                  affordabilityErrors ||
                  props.creditCardIndicator
                    ? "show-error-indicator"
                    : "hide-error-indicator"
                }`}
              />
              {props.affordabilityValidations &&
                !props.affordabilityErrorIndicator &&
                !affordabilityErrors &&
                !props.creditCardIndicator &&
                props.affordabilityPageVisitCount > 0 && (
                  <FontIcon
                    data-testid="affordability-page-valid-icon"
                    primary={true}
                    className="show-valid-icon"
                  >
                    done
                  </FontIcon>
                )}
            </div>
          </Link>
        </li>
        <li className="footer-devider" />
        {
          // TODO: AMM-1292 on condition basis icon change, and same condition apply on affordability next button
        }
        <li id="review-footer" className={ReviewBtnClass().isEnable ? "disable-menu" : ""}>
          <a onClick={() => onClickReviewButton()}>
            {!ReviewBtnClass().isFormComplete ? <ReviewIcon /> : <ReviewIconSucess />}
            <div className="primary footer-heading">Review</div>
          </a>
        </li>
      </ol>
    </footer>
  );
};

export const mapStateToProps = (state: TAppStateType) => {
  return {
    footerProgressWidth: state.help.footerProgressWidth,
    affordabilityErrorIndicator: state.error.affordabilityErrorIndicator,
    affordabilityPageVisitCount: state.help.affordabilityPageVisitCount,
    affordabilityValidations: state.review.validations.affordability,
    applicantValidations: state.review.validations.applicant,
    applicants: state.applicant.applicants,
    applicantsRequiredField: state.applicant.applicantsRequiredField,
    count: state.help.count,
    creditCardIndicator: state.error.creditCardIndicator,
    incomeSourcesRowAdded: state.applicant.incomeSourcesRowAdded,
    loanErrorIndicator: state.error.loanErrorIndicator,
    loanValidations: state.review.validations.solution,
    objectiveErrorIndicator: state.error.objectiveErrorIndicator,
    objectiveValidations: state.review.validations.objective,
    properties: state.objective.properties,
    showReviewScreen: state.help.showReviewScreen,
    account: state.loan.account,
    doYouRequireCashOut: state.loan.doYouRequireCashOut,
    cashOutNote: state.loan.cashOutNote,
    isApplicantFormNotSubmitted: state.review.isApplicantFormNotSubmitted as boolean,
    applicantState: state.applicant,
    error: state.error,
    totalState: state,
    formInvalidArray: state.review.formInvalidArray,
    footerValidations: state.review.validations,
    formInvalidationArrayForSendQuote: state.review.formInvalidationArrayForSendQuote,
    totalFee: state.review.fundsToCompleteTotal.totalFee,
    fundsToCompleteSum: state.review.validations.fundsToCompleteSum,
    isLRASMetricValid: state.help.isLRASMetricValid,
  };
};

export const mapDispatchToProps = {
  helpSidebarAnimationClassAction,
  helpShowReviewScreenAction,
  fullFormValidationThunk,
  reviewSetValidationAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
