import {
  IApplicantRequiredField,
  IApplicantInitialState,
  IEmploymentObject,
} from "amm-applicant-interface";
import * as applicant from "./applicantActionTypes";

import { moduleConstant } from "../../../constants";
import { IAffordabilityTotalsActionType } from "../../../utility";

export const applicantCurrentTabAction: applicant.ApplicantCurrentTabActionType = (currentTab) => {
  return {
    currentTab,
    module: [moduleConstant.applicant],
    type: applicant.APPLICANT_CURRENT_TAB,
  };
};

export const applicantOverrideCreditScoreAction: applicant.ApplicantOverrideCreditScoreActionType = (
  currentTab
) => {
  return {
    currentTab,
    module: [moduleConstant.applicant],
    type: applicant.APPLICANT_OVERRIDE_CREDIT_SCORE,
  };
};

export const applicantOverrideLoaderAction: applicant.ApplicantOverrideLoaderActionType = (
  overrideLoader,
  currentTab,
  isCreditCheckOverriden?
) => {
  return {
    overrideLoader,
    currentTab,
    isCreditCheckOverriden,
    module: [moduleConstant.applicant],
    type: applicant.APPLICANT_OVERRIDE_LOADER,
  };
};

export const applicantUpdateLivingArragement: applicant.ApplicantUpdateLivingArragementActionType = (
  applicantLivingArrangement: string,
  currentTab: number
) => {
  return {
    applicantLivingArrangement,
    currentTab,
    module: [moduleConstant.applicant],
    type: applicant.APPLICANT_UPDATE_LIVING_ARRAGEMENT,
  };
};

export const applicantAffordabilityChangeTimerAction: applicant.ApplicantAffordabilityChangeTimerActionType = (
  currentTab: number,
  smsResendTimer: number
) => {
  return {
    currentTab,
    smsResendTimer,
    module: [moduleConstant.applicant],
    type: applicant.APPLICANT_CHANGE_RESEND_TIMER,
  };
};

export const affordabilityRentalIncomeUpdateAction: applicant.AffordabilityRentalIncomeUpdateActionType = (
  weeklyRentEstimate,
  operation: string,
  index: number,
  propertiesLength: number
) => {
  return {
    index,
    weeklyRentEstimate,
    module: [moduleConstant.applicant],
    operation,
    propertiesLength,
    type: applicant.AFFORDABILITY_RENTAL_INCOME_UPDATE,
  };
};
export const applicantSetProvisoDataAction: applicant.ApplicantSetProvisoDataActionType = (
  provisoData: any, // Todo fix the provisoData type
  index: number,
  illionBankDataFileName: string,
  key: number
) => {
  return {
    index,
    module: [moduleConstant.applicant],
    provisoData,
    illionBankDataFileName,
    key,
    type: applicant.APPLICANT_SET_PROVISO_DATA,
  };
};

export const applicantNoteValueUpdateAction: applicant.ApplicantNoteValueUpdateActionType = (
  noteValue: string,
  rowName: string,
  index: number
) => {
  return {
    index,
    module: [moduleConstant.applicant],
    noteValue,
    rowName,
    type: applicant.APPLICANT_NOTE_VALUE_UPDATE,
  };
};

export const applicantAddressAutoSuggestAction: applicant.ApplicantAddressAutoSuggestActionType = (
  currentTab,
  addressAutoSuggestions,
  childIndex,
  stateName
) => {
  return {
    addressAutoSuggestions,
    childIndex,
    currentTab,
    stateName,
    module: [moduleConstant.applicant],
    type: applicant.APPLICANT_ADDRESS_AUTO_SUGGEST,
  };
};

export const applicantAddressLoaderAction: applicant.ApplicantAddressLoaderActionType = (
  loader: boolean,
  currentTab: number,
  stateName: string,
  childIndex?: number
) => {
  return {
    childIndex,
    currentTab,
    loader,
    module: [moduleConstant.applicant],
    stateName,
    type: applicant.APPLICANT_ADDRESS_LOADER,
  };
};

export const applicantClearAddressResultAction: applicant.ApplicantClearAddressResultActionType = (
  childIndex: number
) => {
  return {
    childIndex,
    module: [moduleConstant.applicant],
    type: applicant.APPLICANT_CLEAR_ADDRESS_RESULT,
  };
};

export const applicantCopyAddressAction: applicant.ApplicantCopyAddressActionType = (
  childIndex: number,
  stateName: string,
  movedInDateType: string,
  applicantStatusType: string
) => {
  return {
    childIndex,
    stateName,
    movedInDateType,
    applicantStatusType,
    module: [moduleConstant.applicant],
    type: applicant.APPLICANT_COPY_ADDRESS,
  };
};

export const applicantAddRetrieveDataAction: applicant.ApplicantAddRetrieveDataActionType = () => {
  return {
    module: [moduleConstant.applicant],
    type: applicant.APPLICANT_ADD_RETRIEVE_DATA,
  };
};

export const applicantUndoAction: applicant.ApplicantUndoActionType = (
  currentTab: number,
  childIndex: number,
  childArray: string,
  childDeletedArray: string
) => {
  return {
    childArray,
    childDeletedArray,
    childIndex,
    currentTab,
    module: [moduleConstant.applicant],
    type: applicant.APPLICANT_UNDO_ROW,
  };
};

export const applicantRemoveRetrieveDataAction: applicant.ApplicantRemoveRetrieveDataActionType = (
  index: number
) => {
  return {
    index,
    module: [moduleConstant.applicant],
    type: applicant.APPLICANT_REMOVE_RETRIEVE_DATA,
  };
};
export const applicantValidationUpdationAction: applicant.ApplicantValidationUpdationActionType = ({
  childArray,
  childIndex,
  stateName,
  stateValue,
}) => {
  return {
    childArray,
    childIndex,
    module: [moduleConstant.applicant],
    stateName,
    stateValue,
    type: applicant.APPLICANT_VALIDATION_UPDATION,
  };
};
export const applicantStateUpdateAction: applicant.ApplicantStateUpdateActionType = ({
  stateName,
  stateValue,
  childArray,
  childIndex,
  mainState,
  regex,
  required,
  objectName,
}) => {
  return {
    childArray,
    childIndex,
    mainState,
    module: [moduleConstant.applicant],
    objectName,
    regex,
    required,
    stateName,
    stateValue,
    type: applicant.APPLICANT_STATE_UPDATE,
  };
};

export const applicantUpdateValueForUncategorisedAction: applicant.ApplicantUpdateValueForUncategorisedActionType = (
  changedTo: string,
  childIndex: number,
  changedFrom: string
) => {
  return {
    childIndex,
    changedTo,
    changedFrom,
    module: [moduleConstant.applicant],
    type: applicant.APPLICANT_UPDATE_VALUE_FOR_UNCATEGORISED,
  };
};

export const applicantReferralCodeUpdateAction: applicant.ApplicantReferralCodeUpdateActionType = ({
  stateName,
  stateValue,
}) => {
  return {
    stateName,
    stateValue,
    module: [moduleConstant.applicant],
    type: applicant.APPLICANT_REFERRAL_CODE_UPDATE,
  };
};

export const applicantGetPropertyAction: applicant.ApplicantGetPropertyActionType = ({
  property,
  currentTab,
  childArray,
  childIndex,
}) => {
  return {
    childArray,
    childIndex,
    currentTab,
    module: [moduleConstant.applicant],
    property,
    type: applicant.APPLICANT_GET_PROPERTY,
  };
};

export const applicantAddNewAction: applicant.ApplicantAddNewActionType = (
  childArrayName?: string,
  index?: number,
  value?: IEmploymentObject
) => {
  if (childArrayName) {
    return {
      childArrayName,
      index,
      value,
      module: [moduleConstant.applicant],
      type: applicant.APPLICANT_ADD_NEW_ITEM,
    };
  }
  return {
    childArrayName,
    module: [moduleConstant.applicant],
    type: applicant.APPLICANT_ADD_NEW,
  };
};

export const setProbationWarningAction: applicant.ApplicantSetProbationWarningActionType = (
  childArrayName: string,
  value: boolean,
  currentTab?: number
) => {
  return {
    childArrayName,
    currentTab,
    value,
    module: [moduleConstant.applicant],
    type: applicant.APPLICANT_SET_PROBATION_WARNING,
  };
};

export const applicantRemoveAction: applicant.ApplicantRemoveActionType = (
  index: number,
  childArrayName?: string,
  childDeletedArrayName?: string
) => {
  if (childArrayName) {
    return {
      childArrayName,
      childDeletedArrayName,
      module: [moduleConstant.applicant],
      removeIndex: index,
      type: applicant.APPLICANT_REMOVE_ITEM,
    };
  }
  return {
    childArrayName,
    childDeletedArrayName,
    module: [moduleConstant.applicant],
    removeIndex: index,
    type: applicant.APPLICANT_REMOVE,
  };
};

export const applicantClearDocumentIdAction: applicant.ApplicantClearDocumentIdActionType = (
  currentTab,
  replaceIllionData
) => {
  return {
    currentTab,
    replaceIllionData,
    module: [moduleConstant.applicant],
    type: applicant.APPLICANT_CLEAR_DOCUMENT_ID,
  };
};

export const applicantClearAffordabilityPageData: applicant.ApplicantClearAffordabilityPageDataActionType = (
  currentTab: number
) => {
  return {
    currentTab,
    module: [moduleConstant.applicant],
    type: applicant.APPLICANT_CLEAR_APPLICANT_PAGE_DATA,
  };
};

export const applicantShowLoaderAction: applicant.ApplicantShowLoaderActionType = (
  index,
  childIndex
) => {
  return {
    childIndex,
    index,
    module: [moduleConstant.applicant],
    type: applicant.APPLICANT_SHOW_LOADER,
  };
};

export const applicantSendOtpLoaderAction: applicant.ApplicantSendOtpLoaderActionType = (
  sendOtpLoader: boolean
) => {
  return {
    module: [moduleConstant.applicant],
    sendOtpLoader,
    type: applicant.APPLICANT_SEND_OTP_LOADER,
  };
};

export const applicantConfirmOtpLoaderAction: applicant.ApplicantConfirmOtpLoaderActionType = (
  confirmOtpLoader
) => {
  return {
    confirmOtpLoader,
    module: [moduleConstant.applicant],
    type: applicant.APPLICANT_CONFIRM_OTP_LOADER,
  };
};
export const applicantIsOTPSentAction: applicant.ApplicantIsOTPSentActionType = (isOtpSent) => {
  return {
    isOtpSent,
    module: [moduleConstant.applicant],
    type: applicant.APPLICANT_IS_OTP_SENT,
  };
};
export const applicantOtpConfirmedAction: applicant.ApplicantOtpConfirmedActionType = (
  isNumberConfirmed: boolean
) => {
  return {
    isNumberConfirmed,
    module: [moduleConstant.applicant],
    type: applicant.APPLICANT_CONFIRM_OTP_NUMBER,
  };
};

export const applicantAddNoteForRentalIncomeAction: applicant.ApplicantAddNoteForRentalIncomeType = (
  applicantIndex: number,
  rowIndex: number
) => {
  return {
    applicantIndex,
    rowIndex,
    module: [moduleConstant.applicant],
    type: applicant.APPLICANT_ADD_NOTE_FOR_RENTAL_INCOME,
  };
};

export const applicantCreditCheckDoneManuallyChange: applicant.ApplicantCreditCheckDoneManuallyChangeType = (
  applicantIndex: number
) => {
  return {
    applicantIndex,
    module: [moduleConstant.applicant],
    type: applicant.APPLICANT_CREDIT_CHECK_DONE_MANUALLY,
  };
};
export const applicantFormLoaderAction: applicant.ApplicantFormLoaderActionType = (
  formLoader: boolean
) => {
  return {
    formLoader,
    module: [moduleConstant.applicant],
    type: applicant.APPLICANT_FORM_LOADER,
  };
};

export const applicantHideLoaderAction: applicant.ApplicantHideLoaderActionType = (
  index,
  childIndex
) => {
  return {
    childIndex,
    index,
    module: [moduleConstant.applicant],
    type: applicant.APPLICANT_HIDE_LOADER,
  };
};

export const applicantModalOpenAction: applicant.ApplicantModalOpenActionType = (
  noteInformation
) => {
  return {
    module: [moduleConstant.applicant],
    noteInformation,
    type: applicant.APPLICANT_MODAL_OPEN,
  };
};

export const applicantModalCloseAction: applicant.ApplicantModalCloseActionType = (
  buttonClicked: string,
  rowType: string,
  currentTab: number
) => {
  return {
    buttonClicked,
    currentTab,
    module: [moduleConstant.applicant],
    rowType,
    type: applicant.APPLICANT_MODAL_CLOSE,
  };
};

export const applicantLoadEntireStateAction: applicant.ApplicantLoadEntireStateActionType = (
  state: IApplicantInitialState
) => {
  return {
    module: [moduleConstant.applicant],
    state,
    type: applicant.APPLICANT_LOAD_STATE,
  };
};

export const applicantSetCreditCardTextErrorAction: applicant.ApplicantSetCreditCardTextErrorActionType = (
  currentIndex: number,
  isError: boolean
) => {
  return {
    currentIndex,
    isError,
    module: [moduleConstant.applicant],
    type: applicant.APPLICANT_CREDIT_CARD_TEXT_ERROR,
  };
};

export const applicantValidateApplicantFieldsAction: applicant.ApplicantValidateApplicantFieldsActionType = (
  applicantsRequiredField: IApplicantRequiredField[]
) => {
  return {
    applicantsRequiredField,
    module: [moduleConstant.applicant],
    type: applicant.APPLICANT_VALIDATE_APPLICANT_FIELD,
  };
};

export const applicantGetCreditScoreAndVerifyIdentityAction: applicant.ApplicantGetCreditScoreAndVerifyIdentityActionType = (result: {
  currentTab: number;
  creditScore: number;
  idsMatrix: boolean;
  verifyBtnText?: string;
  isDocumentVerified?: boolean;
  formSubmit: boolean;
  isCreditCheckOverriden: boolean;
  isCreditCheckDoneManually: boolean;
}) => {
  return {
    creditScore: result.creditScore,
    currentTab: result.currentTab,
    formSubmit: result.formSubmit,
    idsMatrix: result.idsMatrix,
    isDocumentVerified: result.isDocumentVerified,
    module: [moduleConstant.applicant],
    type: applicant.APPLICANT_GET_CREDIT_SCORE_AND_VERIFY_IDENTITY,
    verifyBtnText: result.verifyBtnText,
    isCreditCheckOverriden: result.isCreditCheckOverriden,
    isCreditCheckDoneManually: result.isCreditCheckDoneManually,
  };
};

export const applicantGetOtpAction: applicant.ApplicantGetOtpActionType = (mobileNumber) => {
  return {
    mobileNumber,
    module: [moduleConstant.applicant],
    type: applicant.APPLICANT_GET_OTP,
  };
};

export const applicantCopyToAllAction: applicant.ApplicantCopyToAllActionType = (
  currentTab: number,
  currentIndex: number
) => {
  return {
    currentIndex,
    currentTab,
    module: [moduleConstant.applicant],
    type: applicant.APPLICANT_COPY_TO_ALL,
  };
};

export const applicantAffordabilityClearTableAction: applicant.ApplicantAffordabilityClearTableActionType = (
  currentTab: number,
  clearTable: boolean
) => {
  return {
    currentTab,
    module: [moduleConstant.applicant],
    clearTable,
    type: applicant.APPLICANT_AFFORDABILITY_CLEAR_TABLE,
  };
};

export const applicantSendSMSAction: applicant.ApplicantSendSMSLoaderActionType = (
  smsLoaderShow,
  smsResendTimer,
  currentTab,
  smsSent
) => {
  return {
    smsLoaderShow,
    smsResendTimer,
    currentTab,
    smsSent,
    module: [moduleConstant.applicant],
    type: applicant.APPLICANT_SEND_SMS_LOADER,
  };
};

export const applicantAutoPopulateNoteAction: applicant.ApplicantAutoPopulateNoteActionType = (
  stateName,
  stateValue,
  currentTab,
  childIndex,
  note
) => {
  return {
    stateName,
    stateValue,
    currentTab,
    childIndex,
    note,
    module: [moduleConstant.applicant],
    type: applicant.APPLICANT_AUTO_POPULATE_NOTE,
  };
};

export const applicantSetEmailDuplicateAction: applicant.ApplicantSetEmailDuplicateActiontype = (
  index,
  isEmailDuplicate
) => {
  return {
    index,
    isEmailDuplicate,
    module: [moduleConstant.applicant],
    type: applicant.APPLICANT_SET_EMAIL_DUPLICATE,
  };
};

export const applicantSetMobilePhoneDuplicateAction: applicant.ApplicantSetMobilePhoneDuplicateActiontype = (
  index,
  isMobilePhoneDuplicate
) => {
  return {
    index,
    isMobilePhoneDuplicate,
    module: [moduleConstant.applicant],
    type: applicant.APPLICANT_SET_MOBILE_PHONE_DUPLICATE,
  };
};

export const applicantAddNoteAction: applicant.ApplicantAddNoteActionType = (
  note: string,
  stateName: applicant.NOTE_STATES,
  currentTab: number
) => {
  return {
    note,
    stateName,
    currentTab,
    module: [moduleConstant.applicant],
    type: applicant.APPLICANT_ADD_NOTE,
  };
};
export const applicantSetCurrentTabZeroAction: applicant.ApplicantSetCurrentTabZeroActionType = () => {
  return {
    module: [moduleConstant.applicant],
    type: applicant.APPLICANT_SET_CURRENT_TAB_ZERO,
  };
};

export const applicantStatusRentUpdateAction: applicant.ApplicantStatusRentUpdateActionType = ({
  index,
  operation,
  rent,
  howManyApplicant,
}) => {
  return {
    type: applicant.APPLICANT_STATUS_RENT_UPDATE,
    module: [moduleConstant.applicant],
    rent,
    operation,
    index,
    howManyApplicant,
  };
};
export const applicantPrimaryRequiredFieldsUpdateAction: applicant.ApplicantPrimaryRequiredFieldsUpdateType = ({
  fieldName,
  count,
}) => {
  return {
    type: applicant.APPLICANT_REQUIRED_FIELDS_UPDATE,
    module: [moduleConstant.applicant],
    fieldName,
    count,
  };
};

export const applicantRequiredFieldsCheckAction: applicant.ApplicantRequiredFieldsCheckType = (
  loanTerm?: number
) => {
  return {
    loanTerm,
    type: applicant.APPLICANT_REQUIRED_FIELDS_CHECK,
    module: [moduleConstant.applicant],
  };
};

export const applicantEmploymentIncomeSourceAction: applicant.ApplicantEmploymentIncomeSourceActionType = (
  employmentType: string
) => {
  return {
    employmentType,
    type: applicant.APPLICANT_EMPLOYMENT_INCOME_SOURCE,
    module: [moduleConstant.applicant],
  };
};

export const applicantSetProvisoDataIsNewAction: applicant.ApplicantSetProvisoDataIsNewActionType = (
  isNew: boolean,
  index: number
) => {
  return {
    isNew,
    index,
    type: applicant.APPLICANT_SET_PROVISO_DATA_IS_NEW,
    module: [moduleConstant.applicant],
  };
};

export const applicantAffordabilityTotalsUpdateAction: applicant.ApplicantAffordabilityTotalsUpdateActionType = ({
  rowType,
  currentTab,
  duration,
  total,
  taxStatus,
}: IAffordabilityTotalsActionType) => {
  return {
    rowType,
    duration,
    total,
    currentTab,
    taxStatus,
    type: applicant.APPLICANT_AFFORDABILITY_TOTAL_UPDATE,
    module: [moduleConstant.applicant],
  };
};
