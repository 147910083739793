import React from "react";

const CreditReviewIcon = (props: { className?: string }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 18"
    className={props.className ? props.className : "credit-review-icon"}
    height="24" width="26">
      <path d="M21.52,4l-13,13-6-6L0,13.51,8.49,22,24,6.49Z" transform="translate(0 -4)" />
    </svg>
  );
};

export default CreditReviewIcon;
