import axios, { AxiosResponse } from "axios";
import { userProfileApi, userPermissionsApi, rolesExcludedForUsersPanel } from "../../constants";
import { errorGenericErrorHandleAction } from "../actions/errorActions/errorAction";
import {
  loginSetUserPermissionsAction,
  loginSetCurrentUserStateAction,
  loginUserProfileAction,
  loginUserPermissionsAction,
  loginSetTeamMemberScenarioAction,
} from "../actions/login/loginAction";
import {
  scenarioListAction,
  scenarioLoaderAction,
} from "../actions/scenarioActions/scenarioActions";
import { profileFetchAction } from "../../constants/userActions";
import { IScenarioPayload, IScenarioPayloadUser } from "amm-scenario-interface";
import { ThunkAction } from "redux-thunk";
import { Action } from "redux";
import { IBrokerListType } from "amm-admin-interface";
import {
  adminAddNotifications,
  adminAddOriginators,
  adminSetBrokerListAction,
} from "../actions/adminActions/adminActions";
import { brandingAddBrandName } from "../actions/brandingActions/brandingActions";
import { getCSSThunk } from "./scenarioThunk/getCssThunk";
import Auth from "@aws-amplify/auth";
import { CognitoUserSession } from "amazon-cognito-identity-js";

interface IPayload {
  payload: IScenarioPayload;
}

export type ProfilethunkType = (
  token: string,
  editTeamPermission?: boolean
) => ThunkAction<any, any, any, Action>;

export const profileThunk: ProfilethunkType = (
  token: string,
  editTeamPermission?: boolean
): ThunkAction<any, any, any, Action> => {
  // TODO make this generic as will be used a lot of times
  return async (dispatch) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        timedata: Date.now(),
      },
    };

    try {
      dispatch(scenarioLoaderAction(true));
      Auth.currentSession().then((currentSession: CognitoUserSession) => {
        if (currentSession.getIdToken().payload["custom:originator"]) {
          dispatch(getCSSThunk(currentSession.getIdToken().payload["custom:originator"]));
        }
      });

      const response = await axios.get(userPermissionsApi, config);
      dispatch(loginUserPermissionsAction(response.data.payload.userPermissions));
      dispatch(loginSetUserPermissionsAction(response.data.payload.userPermissions));

      const res: AxiosResponse<IPayload> = await axios.get(userProfileApi, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          timedata: Date.now(),
          user_action: profileFetchAction[0],
        },
      });
      let filteredData: any[] = [];
      if (res.data.payload.allUsers.length) {
        filteredData = res.data.payload.allUsers.filter((user: IScenarioPayloadUser) => {
          if (!rolesExcludedForUsersPanel.includes(user.roles[0].name)) {
            return user;
          } else {
            return;
          }
        });
      }

      const filteredBrokerList: IBrokerListType[] = filteredData.map(
        (broker: IScenarioPayloadUser) => ({
          id: broker.id!,
          firstName: broker.first_name,
          lastName: broker.last_name,
          email: broker.email,
          mobile: broker.mobile,
          status: broker.status,
          role: broker.roles[0].name,
        })
      );

      filteredBrokerList.push({
        id: res.data.payload.user.id,
        firstName: res.data.payload.user.first_name,
        lastName: res.data.payload.user.last_name,
        email: res.data.payload.user.email,
        mobile: res.data.payload.user.mobile,
        status: res.data.payload.user.status,
        role: res.data.payload.user.roles[0].name,
      });

      filteredBrokerList.sort((a, b) => a.id - b.id);
      dispatch(adminSetBrokerListAction(filteredBrokerList, "teams"));

      dispatch(loginUserProfileAction(res.data.payload));
      dispatch(scenarioListAction(res.data.payload));
      dispatch(loginSetCurrentUserStateAction(res.data.payload.user));
      if (editTeamPermission) dispatch(loginSetTeamMemberScenarioAction(true));

      dispatch(adminAddOriginators(res.data.payload.originatorsForThisInstance));
      dispatch(adminAddNotifications(res.data.payload.notificationsList));
      dispatch(brandingAddBrandName(res.data.payload.user.originator.domain_name));
      dispatch(scenarioLoaderAction(false));
    } catch (error) {
      if (error instanceof Error) {
        dispatch(errorGenericErrorHandleAction(error.message));
      }
      dispatch(scenarioLoaderAction(false));
    }
  };
};
