import { IEditBrokerState, IEditBrokerInitialState, IUserNotification } from "amm-admin-interface";
import { brokerStatus, rolesExcludedForTeams } from "../../../constants";
import { AdminSetBrokerListAction } from "../../actions/adminActions/adminActionTypes";

export const setBrokerSaveValues = (brokerState: IEditBrokerState, isNew: boolean) => {
  const brokerNewState: IEditBrokerState = Object.assign(brokerState);

  if (brokerNewState.partialEmail !== brokerNewState.email) {
    brokerNewState.partialEmail = brokerNewState.email;
  }
  if (brokerNewState.partialFirstName !== brokerNewState.firstName) {
    brokerNewState.partialFirstName = brokerNewState.firstName;
  }
  if (brokerNewState.partialLastName !== brokerNewState.lastName) {
    brokerNewState.partialLastName = brokerNewState.lastName;
  }
  if (brokerNewState.partialMiddleName !== brokerNewState.middleName) {
    brokerNewState.partialMiddleName = brokerNewState.middleName;
  }
  if (brokerNewState.partialStatus !== brokerNewState.status) {
    brokerNewState.partialStatus = brokerNewState.status;
  }

  return (brokerState = brokerNewState);
};

export const clearBrokerPartialValues = (brokerState: IEditBrokerState) => {
  const brokerNewState: IEditBrokerState = Object.assign(brokerState);

  if (brokerNewState.partialEmail !== brokerNewState.email) {
    brokerNewState.email = brokerNewState.partialEmail;
  }
  if (brokerNewState.partialFirstName !== brokerNewState.firstName) {
    brokerNewState.firstName = brokerNewState.partialFirstName;
  }
  if (brokerNewState.partialLastName !== brokerNewState.lastName) {
    brokerNewState.lastName = brokerNewState.partialLastName;
  }
  if (brokerNewState.partialMiddleName !== brokerNewState.middleName) {
    brokerNewState.middleName = brokerNewState.partialMiddleName;
  }
  if (brokerNewState.partialStatus !== brokerNewState.status) {
    brokerNewState.status = brokerNewState.partialStatus;
  }
  return (brokerState = brokerNewState);
};

export const setBrokerStateValues = (
  state: IEditBrokerState,
  brokerState: IEditBrokerState,
  isNew: boolean,
  userNotificationsList?: IUserNotification[]
) => {
  if (isNew) {
    brokerState.createdAt = "";
    brokerState.email = "";
    brokerState.firstName = "";
    brokerState.phone = "";
    brokerState.id = 0;
    brokerState.lastLogin = "";
    brokerState.lastName = "";
    brokerState.middleName = "";
    brokerState.mobile = "";
    brokerState.noOfScenarios = 0;
    brokerState.partialEmail = "";
    brokerState.partialFirstName = "";
    brokerState.partialPhone = "";
    brokerState.partialLastName = "";
    brokerState.partialMiddleName = "";
    brokerState.partialMobile = "";
    brokerState.provisoReferralCode = "";
    brokerState.organisationFullName = "";
    brokerState.organisationLegalName = "";
    brokerState.brokerPartyID = "";
    brokerState.originatorPartyID = "";
    brokerState.originatorID = 1;
    brokerState.partialStatus = brokerStatus[0];
    brokerState.status = brokerStatus[0];
    brokerState.title = "";
    brokerState.cognitoId = "";
    brokerState.roleId = -1;
    brokerState.provisoSMSLink = "";
    brokerState.uniqueUserID = "";
    brokerState.userNotifications = userNotificationsList ? userNotificationsList : [];
  } else {
    brokerState.cognitoId = state.cognitoId;
    brokerState.email = state.email;
    brokerState.partialEmail = state.email;
    brokerState.firstName = state.firstName;
    brokerState.phone = state.phone;
    brokerState.middleName = state.middleName;
    brokerState.id = state.id;
    brokerState.lastName = state.lastName;
    brokerState.mobile = state.mobile;
    brokerState.status = state.status;
    brokerState.title = state.title;
    brokerState.partialFirstName = state.firstName;
    brokerState.partialPhone = state.phone;
    brokerState.partialLastName = state.lastName;
    brokerState.partialMiddleName = state.middleName;
    brokerState.partialMobile = state.mobile;
    brokerState.partialStatus = state.status;
    // Todo fix the type
    // @ts-ignore
    brokerState.provisoSMSLink = state.provisoSmsLink ? state.provisoSmsLink : "";
    brokerState.provisoReferralCode = state.provisoReferralCode ? state.provisoReferralCode : "";
    brokerState.organisationFullName = state.organisationFullName ? state.organisationFullName : "";
    brokerState.organisationLegalName = state.organisationLegalName
      ? state.organisationLegalName
      : "";
    brokerState.brokerPartyID = state.brokerPartyID ? state.brokerPartyID : "";
    brokerState.uniqueUserID = state.uniqueUserID ? state.uniqueUserID : "";
    brokerState.originatorPartyID = state.originatorPartyID ? state.originatorPartyID : "";
    brokerState.originatorID = state.originatorID ? state.originatorID : 1;
    brokerState.createdAt = state.createdAt;
    brokerState.lastLogin = state.lastLogin;
    brokerState.noOfScenarios = state.noOfScenarios;
    brokerState.roleId = state.roleId;
    brokerState.userNotifications = state.userNotifications;
  }

  return brokerState;
};

export const setBrokerList = (action: AdminSetBrokerListAction, state: IEditBrokerInitialState) => {
  const currentState = Object.assign({}, state);
  if (action.brokerList.length > 0) {
    currentState.brokerList = action.brokerList;
    currentState.newTeam.allTeamMembers = action.brokerList.filter(
      (teamMember) => teamMember.role && !rolesExcludedForTeams.includes(teamMember.role)
    );
  }
  return {
    ...currentState,
  };
};
