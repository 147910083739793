import React, { useEffect } from "react";
import { DataTable, TableBody, TableColumn, TableHeader, TableRow } from "react-md";
import { connect } from "react-redux";
import { reviewSaveFundsToCompleteAction } from "../../store/actions/reviewActions/reviewActions";
import { loanAccounts } from "../../utility";
import { FundsToComplete, IPartialProperty } from "./fundsToComplete";
import { IAccount } from "amm-solution-interface";
import { IApplicant } from "amm-applicant-interface";
import { ReviewSaveFundsToCompleteActionType } from "../../store/actions/reviewActions/reviewActionTypes";
import { HelpFundsToCompleteActionType } from "../../store/actions/helpActions/helpActionType";
import { helpFundsToCompleteAction } from "../../store/actions/helpActions/helpAction";
import { TAppStateType } from "../../store/reducers/reducers";
import { IPropertyArray } from "amm-objective-interface";
import { accountTypeOptions, interestTypeOptions } from "../../constants";

interface StateProps {
  account: IAccount[];
  applicants: IApplicant[];
  currentTab: number;
  depositAmount: number;
  loanAmount: number;
  loanTermYear: number;
  monthlyPaymentsPerAccount: number[];
  properties: IPropertyArray[];
  isReviewScreen: boolean;
  isAffordabilityScreen: boolean;
  interestRatePerAccount: { [key: string]: number };
}

interface DispatchProps {
  reviewSaveFundsToCompleteAction: ReviewSaveFundsToCompleteActionType;
  helpFundsToCompleteAction: HelpFundsToCompleteActionType;
}

export type AccountSummaryProps = StateProps & DispatchProps;

export const AccountSummary: React.FC<AccountSummaryProps> = (props: AccountSummaryProps) => {
  useEffect(() => {
    props.helpFundsToCompleteAction(true, false);
  }, [props.loanAmount, props.properties, props.applicants]);

  const loanAccount = loanAccounts(props.account);
  const linkedIndices: String[] = new Array();
  const partialProperty = props.properties as IPartialProperty[];

  loanAccount.forEach((account, index) => {
    if (account.isOffsetRequired) {
      linkedIndices.push(
        account.accountName ? account.accountName : `Account ${(index + 1).toString()}`
      );
    }
  });

  const invalidClassName = (amount: number) => {
    return amount === 0 ? "invalid" : "";
  };

  const listItems = loanAccount.map((item, index) => {
    const interestRatePerAccount =
      props.interestRatePerAccount && props.interestRatePerAccount[item.accountId]
        ? (props.interestRatePerAccount[item.accountId] * 100).toPrecision(3)
        : 0;

    const iORepaymentType = `${accountTypeOptions[1].label} (${item.interestOnlyTerm} yr)`;

    const repaymentType =
      item.accountType === accountTypeOptions[1].value &&
      item.interestType === interestTypeOptions[1].label
        ? iORepaymentType
        : item.accountType === accountTypeOptions[0].value
        ? accountTypeOptions[0].label
        : iORepaymentType;

    return (
      <TableRow
        selectable={false}
        key={index}
        className={`${invalidClassName(item.amount ? item.amount : 0)}`}
      >
        <TableColumn>
          {item.accountName && !item.isInValidAccountName
            ? item.accountName
            : `Account ${index + 1}`}
        </TableColumn>

        <TableColumn>{item.accountPurpose}</TableColumn>
        <TableColumn>{repaymentType}</TableColumn>
        <TableColumn>
          {item.interestType}{" "}
          {item.interestType === interestTypeOptions[0].value ? "" : `(${item.fixedTerm} yr)`}
        </TableColumn>
        <TableColumn className="md-text-right">{interestRatePerAccount}%</TableColumn>
        <TableColumn className="md-text-right">
          ${item.amount ? item.amount.toLocaleString("en") : 0}
        </TableColumn>
        <TableColumn className="md-text-right">
          $
          {(props.monthlyPaymentsPerAccount[index]
            ? parseInt(props.monthlyPaymentsPerAccount[index].toFixed(2))
            : 0.0
          ).toLocaleString("en")}{" "}
          p/m {/*  //from lras */}
        </TableColumn>
        <TableColumn className="md-text-right">{props.loanTermYear} yr</TableColumn>
      </TableRow>
    );
  });

  return (
    <React.Fragment>
      <h3 className="md-text--theme-secondary">ACCOUNTS</h3>
      <DataTable plain={true} className="amm--table review-account--table group-row">
        <TableHeader>
          <TableRow selectable={false}>
            <TableColumn className="w-200">NAME</TableColumn>
            <TableColumn>PURPOSE</TableColumn>
            <TableColumn>REPAYMENT TYPE</TableColumn>
            <TableColumn>RATE TYPE</TableColumn>
            <TableColumn className="md-text-right">INTEREST RATE</TableColumn>
            <TableColumn className="md-text-right">AMOUNT</TableColumn>
            <TableColumn className="md-text-right">REPAYMENTS</TableColumn>
            <TableColumn className="md-text-right">TERM</TableColumn>
          </TableRow>
        </TableHeader>
        <TableBody>{listItems}</TableBody>
      </DataTable>

      {linkedIndices.length >= 1 && (
        <>
          <h6 className="heading-6-width-border">OFFSET FACILITY</h6>
          <div className="offset-facility-section group-row">
            <DataTable plain={true} className="amm--table">
              <TableBody>
                {linkedIndices.map((item, index) => {
                  return (
                    <TableRow selectable={false} key={index}>
                      <TableColumn className="w-200">Offset Account {index + 1}</TableColumn>
                      <TableColumn>Linked to {linkedIndices[index]}</TableColumn>
                    </TableRow>
                  );
                })}
              </TableBody>
            </DataTable>
          </div>
        </>
      )}

      {props.isReviewScreen && (
        <FundsToComplete
          properties={partialProperty}
          applicants={props.applicants}
          loanAmount={props.loanAmount}
        />
      )}
    </React.Fragment>
  );
};

export const mapStateToProps = (state: TAppStateType) => {
  return {
    account: state.loan.account,
    applicants: state.applicant.applicants,
    currentTab: state.objective.currentTab,
    depositAmount: state.loan.depositAmount,
    loanAmount: state.loan.loanAmount,
    loanTermYear: state.loan.loanTermYear,
    monthlyPaymentsPerAccount: state.help.lras.monthlyPaymentsPerAccount,
    properties: state.objective.properties,
    isReviewScreen: state.help.showReviewScreen,
    isAffordabilityScreen: state.help.showAffordabilityScreen,
    interestRatePerAccount: state.help.lras.lrasOutputs.rateVariablePerAccount,
  };
};

export const mapDispatchToProps = {
  reviewSaveFundsToCompleteAction,
  helpFundsToCompleteAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountSummary);
