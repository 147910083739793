import { TAppStateType } from "../../reducers/reducers";

export const ERROR_GENERIC_ERROR_HANDLE = "ERROR_GENERIC_ERROR_HANDLE";
export const ERROR_SNACKBAR_CLOSE = "ERROR_SNACKBAR_CLOSE";
export const ERROR_FORM_TRACKER = "ERROR_FORM_TRACKER";
export const ERROR_LOAD_ENTIRE_STATE = "ERROR_LOAD_ENTIRE_STATE";
export const ERROR_CREDIT_CARD_INDICATOR = "ERROR_CREDIT_CARD_INDICATOR";

export interface ErrorGenericErrorHandleAction {
  type: "ERROR_GENERIC_ERROR_HANDLE";
  module: string[];
  errorMessage: string;
}

export interface ErrorSnackbarCloseAction {
  type: "ERROR_SNACKBAR_CLOSE";
  module: string[];
}

export interface ErrorFormTrackerAction {
  type: "ERROR_FORM_TRACKER";
  module: string[];
  page: string;
  section: string;
  location: string;
  status: boolean;
  description?: string;
}

export interface ErrorLoadEntireStateAction {
  type: "ERROR_LOAD_ENTIRE_STATE";
  module: string[];
  state: TAppStateType["error"];
  isCashOutError: boolean;
}

export interface ErrorCreditCardIndicatorAction {
  module: string[];
  type: "ERROR_CREDIT_CARD_INDICATOR";
  value: boolean;
}

export type ErrorActions =
  | ErrorGenericErrorHandleAction
  | ErrorCreditCardIndicatorAction
  | ErrorFormTrackerAction
  | ErrorLoadEntireStateAction
  | ErrorSnackbarCloseAction;

export type ErrorGenericErrorHandleActionType = (
  errorMessage: string
) => ErrorGenericErrorHandleAction;

export type ErrorCreditCardIndicatorActionType = (value: boolean) => ErrorCreditCardIndicatorAction;

export type ErrorSnackbarCloseActionType = () => ErrorSnackbarCloseAction;

export type ErrorFormTrackerActionType = (
  page: string,
  section: string,
  location: string,
  status: boolean,
  description?: string
) => ErrorFormTrackerAction;

export type ErrorLoadEntireStateActionType = (
  state: TAppStateType["error"],
  isCashOutError: boolean
) => ErrorLoadEntireStateAction;
