import {
  LOGIN_ACTIVE_USER_FOR_SCENARIO,
  LOGIN_HISTORY_MODAL_CLOSE,
  LOGIN_HISTORY_MODAL_OPEN,
  LOGIN_IS_AUTHENTICATION_CODE_SENT,
  LOGIN_IS_NEW_USER,
  LOGIN_SET_USER_PERMISSIONS,
  LOGIN_IS_VERIFICATION_CODE_SENT,
  LOGIN_SET_CURRENT_USER_STATE,
  LOGIN_SET_SETTINGS_TAB_VALUE,
  LOGIN_USER_LOGIN,
  LOGIN_USER_LOGOUT,
  LOGIN_USER_PROFILE,
  LOGIN_SET_TEAM_MEMBERS_ACTION,
  LOGIN_GET_TEAM_MEMBER_SCENARIO_ACTION,
  LoginUserLogoutActionType,
  LoginUserLoginActionType,
  LoginISNewUserActionType,
  LoginIsAuthenticationCodeSentActionType,
  LoginIsVerificationCodeSentActionType,
  LoginSetUserPermissionsActionType,
  LoginSetSettingTabValueActionType,
  LoginUserProfileActionType,
  LoginActiveUserForScenarioActionType,
  LoginHistoryModalOpenActionType,
  LoginHistoryModalCloseActionType,
  LoginSetCurrentUserStateActionType,
  LoginUserPermissionsActionType,
  LOGIN_USER_PERMISSIONS,
  LoginSetTeamsActionType,
  LOGIN_SET_TEAMS_ACTION,
  LoginSetTeamMembersActionType,
  LoginGetSelectedUserScenariosActionType,
  LOGIN_GET_SELECTED_USER_SCENARIO_ACTION,
  LoginGetTeamMemberScenarioActionType,
  LoginSetCurrentTeamActionType,
  LOGIN_SET_CURRENT_TEAM_ACTION,
  LoginTeamLoaderActionType,
  LOGIN_TEAM_LOADER_ACTION,
  LoginSetTeamMemberScenarioActionType,
  LOGIN_SET_TEAM_MEMBER_SCENARIO_ACTION,
  LOGIN_SET_BREAD_CRUMBS_VALUE,
  LOGIN_REMOVE_BREAD_CRUMBS_ELEMENT,
  LOGIN_CLEAR_BREAD_CRUMBS_VALUE,
  LoginSetBreadCrumbsValueActionType,
  LoginRemoveBreadCrumbsElementActionType,
  LoginClearBreadCrumbsValueActionType,
  LOGIN_CAPITAL_STATUS_UPDATE,
  LoginCapitalStatusScenarioUpdateType,
} from "./loginActionType";

import { moduleConstant } from "../../../constants";
import { IPermissionsObject, ITeam, ITeamMembers } from "amm-admin-interface";
import { IScenarioPayload, IScenarioPayloadUserScenarios } from "amm-scenario-interface";
import { BreadCrumbObject } from "../../reducers/loginReducers/loginReducer";

export const loginUserLogoutAction: LoginUserLogoutActionType = () => {
  return {
    isLogin: false,
    module: Object.values(moduleConstant),
    type: LOGIN_USER_LOGOUT,
  };
};

export const loginUserLoginAction: LoginUserLoginActionType = (token: string, isLogin: boolean) => {
  return {
    isLogin,
    module: [moduleConstant.login],
    token,
    type: LOGIN_USER_LOGIN,
  };
};

export const loginISNewUserAction: LoginISNewUserActionType = (isNew: boolean, user) => {
  return {
    isNew,
    module: [moduleConstant.login],
    type: LOGIN_IS_NEW_USER,
    user,
  };
};

export const loginIsVerificationCodeSentAction: LoginIsVerificationCodeSentActionType = (
  isCodeSent: boolean,
  user
) => {
  return {
    isCodeSent,
    module: [moduleConstant.login],
    type: LOGIN_IS_VERIFICATION_CODE_SENT,
    user,
  };
};

export const loginIsAuthenticationCodeSentAction: LoginIsAuthenticationCodeSentActionType = (
  isCodeSent: boolean,
  user
) => {
  return {
    isCodeSent,
    module: [moduleConstant.login],
    type: LOGIN_IS_AUTHENTICATION_CODE_SENT,
    user,
  };
};

export const loginSetUserPermissionsAction: LoginSetUserPermissionsActionType = (
  data: IPermissionsObject[]
) => {
  return {
    data,
    module: [moduleConstant.login],
    type: LOGIN_SET_USER_PERMISSIONS,
  };
};

export const loginSetSettingTabValueAction: LoginSetSettingTabValueActionType = (key, value) => {
  return {
    key,
    module: [moduleConstant.login],
    type: LOGIN_SET_SETTINGS_TAB_VALUE,
    value,
  };
};

export const loginSetCurrentUserStateAction: LoginSetCurrentUserStateActionType = (data) => {
  return {
    data,
    module: [moduleConstant.login],
    type: LOGIN_SET_CURRENT_USER_STATE,
  };
};

export const loginSetTeamMemberScenarioAction: LoginSetTeamMemberScenarioActionType = (
  setCurrentTeamMemberScenarios: boolean
) => {
  return {
    setCurrentTeamMemberScenarios,
    module: [moduleConstant.login],
    type: LOGIN_SET_TEAM_MEMBER_SCENARIO_ACTION,
  };
};

export const loginUserPermissionsAction: LoginUserPermissionsActionType = (
  userPermissions: IPermissionsObject[]
) => {
  return {
    module: [moduleConstant.login],
    userPermissions,
    type: LOGIN_USER_PERMISSIONS,
  };
};


export const loginCapitalStatusScenarioUpdate: LoginCapitalStatusScenarioUpdateType = (scenarioId: number, capitalStatus: string) => {
  return {
    module: [moduleConstant.login],
    scenarioId,
    capitalStatus,
    type: LOGIN_CAPITAL_STATUS_UPDATE,
  }
}
export const loginUserProfileAction: LoginUserProfileActionType = (data: IScenarioPayload) => {
  return {
    module: [moduleConstant.login],
    profileData: data,
    type: LOGIN_USER_PROFILE,
  };
};

export const loginActiveUserForScenarioAction: LoginActiveUserForScenarioActionType = (
  userId: number
) => {
  return {
    module: [moduleConstant.login],
    type: LOGIN_ACTIVE_USER_FOR_SCENARIO,
    userId,
  };
};

export const loginHistoryModalOpenAction: LoginHistoryModalOpenActionType = (
  scenarioId: number,
  historyModalVisibility: boolean
) => {
  return {
    historyModalVisibility,
    module: [moduleConstant.login],
    scenarioId,
    type: LOGIN_HISTORY_MODAL_OPEN,
  };
};

export const loginHistoryModalCloseAction: LoginHistoryModalCloseActionType = (
  historyModalVisibility: boolean
) => {
  return {
    historyModalVisibility,
    module: [moduleConstant.login],
    type: LOGIN_HISTORY_MODAL_CLOSE,
  };
};

export const loginSetTeamsAction: LoginSetTeamsActionType = (teams: ITeam) => {
  return {
    teams,
    module: [moduleConstant.login],
    type: LOGIN_SET_TEAMS_ACTION,
  };
};

export const loginSetBreadCrumbsValueAction: LoginSetBreadCrumbsValueActionType = (
  breadCrumbValue: BreadCrumbObject
) => {
  return {
    module: [moduleConstant.help],
    breadCrumbValue,
    type: LOGIN_SET_BREAD_CRUMBS_VALUE,
  };
};

export const loginRemoveBreadCrumbsElementAction: LoginRemoveBreadCrumbsElementActionType = (
  keyIndex?: number
) => {
  return {
    module: [moduleConstant.help],
    keyIndex,
    type: LOGIN_REMOVE_BREAD_CRUMBS_ELEMENT,
  };
};

export const loginClearBreadCrumbsValueAction: LoginClearBreadCrumbsValueActionType = () => {
  return {
    module: [moduleConstant.help],
    type: LOGIN_CLEAR_BREAD_CRUMBS_VALUE,
  };
};

export const loginSetTeamMembersAction: LoginSetTeamMembersActionType = (
  teamMembers: ITeamMembers[],
  teamID: number
) => {
  return {
    teamMembers,
    teamID,
    module: [moduleConstant.login],
    type: LOGIN_SET_TEAM_MEMBERS_ACTION,
  };
};

export const loginGetSelectedUserScenariosAction: LoginGetSelectedUserScenariosActionType = (
  userID: number
) => {
  return {
    userID,
    module: [moduleConstant.login],
    type: LOGIN_GET_SELECTED_USER_SCENARIO_ACTION,
  };
};

export const loginGetTeamMemberScenarioAction: LoginGetTeamMemberScenarioActionType = (
  scenarios: IScenarioPayloadUserScenarios[]
) => {
  return {
    scenarios,
    module: [moduleConstant.login],
    type: LOGIN_GET_TEAM_MEMBER_SCENARIO_ACTION,
  };
};

export const loginSetCurrentTeamAction: LoginSetCurrentTeamActionType = (teamID: number) => {
  return {
    teamID,
    module: [moduleConstant.login],
    type: LOGIN_SET_CURRENT_TEAM_ACTION,
  };
};

export const loginTeamLoaderAction: LoginTeamLoaderActionType = (loader: boolean) => {
  return {
    loader,
    module: [moduleConstant.login],
    type: LOGIN_TEAM_LOADER_ACTION,
  };
};
