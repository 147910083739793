import React, { useEffect } from "react";
import { connect } from "react-redux";
import { TAppStateType } from "../store/reducers/reducers";
import { getCSSThunk, GetCSSThunkType } from "../store/thunk/scenarioThunk/getCssThunk";
import AllRoutes from "./allRoutes";
interface StateProps {
  css: string;
  brandName: string;
}

interface DispatchProps {
  getCSSThunk: GetCSSThunkType;
}

type RouteProps = StateProps & DispatchProps;

const routes = (props: RouteProps) => {
  useEffect(() => {
    props.getCSSThunk(props.brandName);
  }, []);

  return (
    <>
      <style type="text/css">{props.css}</style>
      <AllRoutes />
    </>
  );
};
const mapStateToProps = (state: TAppStateType) => {
  return {
    css: state.branding.css,
    brandName: state.branding.brandName,
  };
};

const mapDispatchToProps = {
  getCSSThunk,
};

export default connect(mapStateToProps, mapDispatchToProps)(routes);
