import axios from "axios";
import { sendQuoteUrl } from "../../../constants";
import { errorGenericErrorHandleAction } from "../../actions/errorActions/errorAction";
import { successSnackBarHandleAction } from "../../actions/snackbarSuccessAction/successAction";
import { scenarioSendQuoteAction } from "../../../constants/userActions";
import { Action } from "redux";
import { ThunkAction } from "redux-thunk";
import { IApplicant } from "amm-applicant-interface";

export const sendQuoteThunk = (
  token: string,
  applicants: IApplicant[]
): ThunkAction<any, any, any, Action> => {
  const emails = applicants.map((applicant) => {
    return applicant.applicantEmail;
  });

  return (dispatch) => {
    axios
      .get(sendQuoteUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        params: {
          emails,
          user_action: scenarioSendQuoteAction[0],
        },
      })
      .then((res) => {
        return dispatch(successSnackBarHandleAction("Mail sent successfully."));
      })
      .catch((err) => {
        dispatch(errorGenericErrorHandleAction(err.message));
      });
  };
};
