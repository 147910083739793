import { Reducer } from "redux";
import { moduleConstant } from "../../../constants";
import { deepCopyObject } from "../../../utility";
import {
  ERROR_CREDIT_CARD_INDICATOR,
  ERROR_FORM_TRACKER,
  ERROR_LOAD_ENTIRE_STATE,
  ERROR_SNACKBAR_CLOSE,
  ERROR_GENERIC_ERROR_HANDLE,
  ErrorActions,
} from "../../actions/errorActions/errorActionType";
import { LOGIN_USER_LOGOUT, LoginUserLogoutAction } from "../../actions/login/loginActionType";
import { IToastsInterface } from "../successSnackbarReducer/index";
import { errorFormReducerUtility } from "../utility/errorReducerUtility";
export interface IErrorStateInterface {
  toasts: IToastsInterface[];
  autohide: boolean;
  objectiveErrorIndicator: boolean;
  affordabilityErrorIndicator: boolean;
  loanErrorIndicator: boolean;
  creditCardIndicator: boolean;
  errorTracker: IErrorTracker;
}

export interface IErrorTracker {
  [key: string]: {
    [key: string]: {
      [key: string]: {
        description: string;
        status: boolean;
      };
    };
  };
}

export const errorInitialState: IErrorStateInterface = {
  affordabilityErrorIndicator: false,
  autohide: true,
  creditCardIndicator: false,
  errorTracker: {},
  loanErrorIndicator: false,
  objectiveErrorIndicator: false,
  toasts: [],
};

const errorReducer: Reducer<IErrorStateInterface, ErrorActions | LoginUserLogoutAction> = (
  state: IErrorStateInterface = deepCopyObject(errorInitialState),
  action: ErrorActions | LoginUserLogoutAction
): IErrorStateInterface => {
  if (
    action &&
    action.module &&
    action.module.filter((currentModule) => Object.values(moduleConstant).includes(currentModule))
      .length === 0
  ) {
    return { ...state };
  }
  const currentState = Object.assign({}, state);
  let toasts = [...currentState.toasts];
  toasts.slice();
  switch (action.type) {
    case ERROR_GENERIC_ERROR_HANDLE:
      const lastError = toasts[toasts.length - 1];
      if (!lastError || lastError.text !== action.errorMessage) {
        toasts.push({ text: action.errorMessage });
      }
      return { ...currentState, toasts };

    case ERROR_SNACKBAR_CLOSE:
      [, ...toasts] = state.toasts;
      return { ...currentState, toasts };

    case ERROR_FORM_TRACKER:
      return errorFormReducerUtility(currentState, action);

    case ERROR_LOAD_ENTIRE_STATE:
      if (action.isCashOutError) {
        action.state.loanErrorIndicator = true;
      }
      return action.state;

    case LOGIN_USER_LOGOUT:
      return { ...errorInitialState };

    case ERROR_CREDIT_CARD_INDICATOR:
      return { ...errorInitialState, creditCardIndicator: action.value };

    default:
      return { ...currentState };
  }
};

export default errorReducer;
