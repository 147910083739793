import React from "react";
import { FileUpload } from "react-md";
import {
  AttachedFilesActionType,
  SetToastsActionType,
} from "../../store/actions/uploadDocumentsActions/uploadDocumentsActionType";
import {
  attachedFilesAction,
  setToastsAction,
  isAllFilesAttachedAction,
} from "../../store/actions/uploadDocumentsActions/uploadDocumentsAction";
import { connect } from "react-redux";
import { TAppStateType } from "../../store/reducers/reducers";
import {
  uploadFileScenarioThunk,
  UploadFileScenarioThunkType,
} from "../../store/thunk/scenarioThunk/uploadFileScenarioThunk";
import { handleSubmit, isUploadDisabled } from "./uploadHandler";
import { maxFileSize } from "../../constants";
import { setUploadingFilesAction } from "../../store/actions/uploadDocumentsActions/uploadDocumentsAction";
import {
  SetUploadingFilesActionType,
  isAllFilesAttachedActionType,
} from "../../store/actions/uploadDocumentsActions/uploadDocumentsActionType";
import { errorGenericErrorHandleAction } from "../../store/actions/errorActions/errorAction";
import { ErrorGenericErrorHandleActionType } from "../../store/actions/errorActions/errorActionType";

interface StateProps {
  numberOfUploads: number;
  token: string;
  currentScenarioID: number;
  currentUserId: number;
  currentScenarioUserId: number;
  currentScenarioReviwerID: number;
  isFileUploading: boolean;
  allFilesUploaded: number;
  allFilesAttached: boolean;
  uploadDocumentsForAllScenariosAsReviewer: boolean;
}

interface DispatchProps {
  uploadFileScenarioThunk: UploadFileScenarioThunkType;
  attachedFilesAction: AttachedFilesActionType;
  setToastsAction: SetToastsActionType;
  setUploadingFilesAction: SetUploadingFilesActionType;
  isAllFilesAttachedAction: isAllFilesAttachedActionType;
  errorGenericErrorHandleAction: ErrorGenericErrorHandleActionType;
}

interface OwnProps {
  scenarioId: string;
  chooseFileButtonEnabled: boolean;
}

type UploadFileProps = DispatchProps & StateProps & OwnProps;

const UploadFileMultiple: React.FC<UploadFileProps> = (props: UploadFileProps) => {
  const acceptedFileTypes =
    ".pdf, .xls, .xlsx, .xlsm, .csv, .jpg, .jpeg, .png, .tif, .tiff, .bmp, .gif, .odt, .ods, .txt, .json, .doc, .docx, .rtf, .html";
  return (
    <div className="browse-files">
      <FileUpload
        id="browseButton"
        className="browse-files-button"
        flat={true}
        secondary={true}
        swapTheming={true}
        multiple={true}
        label="Browse"
        icon={<></>}
        maxSize={maxFileSize}
        onChange={(e) => {
          handleSubmit(
            e,
            props.numberOfUploads,
            props.uploadFileScenarioThunk,
            props.token,
            props.scenarioId,
            props.currentUserId,
            props.currentScenarioUserId,
            props.currentScenarioReviwerID,
            props.attachedFilesAction,
            props.setToastsAction,
            props.setUploadingFilesAction,
            props.allFilesUploaded,
            props.isAllFilesAttachedAction,
            props.uploadDocumentsForAllScenariosAsReviewer
          );
        }}
        onSizeError={(files) => {
          files.forEach((file) => {
            return props.errorGenericErrorHandleAction(
              `File upload failed: File - ${file.name} too large`
            );
          });
        }}
        onError={() => {
          props.setToastsAction([{ text: "File upload failed" }], false);
        }}
        accept={acceptedFileTypes}
        allowDuplicates={true}
        disabled={isUploadDisabled(
          props.isFileUploading,
          props.numberOfUploads,
          props.chooseFileButtonEnabled,
          props.allFilesAttached
        )}
      />
      <div className="browse-info">
        <p className="browse-info-p">Select one or multiple files to attach.</p>
        <p className="browse-info-p">
          Drag and drop files into this space to upload files from desktop.
        </p>
      </div>
    </div>
  );
};

const mapStateToProps = (state: TAppStateType) => {
  return {
    numberOfUploads: state.upload.numberOfUploads,
    token: state.login.token as string,
    currentScenarioID: state.scenario.currentScenarioId ? state.scenario.currentScenarioId : 0,
    currentUserId: state.login.id ? state.login.id : 0,
    currentScenarioUserId: state.scenario.ownerID ? state.scenario.ownerID : 0,
    currentScenarioReviwerID: state.scenario.reviewerID ? state.scenario.reviewerID : 0,
    isFileUploading: state.upload.showUploadLoader,
    allFilesUploaded: state.upload.allFilesUploaded,
    allFilesAttached: state.upload.allFilesAttached,
    uploadDocumentsForAllScenariosAsReviewer:
      state.login.userPermissions.uploadDocumentsForAllScenariosAsReviewer,
  };
};

const dispatchStateToProps = {
  uploadFileScenarioThunk,
  attachedFilesAction,
  setToastsAction,
  setUploadingFilesAction,
  isAllFilesAttachedAction,
  errorGenericErrorHandleAction,
};

export default connect(mapStateToProps, dispatchStateToProps)(UploadFileMultiple);
