import { ICurrentUser, IPermissionsObject } from "amm-admin-interface";
import { IScenarioPayloadUser } from "amm-scenario-interface";
import { ILoginInitialState } from "./loginReducer";
import { deepCopyObject } from "../../../utility";
import { LoginRemoveBreadCrumbsElementAction } from "../../actions/login/loginActionType";

export const setCurrentUserStateUtility = (
  data: IScenarioPayloadUser,
  state: ILoginInitialState
) => {
  const currentUser = {} as ICurrentUser;
  if (data.first_name) {
    currentUser.firstName = data.first_name;
    (currentUser.brokerPartyID = data.broker_party_id),
      (currentUser.uniqueUserID = data.unique_user_id),
      (currentUser.originatorPartyID = data.originator_party_id),
      (currentUser.originatorID = data.originator_id),
      (currentUser.organisationFullName = data.organisation_full_name),
      (currentUser.originatorName = data.originator.name),
      (currentUser.organisationLegalName = data.organisation_legal_name),
      (currentUser.cognitoId = data.cognito_id),
      (currentUser.createdAt = data.created_at),
      (currentUser.email = data.email),
      (currentUser.id = data.id),
      (currentUser.lastLogin = data.last_login),
      (currentUser.lastName = data.last_name),
      (currentUser.middleName = data.middle_name),
      (currentUser.mobile = data.mobile),
      (currentUser.noOfScenarios = data.scenarios.length),
      (currentUser.partialEmail = data.email),
      (currentUser.partialFirstName = data.first_name),
      (currentUser.partialLastName = data.last_name),
      (currentUser.partialMiddleName = data.middle_name),
      (currentUser.partialMobile = data.mobile),
      (currentUser.partialPhone = data.phone),
      (currentUser.partialStatus = data.status),
      (currentUser.phone = data.phone),
      (currentUser.provisoReferralCode = data.proviso_referral_code),
      (currentUser.provisoSMSLink = data.proviso_sms_link),
      (currentUser.roleId = data.roles[0].id),
      (currentUser.status = data.status),
      (currentUser.title = data.title),
      (currentUser.userNotifications = data.user_notifications);
  } else {
    // fix the all type, @Aastha, @lovepreet
    // @ts-ignore
    currentUser.firstName = data.firstName;
    // @ts-ignore
    (currentUser.brokerPartyID = data.brokerPartyID),
      // @ts-ignore
      (currentUser.uniqueUserID = data.uniqueUserID),
      // @ts-ignore
      (currentUser.originatorPartyID = data.originatorPartyID),
      (currentUser.originatorID = data.originator_id),
      (currentUser.originatorName = data.originator.name),
      // @ts-ignore
      (currentUser.organisationFullName = data.organisationFullName),
      // @ts-ignore
      (currentUser.organisationLegalName = data.organisationLegalName),
      // @ts-ignore
      (currentUser.cognitoId = data.cognitoId),
      // @ts-ignore
      (currentUser.createdAt = data.createdAt),
      (currentUser.email = data.email),
      (currentUser.id = data.id),
      // @ts-ignore
      (currentUser.lastLogin = data.lastLogin),
      // @ts-ignore
      (currentUser.lastName = data.lastName),
      // @ts-ignore
      (currentUser.middleName = data.middleName),
      (currentUser.mobile = data.mobile),
      // @ts-ignore
      (currentUser.noOfScenarios = data.noOfScenarios),
      (currentUser.partialEmail = data.email),
      // @ts-ignore
      (currentUser.partialFirstName = data.firstName),
      // @ts-ignore
      (currentUser.partialLastName = data.lastName),
      // @ts-ignore
      (currentUser.partialMiddleName = data.middleName),
      (currentUser.partialMobile = data.mobile),
      (currentUser.partialPhone = data.phone),
      (currentUser.partialStatus = data.status),
      (currentUser.phone = data.phone),
      // @ts-ignore
      (currentUser.provisoReferralCode = data.provisoReferralCode),
      (currentUser.provisoSMSLink = data.proviso_sms_link as string),
      // @ts-ignore
      (currentUser.roles = data.usersRolesByUserId.nodes[0].roleByRoleId.name),
      (currentUser.status = data.status),
      (currentUser.title = data.title),
      (currentUser.userNotifications = data.user_notifications);
  }

  return {
    ...state,
    currentUser,
  };
};

export const loginSetPermissionsUtility = (
  data: IPermissionsObject[],
  state: ILoginInitialState
) => {
  const userPermissions: { [key: string]: boolean } = {};
  userPermissions.viewAdminPanelPermission = Boolean(
    data.find((permission) => permission.resource === "adminPanel")
  );
  userPermissions.brandingPermission = Boolean(
    data.find((permission) => permission.resource === "branding" && permission.action === "update")
  );

  userPermissions.addBrokerPermission = Boolean(
    data.find((permission) => permission.resource === "admin" && permission.action === "addBroker")
  );

  userPermissions.editBrokerPermission = Boolean(
    data.find((permission) => permission.resource === "admin" && permission.action === "editBroker")
  );

  userPermissions.scenarioDuplicateAllPermission = Boolean(
    data.find(
      (permission) => permission.resource === "scenario" && permission.action === "duplicateAll"
    )
  );

  userPermissions.selfScenarioDuplicateAllPermission = Boolean(
    data.find(
      (permission) => permission.resource === "scenario" && permission.action === "selfDuplicateAll"
    )
  );

  // setting view team panel permission
  userPermissions.viewTeamsPanelPermission = Boolean(
    data.find(
      (permission) => permission.resource === "teams" && permission.action === "viewTeamsPanel"
    )
  );

  // setting duplicate self permission
  userPermissions.duplicateScenarioPermission = Boolean(
    data.find(
      (permission) => permission.resource === "scenario" && permission.action === "duplicateSelf"
    )
  );

  // setting duplicate credit review scenario permission
  userPermissions.duplicateCreditReviewPermission = Boolean(
    data.find(
      (permission) =>
        permission.resource === "scenario" && permission.action === "duplicateForCreditReview"
    )
  );

  // setting delete self permission
  userPermissions.deleteScenarioPermission = Boolean(
    data.find((permission) => permission.resource === "scenario" && permission.action === "delete")
  );

  // setting view teams permssion
  userPermissions.viewTeamsPermission = Boolean(
    data.find((permission) => permission.resource === "teams" && permission.action === "viewTeams")
  );

  // setting add team permssion
  userPermissions.addTeamPermission = Boolean(
    data.find((permission) => permission.resource === "teams" && permission.action === "add")
  );

  // setting edit team permssion
  userPermissions.editTeamPermission = Boolean(
    data.find((permission) => permission.resource === "teams" && permission.action === "edit")
  );

  // setting delete team permssion
  userPermissions.deleteTeamPermission = Boolean(
    data.find((permission) => permission.resource === "teams" && permission.action === "delete")
  );

  // setting view my team members permssion
  userPermissions.viewTeamMembers = Boolean(
    data.find(
      (permission) => permission.resource === "teams" && permission.action === "viewTeamMembers"
    )
  );
  // setting view scenario count for all users permssion
  userPermissions.viewScenarioCountPermission = Boolean(
    data.find(
      (permission) =>
        permission.resource === "scenario" && permission.action === "viewScenarioCount"
    )
  );

  // setting load all scenarios permssion
  userPermissions.loadAllScenario = Boolean(
    data.find((permission) => permission.resource === "scenario" && permission.action === "loadAll")
  );

  // setting duplicate team scenario permssion
  userPermissions.duplicateTeamScenarioPermission = Boolean(
    data.find(
      (permission) => permission.resource === "scenario" && permission.action === "duplicateTeam"
    )
  );

  // setting load team scenario permssion
  userPermissions.loadTeamScenarioPermission = Boolean(
    data.find(
      (permission) => permission.resource === "scenario" && permission.action === "loadTeamScenario"
    )
  );

  // setting save team scenario permssion
  userPermissions.saveTeamScenarioPermission = Boolean(
    data.find(
      (permission) => permission.resource === "scenario" && permission.action === "saveTeamScenario"
    )
  );

   // setting view leads panel permission

   userPermissions.viewLeadsPanelPermission = Boolean(
    data.find(
      (permission) => permission.resource === "leads" && permission.action === "viewAllLeads"
    )
  );
  // setting override scenario permission
  userPermissions.overrideCreditScorePermission = Boolean(
    data.find(
      (permission) => permission.resource === "scenario" && permission.action === "overrideScenario"
    )
  );

  // setting generatePDF team scenario permssion
  userPermissions.generatePDFTeamScenarioPermission = Boolean(
    data.find(
      (permission) =>
        permission.resource === "scenario" && permission.action === "generatePDFTeamScenario"
    )
  );

  // setting capitalDocument team scenario permssion
  userPermissions.capitalDocumentTeamScenarioPermission = Boolean(
    data.find(
      (permission) =>
        permission.resource === "document" && permission.action === "capitalDocumentTeamScenario"
    )
  );

  // setting assign permission for other roles than api loan writer/client manager
  userPermissions.assignTeamScenarioPermission = Boolean(
    data.find(
      (permission) => permission.resource === "teams" && permission.action === "reassignTeamMember"
    )
  );
  // setting capitalOrigination team scenario permssion
  userPermissions.capitalOriginationTeamScenarioPermission = Boolean(
    data.find(
      (permission) =>
        permission.resource === "document" && permission.action === "capitalOriginationTeamScenario"
    )
  );

  // setting uploadDocuments team scenario permssion
  userPermissions.uploadDocumentsTeamScenarioPermission = Boolean(
    data.find(
      (permission) =>
        permission.resource === "scenario" && permission.action === "uploadDocumentsTeamScenario"
    )
  );

  // setting downloadAcceptanceLetter team scenario permssion
  userPermissions.downloadAcceptanceLetterTeamScenarioPermission = Boolean(
    data.find(
      (permission) =>
        permission.resource === "document" &&
        permission.action === "downloadAcceptanceLetterTeamScenario"
    )
  );

  // setting docusignTrigger team scenario permssion
  userPermissions.docusignTriggerTeamScenarioPermission = Boolean(
    data.find(
      (permission) =>
        permission.resource === "document" && permission.action === "docusignTriggerTeamScenario"
    )
  );

  // setting not to view my dashboard or new scenario permssion
  userPermissions.notViewMyDashboardOrScenarioPermission = Boolean(
    data.find(
      (permission) =>
        permission.resource === "myDashboardOrScenario" &&
        permission.action === "viewmyDashboardOrScenarioAction"
    )
  );

  // approve button disabled for shore loan writers

  userPermissions.approveButtonDisabled = Boolean(
    data.find(
      (permission) =>
        permission.resource === "scenario" &&
        permission.action === "save" &&
        permission.attributes.includes(`${state.currentUser.roleId}`)
    )
  );

  userPermissions.capitalSubmitDisabled = Boolean(
    data.find(
      (permission) =>
        permission.resource === "document" &&
        permission.action === "capitalOriginateSelf" &&
        permission.attributes.includes(`${state.currentUser.roleId}`)
    )
  );

  userPermissions.capitalSaveCredentialsPermission = Boolean(
    data.find(
      (permission) =>
        permission.resource === "myAccount" && permission.action === "saveCapitalCredentials"
    )
  );

  userPermissions.updateBPPartyIDPermission = Boolean(
    data.find(
      (permission) => permission.resource === "myAccount" && permission.action === "updateBPPartyID"
    )
  );

  userPermissions.createNewScenarioPermission = Boolean(
    data.find((permission) => permission.resource === "scenario" && permission.action === "create")
  );

  // setting view scenario legacy number persmission for APILW
  userPermissions.viewScenarioLegacyID = Boolean(
    data.find(
      (permission) => permission.resource === "scenario" && permission.action === "viewLegacyID"
    )
  );

  // setting view scenario legacy number persmission for AFGLW
  userPermissions.viewEditLegacyID = Boolean(
    data.find(
      (permission) => permission.resource === "scenario" && permission.action === "addEditLegacyID"
    )
  );
  // setting view Credit manager option in Side menu
  userPermissions.viewCreditReview = Boolean(
    data.find(
      (permission) => permission.resource === "scenario" && permission.action === "creditReview"
    )
  );

  userPermissions.viewReport = Boolean(
    data.find(
      (permission) => permission.resource === "report" && permission.action === "viewAllScenarios"
    )
  );

  // save scenario permission for credit reviewer
  userPermissions.viewSaveButtonAsReviewer = Boolean(
    data.find(
      (permission) => permission.resource === "scenario" && permission.action === "saveAsReviewer"
    )
  );

  // upload document permission for credit reviewer for all scenarios
  userPermissions.uploadDocumentsForAllScenariosAsReviewer = Boolean(
    data.find(
      (permission) =>
        permission.resource === "scenario" && permission.action === "uploadDocumentsForAllScenario"
    )
  );

  return {
    ...state,
    userPermissions,
  };
};

export const removeBreadCrumbs = (
  action: LoginRemoveBreadCrumbsElementAction,
  state: ILoginInitialState
) => {
  const updatedBreadCrumb = deepCopyObject(state.breadCrumbsState);
  const keys = Object.keys(state.breadCrumbsState);
  if (action.keyIndex !== undefined) {
    const index = action.keyIndex + 1;
    const keysToBeRemoved = keys.splice(index);
    keysToBeRemoved.forEach((key) => delete updatedBreadCrumb[key]);
  } else {
    const lastKey = keys[keys.length - 1];
    delete updatedBreadCrumb[lastKey];
  }
  return {
    ...state,
    breadCrumbsState: updatedBreadCrumb,
  };
};
