import axios from "axios";
import { brandingCssUpdateAction } from "../../actions/brandingActions/brandingActions";
import { errorGenericErrorHandleAction } from "../../actions/errorActions/errorAction";
import { Dispatch } from "react";
import { BrandingCssUpdateAction } from "../../actions/brandingActions/brandingActionTypes";
import { ErrorGenericErrorHandleAction } from "../../actions/errorActions/errorActionType";
import { ThunkAction } from "redux-thunk";
import { Action } from "redux";

export type GetCSSThunkType = (brandName:string) => ThunkAction<any, any, any, Action>;

export const getCSSThunk: GetCSSThunkType = (brandName:string) => {
  const cssFileUrl = `https://${process.env.REACT_APP_AWS_S3_BUCKET}.s3-ap-southeast-2.amazonaws.com/css/${brandName}-branding-css.css`;

  return async (dispatch: Dispatch<BrandingCssUpdateAction | ErrorGenericErrorHandleAction>) => {
    try {
      const res = await axios.get(cssFileUrl);
      dispatch(brandingCssUpdateAction(res.data, brandName));
    } catch (error) {
      if (error.response && error.response.status === 403) {
        return;
      }
      return dispatch(errorGenericErrorHandleAction(error.message));
    }
    
  };
};
