import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloClient } from "apollo-client";
import { ApolloLink } from "apollo-link";
import { setContext } from "apollo-link-context";
import { onError } from "apollo-link-error";
import { HttpLink } from "apollo-link-http";
import { RestLink } from "apollo-link-rest";
import * as React from "react";
import { ApolloProvider } from "react-apollo";
import { ApolloProvider as ApolloHooksProvider } from "react-apollo-hooks";
import { graphQl, serverUrl } from "../../constants";

interface OwnProps {
  token?: string;
  children: React.ReactFragment;
}

type ApolloProviderProps = OwnProps;
const restLink = new RestLink({
  uri: serverUrl,
});
export class AMMProvider extends React.Component<ApolloProviderProps> {
  public readonly client = new ApolloClient({
    cache: new InMemoryCache({
      addTypename: false,
    }),
    // connectToDevTools: true,
    link: ApolloLink.from([
      // Error Link
      onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors) {
          graphQLErrors.map(({ message, locations, path }) =>
            console.log(
              `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
            )
          );
        }
        if (networkError) {
          console.log(`[Network error]: ${networkError}`);
        }
      }),
      // Auth Link
      setContext(async (_, { headers }) => {
        try {
          if (this.props.token) {
            return {
              headers: {
                Authorization: `Bearer ${this.props.token}`,
                "Content-Type": "application/x-www-form-urlencoded",
              },
            };
          }
          return { headers };
        } catch (e) {
          return { headers };
        }
      }),

      // Rest link
      restLink,
      // HTTP Link
      new HttpLink({
        uri: graphQl,
      }),
    ]),
  });

  public render() {
    return (
      <ApolloProvider {...this.props} client={this.client}>
        <ApolloHooksProvider client={this.client}>{this.props.children}</ApolloHooksProvider>
      </ApolloProvider>
    );
  }
}
