import React, { ReactElement } from "react";
import { Box, CircularProgress, styled, Theme } from "@material-ui/core";

interface ICircularProgressReview {
  size: number;
  children: ReactElement;
  outerThickness: number;
  outerValue: number;
  warning: boolean;
}
export const CircularProgressReview = styled((props: ICircularProgressReview) => (
  <Box
    position="relative"
    display="inline-flex"
    margin="auto"
    width={props.size}
    height={props.size}
  >
    <Background
      variant="static"
      {...props}
      value={100}
      size={props.size}
      thickness={props.outerThickness}
    />
    <OuterCircle
      variant="static"
      {...props}
      value={props.outerValue}
      size={props.size}
      thickness={props.outerThickness}
      className={props.warning ? "overLimit" : ""}
    />

    <Box
      top={0}
      left={0}
      bottom={0}
      right={0}
      position="absolute"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      {props.children}
    </Box>
  </Box>
))({});

const Background = styled(CircularProgress)(({ theme }) => ({
  position: "absolute",
  "& svg": {
    color: theme.palette.common.black,
  },
}));

interface IOuterCircle {
  warning: boolean;
  theme: Theme;
}
const OuterCircle = styled(CircularProgress)(({ warning }: IOuterCircle) => ({
  position: "absolute",
}));
