import React from "react";

const PropertyIcon: React.FC = () => {
  return (
    <svg
      className="icon footer-icon footer-property-icon"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 35 35"
    >
      <title>icon-property</title>
      <path
        className="cls-1"
        d="M17.5,2A15.5,15.5,0,1,1,2,17.5,15.5176,15.5176,0,0,1,17.5,2m0-2A17.5,17.5,0,1,0,35,17.5,17.5,17.5,0,0,0,17.5,0Z"
      />
      <path className="cls-2" d="M15,25V18h5v7h5V16h3L17.5,7,7,16h3v9Z" />
    </svg>
  );
};

export default PropertyIcon;
