import { BreadCrumbObject } from "../store/reducers/loginReducers/loginReducer";
import history from "../routes/history";
import { ITeam, IBrokerListType, ITeamMembers } from "amm-admin-interface";

export const setBreadCrumbsValue = (teams: ITeam, brokerList: IBrokerListType[]) => {
  let breadCrumbsObject: BreadCrumbObject = {};
  const path = history.location.pathname;
  let modifiedPath = history.location.pathname;

  if (path.includes("/teams/team-members")) {
    modifiedPath = modifiedPath.substring(0, 19);
  } else if (path.includes("/dashboard/user-scenarios")) {
    modifiedPath = modifiedPath.substring(0, 25);
  } else if(path.includes("/teams/add-edit")) {
    modifiedPath = modifiedPath.substring(0, 6);
  }
  
  switch (modifiedPath) {
    case "/teams":
      breadCrumbsObject = {
        Teams: "/teams",
      };
      break;
    case "/teams/team-members":
      let dividedPath = path.split("/");
      // team member's scenarios
      if (dividedPath.length === 5) {
        const userID = dividedPath[dividedPath.length - 1];
        const teamID = dividedPath[dividedPath.length - 2];
        const selectedUserName = teams[teamID] && teams[teamID].teamMembers ? findFirstName(teams[teamID].teamMembers, userID) : "";
        
        const selectedUserDasboard = selectedUserName + "'s Dashboard";

        const teamName = teams[teamID].teamName;
        const teamLink = `/teams/team-members/${teamID}`;
        breadCrumbsObject = {
          Teams: "/teams",
          [`${teamName} team`]: teamLink,
          [selectedUserDasboard]: `/dashboard/user-scenarios/${userID}`,
        };
      }
      // team members
      else if (dividedPath.length === 4) {
        const teamID = dividedPath[dividedPath.length - 1];
        const teamName = teams[teamID].teamName;
        const teamLink = `/teams/team-members/${teamID}`;
        breadCrumbsObject = {
          Teams: "/teams",
          [`${teamName} team`]: teamLink,
        };
      }
      break;
    case "/administration/users":
      breadCrumbsObject = {
        Users: "/administration/users",
      };
      break;
    case "/dashboard/user-scenarios":
      dividedPath = path.split("/");
      const currentUserID = dividedPath[dividedPath.length - 1];
      const selectedUser = brokerList.find((broker) => broker.id === Number(currentUserID));
      const selectedUserName = selectedUser ? selectedUser.firstName : "";
      const selectedUserDasboard = selectedUserName + "'s Dashboard";
      breadCrumbsObject = {
        Users: "/administration/users",
        [selectedUserDasboard]: `/dashboard/user-scenarios/${currentUserID}`,
      };
      break;
    default:
      breadCrumbsObject = breadCrumbsObject;
  }
  return breadCrumbsObject;
};

const findFirstName = (teamMembers: ITeamMembers[], userID: string) => {
  const teamMember = teamMembers.find(
    (teamMember: ITeamMembers) => teamMember.id === Number(userID)
  )
  return teamMember  ? teamMember.firstName : "";
}