import {
  IApplicant,
  IApplicantInitialState,
  IApplicantRequiredField,
  IRetrieveBankData,
  IIncomeSources,
  ISplitAddress,
  IFinancialObligation,
  ILivingExpenses,
  IAssets,
  ICreditCards,
  IAffordabilityRequiredFields,
} from "amm-applicant-interface";
import dayjs from "dayjs";
import isEqual from "lodash/isEqual";
import {
  affordabilityMaxRow,
  applicantAssetsTypeOptions,
  creditScoreBtnText,
  documentType,
  documentTypeIndex,
  howManyApplicant,
  maxApplicantTab,
  privacyOptions,
  provisoOrManual,
  verifyBtnText,
  applicantFinancialObligationTypeOptions,
  duration,
  taxStatus,
  applicantPAYGIncomeTypeOptions,
  alphanumericRegexForDocumentId,
  autoAddedNotes,
  applicantFinancialObligationLoanTypeOptions,
  loanIsRefinancedText,
  includedLoanFinanceOptions,
  ProvisoTypeMappings,
  rentStatuses,
  MINIMUM_INCOME_SOURCES,
  MINIMUM_ASSETS,
  MINIMUM_LIVING_EXPENSES,
  MINIMUM_REFINANCED_LOAN,
  rentalIncome,
  EmploymentTypes,
  applicantSEIncomeTypeOptions,
} from "../../../constants";
import {
  ApplicantAddNewAction,
  ApplicantAddressAutoSuggestAction,
  ApplicantAddressLoaderAction,
  ApplicantAddRetrieveDataAction,
  ApplicantClearAddressResultAction,
  ApplicantCopyToAllAction,
  ApplicantGetCreditScoreAndVerifyIdentityAction,
  ApplicantGetPropertyAction,
  ApplicantNoteValueUpdateAction,
  ApplicantRemoveAction,
  ApplicantRemoveRetrieveDataAction,
  ApplicantSetCreditCardTextErrorAction,
  ApplicantSetProvisioDataAction,
  ApplicantStateUpdateAction,
  ApplicantUndoAction,
  ApplicantValidateApplicantFieldsAction,
  ApplicantClearAffordabilityPageDataAction,
  ApplicantValidationUpdationAction,
  AffordabilityRentalIncomeUpdateAction,
  ApplicantCopyAddressAction,
  ApplicantReferralCodeUpdateAction,
  ApplicantClearDocumentIdAction,
  ApplicantUpdateValueForUncategorisedAction,
  ApplicantIsLoanRefiancedNoteAction,
  ApplicantUpdateLivingArragementAction,
  ApplicantAddNoteAction,
  ApplicantOverrideCreditScoreAction,
  ApplicantStatusRentUpdateAction,
  ApplicantAffordabilityTotalsUpdateAction,
  ApplicantSetProbationWarningAction,
} from "../../actions/applicantActions/applicantActionTypes";
import {
  applicantDocumentsData,
  applicantDocumentsLastData,
  applicantDocumentsSecondData,
  applicants,
  applicantsRequiredFieldList,
  assetsData,
  creditCardsData,
  documentRequiredField,
  financialObligationData,
  incomeSourcesData,
  livingExpensesData,
  medicareRequiredField,
  passportRequiredField,
  retrieveDataObject,
  dependentData,
} from "./applicantInitialState";
import { deepCopyObject } from "../../../utility";
import { livingArrangementItems } from "../../../constants/index";
import { isApplicantOld } from "../../../utility/applicantUtility";
import moment from "moment";
import { getTotalLivingExpense } from "../../../components/common/utility";

// applicant and affordability current tab state update
export const applicantStateUpdate = (
  action: ApplicantStateUpdateAction,
  state: IApplicantInitialState
): IApplicantInitialState => {
  const currentState: IApplicantInitialState = JSON.parse(JSON.stringify(Object.assign(state)));
  let applicant: IApplicant[] = JSON.parse(JSON.stringify([...currentState.applicants]));
  let applicantsRequiredField: IApplicantRequiredField[] = [
    ...currentState.applicantsRequiredField,
  ];
  let applicantTab: number = currentState.maxApplicantTab;
  let currentTab: number = currentState.currentTab;
  let noteRequired: boolean[] = [];

  if (action.stateName === "earlyRepaymentStrategy") {
    applicantsRequiredField[currentState.currentTab].earlyRepaymentStrategy = Boolean(
      action.required
    );
  }

  if (action.childArray === "totalExpensesNotes" && action.stateName === "noteRequired") {
    applicant[currentState.currentTab].totalExpensesNotes[0].noteRequired = Boolean(
      action.stateValue
    );
    // return { ...currentState, applicants: applicant };
  }

  if (action.stateName === "earlyRepaymentStrategyNote") {
    applicantsRequiredField[currentState.currentTab].earlyRepaymentStrategyNote = Boolean(
      action.required
    );
  }

  if (action.childArray === "incomeSources") {
    noteRequired = [...applicant[currentState.currentTab].incomeSourcesNoteRequired];
  } else if (action.childArray === "financialObligation") {
    noteRequired = [...applicant[currentState.currentTab].financialObligationNoteRequired];
  } else if (action.childArray === "livingExpenses") {
    noteRequired = [...applicant[currentState.currentTab].livingExpensesNoteRequired];
  } else if (action.childArray === "creditCards") {
    noteRequired = [...applicant[currentState.currentTab].creditCardsNoteRequired];
  } else if (action.childArray === "assets") {
    if (action.stateName !== "myContribution") {
      noteRequired = [...applicant[currentState.currentTab].assetsNoteRequired];
    }
  }

  // @Todo
  const buttonUpdate = () => {
    applicant[currentState.currentTab].creditScoreBtnText = creditScoreBtnText[0];
    applicant[currentState.currentTab].verifyBtnText = verifyBtnText[0];
    applicant[currentState.currentTab].isCreditScore = false;
    applicant[currentState.currentTab].isDocumentVerified = false;
  };

  if (action.objectName) {
    applicant[currentState.currentTab][action.objectName][action.stateName] = action.stateValue;
    applicant[currentState.currentTab].privacyConsent.idsMatrix = false;
    applicant[currentState.currentTab].privacyConsent.creditScore = 700;
    applicant[currentState.currentTab].privacyConsent.formSubmit = false;
    applicant[currentState.currentTab].privacyConsent.isFormValid = false;
  } else if (action.childArray) {
    const index = action.childIndex as number;
    if (
      action.childArray === "financialObligation" &&
      applicant[currentState.currentTab].financialObligation[index] &&
      applicant[currentState.currentTab].financialObligation[index].duration === "Once off" &&
      action.stateName === "type" &&
      action.stateValue === "Personal loan"
    ) {
      applicant[currentState.currentTab][action.childArray][index].duration = "Month";
    }

    const newApplicants: IApplicant[] = Object.assign([], applicant);
    const childNewArray = [...newApplicants[currentState.currentTab][action.childArray]];
    // setting isLoanRefinanced to false

    if (
      action.childArray === "financialObligation" &&
      action.stateName === "type" &&
      (!applicantFinancialObligationLoanTypeOptions.includes(action.stateValue as string) ||
        !includedLoanFinanceOptions.includes(action.stateValue as string))
    ) {
      const currentRow = childNewArray[index];
      currentRow["isLoanRefinanced"] = false;
      currentRow["note"] = currentRow.note
        .trim()
        .replace(new RegExp(loanIsRefinancedText, "g"), "");
      currentRow["partialNoteValue"] = currentRow.partialNoteValue
        .trim()
        .replace(new RegExp(loanIsRefinancedText, "g"), "");

      if (
        typeof action.stateValue === "string" &&
        disabledFinancialObligationOptions(action.stateValue)
      ) {
        currentRow.balance = 0;
        currentRow.interestRate = 0;
      }
    }

    childNewArray[index][action.stateName] = action.stateValue;
    // if note was added for rental income, remove that.
    if (
      action.childArray === "incomeSources" &&
      action.stateName === "type" &&
      childNewArray[index].provisoOrManual === provisoOrManual[1]
    ) {
      childNewArray[index].noteRequired = false;
    }
    let childNewCoupleArray;
    if (action.childArray === "dependents") {
      if (currentState.howManyApplicant === "Couple" && currentState.currentTab < 2) {
        if (currentState.currentTab) {
          childNewCoupleArray = [...newApplicants[0][action.childArray]];
          childNewCoupleArray[index][action.stateName] = action.stateValue;
          applicant[0][action.childArray] = childNewCoupleArray;
        } else {
          const childNewCoupleArray = [...newApplicants[1][action.childArray]];
          childNewCoupleArray[index][action.stateName] = action.stateValue;
          applicant[1][action.childArray] = childNewCoupleArray;
        }
      }
    }
    if (
      action.childArray === "applicantDocuments" &&
      action.stateName === "documentType" &&
      action.stateValue === documentType[0].value
    ) {
      const index = action.childIndex as number;
      applicant[currentState.currentTab][action.childArray][index].documentAddressLocation = "";
      applicant[currentState.currentTab][action.childArray][index].licenseNumber = "";
      delete applicant[currentState.currentTab][action.childArray][index].cardNumber;
      delete applicant[currentState.currentTab][action.childArray][index].expiryDate;
      delete applicant[currentState.currentTab][action.childArray][index].passportNumber;
      delete applicant[currentState.currentTab][action.childArray][index].referenceNumber;
      applicantsRequiredField[currentState.currentTab][action.childArray][index] = {
        licenseNumber: false,
        documentAddressLocation: false,
      };
    } else if (
      action.childArray === "applicantDocuments" &&
      action.stateName === "documentType" &&
      action.stateValue === documentType[2].value
    ) {
      delete applicant[currentState.currentTab][action.childArray][index].licenseNumber;
      delete applicant[currentState.currentTab][action.childArray][index].cardNumber;
      delete applicant[currentState.currentTab][action.childArray][index].expiryDate;
      delete applicant[currentState.currentTab][action.childArray][index].referenceNumber;

      applicant[currentState.currentTab][action.childArray][index].documentAddressLocation = "AUS";
      applicant[currentState.currentTab][action.childArray][index].passportNumber = "";
      applicantsRequiredField[currentState.currentTab][action.childArray][index] = {
        passportNumber: false,
      };
    } else if (
      action.childArray === "applicantDocuments" &&
      action.stateName === "documentType" &&
      action.stateValue === documentType[1].value
    ) {
      delete applicant[currentState.currentTab][action.childArray][index].documentAddressLocation;
      delete applicant[currentState.currentTab][action.childArray][index].licenseNumber;
      delete applicant[currentState.currentTab][action.childArray][index].passportNumber;

      applicant[currentState.currentTab][action.childArray][index].cardNumber = "";
      applicant[currentState.currentTab][action.childArray][index].expiryDate = "";
      applicant[currentState.currentTab][action.childArray][index].referenceNumber = 1;
      applicantsRequiredField[currentState.currentTab][action.childArray][index] = {
        cardNumber: false,
        expiryDate: false,
      };
    }

    const ignoreKeys = (
      element: IFinancialObligation | ILivingExpenses | IAssets | ICreditCards | IIncomeSources,
      keys: string[]
    ) => {
      const elem = deepCopyObject(element);
      for (const key of keys) {
        if (elem[key] !== undefined) {
          delete elem[key];
        }
      }
      return elem;
    };
    if (
      action.childArray === "financialObligation" ||
      action.childArray === "livingExpenses" ||
      action.childArray === "creditCards" ||
      action.childArray === "incomeSources" ||
      action.childArray === "assets"
    ) {
      if (
        applicant[currentState.currentTab][action.childArray][index] &&
        applicant[currentState.currentTab].provisoData[action.childArray][index] &&
        applicant[currentState.currentTab][action.childArray][index].provisoOrManual ===
          `${provisoOrManual[0]}` &&
        !autoAddedNotes.includes(action.stateName)
      ) {
        const note = applicant[currentState.currentTab][action.childArray][index].note;
        const deletedNote =
          applicant[currentState.currentTab][action.childArray][index].deletedNote;
        const partialNoteValue =
          applicant[currentState.currentTab][action.childArray][index].partialNoteValue;
        const deletedPartialNoteValue =
          applicant[currentState.currentTab][action.childArray][index].deletedPartialNoteValue;
        applicant[currentState.currentTab][action.childArray][index].note = "";
        applicant[currentState.currentTab][action.childArray][index].deletedNote = "";
        applicant[currentState.currentTab][action.childArray][index].deletedPartialNoteValue = "";
        applicant[currentState.currentTab][action.childArray][index].partialNoteValue = "";

        const keysToIgnore = [
          "note",
          "partialNoteValue",
          "isLoanRefinanced",
          "myContribution",
          "isCreditCardRefinanced",
        ];
        if (
          !isEqual(
            ignoreKeys(applicant[currentState.currentTab][action.childArray][index], keysToIgnore),
            ignoreKeys(
              applicant[currentState.currentTab].provisoData[action.childArray][index],
              keysToIgnore
            )
          )
        ) {
          applicant[currentState.currentTab][action.childArray][index].noteRequired = true;
          applicant[currentState.currentTab].provisoData[action.childArray][
            index
          ].noteRequired = true;
          noteRequired[index] = true;
        } else {
          noteRequired[index] = false;
          applicant[currentState.currentTab][action.childArray][index].noteRequired = false;
          applicant[currentState.currentTab].provisoData[action.childArray][
            index
          ].noteRequired = false;
        }
        applicant[currentState.currentTab][action.childArray][
          index
        ].deletedPartialNoteValue = deletedPartialNoteValue;
        applicant[currentState.currentTab][action.childArray][
          index
        ].partialNoteValue = partialNoteValue;
        applicant[currentState.currentTab][action.childArray][index].deletedNote = deletedNote;
        applicant[currentState.currentTab][action.childArray][index].note = note;
      }
    }

    if (action.required) {
      if (!action.stateValue && action.stateValue !== 0 && action.required) {
        applicantsRequiredField[currentState.currentTab][action.childArray][index][
          action.stateName
        ] = true;
      } else if (action.regex) {
        const regexp = new RegExp(action.regex);
        applicantsRequiredField[currentState.currentTab][action.childArray][index][
          action.stateName
        ] = regexp.test(`${action.stateValue}`);
      } else {
        applicantsRequiredField[currentState.currentTab][action.childArray][index][
          action.stateName
        ] = false;
      }
    }
    if (
      action.childArray === "applicantDocuments" &&
      action.stateName !== "bankDocumentId" &&
      action.stateName !== "referralCode" &&
      action.stateName !== "provider"
    ) {
      applicant[currentState.currentTab].privacyConsent.idsMatrix = false;
      applicant[currentState.currentTab].privacyConsent.creditScore = 700;
      applicant[currentState.currentTab].privacyConsent.formSubmit = false;
      applicant[currentState.currentTab].privacyConsent.isFormValid = false;
    }
    // @Todo
    buttonUpdate();
  } else if (action.mainState) {
    currentState[action.stateName] = action.stateValue;
    applicant = deepCopyObject(applicant);
    switch (action.stateValue) {
      case howManyApplicant[0].value:
        applicant.splice(1, 6);
        applicant[0].isApplicantGuarantor = "No";
        applicantsRequiredField.splice(1, 15);
        applicantTab = 1;
        currentTab = 0;
        applicant[0].applicantLivingArrangement = livingArrangementItems.Single;

        const rentIndex = findApplicantRentObligation(applicant[0]);

        if (rentIndex > -1 && !rentStatuses.includes(applicant[0].applicantStatus)) {
          const newState = financialObligationRentUpdate(currentState, {
            index: 0,
            operation: "remove",
            module: [],
            howManyApplicant: "Single",
            type: "APPLICANT_STATUS_RENT_UPDATE",
          });

          applicant[0] = newState.applicants[0];
        }
        break;
      case howManyApplicant[1].value:
        applicantTab = maxApplicantTab;
        currentTab = 0;
        if (applicant.length < 2) {
          applicant = applicant.concat([{ ...applicants }]);
          applicant[1].isApplicantGuarantor = "No";
          applicantsRequiredField = applicantsRequiredField.concat([
            { ...applicantsRequiredFieldList },
          ]);
        }
        applicant.map((applicant, index) => {
          if (index > 1) {
            applicant.isApplicantGuarantor = "Yes";
          } else {
            applicant.isApplicantGuarantor = "No";
          }
        });
        applicant[1].dependents = applicant[0].dependents;
        applicant[1].applicantLivingArrangement = livingArrangementItems.Couple;
        applicant[0].applicantLivingArrangement = livingArrangementItems.Couple;

        const firstApplicantRentIndex = findApplicantRentObligation(applicant[0]);

        if (
          (rentStatuses.includes(applicant[0].applicantStatus) ||
            rentStatuses.includes(applicant[1].applicantStatus)) &&
          firstApplicantRentIndex < 0
        ) {
          const newState = financialObligationRentUpdate(currentState, {
            index: 0,
            operation: "add",
            module: [],
            howManyApplicant: "Couple",
            type: "APPLICANT_STATUS_RENT_UPDATE",
            rent: 150,
          });
          applicant[0] = newState.applicants[0];
          if (applicant.length >= 2) {
            removeRentFromApplicant(applicant, 1);
          }
        }
        break;
      case howManyApplicant[2].value:
        applicantTab = maxApplicantTab;
        currentTab = 0;
        if (applicant.length === 1) {
          applicant = applicant.concat([{ ...applicants }]);
          applicantsRequiredField = applicantsRequiredField.concat([
            { ...applicantsRequiredFieldList },
          ]);
        }

        applicant.map((member, index) => {
          member.applicantLivingArrangement = livingArrangementItems.Single;
          if (index > 0) {
            member.isApplicantGuarantor = "No";
          }

          if (rentStatuses.includes(member.applicantStatus)) {
            const newState = financialObligationRentUpdate(
              { ...currentState, applicants: applicant },
              {
                index,
                operation: "add",
                module: [],
                howManyApplicant: "Single",
                type: "APPLICANT_STATUS_RENT_UPDATE",
                rent: 150,
              }
            );
            member = newState.applicants[index];
          } else {
            removeRentFromApplicant(applicant, index);
          }
        });
        break;
    }
  } else {
    if (
      action.stateName !== "bankDocumentId" &&
      action.stateName !== "referralCode" &&
      action.stateName !== "provider" &&
      action.stateName !== "previousSplitAddress" &&
      action.stateName !== "previousAddress" &&
      action.stateName !== "currentSplitAddress" &&
      action.stateName !== "currentAddress" &&
      applicant[currentState.currentTab][action.stateName] !== action.stateValue
    ) {
      applicant[currentState.currentTab].privacyConsent.idsMatrix = false;
      applicant[currentState.currentTab].privacyConsent.creditScore = 700;
      applicant[currentState.currentTab].privacyConsent.formSubmit = false;
      applicant[currentState.currentTab].privacyConsent.isFormValid = false;
    }

    if (action.required || action.regex) {
      if (!action.stateValue && action.stateValue !== 0 && action.required) {
        applicantsRequiredField[currentState.currentTab][action.stateName] = true;
      } else if (
        action.regex &&
        action.stateName !== "homePhone" &&
        action.stateName !== "middleName"
      ) {
        const regexp = new RegExp(action.regex);
        applicantsRequiredField[currentState.currentTab][action.stateName] = !regexp.test(
          `${action.stateValue}`
        );
      } else if (action.regex && action.stateName === "middleName") {
        const regexp = new RegExp(action.regex);
        applicantsRequiredField[currentState.currentTab][action.stateName] =
          action.stateValue !== "" ? !regexp.test(`${action.stateValue}`) : false;
      } else if (action.stateName === "homePhone") {
        if (action.stateValue === "") {
          applicant[currentState.currentTab].homePhoneInvalid = false;
        } else {
          const regexp = new RegExp(action.regex as RegExp);
          applicant[currentState.currentTab].homePhoneInvalid = !regexp.test(
            `${action.stateValue}`
          );
        }
        applicant[
          currentState.currentTab
        ].privacyConsent.isFormValid = checkApplicantFormFieldOrNot(
          applicantsRequiredField[currentState.currentTab],
          applicant[currentState.currentTab]
        );
      } else {
        applicantsRequiredField[currentState.currentTab][action.stateName] = false;
      }
    }
    if (action.stateName === "referralCode" || action.stateName === "provider") {
      const index = action.childIndex as number;
      applicant[currentState.currentTab].retrieveBankData[index][
        action.stateName
      ] = action.stateValue as string;
    }
    if (action.stateName === "bankDocumentId") {
      const index = action.childIndex as number;
      applicant[currentState.currentTab].retrieveBankData[index][
        action.stateName
      ] = action.stateValue as string;
      applicant = retrieveBankDataValidations(
        applicant,
        currentState.currentTab,
        action.stateValue as string,
        index
      );
    }
    if (action.stateName === "employment") {
      applicant[currentState.currentTab][action.stateName] = action.stateValue as [];
    } else {
      applicant[currentState.currentTab][action.stateName] = action.stateValue;
    }

    // @Todo
    buttonUpdate();
  }

  if (action.childArray === "incomeSources") {
    applicant[currentState.currentTab].incomeSourcesNoteRequired = noteRequired;
  } else if (action.childArray === "financialObligation") {
    applicant[currentState.currentTab].financialObligationNoteRequired = noteRequired;
  } else if (action.childArray === "livingExpenses") {
    applicant[currentState.currentTab].livingExpensesNoteRequired = noteRequired;
  } else if (action.childArray === "creditCards") {
    applicant[currentState.currentTab].creditCardsNoteRequired = noteRequired;
  } else if (action.childArray === "assets") {
    applicant[currentState.currentTab].assetsNoteRequired = noteRequired;
  }

  // ToDo: Code Block for Moved In Date Difference Determination.
  if (action.stateName === "movedInDate") {
    if (applicant[currentState.currentTab].movedInDate !== null) {
      const movedInDateDiff = dayjs().diff(
        dayjs(applicant[currentState.currentTab].movedInDate as string),
        "year",
        true
      );
      applicant[currentState.currentTab].movedInDateDiff = movedInDateDiff;
      applicant[currentState.currentTab].previousAddressMovedInDate = null;
      applicant[currentState.currentTab].previousAddress = "";
    }
  }
  // ToDo: Code Block for previousAddress & previousAddressMovedInDate required field toggler
  if (
    action.stateName === "movedInDate" &&
    action.required &&
    applicant[currentState.currentTab].previousAddressMovedInDate !== ""
  ) {
    if (applicant[currentState.currentTab].movedInDateDiff > 3) {
      applicantsRequiredField[currentState.currentTab].previousAddress = false;
      applicantsRequiredField[currentState.currentTab].previousAddressMovedInDate = false;
    } else {
      applicantsRequiredField[currentState.currentTab].previousAddress = true;
      applicantsRequiredField[currentState.currentTab].previousAddressMovedInDate = true;
    }
  }
  if (!action.mainState) {
    JSON.parse(JSON.stringify(applicant));
    const applicantFormValid: boolean = checkApplicantFormFieldOrNot(
      applicantsRequiredField[currentState.currentTab],
      applicant[currentState.currentTab]
    ); // validate the applicant form field are fill or not
    applicant[currentState.currentTab].privacyConsent.isFormValid = applicantFormValid;
  }
  // to check the uniqueness of applicants email addresses
  if (action.stateName === "applicantEmail") {
    let duplicateCount = 0;
    applicant.forEach((eachApplicant, index) => {
      // check for duplicates
      if (
        action.stateValue === eachApplicant.applicantEmail &&
        eachApplicant.applicantEmail !== ""
      ) {
        eachApplicant.isEmailDuplicate = true;
        duplicateCount++;
      }
      // check for any other duplicates other than the first one
      else if (
        action.stateValue !== eachApplicant.applicantEmail &&
        index !== currentState.currentTab &&
        eachApplicant.applicantEmail !== ""
      ) {
        const anotherDuplicate = applicant.find(
          (app, innerIndex) =>
            app.applicantEmail === eachApplicant.applicantEmail && innerIndex !== index
        );
        eachApplicant.isEmailDuplicate = Boolean(anotherDuplicate);
      }
    });
    applicant[currentState.currentTab].isEmailDuplicate = duplicateCount > 1;
  }
  // to check the uniqueness of applicants mobile phone numbers
  if (action.stateName === "mobilePhone") {
    let duplicateCount = 0;
    applicant.forEach((eachApplicant, index) => {
      // check for duplicates
      if (action.stateValue === eachApplicant.mobilePhone && eachApplicant.mobilePhone !== "") {
        eachApplicant.isMobilePhoneDuplicate = true;
        duplicateCount++;
      }
      // check for any other duplicates other than the first one
      else if (
        action.stateValue !== eachApplicant.mobilePhone &&
        index !== currentState.currentTab &&
        eachApplicant.mobilePhone !== ""
      ) {
        const anotherDuplicate = applicant.find(
          (app, innerIndex) => app.mobilePhone === eachApplicant.mobilePhone && innerIndex !== index
        );
        eachApplicant.isMobilePhoneDuplicate = Boolean(anotherDuplicate);
      }
    });
    applicant[currentState.currentTab].isMobilePhoneDuplicate = duplicateCount > 1;
  }

  if (
    action.childArray &&
    currentState.currentTab === 0 &&
    (action.stateName === "amount" ||
      action.stateName === "balance" ||
      action.stateName === "type" ||
      action.stateName === "value" ||
      action.stateName === "isLoanRefinanced")
  ) {
    currentState.primaryAffordabilityRequiredFields = affordabilityRequiredFields({
      childArray: action.childArray,
      applicant: applicant[0],
      primaryAffordabilityRequiredFields: currentState.primaryAffordabilityRequiredFields,
    });
  }

  return {
    ...currentState,
    applicants: applicant,
    applicantsRequiredField,
    currentTab,
    maxApplicantTab: applicantTab,
  };
};

interface IAffordabilityRequiredFieldsInterface {
  applicant: IApplicant;
  primaryAffordabilityRequiredFields: IAffordabilityRequiredFields;
  checkAll?: boolean;
  childArray?: string;
}
export const affordabilityRequiredFields = ({
  childArray,
  applicant,
  primaryAffordabilityRequiredFields,
  checkAll,
}: IAffordabilityRequiredFieldsInterface) => {
  // check minimum affordability requirements

  if ((childArray && childArray === "incomeSources") || checkAll) {
    const primaryApplicantIncomeCount = applicant.incomeSources
      ? applicant.incomeSources.filter(
          (source) => source.type && source.amount && !source.isDeleted
        ).length
      : 0;
    const remainingIncomeSources = MINIMUM_INCOME_SOURCES - primaryApplicantIncomeCount;
    primaryAffordabilityRequiredFields.count.incomeSources =
      remainingIncomeSources <= 0 ? 0 : remainingIncomeSources;
    primaryAffordabilityRequiredFields.required.incomeSources =
      primaryAffordabilityRequiredFields.count.incomeSources > 0;
  }

  if ((childArray && childArray === "assets") || checkAll) {
    const primaryApplicantAssetsCount = applicant.assets
      ? applicant.assets.filter((asset) => asset.type && asset.value && !asset.isDeleted).length
      : 0;
    const remainingAssets = MINIMUM_ASSETS - primaryApplicantAssetsCount;
    primaryAffordabilityRequiredFields.count.assets = remainingAssets <= 0 ? 0 : remainingAssets;
    primaryAffordabilityRequiredFields.required.assets =
      primaryAffordabilityRequiredFields.count.assets > 0;
  }

  if ((childArray && childArray === "livingExpenses") || checkAll) {
    const primaryApplicantExpenseCount = applicant.livingExpenses
      ? applicant.livingExpenses.filter(
          (expense) => expense.type && expense.amount && !expense.isDeleted
        ).length
      : 0;
    const remainingExpenses = MINIMUM_LIVING_EXPENSES - primaryApplicantExpenseCount;
    primaryAffordabilityRequiredFields.count.livingExpenses =
      remainingExpenses <= 0 ? 0 : remainingExpenses;
    primaryAffordabilityRequiredFields.required.livingExpenses =
      primaryAffordabilityRequiredFields.count.livingExpenses > 0;
  }

  if ((childArray && childArray === "financialObligation") || checkAll) {
    const remainingRefinancedLoan = remainingFinancialObligations(applicant);
    primaryAffordabilityRequiredFields.count.refinancedLoan =
      remainingRefinancedLoan <= 0 ? 0 : remainingRefinancedLoan;
    primaryAffordabilityRequiredFields.required.refinancedLoan =
      primaryAffordabilityRequiredFields.count.refinancedLoan > 0;
  }

  return primaryAffordabilityRequiredFields;
};

export const applicantRequiredFields = (currentState: IApplicantInitialState, loanTerm: number) => {
  const applicantRequiredFields = currentState.applicantsRequiredField;
  const applicants = currentState.applicants;
  currentState.applicants.map((applicant: IApplicant, index: number) => {
    if (isApplicantOld(applicant.dateOfBirth) && loanTerm >= 17) {
      applicantRequiredFields[index].earlyRepaymentStrategy =
        applicant.earlyRepaymentStrategy === "";
      applicantRequiredFields[index].earlyRepaymentStrategyNote =
        applicant.earlyRepaymentStrategyNote === "";
    } else {
      applicantRequiredFields[index].earlyRepaymentStrategy = false;
      applicantRequiredFields[index].earlyRepaymentStrategyNote = false;
      applicants[index].earlyRepaymentStrategy = "";
      applicants[index].earlyRepaymentStrategyNote = "";
    }
  });
  return {
    ...currentState,
    applicantRequiredFields,
    applicants,
  };
};
export const remainingFinancialObligations = (applicant: IApplicant) => {
  const primaryApplicantLoanCount = applicant.financialObligation
    ? applicant.financialObligation.filter(
        (obligation) =>
          (obligation.type === applicantFinancialObligationLoanTypeOptions[0] ||
            obligation.type === applicantFinancialObligationLoanTypeOptions[3]) &&
          obligation.balance &&
          obligation.isLoanRefinanced &&
          !obligation.isDeleted
      ).length
    : 0;
  return MINIMUM_REFINANCED_LOAN - primaryApplicantLoanCount;
};
export const getCreditScoreAndVerifyIdentity = (
  action: ApplicantGetCreditScoreAndVerifyIdentityAction,
  state: IApplicantInitialState
) => {
  const currentState = Object.assign(state);
  const applicant: IApplicant[] = [...currentState.applicants];
  applicant[currentState.currentTab].privacyConsent.idsMatrix = action.idsMatrix;
  applicant[currentState.currentTab].privacyConsent.formSubmit = action.formSubmit;
  applicant[currentState.currentTab].privacyConsent.creditScore = action.creditScore;
  applicant[currentState.currentTab].privacyConsent.isCreditCheckOverriden =
    action.isCreditCheckOverriden;
  applicant[currentState.currentTab].privacyConsent.isCreditCheckDoneManually =
    action.isCreditCheckDoneManually;
  return { ...currentState, applicants: applicant };
};

export const applicantAddNew = (action: ApplicantAddNewAction, state: IApplicantInitialState) => {
  const currentState: IApplicantInitialState = Object.assign(state);

  let applicant: IApplicant[] = [...currentState.applicants];
  let applicantsRequiredField: IApplicantRequiredField[] = [
    ...currentState.applicantsRequiredField,
  ];

  let assetsRowAdded: boolean[] = [...currentState.assetsRowAdded];
  let incomeSourcesRowAdded: boolean[] = [...currentState.incomeSourcesRowAdded];
  let livingExpensesRowAdded: boolean[] = [...currentState.livingExpensesRowAdded];
  let financialObligationRowAdded: boolean[] = [...currentState.financialObligationRowAdded];
  let creditCardsRowAdded: boolean[] = [...currentState.creditCardsRowAdded];

  assetsRowAdded.push(false);
  incomeSourcesRowAdded.push(false);
  livingExpensesRowAdded.push(false);
  financialObligationRowAdded.push(false);
  creditCardsRowAdded.push(false);

  applicant.push({ ...applicants });
  applicant = JSON.parse(JSON.stringify(applicant));
  applicantsRequiredField = applicantsRequiredField.concat([{ ...applicantsRequiredFieldList }]);

  assetsRowAdded = JSON.parse(JSON.stringify(assetsRowAdded));
  incomeSourcesRowAdded = JSON.parse(JSON.stringify(incomeSourcesRowAdded));
  livingExpensesRowAdded = JSON.parse(JSON.stringify(livingExpensesRowAdded));
  financialObligationRowAdded = JSON.parse(JSON.stringify(financialObligationRowAdded));
  creditCardsRowAdded = JSON.parse(JSON.stringify(creditCardsRowAdded));
  applicant.map((applicant, index) => {
    if (index > 0 && currentState.howManyApplicant === howManyApplicant[2].value) {
      // applicant.isApplicantGuarantor = "No";
      applicant.isApplicantGuarantor = applicant.isApplicantGuarantor;
    } else if (index > 1) {
      applicant.isApplicantGuarantor = "Yes";
    }
  });

  return {
    ...currentState,
    applicants: applicant,
    applicantsRequiredField,
    assetsRowAdded,
    creditCardsRowAdded,
    financialObligationRowAdded,
    incomeSourcesRowAdded,
    livingExpensesRowAdded,
  };
};

export const applicantRemove = (action: ApplicantRemoveAction, state: IApplicantInitialState) => {
  const currentState: IApplicantInitialState = Object.assign(state);
  const applicant: IApplicant[] = [...currentState.applicants];
  const applicantsRequiredField: IApplicantRequiredField[] = [
    ...currentState.applicantsRequiredField,
  ];
  const assetsRowAdded: boolean[] = [...currentState.assetsRowAdded];
  const incomeSourcesRowAdded: boolean[] = [...currentState.incomeSourcesRowAdded];
  const livingExpensesRowAdded: boolean[] = [...currentState.livingExpensesRowAdded];
  const financialObligationRowAdded: boolean[] = [...currentState.financialObligationRowAdded];
  const creditCardsRowAdded: boolean[] = [...currentState.creditCardsRowAdded];
  let currentTab: number = currentState.currentTab;
  if (applicant.length > 1) {
    if (currentTab > 0) {
      currentTab = currentTab - 1;
    }

    applicant.splice(action.removeIndex, 1);
    applicant[0].isApplicantGuarantor = "No";
    applicantsRequiredField.splice(action.removeIndex, 1);
    assetsRowAdded.splice(action.removeIndex, 1);
    incomeSourcesRowAdded.splice(action.removeIndex, 1);
    livingExpensesRowAdded.splice(action.removeIndex, 1);
    financialObligationRowAdded.splice(action.removeIndex, 1);
    creditCardsRowAdded.splice(action.removeIndex, 1);

    if (currentState.howManyApplicant === "Couple" && action.removeIndex < 2) {
      applicant[1].dependents = applicant[0].dependents;
      applicant[0].isApplicantGuarantor = "No";
      applicant[1].isApplicantGuarantor = "No";
      applicant[1].applicantLivingArrangement = livingArrangementItems.Couple;
      applicant[0].applicantLivingArrangement = livingArrangementItems.Couple;
    }
  }
  applicant.forEach((singleApplicant, applicantIndex) => {
    let duplicateCount = 0;
    applicant.forEach((eachApplicant, index) => {
      // check for duplicates
      if (
        singleApplicant.applicantEmail === eachApplicant.applicantEmail &&
        eachApplicant.applicantEmail !== "" &&
        applicantIndex !== index
      ) {
        eachApplicant.isEmailDuplicate = true;
        duplicateCount++;
      }
      // check for any other duplicates other than the first one
      else if (
        singleApplicant.applicantEmail !== eachApplicant.applicantEmail &&
        index !== currentState.currentTab &&
        eachApplicant.applicantEmail !== "" &&
        applicantIndex !== index
      ) {
        const anotherDuplicate = applicant.find(
          (app, innerIndex) =>
            app.applicantEmail === eachApplicant.applicantEmail && innerIndex !== index
        );
        eachApplicant.isEmailDuplicate = Boolean(anotherDuplicate);
      }
    });
    if (currentState.currentTab !== applicant.length) {
      applicant[currentState.currentTab].isEmailDuplicate = duplicateCount > 1;
    } else {
      applicant[currentState.currentTab - 1].isEmailDuplicate = duplicateCount > 1;
    }
  });
  applicant.forEach((singleApplicant, applicantIndex) => {
    let duplicateCount = 0;
    applicant.forEach((eachApplicant, index) => {
      // check for duplicates
      if (
        singleApplicant.mobilePhone === eachApplicant.mobilePhone &&
        eachApplicant.mobilePhone !== "" &&
        applicantIndex !== index
      ) {
        eachApplicant.isMobilePhoneDuplicate = true;
        duplicateCount++;
      }
      // check for any other duplicates other than the first one
      else if (
        singleApplicant.mobilePhone !== eachApplicant.mobilePhone &&
        index !== currentState.currentTab &&
        eachApplicant.mobilePhone !== "" &&
        applicantIndex !== index
      ) {
        const anotherDuplicate = applicant.find(
          (app, innerIndex) => app.mobilePhone === eachApplicant.mobilePhone && innerIndex !== index
        );
        eachApplicant.isMobilePhoneDuplicate = Boolean(anotherDuplicate);
      }
    });
    if (currentState.currentTab !== applicant.length) {
      applicant[currentState.currentTab].isMobilePhoneDuplicate = duplicateCount > 1;
    } else {
      applicant[currentState.currentTab - 1].isMobilePhoneDuplicate = duplicateCount > 1;
    }
  });

  currentState.primaryAffordabilityRequiredFields = affordabilityRequiredFields({
    checkAll: true,
    applicant: applicant[0],
    primaryAffordabilityRequiredFields: currentState.primaryAffordabilityRequiredFields,
  });

  return {
    ...currentState,
    applicants: applicant,
    applicantsRequiredField,
    assetsRowAdded,
    creditCardsRowAdded,
    currentTab,
    financialObligationRowAdded,
    incomeSourcesRowAdded,
    livingExpensesRowAdded,
  };
};

export const applicantChildAddNewItem = (
  action: ApplicantAddNewAction,
  state: IApplicantInitialState
) => {
  const currentState: IApplicantInitialState = Object.assign(state);
  const applicant: IApplicant[] = [...currentState.applicants];
  const applicantsRequiredField: IApplicantRequiredField[] = [
    ...currentState.applicantsRequiredField,
  ];
  const assetsRowAdded: boolean[] = [...currentState.assetsRowAdded];
  const incomeSourcesRowAdded: boolean[] = [...currentState.incomeSourcesRowAdded];
  const livingExpensesRowAdded: boolean[] = [...currentState.livingExpensesRowAdded];
  const financialObligationRowAdded: boolean[] = [...currentState.financialObligationRowAdded];
  const creditCardsRowAdded: boolean[] = [...currentState.creditCardsRowAdded];

  let childElement;
  let childProvisoElement;
  let childElementRequiredField;

  if (action.childArrayName === "dependents") {
    childElement = [...currentState.applicants[currentState.currentTab].dependents];
    childElement = childElement.concat([{ ...dependentData }]);
    if (currentState.howManyApplicant === "Couple" && currentState.currentTab < 2) {
      // add for couple

      let childElementForCouple = currentState.currentTab
        ? [...currentState.applicants[0].dependents]
        : [...currentState.applicants[1].dependents];
      childElementForCouple = childElement;
      const coupleIndex = currentState.currentTab ? 0 : 1;
      applicant[coupleIndex][action.childArrayName] = childElementForCouple;
    }
  } else if (action.childArrayName === "assets") {
    childElement = [...currentState.applicants[currentState.currentTab].assets];
    childElement = childElement.concat([{ ...assetsData }]);
    childProvisoElement = [...currentState.applicants[currentState.currentTab].provisoData.assets];
    childProvisoElement = childProvisoElement.concat([{ ...assetsData }]);
    applicant[currentState.currentTab].assetsNoteRequired[childProvisoElement.length - 1] = false;
    if (childElement.length === 1) {
      assetsRowAdded[currentState.currentTab] = true;
    }
  } else if (action.childArrayName === "creditCards") {
    childElement = [...currentState.applicants[currentState.currentTab].creditCards];

    childElement = childElement.concat([{ ...creditCardsData }]);
    childProvisoElement = [
      ...currentState.applicants[currentState.currentTab].provisoData.creditCards,
    ];
    childProvisoElement = childProvisoElement.concat([{ ...creditCardsData }]);
    applicant[currentState.currentTab].creditCardsNoteRequired[
      childProvisoElement.length - 1
    ] = false;
    if (childElement.length === 1) {
      creditCardsRowAdded[currentState.currentTab] = true;
    }
  } else if (action.childArrayName === "financialObligation") {
    childElement = [...currentState.applicants[currentState.currentTab].financialObligation];

    childElement = childElement.concat([{ ...financialObligationData }]);
    childProvisoElement = [
      ...currentState.applicants[currentState.currentTab].provisoData.financialObligation,
    ];
    childProvisoElement = childProvisoElement.concat([{ ...financialObligationData }]);
    applicant[currentState.currentTab].financialObligationNoteRequired[
      childProvisoElement.length - 1
    ] = false;
    if (childElement.length === 1) {
      financialObligationRowAdded[currentState.currentTab] = true;
    }
  } else if (action.childArrayName === "incomeSources") {
    childElement = [...currentState.applicants[currentState.currentTab].incomeSources];

    childElement = childElement.concat([{ ...incomeSourcesData }]);
    childProvisoElement = [
      ...currentState.applicants[currentState.currentTab].provisoData.incomeSources,
    ];
    childProvisoElement = childProvisoElement.concat([{ ...incomeSourcesData }]);
    applicant[currentState.currentTab].incomeSourcesNoteRequired[
      childProvisoElement.length - 1
    ] = false;
    if (childElement.length === 1) {
      incomeSourcesRowAdded[currentState.currentTab] = true;
    }
  } else if (action.childArrayName === "livingExpenses") {
    childElement = [...currentState.applicants[currentState.currentTab].livingExpenses];

    childElement = childElement.concat([{ ...livingExpensesData }]);
    childProvisoElement = [
      ...currentState.applicants[currentState.currentTab].provisoData.livingExpenses,
    ];
    childProvisoElement = childProvisoElement.concat([{ ...livingExpensesData }]);
    applicant[currentState.currentTab].livingExpensesNoteRequired[
      childProvisoElement.length - 1
    ] = false;
    if (childElement.length === 1) {
      livingExpensesRowAdded[currentState.currentTab] = true;
    }
  } else if (action.childArrayName === "employment") {
    childElement = [...currentState.applicants[currentState.currentTab].employment];
    if (action.value) {
      childElement[action.index ? action.index : 0] = action.value;
    }
    currentState.applicants[currentState.currentTab].privacyConsent.formSubmit = false;
    currentState.applicantsRequiredField[currentState.currentTab].employment =
      childElement.length === 0;
  } else if (action.childArrayName === "applicantDocuments") {
    childElement = [...currentState.applicants[currentState.currentTab].applicantDocuments];
    childElementRequiredField = [
      ...currentState.applicantsRequiredField[currentState.currentTab].applicantDocuments,
    ];
    const presentElements = childElement.map((item) => {
      return item.documentType;
    });
    let itemToBeAdded;
    for (const document of documentTypeIndex) {
      if (presentElements.indexOf(document) === -1) {
        itemToBeAdded = document;
      }
    }
    switch (itemToBeAdded) {
      case documentTypeIndex[0]:
        childElement = childElement.concat([{ ...applicantDocumentsData }]);
        childElementRequiredField = childElementRequiredField.concat([
          { ...documentRequiredField },
        ]);
        break;
      case documentTypeIndex[1]:
        childElement = childElement.concat([{ ...applicantDocumentsSecondData }]);
        childElementRequiredField = childElementRequiredField.concat([
          { ...medicareRequiredField },
        ]);
        break;
      case documentTypeIndex[2]:
        childElement = childElement.concat([{ ...applicantDocumentsLastData }]);
        childElementRequiredField = childElementRequiredField.concat([
          { ...passportRequiredField },
        ]);
    }

    applicantsRequiredField[currentState.currentTab][
      action.childArrayName
    ] = childElementRequiredField;
  } else {
    return { ...currentState };
  }

  const noteInfo = { ...currentState.noteInformation };
  noteInfo.index = childElement.length - 1;
  // @ts-ignore
  applicant[currentState.currentTab][action.childArrayName] = childElement;
  applicant[currentState.currentTab].provisoData[action.childArrayName] = childProvisoElement;
  applicant[currentState.currentTab].privacyConsent.isFormValid = checkApplicantFormFieldOrNot(
    applicantsRequiredField[currentState.currentTab],
    applicant[currentState.currentTab]
  );
  return {
    ...currentState,
    applicants: applicant,
    applicantsRequiredField,
    assetsRowAdded,
    creditCardsRowAdded,
    financialObligationRowAdded,
    incomeSourcesRowAdded,
    livingExpensesRowAdded,
    noteInformation: noteInfo,
  };
};

export const applicantChildRemoveItem = (
  action: ApplicantRemoveAction,
  state: IApplicantInitialState
) => {
  const currentState = Object.assign(state);
  const applicant: IApplicant[] = [...currentState.applicants];
  let incomeSourcesNoteRequired = [...applicant[currentState.currentTab].incomeSourcesNoteRequired];
  const applicantsRequiredField: IApplicantRequiredField[] = [
    ...currentState.applicantsRequiredField,
  ];
  let rowAdded;
  let childElement;
  let childProvisoElement;
  let childElementRequiredField;
  let childNoteRequiredField;

  // if array name and childDeletedArrayName is present.
  if (action.childArrayName && action.childDeletedArrayName) {
    rowAdded = [...currentState[`${action.childArrayName}RowAdded`]];

    // if the row deleted is manual
    if (
      applicant[currentState.currentTab][action.childArrayName][action.removeIndex]
        .provisoOrManual === `${provisoOrManual[1]}`
    ) {
      childElement = [...applicant[currentState.currentTab][action.childArrayName]];
      if (action.childArrayName === "incomeSources") {
        incomeSourcesNoteRequired.splice(action.removeIndex, 1);
      }

      childElement.splice(action.removeIndex, 1);
      childProvisoElement = [
        ...applicant[currentState.currentTab].provisoData[action.childArrayName],
      ];
      childProvisoElement.splice(action.removeIndex, 1);
    }
    // if the deleted item is from Proviso
    else {
      childProvisoElement = [
        ...applicant[currentState.currentTab].provisoData[action.childArrayName],
      ];
      childElement = [...applicant[currentState.currentTab][action.childArrayName]];
      childElement[action.removeIndex].isDeleted = true;
      childElement[action.removeIndex].deletedNoteRequired = true;
      const noteRequiredArray = action.childArrayName + "NoteRequired";
      const childElementRequiredFieldArray = [
        ...applicant[currentState.currentTab][noteRequiredArray],
      ];
      childElementRequiredFieldArray[action.removeIndex] = false;
      childNoteRequiredField = [
        ...applicant[currentState.currentTab][action.childDeletedArrayName],
      ];
      childNoteRequiredField[action.removeIndex] = true;
      applicant[currentState.currentTab][action.childDeletedArrayName] = childNoteRequiredField;
      applicant[currentState.currentTab][noteRequiredArray] = childElementRequiredFieldArray;
      if (action.childArrayName === "incomeSources") {
        incomeSourcesNoteRequired = childElementRequiredFieldArray;
      }
    }

    if (childElement.length === 0) {
      rowAdded[currentState.currentTab] = false;
    }
  } else if (action.childArrayName === "applicantDocuments") {
    childElement = [...applicant[currentState.currentTab].applicantDocuments];
    childElement.splice(action.removeIndex, 1);

    childElementRequiredField = [
      ...currentState.applicantsRequiredField[currentState.currentTab].applicantDocuments,
    ];
    childElementRequiredField.splice(action.removeIndex, 1);
    applicantsRequiredField[currentState.currentTab][
      action.childArrayName
    ] = childElementRequiredField;
  } else if (action.childArrayName === "dependents") {
    childElement = [...applicant[currentState.currentTab].dependents];
    childElement.splice(action.removeIndex, 1);
    if (currentState.howManyApplicant === "Couple" && currentState.currentTab < 2) {
      // remove for couple

      let childElementForCouple = currentState.currentTab
        ? [...currentState.applicants[0].dependents]
        : [...currentState.applicants[1].dependents];
      childElementForCouple = childElement;
      const coupleIndex = currentState.currentTab ? 0 : 1;
      applicant[coupleIndex][action.childArrayName] = childElementForCouple;
    }
  } else if (action.childArrayName === "employment") {
    childElement = [...applicant[currentState.currentTab].employment];
    childElement.splice(action.removeIndex, 1);
    applicant[currentState.currentTab].privacyConsent.formSubmit = false;
    if (childElement.length == 0) {
      applicantsRequiredField[currentState.currentTab].employment = true;
    } else {
      applicantsRequiredField[currentState.currentTab].employment = false;
    }
  } else {
    return { ...currentState };
  }

  const noteInfo = { ...currentState.noteInformation };
  noteInfo.index = action.removeIndex;
  applicant[currentState.currentTab].incomeSourcesNoteRequired = incomeSourcesNoteRequired;
  applicant[currentState.currentTab][action.childArrayName] = childElement;
  applicant[currentState.currentTab].provisoData[action.childArrayName] = childProvisoElement;
  applicant[currentState.currentTab].privacyConsent.isFormValid = checkApplicantFormFieldOrNot(
    applicantsRequiredField[currentState.currentTab],
    applicant[currentState.currentTab]
  );

  if (action.childArrayName && currentState.currentTab === 0) {
    currentState.primaryAffordabilityRequiredFields = affordabilityRequiredFields({
      childArray: action.childArrayName,
      applicant: applicant[0],
      primaryAffordabilityRequiredFields: currentState.primaryAffordabilityRequiredFields,
    });
  }

  return {
    ...currentState,
    applicants: applicant,
    applicantsRequiredField,
    noteInformation: noteInfo,
    [`${action.childArrayName}RowAdded`]: rowAdded,
  };
};

export const autoSuggestLoader = (
  action: ApplicantAddressLoaderAction,
  state: IApplicantInitialState
) => {
  const currentState = Object.assign(state);
  const index = action.childIndex as number;
  const applicant: IApplicant[] = [...currentState.applicants];
  let childElement;
  if (index >= 0) {
    childElement = [...currentState.applicants[action.currentTab].assets];
    childElement[index].loader = action.loader;
    applicant[action.currentTab].assets = childElement;
  } else {
    if (action.stateName === "currentAddress") {
      applicant[action.currentTab].currentAddressLoader = action.loader;
    } else {
      applicant[action.currentTab].previousAddressLoader = action.loader;
    }
  }

  return { ...currentState, applicants: applicant };
};

export const setProbationWarning = (
  action: ApplicantSetProbationWarningAction,
  state: IApplicantInitialState
) => {
  const currentState = Object.assign(state);
  const currentTab = action.currentTab as number;
  const applicantRequiredFields: IApplicantRequiredField[] = [
    ...currentState.applicantsRequiredField,
  ];

  if (action.childArrayName === "probation") {
    applicantRequiredFields[currentTab].isProbationOn = action.value;
  }

  return { ...currentState, applicantsRequiredField: applicantRequiredFields };
};

export const applicantAddRetrieveDataItem = (
  action: ApplicantAddRetrieveDataAction,
  state: IApplicantInitialState
) => {
  const currentState = Object.assign(state);
  let applicant: IApplicant[] = [...currentState.applicants];
  const index = currentState.currentTab;
  const retrieveData = [...applicant[index].retrieveBankData];
  const dataObject: IRetrieveBankData = { ...retrieveDataObject };
  dataObject.bankDocumentId = "";
  retrieveData.push(dataObject);
  applicant[index].retrieveBankData = retrieveData;
  applicant = retrieveBankDataValidations(
    applicant,
    currentState.currentTab,
    dataObject.bankDocumentId,
    retrieveData.length - 1
  );
  return { ...currentState, applicants: applicant };
};

export const applicantRemoveRetrieveDataItem = (
  action: ApplicantRemoveRetrieveDataAction,
  state: IApplicantInitialState
) => {
  const currentState = Object.assign(state);
  const applicant: IApplicant[] = [...currentState.applicants];
  const index = currentState.currentTab;
  const retrieveData = [...applicant[index].retrieveBankData];
  removeRetrievedDataItems(action, state);
  retrieveData.splice(action.index, 1);
  applicant[index].retrieveBankData = retrieveBankDataValidationsForDeletion(
    retrieveData,
    retrieveData.length
  );
  return { ...currentState, applicants: applicant };
};

const removeRetrievedDataItems = (
  action: ApplicantRemoveRetrieveDataAction,
  state: IApplicantInitialState
) => {
  const applicant: IApplicant[] = [...state.applicants];
  const index = state.currentTab;
  const selectedIllionId = applicant[index].retrieveBankData[action.index].bankDocumentId;
  const illionBankDataFetched =
    applicant[index].retrieveBankData[action.index].illionBankDataFileName != "";

  if (illionBankDataFetched) {
    const updatedAssets = applicant[index].assets.filter(
      (asset) => asset.illionId != selectedIllionId
    );
    const updatedProvisoAssets = applicant[index].provisoData.assets.filter(
      (asset) => asset.illionId != selectedIllionId
    );
    const updatedIncomeSources = applicant[index].incomeSources.filter(
      (incomeSource) => incomeSource.illionId != selectedIllionId
    );
    const updatedProvisoincomeSources = applicant[index].provisoData.incomeSources.filter(
      (incomeSource) => incomeSource.illionId != selectedIllionId
    );
    const updatedCreditCards = applicant[index].creditCards.filter(
      (creditCard) => creditCard.illionId != selectedIllionId
    );
    const updatedProvisocreditCards = applicant[index].provisoData.creditCards.filter(
      (creditCard) => creditCard.illionId != selectedIllionId
    );
    const updatedFinancialObligations = applicant[index].financialObligation.filter(
      (financialObligation) => financialObligation.illionId != selectedIllionId
    );
    const updatedProvisoFinancialObligations = applicant[
      index
    ].provisoData.financialObligation.filter(
      (financialObligation) => financialObligation.illionId != selectedIllionId
    );
    const updatedLivingExpenses = applicant[index].livingExpenses.filter(
      (livingExpense) => livingExpense.illionId != selectedIllionId
    );
    const updatedProvisoLivingExpenses = applicant[index].provisoData.livingExpenses.filter(
      (livingExpense) => livingExpense.illionId != selectedIllionId
    );

    applicant[index].assets = updatedAssets;
    applicant[index].provisoData.assets = updatedProvisoAssets;
    applicant[index].livingExpenses = updatedLivingExpenses;
    applicant[index].provisoData.livingExpenses = updatedProvisoLivingExpenses;
    applicant[index].incomeSources = updatedIncomeSources;
    applicant[index].provisoData.incomeSources = updatedProvisoincomeSources;
    applicant[index].creditCards = updatedCreditCards;
    applicant[index].provisoData.creditCards = updatedProvisocreditCards;
    applicant[index].financialObligation = updatedFinancialObligations;
    applicant[index].provisoData.financialObligation = updatedProvisoFinancialObligations;
  }
};

export const applicantAddressAutoSuggest = (
  action: ApplicantAddressAutoSuggestAction,
  state: IApplicantInitialState
) => {
  const currentState = Object.assign(state);
  const applicant: IApplicant[] = [...currentState.applicants];
  let childElement;
  // Todo fix the any type
  const addressResults: any = action.addressAutoSuggestions;
  const index = action.childIndex as number;
  if (index >= 0) {
    const result = addressResults.map((data: { propertyId: number; address: string }) => ({
      datavalue: data.propertyId,
      primaryText: data.address,
    }));
    childElement = [...currentState.applicants[action.currentTab].assets];
    childElement[index].propertyAutoSuggestList = result;
    applicant[action.currentTab].assets = childElement;
  } else {
    const result = addressResults.map(
      (data: { placeId: number; address: string; split_address: ISplitAddress }) => ({
        placeId: data.placeId,
        primaryText: data.address,
        splitAddress: data.split_address,
        suburb: data.address,
      })
    );
    if (action.stateName === "currentAddress") {
      applicant[action.currentTab].addressAutoSuggest = result;
    } else {
      applicant[action.currentTab].previousAddressAutoSuggest = result;
    }
  }

  return { ...currentState, applicants: applicant };
};

export const applicantUndoReducer = (
  action: ApplicantUndoAction,
  state: IApplicantInitialState
) => {
  const currentState = Object.assign(state);
  const applicant: IApplicant[] = [...currentState.applicants];
  const index = action.childIndex as number;
  applicant[action.currentTab][action.childArray][index].isDeleted = false;
  applicant[action.currentTab][action.childArray][index].deletedNoteRequired = false;
  applicant[action.currentTab][action.childArray][index].deletedPartialNoteValue = "";
  applicant[action.currentTab][action.childArray][index].deletedNote = "";
  applicant[action.currentTab][action.childDeletedArray][index] = false;
  const noteRequiredArray = action.childArray + "NoteRequired";
  applicant[action.currentTab][action.childArray][index].noteRequired
    ? (applicant[action.currentTab][noteRequiredArray][index] = true)
    : (applicant[action.currentTab][noteRequiredArray][index] = false);

  action.childArray === "incomeSources" &&
  applicant[action.currentTab][action.childArray][index].type === "Uncategorised"
    ? (applicant[action.currentTab][noteRequiredArray][index] = true)
    : (applicant[action.currentTab][noteRequiredArray][index] = false);

  if (action.childArray && currentState.currentTab === 0) {
    currentState.primaryAffordabilityRequiredFields = affordabilityRequiredFields({
      childArray: action.childArray,
      applicant: applicant[0],
      primaryAffordabilityRequiredFields: currentState.primaryAffordabilityRequiredFields,
    });
  }

  let total = 0;
  total = getTotalLivingExpense(total, applicant[action.currentTab]);

  applicant[action.currentTab].affordabilityTotals["livingExpenses"].total = total;

  return { ...currentState, applicants: applicant };
};

export const getProperty = (action: ApplicantGetPropertyAction, state: IApplicantInitialState) => {
  const currentState = Object.assign(state);
  const applicant: IApplicant[] = [...currentState.applicants];
  const index = action.childIndex as number;
  let childElement;
  if (index >= 0) {
    childElement = [...currentState.applicants[action.currentTab].assets];
    childElement[index].propertyValue = action.property.estimatedValue;
    applicant[action.currentTab].assets = childElement;
    childElement[index].weeklyRentEstimate = +action.property.weeklyRentEstimate;
  }
  return { ...currentState, applicants: applicant };
};

export const affordabilityTotalsUpdate = (
  action: ApplicantAffordabilityTotalsUpdateAction,
  state: IApplicantInitialState
) => {
  const currentState = Object.assign(state);
  const applicants = [...currentState.applicants];

  const previousTotal = applicants[action.currentTab].affordabilityTotals[action.rowType].total;
  const previousDuration =
    applicants[action.currentTab].affordabilityTotals[action.rowType].duration;
  const previousTaxStatus =
    applicants[action.currentTab].affordabilityTotals[action.rowType].taxStatus;

  if (
    previousTotal === action.total &&
    previousDuration === action.duration &&
    previousTaxStatus === action.taxStatus
  ) {
    return state;
  }

  applicants[action.currentTab].affordabilityTotals[action.rowType].total = action.total;
  applicants[action.currentTab].affordabilityTotals[action.rowType].duration = action.duration;
  applicants[action.currentTab].affordabilityTotals[action.rowType].taxStatus = action.taxStatus;
  return {
    ...state,
    applicants,
  };
};

export const clearAddressResult = (
  action: ApplicantClearAddressResultAction,
  state: IApplicantInitialState
) => {
  const currentState = Object.assign(state);
  const applicant: IApplicant[] = [...currentState.applicants];
  let childElement;
  const index = action.childIndex as number;
  if (index >= 0) {
    childElement = [...currentState.applicants[currentState.currentTab].assets];
    childElement[index].propertyAutoSuggestList = [];
    childElement[index].propertyValue = 0;
    childElement[index].weeklyRentEstimate = 0;
    applicant[currentState.currentTab].assets = childElement;
  } else {
    applicant[currentState.currentTab].addressAutoSuggest = [];
  }
  return { ...currentState, applicants: applicant };
};

export const setProvisoDataReducer = (
  state: IApplicantInitialState,
  action: ApplicantSetProvisioDataAction
) => {
  const currentState: IApplicantInitialState = Object.assign({}, state);
  const index = action.index;
  const assetsRowAdded: boolean[] = [...currentState.assetsRowAdded];
  const incomeSourcesRowAdded: boolean[] = [...currentState.incomeSourcesRowAdded];
  const livingExpensesRowAdded: boolean[] = [...currentState.livingExpensesRowAdded];
  const financialObligationRowAdded: boolean[] = [...currentState.financialObligationRowAdded];
  const creditCardsRowAdded: boolean[] = [...currentState.creditCardsRowAdded];
  if (action.provisoData) {
    incomeSourcesRowAdded[index] = true;
    livingExpensesRowAdded[index] = true;
    financialObligationRowAdded[index] = true;

    currentState.applicants[index].retrieveId = action.provisoData.dataVersion;
    if (currentState.applicants[index].retrieveBankData[action.key]) {
      currentState.applicants[index].retrieveBankData[action.key].illionBankDataFileName =
        action.illionBankDataFileName;
      currentState.applicants[index].retrieveBankData[action.key].isNew = false;
    } else {
      currentState.applicants[index].retrieveBankData[0].illionBankDataFileName =
        action.illionBankDataFileName;
      currentState.applicants[index].retrieveBankData[0].isNew = false;
    }

    let newState = setProvisoPAYGIncomeData(action.provisoData, currentState, index);
    newState = setProvisoFinancialObligationsData(action.provisoData, newState, index);
    newState = setProvisoLivingExpensesData(action.provisoData, newState, index);
    return setProvisoAssetsAndCreditData(
      action.provisoData,
      newState,
      index,
      assetsRowAdded,
      incomeSourcesRowAdded,
      livingExpensesRowAdded,
      financialObligationRowAdded,
      creditCardsRowAdded
    );
  }
  return { ...currentState };
};

export const copyToAllReducer = (
  state: IApplicantInitialState,
  action: ApplicantCopyToAllAction
) => {
  const currentState = JSON.parse(JSON.stringify(state));
  const applicant: IApplicant[] = [...currentState.applicants];
  const currentAssestsRow = applicant[action.currentTab].assets[action.currentIndex];
  let childElement;
  let childProvisoElement;
  JSON.parse(JSON.stringify(applicant));
  const assetsRowAdded = [...currentState.assetsRowAdded];

  // copy to another applicant only if he/she is not a guarantor
  applicant.map((list, index) => {
    if (list.isApplicantGuarantor === "No") {
      assetsRowAdded[index] = true;
      if (index !== action.currentTab && list.assets.length < affordabilityMaxRow) {
        childElement = [...list.assets];
        const dummyRow = JSON.parse(JSON.stringify(currentAssestsRow));
        dummyRow.noteRequired = false;
        dummyRow.note = "";
        childElement.push(dummyRow);
        childProvisoElement = JSON.parse(JSON.stringify([...list.provisoData.assets]));
        childProvisoElement.push(dummyRow);
        list.assetsNoteRequired.push(false);
        applicant[index].assets = JSON.parse(JSON.stringify(childElement));
        applicant[index].provisoData.assets = JSON.parse(JSON.stringify(childProvisoElement));
      }
    }
  });

  currentState.primaryAffordabilityRequiredFields = affordabilityRequiredFields({
    checkAll: true,
    applicant: applicant[0],
    primaryAffordabilityRequiredFields: currentState.primaryAffordabilityRequiredFields,
  });

  return { ...currentState, applicants: applicant, assetsRowAdded };
};

// validate applicants required field
export const validateApplicantFields = (
  action: ApplicantValidateApplicantFieldsAction,
  state: IApplicantInitialState
) => {
  return {
    ...state,
    applicantsRequiredField: action.applicantsRequiredField,
  };
};

const setProvisoPAYGIncomeData = (
  provisoData: any,
  currentState: IApplicantInitialState,
  index: number
) => {
  // setting income sources
  const decisionMetrics = provisoData.decisionMetrics;
  const applicantDummy = [...currentState.applicants];
  let incomeSourcesNoteRequired = [...applicantDummy[index].incomeSourcesNoteRequired];
  const applicantPAYGIncomeDummy = { ...applicantDummy[index] };
  let incomeSources = [...applicantPAYGIncomeDummy.incomeSources];
  let incomeSourcesDeletedNoteRequired = [
    ...applicantDummy[index].incomeSourcesDeletedNoteRequired,
  ];
  // to delete default first row.
  if (isEqual(incomeSources[0], incomeSourcesData)) {
    incomeSources = [];
    incomeSourcesNoteRequired = [];
    incomeSourcesDeletedNoteRequired = [];
  }
  const decisionMetricsIDArray: any = [];
  for (const decisionMetric of decisionMetrics) {
    decisionMetricsIDArray.push(decisionMetric.id);
  }
  if (decisionMetricsIDArray.includes("DM001")) {
    if (+decisionMetrics[decisionMetricsIDArray.indexOf("DM001")].value) {
      incomeSources.push({
        amount: parseFloat(
          calculateValue(decisionMetrics[decisionMetricsIDArray.indexOf("DM001")].value)
        ),
        deletedNote: "",
        deletedNoteRequired: false,
        deletedPartialNoteValue: "",
        duration: checkDuration(decisionMetrics[decisionMetricsIDArray.indexOf("DM001")].name),
        isDeleted: false,
        isFromInvestments: false,
        note: "",
        noteRequired: false,
        partialNoteValue: "",
        provisoOrManual: `${provisoOrManual[0]}`,
        taxStatus: "After",
        illionId: provisoData.documentId,
        type: applicantDummy[index].employment.length
          ? applicantDummy[index].employment[0].employmentType === EmploymentTypes.PAYGEmployee ||
            applicantDummy[index].employment[0].employmentType === EmploymentTypes.PAYGContractor ||
            applicantDummy[index].employment[0].employmentType === EmploymentTypes.Other
            ? applicantPAYGIncomeTypeOptions[0]
            : applicantSEIncomeTypeOptions[0]
          : applicantPAYGIncomeTypeOptions[0],
      });
    }
  }

  if (decisionMetricsIDArray.includes("DM002")) {
    if (+decisionMetrics[decisionMetricsIDArray.indexOf("DM002")].value) {
      incomeSources.push({
        amount: parseFloat(
          calculateValue(decisionMetrics[decisionMetricsIDArray.indexOf("DM002")].value)
        ),
        deletedNote: "",
        deletedNoteRequired: false,
        deletedPartialNoteValue: "",
        duration: checkDuration(decisionMetrics[decisionMetricsIDArray.indexOf("DM002")].name),
        isDeleted: false,
        isFromInvestments: false,
        note: "",
        noteRequired: false,
        partialNoteValue: "",
        provisoOrManual: `${provisoOrManual[0]}`,
        taxStatus: "After",
        type: "Government pensions",
        illionId: provisoData.documentId,
      });
    }
  }

  if (decisionMetricsIDArray.includes("DM016")) {
    if (+decisionMetrics[decisionMetricsIDArray.indexOf("DM016")].value) {
      incomeSources.push({
        amount: parseFloat(
          calculateValue(decisionMetrics[decisionMetricsIDArray.indexOf("DM016")].value)
        ),
        deletedNote: "",
        deletedNoteRequired: false,
        deletedPartialNoteValue: "",
        duration: checkDuration(decisionMetrics[decisionMetricsIDArray.indexOf("DM016")].name),
        isDeleted: false,
        isFromInvestments: false,
        note: "",
        noteRequired: false,
        partialNoteValue: "",
        provisoOrManual: `${provisoOrManual[0]}`,
        taxStatus: "After",
        type: "Uncategorised",
        illionId: provisoData.documentId,
      });
    }
  }

  for (let i = 0; i < incomeSources.length; i++) {
    incomeSourcesNoteRequired[i] =
      incomeSources[i].type === "Uncategorised" || incomeSources[i].type === rentalIncome;
    incomeSourcesDeletedNoteRequired[i] = false;
  }

  applicantDummy[index].incomeSourcesDeletedNoteRequired = incomeSourcesDeletedNoteRequired;
  applicantDummy[index].incomeSourcesNoteRequired = incomeSourcesNoteRequired;
  const incomeSourcesLength = incomeSources.length;
  const provisoIncomeSourcesLength = applicantDummy[index].provisoData.incomeSources.length;
  const newIncomeSources = incomeSources.slice(provisoIncomeSourcesLength, incomeSourcesLength);
  applicantDummy[index].provisoData.incomeSources = JSON.parse(
    JSON.stringify([...applicantDummy[index].provisoData.incomeSources, ...newIncomeSources])
  );
  applicantDummy[index].incomeSources = JSON.parse(JSON.stringify(incomeSources));

  if (index === 0) {
    currentState.primaryAffordabilityRequiredFields = affordabilityRequiredFields({
      childArray: "incomeSources",
      applicant: applicantDummy[0],
      primaryAffordabilityRequiredFields: currentState.primaryAffordabilityRequiredFields,
    });
  }

  return { ...currentState, applicants: applicantDummy };
};

const setProvisoFinancialObligationsData = (
  provisoData: any,
  state: IApplicantInitialState,
  index: number
) => {
  const currentState = Object.assign({}, state);
  const applicantDummy = [...currentState.applicants];
  let applicantFinancialObligationDummy = [...applicantDummy[index].financialObligation];
  let financialObligationNoteRequired = [...applicantDummy[index].financialObligationNoteRequired];
  let financialObligationDeletedNoteRequired = [
    ...applicantDummy[index].financialObligationDeletedNoteRequired,
  ];
  // to delete default first row.
  if (isEqual(applicantFinancialObligationDummy[0], financialObligationData)) {
    applicantFinancialObligationDummy = [];
    financialObligationNoteRequired = [];
    financialObligationDeletedNoteRequired = [];
  }

  const decisionMetrics = provisoData.decisionMetrics;
  const decisionMetricsIDArray: any = [];
  for (const decisionMetric of decisionMetrics) {
    decisionMetricsIDArray.push(decisionMetric.id);
  }
  if (decisionMetricsIDArray.includes("DM048")) {
    if (
      parseFloat(calculateValue(decisionMetrics[decisionMetricsIDArray.indexOf("DM048")].value)) !=
      0
    ) {
      applicantFinancialObligationDummy.push({
        balance: parseFloat(
          calculateValue(decisionMetrics[decisionMetricsIDArray.indexOf("DM048")].value)
        ),
        deletedNote: "",
        deletedNoteRequired: false,
        deletedPartialNoteValue: "",
        duration: checkDuration(decisionMetrics[decisionMetricsIDArray.indexOf("DM048")].name),
        interestRate: 0,
        isDeleted: false,
        note: "",
        noteRequired: false,
        partialNoteValue: "",
        paymentAmount: 0,
        provisoOrManual: `${provisoOrManual[0]}`,
        isLoanRefinanced: false,
        type: "Other Loan",
        illionId: provisoData.documentId,
      });
    }
  }

  if (decisionMetricsIDArray.includes("DM028")) {
    if (
      parseFloat(calculateValue(decisionMetrics[decisionMetricsIDArray.indexOf("DM028")].value)) !=
      0
    ) {
      applicantFinancialObligationDummy.push({
        balance: 0,
        deletedNote: "",
        deletedNoteRequired: false,
        deletedPartialNoteValue: "",
        duration: checkDuration(decisionMetrics[decisionMetricsIDArray.indexOf("DM028")].name),
        interestRate: 0,
        isDeleted: false,
        note: "",
        noteRequired: false,
        partialNoteValue: "",
        paymentAmount: parseFloat(
          calculateValue(decisionMetrics[decisionMetricsIDArray.indexOf("DM028")].value)
        ),
        provisoOrManual: `${provisoOrManual[0]}`,
        isLoanRefinanced: false,
        type: ProvisoTypeMappings.DM028,
        isFromApplicantStatus: false,
        illionId: provisoData.documentId,
      });
    }
  }

  for (let i = 0; i < applicantFinancialObligationDummy.length; i++) {
    financialObligationNoteRequired[i] = false;
    financialObligationDeletedNoteRequired[i] = false;
  }
  applicantDummy[index].financialObligationNoteRequired = financialObligationNoteRequired;
  applicantDummy[
    index
  ].financialObligationDeletedNoteRequired = financialObligationDeletedNoteRequired;
  const financialObligationLength = applicantFinancialObligationDummy.length;
  const provisoFinancialObligationLength =
    applicantDummy[index].provisoData.financialObligation.length;
  const newFinancialObligationData = applicantFinancialObligationDummy.slice(
    provisoFinancialObligationLength,
    financialObligationLength
  );
  applicantDummy[index].provisoData.financialObligation = JSON.parse(
    JSON.stringify([
      ...applicantDummy[index].provisoData.financialObligation,
      ...newFinancialObligationData,
    ])
  );
  applicantDummy[index].financialObligation = JSON.parse(
    JSON.stringify(applicantFinancialObligationDummy)
  );

  if (index === 0) {
    currentState.primaryAffordabilityRequiredFields = affordabilityRequiredFields({
      childArray: "financialObligation",
      applicant: applicantDummy[0],
      primaryAffordabilityRequiredFields: currentState.primaryAffordabilityRequiredFields,
    });
  }

  return { ...currentState, applicants: applicantDummy };
};

const setProvisoLivingExpensesData = (
  provisoData: any,
  state: IApplicantInitialState,
  index: number
) => {
  // setting living expenses
  const currentState = Object.assign({}, state);

  const applicantDummy = [...currentState.applicants];
  let livingExpensesNoteRequired = [...applicantDummy[index].livingExpensesNoteRequired];
  let livingExpensesDeletedNoteRequired = [
    ...applicantDummy[index].livingExpensesDeletedNoteRequired,
  ];
  let applicantExpenseDummy = [...applicantDummy[index].livingExpenses];
  const decisionMetrics = provisoData.decisionMetrics;
  const decisionMetricsIDArray: any = [];

  // to delete default first row.
  if (isEqual(applicantExpenseDummy[0], livingExpensesData)) {
    applicantExpenseDummy = [];
    livingExpensesNoteRequired = [];
    livingExpensesDeletedNoteRequired = [];
  }

  for (const decisionMetric of decisionMetrics) {
    decisionMetricsIDArray.push(decisionMetric.id);
  }

  if (decisionMetricsIDArray.includes("DM035")) {
    if (+decisionMetrics[decisionMetricsIDArray.indexOf("DM035")].value) {
      applicantExpenseDummy.push({
        amount: parseFloat(
          calculateValue(decisionMetrics[decisionMetricsIDArray.indexOf("DM035")].value)
        ),
        deletedNote: "",
        deletedNoteRequired: false,
        deletedPartialNoteValue: "",
        duration: checkDuration(decisionMetrics[decisionMetricsIDArray.indexOf("DM035")].name),
        isDeleted: false,
        note: "",
        noteRequired: false,
        partialNoteValue: "",
        provisoOrManual: `${provisoOrManual[0]}`,
        type: ProvisoTypeMappings.DM035,
        illionId: provisoData.documentId,
      });
    }
  }

  if (decisionMetricsIDArray.includes("DM023")) {
    if (+decisionMetrics[decisionMetricsIDArray.indexOf("DM023")].value) {
      applicantExpenseDummy.push({
        amount: parseFloat(
          calculateValue(decisionMetrics[decisionMetricsIDArray.indexOf("DM023")].value)
        ),
        deletedNote: "",
        deletedNoteRequired: false,
        deletedPartialNoteValue: "",
        duration: checkDuration(decisionMetrics[decisionMetricsIDArray.indexOf("DM023")].name),
        isDeleted: false,
        note: "",
        noteRequired: false,
        partialNoteValue: "",
        provisoOrManual: `${provisoOrManual[0]}`,
        type: ProvisoTypeMappings.DM023,
        illionId: provisoData.documentId,
      });
    }
  }

  if (decisionMetricsIDArray.includes("DM058")) {
    if (+decisionMetrics[decisionMetricsIDArray.indexOf("DM058")].value) {
      applicantExpenseDummy.push({
        amount: parseFloat(
          calculateValue(decisionMetrics[decisionMetricsIDArray.indexOf("DM058")].value)
        ),
        deletedNote: "",
        deletedNoteRequired: false,
        deletedPartialNoteValue: "",
        duration: checkDuration(decisionMetrics[decisionMetricsIDArray.indexOf("DM058")].name),
        isDeleted: false,
        note: "",
        noteRequired: false,
        partialNoteValue: "",
        provisoOrManual: `${provisoOrManual[0]}`,
        type: ProvisoTypeMappings.DM058,
        illionId: provisoData.documentId,
      });
    }
  }

  if (decisionMetricsIDArray.includes("DM024")) {
    if (+decisionMetrics[decisionMetricsIDArray.indexOf("DM024")].value) {
      applicantExpenseDummy.push({
        amount: parseFloat(
          calculateValue(decisionMetrics[decisionMetricsIDArray.indexOf("DM024")].value)
        ),
        deletedNote: "",
        deletedNoteRequired: false,
        deletedPartialNoteValue: "",
        duration: checkDuration(decisionMetrics[decisionMetricsIDArray.indexOf("DM024")].name),
        isDeleted: false,
        note: "",
        noteRequired: false,
        partialNoteValue: "",
        provisoOrManual: `${provisoOrManual[0]}`,
        type: ProvisoTypeMappings.DM024,
        illionId: provisoData.documentId,
      });
    }
  }

  if (decisionMetricsIDArray.includes("DM032")) {
    if (+decisionMetrics[decisionMetricsIDArray.indexOf("DM032")].value) {
      applicantExpenseDummy.push({
        amount: parseFloat(
          calculateValue(decisionMetrics[decisionMetricsIDArray.indexOf("DM032")].value)
        ),
        deletedNote: "",
        deletedNoteRequired: false,
        deletedPartialNoteValue: "",
        duration: checkDuration(decisionMetrics[decisionMetricsIDArray.indexOf("DM032")].name),
        isDeleted: false,
        note: "",
        noteRequired: false,
        partialNoteValue: "",
        provisoOrManual: `${provisoOrManual[0]}`,
        type: ProvisoTypeMappings.DM032,
        illionId: provisoData.documentId,
      });
    }
  }

  if (decisionMetricsIDArray.includes("DM029")) {
    if (+decisionMetrics[decisionMetricsIDArray.indexOf("DM029")].value) {
      applicantExpenseDummy.push({
        amount: parseFloat(
          calculateValue(decisionMetrics[decisionMetricsIDArray.indexOf("DM029")].value)
        ),
        deletedNote: "",
        deletedNoteRequired: false,
        deletedPartialNoteValue: "",
        duration: checkDuration(decisionMetrics[decisionMetricsIDArray.indexOf("DM029")].name),
        isDeleted: false,
        note: "",
        noteRequired: false,
        partialNoteValue: "",
        provisoOrManual: `${provisoOrManual[0]}`,
        type: ProvisoTypeMappings.DM029,
        illionId: provisoData.documentId,
      });
    }
  }

  if (decisionMetricsIDArray.includes("DM040")) {
    if (+decisionMetrics[decisionMetricsIDArray.indexOf("DM040")].value) {
      applicantExpenseDummy.push({
        amount: parseFloat(
          calculateValue(decisionMetrics[decisionMetricsIDArray.indexOf("DM040")].value)
        ),
        deletedNote: "",
        deletedNoteRequired: false,
        deletedPartialNoteValue: "",
        duration: checkDuration(decisionMetrics[decisionMetricsIDArray.indexOf("DM040")].name),
        isDeleted: false,
        note: "",
        noteRequired: false,
        partialNoteValue: "",
        provisoOrManual: `${provisoOrManual[0]}`,
        type: ProvisoTypeMappings.DM040,
        illionId: provisoData.documentId,
      });
    }
  }

  if (decisionMetricsIDArray.includes("TF003")) {
    if (+decisionMetrics[decisionMetricsIDArray.indexOf("TF003")].value) {
      applicantExpenseDummy.push({
        amount: parseFloat(
          calculateValue(decisionMetrics[decisionMetricsIDArray.indexOf("TF003")].value)
        ),
        deletedNote: "",
        deletedNoteRequired: false,
        deletedPartialNoteValue: "",
        duration: checkDuration(decisionMetrics[decisionMetricsIDArray.indexOf("TF003")].name),
        isDeleted: false,
        note: "",
        noteRequired: false,
        partialNoteValue: "",
        provisoOrManual: `${provisoOrManual[0]}`,
        type: ProvisoTypeMappings.TF003,
        illionId: provisoData.documentId,
      });
    }
  }

  if (decisionMetricsIDArray.includes("DM052")) {
    if (+decisionMetrics[decisionMetricsIDArray.indexOf("DM052")].value) {
      applicantExpenseDummy.push({
        amount: parseFloat(
          calculateValue(decisionMetrics[decisionMetricsIDArray.indexOf("DM052")].value)
        ),
        deletedNote: "",
        deletedNoteRequired: false,
        deletedPartialNoteValue: "",
        duration: checkDuration(decisionMetrics[decisionMetricsIDArray.indexOf("DM052")].name),
        isDeleted: false,
        note: "",
        noteRequired: false,
        partialNoteValue: "",
        provisoOrManual: `${provisoOrManual[0]}`,
        type: ProvisoTypeMappings.DM052,
        illionId: provisoData.documentId,
      });
    }
  }

  if (decisionMetricsIDArray.includes("DM054")) {
    if (+decisionMetrics[decisionMetricsIDArray.indexOf("DM054")].value) {
      applicantExpenseDummy.push({
        amount: parseFloat(
          calculateValue(decisionMetrics[decisionMetricsIDArray.indexOf("DM054")].value)
        ),
        deletedNote: "",
        deletedNoteRequired: false,
        deletedPartialNoteValue: "",
        duration: checkDuration(decisionMetrics[decisionMetricsIDArray.indexOf("DM054")].name),
        isDeleted: false,
        note: "",
        noteRequired: false,
        partialNoteValue: "",
        provisoOrManual: `${provisoOrManual[0]}`,
        type: ProvisoTypeMappings.DM054,
        illionId: provisoData.documentId,
      });
    }
  }

  if (decisionMetricsIDArray.includes("DM053")) {
    if (+decisionMetrics[decisionMetricsIDArray.indexOf("DM053")].value) {
      applicantExpenseDummy.push({
        amount: parseFloat(
          calculateValue(decisionMetrics[decisionMetricsIDArray.indexOf("DM053")].value)
        ),
        deletedNote: "",
        deletedNoteRequired: false,
        deletedPartialNoteValue: "",
        duration: checkDuration(decisionMetrics[decisionMetricsIDArray.indexOf("DM053")].name),
        isDeleted: false,
        note: "",
        noteRequired: false,
        partialNoteValue: "",
        provisoOrManual: `${provisoOrManual[0]}`,
        type: ProvisoTypeMappings.DM053,
        illionId: provisoData.documentId,
      });
    }
  }

  if (decisionMetricsIDArray.includes("DM062")) {
    if (+decisionMetrics[decisionMetricsIDArray.indexOf("DM062")].value) {
      applicantExpenseDummy.push({
        amount: parseFloat(
          calculateValue(decisionMetrics[decisionMetricsIDArray.indexOf("DM062")].value)
        ),
        deletedNote: "",
        deletedNoteRequired: false,
        deletedPartialNoteValue: "",
        duration: checkDuration(decisionMetrics[decisionMetricsIDArray.indexOf("DM062")].name),
        isDeleted: false,
        note: "",
        noteRequired: false,
        partialNoteValue: "",
        provisoOrManual: `${provisoOrManual[0]}`,
        type: ProvisoTypeMappings.DM062,
        illionId: provisoData.documentId,
      });
    }
  }

  if (decisionMetricsIDArray.includes("DM056")) {
    if (+decisionMetrics[decisionMetricsIDArray.indexOf("DM056")].value) {
      applicantExpenseDummy.push({
        amount: parseFloat(
          calculateValue(decisionMetrics[decisionMetricsIDArray.indexOf("DM056")].value)
        ),
        deletedNote: "",
        deletedNoteRequired: false,
        deletedPartialNoteValue: "",
        duration: checkDuration(decisionMetrics[decisionMetricsIDArray.indexOf("DM056")].name),
        isDeleted: false,
        note: "",
        noteRequired: false,
        partialNoteValue: "",
        provisoOrManual: `${provisoOrManual[0]}`,
        type: ProvisoTypeMappings.DM056,
        illionId: provisoData.documentId,
      });
    }
  }

  if (decisionMetricsIDArray.includes("DM057")) {
    if (+decisionMetrics[decisionMetricsIDArray.indexOf("DM057")].value) {
      applicantExpenseDummy.push({
        amount: parseFloat(
          calculateValue(decisionMetrics[decisionMetricsIDArray.indexOf("DM057")].value)
        ),
        deletedNote: "",
        deletedNoteRequired: false,
        deletedPartialNoteValue: "",
        duration: checkDuration(decisionMetrics[decisionMetricsIDArray.indexOf("DM057")].name),
        isDeleted: false,
        note: "",
        noteRequired: false,
        partialNoteValue: "",
        provisoOrManual: `${provisoOrManual[0]}`,
        type: ProvisoTypeMappings.DM057,
        illionId: provisoData.documentId,
      });
    }
  }

  if (decisionMetricsIDArray.includes("DM060")) {
    if (+decisionMetrics[decisionMetricsIDArray.indexOf("DM060")].value) {
      applicantExpenseDummy.push({
        amount: parseFloat(
          calculateValue(decisionMetrics[decisionMetricsIDArray.indexOf("DM060")].value)
        ),
        deletedNote: "",
        deletedNoteRequired: false,
        deletedPartialNoteValue: "",
        duration: checkDuration(decisionMetrics[decisionMetricsIDArray.indexOf("DM060")].name),
        isDeleted: false,
        note: "",
        noteRequired: false,
        partialNoteValue: "",
        provisoOrManual: `${provisoOrManual[0]}`,
        type: ProvisoTypeMappings.DM060,
        illionId: provisoData.documentId,
      });
    }
  }

  if (decisionMetricsIDArray.includes("DM055")) {
    if (+decisionMetrics[decisionMetricsIDArray.indexOf("DM055")].value) {
      applicantExpenseDummy.push({
        amount: parseFloat(
          calculateValue(decisionMetrics[decisionMetricsIDArray.indexOf("DM055")].value)
        ),
        deletedNote: "",
        deletedNoteRequired: false,
        deletedPartialNoteValue: "",
        duration: checkDuration(decisionMetrics[decisionMetricsIDArray.indexOf("DM055")].name),
        isDeleted: false,
        note: "",
        noteRequired: false,
        partialNoteValue: "",
        provisoOrManual: `${provisoOrManual[0]}`,
        type: ProvisoTypeMappings.DM055,
        illionId: provisoData.documentId,
      });
    }
  }

  if (decisionMetricsIDArray.includes("DM059")) {
    if (+decisionMetrics[decisionMetricsIDArray.indexOf("DM059")].value) {
      applicantExpenseDummy.push({
        amount: parseFloat(
          calculateValue(decisionMetrics[decisionMetricsIDArray.indexOf("DM059")].value)
        ),
        deletedNote: "",
        deletedNoteRequired: false,
        deletedPartialNoteValue: "",
        duration: checkDuration(decisionMetrics[decisionMetricsIDArray.indexOf("DM059")].name),
        isDeleted: false,
        note: "",
        noteRequired: false,
        partialNoteValue: "",
        provisoOrManual: `${provisoOrManual[0]}`,
        type: ProvisoTypeMappings.DM059,
        illionId: provisoData.documentId,
      });
    }
  }

  if (decisionMetricsIDArray.includes("DM071")) {
    if (+decisionMetrics[decisionMetricsIDArray.indexOf("DM071")].value) {
      applicantExpenseDummy.push({
        amount: parseFloat(
          calculateValue(decisionMetrics[decisionMetricsIDArray.indexOf("DM071")].value)
        ),
        deletedNote: "",
        deletedNoteRequired: false,
        deletedPartialNoteValue: "",
        duration: checkDuration(decisionMetrics[decisionMetricsIDArray.indexOf("DM071")].name),
        isDeleted: false,
        note: "",
        noteRequired: false,
        partialNoteValue: "",
        provisoOrManual: `${provisoOrManual[0]}`,
        type: ProvisoTypeMappings.DM071,
        illionId: provisoData.documentId,
      });
    }
  }

  for (let i = 0; i < applicantExpenseDummy.length; i++) {
    livingExpensesNoteRequired[i] = false;
    livingExpensesDeletedNoteRequired[i] = false;
  }

  applicantDummy[index].livingExpensesNoteRequired = livingExpensesNoteRequired;
  applicantDummy[index].livingExpensesDeletedNoteRequired = livingExpensesDeletedNoteRequired;
  const livingExpenseLength = applicantExpenseDummy.length;
  const provisoLivingExpenseLength = applicantDummy[index].provisoData.livingExpenses.length;
  const newLivingExpense = applicantExpenseDummy.slice(
    provisoLivingExpenseLength,
    livingExpenseLength
  );
  applicantDummy[index].provisoData.livingExpenses = JSON.parse(
    JSON.stringify([...applicantDummy[index].provisoData.livingExpenses, ...newLivingExpense])
  );
  applicantDummy[index].livingExpenses = JSON.parse(JSON.stringify(applicantExpenseDummy));

  if (index === 0) {
    currentState.primaryAffordabilityRequiredFields = affordabilityRequiredFields({
      childArray: "livingExpenses",
      applicant: applicantDummy[0],
      primaryAffordabilityRequiredFields: currentState.primaryAffordabilityRequiredFields,
    });
  }

  return { ...currentState, applicants: applicantDummy };
};

const setProvisoAssetsAndCreditData = (
  provisoData: any,
  state: IApplicantInitialState,
  index: number,
  assetsRowAdded: boolean[],
  incomeSourcesRowAdded: boolean[],
  livingExpensesRowAdded: boolean[],
  financialObligationRowAdded: boolean[],
  creditCardsRowAdded: boolean[]
) => {
  const currentState = Object.assign({}, state);
  const applicantDummy = [...currentState.applicants];
  // assets array dummy
  let assetsDummy = [...applicantDummy[index].assets];
  let assetsNoteRequired = [...applicantDummy[index].assetsNoteRequired];
  let assetsDeletedNoteRequired = [...applicantDummy[index].assetsDeletedNoteRequired];

  // credit cards array dummy
  const creditCardsDummy = [...applicantDummy[index].creditCards];
  const creditCardsNoteRequired = [...applicantDummy[index].creditCardsNoteRequired];
  const creditCardsDeletedNoteRequired = [...applicantDummy[index].creditCardsDeletedNoteRequired];

  // financial Obligations array dummy
  const applicantFinancialObligationDummy = [...applicantDummy[index].financialObligation];
  const financialObligationNoteRequired = [
    ...applicantDummy[index].financialObligationNoteRequired,
  ];
  const financialObligationDeletedNoteRequired = [
    ...applicantDummy[index].financialObligationDeletedNoteRequired,
  ];

  // to delete default first row.
  if (isEqual(assetsDummy[0], assetsData)) {
    assetsNoteRequired = [];
    assetsDummy = [];
    assetsDeletedNoteRequired = [];
  }
  const accountInfo = provisoData.bankData ? provisoData.bankData.bankAccounts : [];
  for (const account of accountInfo) {
    // populate savings account in assets.
    if (account.accountType === "savings") {
      // validation that row is added in assets table.
      assetsRowAdded[index] = true;

      assetsDummy.push({
        deletedNote: "",
        deletedNoteRequired: false,
        deletedPartialNoteValue: "",
        isDeleted: false,
        loader: false,
        myContribution: true,
        note: "",
        noteRequired: false,
        partialNoteValue: "",
        propertyAddress: "",
        assetNumber: account.accountNumber,
        propertyAutoSuggestList: [],
        propertyValue: 0,
        provisoOrManual: `${provisoOrManual[0]}`,
        share: 100,
        type: `${applicantAssetsTypeOptions[0]}`,
        value: parseFloat(calculateZeroValues(parseFloat(account.availableBalance).toFixed(2))),
        illionId: provisoData.documentId,
      });
    } else if (account.accountType === "transaction") {
      // validation that row is added in assets table.
      assetsRowAdded[index] = true;

      assetsDummy.push({
        deletedNote: "",
        deletedNoteRequired: false,
        deletedPartialNoteValue: "",
        isDeleted: false,
        loader: false,
        myContribution: true,
        note: "",
        noteRequired: false,
        partialNoteValue: "",
        propertyAddress: "",
        propertyAutoSuggestList: [],
        propertyValue: 0,
        provisoOrManual: `${provisoOrManual[0]}`,
        share: 100,
        type: `${applicantAssetsTypeOptions[3]}`,
        assetNumber: account.accountNumber,
        value: parseFloat(calculateZeroValues(parseFloat(account.availableBalance).toFixed(2))),
        illionId: provisoData.documentId,
      });
    } else if (account.accountType === "home loan") {
      // validation that row is added in financial obligation table.
      financialObligationRowAdded[index] = true;

      applicantFinancialObligationDummy.push({
        balance: parseFloat(
          (Math.abs(account.availableBalance) + Math.abs(account.currentBalance)).toFixed(2)
        ),
        deletedNote: "",
        deletedNoteRequired: false,
        deletedPartialNoteValue: "",
        duration: "Year",
        interestRate: account.additionalDetails.interestRate
          ? parseFloat(
              calculateZeroValues(parseFloat(account.additionalDetails.interestRate).toFixed(2))
            )
          : 0,
        isDeleted: false,
        note: "",
        noteRequired: false,
        partialNoteValue: "",
        paymentAmount: 0,
        provisoOrManual: `${provisoOrManual[0]}`,
        isLoanRefinanced: false,
        type: applicantFinancialObligationTypeOptions[0],
        illionId: provisoData.documentId,
      });
    } else if (account.accountType === "investments") {
      // validation that row is added in financial obligation table.
      financialObligationRowAdded[index] = true;

      applicantFinancialObligationDummy.push({
        balance: parseFloat(
          (Math.abs(account.availableBalance) + Math.abs(account.currentBalance)).toFixed(2)
        ),
        deletedNote: "",
        deletedNoteRequired: false,
        deletedPartialNoteValue: "",
        duration: "Year",
        interestRate: account.additionalDetails.interestRate
          ? parseFloat(
              calculateZeroValues(parseFloat(account.additionalDetails.interestRate).toFixed(2))
            )
          : 0,
        isDeleted: false,
        note: "",
        noteRequired: false,
        partialNoteValue: "",
        paymentAmount: 0,
        provisoOrManual: `${provisoOrManual[0]}`,
        isLoanRefinanced: false,
        type: applicantFinancialObligationLoanTypeOptions[3],
        illionId: provisoData.documentId,
      });
    }

    // populate credit card info.
    else if (account.accountType === "credit card") {
      // validation that row is added in credit cards table.
      creditCardsRowAdded[index] = true;

      let days = Math.round(
        moment(account.statementSummary.transactionsEndDate).diff(
          moment(account.statementSummary.transactionsStartDate),
          "days",
          true
        )
      );
      creditCardsDummy.push({
        creditLimit: parseFloat(
          (Math.abs(account.availableBalance) + Math.abs(account.currentBalance)).toFixed(2)
        ),
        deletedNote: "",
        deletedNoteRequired: false,
        deletedPartialNoteValue: "",
        institution: account.accountName + " - " + account.accountNumber,
        isDeleted: false,
        note: "",
        noteRequired: false,
        partialNoteValue: "",
        provisoOrManual: `${provisoOrManual[0]}`,
        textError: false,
        isCreditCardRefinanced: false,
        period: days,
        illionId: provisoData.documentId,
      });
    }
  }

  for (let i = 0; i < applicantFinancialObligationDummy.length; i++) {
    financialObligationNoteRequired[i] = false;
    financialObligationDeletedNoteRequired[i] = false;
  }

  for (let i = 0; i < assetsDummy.length; i++) {
    assetsNoteRequired[i] = false;
    assetsDeletedNoteRequired[i] = false;
  }
  for (let i = 0; i < creditCardsDummy.length; i++) {
    creditCardsNoteRequired[i] = false;
    creditCardsDeletedNoteRequired[i] = false;
  }

  applicantDummy[index].assetsNoteRequired = assetsNoteRequired;
  applicantDummy[index].assetsDeletedNoteRequired = assetsDeletedNoteRequired;
  const assetsLength = assetsDummy.length;
  const provisoAssetsLength = applicantDummy[index].provisoData.assets.length;
  const newAssets = assetsDummy.slice(provisoAssetsLength, assetsLength);
  applicantDummy[index].provisoData.assets = JSON.parse(
    JSON.stringify([...applicantDummy[index].provisoData.assets, ...newAssets])
  );
  applicantDummy[index].assets = JSON.parse(JSON.stringify(assetsDummy));

  applicantDummy[index].creditCardsNoteRequired = creditCardsNoteRequired;
  applicantDummy[index].creditCardsDeletedNoteRequired = creditCardsDeletedNoteRequired;
  const creditCardLength = creditCardsDummy.length;
  const provisoCreditCardLength = applicantDummy[index].provisoData.creditCards.length;
  const newCreditCards = creditCardsDummy.slice(provisoCreditCardLength, creditCardLength);
  applicantDummy[index].provisoData.creditCards = JSON.parse(
    JSON.stringify([...applicantDummy[index].provisoData.creditCards, ...newCreditCards])
  );
  applicantDummy[index].creditCards = JSON.parse(JSON.stringify(creditCardsDummy));

  applicantDummy[index].financialObligationNoteRequired = financialObligationNoteRequired;
  applicantDummy[
    index
  ].financialObligationDeletedNoteRequired = financialObligationDeletedNoteRequired;
  const financialObligationLength = applicantFinancialObligationDummy.length;
  const provisoFinancialObligationLength =
    applicantDummy[index].provisoData.financialObligation.length;
  const newFinancialObligationData = applicantFinancialObligationDummy.slice(
    provisoFinancialObligationLength,
    financialObligationLength
  );
  applicantDummy[index].provisoData.financialObligation = JSON.parse(
    JSON.stringify([
      ...applicantDummy[index].provisoData.financialObligation,
      ...newFinancialObligationData,
    ])
  );
  applicantDummy[index].financialObligation = JSON.parse(
    JSON.stringify(applicantFinancialObligationDummy)
  );

  if (index === 0) {
    currentState.primaryAffordabilityRequiredFields = affordabilityRequiredFields({
      childArray: "assets",
      applicant: applicantDummy[0],
      primaryAffordabilityRequiredFields: currentState.primaryAffordabilityRequiredFields,
    });
  }

  return {
    ...currentState,
    applicants: applicantDummy,
    assetsRowAdded,
    creditCardsRowAdded,
    financialObligationRowAdded,
    incomeSourcesRowAdded,
    livingExpensesRowAdded,
  };
};

export const clearDocumentIdReducer = (
  action: ApplicantClearDocumentIdAction,
  state: IApplicantInitialState
) => {
  const applicant = [...Object.assign(state).applicants];
  const retrieveData = [...applicant[action.currentTab].retrieveBankData];
  let data;
  if (action.replaceIllionData) {
    data = retrieveData.filter((obj) => obj.isNew);
    data.map((element) => (element.errorText = ""));
  } else {
    data = retrieveData.slice(-1);
    data[0].bankDocumentId = "";
    data[0].errorText = "";
    data[0].illionBankDataFileName = "";
    data[0].isNew = true;
  }
  applicant[state.currentTab].retrieveBankData = data;
  return { ...state, applicants: applicant };
};

export const updateReferralCode = (
  action: ApplicantReferralCodeUpdateAction,
  state: IApplicantInitialState
) => {
  const currentState = deepCopyObject(state);
  const applicant = [...Object.assign(currentState).applicants];
  applicant.forEach((app) => {
    for (const row of app.retrieveBankData) {
      row.referralCode = action.stateValue;
      if (row.illionBankDataFileName !== "") {
        const splitArray = row.illionBankDataFileName.split("_");
        splitArray[1] = action.stateValue;
        row.illionBankDataFileName = `${splitArray[0]}_${splitArray[1]}`;
      }
    }
  });

  return { ...deepCopyObject(currentState), applicants: applicant };
};
export const clearApplicantPageDataReducer = (
  action: ApplicantClearAffordabilityPageDataAction,
  state: IApplicantInitialState
) => {
  const currentState = Object.assign(state);
  const applicant = [...currentState.applicants];
  const assetsRowAdded = [...currentState.assetsRowAdded];
  const creditCardsRowAdded = [...currentState.creditCardsRowAdded];
  const incomeSourcesRowAdded = [...currentState.incomeSourcesRowAdded];
  const financialObligationRowAdded = [...currentState.financialObligationRowAdded];
  const livingExpensesRowAdded = [...currentState.livingExpensesRowAdded];
  const incomeSourcesDummy: IIncomeSources[] = [];
  const incomeSourcesNoteRequiredDummy: boolean[] = [];
  let incomeRemovalIndexArray: number[] = [...currentState.incomeRemovalIndexArray];

  const rentIndex = applicant[action.currentTab].financialObligation.findIndex(
    (item: IFinancialObligation) => item.type === "Rent" && item.isFromApplicantStatus
  );

  (applicant[action.currentTab].assetsNoteRequired = []),
    (applicant[action.currentTab].assets = []),
    (applicant[action.currentTab].assetsDeletedNoteRequired = []),
    (applicant[action.currentTab].incomeSourcesDeletedNoteRequired = []),
    (applicant[action.currentTab].creditCardsNoteRequired = []),
    (applicant[action.currentTab].creditCardsDeletedNoteRequired = []),
    (applicant[action.currentTab].creditCards = []),
    (applicant[action.currentTab].livingExpensesNoteRequired = []),
    (applicant[action.currentTab].livingExpensesDeletedNoteRequired = []),
    (applicant[action.currentTab].livingExpenses = []),
    (applicant[action.currentTab].provisoData.assets = []),
    (applicant[action.currentTab].provisoData.creditCards = []),
    (applicant[action.currentTab].provisoData.livingExpenses = []);
  assetsRowAdded[action.currentTab] = false;
  creditCardsRowAdded[action.currentTab] = false;
  livingExpensesRowAdded[action.currentTab] = false;
  applicant[action.currentTab].totalExpensesNotes = [
    {
      note: "",
      noteRequired: false,
      noteValue: "",
      partialNoteValue: "",
      deletedNote: "",
      deletedNoteRequired: false,
      deletedPartialNoteValue: "",
      isDeleted: false,
      provisoOrManual: "",
    },
  ];

  if (rentIndex > -1) {
    applicant[action.currentTab].provisoData.financialObligation = [
      applicant[action.currentTab].provisoData.financialObligation[rentIndex],
    ];
    financialObligationRowAdded[action.currentTab] = Boolean(
      applicant[action.currentTab].financialObligation.length
    );
    applicant[action.currentTab].financialObligation = [
      applicant[action.currentTab].financialObligation[rentIndex],
    ];
    applicant[action.currentTab].financialObligationNoteRequired = [
      applicant[action.currentTab].financialObligationNoteRequired[rentIndex],
    ];
    applicant[action.currentTab].financialObligationDeletedNoteRequired = [
      applicant[action.currentTab].financialObligationDeletedNoteRequired[rentIndex],
    ];
  } else {
    applicant[action.currentTab].provisoData.financialObligation = [];
    financialObligationRowAdded[action.currentTab] = false;
    applicant[action.currentTab].financialObligation = [];
    applicant[action.currentTab].financialObligationNoteRequired = [];
    applicant[action.currentTab].financialObligationDeletedNoteRequired = [];
  }

  const incomeRemovalIndexArrayDummy: number[] = [];
  let countOfInvestments = 0;
  applicant[action.currentTab].incomeSources.map((source: IIncomeSources, index: number) => {
    if (source.isFromInvestments) {
      incomeSourcesDummy.push(source);
      incomeSourcesNoteRequiredDummy.push(
        applicant[action.currentTab].incomeSourcesNoteRequired[index]
      );
      incomeRemovalIndexArrayDummy.push(countOfInvestments);
      countOfInvestments++;
    }
  });
  if (incomeSourcesDummy.length > 0) {
    incomeSourcesRowAdded[action.currentTab] = true;
    applicant[action.currentTab].incomeSourcesNoteRequired = incomeSourcesNoteRequiredDummy;
    applicant[action.currentTab].incomeSources = incomeSourcesDummy;
    applicant[action.currentTab].provisoData.incomeSources = incomeSourcesDummy;
    incomeRemovalIndexArray = incomeRemovalIndexArrayDummy;
  } else {
    incomeSourcesRowAdded[action.currentTab] = false;
    applicant[action.currentTab].incomeSourcesNoteRequired = [];
    applicant[action.currentTab].incomeSources = [];
    applicant[action.currentTab].provisoData.incomeSources = [];
  }

  return {
    ...state,
    applicants: applicant,
    assetsRowAdded,
    creditCardsRowAdded,
    financialObligationRowAdded,
    incomeSourcesRowAdded,
    incomeRemovalIndexArray,
    livingExpensesRowAdded,
  };
};

export const checkApplicantFormFieldOrNot = (
  applicantRequiredField: IApplicantRequiredField,
  applicant: IApplicant
) => {
  let formError: boolean = true;
  const option: string = applicant.privacyConsent.privacyOptions;
  if (option === privacyOptions[0].value && !applicant.privacyConsent.isInformationVerified) {
    formError = false;
  }

  for (const key in applicantRequiredField) {
    if (
      key !== "applicantDocuments" &&
      key !== "errorMessage" &&
      key !== "previousAddress" &&
      key !== "previousAddressMovedInDate" &&
      key !== "isProbationOn"
    ) {
      if (applicantRequiredField[key]) {
        formError = false;
      } else if (
        !applicant[key] &&
        applicant[key] !== 0 &&
        key !== "middleName" &&
        key !== "earlyRepaymentStrategy" &&
        key !== "earlyRepaymentStrategyNote" &&
        key !== "homePhone"
      ) {
        formError = false;
      }
    } else if (key === "applicantDocuments") {
      applicantRequiredField.applicantDocuments.forEach((list, index) => {
        for (const keyName in list) {
          if (list[keyName]) {
            formError = false;
          } else if (!applicant.applicantDocuments[index][keyName]) {
            // @Todo to build the logic
            formError = false;
          }
        }
      });
    } else if (key === "previousAddress" || key === "previousAddressMovedInDate") {
      if (applicant.movedInDate !== null) {
        const movedInDateDiff = dayjs().diff(dayjs(applicant.movedInDate as string), "year", true);
        if (movedInDateDiff < 3) {
          if (applicant[key] === "") {
            formError = false;
          }
        }
      }
    }
  }
  if (applicant.homePhoneInvalid) {
    formError = false;
  }
  return formError;
};

export const noteValueUpdateReducer = (
  action: ApplicantNoteValueUpdateAction,
  state: IApplicantInitialState
) => {
  const currentState = Object.assign(state);
  const applicant = [...currentState.applicants];

  if (applicant[currentState.currentTab][action.rowName][action.index].deletedNoteRequired) {
    if (
      applicant[currentState.currentTab][action.rowName][action.index].deletedPartialNoteValue ===
      ""
    ) {
      applicant[currentState.currentTab][action.rowName][action.index].deletedNoteRequired = true;
    }
    applicant[currentState.currentTab][action.rowName][action.index].deletedPartialNoteValue =
      action.noteValue;
  } else {
    if (applicant[currentState.currentTab][action.rowName][action.index].isDeleted) {
      applicant[currentState.currentTab][action.rowName][action.index].deletedPartialNoteValue =
        action.noteValue;
      return { ...currentState, applicants: applicant };
    }
    applicant[currentState.currentTab][action.rowName][action.index].partialNoteValue =
      action.noteValue;
  }
  return { ...currentState, applicants: applicant };
};

const checkDuration = (name: string) => {
  const array = name.split(" ");
  switch (array[array.length - 1]) {
    case "Monthly":
      return "Month";
    case "Annual":
      return "Year";
    case "Weekly":
      return "Week";
    default:
      return "Month";
  }
};

const calculateValue = (value: string) => {
  if (value.includes("$") || value.includes("Once off")) {
    const splitArray = value.split(" ");
    const amount = splitArray[0].substring(1);
    return calculateZeroValues(parseFloat(amount).toFixed(2));
  } else {
    return calculateZeroValues(parseFloat(value).toFixed(2));
  }
};

const calculateZeroValues = (value: string): string => {
  const splitArray = value.split(".");
  const amount = splitArray[1];
  const returnValue = amount.localeCompare("00");
  if (returnValue !== 0) {
    return value.toString();
  } else {
    return splitArray[0].toString();
  }
};
const clearOtherErrorTexts = (index: number, retrieveBankData: IRetrieveBankData[]) => {
  for (let i = 0; i < retrieveBankData.length; i++) {
    if (i !== index) {
      for (let j = i + 1; j < retrieveBankData.length; j++) {
        if (
          retrieveBankData[i].bankDocumentId === retrieveBankData[j].bankDocumentId &&
          retrieveBankData[i].bankDocumentId !== ""
        ) {
          retrieveBankData[j].errorText = "set";
        } else {
          if (retrieveBankData[j].errorText !== "set") {
            retrieveBankData[j].errorText = "";
          }
        }
      }
    }
  }

  for (const retrieveData of retrieveBankData) {
    if (retrieveData.errorText === "set") {
      retrieveData.errorText = "Two document IDs can't be same, please change any one of them.";
    }
  }
  return retrieveBankData;
};

const retrieveBankDataValidationsForDeletion = (
  retrieveData: IRetrieveBankData[],
  length: number
) => {
  switch (length) {
    case 1:
      if (
        (retrieveData[0].errorText =
          "Two document IDs can't be same, please change any one of them.")
      ) {
        retrieveData[0].errorText = "";
      }
      break;
    case 2:
      if (
        retrieveData[0].bankDocumentId === retrieveData[1].bankDocumentId &&
        retrieveData[0].bankDocumentId !== ""
      ) {
        retrieveData[1].errorText =
          "Two document IDs can't be same, please change any one of them.";
        retrieveData[0].errorText = "";
      } else {
        retrieveData[0].errorText = "";
        retrieveData[1].errorText = "";
      }
      break;
    case 3:
    case 4:
    case 5:
      retrieveData.forEach((element, key) => {
        const documentId = element.bankDocumentId;
        for (let index = key; index < retrieveData.length; index++) {
          if (index !== key) {
            if (documentId === retrieveData[index].bankDocumentId && documentId !== "") {
              retrieveData[index].errorText =
                "Two document IDs can't be same, please change any one of them.";
            } else {
              retrieveData[index].errorText = "";
            }
          }
        }
      });
      break;
  }
  return retrieveData;
};

export const documentIdInvalid = (element: string): boolean => {
  const bankDocumentId = element;
  const regexp = new RegExp(alphanumericRegexForDocumentId);
  const result = !regexp.test(bankDocumentId);
  return result;
};

const invalidDocumentIDErrorMessage = "Document ID must be less than 15 characters";

const retrieveBankDataValidations = (
  applicant: IApplicant[],
  currentTab: number,
  stateValue: string,
  childIndex: number
) => {
  let retrieveBankData = applicant[currentTab].retrieveBankData;

  for (const bankObject of retrieveBankData) {
    if (
      bankObject.errorText == invalidDocumentIDErrorMessage &&
      !documentIdInvalid(bankObject.bankDocumentId)
    ) {
      bankObject.errorText = "";
    }
    if (bankObject.bankDocumentId === stateValue && bankObject.bankDocumentId !== "") {
      retrieveBankData[childIndex].errorText =
        "Two document IDs can't be same, please change any one of them.";
      retrieveBankData = clearOtherErrorTexts(childIndex, retrieveBankData);
      break;
    } else {
      retrieveBankData[childIndex].errorText = "";
      retrieveBankData = clearOtherErrorTexts(childIndex, retrieveBankData);
    }
  }
  if (childIndex === 0) {
    let isRepeated: boolean = false;
    for (let i = 1; i < retrieveBankData.length; i++) {
      if (retrieveBankData[i].bankDocumentId === stateValue) {
        isRepeated = true;
      }
    }
    if (
      retrieveBankData[0].errorText ===
        "Two document IDs can't be same, please change any one of them." &&
      retrieveBankData[0].bankDocumentId === stateValue &&
      !isRepeated
    ) {
      retrieveBankData[0].errorText = "";
    }
  }

  const documentIDNotValid = retrieveBankData.find(
    (provider) => provider.bankDocumentId && documentIdInvalid(provider.bankDocumentId)
  );
  if (documentIDNotValid) {
    documentIDNotValid.errorText = invalidDocumentIDErrorMessage;
  }
  applicant[currentTab].retrieveBankData = retrieveBankData;
  return applicant;
};

export const changeCreditCardError = (
  action: ApplicantSetCreditCardTextErrorAction,
  state: IApplicantInitialState
) => {
  const currentState = Object.assign(state, {});
  const applicant = currentState.applicants;
  applicant[currentState.currentTab].creditCards[action.currentIndex].textError = action.isError;
  return {
    ...currentState,
    applicants: applicant,
  };
};

export const applicantValidationUpdation = (
  action: ApplicantValidationUpdationAction,
  state: IApplicantInitialState
) => {
  const currentState = Object.assign({}, state);
  let applicantsRequiredField: IApplicantRequiredField[] = [
    ...currentState.applicantsRequiredField,
  ];
  applicantsRequiredField = JSON.parse(JSON.stringify(applicantsRequiredField));

  if (action.stateName === "dateOfBirth") {
    if (document.getElementById(`date-of-birth-${currentState.currentTab}-helper-text`)) {
      if (
        (document.getElementById(
          `date-of-birth-${currentState.currentTab}-helper-text`
        ) as HTMLElement).innerHTML === "Date should not be after maximal date"
      ) {
        (document.getElementById(
          `date-of-birth-${currentState.currentTab}-helper-text`
        ) as HTMLElement).innerHTML = "Applicant's age must be >= 18 years.";
        applicantsRequiredField[currentState.currentTab][action.stateName] = true;
      }
    } else {
      applicantsRequiredField[currentState.currentTab][action.stateName] = false;
    }
  } else if (action.stateName === "movedInDate") {
    if (document.getElementById(`movedInDate-${currentState.currentTab}-helper-text`)) {
      applicantsRequiredField[currentState.currentTab][action.stateName] = true;
    } else {
      applicantsRequiredField[currentState.currentTab][action.stateName] = false;
    }
  } else if (action.stateName === "previousAddressMovedInDate") {
    if (
      document.getElementById(`previousAddressMovedInDate-${currentState.currentTab}-helper-text`)
    ) {
      applicantsRequiredField[currentState.currentTab][action.stateName] = true;
    } else {
      applicantsRequiredField[currentState.currentTab][action.stateName] = false;
    }
  } else if (action.stateName === "expiryDate") {
    if (
      document.getElementById(
        `applicant-id-${currentState.currentTab}-expiry-date-${action.childIndex}-helper-text`
      )
    ) {
      applicantsRequiredField[currentState.currentTab][action.childArray as string][
        action.childIndex
      ][action.stateName] = true;
    } else {
      applicantsRequiredField[currentState.currentTab][action.childArray as string][
        action.childIndex
      ][action.stateName] = false;
    }
  } else if (action.stateName === "currentAddress" || action.stateName === "previousAddress") {
    if (action.stateValue) {
      applicantsRequiredField[+action.stateValue][action.stateName] = true;
    }
  }
  return {
    ...currentState,
    applicantsRequiredField,
  };
};

export const affordabilityRentalIncomeUpdate = (
  state: IApplicantInitialState,
  action: AffordabilityRentalIncomeUpdateAction
) => {
  const currentState: IApplicantInitialState = Object.assign({}, state);
  const applicant: IApplicant[] = deepCopyObject(currentState.applicants);
  let incomeRemovalIndexArray: number[] = [...currentState.incomeRemovalIndexArray];
  const incomeRemovalForPropertyIndexArray: number[] = [
    ...currentState.incomeRemovalForPropertyIndexArray,
  ];
  const applicantsRequiredField: IApplicantRequiredField[] = [
    ...currentState.applicantsRequiredField,
  ];
  const incomeSourcesRowAdded: boolean[] = [...currentState.incomeSourcesRowAdded];
  let childElement;
  let childProvisoElement;

  // addition and deletion of Rental Income needs to be done to the first applicant only.
  childElement = deepCopyObject(currentState.applicants[0].incomeSources);
  childProvisoElement = deepCopyObject(currentState.applicants[0].provisoData.incomeSources);
  const noOfInvestmentArray = childElement.filter((element) => element.isFromInvestments);

  if (action.operation === "add") {
    // check for updated values
    if (noOfInvestmentArray.length === action.propertiesLength) {
      const indexToBeUsed = incomeRemovalForPropertyIndexArray.findIndex(
        (element) => element === action.index
      );

      if (indexToBeUsed > -1) {
        childElement[incomeRemovalIndexArray[indexToBeUsed]].amount = action.weeklyRentEstimate;
        childElement[incomeRemovalIndexArray[indexToBeUsed]].noteRequired = true;
        childProvisoElement[incomeRemovalIndexArray[indexToBeUsed]].amount =
          action.weeklyRentEstimate;
      } else {
        const elementToBeRemovedIndex = childElement.findIndex(
          (element) => element.isFromInvestments
        );
        childElement.splice(elementToBeRemovedIndex, 1);
        childProvisoElement.splice(elementToBeRemovedIndex, 1);
        const deletedElement = incomeRemovalIndexArray.splice(indexToBeUsed, 1);

        // if an element was deleted before it so now the index need to be reduced by 1.
        // for example elements were at index 0 and 1, element at 0 was deleted, so now
        // the left index needs to be 0 instead of 1.
        incomeRemovalIndexArray = incomeRemovalIndexArray.map((element) => {
          deletedElement[0] < element ? (element = element - 1) : (element = element);
          return element;
        });
        incomeRemovalForPropertyIndexArray.splice(indexToBeUsed, 1);
        childElement = childElement.concat([
          {
            amount: action.weeklyRentEstimate,
            deletedNote: "",
            deletedNoteRequired: false,
            deletedPartialNoteValue: "",
            duration: `${duration[2].value}`,
            isDeleted: false,
            isFromInvestments: true,
            note: "",
            noteRequired: true,
            partialNoteValue: "",
            provisoOrManual: `${provisoOrManual[1]}`,
            taxStatus: `${taxStatus[0].value}`,
            type: applicantPAYGIncomeTypeOptions[2],
            illionId: "",
          },
        ]);

        childProvisoElement = childProvisoElement.concat([
          {
            amount: 0,
            deletedNote: "",
            deletedNoteRequired: false,
            deletedPartialNoteValue: "",
            duration: `${duration[2].value}`,
            isDeleted: false,
            isFromInvestments: true,
            note: "",
            noteRequired: false,
            partialNoteValue: "",
            provisoOrManual: `${provisoOrManual[1]}`,
            taxStatus: `${taxStatus[0].value}`,
            type: applicantPAYGIncomeTypeOptions[2],
            illionId: "",
          },
        ]);
        applicant[0].incomeSourcesNoteRequired[childProvisoElement.length - 1] = false;
        incomeRemovalIndexArray.push(childElement.length - 1);
        incomeRemovalForPropertyIndexArray.push(action.index);
      }
    } else {
      childElement = childElement.concat([
        {
          amount: action.weeklyRentEstimate,
          deletedNote: "",
          deletedNoteRequired: false,
          deletedPartialNoteValue: "",
          duration: `${duration[2].value}`,
          isDeleted: false,
          isFromInvestments: true,
          note: "",
          noteRequired: true,
          partialNoteValue: "",
          provisoOrManual: `${provisoOrManual[1]}`,
          taxStatus: `${taxStatus[0].value}`,
          type: applicantPAYGIncomeTypeOptions[2],
          illionId: "",
        },
      ]);

      childProvisoElement = childProvisoElement.concat([
        {
          amount: 0,
          deletedNote: "",
          deletedNoteRequired: false,
          deletedPartialNoteValue: "",
          duration: `${duration[2].value}`,
          isDeleted: false,
          isFromInvestments: true,
          note: "",
          noteRequired: false,
          partialNoteValue: "",
          provisoOrManual: `${provisoOrManual[1]}`,
          taxStatus: `${taxStatus[0].value}`,
          type: applicantPAYGIncomeTypeOptions[2],
          illionId: "",
        },
      ]);
      applicant[0].incomeSourcesNoteRequired[childProvisoElement.length - 1] = false;
      incomeRemovalIndexArray.push(childElement.length - 1);
      incomeRemovalForPropertyIndexArray.push(action.index);
    }
  } else if (action.operation === "remove") {
    const indexToBeUsed = incomeRemovalForPropertyIndexArray.findIndex(
      (element) => element === action.index
    );
    if (indexToBeUsed > -1) {
      childElement.splice(incomeRemovalIndexArray[indexToBeUsed], 1);
      childProvisoElement.splice(incomeRemovalIndexArray[indexToBeUsed], 1);
      const deletedElement = incomeRemovalIndexArray.splice(indexToBeUsed, 1);

      // if an element was deleted before it so now the index need to be reduced by 1.
      // for example elements were at index 0 and 1, element at 0 was deleted, so now
      // the left index needs to be 0 instead of 1.
      incomeRemovalIndexArray = incomeRemovalIndexArray.map((element) => {
        deletedElement[0] < element ? (element = element - 1) : (element = element);
        return element;
      });
      incomeRemovalForPropertyIndexArray.splice(indexToBeUsed, 1);
    } else {
      const elementToBeRemovedIndex = childElement.findIndex(
        (element) => element.isFromInvestments && element.amount === action.weeklyRentEstimate
      );
      childElement.splice(elementToBeRemovedIndex, 1);
      childProvisoElement.splice(elementToBeRemovedIndex, 1);
      const deletedElement = incomeRemovalIndexArray.splice(indexToBeUsed, 1);

      // if an element was deleted before it so now the index need to be reduced by 1.
      // for example elements were at index 0 and 1, element at 0 was deleted, so now
      // the left index needs to be 0 instead of 1.
      incomeRemovalIndexArray = incomeRemovalIndexArray.map((element) => {
        deletedElement[0] < element ? (element = element - 1) : (element = element);
        return element;
      });
      incomeRemovalForPropertyIndexArray.splice(indexToBeUsed, 1);
    }
  }
  if (childElement.length >= 1) {
    incomeSourcesRowAdded[currentState.currentTab] = true;
  } else {
    incomeSourcesRowAdded[currentState.currentTab] = false;
  }
  const noteInfo = { ...currentState.noteInformation };
  noteInfo.index = childElement.length > 0 ? childElement.length - 1 : noteInfo.index;
  applicant[0].incomeSources = childElement;
  applicant[0].provisoData.incomeSources = childProvisoElement;
  return {
    ...currentState,
    applicants: applicant,
    applicantsRequiredField,
    incomeSourcesRowAdded,
    incomeRemovalIndexArray,
    incomeRemovalForPropertyIndexArray,
    noteInformation: noteInfo,
  };
};

export const copyAddressOfApplicant = (
  action: ApplicantCopyAddressAction,
  state: IApplicantInitialState
) => {
  const currentState: IApplicantInitialState = Object.assign({}, state);
  let applicant: IApplicant[] = [...currentState.applicants];
  applicant = JSON.parse(JSON.stringify(applicant));
  let applicantsRequiredField: IApplicantRequiredField[] = [
    ...currentState.applicantsRequiredField,
  ];
  applicantsRequiredField = JSON.parse(JSON.stringify(applicantsRequiredField));
  const splitAddress = action.stateName.includes("current")
    ? "currentSplitAddress"
    : "previousSplitAddress";
  if (action.childIndex === 0) {
    applicant[0][action.stateName] = applicant[1][action.stateName];
    applicant[0][splitAddress] = applicant[1][splitAddress];
    applicant[0][action.movedInDateType] = applicant[1][action.movedInDateType];
    applicant[0][action.applicantStatusType] = applicant[1][action.applicantStatusType];
    applicantsRequiredField[0][action.stateName] = applicantsRequiredField[1][action.stateName];
  } else {
    applicant[1][action.stateName] = applicant[0][action.stateName];
    applicant[1][splitAddress] = applicant[0][splitAddress];
    applicant[1][action.movedInDateType] = applicant[0][action.movedInDateType];
    applicant[1][action.applicantStatusType] = applicant[0][action.applicantStatusType];
    applicantsRequiredField[1][action.stateName] = applicantsRequiredField[0][action.stateName];
  }

  if (action.movedInDateType === "movedInDate") {
    const movedInDateDiff = dayjs().diff(
      dayjs(applicant[action.childIndex][action.movedInDateType] as string),
      "year",
      true
    );
    applicant[action.childIndex].movedInDateDiff = movedInDateDiff;
  }

  return {
    ...currentState,
    applicants: applicant,
    applicantsRequiredField,
  };
};

export const updateTypeForUncategorised = (
  action: ApplicantUpdateValueForUncategorisedAction,
  state: IApplicantInitialState
) => {
  const currentState: IApplicantInitialState = Object.assign({}, state);
  let applicant: IApplicant[] = deepCopyObject(currentState.applicants);
  let incomeSourcesNoteRequired = [...applicant[currentState.currentTab].incomeSourcesNoteRequired];
  let childElement = deepCopyObject(
    applicant[currentState.currentTab].incomeSources[action.childIndex]
  );
  let childProvisoElement = deepCopyObject(
    applicant[currentState.currentTab].provisoData.incomeSources[action.childIndex]
  );
  if (action.changedFrom === "Uncategorised") {
    childElement.note = `${childElement.note.trim()} ${action.changedTo} type added.`.trim();
    childElement.partialNoteValue = childElement.note;
    childElement.type = action.changedTo;
    childProvisoElement.type = action.changedTo;
    incomeSourcesNoteRequired[action.childIndex] = childElement.noteRequired;
  }
  // if it is changed to uncategorised.
  else {
    const noteValueToBeRemoved = `${action.changedFrom} type added.`;
    if (childElement.note.includes(noteValueToBeRemoved)) {
      childElement.note = childElement.note.replace(noteValueToBeRemoved, "").trim();
    }
    childElement.partialNoteValue = childElement.note;
    childElement.type = action.changedTo;
    childElement.noteRequired = false;
    childProvisoElement.type = action.changedTo;
    incomeSourcesNoteRequired[action.childIndex] = true;
  }

  applicant[currentState.currentTab].incomeSources[action.childIndex] = childElement;
  applicant[currentState.currentTab].provisoData.incomeSources[
    action.childIndex
  ] = childProvisoElement;
  applicant[currentState.currentTab].incomeSourcesNoteRequired = incomeSourcesNoteRequired;
  return {
    ...currentState,
    applicants: applicant,
  };
};

export const autoPopulateNote = (
  action: ApplicantIsLoanRefiancedNoteAction,
  state: IApplicantInitialState
) => {
  const currentState = Object.assign(state);
  const applicants = [...currentState.applicants];
  const currentRow = applicants[action.currentTab][action.stateName][action.childIndex];

  const newNote = action.stateValue
    ? `${currentRow.note} ${action.note}`
    : currentRow.note.trim().replace(new RegExp(action.note, "g"), "");
  const newPartialNote = action.stateValue
    ? `${currentRow.partialNoteValue} ${action.note}`
    : currentRow.partialNoteValue.trim().replace(new RegExp(action.note, "g"), "");

  currentRow.note = newNote.trim().replace(/ +/g, " ");
  currentRow.partialNoteValue = newPartialNote.trim().replace(/ +/g, " ");

  return { ...state, applicants };
};

export const applicantUpdateLivingArragementFunction = (
  state: IApplicantInitialState,
  action: ApplicantUpdateLivingArragementAction
) => {
  const currentState: IApplicantInitialState = Object.assign(state);
  const applicants = [...currentState.applicants];
  applicants[action.currentTab].applicantLivingArrangement = action.applicantLivingArrangement;

  return {
    ...state,
    applicants,
  };
};

export const disabledFinancialObligationOptions = (type: string) => {
  return (
    type === applicantFinancialObligationTypeOptions[4] ||
    type === applicantFinancialObligationTypeOptions[5] ||
    type === applicantFinancialObligationTypeOptions[6] ||
    type === applicantFinancialObligationTypeOptions[7] ||
    type === applicantFinancialObligationTypeOptions[11]
  );
};
export const applicantAddNote = (state: IApplicantInitialState, action: ApplicantAddNoteAction) => {
  const currentState: IApplicantInitialState = Object.assign(state);
  const applicants = [...currentState.applicants];
  applicants[action.currentTab][action.stateName] = action.note;

  let applicantsRequiredField: IApplicantRequiredField[] = [
    ...currentState.applicantsRequiredField,
  ];
  applicantsRequiredField[action.currentTab].earlyRepaymentStrategyNote = action.note === "";

  applicants[action.currentTab].privacyConsent.isFormValid = checkApplicantFormFieldOrNot(
    applicantsRequiredField[action.currentTab],
    applicants[action.currentTab]
  );
  return { ...state, applicants, applicantsRequiredField };
};

/**
 *
 * @param state takes the whole applicant state
 * @param action contains the applicant index for which the override action has to be performed
 * @returns the updated state after overriding the credit score as 700.
 */
export const applicantOverrideCreditScore = (
  state: IApplicantInitialState,
  action: ApplicantOverrideCreditScoreAction
) => {
  const currentState: IApplicantInitialState = Object.assign(state);
  const applicants = [...currentState.applicants];
  const privacyConsent = { ...applicants[action.currentTab].privacyConsent };
  privacyConsent.creditScore = 700;
  privacyConsent.formSubmit = true;
  privacyConsent.idsMatrix = true;
  privacyConsent.isFormValid = true;
  privacyConsent.isInformationVerified = true;
  applicants[action.currentTab].privacyConsent = privacyConsent;
  return { ...state, applicants };
};

export const financialObligationRentUpdate = (
  state: IApplicantInitialState,
  action: ApplicantStatusRentUpdateAction
) => {
  const currentState: IApplicantInitialState = Object.assign(state);
  let applicants = [...currentState.applicants];

  if (action.operation === "add") {
    const index = action.howManyApplicant === "Couple" ? 0 : action.index;

    const rentObject = {
      balance: 0,
      interestRate: 0,
      paymentAmount: action.rent ? action.rent : 150,
      duration: "Week",
      isLoanRefinanced: false,
      type: "Rent",
      isFromApplicantStatus: true,
      note: "",
      noteRequired: false,
      partialNoteValue: "",
      isDeleted: false,
      deletedPartialNoteValue: "",
      provisoOrManual: `${provisoOrManual[0]}`,
      deletedNote: "",
      deletedNoteRequired: false,
      illionId: "",
    };

    const rent = applicants[index].financialObligation.find(
      (item) => item.type === "Rent" && item.isFromApplicantStatus
    );

    if (!rent) {
      applicants[index].financialObligation.push(rentObject);
      applicants[index].provisoData.financialObligation.push(rentObject);
      applicants[index].financialObligationNoteRequired.push(false);
      applicants[index].financialObligationDeletedNoteRequired.push(false);
    }
  } else {
    if (action.howManyApplicant === "Couple") {
      if (
        rentStatuses.includes(applicants[0].applicantStatus) ||
        rentStatuses.includes(applicants[1].applicantStatus)
      ) {
        return { ...currentState };
      }
    }
    applicants = removeRentFromApplicant(applicants, action.index, action.howManyApplicant);
  }
  currentState.financialObligationRowAdded[action.index] = Boolean(
    applicants[action.index].financialObligation.length
  );
  return { ...currentState, applicants };
};

export const removeRentFromApplicant = (
  applicants: IApplicant[],
  index: number,
  howManyApplicant?: string
) => {
  let indexToBeUsed = index;
  if (howManyApplicant === "Couple") {
    indexToBeUsed = 0;
  }
  const rentIndex = applicants[indexToBeUsed].financialObligation.findIndex(
    (item) => !item.isFromApplicantStatus
  );

  if (rentIndex > -1) {
    applicants[indexToBeUsed].financialObligationNoteRequired.splice(rentIndex, 1);
    applicants[indexToBeUsed].financialObligationDeletedNoteRequired.splice(rentIndex, 1);
  }
  const newFinancialObligations = applicants[indexToBeUsed].financialObligation.filter(
    (item) => !item.isFromApplicantStatus
  );
  const newProvisoFinancialObligations = applicants[
    indexToBeUsed
  ].provisoData.financialObligation.filter((item) => !item.isFromApplicantStatus);
  applicants[indexToBeUsed].financialObligation = newFinancialObligations;
  applicants[indexToBeUsed].provisoData.financialObligation = newProvisoFinancialObligations;

  return applicants;
};

export const findApplicantRentObligation = (applicant: IApplicant) => {
  return applicant.financialObligation.findIndex(
    (item: IFinancialObligation) => item.type === "Rent" && item.isFromApplicantStatus
  );
};
