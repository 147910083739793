import React from "react";

const BrandingIcon = (props: { className?: string }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23.89 24.07"
    className={props.className ? props.className : "branding-icon"}
    height="24" width="26">
      <path
        d="M1,22.44s0,0,0-.05a1.53,1.53,0,0,1,.42-.95c.32-.35.63-.71,1-1.05,2.26-2.28,4.54-4.56,6.8-6.85q2.27-2.27,4.5-4.56a2.16,2.16,0,0,0,.56-1.32c0-.59,0-1.18.06-1.77a3.94,3.94,0,0,1,.07-.77,5.32,5.32,0,0,1,6.44-4,2.32,2.32,0,0,1,.37.13c.11.06.12.11,0,.21l-.08.09L20.14,2.64c-.41.43-.83.84-1.22,1.28a1.37,1.37,0,0,0-.3.54,1.55,1.55,0,0,0,.29,1.4,9.19,9.19,0,0,0,.67.73,5.17,5.17,0,0,0,.85.71,1.44,1.44,0,0,0,1.46,0A1.92,1.92,0,0,0,22.4,7l1.7-1.64a5.72,5.72,0,0,1,.46-.4c.11-.09.17-.08.22,0a1.11,1.11,0,0,1,.07.26c0,.19,0,.39.07.59V6.89c0,.12,0,.23-.05.35a5.33,5.33,0,0,1-5.5,4.3,3,3,0,0,0-.67,0,2.55,2.55,0,0,0-1.48.64c-.27.24-.54.48-.79.74L7.67,21.64c-.94.93-1.9,1.84-2.86,2.76A2,2,0,0,1,3.68,25a2.36,2.36,0,0,1-2.5-1.52A3.9,3.9,0,0,1,1,22.88Z"
        transform="translate(-1.04 -0.94)"
      />
    </svg>
  );
};

export default BrandingIcon;
