import { moduleConstant } from "../../../constants";
import {
  REVIEW_SHOW_DOWNLOAD_STATUS,
  REVIEW_FULL_FORM_VALIDATION,
  REVIEW_FULL_FORM_VALIDATION_FOR_SEND_QUOTE,
  REVIEW_SET_VALIDATION,
  REVIEW_SAVE_FUNDS_TO_COMPLETE,
  REVIEW_SHOW_APPROVE_LOADER,
  REVIEW_CLEAR_VALIDATIONS_ARRAY,
  ReviewSetValidationActionType,
  ReviewFullFormValidationForSendQuoteActionType,
  ReviewSaveFundsToCompleteActionType,
  ReviewShowApproveLoaderActionType,
  ReviewFullFormValidationActionType,
  ReviewShowDownloadStatusActionType,
  ReviewClearValidationsArrayActionType,
  ReviewLoadEntireStateActionType,
  REVIEW_LOAD_ENTIRE_STATE,
  ReviewShowApproveModalActionType,
  REVIEW_SHOW_APPROVE_MODAL,
  REVIEW_SET_APPLICANT_FORM_SUBMITTED_FLAG,
  ReviewSetApplicantFormSumittedFlagActionType,
  ReviewPageVisitedActionType,
  REVIEW_PAGE_VISITED,
  ReviewSetLegacyIDActionType,
  REVIEW_SET_LEGACY_ID,
} from "./reviewActionTypes";
import { TAppStateType } from "../../reducers/reducers";

export const reviewShowDownloadStatusAction: ReviewShowDownloadStatusActionType = (
  status: string
) => {
  return {
    module: [moduleConstant.review],
    status,
    type: REVIEW_SHOW_DOWNLOAD_STATUS,
  };
};

export const reviewShowApproveModal: ReviewShowApproveModalActionType = (toggleShow: boolean) => {
  return {
    module: [moduleConstant.review],
    toggleShow,
    type: REVIEW_SHOW_APPROVE_MODAL,
  };
};

export const reviewFullFormValidationAction: ReviewFullFormValidationActionType = (
  formInvalidArray
) => {
  return {
    formInvalidArray,
    module: [moduleConstant.objective, moduleConstant.applicant],
    type: REVIEW_FULL_FORM_VALIDATION,
  };
};

export const reviewShowApproveLoaderAction: ReviewShowApproveLoaderActionType = ({
  toggleLoader,
  uploadLoader,
  scenarioApproveLoader,
  isSubmittedToCapital,
}) => {
  return {
    module: [moduleConstant.objective, moduleConstant.applicant],
    toggleLoader,
    uploadLoader,
    scenarioApproveLoader,
    isSubmittedToCapital,
    type: REVIEW_SHOW_APPROVE_LOADER,
  };
};

export const reviewClearValidationsArrayAction: ReviewClearValidationsArrayActionType = () => {
  return {
    module: [moduleConstant.review],
    type: REVIEW_CLEAR_VALIDATIONS_ARRAY,
  };
};

export const reviewSaveFundsToCompleteAction: ReviewSaveFundsToCompleteActionType = (
  fundsToCompleteIndividual,
  fundsToCompleteTotal
) => {
  return {
    fundsToCompleteIndividual,
    fundsToCompleteTotal,
    module: [moduleConstant.review],
    type: REVIEW_SAVE_FUNDS_TO_COMPLETE,
  };
};

export const reviewFullFormValidationForSendQuoteAction: ReviewFullFormValidationForSendQuoteActionType = (
  formInvalidationArrayForSendQuote
) => {
  return {
    formInvalidationArrayForSendQuote,
    module: [moduleConstant.objective, moduleConstant.applicant],
    type: REVIEW_FULL_FORM_VALIDATION_FOR_SEND_QUOTE,
  };
};

export const reviewSetValidationAction: ReviewSetValidationActionType = (validations, key) => {
  return {
    key,
    module: [moduleConstant.review],
    type: REVIEW_SET_VALIDATION,
    validations,
  };
};

export const reviewSetApplicantFormSumittedFlag: ReviewSetApplicantFormSumittedFlagActionType = (
  isApplicantFormNotSubmitted: boolean
) => {
  return {
    isApplicantFormNotSubmitted,
    module: [moduleConstant.review],
    type: REVIEW_SET_APPLICANT_FORM_SUBMITTED_FLAG,
  };
};

export const reviewLoadEntireStateAction: ReviewLoadEntireStateActionType = (
  state: TAppStateType["review"]
) => {
  return {
    module: [moduleConstant.error],
    state,
    type: REVIEW_LOAD_ENTIRE_STATE,
  };
};

export const reviewPageVisitedAction: ReviewPageVisitedActionType = (isVisited: boolean) => {
  return {
    module: [moduleConstant.review],
    isVisited,
    type: REVIEW_PAGE_VISITED,
  };
};

export const reviewSetLegacyID: ReviewSetLegacyIDActionType = (legacyID: string) => {
  return {
    legacyID,
    module: [moduleConstant.scenario],
    type: REVIEW_SET_LEGACY_ID,
  };
};
