import { NextAndBackButtonSectionProps } from "../components/common/nextAndBackButtonSection";
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type AffordabilityProps = Omit<
  NextAndBackButtonSectionProps,
  "hideReview" | "preApprove" | "nextUrl"
>;
export type ApplicantProps = Omit<
  NextAndBackButtonSectionProps,
  "showReview" | "preApprove" | "hideReview"
>;
export type ObjectiveProps = Omit<
  NextAndBackButtonSectionProps,
  "showReview" | "preApprove" | "hideReview" | "backUrl"
>;
export type ReviewProps = Omit<NextAndBackButtonSectionProps, "backUrl" | "nextUrl" | "showReview">;

export const isAffordabilityProps = (
  validator: AffordabilityProps | ApplicantProps | ObjectiveProps | ReviewProps
): validator is AffordabilityProps => {
  return (
    (validator as AffordabilityProps).showReview !== undefined &&
    (validator as AffordabilityProps).backUrl !== undefined
  );
};
export const isSolutionOrApplicantProps = (
  validator: AffordabilityProps | ApplicantProps | ObjectiveProps | ReviewProps
): validator is ApplicantProps => {
  return (
    (validator as ApplicantProps).backUrl !== undefined &&
    (validator as ApplicantProps).nextUrl !== undefined &&
    (validator as ApplicantProps).nextBtnName !== undefined
  );
};

export const isObjectiveProps = (
  validator: AffordabilityProps | ApplicantProps | ObjectiveProps | ReviewProps
): validator is ObjectiveProps => {
  return (
    (validator as ObjectiveProps).nextUrl !== undefined &&
    (validator as ObjectiveProps).nextBtnName !== undefined &&
    (validator as ApplicantProps).backUrl == undefined
  );
};
export const isReviewProps = (
  validator: AffordabilityProps | ApplicantProps | ObjectiveProps | ReviewProps
): validator is ReviewProps => {
  return (
    (validator as ReviewProps).preApprove !== undefined &&
    (validator as ReviewProps).hideReview !== undefined
  );
};
