import { Reducer } from "redux";
import { IEditBrokerInitialState, ITeamAdmin } from "amm-admin-interface";
import { moduleConstant } from "../../../constants";
import * as types from "../../actions/adminActions/adminActionTypes";
import { LOGIN_USER_LOGOUT, LoginUserLogoutAction } from "../../actions/login/loginActionType";
import { editBrokerState, reviewNoteDefaultState } from "./adminInitialState";
import {
  clearBrokerPartialValues,
  setBrokerList,
  setBrokerSaveValues,
  setBrokerStateValues,
} from "./adminReducerUtility";
import { deepCopyObject } from "../../../utility";
import { IAssignUserScenariosModalData } from "../../actions/adminActions/adminActions";

export const teamInitialState: ITeamAdmin = {
  teamId: 0,
  teamName: "",
  existingTeamMembers: [],
  allTeamMembers: [],
};

export const adminInitialState: IEditBrokerInitialState = {
  brokerList: [],
  brokerNotesData: "<p><br></p>", // loan writer notes
  brokerSavingLoader: false,
  brokerState: editBrokerState,
  isNew: true,
  isAdminSelectModalVisible: false,
  adminDuplicateScenarioID: 0,
  newTeam: teamInitialState,
  isTeamSaveDisable: false,
  showTeamLoader: false,
  openTeamMemberModal: false,
  saveTeamButtonLoader: false,
  mockedCurrentUserId: 0,
  operation: "",
  oldUserRole: -1,
  assignUserScenarioModal: {
    isOpen: false,
  },
  scenariosForCreditReview: [],
  allUserScenariosLoader: false,
  reviewNotesData: reviewNoteDefaultState,
  originators: [],
  notifications: [],

  leads: [],
  leadsLoader: false,
  leadUsers: [],
  isAssignModalVisible: false,
};

const adminReducer: Reducer<IEditBrokerInitialState, types.AdminActions | LoginUserLogoutAction> = (
  state: IEditBrokerInitialState = adminInitialState,
  action: types.AdminActions | LoginUserLogoutAction
) => {
  if (
    action &&
    action.module &&
    action.module.filter((currentModule) => Object.values(moduleConstant).includes(currentModule))
      .length === 0
  ) {
    return { ...state };
  }

  switch (action.type) {
    case types.ADMIN_CAPITAL_STATUS_UPDATE:
      const newScenarios = JSON.parse(JSON.stringify(state.scenariosForCreditReview));
      const foundScenarioIndex = newScenarios.findIndex(
        (scenario: any) => scenario.id === action.scenarioId
      );
      if (foundScenarioIndex >= 0) {
        newScenarios[foundScenarioIndex].scenario_datas[0].capitalStatus = action.capitalStatus;
      }
      return {
        ...state,
        scenariosForCreditReview: newScenarios,
      };
    case types.ADMIN_SET_EDIT_BROKER_STATE:
      const brokerState = setBrokerStateValues(
        action.state,
        state.brokerState,
        action.isNew,
        state.notifications
      );
      const isNew = action.isNew;
      const oldUserRole = brokerState.roleId;
      return {
        ...state,
        brokerState,
        isNew,
        oldUserRole,
      };

    case types.ADMIN_BROKER_VALUE_UPDATE:
      const currentState = Object.assign({}, state);
      const brokerSTate = Object.assign({}, currentState.brokerState);
      if (action.key === "userNotifications" && action.child) {
        brokerSTate.userNotifications[action.index ? action.index : 0][action.child] = action.value;
        return {
          ...currentState,
          brokerState: brokerSTate,
        };
      } else {
        currentState.brokerState[action.key] = action.value;
        return {
          ...currentState,
        };
      }

    case types.ADMIN_SET_BROKER_LIST:
      return setBrokerList(action, state);

    case types.ADMIN_BROKER_SAVE_LOADER:
      return {
        ...state,
        brokerSavingLoader: action.isLoading,
      };

    case types.ADMIN_OPEN_SELECT_MODAL_ACTION:
      return {
        ...state,
        isAdminSelectModalVisible: action.isOpen,
        adminDuplicateScenarioID: action.scenarioID,
        mockedCurrentUserId: action.userID,
        operation: action.operation,
      };

    case types.ADMIN_EDIT_BROKER_BUTTON_CLICK:
      if (action.buttonType === "save") {
        state.brokerState = setBrokerSaveValues(state.brokerState, state.isNew);
      } else {
        state.brokerState = clearBrokerPartialValues(state.brokerState);
      }
      return {
        ...state,
      };

    case types.ADMIN_UPDATE_TEAM_MEMBER:
      if (action.addOrRemove === "add") {
        const tempTeam = deepCopyObject(state.newTeam);
        tempTeam.existingTeamMembers.push(action.broker);
        // tempTeam.allTeamMembers = state.brokerList.filter((broker) => broker.id !== action.broker.id);
        return {
          ...state,
          newTeam: tempTeam,
        };
      } else {
        const tempTeam = deepCopyObject(state.newTeam);
        // tempTeam.allTeamMembers.push(action.broker);
        tempTeam.existingTeamMembers = state.newTeam.existingTeamMembers.filter(
          (broker) => broker.id !== action.broker.id
        );
        return {
          ...state,
          newTeam: tempTeam,
        };
      }

    case types.ADMIN_UPDATE_TEAM_DETAIL:
      const team = deepCopyObject(state.newTeam);
      team.teamName = action.teamName;
      return {
        ...state,
        newTeam: team,
      };

    case types.ADMIN_SET_TEAM:
      const tempTeam = deepCopyObject(action.state);
      tempTeam.allTeamMembers = state.newTeam.allTeamMembers;
      return {
        ...state,
        newTeam: deepCopyObject(tempTeam),
      };

    case types.ADMIN_SET_TEAM_SAVE_DISABLE:
      return {
        ...state,
        isTeamSaveDisable: action.isDisable,
      };

    case types.ADMIN_SET_TEAM_LOADER:
      return {
        ...state,
        showTeamLoader: action.showLoader,
      };

    case types.ADMIN_OPEN_MEMBER_MODAL:
      return {
        ...state,
        openTeamMemberModal: action.isOpen,
      };
    case types.ADMIN_OPEN_ASSIGN_USER_SCENARIO_MODAL:
      const assignUserScenarioModal: IAssignUserScenariosModalData = { isOpen: false };
      if (action.modalData.isOpen) {
        assignUserScenarioModal.isOpen = action.modalData.isOpen;
      }
      if (action.modalData.calledFrom) {
        assignUserScenarioModal.calledFrom = action.modalData.calledFrom;
      }
      if (action.modalData.scenarioId) {
        assignUserScenarioModal.scenarioId = action.modalData.scenarioId;
      }
      if (action.modalData.okayAction) {
        assignUserScenarioModal.okayAction = action.modalData.okayAction;
      }
      if (action.modalData.cancelAction) {
        assignUserScenarioModal.cancelAction = action.modalData.cancelAction;
      }
      return {
        ...state,
        assignUserScenarioModal,
      };

    case types.ADMIN_SET_SAVE_TEAM_LOADER:
      return {
        ...state,
        saveTeamButtonLoader: action.saveTeamButtonLoader,
      };

    case types.ADMIN_SET_CREDIT_REVIEW_SCENARIOS:
      return {
        ...state,
        scenariosForCreditReview: action.scenariosForCreditReview,
      };

    case types.ADMIN_SET_CREDIT_REVIEW_SCENARIOS_LOADER:
      return {
        ...state,
        allUserScenariosLoader: action.allUserScenariosLoader,
      };

    case types.ADMIN_REVIEW_NOTES:
      const reviewNotesData = action.reviewNotesData;
      return {
        ...state,
        reviewNotesData,
      };

    case types.ADMIN_BROKER_NOTES:
      const brokerNotesData = action.brokerNotesData;
      return {
        ...state,
        brokerNotesData,
      };

    case types.ADMIN_SET_LEADS:
      const leads = action.leads;
      const leadUsers = action.users;
      return {
        ...state,
        leads,
        leadUsers,
      };

    case types.ADMIN_ADD_ORIGINATORS:
      return { ...state, originators: action.originators };

    case types.ADMIN_ADD_NOTIFICATIONS:
      return { ...state, notifications: action.notifications };

    case types.ADMIN_SET_ASSIGN_LEAD_MODAL_VISIBILITY:
      return { ...state, isAssignModalVisible: action.isAssignModalVisible };

    case LOGIN_USER_LOGOUT:
      return { ...adminInitialState };
    case types.ADMIN_SET_LEADS_LOADER:
      return { ...state, leadsLoader: action.leadsLoader };
    default:
      return {
        ...state,
      };
  }
};

export default adminReducer;
