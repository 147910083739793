import React from "react";
import { useMutation } from "react-apollo-hooks";
import { Button } from "react-md";
import { connect } from "react-redux";
import { DELETE_UPLOAD_BY_ID } from "../../constants/queryAndMutations";
import { deleteFileModalCloseAction } from "../../store/actions/uploadDocumentsActions/uploadDocumentsAction";
import { DeleteFileModalCloseActionType } from "../../store/actions/uploadDocumentsActions/uploadDocumentsActionType";
import { TAppStateType } from "../../store/reducers/reducers";
import { uploadDeleteById } from "../../constants/userActions";
import { ErrorGenericErrorHandleActionType } from "../../store/actions/errorActions/errorActionType";
import { errorGenericErrorHandleAction } from "../../store/actions/errorActions/errorAction";
import { Dialog, DialogContent, DialogActions } from "@material-ui/core";
import { successSnackBarHandleAction } from "../../store/actions/snackbarSuccessAction/successAction";
import { SuccessSnackBarHandleActionType } from "../../store/actions/snackbarSuccessAction/successActionType";

interface IDeleteUploadById {
  deleteUploadById: { id: number; fileName: string };
}
interface IVariables {
  id: number;
  scenario_id: number;
  userAction: {
    actionPassed: string;
  };
}
interface StateProps {
  visible: boolean;
  deleteFileId: number;
  currentUserId: number;
  uploadDocumentsForAllScenariosAsReviewer: boolean;
}
interface OwnProps {
  scenarioId: number;
  currentScenarioUserId: number;
  isClickedByReviewer: boolean;
}
interface DispatchProps {
  deleteFileModalCloseAction: DeleteFileModalCloseActionType;
  errorGenericErrorHandleAction: ErrorGenericErrorHandleActionType;
  successSnackBarHandleAction: SuccessSnackBarHandleActionType;
}

type DeleteModalProps = StateProps & DispatchProps & OwnProps;

const DeleteModal: React.FC<DeleteModalProps> = (props: DeleteModalProps) => {
  const removeFocus = () => {
    setTimeout(() => {
      const items = document.getElementsByClassName("delete-icon");
      Array.from(items).forEach((item) => {
        if (document.activeElement === item) {
          (item as HTMLElement).blur();
        }
      });
    }, 500);
  };

  const deleteFileHandler = async () => {
    props.deleteFileModalCloseAction(false);
    try {
      const { data } = await deleteUpload();
      if (data && data.deleteUploadById && data.deleteUploadById.id) {
        props.successSnackBarHandleAction(
          `File: ${data.deleteUploadById.fileName} deleted successfully for Scenario ID: ${props.scenarioId}`
        );
      }
    } catch (error) {
      if (error.message === "Network error: Response not successful: Received status code 500") {
        props.errorGenericErrorHandleAction(
          "The specific file couldn't be deleted, please try again."
        );
      } else {
        props.errorGenericErrorHandleAction(error.message);
      }
    }
  };

  const actionPassedForDeleteDocument = () => {
    if (props.isClickedByReviewer) {
      return uploadDeleteById[2];
    } else if (props.currentScenarioUserId === props.currentUserId) {
      return uploadDeleteById[0];
    } else if (props.uploadDocumentsForAllScenariosAsReviewer) {
      return uploadDeleteById[3];
    } else {
      return uploadDeleteById[1];
    }
  };

  const [deleteUpload] = useMutation<IDeleteUploadById, IVariables>(DELETE_UPLOAD_BY_ID, {
    variables: {
      id: props.deleteFileId,
      scenario_id: props.scenarioId,
      userAction: { actionPassed: actionPassedForDeleteDocument() },
    },
  });

  return (
    <div id="historyAndUpload" data-testid="delete-modal-test">
      <Dialog
        id="note-modal overflow-auto"
        aria-labelledby="customized-dialog-title"
        open={props.visible}
        className="modal-sm"
        onClose={() => {
          props.deleteFileModalCloseAction(false);
        }}
      >
        <DialogContent>
          <label>Are you sure you want to delete this file?</label>
        </DialogContent>
        <DialogActions>
          <Button
            className="warning-modal-cancel-btn"
            flat={true}
            swapTheming={true}
            onClick={() => {
              removeFocus();
              props.deleteFileModalCloseAction(false);
            }}
          >
            CANCEL
          </Button>
          <Button
            className="select-tag-modal-ok-btn"
            flat={true}
            secondary={true}
            swapTheming={true}
            onClick={deleteFileHandler}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export const mapStateToProps = (state: TAppStateType) => {
  return {
    visible: state.upload.deleteModalVisibility,
    deleteFileId: state.upload.deleteFileId,
    currentUserId: state.login.currentUser.id,
    uploadDocumentsForAllScenariosAsReviewer:
      state.login.userPermissions.uploadDocumentsForAllScenariosAsReviewer,
  };
};

export const dispatchStateToProps = {
  deleteFileModalCloseAction,
  errorGenericErrorHandleAction,
  successSnackBarHandleAction,
};

export default connect(mapStateToProps, dispatchStateToProps)(DeleteModal);
