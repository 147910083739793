import { IEmploymentObject } from "amm-applicant-interface";
import {
  IComparableRentalListings,
  IComparableSaleListings,
  IComparableSales,
  IPropertyAmenities,
} from "amm-objective-interface";
import { EmploymentTypes } from "../../../constants";
import { deepCopyObject } from "../../../utility";

export const formatPropertyStateData = (currentState: any) => {
  delete currentState.property_id;
  delete currentState.location;
  delete currentState.coverage_probability_percent;

  // we need to keep first 4 results only.
  const comparableSales: IComparableSales[] = currentState.comparable_sales.slice(0, 4);
  comparableSales.map(
    (sale: IComparableSales) =>
      delete sale.sale_id && delete sale.location && delete sale.property_id && delete sale.state
  );
  currentState.comparable_sales = deepCopyObject(comparableSales);

  const comparableSaleListings: IComparableSaleListings[] = currentState.comparable_sale_listings.slice(
    0,
    4
  );
  comparableSaleListings.map(
    (sale: IComparableSaleListings) =>
      delete sale.listing_id && delete sale.location && delete sale.property_id && delete sale.state
  );
  currentState.comparable_sale_listings = deepCopyObject(comparableSaleListings);

  const comparableRentalListings: IComparableRentalListings[] = currentState.comparable_rental_listings.slice(
    0,
    4
  );
  comparableRentalListings.map((rent: IComparableRentalListings) => {
    return (
      delete rent.listing_id && delete rent.location && delete rent.property_id && delete rent.state
    );
  });
  currentState.comparable_rental_listings = deepCopyObject(comparableRentalListings);

  return currentState;
};

export const setPropertyAmenities = (
  bedrooms: number,
  bathrooms: number,
  carparks: number,
  bedroomsInitial: number,
  carparksInitial: number,
  bathroomsInitial: number
) => {
  let propertyAmenities: IPropertyAmenities = {
    carparks: undefined,
    bedrooms: undefined,
    bathrooms: undefined,
  };
  if (carparks !== carparksInitial) {
    propertyAmenities.carparks = carparks;
  }
  if (bedrooms !== bedroomsInitial) {
    propertyAmenities.bedrooms = bedrooms;
  }
  if (bathrooms !== bathroomsInitial) {
    propertyAmenities.bathrooms = bathrooms;
  }
  return propertyAmenities;
};

export const checkEmploymentFieldHandler = (employmentObj: IEmploymentObject) => {
  if (employmentObj.employmentType === EmploymentTypes.Other) {
    const isEmploymentTypeOtherAndIsCurrentValid =
      !employmentObj.employmentBasis || !employmentObj.startDate;
    if (employmentObj.isCurrent) {
      return isEmploymentTypeOtherAndIsCurrentValid;
    } else {
      return (
        isEmploymentTypeOtherAndIsCurrentValid || !employmentObj.startDate || !employmentObj.endDate
      );
    }
  }
  const isPrimaryEmploymentValid =
    !employmentObj.abn ||
    !employmentObj.abnStatus ||
    !employmentObj.businessName ||
    !employmentObj.employmentBasis ||
    !employmentObj.jobTitle ||
    !employmentObj.industry ||
    !employmentObj.nameType ||
    !employmentObj.employmentType ||
    !employmentObj.startDate;

  if (employmentObj.isCurrent) {
    return isPrimaryEmploymentValid;
  } else {
    return isPrimaryEmploymentValid || !employmentObj.endDate;
  }
};
