import { IReviewNote, IReviewNoteData } from "amm-admin-interface";
import React, { useEffect, useState } from "react";
import { AdminReviewNotesActionType } from "../../store/actions/adminActions/adminActionTypes";
import { adminReviewAddNotes } from "../../store/actions/adminActions/adminActions";
import { TAppStateType } from "../../store/reducers/reducers";
import { connect } from "react-redux";
import { STATUS_NAMES, ReviewNotesScreens } from "../../constants";
import { Checkbox, FormControlLabel, FormGroup } from "@material-ui/core";
import { useDebounce } from "./useDebounce";
import { defaultReviewNoteValue } from "../../store/reducers/adminReducers/adminInitialState";
import { deepCopyObject } from "../../utility";
import RichTextEditor, { EditorValue } from "react-rte";
import { toolbarConfig } from "../../utility/toolBarConfig";

interface StateProps {
  reviewNotes: IReviewNoteData;
  viewCreditReviewPermission: boolean;
  scenarioStatus: string;
  showReviewScreen: boolean;
  loggedInUser: number;
}

interface OwnProps {
  currentPage: ReviewNotesScreens;
}

interface DispatchProps {
  adminReviewAddNotes: AdminReviewNotesActionType;
}

enum REVIEW_NOTES_FIELDS {
  reviewNoteText = "reviewNoteText",
  isReviewCompleted = "isReviewCompleted",
}

type ReviewNotesProps = StateProps & OwnProps & DispatchProps;

export const ReviewNotes: React.FC<ReviewNotesProps> = (props: ReviewNotesProps) => {
  const [data, setData] = useState<IReviewNote>(
    props.reviewNotes
      ? props.reviewNotes[props.currentPage]
      : deepCopyObject(defaultReviewNoteValue)
  );
  const debouncedInput = useDebounce(data.reviewNoteText, 500);
  const stateData = props.reviewNotes? RichTextEditor.createValueFromString(props.reviewNotes[props.currentPage].reviewNoteText,'html'): RichTextEditor.createEmptyValue()
  const [textValue, setTextValue] = useState<EditorValue>(stateData);
  useEffect(() => {
    if (props.showReviewScreen) {
      setData(props.reviewNotes[props.currentPage]);
    }
  }, [props.showReviewScreen]);

  useEffect(() => {
    props.adminReviewAddNotes({ ...props.reviewNotes, [props.currentPage]: data });
  }, [debouncedInput, data.isReviewCompleted]);

  const handleRTEChange = (value: EditorValue) => {
    setData({
      ...data,
      reviewNoteText: value.toString('html'),
    reviewerID: props.loggedInUser
    })

    setTextValue(value);
  };

  const handleCheckboxChange=(event:React.ChangeEvent<HTMLInputElement>)=>{
    if (event.target.name === REVIEW_NOTES_FIELDS.reviewNoteText)
      setData({ ...data, [event.target.name]: event.target.value });
    else
      setData({ ...data, [event.target.name]: event.target.checked})
  }

  const shouldShowReviewNotesSection = (status: string) => {
    return (
      props.viewCreditReviewPermission &&
      [
        `${STATUS_NAMES.creditReview}`,
        `${STATUS_NAMES.reviewComplete}`,
        `${STATUS_NAMES.approvedPending}`,
      ].includes(status)
    );
  };
 
  return shouldShowReviewNotesSection(props.scenarioStatus) ? (
    <div className="margin-bottom-20">
      <RichTextEditor
        value={textValue}
        className ="review_Notes"
        toolbarConfig={toolbarConfig}
        placeholder="Review Notes"
        onChange={handleRTEChange}
      />
      <FormGroup row>
        <FormControlLabel
          className="review-checkbox"
          control={
            <Checkbox
              checked={data.isReviewCompleted}
              onChange={handleCheckboxChange}
              name={REVIEW_NOTES_FIELDS.isReviewCompleted}
            />
          }
          label="Credit Review for this page is complete"
        ></FormControlLabel>
      </FormGroup>
    </div>
  ) : (
    <></>
  );
};

export const mapStateToProps = (state: TAppStateType) => {
  return {
    reviewNotes: state.admin.reviewNotesData,
    viewCreditReviewPermission: state.login.userPermissions.viewCreditReview,
    scenarioStatus: state.scenario.currentScenarioStatus,
    showReviewScreen: state.help.showReviewScreen,
    loggedInUser: state.login.id ? state.login.id : -1,
  };
};

export const mapDispatchToProps = {
  adminReviewAddNotes,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReviewNotes);
