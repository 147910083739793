import axios from "axios";
import { dashboardScenarioSave, pdfGeneration, propertyEstimateApi } from "../../../constants";
import { errorGenericErrorHandleAction } from "../../actions/errorActions/errorAction";
import {
  reviewShowApproveLoaderAction,
  reviewShowDownloadStatusAction,
} from "../../actions/reviewActions/reviewActions";
import { profileThunk } from "../profileThunk";
import { deepCopyObject } from "../../../utility";
import { getProvisoDataThunk } from "../affordabilityPageThunk/getProvisoDataThunk";
import history from "../../../routes/history";
import { helpShowReviewScreenAction } from "../../actions/helpActions/helpAction";
import {
  scenarioGeneratePdfSelfAction,
  scenarioDashboardSaveAction,
  propertyGetPropertyEstimateAction,
} from "../../../constants/userActions";
import { Action } from "redux";
import { ThunkAction } from "redux-thunk";
import { TAppStateType } from "../../reducers/reducers";
import { IApplicant } from "amm-applicant-interface";
import { loginGetSelectedUserScenariosAction } from "../../actions/login/loginAction";
import {
  scenarioSaveDiscardModalShowAction,
  scenarioSaveAsOwnScenarioModalShowAction,
  scenarioLoadAction,
} from "../../actions/scenarioActions/scenarioActions";
import { openSaveAsOwnScenario } from "../utility/openSaveAsOwnScenario";
import { IGallery, IPropertyAmenities, IPropertyArray } from "amm-objective-interface";
import { formatPropertyStateData, setPropertyAmenities } from "../utility";

interface IProperty {
  placeId: string;
  postCode: number | undefined;
  images: IGallery[];
  bedrooms: number | undefined;
  bathrooms: number | undefined;
  carparks: number | undefined;
  address: string;
}

const getIdsData = async (
  bedrooms: number | undefined,
  bathrooms: number | undefined,
  carparks: number | undefined,
  placeId: string,
  postCode: number | undefined,
  scenarioId: number,
  token: string,
  userIdForScenario: number,
  images: IGallery[],
  brandName: string,
  address: string,
  teamAction: boolean
): Promise<any> => {
  try {
    const parameters = {
      bathrooms,
      bedrooms,
      carparks,
      property_id: placeId,
      postcode: postCode,
      timedata: Date.now(),
      user_action: propertyGetPropertyEstimateAction[0],
    };
    const response = await axios.get(propertyEstimateApi, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: parameters,
    });

    const currentState = response.data.payload;
    currentState.branding = {};
    currentState.branding.brandName = brandName ? brandName : "";
    formatPropertyStateData(currentState);

    // we have to show maximum 5 images on pdf
    currentState.images = images.slice(0, 5);
    currentState.brand = brandName;
    currentState.propertyAddress = address;

    const resp = await axios.post(
      pdfGeneration,
      JSON.stringify({
        scenarioId: scenarioId,
        state: currentState,
        userIdForScenario,
        pdfName: "AVM Report",
      }),
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        params: {
          user_action: teamAction
            ? scenarioGeneratePdfSelfAction[1]
            : scenarioGeneratePdfSelfAction[0],
        },
      }
    );

    return resp;
  } catch (error) {
    if (error instanceof Error) {
      throw Error(error.message);
    }
  }
};

export const reviewSubmitThunk = (
  state: TAppStateType,
  token: string,
  scenarioId: number,
  saveTeamPermission: boolean,
  editTeamPermission: boolean,
  scenarioLegacyID?: string
): ThunkAction<any, any, any, Action> => {
  const currentState = JSON.parse(JSON.stringify(state));
  const userIdForScenario = currentState.login.userIdForScenario;
  const currentUserId = currentState.login.currentUser.id;
  const currentTeamID = currentState.login.currentTeamID;
  const teamAction = saveTeamPermission && userIdForScenario !== currentUserId;

  // delete currentState.login;
  currentState.broker = { firstName: "", lastName: "", mobile: "" };
  currentState.broker.firstName = currentState.login.currentUser.firstName;
  currentState.broker.lastName = currentState.login.currentUser.lastName;
  currentState.broker.phoneNumber = currentState.login.currentUser.mobile;
  currentState.scenarioId = currentState.scenario.currentScenarioId;
  currentState.branding.bucket = process.env.REACT_APP_AWS_S3_BUCKET;
  currentState.provisoReferralCode = state.login.currentUser.provisoReferralCode;
  scenarioId = scenarioId ? scenarioId : 0;
  const currentUserRole = state.login.rolesForThisInstance.find(
    (role) => role.value === currentState.login.currentUser.roleId
  );
  const reviewNotes = state.admin.reviewNotesData;

  delete currentState.scenario;
  delete currentState.trigger;
  delete currentState.login;
  delete currentState.branding.css;
  currentState.help.showReviewScreen = false;
  currentState.upload.settlementDate = "";
  currentState.upload.file = "";
  return async (dispatch) => {
    dispatch(reviewShowDownloadStatusAction("download"));
    dispatch(reviewShowApproveLoaderAction({ scenarioApproveLoader: true }));
    if (
      state.applicant.applicants[0].retrieveBankData[0].illionBankDataFileName &&
      state.applicant.applicants[0].retrieveBankData[0].illionBankDataFileName.split("_")[1] !==
        state.login.currentUser.provisoReferralCode
    ) {
      currentState.applicant = deepCopyObject(state.applicant);
      currentState.applicant.applicants.forEach((applicant: IApplicant, index: number) => {
        dispatch(
          getProvisoDataThunk({
            retrieveBankData: applicant.retrieveBankData,
            provisoReferralCode: state.login.currentUser.provisoReferralCode,
            token,
            index,
            calledFrom: "reviewpage",
          })
        );
      });
    }

    const propertiesArray: IProperty[] = [];
    currentState.objective.properties.forEach((property: IPropertyArray) => {
      if (!property.suburbPropertyAddress) {
        const propertyAmenities: IPropertyAmenities = setPropertyAmenities(
          property.bedroom,
          property.bathrooms,
          property.carparks,
          property.initialIDSBedrooms,
          property.initialIDSCarparks,
          property.initialIDSBathrooms
        );
        const address =
          property.splitAddress.street_number + " " + property.splitAddress.street_name;
        propertiesArray.push({
          placeId: property.placeId,
          postCode: Number(property.splitAddress.postcode),
          images: property.gallery,
          bedrooms: propertyAmenities.bedrooms,
          bathrooms: propertyAmenities.bathrooms,
          carparks: propertyAmenities.carparks,
          address,
        });
      }
    });

    try {
      if (scenarioId === 0) {
        const resp = await axios.post(
          dashboardScenarioSave,
          JSON.stringify({
            scenarioId,
            state: currentState,
            review_notes: reviewNotes,
            userIdForScenario,
            operation: "Save",
            scenarioLegacyID,
          }),
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            params: {
              timedata: Date.now(),
              user_action: teamAction
                ? scenarioDashboardSaveAction[1]
                : scenarioDashboardSaveAction[0],
            },
          }
        );

        scenarioId = resp.data.payload.scenario_id;
        dispatch(
          scenarioLoadAction({
            scenarioId,
            scenarioStatus: resp.data.payload.scenario_status,
            newSavedScenario: resp.data.payload.newSavedScenario,
            ownerID: resp.data.payload.owner_id,
          })
        );
      }

      // generate loan writer notes only when notes are added by loan writer
      if (currentState.admin.brokerNotesData !== "<p><br></p>") {
        await axios.post(
          pdfGeneration,
          JSON.stringify({
            scenarioId,
            state: currentState,
            userIdForScenario,
            pdfName: "Loan Writer Notes",
          }),
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            params: {
              user_action: teamAction
                ? scenarioGeneratePdfSelfAction[1]
                : scenarioGeneratePdfSelfAction[0],
            },
          }
        );
      }

      await Promise.all(
        propertiesArray.map((property) =>
          getIdsData(
            property.bathrooms,
            property.bedrooms,
            property.carparks,
            property.placeId,
            property.postCode,
            scenarioId,
            token,
            userIdForScenario,
            property.images,
            currentState.branding.brandName,
            property.address,
            teamAction
          )
        )
      );

      const res = await axios.post(
        dashboardScenarioSave,
        JSON.stringify({
          scenarioId,
          state: currentState,
          isApprove: true,
          userIdForScenario,
          review_notes: reviewNotes,
          scenarioLegacyID,
        }),
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          params: {
            user_action: teamAction
              ? scenarioDashboardSaveAction[1]
              : scenarioDashboardSaveAction[0],
          },
        }
      );

      await axios.post(
        pdfGeneration,
        JSON.stringify({
          scenarioId: res.data.payload.scenario_id,
          state: currentState,
          userIdForScenario,
        }),
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          params: {
            user_action: teamAction
              ? scenarioGeneratePdfSelfAction[1]
              : scenarioGeneratePdfSelfAction[0],
          },
        }
      );

      dispatch(reviewShowApproveLoaderAction({ scenarioApproveLoader: false }));
      dispatch(profileThunk(token, editTeamPermission));
      dispatch(helpShowReviewScreenAction(false));
      if (teamAction) {
        dispatch(loginGetSelectedUserScenariosAction(userIdForScenario));
        window.history.pushState(
          "",
          "",
          `/teams/team-members/${currentTeamID}/${userIdForScenario}`
        );
      } else {
        history.push("/dashboard/my-scenarios");
      }
      history.push(`/submit/${res.data.payload.owner_id}/${res.data.payload.scenario_id}`);
    } catch (err) {
      const errorMessage =
        err.response && err.response.data.message
          ? err.response.data.message
          : "Something went wrong. Please try again.";
      if (err.response && err.response.status === 403) {
        /**
         * if user failed to save scenario. Give them option to save changes as their own scenario.
         * function scenarioResetCurrentScenarioIdAction: To reset the currentScenarioID to null.
         * function scenarioSaveAsOwnScenarioModalShowAction: To open save/discard scenario modal.
         */

        return openSaveAsOwnScenario(
          dispatch,
          "This scenario is no longer accessible by you.",
          currentUserRole ? currentUserRole.label : ""
        );
      } else {
        dispatch(scenarioSaveDiscardModalShowAction(false));
        dispatch(scenarioSaveAsOwnScenarioModalShowAction(false, ""));
      }
      dispatch(errorGenericErrorHandleAction(errorMessage));
      dispatch(reviewShowApproveLoaderAction({ scenarioApproveLoader: false }));
    }
  };
};
