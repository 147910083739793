import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import { Button, DataTable, TableHeader, TableRow, TableColumn, TableBody } from "react-md";
import { IReviewCondition } from "amm-review-interface";
import { approvalLettersMapping, APPROVE_LETTERS } from "../../constants";

interface OwnProps {
  conditions: IReviewCondition[];
  setConditions: React.Dispatch<React.SetStateAction<IReviewCondition[]>>;
  calledFrom: string;
  setCalledFrom: React.Dispatch<React.SetStateAction<string>>;
  isVisible: boolean;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
  handleCompleteReviewClick: () => void;
  handleApprovedPendingClick: () => void;
}

type ReviewModalProps = OwnProps;

const ReviewModal: React.FC<ReviewModalProps> = (props: ReviewModalProps) => {
  const addHandler = () => {
    const newData = [...props.conditions, { condition: "" }];
    props.setConditions(newData);
  };

  const updateHandler = (value: string, index: number) => {
    props.conditions[index].condition = value.trimLeft();
    props.setConditions([...props.conditions]);
  };

  const removeHandler = (index: number) => {
    const newData = [...props.conditions];
    newData.splice(index, 1);
    props.setConditions(newData);
  };

  const isSaveDisabled = () => {
    return props.conditions.filter((element) => element.condition === "").length > 0;
  };

  const renderTableRows = (array: IReviewCondition[]) => {
    if (array.length <= 0) {
      return <React.Fragment />;
    }
    return array.map((row: IReviewCondition, key: number) => (
      <TableRow>
        <TableColumn>
          <span className="margin-bottom-20">
            <TextField
              className="margin-top-5"
              id={`outlined-multiline-static-${key}`}
              fullWidth
              label={
                props.calledFrom === APPROVE_LETTERS.completeReview
                  ? `Settlement Condition ${key + 1}`
                  : `Approval Condition ${key + 1}`
              }
              placeholder="Add Condition"
              value={row.condition}
              required
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Button
                      id={`obligation-close-button`}
                      className="review-modal-close-button"
                      icon={true}
                      onClick={() => removeHandler(key)}
                    >
                      close
                    </Button>
                  </InputAdornment>
                ),
              }}
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
                updateHandler(e.target.value, key)
              }
            />
          </span>
        </TableColumn>
      </TableRow>
    ));
  };

  return (
    <React.Fragment>
      <div id="review-modal">
        <Dialog
          aria-labelledby="customized-dialog-title"
          open={props.isVisible}
          className="review-modal"
          disableBackdropClick
        >
          <DialogTitle className="user-modal-title">{props.calledFrom}</DialogTitle>
          <DialogContent dividers>
            <DataTable plain={true} className="conditions__table group-row">
              <TableHeader>
                <TableRow>
                  <TableColumn>
                    <span>{approvalLettersMapping[props.calledFrom]}</span>
                    {props.conditions.length < 6 && (
                      <Button
                        id="review-modal-add-new-button"
                        icon={true}
                        onClick={addHandler}
                        className="plusIcon"
                        disabled={props.conditions.length > 5}
                      >
                        <span>Add new</span>
                      </Button>
                    )}
                  </TableColumn>
                </TableRow>
              </TableHeader>
              <TableBody>{renderTableRows(props.conditions)}</TableBody>
            </DataTable>
          </DialogContent>
          <DialogActions>
            <Button
              className="review-modal-cancel-btn"
              flat={true}
              secondary={false}
              swapTheming={true}
              onClick={() => {
                props.setIsVisible(false);
                props.setCalledFrom("");
              }}
            >
              Cancel
            </Button>
            <Button
              className="review-modal-ok-btn"
              flat={true}
              secondary={true}
              swapTheming={true}
              disabled={isSaveDisabled()}
              onClick={() =>
                props.calledFrom === APPROVE_LETTERS.completeReview
                  ? props.handleCompleteReviewClick()
                  : props.handleApprovedPendingClick()
              }
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </React.Fragment>
  );
};

export default ReviewModal;
