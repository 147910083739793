import { IAccount, ISolutionInitialState } from "amm-solution-interface";
import {
  accountTypeOptions,
  accountNameRegex,
  lendingSpecPropertyPercentage,
  cashOutMaxValue,
  MINIMUM_LOAN_AMOUNT_PER_ACCOUNT,
} from "../../../constants";
import { loanAccounts } from "../../../utility/index";
import { accountState } from "./solutionInitialState";
import {
  SolutionAccountStateUpdateAction,
  SolutionAccountRemoveAction,
  SolutionGetLoanAmountAction,
  SolutionSolutionStateUpdateAction,
} from "../../actions/solutionPageActions/solutionPageActionTypes";

const loanMax = Number(process.env.REACT_APP_LOAN_AMOUNT_MAX);
const loanMin = Number(process.env.REACT_APP_LOAN_AMOUNT_MIN);

// get total accountTotal function
const accountTotalFunction = (account: IAccount[]) => {
  const accountTotalAmount = loanAccounts(account).reduce((sum, num) => sum + num.amount!, 0);
  return accountTotalAmount as number;
};

// set account active tab
export const currentTabFunction: (
  state: ISolutionInitialState,
  currentTab: number
) => ISolutionInitialState = (state, currentTab) => {
  return { ...state, currentTab };
};

// account tabs content state value set
export const accountStateUpdate = (
  action: SolutionAccountStateUpdateAction,
  account: IAccount[],
  state: ISolutionInitialState,
  activeTab: number
) => {
  let currentIndex = activeTab;

  switch (action.stateName) {
    case "amount":
      const amount = Number(action.stateValue);

      if (currentIndex === 0) {
        account[currentIndex]["amount"] = amount;
      } else {
        const totalAccountsSum = state.accountTotalAmount + amount - account[currentIndex].amount;

        const delta = amount - account[currentIndex].amount;

        if (delta > 0) {
          if (totalAccountsSum <= state.loanAmount) {
            account[activeTab].amount = amount;
          } else {
            const diff = account[0].amount - delta;

            if (diff <= MINIMUM_LOAN_AMOUNT_PER_ACCOUNT) {
              account[0].amount = MINIMUM_LOAN_AMOUNT_PER_ACCOUNT;
              account[activeTab].amount = amount;
            }
            if (diff > MINIMUM_LOAN_AMOUNT_PER_ACCOUNT) {
              account[activeTab].amount = amount;
              account[0].amount = diff;
            }
          }
        } else if (delta < 0) {
          if (totalAccountsSum <= state.loanAmount) {
            const totalDelta = state.loanAmount - totalAccountsSum;

            const diff = -delta > totalDelta ? totalDelta : -delta;

            account[0].amount += diff;
            account[activeTab].amount = amount;
          } else {
            account[activeTab].amount = amount;
          }
        }
      }
      break;

    case "accountType":
      delete account[currentIndex].linkedAccount;
      account[currentIndex].accountType = `${action.stateValue}`;
      if (!account[currentIndex].amount && !account[currentIndex].interestOnlyTerm) {
        account[currentIndex] = {
          ...account[currentIndex],
          amount: 0,
          interestOnlyTerm: 5,
        };
      }

      break;
    case "accountName":
      account[currentIndex].accountName = action.stateValue as string;
      account[currentIndex].isInValidAccountName = !new RegExp(accountNameRegex).test(
        action.stateValue as string
      );

      break;
    case "accountPurpose":
      account[currentIndex].accountPurpose = action.stateValue as string;
      break;
    case "fixedTerm":
      account[currentIndex].fixedTerm = action.stateValue as number;
      break;
    case "interestType":
      account[currentIndex].interestType = action.stateValue as string;
      break;
    default:
      if (action.index) {
        currentIndex = action.index;
        account[currentIndex][action.stateName] = action.stateValue;
      } else {
        account[currentIndex][action.stateName] = action.stateValue;
      }
  }
  return {
    ...state,
    account,
  };
};

/*
- this function set state of "loan amount", "deposit", "loanTerm" and "InterestSaverORoffsetFacility"
-  when the change the "loan-amount" the account automatically adujust the value
*/
export const solutionStateUpdate = (
  action: SolutionSolutionStateUpdateAction,
  state: ISolutionInitialState
) => {
  let depositAmount: number = state.depositAmount;
  let accounts: IAccount[] = [...JSON.parse(JSON.stringify(state)).account];
  accounts = accounts.slice();

  const numberOfAccounts: number = accounts.length;
  let accountTotal = accountTotalFunction(accounts);

  let cashOutAmountMax: number = state.cashOutAmountMax;
  let cashOutAmount: number =
    action.stateName === "cashOutAmount" ? (action.stateValue as number) : state.cashOutAmount;
  /////////////////////////////////////////////////
  if (action.stateName === "loanAmount") {
    const stateValue = isNaN(action.stateValue as number) ? 0 : (action.stateValue as number);
    depositAmount = loanMax - stateValue;
    if (numberOfAccounts === 1) {
      accounts[0].amount = stateValue;
    } else {
      let loanAccountArray = accounts;
      let loanRestValue = stateValue - accountTotal;

      if (loanRestValue >= 0) {
        loanAccountArray[loanAccountArray.length - 1].amount += loanRestValue;
        // const loanAccountArrayExcludingLast = loanAccountArray.splice(-1, 1);
        // const loanAccountArrayExcludingLastAmountSum = accountTotalFunction(loanAccountArrayExcludingLast);
      } else {
        loanRestValue = -loanRestValue;
        const loanAccountArrayProcessor = (loanAccountArrayInner: IAccount[]) => {
          const reversedArray = JSON.parse(JSON.stringify(loanAccountArray)).reverse();

          if (stateValue === 0) {
            loanAccountArray[0].amount = MINIMUM_LOAN_AMOUNT_PER_ACCOUNT;
            loanAccountArray.forEach((account: IAccount, index: number) => {
              if (!index) {
                account.amount = 0;
              }
            });
          } else {
            reversedArray.forEach((account: IAccount) => {
              const diff = loanRestValue - account.amount;
              if (loanRestValue) {
                if (diff > 0) {
                  loanRestValue -= account.amount;
                  account.amount = 0;
                } else {
                  account.amount -= loanRestValue;
                  loanRestValue = 0;
                }
              }
            });

            loanAccountArray = reversedArray.reverse();
          }

          if (loanAccountArray[0].amount < MINIMUM_LOAN_AMOUNT_PER_ACCOUNT) {
            loanAccountArray[0].amount = MINIMUM_LOAN_AMOUNT_PER_ACCOUNT;
          }
          return loanAccountArray;
        };
        loanAccountArray = loanAccountArrayProcessor(loanAccountArray);
      }
      accounts.forEach((account, index) => {
        loanAccountArray.forEach((currentAccount) => {
          if (account.accountId === currentAccount.accountId) {
            account.amount = currentAccount.amount;
          }
        });
      });
    }

    // if (state.cashOutUse === "Debt consolidation") {
    //   cashOutAmountMax = action.stateValue as number;
    //   if (state.cashOutAmount >= cashOutAmountMax) {
    //     cashOutAmount = cashOutAmountMax;
    //   }
    // }

    cashOutAmountMax = calculateCashOutAmountMax(
      state.propertiesTotal,
      action.stateValue as number
    );
    if (state.cashOutAmount >= cashOutAmountMax) {
      cashOutAmount = cashOutAmountMax;
    }
  }

  if (action.stateName === "isOffsetRequired" && action.index !== undefined) {
    accounts[+action.index].isOffsetRequired = Boolean(action.stateValue);
  }

  if (action.stateName === "cashOutUse") {
    cashOutAmount = 0;
    cashOutAmountMax = calculateCashOutAmountMax(state.propertiesTotal, state.loanAmount);
  }

  /////////////////////////////////////////////
  if (action.stateName === "loanTermYear" && action.stateValue > 15) {
    accounts.forEach((account) => {
      if (
        account.interestOnlyTerm &&
        account.interestOnlyTerm >= (action.stateValue as number) - 15 &&
        (action.stateValue as number) != 16
        // AMM-4344 state management for fixed term and IO
      ) {
        account.interestOnlyTerm = (action.stateValue as number) - 15;
      }
    });
  } else if (action.stateName === "loanTermYear" && action.stateValue <= 15) {
    accounts.forEach((account) => {
      if (account.accountType === accountTypeOptions[1].value) {
        account.accountType = accountTypeOptions[0].value as string;
      }
    });
  }
  accountTotal = accountTotalFunction(accounts);

  return {
    ...state,
    [action.stateName]: action.stateValue,
    account: accounts,
    accountTotalAmount: accountTotal,
    cashOutAmount,
    cashOutAmountMax,
    depositAmount,
  };
};

// remove the "account" from the tab
export const RemoveAccount = (
  action: SolutionAccountRemoveAction,
  account: IAccount[],
  state: ISolutionInitialState,
  activeTab: number
) => {
  const removedAccountBalance = state.account[action.removeIndex].amount;

  const accounts = JSON.parse(JSON.stringify(account));
  if (account.length > 1) {
    accounts.splice(action.removeIndex, 1);

    if (activeTab >= action.removeIndex) {
      activeTab = activeTab - 1;
    }
  }
  let accountTotal = accountTotalFunction(accounts);

  if (accountTotal <= state.loanAmount) {
    const totalDelta = state.loanAmount - accountTotal;

    const diff = removedAccountBalance > totalDelta ? totalDelta : removedAccountBalance;

    accounts[0].amount += diff;
    accountTotal += diff;
  }
  return {
    ...state,
    account: accounts,
    accountTotalAmount: accountTotal,
    currentTab: activeTab,
  };
};

// add new account in the tab
export const addNewAccount = (
  state: ISolutionInitialState,
  account: IAccount[],
  accountPurpose: string
) => {
  const accountstate = JSON.parse(JSON.stringify(accountState));
  accountstate.accountId = Math.random().toString(36).substr(2, 9);
  accountstate.accountPurpose = accountPurpose;
  account.push({ ...accountstate });
  const accountTotal = accountTotalFunction(account);
  return { ...state, account, accountTotalAmount: accountTotal };
};

// when the properties value change automatically adjust solution("loan amount" and "account")
export const loanCalculator = (
  state: ISolutionInitialState,
  action: SolutionGetLoanAmountAction
) => {
  const propertiesTotal = action.loanAmount;
  const refinancePropertyTotal: number =
    action.refinancePropertyTotal >= loanMax ? loanMax : action.refinancePropertyTotal;
  const depositAmount: number = loanMax - action.loanAmount;
  let account: IAccount[] = [...Object.assign(state).account];
  account = account.slice();
  if (account.length === 1) {
    account[0].amount = state.loanAmount;
  }

  const cashOutAmountMax: number = calculateCashOutAmountMax(propertiesTotal, state.loanAmount);

  const cashOutAmount: number = calculateCashOutAmount(cashOutAmountMax, action.cashOutAmount);
  const accountTotal = accountTotalFunction(account);

  return {
    ...state,
    account,
    accountTotalAmount: accountTotal,
    propertiesTotal,
    depositAmount,
    refinancePropertyTotal,
    cashOutAmount,
    cashOutAmountMax,
  };
};

// if solution has one account and and change the account amount loanAmount and deposit amount set automatically
export const accountAmountChange = (state: ISolutionInitialState) => {
  let depositAmount = state.depositAmount;
  let account: IAccount[] = [...Object.assign(state).account];
  account = account.slice();
  const numberOfAccounts: number = loanAccounts(account).length;
  let loanAmount: number = state.loanAmount;

  if (numberOfAccounts === 1) {
    if (loanMin >= (account[0].amount as number)) {
      loanAmount = loanMin;
    } else {
      loanAmount = Math.floor(account[0].amount as number);
    }

    depositAmount = loanMax - loanAmount;
  }
  const accountTotal = accountTotalFunction(account);
  return {
    ...state,
    accountTotalAmount: accountTotal,
    depositAmount,
    loanAmount,
  };
};

/**
 * Calculate the max value of advance cashout
 * @param propertiesTotal total sum of properties entered on properties page
 * @param state initial state of solution
 */

export const calculateCashOutAmountMax = (propertiesTotal: number, loanAmount: number) => {
  const cashAdvancePropertyLimit = parseFloat(
    (lendingSpecPropertyPercentage * propertiesTotal).toFixed(2)
  );
  let cashOutAmountMax: number = loanAmount < cashOutMaxValue ? loanAmount : cashOutMaxValue;
  // commented for the lending spec changes according to AMM-2641
  //   if (action === "Debt consolidation") {
  //   cashOutAmountMax = loanAmount;
  // } else {
  //   cashOutAmountMax = parseFloat(
  //     (lendingSpecPropertyPercentage * propertiesTotal).toFixed(2)
  //   );
  // }
  if (cashAdvancePropertyLimit < cashOutAmountMax) {
    cashOutAmountMax = cashAdvancePropertyLimit;
  }

  return cashOutAmountMax;
};

export const calculateCashOutAmount = (cashOutAmountMax: number, cashOutAmount: number) => {
  return cashOutAmountMax
    ? cashOutAmount > cashOutAmountMax
      ? cashOutAmountMax
      : cashOutAmount > 0
      ? cashOutAmount
      : 0
    : cashOutAmount;
};
