import React from "react";

const BarChartLabelIcon = (props: { fill: string }) => {
  return (
    <svg
      className="recharts-surface"
      width="10"
      height="10"
      viewBox="0 0 32 32"
      version="1.1"
      style={{ display: "inline-block", verticalAlign: "middle", marginRight: "4px" }}
    >
      <path
        fill={props.fill}
        cx="16"
        cy="16"
        type="circle"
        className="recharts-symbols"
        transform="translate(16, 16)"
        d="M16,0A16,16,0,1,1,-16,0A16,16,0,1,1,16,0"
      ></path>
    </svg>
  );
};

export default BarChartLabelIcon;
