import React from "react";

const TickIcon: React.FC = () => {
  return (
    <svg
      className="icon footer-icon footer-review-icon footer-solution-icon"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 35 35"
    >
      <title>icon-tick</title>
      <path
        className="cls-1"
        d="M17.5,2A15.5,15.5,0,1,1,2,17.5,15.5176,15.5176,0,0,1,17.5,2m0-2A17.5,17.5,0,1,0,35,17.5,17.5,17.5,0,0,0,17.5,0Z"
      />
      <polygon
        className="cls-2"
        points="28.607 12.464 27.192 11.05 14.465 23.778 8.808 18.121 7.394 19.535 13.05 25.192 13.05 25.192 14.036 26.178 14.465 26.607 15.879 25.192 15.879 25.192 28.607 12.464"
      />
    </svg>
  );
};

export default TickIcon;
