import React from "react";
import algoliasearch from "algoliasearch/lite";
import { connectStateResults, Hits, InstantSearch, SearchBox } from "react-instantsearch-dom";
import { Button } from "react-md";
import { connect } from "react-redux";
import { algoliaApiKey, algoliaAppID, algoliaIndexName } from "../../constants";
import Review from "../../pages/review";
import history from "../../routes/history";
import {
  getAlgoliaRecordsThunk,
  GetAlgoliaRecordsThunkType,
} from "../../store/thunk/helpSectionThunk/getAlgoliaRecordsThunk";
import hitProduct from "./hitProduct";
import MetricsPanel from "./metricsPanel";
import { TAppStateType } from "../../store/reducers/reducers";
import { IHelpLinksInterface } from "../../store/reducers/helpReducers/helpReducer";

interface StateProps {
  content: string;
  heading: string;
  links: IHelpLinksInterface[];
  showReviewScreen: boolean;
  sidebarAnimationClassName: string;
  subcontent: string;
  subheading: string;
}

const SearchBarStateResults = ({ searchState, searchResults }: any) => {
  return (
    <div>
      <div className="help_content">
        {searchResults && searchResults.hits.length === 0 ? "no results" : ""}
      </div>
      <Hits hitComponent={hitProduct} />
    </div>
  );
};

const NoSearchResults = connectStateResults(SearchBarStateResults);

interface DispatchProps {
  getAlgoliaRecordsThunk: GetAlgoliaRecordsThunkType;
}

type SidebarProps = StateProps & DispatchProps;
interface ISideBarStateType {
  isMobileSidebarOpen: boolean;
  isSearchActive: boolean;
  path: string;
}
class Sidebar extends React.Component<SidebarProps, ISideBarStateType> {
  private myRef: React.RefObject<any>;
  constructor(props: SidebarProps) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      isMobileSidebarOpen: false,
      isSearchActive: false,
      path: history.location.pathname,
    };
  }

  public componentDidMount = () => {
    const elem: any = document.getElementsByClassName("ais-SearchBox-input")[0];
    elem.placeholder = "Search";
  };

  public componentDidUpdate = (prevProps: SidebarProps) => {
    if (prevProps.showReviewScreen !== this.props.showReviewScreen) {
      this.myRef.current.scrollTop = 0; // set the div scroll on top every time review screen appear
    }
  };
  public mobileSidebarOpen = () => {
    if (window.innerWidth <= 1100) {
      this.setState({
        isMobileSidebarOpen: true,
      });
    }
  };
  public mobileSidebarClose = () => {
    if (window.innerWidth <= 1100) {
      this.setState({
        isMobileSidebarOpen: false,
      });
    }
  };

  public searchStateChange = (event: any) => {
    const element: any = document.getElementsByClassName("hits_display")[0];
    if (event.query !== "") {
      element.style.display = "block";
    } else {
      element.style.display = "none";
    }
  };
  //@ts-ignore
  public ListItem = ({ value, onClick }) => <li onClick={onClick}>{value}</li>;

  public renderLinks = () =>
    this.props.links.length !== 0 ? (
      <ul>
        {this.props.links.map((link, i) =>
          link.url ? (
            <li key={i} className="help-link-items">
              <a href={link.url} target="_blank">
                {link.heading} <span className="material-icons">launch</span>
              </a>
            </li>
          ) : (
            <this.ListItem
              key={i}
              value={link.heading}
              onClick={() => this.props.getAlgoliaRecordsThunk(link.objectID, true)}
            />
          )
        )}
      </ul>
    ) : (
      <></>
    );

  public renderSubheadings = () => (
    <>
      <div className="help_heading border0">{this.props.subheading}</div>
      <p className="help_content">{this.props.subcontent}</p>
    </>
  );
  public searchClient = algoliasearch(algoliaAppID, algoliaApiKey);

  public render() {
    return (
      <aside
        className={`side_widget ${this.props.sidebarAnimationClassName} ${
          this.props.showReviewScreen ? "review-active" : ""
        }`}
      >
        <div className={`score_widget ${this.state.isMobileSidebarOpen ? "widget-expend" : ""}`}>
          <Button
            className="widget--close-btn"
            icon={true}
            onClick={() => this.mobileSidebarClose()}
            secondary={true}
          >
            close
          </Button>
          <MetricsPanel />
          <div className="help_container">
            <InstantSearch
              onSearchStateChange={this.searchStateChange}
              indexName={algoliaIndexName}
              searchClient={this.searchClient}
            >
              <div className="sidebar-searchbox" onClick={() => this.mobileSidebarOpen()}>
                <SearchBox />
              </div>
              <div className="hits_display">
                <NoSearchResults />
              </div>
            </InstantSearch>
            <div className="help_heading">{this.props.heading}</div>
            <p className="help_content">{this.props.content}</p>
            {this.props.links.length > 0 && (
              <p className="help-reference-heading">Reference Links:</p>
            )}
            {this.renderLinks()}
            {this.renderSubheadings()}
          </div>
        </div>
        <div ref={this.myRef} className="score_page">
          <Review />
        </div>
      </aside>
    );
  }
}
const mapStateToProps = (state: TAppStateType) => {
  return {
    content: state.help.reviewObject.helpSectionContent,
    heading: state.help.reviewObject.helpSectionHeading,
    links: state.help.reviewObject.helpSectionLinks as IHelpLinksInterface[],
    showReviewScreen: state.help.showReviewScreen,
    sidebarAnimationClassName: state.help.sidebarAnimationClassName,
    subcontent: state.help.reviewObject.helpSubcontent,
    subheading: state.help.reviewObject.helpSubheading,
  };
};

export const mapDispatchToProps = {
  getAlgoliaRecordsThunk,
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
