import * as solution from "./solutionPageActionTypes";

import { moduleConstant } from "../../../constants";

export const solutionAccountCurrentTabAction: solution.SolutionAccountCurrentTabActionType = (
  currentTab
) => {
  return {
    currentTab,
    module: [moduleConstant.solution],
    type: solution.SOLUTION_ACCOUNT_CURRENT_TAB,
  };
};

export const solutionAccountAddNewAction: solution.SolutionAccountAddNewActionType = (
  accountPurpose: string
) => {
  return {
    accountPurpose,
    module: [moduleConstant.solution],
    type: solution.SOLUTION_ACCOUNT_ADD_NEW,
  };
};

export const solutionAccountRemoveAction: solution.SolutionAccountRemoveActionType = (index) => {
  return {
    module: [moduleConstant.solution],
    removeIndex: index,
    type: solution.SOLUTION_REMOVE_ACCOUNT,
  };
};

export const solutionAccountStateUpdateAction: solution.SolutionAccountStateUpdateActionType = (
  stateName: string,
  stateValue: number | string | boolean,
  functionName?: string,
  index?: number
) => {
  if (functionName === "account") {
    return {
      index,
      module: [moduleConstant.solution],
      stateName,
      stateValue,
      type: solution.SOLUTION_ACCOUNT_STATE_UPDATE,
    };
  }
  return {
    index,
    module: [moduleConstant.solution],
    stateName,
    stateValue,
    type: solution.SOLUTION_SOLUTION_STATE_UPDATE,
  };
};

export const solutionGetLoanAmountAction: solution.SolutionGetLoanAmountActionType = (
  loanAmount,
  refinancePropertyTotal,
  cashOutAmount
) => {
  return {
    loanAmount,
    cashOutAmount,
    module: [moduleConstant.solution],
    refinancePropertyTotal,
    type: solution.SOLUTION_GET_LOAN_AMOUNT,
  };
};

export const solutionCashOutNoteUpdateAction: solution.SolutionCashOutNoteUpdateActionType = (
  note: string
) => {
  return {
    note,
    module: [moduleConstant.solution],
    type: solution.SOLUTION_CASHOUT_NOTE_UPDATE,
  };
};

export const solutionAccountAmountChangeAction: solution.SolutionAccountAmountChangeActionType = () => {
  return {
    module: [moduleConstant.solution],
    type: solution.SOLUTION_ACCOUNT_AMOUNT_CHANGE,
  };
};

export const solutionLoanTermAction: solution.solutionLoanTermActionType = () => {
  return {
    module: [moduleConstant.solution],
    type: solution.SOLUTION_LOAN_TERM,
  };
};

export const solutionLoadEntireStateAction: solution.SolutionLoadEntireStateActionType = (
  state
) => {
  return {
    module: [moduleConstant.solution],
    state,
    type: solution.SOLUTION_LOAD_ENTIRE_STATE,
  };
};

export const solutionAccountPurposeUpdateAllAction: solution.SolutionAccountPurposeUpdateAllActionType = (
  accountPurpose: string
) => {
  return {
    module: [moduleConstant.solution],
    accountPurpose,
    type: solution.SOLUTION_ACCOUNT_PURPOSE_CHANGE_ALL,
  };
};
