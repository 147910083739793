import { Reducer } from "redux";
import { IFundsToCompleteDuty } from "amm-objective-interface";
import { IReviewCondition, IReviewValidationState } from "amm-review-interface";
import { moduleConstant } from "../../../constants";
import { deepCopyObject } from "../../../utility";
import { LoginUserLogoutAction, LOGIN_USER_LOGOUT } from "../../actions/login/loginActionType";
import {
  REVIEW_SHOW_DOWNLOAD_STATUS,
  REVIEW_FULL_FORM_VALIDATION,
  REVIEW_FULL_FORM_VALIDATION_FOR_SEND_QUOTE,
  REVIEW_SET_VALIDATION,
  REVIEW_SAVE_FUNDS_TO_COMPLETE,
  REVIEW_SHOW_APPROVE_LOADER,
  REVIEW_CLEAR_VALIDATIONS_ARRAY,
  REVIEW_LOAD_ENTIRE_STATE,
  REVIEW_SET_APPLICANT_FORM_SUBMITTED_FLAG,
  REVIEW_PAGE_VISITED,
  ReviewActions,
  REVIEW_SET_LEGACY_ID,
  // REVIEW_SHOW_APPROVE_MODAL
} from "../../actions/reviewActions/reviewActionTypes";
export interface IInitialState {
  buttonText: string;
  formInvalidArray: boolean[];
  formInvalidationArrayForSendQuote: boolean[];
  fundsToCompleteIndividual: IFundsToCompleteDuty[];
  fundsToCompleteTotal: IFundsToCompleteDuty;
  validations: IReviewValidationState;
  showApproveModal: boolean;
  isVisited?: boolean;
  isApplicantFormNotSubmitted?: boolean;
  toggleLoader?: boolean;
  scenarioApproveLoader?: boolean;
  isSubmittedToCapital?: boolean;
  uploadLoader?: boolean;
  scenarioLegacyID?: string;
  completeReviewConditions?: IReviewCondition[];
  approvedPendingConditions?: IReviewCondition[];
}

export const reviewInitialState: IInitialState = {
  isVisited: false,
  buttonText: "Pre-approve",
  showApproveModal: false,
  formInvalidArray: [true],
  formInvalidationArrayForSendQuote: [true],
  fundsToCompleteIndividual: [],
  fundsToCompleteTotal: {
    deposit: 0,
    dischargeFee: 0,
    mortgageRegistrationFee: 0,
    settlementFee: 0,
    stampDutyOnProperty: 0,
    totalFee: 0,
    transferFee: 0,
    valuationFee: 0,
  },
  validations: {
    affordability: false,
    applicant: false,
    objective: false,
    solution: false,
    fundsToCompleteSum: 0,
  },
  toggleLoader: false,
  scenarioApproveLoader: false,
  isSubmittedToCapital: false,
  uploadLoader: false,
  scenarioLegacyID: "",
};

export interface IReviewAction {
  module: string[];
  type: string;
  key: string;
  status: string;
  formInvalidArray: boolean[];
  formInvalidationArrayForSendQuote: boolean[];
  validations: IReviewValidationState;
  toggleLoader: boolean;
  scenarioApproveLoader: boolean;
  fundsToCompleteIndividual: IFundsToCompleteDuty[];
  fundsToCompleteTotal: IFundsToCompleteDuty;
  state: IInitialState;
  isApplicantFormNotSubmitted: boolean;
  uploadLoader?: boolean;
  isVisited: boolean;
}

const reviewReducer: Reducer<IInitialState, ReviewActions> = (
  state: IInitialState = deepCopyObject(reviewInitialState),
  action: ReviewActions | LoginUserLogoutAction
) => {
  if (
    action &&
    action.module &&
    action.module.filter((currentModule) => Object.values(moduleConstant).includes(currentModule))
      .length === 0
  ) {
    return { ...state };
  }

  let currentState = Object.assign({}, state);
  switch (action.type) {
    case REVIEW_PAGE_VISITED:
      return { ...currentState, isVisited: action.isVisited };

    case REVIEW_SHOW_DOWNLOAD_STATUS:
      return { ...currentState, buttonText: action.status };

    // case REVIEW_SHOW_APPROVE_MODAL:
    //   return { ...currentState, showApproveModal: action.toggleLoader };

    case LOGIN_USER_LOGOUT:
      return { ...reviewInitialState };

    case REVIEW_FULL_FORM_VALIDATION:
      const formInvalidArray = action.formInvalidArray;
      return { ...state, formInvalidArray };

    case REVIEW_FULL_FORM_VALIDATION_FOR_SEND_QUOTE:
      const formInvalidationArrayForSendQuote = action.formInvalidationArrayForSendQuote;
      return { ...state, formInvalidationArrayForSendQuote };

    case REVIEW_SET_VALIDATION:
      currentState = Object.assign(state);
      const validations = Object.assign(currentState.validations);
      validations[action.key] = action.validations[action.key];
      return { ...currentState, validations };

    case REVIEW_SET_APPLICANT_FORM_SUBMITTED_FLAG:
      return {
        ...state,
        isApplicantFormNotSubmitted: action.isApplicantFormNotSubmitted,
      };

    case REVIEW_SHOW_APPROVE_LOADER:
      const scenarioApproveLoader =
        action.scenarioApproveLoader !== undefined
          ? action.scenarioApproveLoader
          : currentState.scenarioApproveLoader;
      const toggleLoader =
        action.toggleLoader !== undefined ? action.toggleLoader : currentState.toggleLoader;

      const uploadLoader =
        action.uploadLoader !== undefined ? action.uploadLoader : currentState.uploadLoader;

      const isSubmittedToCapital = action.isSubmittedToCapital;

      return { ...state, toggleLoader, scenarioApproveLoader, uploadLoader, isSubmittedToCapital };

    case REVIEW_LOAD_ENTIRE_STATE:
      return action.state;

    case REVIEW_CLEAR_VALIDATIONS_ARRAY:
      return {
        ...state,
        validations: {
          affordability: false,
          applicant: false,
          objective: false,
          solution: false,
        },
      };

    case REVIEW_SAVE_FUNDS_TO_COMPLETE:
      return {
        ...state,
        fundsToCompleteIndividual: action.fundsToCompleteIndividual,
        fundsToCompleteTotal: action.fundsToCompleteTotal,
      };

    case REVIEW_SET_LEGACY_ID:
      currentState.scenarioLegacyID = action.legacyID;

      return { ...currentState };

    default:
      return { ...state };
  }
};

export default reviewReducer;
