import React from "react";
import { useLocation } from "react-router-dom";
// import IncomeExpense from "../review/incomeExpense";
import LoanMetrics from "../review/loanMetrics";
import Repayments from "../review/repayments";

const MetricsPanel: React.FC = () => {
  const { pathname } = useLocation();
  return (
    <div
      className={
        pathname.includes("/dashboard") ? "metrics-panel metrics-panel-hide-lras" : "metrics-panel"
      }
    >
      <LoanMetrics />
      <Repayments />
    </div>
  );
};

export default MetricsPanel;
