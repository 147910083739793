import axios from "axios";
import { CREDIT_REVIEW_SAVE_ACTIONS, dashboardScenarioSave } from "../../../constants";
import { errorGenericErrorHandleAction } from "../../actions/errorActions/errorAction";
import {
  scenarioSaveButtonLoaderAction,
  scenarioModalHideAction,
  scenarioSaveDiscardModalShowAction,
  scenarioLoadAction,
  scenarioSaveAsOwnScenarioModalShowAction,
  setReviewerIdReWorkAction,
} from "../../actions/scenarioActions/scenarioActions";
import { successSnackBarHandleAction } from "../../actions/snackbarSuccessAction/successAction";
import { triggerProfileLoadAction } from "../../actions/triggerActions/triggerAction";
import { ThunkAction } from "redux-thunk";
import { Action } from "redux";
import { TAppStateType } from "../../reducers/reducers";
import { clearCurrentScenario } from "../../../utility/clearCurrentScenario";
import { openSaveAsOwnScenario } from "../utility/openSaveAsOwnScenario";
import history from "../../../routes/history";
import { scenarioDashboardSaveAction } from "../../../constants/userActions";

const successMessage = {
  [CREDIT_REVIEW_SAVE_ACTIONS.reWork]: "Successfully assigned for Re-work",
  [CREDIT_REVIEW_SAVE_ACTIONS.reviewComplete]: "Review Completed",
  [CREDIT_REVIEW_SAVE_ACTIONS.approvedPending]: "Status changed to Approved Pending",
  Save: "Saved Successfully.",
};

interface ISaveScenarioThunk {
  state: TAppStateType;
  token: string;
  scenarioId: number;
  calledFrom: string;
  userAction: string;
  saveAsOwnScenarioModalShow: boolean;
  operation: string;
  toPath?: string;
  reviewerIdForScenario?: number;
  scenarioLegacyID?: string;
  currentUser?: number;
}

export type SaveScenarioThunkType = (
  params: ISaveScenarioThunk
) => ThunkAction<any, any, any, Action>;

export const saveScenarioThunk: SaveScenarioThunkType = ({
  state,
  token,
  scenarioId,
  calledFrom,
  userAction,
  saveAsOwnScenarioModalShow,
  operation,
  toPath,
  reviewerIdForScenario,
  scenarioLegacyID,
  currentUser,
}): ThunkAction<any, any, any, Action> => {
  const apiUrl = dashboardScenarioSave; // set the value;
  const currentState = JSON.parse(JSON.stringify(state));
  const userIdForScenario = saveAsOwnScenarioModalShow
    ? currentState.login.id
    : currentState.login.userIdForScenario;
  scenarioId = scenarioId ? scenarioId : 0;
  currentState.broker = { firstName: "", lastName: "", mobile: "" };
  currentState.broker.firstName = currentState.login.currentUser.firstName;
  currentState.broker.lastName = currentState.login.currentUser.lastName;
  currentState.broker.phoneNumber = currentState.login.currentUser.mobile;
  currentState.scenarioId = currentState.scenario.currentScenarioId;
  const currentUserRole = state.login.rolesForThisInstance.find(
    (role) => role.value === currentState.login.currentUser.roleId
  );
  const reviewNotes = state.admin.reviewNotesData;
  const currentUserToBeUsed = currentUser ? currentUser : 0;
  if (reviewNotes.final) {
    reviewNotes.final.reviewerID = currentUserToBeUsed;
  } else {
    reviewNotes.final = {
      reviewerID: currentUserToBeUsed,
      isReviewCompleted: false,
      reviewNoteText: "",
    };
  }

  delete currentState.admin.reviewNotesData;
  delete currentState.login;
  delete currentState.scenario;
  delete currentState.trigger;
  delete currentState.branding.css;
  currentState.help.menuOpen = false;
  currentState.upload.settlementDate = "";
  currentState.upload.file = "";

  return (dispatch) => {
    dispatch(scenarioSaveButtonLoaderAction(true));

    axios
      .post(
        apiUrl,
        JSON.stringify({
          scenarioId,
          state: currentState,
          review_notes: reviewNotes,
          userIdForScenario,
          isReviewerSaved: userAction === scenarioDashboardSaveAction[3],
          operation,
          reviewerIdForScenario,
          scenarioLegacyID,
        }),
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          params: {
            timedata: Date.now(),
            user_action: userAction,
          },
        }
      )
      .then((response) => {
        dispatch(
          scenarioLoadAction({
            scenarioId: response.data.payload.scenario_id,
            scenarioStatus: response.data.payload.scenario_status,
            newSavedScenario: response.data.payload.newSavedScenario,
            ownerID: response.data.payload.owner_id,
          })
        );
        dispatch(triggerProfileLoadAction());
        if (calledFrom !== "nextAndBackButton") {
          dispatch(clearCurrentScenario());
          if (toPath) {
            history.push(toPath);
          }
        }
        if (
          operation === CREDIT_REVIEW_SAVE_ACTIONS.reWork ||
          operation === CREDIT_REVIEW_SAVE_ACTIONS.approvedPending
        ) {
          history.push("/");
        }
        if (operation === CREDIT_REVIEW_SAVE_ACTIONS.reviewComplete) {
          history.push("/");
          history.push(
            `/submit/${response.data.payload.owner_id}/${response.data.payload.scenario_id}`
          );
        }

        if (operation === CREDIT_REVIEW_SAVE_ACTIONS.reWork) {
          dispatch(setReviewerIdReWorkAction(0));
        }

        dispatch(successSnackBarHandleAction(successMessage[operation]));
        dispatch(scenarioSaveButtonLoaderAction(false));

        dispatch(scenarioModalHideAction());
        dispatch(scenarioSaveDiscardModalShowAction(false));
        dispatch(scenarioSaveAsOwnScenarioModalShowAction(false, ""));
      })
      .catch((error) => {
        dispatch(scenarioModalHideAction());
        dispatch(scenarioSaveButtonLoaderAction(false));

        if (error.response.status === 403) {
          /**
           * if user failed to save scenario. Give them option to save changes as their own scenario.
           */

          return openSaveAsOwnScenario(
            dispatch,
            "This scenario is no longer accessible by you.",
            currentUserRole ? currentUserRole.label : ""
          );
        } else {
          dispatch(scenarioSaveDiscardModalShowAction(false));
          dispatch(scenarioSaveAsOwnScenarioModalShowAction(false, ""));
        }
        return dispatch(errorGenericErrorHandleAction(error.message));
      });
  };
};
