import { IPropertyAutoSuggest, ISuburbAutoSuggest } from "amm-objective-interface";
import {
  IApplicantRequiredField,
  StateUpdateAction,
  ISplitAddress,
  IApplicantInitialState,
  IEmploymentObject,
} from "amm-applicant-interface";
import { IAffordabilityTotalsActionType } from "../../../utility";

export const APPLICANT_CURRENT_TAB = "APPLICANT_CURRENT_TAB";
export const APPLICANT_SET_PROVISO_DATA = "APPLICANT_SET_PROVISO_DATA";
export const APPLICANT_ADD_NEW = "APPLICANT_ADD_NEW";
export const APPLICANT_REMOVE = "APPLICANT_REMOVE";
export const APPLICANT_ADD_NEW_ITEM = "APPLICANT_ADD_NEW_ITEM";
export const APPLICANT_SET_PROBATION_WARNING = "APPLICANT_SET_PROBATION_WARNING";
export const APPLICANT_REMOVE_ITEM = "APPLICANT_REMOVE_ITEM";
export const APPLICANT_STATE_UPDATE = "APPLICANT_STATE_UPDATE";
export const APPLICANT_CLEAR_DOCUMENT_ID = "APPLICANT_CLEAR_DOCUMENT_ID";
export const APPLICANT_ADDRESS_AUTO_SUGGEST = "APPLICANT_ADDRESS_AUTO_SUGGEST";
export const APPLICANT_GET_PROPERTY = "APPLICANT_GET_PROPERTY";
export const APPLICANT_CLEAR_ADDRESS_RESULT = "APPLICANT_CLEAR_ADDRESS_RESULT";
export const APPLICANT_ADDRESS_LOADER = "APPLICANT_ADDRESS_LOADER";
export const APPLICANT_SHOW_LOADER = "APPLICANT_SHOW_LOADER";
export const APPLICANT_HIDE_LOADER = "APPLICANT_HIDE_LOADER";
export const APPLICANT_MODAL_OPEN = "APPLICANT_MODAL_OPEN";
export const APPLICANT_MODAL_CLOSE = "APPLICANT_MODAL_CLOSE";
export const APPLICANT_LOAD_STATE = "APPLICANT_LOAD_STATE";
export const APPLICANT_VALIDATE_APPLICANT_FIELD = "APPLICANT_VALIDATE_APPLICANT_FIELD";
export const APPLICANT_GET_CREDIT_SCORE_AND_VERIFY_IDENTITY =
  "APPLICANT_GET_CREDIT_SCORE_AND_VERIFY_IDENTITY";
export const APPLICANT_GET_OTP = "APPLICANT_GET_OTP";
export const APPLICANT_SEND_OTP_LOADER = "APPLICANT_SEND_OTP_LOADER";
export const APPLICANT_IS_OTP_SENT = "APPLICANT_IS_OTP_SENT";
export const APPLICANT_CONFIRM_OTP_LOADER = "APPLICANT_CONFIRM_OTP_LOADER";
export const APPLICANT_CONFIRM_OTP_NUMBER = "APPLICANT_CONFIRM_OTP_NUMBER";
export const APPLICANT_FORM_LOADER = "APPLICANT_FORM_LOADER";
export const APPLICANT_NOTE_VALUE_UPDATE = "APPLICANT_NOTE_VALUE_UPDATE";
export const APPLICANT_COPY_TO_ALL = "APPLICANT_COPY_TO_ALL";
export const APPLICANT_CLEAR_APPLICANT_PAGE_DATA = "APPLICANT_CLEAR_APPLICANT_PAGE_DATA";
export const APPLICANT_ADD_RETRIEVE_DATA = "APPLICANT_ADD_RETRIEVE_DATA";
export const APPLICANT_REMOVE_RETRIEVE_DATA = "APPLICANT_REMOVE_RETRIEVE_DATA";
export const APPLICANT_UNDO_ROW = "APPLICANT_UNDO_ROW";
export const APPLICANT_CREDIT_CARD_TEXT_ERROR = "APPLICANT_CREDIT_CARD_TEXT_ERROR";
export const APPLICANT_VALIDATION_UPDATION = "APPLICANT_VALIDATION_UPDATION";
export const AFFORDABILITY_RENTAL_INCOME_UPDATE = "AFFORDABILITY_RENTAL_INCOME_UPDATE";
export const APPLICANT_COPY_ADDRESS = "APPLICANT_COPY_ADDRESS";
export const APPLICANT_AFFORDABILITY_CLEAR_TABLE = "APPLICANT_AFFORDABILITY_CLEAR_TABLE";
export const APPLICANT_SEND_SMS_LOADER = "APPLICANT_SEND_SMS_LOADER";
export const APPLICANT_REFERRAL_CODE_UPDATE = "APPLICANT_REFERRAL_CODE_UPDATE";
export const APPLICANT_CHANGE_RESEND_TIMER = "APPLICANT_CHANGE_RESEND_TIMER";
export const APPLICANT_UPDATE_VALUE_FOR_UNCATEGORISED = "APPLICANT_UPDATE_VALUE_FOR_UNCATEGORISED";
export const APPLICANT_AUTO_POPULATE_NOTE = "APPLICANT_AUTO_POPULATE_NOTE";
export const APPLICANT_UPDATE_LIVING_ARRAGEMENT = "APPLICANT_UPDATE_LIVING_ARRAGEMENT";
export const APPLICANT_SET_EMAIL_DUPLICATE = "APPLICANT_SET_EMAIL_DUPLICATE";
export const APPLICANT_SET_MOBILE_PHONE_DUPLICATE = "APPLICANT_SET_MOBILE_PHONE_DUPLICATE";
export const APPLICANT_ADD_NOTE = "APPLICANT_ADD_NOTE";
export const APPLICANT_SET_CURRENT_TAB_ZERO = "APPLICANT_SET_CURRENT_TAB_ZERO";
export const APPLICANT_OVERRIDE_CREDIT_SCORE = "APPLICANT_OVERRIDE_CREDIT_SCORE";
export const APPLICANT_OVERRIDE_LOADER = "APPLICANT_OVERRIDE_LOADER";
export const APPLICANT_STATUS_RENT_UPDATE = "APPLICANT_STATUS_RENT_UPDATE";
export const APPLICANT_REQUIRED_FIELDS_UPDATE = "APPLICANT_REQUIRED_FIELDS_UPDATE";
export const APPLICANT_REQUIRED_FIELDS_CHECK = "APPLICANT_REQUIRED_FIELDS_CHECK";
export const APPLICANT_CREDIT_CHECK_DONE_MANUALLY = "APPLICANT_CREDIT_CHECK_DONE_MANUALLY";
export const APPLICANT_ADD_NOTE_FOR_RENTAL_INCOME = "APPLICANT_ADD_NOTE_FOR_RENTAL_INCOME";
export const APPLICANT_EMPLOYMENT_INCOME_SOURCE = "APPLICANT_EMPLOYMENT_INCOME_SOURCE";
export const APPLICANT_SET_PROVISO_DATA_IS_NEW = "APPLICANT_SET_PROVISO_DATA_IS_NEW";
export const APPLICANT_AFFORDABILITY_TOTAL_UPDATE = "APPLICANT_AFFORDABILITY_TOTAL_UPDATE";
export interface ApplicantAffordabilityClearTableAction {
  type: "APPLICANT_AFFORDABILITY_CLEAR_TABLE";
  module: string[];
  clearTable: boolean;
  currentTab: number;
}

export interface ApplicantAffordabilityChangeTimerAction {
  type: "APPLICANT_CHANGE_RESEND_TIMER";
  module: string[];
  smsResendTimer: number;
  currentTab: number;
}

export interface ApplicantCurrentTabAction {
  type: "APPLICANT_CURRENT_TAB";
  module: string[];
  currentTab: number;
}
export interface ApplicantOverrideCreditScoreAction {
  type: "APPLICANT_OVERRIDE_CREDIT_SCORE";
  module: string[];
  currentTab: number;
}
export interface ApplicantOverrideLoaderAction {
  type: "APPLICANT_OVERRIDE_LOADER";
  module: string[];
  overrideLoader: boolean;
  currentTab: number;
  isCreditCheckOverriden?: boolean;
}

export interface ApplicantUpdateLivingArragementAction {
  type: "APPLICANT_UPDATE_LIVING_ARRAGEMENT";
  module: string[];
  currentTab: number;
  applicantLivingArrangement: string;
}
export interface AffordabilityRentalIncomeUpdateAction {
  type: "AFFORDABILITY_RENTAL_INCOME_UPDATE";
  module: string[];
  weeklyRentEstimate: number;
  operation: string;
  index: number;
  propertiesLength: number;
}

export interface ApplicantSetProvisioDataAction {
  index: number;
  module: string[];
  illionBankDataFileName: string;
  key: number;
  provisoData: any; // Todo fix the type
  type: "APPLICANT_SET_PROVISO_DATA";
}

export interface ApplicantNoteValueUpdateAction {
  index: number;
  module: string[];
  noteValue: string;
  rowName: string;
  type: "APPLICANT_NOTE_VALUE_UPDATE";
}

export interface ApplicantAddressAutoSuggestAction {
  addressAutoSuggestions: IPropertyAutoSuggest[] | ISuburbAutoSuggest[];
  childIndex: number;
  currentTab: number;
  module: string[];
  stateName: string;
  type: "APPLICANT_ADDRESS_AUTO_SUGGEST";
}

export interface ApplicantAddressLoaderAction {
  childIndex?: number;
  currentTab: number;
  loader: boolean;
  module: string[];
  stateName: string;
  type: "APPLICANT_ADDRESS_LOADER";
}

export interface ApplicantClearAddressResultAction {
  childIndex: number;
  module: string[];
  type: "APPLICANT_CLEAR_ADDRESS_RESULT";
}

export interface ApplicantCopyAddressAction {
  childIndex: number;
  stateName: string;
  movedInDateType: string;
  module: string[];
  applicantStatusType: string;
  type: "APPLICANT_COPY_ADDRESS";
}
export interface ApplicantAddRetrieveDataAction {
  module: string[];
  type: "APPLICANT_ADD_RETRIEVE_DATA";
}

export interface ApplicantUndoAction {
  childArray: string;
  childDeletedArray: string;
  childIndex: number;
  currentTab: number;
  module: string[];
  type: "APPLICANT_UNDO_ROW";
}

export interface ApplicantRemoveRetrieveDataAction {
  index: number;
  module: string[];
  type: "APPLICANT_REMOVE_RETRIEVE_DATA";
}

export interface ApplicantStateUpdateAction {
  objectName?: string;
  stateName: string;
  stateValue: number | string | Date | boolean | ISplitAddress | [];
  childArray?: string;
  childIndex?: number;
  mainState?: boolean;
  regex?: RegExp;
  required?: boolean;
  module: string[];
  type: "APPLICANT_STATE_UPDATE";
}

export interface ApplicantValidationUpdationAction {
  stateName: string;
  stateValue?: number | string | Date | boolean | ISplitAddress | null;
  childArray?: string;
  childIndex?: number;
  module: string[];
  type: "APPLICANT_VALIDATION_UPDATION";
}

export interface ApplicantGetPropertyAction {
  childArray: string;
  childIndex: string | number;
  currentTab: number;
  module: string[];
  property: any;
  type: "APPLICANT_GET_PROPERTY";
}

export interface ApplicantReferralCodeUpdateAction {
  stateName: string;
  stateValue: string;
  module: string[];
  type: "APPLICANT_REFERRAL_CODE_UPDATE";
}

export interface ApplicantAddNewAction {
  childArrayName?: string;
  index?: number;
  value?: IEmploymentObject;
  module: string[];
  type: "APPLICANT_ADD_NEW" | "APPLICANT_ADD_NEW_ITEM";
}

export interface ApplicantSetProbationWarningAction {
  childArrayName: string;
  value: boolean;
  currentTab?: number;
  module: string[];
  type: "APPLICANT_SET_PROBATION_WARNING";
}

export interface ApplicantUpdateValueForUncategorisedAction {
  changedTo: string;
  changedFrom: string;
  childIndex: number;
  module: string[];
  type: "APPLICANT_UPDATE_VALUE_FOR_UNCATEGORISED";
}
export interface ApplicantRemoveAction {
  childArrayName?: string;
  childDeletedArrayName?: string;
  module: string[];
  removeIndex: number;
  type: "APPLICANT_REMOVE_ITEM" | "APPLICANT_REMOVE";
}

export interface ApplicantClearDocumentIdAction {
  currentTab: number;
  replaceIllionData: boolean;
  module: string[];
  type: "APPLICANT_CLEAR_DOCUMENT_ID";
}

export interface ApplicantClearAffordabilityPageDataAction {
  currentTab: number;
  module: string[];
  type: "APPLICANT_CLEAR_APPLICANT_PAGE_DATA";
}

export interface ApplicantShowLoaderAction {
  childIndex: number;
  index: number;
  module: string[];
  type: "APPLICANT_SHOW_LOADER";
}

export interface ApplicantSendOtpLoaderAction {
  module: string[];
  sendOtpLoader: boolean;
  type: "APPLICANT_SEND_OTP_LOADER";
}

export interface ApplicantIsOtpSentActon {
  module: string[];
  isOtpSent: boolean;
  type: "APPLICANT_IS_OTP_SENT";
}

export interface ApplicantConfirmOtpLoaderAction {
  confirmOtpLoader: boolean;
  module: string[];
  type: "APPLICANT_CONFIRM_OTP_LOADER";
}

export interface ApplicantOtpConfirmedAction {
  isNumberConfirmed: boolean;
  module: string[];
  type: "APPLICANT_CONFIRM_OTP_NUMBER";
}

export interface ApplicantAddNoteForRentalIncomeAction {
  applicantIndex: number;
  rowIndex: number;
  module: string[];
  type: "APPLICANT_ADD_NOTE_FOR_RENTAL_INCOME";
}
export interface ApplicantCreditCheckDoneManuallyChangeAction {
  applicantIndex: number;
  module: string[];
  type: "APPLICANT_CREDIT_CHECK_DONE_MANUALLY";
}

export interface ApplicantFormLoaderAction {
  formLoader: boolean;
  module: string[];
  type: "APPLICANT_FORM_LOADER";
}

export interface ApplicantHideLoaderAction {
  childIndex: number;
  index: number;
  module: string[];
  type: "APPLICANT_HIDE_LOADER";
}

interface INoteInformation {
  index: number;
  type: string;
  key: string[];
  note: string;
}
export interface ApplicantModalOpenAction {
  module: string[];
  noteInformation: INoteInformation;
  type: "APPLICANT_MODAL_OPEN";
}

export interface ApplicantModalCloseAction {
  buttonClicked: string;
  currentTab: number;
  module: string[];
  rowType: string;
  type: "APPLICANT_MODAL_CLOSE";
}

export interface ApplicantLoadEntireStateAction {
  module: string[];
  state: IApplicantInitialState;
  type: "APPLICANT_LOAD_STATE";
}

export interface ApplicantSetCreditCardTextErrorAction {
  currentIndex: number;
  isError: boolean;
  module: string[];
  type: "APPLICANT_CREDIT_CARD_TEXT_ERROR";
}

export interface ApplicantValidateApplicantFieldsAction {
  applicantsRequiredField: IApplicantRequiredField[];
  module: string[];
  type: "APPLICANT_VALIDATE_APPLICANT_FIELD";
}
export interface ApplicantGetCreditScoreAndVerifyIdentityAction {
  creditScore: number;
  currentTab: number;
  formSubmit: boolean;
  idsMatrix: boolean;
  isDocumentVerified?: boolean;
  module: string[];
  verifyBtnText?: string;
  isCreditCheckOverriden: boolean;
  isCreditCheckDoneManually: boolean;
  type: "APPLICANT_GET_CREDIT_SCORE_AND_VERIFY_IDENTITY";
}

export interface ApplicantGetOtpAction {
  mobileNumber: string;
  module: string[];
  type: "APPLICANT_GET_OTP";
}

export interface ApplicantCopyToAllAction {
  currentIndex: number;
  currentTab: number;
  module: string[];
  type: "APPLICANT_COPY_TO_ALL";
}

export interface ApplicantSendSMSLoaderAction {
  smsLoaderShow: boolean;
  smsResendTimer: number;
  currentTab: number;
  smsSent: boolean;
  module: string[];
  type: "APPLICANT_SEND_SMS_LOADER";
}

export interface ApplicantIsLoanRefiancedNoteAction {
  stateName: string;
  stateValue: boolean;
  currentTab: number;
  childIndex: number;
  note: string;
  module: string[];
  type: "APPLICANT_AUTO_POPULATE_NOTE";
}

export interface ApplicantSetEmailDuplicateAction {
  module: string[];
  type: "APPLICANT_SET_EMAIL_DUPLICATE";
  index: number;
  isEmailDuplicate: boolean;
}

export interface ApplicantSetMobilePhoneDuplicateAction {
  module: string[];
  type: "APPLICANT_SET_MOBILE_PHONE_DUPLICATE";
  index: number;
  isMobilePhoneDuplicate: boolean;
}
export type NOTE_STATES = "earlyRepaymentStrategyNote";
export interface ApplicantAddNoteAction {
  note: string;
  stateName: NOTE_STATES;
  currentTab: number;
  module: string[];
  type: "APPLICANT_ADD_NOTE";
}
export interface ApplicantSetCurrentTabZeroAction {
  module: string[];
  type: "APPLICANT_SET_CURRENT_TAB_ZERO";
}

export interface ApplicantStatusRentUpdateAction {
  type: "APPLICANT_STATUS_RENT_UPDATE";
  module: string[];
  rent?: number;
  operation: string;
  index: number;
  howManyApplicant: string;
}

export interface ApplicantPrimaryRequiredFieldsUpdate {
  type: "APPLICANT_REQUIRED_FIELDS_UPDATE";
  module: string[];
  fieldName: string;
  count: number;
}

export interface ApplicantRequiredFieldsCheck {
  type: "APPLICANT_REQUIRED_FIELDS_CHECK";
  module: string[];
  loanTerm?: number;
}
export interface ApplicantEmploymentIncomeSourceAction {
  type: "APPLICANT_EMPLOYMENT_INCOME_SOURCE";
  module: string[];
  employmentType: string;
}
export interface ApplicantSetProvisoDataIsNewAction {
  type: "APPLICANT_SET_PROVISO_DATA_IS_NEW";
  module: string[];
  isNew: boolean;
  index: number;
}

export interface ApplicantAffordabilityTotalsUpdateAction {
  type: "APPLICANT_AFFORDABILITY_TOTAL_UPDATE";
  total: number;
  taxStatus?: string;
  duration?: string;
  currentTab: number;
  rowType: string;
  module: string[];
}

export type ApplicantActions =
  | ApplicantAddNewAction
  | ApplicantUpdateValueForUncategorisedAction
  | ApplicantAddRetrieveDataAction
  | ApplicantAddressAutoSuggestAction
  | ApplicantAddressLoaderAction
  | ApplicantClearAddressResultAction
  | ApplicantCopyAddressAction
  | ApplicantClearAffordabilityPageDataAction
  | ApplicantClearDocumentIdAction
  | ApplicantConfirmOtpLoaderAction
  | ApplicantCopyToAllAction
  | ApplicantCurrentTabAction
  | ApplicantOverrideCreditScoreAction
  | ApplicantOverrideLoaderAction
  | ApplicantUpdateLivingArragementAction
  | AffordabilityRentalIncomeUpdateAction
  | ApplicantFormLoaderAction
  | ApplicantGetCreditScoreAndVerifyIdentityAction
  | ApplicantGetOtpAction
  | ApplicantGetPropertyAction
  | ApplicantReferralCodeUpdateAction
  | ApplicantHideLoaderAction
  | ApplicantLoadEntireStateAction
  | ApplicantModalCloseAction
  | ApplicantModalOpenAction
  | ApplicantNoteValueUpdateAction
  | ApplicantOtpConfirmedAction
  | ApplicantAddNoteForRentalIncomeAction
  | ApplicantCreditCheckDoneManuallyChangeAction
  | ApplicantRemoveAction
  | ApplicantRemoveRetrieveDataAction
  | ApplicantSendOtpLoaderAction
  | ApplicantSetCreditCardTextErrorAction
  | ApplicantSetProvisioDataAction
  | ApplicantShowLoaderAction
  | ApplicantStateUpdateAction
  | ApplicantValidationUpdationAction
  | ApplicantUndoAction
  | ApplicantIsOtpSentActon
  | ApplicantValidateApplicantFieldsAction
  | ApplicantAffordabilityClearTableAction
  | ApplicantAffordabilityChangeTimerAction
  | ApplicantSendSMSLoaderAction
  | ApplicantIsLoanRefiancedNoteAction
  | ApplicantSetEmailDuplicateAction
  | ApplicantSetMobilePhoneDuplicateAction
  | ApplicantAddNoteAction
  | ApplicantSetCurrentTabZeroAction
  | ApplicantStatusRentUpdateAction
  | ApplicantPrimaryRequiredFieldsUpdate
  | ApplicantRequiredFieldsCheck
  | ApplicantEmploymentIncomeSourceAction
  | ApplicantSetProvisoDataIsNewAction
  | ApplicantAffordabilityTotalsUpdateAction
  | ApplicantSetProbationWarningAction;

export type ApplicantAffordabilityTotalsUpdateActionType = ({
  rowType,
  duration,
  taxStatus,
  total,
  currentTab,
}: IAffordabilityTotalsActionType) => ApplicantAffordabilityTotalsUpdateAction;

export type ApplicantRequiredFieldsCheckType = (loanTerm?: number) => ApplicantRequiredFieldsCheck;

export type ApplicantPrimaryRequiredFieldsUpdateType = ({
  fieldName,
  count,
}: {
  fieldName: string;
  count: number;
}) => ApplicantPrimaryRequiredFieldsUpdate;
export type ApplicantAutoPopulateNoteActionType = (
  stateName: string,
  stateValue: boolean,
  currentTab: number,
  childIndex: number,
  note: string
) => ApplicantIsLoanRefiancedNoteAction;

export type ApplicantAddNoteActionType = (
  note: string,
  stateName: NOTE_STATES,
  currentTab: number
) => ApplicantAddNoteAction;

export type ApplicantAddRetrieveDataActionType = () => ApplicantAddRetrieveDataAction;

export type ApplicantCurrentTabActionType = (currentTab: number) => ApplicantCurrentTabAction;
export type ApplicantOverrideCreditScoreActionType = (
  currentTab: number
) => ApplicantOverrideCreditScoreAction;
export type ApplicantOverrideLoaderActionType = (
  overrideLoader: boolean,
  currentTab: number,
  isCreditCheckOverriden?: boolean
) => ApplicantOverrideLoaderAction;
export type ApplicantUpdateLivingArragementActionType = (
  applicantLivingArrangement: string,
  currentTab: number
) => ApplicantUpdateLivingArragementAction;

export type ApplicantSendSMSLoaderActionType = (
  smsLoaderShow: boolean,
  smsResendTimer: number,
  currentTab: number,
  smsSent: boolean
) => ApplicantSendSMSLoaderAction;

export type ApplicantAffordabilityClearTableActionType = (
  currentTab: number,
  clearTable: boolean
) => ApplicantAffordabilityClearTableAction;

export type ApplicantAffordabilityChangeTimerActionType = (
  currentTab: number,
  smsResendTimer: number
) => ApplicantAffordabilityChangeTimerAction;

export type AffordabilityRentalIncomeUpdateActionType = (
  weeklyRentEstimate: number,
  operation: string,
  index: number,
  propertiesLength: number
) => AffordabilityRentalIncomeUpdateAction;

export type ApplicantSetProvisoDataActionType = (
  provisoData: any, // fix this type
  index: number,
  illionBankDataFileName: string,
  key: number
) => ApplicantSetProvisioDataAction;

export type ApplicantNoteValueUpdateActionType = (
  noteValue: string,
  rowName: string,
  index: number
) => ApplicantNoteValueUpdateAction;

export type ApplicantAddressAutoSuggestActionType = (
  currentTab: number,
  addressAutoSuggestions: IPropertyAutoSuggest[] | ISuburbAutoSuggest[],
  childIndex: number,
  stateName: string
) => ApplicantAddressAutoSuggestAction;

export type ApplicantAddressLoaderActionType = (
  loader: boolean,
  currentTab: number,
  stateName: string,
  childIndex?: number
) => ApplicantAddressLoaderAction;

export type ApplicantClearAddressResultActionType = (
  childIndex: number
) => ApplicantClearAddressResultAction;

export type ApplicantCopyAddressActionType = (
  childIndex: number,
  stateName: string,
  movedInDateType: string,
  applicantStatusType: string
) => ApplicantCopyAddressAction;

export type ApplicantUndoActionType = (
  currentTab: number,
  childIndex: number,
  childArray: string,
  childDeletedArray: string
) => ApplicantUndoAction;

export type ApplicantRemoveRetrieveDataActionType = (
  index: number
) => ApplicantRemoveRetrieveDataAction;

export type ApplicantStateUpdateActionType = ({}: StateUpdateAction) => ApplicantStateUpdateAction;

export type ApplicantValidationUpdationActionType = ({
  childArray,
  childIndex,
  stateName,
  stateValue,
}: {
  childArray: string;
  childIndex: number;
  stateName: string;
  stateValue: string | number | Date | null;
}) => ApplicantValidationUpdationAction;

export type ApplicantGetPropertyActionType = ({
  property,
  currentTab,
  childArray,
  childIndex,
}: {
  property: any;
  currentTab: number;
  childArray: string;
  childIndex: number;
}) => ApplicantGetPropertyAction;

interface IKeyValue {
  [x: string]: string;
}

export type ApplicantReferralCodeUpdateActionType = ({
  stateName,
  stateValue,
}: IKeyValue) => ApplicantReferralCodeUpdateAction;

export type ApplicantUpdateValueForUncategorisedActionType = (
  changedTo: string,
  childIndex: number,
  changedFrom: string
) => ApplicantUpdateValueForUncategorisedAction;

export type ApplicantAddNewActionType = (
  childArrayName?: string,
  index?: number,
  value?: IEmploymentObject
) => ApplicantAddNewAction;

export type ApplicantSetProbationWarningActionType = (
  childArrayName: string,
  value: boolean,
  currentTab?: number
) => ApplicantSetProbationWarningAction;

export type ApplicantRemoveActionType = (
  index: number,
  childArrayName?: string,
  childDeletedArrayName?: string
) => ApplicantRemoveAction;

export type ApplicantClearDocumentIdActionType = (
  currentTab: number,
  replace: boolean,
  length?: number
) => ApplicantClearDocumentIdAction;

export type ApplicantClearAffordabilityPageDataActionType = (
  currentTab: number
) => ApplicantClearAffordabilityPageDataAction;

export type ApplicantShowLoaderActionType = (
  index: number,
  childIndex: number
) => ApplicantShowLoaderAction;

export type ApplicantSendOtpLoaderActionType = (
  sendOtpLoader: boolean
) => ApplicantSendOtpLoaderAction;

export type ApplicantConfirmOtpLoaderActionType = (
  confirmOtpLoader: boolean
) => ApplicantConfirmOtpLoaderAction;

export type ApplicantIsOTPSentActionType = (isOtpSent: boolean) => ApplicantIsOtpSentActon;

export type ApplicantOtpConfirmedActionType = (
  isNumberConfirmed: boolean
) => ApplicantOtpConfirmedAction;

export type ApplicantAddNoteForRentalIncomeType = (
  applicantIndex: number,
  rowIndex: number
) => ApplicantAddNoteForRentalIncomeAction;

export type ApplicantCreditCheckDoneManuallyChangeType = (
  applicantIndex: number
) => ApplicantCreditCheckDoneManuallyChangeAction;

export type ApplicantFormLoaderActionType = (formLoader: boolean) => ApplicantFormLoaderAction;

export type ApplicantHideLoaderActionType = (
  index: number,
  childIndex: number
) => ApplicantHideLoaderAction;

export type ApplicantModalOpenActionType = (
  noteInformation: INoteInformation
) => ApplicantModalOpenAction;

export type ApplicantModalCloseActionType = (
  buttonClicked: string,
  rowType: string,
  currentTab: number
) => ApplicantModalCloseAction;

export type ApplicantLoadEntireStateActionType = (
  state: IApplicantInitialState
) => ApplicantLoadEntireStateAction;

export type ApplicantSetCreditCardTextErrorActionType = (
  currentIndex: number,
  isError: boolean
) => ApplicantSetCreditCardTextErrorAction;

export type ApplicantValidateApplicantFieldsActionType = (
  applicantsRequiredField: IApplicantRequiredField[]
) => ApplicantValidateApplicantFieldsAction;

export type ApplicantGetCreditScoreAndVerifyIdentityActionType = (result: {
  currentTab: number;
  creditScore: number;
  idsMatrix: boolean;
  verifyBtnText?: string;
  isDocumentVerified?: boolean;
  formSubmit: boolean;
  isCreditCheckOverriden: boolean;
  isCreditCheckDoneManually: boolean;
}) => ApplicantGetCreditScoreAndVerifyIdentityAction;

export type ApplicantGetOtpActionType = (mobileNumber: string) => ApplicantGetOtpAction;

export type ApplicantCopyToAllActionType = (
  currentTab: number,
  currentIndex: number
) => ApplicantCopyToAllAction;

export type ApplicantSetEmailDuplicateActiontype = (
  index: number,
  isEmailDuplicate: boolean
) => ApplicantSetEmailDuplicateAction;
export type ApplicantSetMobilePhoneDuplicateActiontype = (
  index: number,
  isMobilePhoneDuplicate: boolean
) => ApplicantSetMobilePhoneDuplicateAction;
export type ApplicantSetCurrentTabZeroActionType = () => ApplicantSetCurrentTabZeroAction;

export type ApplicantStatusRentUpdateActionType = ({
  index,
  operation,
  rent,
  howManyApplicant,
}: {
  index: number;
  operation: string;
  rent?: number;
  howManyApplicant: string;
}) => ApplicantStatusRentUpdateAction;

export type ApplicantEmploymentIncomeSourceActionType = (
  employmentType: string
) => ApplicantEmploymentIncomeSourceAction;

export type ApplicantSetProvisoDataIsNewActionType = (
  isNew: boolean,
  index: number
) => ApplicantSetProvisoDataIsNewAction;
