import { IAttachedFile } from "../../../constants";
import { IToastsInterface } from "../../reducers/successSnackbarReducer";

export const UPLOAD_FILE_MODAL_OPEN = "UPLOAD_FILE_MODAL_OPEN";
export const UPLOAD_FILE_MODAL_CLOSE = "UPLOAD_FILE_MODAL_CLOSE";
export const UPLOAD_LOADER = "UPLOAD_LOADER";
export const UPLOAD_HISTORY_UPDATE = "UPLOAD_HISTORY_UPDATE";
export const UPLOAD_LOAD_ENTIRE_STATE = "UPLOAD_LOAD_ENTIRE_STATE";
export const UPLOAD_UPDATE_NUMBER_OF_UPLOADS = "UPLOAD_UPDATE_NUMBER_OF_UPLOADS";
export const DELETE_FILE_MODAL_CLOSE = "DELETE_FILE_MODAL_CLOSE";
export const DELETE_FILE_MODAL_OPEN = "DELETE_FILE_MODAL_OPEN";
export const DISABLE_SUBMIT_BUTTON = "DISABLE_SUBMIT_BUTTON";
export const UPDATE_IS_SUBMIT_MODAL_VISIBILE = "UPDATE_IS_SUBMIT_MODAL_VISIBILE";
export const UPDATE_SETTLEMENT_DATE = "UPDATE_SETTLEMENT_DATE";
export const UPLOAD_DOCUMENT_PREVIEW = "UPLOAD_DOCUMENT_PREVIEW";
export const IS_UPLOAD_MODAL_VISIBLE = "IS_UPLOAD_MODAL_VISIBLE";
export const CHOOSE_FILE_NAME = "CHOOSE_FILE_NAME";
export const CHOOSE_FILE_EXTENSION = "CHOOSE_FILE_EXTENSION";
export const UPLOAD_TIME = "UPLOAD_TIME";
export const FILE_CHUNKS = "FILE_CHUNKS";
export const SET_TOASTS = "SET_TOASTS";
export const ATTACHED_FILES = "ATTACHED_FILES";
export const UPDATE_TAGS_ACTION = "UPDATE_TAGS_ACTION";
export const SELECTED_TAGS_ACTION = "SELECTED_TAGS_ACTION";
export const IS_ALL_FILES_UPLOADED = "IS_ALL_FILES_UPLOADED";
export const SET_UPLOADING_FILES = "SET_UPLOADING_FILES";

interface ICommon {
  module: string[];
}
export interface UploadFileModalCloseAction extends ICommon {
  tagsModalVisibility: boolean;
  type: "UPLOAD_FILE_MODAL_CLOSE";
}

export interface DeleteFileModalCloseAction extends ICommon {
  deleteModalVisibility: boolean;
  type: "DELETE_FILE_MODAL_CLOSE";
}

export interface UpdateSettlementDateAction extends ICommon {
  settlementDate: Date;
  type: "UPDATE_SETTLEMENT_DATE";
}

export interface UploadIsSubmitModalVisibleAction extends ICommon {
  isSubmitModalVisible: boolean;
  type: "UPDATE_IS_SUBMIT_MODAL_VISIBILE";
}

export interface UploadModalVisibleAction extends ICommon {
  isUploadModalVisible: boolean;
  scenarioId: string;
  type: "IS_UPLOAD_MODAL_VISIBLE";
}

export interface DeleteFileModalOpenAction extends ICommon {
  deleteModalVisibility: boolean;
  deleteFileId: any;
  type: "DELETE_FILE_MODAL_OPEN";
}

export interface UploadFileModalOpenAction extends ICommon {
  tagsModalVisibility: boolean;
  scenarioId: string;
  type: "UPLOAD_FILE_MODAL_OPEN";
}

export interface UploadLoaderAction extends ICommon {
  showUploadLoader: boolean;
  type: "UPLOAD_LOADER";
}

export interface UploadLoadEntireStateAction extends ICommon {
  state: any;
  type: "UPLOAD_LOAD_ENTIRE_STATE";
}

export interface UploadUpdateNumberOfUploadsAction extends ICommon {
  numberOfUploads: number;
  type: "UPLOAD_UPDATE_NUMBER_OF_UPLOADS";
}

export interface UploadHistoryUpdateAction extends ICommon {
  fileName: string;
  type: "UPLOAD_HISTORY_UPDATE";
}

export interface DisableSubmitButtonAction extends ICommon {
  isDisabled: boolean;
  type: "DISABLE_SUBMIT_BUTTON";
}

export interface UploadDocumentPreviewAction extends ICommon {
  isOpen: boolean;
  fileName: string;
  url: string;
  fileType: string;
  type: "UPLOAD_DOCUMENT_PREVIEW";
}

export interface FileChunksAction extends ICommon {
  index: number;
  chunks: number;
  type: "FILE_CHUNKS";
}

export interface SetToastsAction extends ICommon {
  toasts: IToastsInterface[];
  isFileSuccess: boolean;
  type: "SET_TOASTS";
}

export interface AttachedFilesAction extends ICommon {
  attachedFiles: IAttachedFile[];
  type: "ATTACHED_FILES";
}

export interface UpdateTagsAction extends ICommon {
  fileName: string;
  uploadTime: string;
  tags: string[];
  uploadId: number;
  type: "UPDATE_TAGS_ACTION";
}

export interface SelectedTagsAction extends ICommon {
  selectedTags: string[];
  type: "SELECTED_TAGS_ACTION";
}

export interface isAllFilesAttachedAction extends ICommon {
  allFilesAttached: boolean;
  type: "IS_ALL_FILES_UPLOADED";
}

export interface SetUploadingFilesAction extends ICommon {
  allFilesUploaded: number;
  type: "SET_UPLOADING_FILES";
}

export type UploadActions =
  | UploadFileModalCloseAction
  | DeleteFileModalCloseAction
  | UpdateSettlementDateAction
  | UploadIsSubmitModalVisibleAction
  | DeleteFileModalOpenAction
  | UploadFileModalOpenAction
  | UploadLoaderAction
  | UploadHistoryUpdateAction
  | UploadLoadEntireStateAction
  | UploadUpdateNumberOfUploadsAction
  | DisableSubmitButtonAction
  | UploadDocumentPreviewAction
  | UploadModalVisibleAction
  | FileChunksAction
  | SetToastsAction
  | AttachedFilesAction
  | UpdateTagsAction
  | SelectedTagsAction
  | isAllFilesAttachedAction
  | SetUploadingFilesAction;

export type UploadFileModalCloseActionType = (
  tagsModalVisibility: boolean
) => UploadFileModalCloseAction;

export type DeleteFileModalCloseActionType = (
  deleteModalVisibility: boolean
) => DeleteFileModalCloseAction;

export type UploadModalVisibleActionType = (
  isUploadModalVisible: boolean,
  scenarioId: string
) => UploadModalVisibleAction;

export type UpdateSettlementDateActionType = (settlementDate: Date) => UpdateSettlementDateAction;

export type UploadIsSubmitModalVisibleActionType = (
  isSubmitModalVisible: boolean
) => UploadIsSubmitModalVisibleAction;

export type UploadLoadEntireStateActionType = (state: any) => UploadLoadEntireStateAction;

export type UploadUpdateNumberOfUploadsActionType = (
  numberOfUploads: number
) => UploadUpdateNumberOfUploadsAction;

export type UploadFileModalOpenActionType = (
  tagsModalVisibility: boolean,
  scenarioId: string
) => UploadFileModalOpenAction;

export type DeleteFileModalOpenActionType = (
  deleteModalVisibility: boolean,
  deleteFileId: any
) => DeleteFileModalOpenAction;

export type UploadLoaderActionType = (showUploadLoader: boolean) => UploadLoaderAction;

export type UploadHistoryUpdateActionType = (fileName: string) => UploadHistoryUpdateAction;

export type DisableSubmitButtonActionType = (isDisabled: boolean) => DisableSubmitButtonAction;
export type UploadDocumentPreviewActionType = (
  isOpen: boolean,
  fileName: string,
  url: string,
  fileType: string
) => UploadDocumentPreviewAction;

export type FileChunksActionType = (index: number, chunks: number) => FileChunksAction;

export type SetToastsActionType = (
  toasts: IToastsInterface[],
  isFileSuccess: boolean
) => SetToastsAction;

export type AttachedFilesActionType = (attachedFiles: IAttachedFile[]) => AttachedFilesAction;

export type UpdateTagsActionType = (
  fileName: string,
  uploadTime: string,
  tags: string[],
  uploadId: number
) => UpdateTagsAction;

export type SelectedTagsActionType = (selectedTags: string[]) => SelectedTagsAction;

export type isAllFilesAttachedActionType = (allFilesAttached: boolean) => isAllFilesAttachedAction;

export type SetUploadingFilesActionType = (allFilesUploaded: number) => SetUploadingFilesAction;
