import ApolloClient from "apollo-client";
import React, { useState } from "react";
import { useApolloClient } from "react-apollo-hooks";
import { Button } from "react-md";
import { connect } from "react-redux";
import { UPDATE_LAST_LOGIN } from "../../constants/queryAndMutations";
import logo from "../../images/embedded-logo.png";
import { errorGenericErrorHandleAction } from "../../store/actions/errorActions/errorAction";
import { ErrorGenericErrorHandleActionType } from "../../store/actions/errorActions/errorActionType";
import { helpMenuOpenAction } from "../../store/actions/helpActions/helpAction";
import { HelpMenuOpenActionType } from "../../store/actions/helpActions/helpActionType";
import {
  scenarioResetCurrentScenarioIdAction,
  scenarioModalShowAction,
  scenarioSaveDiscardModalShowAction,
  scenarioSaveAsOwnScenarioModalShowAction,
} from "../../store/actions/scenarioActions/scenarioActions";
import {
  ScenarioResetCurrentScenarioIdType,
  ScenarioModalShowActionType,
  ScenarioSaveDiscardModalShowActionType,
  ScenarioSaveAsOwnScenarioModalShowActionType,
} from "../../store/actions/scenarioActions/scenarioActionType";
import { logoutThunk, LogoutThunkType } from "../../store/thunk/loginThunk";
import GearIcon from "./icons/gearIcon";
import BrokerIcon from "./icons/brokersIcon";
import HouseIcon from "./icons/houseIconNew";
import PersonIcon from "./icons/personIcon";
import SignOutIcon from "./icons/signoutIcon";
import TableIcon from "./icons/tableIcon";
import { TAppStateType } from "../../store/reducers/reducers";
import { ICurrentUser, ITeam } from "amm-admin-interface";
import { myAccountUpdateLastLogin } from "../../constants/userActions";
import { useLocation, useNavigate } from "react-router-dom";
import {
  saveScenarioThunk,
  SaveScenarioThunkType,
} from "../../store/thunk/scenarioThunk/saveScenarioThunk";
import { clearCurrentScenario } from "../../utility/clearCurrentScenario";
import ScenarioSaveDiscardModal from "./scenarioSaveDiscardModal";
import { IScenarioPayloadUserScenarios } from "amm-scenario-interface";
import { loginActiveUserForScenarioAction } from "../../store/actions/login/loginAction";
import { LoginActiveUserForScenarioActionType } from "../../store/actions/login/loginActionType";
import { isMocked } from "../../utility";
import { STATUS_NAMES } from "../../constants";
import { getSaveScearioUserAction } from "./utility";
import CreditReviewIcon from "./icons/creditReview";
import ReportsIcon from "./icons/reportsIcon";
import BrandingIcon from "./icons/brandingIcon";
import LeadsIcon from "./icons/leadsIcon";
import BrandIcon from "./icons/brandIcon";

// tslint:disable-next-line: no-empty-interface
interface OwnProps {}
interface StateProps {
  currentUser: ICurrentUser;
  firstName: string;
  lastName: string;
  token: string;
  totalState: TAppStateType;
  menuOpen: boolean;
  viewAdminPanelPermission: boolean;
  brandingPermission: boolean;
  currentScenarioId: number;
  saveDiscardModalShow: boolean;
  scenarios: IScenarioPayloadUserScenarios[];
  viewTeamsPanelPermission: boolean;
  saveTeamScenarioPermission: boolean;
  notViewMyDashboardOrScenarioPermission: boolean;
  userIDForScenario: number;
  currentUserID: number;
  teams: ITeam;
  currentTeamID: number;
  selectedUserScenarios: IScenarioPayloadUserScenarios[];
  saveAsOwnScenarioModalShow: boolean;
  viewLeadsPanelPermission: boolean;
  createNewScenarioPermission: boolean;
  viewCreditReviewPermission: boolean;
  viewReportPermission: boolean;
  currentScenarioStatus: string;
  reviewerID?: number;
  scenarioLegacyID?: string;
}

interface DispatchProps {
  logoutThunk: LogoutThunkType;
  errorGenericErrorHandleAction: ErrorGenericErrorHandleActionType;
  scenarioResetCurrentScenarioIdAction: ScenarioResetCurrentScenarioIdType;
  helpMenuOpenAction: HelpMenuOpenActionType;
  scenarioModalShowAction: ScenarioModalShowActionType;
  saveScenarioThunk: SaveScenarioThunkType;
  clearCurrentScenario: () => void;
  scenarioSaveDiscardModalShowAction: ScenarioSaveDiscardModalShowActionType;
  loginActiveUserForScenarioAction: LoginActiveUserForScenarioActionType;
  scenarioSaveAsOwnScenarioModalShowAction: ScenarioSaveAsOwnScenarioModalShowActionType;
}

type HeaderProps = StateProps & DispatchProps & OwnProps;

const submit = async (apolloClient: ApolloClient<object>, props: HeaderProps) => {
  props.logoutThunk(props.token);
  return apolloClient
    .mutate({
      mutation: UPDATE_LAST_LOGIN,
      variables: {
        input: {
          id: props.currentUser.id,
        },
        userAction: {
          actionPassed: myAccountUpdateLastLogin[0],
        },
      },
    })
    .catch((err) => {
      console.log("error while updating last login", err.message);
    });
};

const SideMenu: React.FC<HeaderProps> = (props: HeaderProps) => {
  const apolloClient = useApolloClient();
  const location = useLocation();
  const navigate = useNavigate();
  const [showOverlay, setShowOverlay] = React.useState(false);

  const onAnimationEnd = () => {
    if (!props.menuOpen) {
      setShowOverlay(false);
    }
  };

  React.useEffect(() => {
    if (props.menuOpen) {
      setShowOverlay(true);
    }
  }, [props.menuOpen]);

  const isReviewer =
    (props.currentScenarioStatus === STATUS_NAMES.creditReview ||
      props.currentScenarioStatus === STATUS_NAMES.approvedPending) &&
    props.reviewerID === props.currentUserID;
  const okayAction = () => {
    props.saveScenarioThunk({
      state: props.totalState,
      token: props.token,
      scenarioId: props.currentScenarioId as number,
      calledFrom: "sideMenu",
      userAction: getSaveScearioUserAction({
        isReviewer,
        currentUserID: props.currentUserID,
        userIDForScenario: props.userIDForScenario,
        saveTeamScenarioPermission: props.saveTeamScenarioPermission,
        saveAsOwnScenarioModalShow: props.saveAsOwnScenarioModalShow,
      }),
      operation: "Save",
      saveAsOwnScenarioModalShow: props.saveAsOwnScenarioModalShow,
      toPath: toPath,
      scenarioLegacyID: props.scenarioLegacyID,
    });

    props.loginActiveUserForScenarioAction(props.currentUserID);
  };

  // on-dicard click clear current scenario and move to next page
  const discardAction = () => {
    props.clearCurrentScenario();

    navigate(toPath);
    props.scenarioSaveDiscardModalShowAction(false);
    props.loginActiveUserForScenarioAction(props.currentUserID);
    props.scenarioSaveAsOwnScenarioModalShowAction(false, "");
  };

  const cancelAction = () => {
    props.scenarioSaveDiscardModalShowAction(false);
  };

  const paths = [
    "/scenario/property",
    "/scenario/applicants",
    "/scenario/solution",
    "/scenario/affordability",
  ];

  const [toPath, setToPath] = useState<string>("");

  const handleOnClick = (to: string) => {
    const currentScenario = props.scenarios.find(
      (scenario) => scenario.id === props.currentScenarioId
    );
    const scenarioStatus = currentScenario ? currentScenario.statuses[0].status : "";
    if (!paths.includes(location.pathname) && to === paths[0]) {
      props.clearCurrentScenario();
    }

    // when credit reviewer is reviewing the scenario
    if (paths.includes(location.pathname) && isReviewer) {
      setToPath(to);
      props.scenarioSaveDiscardModalShowAction(true);
      return;
    }
    if (
      paths.includes(location.pathname) &&
      (!props.currentScenarioId ||
        scenarioStatus === STATUS_NAMES.scenario ||
        scenarioStatus === STATUS_NAMES.reWork)
    ) {
      setToPath(to);
      props.scenarioSaveDiscardModalShowAction(true);
    } else if (
      paths.includes(location.pathname) &&
      (!props.currentScenarioId ||
        (scenarioStatus !== STATUS_NAMES.scenario && scenarioStatus !== STATUS_NAMES.reWork))
    ) {
      if (
        isMocked(
          props.teams,
          props.currentTeamID,
          props.selectedUserScenarios,
          props.currentScenarioId,
          props.userIDForScenario,
          props.currentUserID
        )
      ) {
        setToPath(to);
        props.scenarioSaveDiscardModalShowAction(true);
      } else {
        props.clearCurrentScenario();
        props.loginActiveUserForScenarioAction(props.currentUserID);
        navigate(to);
      }
    } else {
      if (paths.includes(location.pathname) && props.currentScenarioId && !currentScenario) {
        props.clearCurrentScenario();
      }

      navigate(to);
    }
  };

  return (
    <>
      {showOverlay && (
        <div
          className="overlay"
          onClick={() => props.helpMenuOpenAction(false)}
          onAnimationEnd={onAnimationEnd}
          style={{ animation: `${props.menuOpen ? "fadeIn" : "fadeOut"} 1s ease 0s forwards` }}
        />
      )}
      <aside className={`side-menu ${props.menuOpen ? "side-menu-open" : ""}`}>
        <div className="menu">
          <header>
            <h4 className="header-broker-name">
              {props.firstName !== "" ? `${props.firstName} ${props.lastName}` : "BROKER NAME"}
            </h4>
            <Button
              className="widget--close-btn"
              onClick={() => props.helpMenuOpenAction(false)}
              icon={true}
            >
              close
            </Button>
          </header>
          <ul className="menu-list" onClick={() => props.helpMenuOpenAction(false)}>
            {!props.notViewMyDashboardOrScenarioPermission && (
              <li
                id="menu-list-dashboard"
                className="menu-list-dashboard"
                onClick={() => handleOnClick("/dashboard/my-scenarios")}
              >
                <a>
                  <TableIcon />
                  Dashboard
                </a>
              </li>
            )}
            {props.createNewScenarioPermission && (
              <li
                id="menu-list-new-scenario"
                className="menu-list-new-scenario"
                onClick={() => handleOnClick("/scenario/property")}
              >
                <a>
                  <HouseIcon />
                  Create scenario
                </a>
              </li>
            )}
            {props.viewAdminPanelPermission && (
              <li
                id="menu-list-users"
                className="menu-list-adminstration"
                onClick={() => handleOnClick("/administration/users")}
              >
                <a>
                  <PersonIcon />
                  Users
                </a>
              </li>
            )}
            {props.viewTeamsPanelPermission && (
              <li
                id="menu-list-teams"
                className="menu-list-adminstration"
                onClick={() => handleOnClick("/teams")}
              >
                <a>
                  <BrokerIcon />
                  Teams
                </a>
              </li>
            )}

            {props.viewLeadsPanelPermission && (
              <li
                id="menu-list-leads"
                className="menu-list-adminstration"
                onClick={() => handleOnClick("/leads")}
              >
                <a>
                  <LeadsIcon />
                  Leads
                </a>
              </li>
            )}
            {props.viewCreditReviewPermission && (
              <li
                id="menu-list-credit-review"
                className="menu-list-adminstration"
                onClick={() => handleOnClick("/credit_review")}
              >
                <a>
                  {/* new broker icon to be used when provided */}
                  <CreditReviewIcon />
                  Credit Review
                </a>
              </li>
            )}
            <li
              id="menu-list-my-account"
              className="menu-list-my-account"
              onClick={() => handleOnClick("/dashboard/user-list")}
            >
              <a>
                <GearIcon />
                Account
              </a>
            </li>

            {props.brandingPermission && (
              <li
                id="menu-list-settings"
                className="menu-list-settings"
                onClick={() => handleOnClick("/settings/branding")}
              >
                <a>
                  <BrandingIcon />
                  Administration
                </a>
                <div className="sub-nav menu-list-branding">
                  <a>
                    <BrandIcon />
                    Branding
                  </a>
                </div>
              </li>
            )}
            {props.viewReportPermission && (
              <li
                id="menu-list-credit-review"
                className="menu-list-adminstration"
                onClick={() => handleOnClick("/report")}
              >
                <a>
                  {/* new broker icon to be used when provided */}
                  <ReportsIcon />
                  Reports
                </a>
              </li>
            )}
            <li id="sign-out">
              <a onClick={() => submit(apolloClient, props)}>
                <SignOutIcon />
                Sign out
              </a>
            </li>
          </ul>
        </div>
        <footer>
          <h4 className="menu-list-footer">
            <img className="menu-list-footer-app-logo" src={logo} alt="amm" /> Embedded
          </h4>
          <p className="menu-list-footer-content">
            We Are Embedded Lending Pty Limited, ABN 16 672 776 608, is a Corporate Authorised Representative (558519) of EM Finance Corporation Pty Limited, Australian Credit Licence 388274, ABN 66 097 851 324.
          </p>
          <ul>
            <li className="menu-list-footer-list-items">
              <a href="https://australianmortgage.com" target="_blank">
                Contact Australian Mortgage
              </a>
            </li>
            <li className="menu-list-footer-list-items">
              <a href="#">Disclaimer</a>
            </li>
            <li className="menu-list-footer-list-items">
              <a href="https://australianmortgage.com/resources/privacy-policy/">
                Privacy Statement
              </a>
            </li>
            <li className="menu-list-footer-list-items">
              <a href="#">Terms & Conditions</a>
            </li>
          </ul>
        </footer>
      </aside>
      <ScenarioSaveDiscardModal
        modalShow={props.saveDiscardModalShow}
        okayAction={okayAction}
        discardAction={discardAction}
        cancelAction={cancelAction}
      />
    </>
  );
};

export const mapStateToProps = (state: TAppStateType) => {
  return {
    currentUser: state.login.currentUser,
    firstName: state.login.currentUser
      ? state.login.currentUser.firstName
        ? state.login.currentUser.firstName
        : ""
      : "",
    lastName: state.login.currentUser
      ? state.login.currentUser.lastName
        ? state.login.currentUser.lastName
        : ""
      : "",
    token: state.login.token as string,
    totalState: state,
    menuOpen: state.help.menuOpen,
    brandingPermission: state.login.userPermissions.brandingPermission,
    viewAdminPanelPermission: state.login.userPermissions.viewAdminPanelPermission,
    currentScenarioId: state.scenario.currentScenarioId ? state.scenario.currentScenarioId : 0,
    saveDiscardModalShow: state.scenario.modal.saveDiscardModalShow
      ? state.scenario.modal.saveDiscardModalShow
      : false,
    scenarios: state.scenario.scenarios,
    viewTeamsPanelPermission: state.login.userPermissions.viewTeamsPanelPermission,
    viewLeadsPanelPermission: state.login.userPermissions.viewLeadsPanelPermission,
    saveTeamScenarioPermission: state.login.userPermissions.saveTeamScenarioPermission,
    notViewMyDashboardOrScenarioPermission:
      state.login.userPermissions.notViewMyDashboardOrScenarioPermission,
    createNewScenarioPermission: state.login.userPermissions.createNewScenarioPermission,
    currentUserID: state.login.currentUser.id,
    userIDForScenario: state.login.userIdForScenario
      ? state.login.userIdForScenario
      : state.login.currentUser.id,
    teams: state.login.teams,
    currentTeamID: state.login.currentTeamID,
    selectedUserScenarios: state.login.selectedUserScenarios
      ? state.login.selectedUserScenarios
      : [],
    saveAsOwnScenarioModalShow: state.scenario.modal.saveAsOwnScenarioModalShow
      ? state.scenario.modal.saveAsOwnScenarioModalShow
      : false,
    viewCreditReviewPermission: state.login.userPermissions.viewCreditReview,
    viewReportPermission: state.login.userPermissions.viewReport,
    currentScenarioStatus: state.scenario.currentScenarioStatus,
    reviewerID: state.scenario.reviewerID,
    scenarioLegacyID: state.review.scenarioLegacyID ? state.review.scenarioLegacyID : "",
  };
};
export const mapDispatchToProps = {
  logoutThunk,
  errorGenericErrorHandleAction,
  scenarioResetCurrentScenarioIdAction,
  helpMenuOpenAction,
  scenarioModalShowAction,
  saveScenarioThunk,
  clearCurrentScenario,
  scenarioSaveDiscardModalShowAction,
  loginActiveUserForScenarioAction,
  scenarioSaveAsOwnScenarioModalShowAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(SideMenu);
