import axios from "axios";
import { documentScenarioUploadFile, uploadFileSizeMaxBytes } from "../../../constants";
import {
  fileChunksAction,
  uploadHistoryUpdateAction,
  uploadLoaderAction,
} from "../../actions/uploadDocumentsActions/uploadDocumentsAction";
import { scenarioUploadDocumentAction } from "../../../constants/userActions";
import { Action } from "redux";
import { ThunkAction } from "redux-thunk";
import { reviewShowApproveLoaderAction } from "../../actions/reviewActions/reviewActions";
import store from "../../configureStore";
import { successSnackBarHandleAction } from "../../actions/snackbarSuccessAction/successAction";
import {
  setToastsAction,
  isAllFilesAttachedAction,
} from "../../actions/uploadDocumentsActions/uploadDocumentsAction";

export type UploadFileScenarioThunkType = (
  file: any,
  token: string,
  scenarioId: string,
  currentUserId: number,
  currentScenarioUserId: number,
  index: number,
  totalFiles: number,
  uploadDocumentsForAllScenariosAsReviewer: boolean,
  currentScenarioReviewerId?: number
) => ThunkAction<any, any, any, Action>;

export const uploadFileScenarioThunk: UploadFileScenarioThunkType = (
  file,
  token,
  scenarioId,
  currentUserId,
  currentScenarioUserId,
  index,
  totalFiles,
  uploadDocumentsForAllScenariosAsReviewer,
  currentScenarioReviewerId
) => {
  const apiUrl = documentScenarioUploadFile; // set tthe value;

  return async (dispatch: any) => {
    dispatch(uploadLoaderAction(true));
    dispatch(reviewShowApproveLoaderAction({ uploadLoader: false }));
    file.fileTags = JSON.stringify("");
    try {
      await sendFileInChunks({
        apiUrl,
        file,
        currentScenarioUserId,
        currentUserId,
        scenarioId,
        token,
        dispatch,
        currentScenarioReviewerId,
        index,
        totalFiles,
        uploadDocumentsForAllScenariosAsReviewer,
      });
    } catch (error) {
      dispatch(uploadLoaderAction(false));
      if (index == totalFiles - 1) {
        dispatch(isAllFilesAttachedAction(true));
      }
      return dispatch(setToastsAction([{ text: error.message }], false));
    }
  };
};

interface ISendFile {
  apiUrl: string;
  file: any;
  currentScenarioUserId: number;
  currentUserId: number;
  token: string;
  scenarioId: string;
  dispatch?: any;
  currentScenarioReviewerId?: number;
  numberOfFragments?: number;
  index: number;
  totalFiles: number;
  uploadDocumentsForAllScenariosAsReviewer: boolean;
}

const calculateFragments = (fileSize: number) => {
  const maxfileSizeBytes = uploadFileSizeMaxBytes;
  if (fileSize <= maxfileSizeBytes) {
    return 1;
  } else {
    return Math.ceil(fileSize / maxfileSizeBytes);
  }
};

const sendFileInChunks = async ({
  apiUrl,
  file,
  token,
  currentScenarioUserId,
  currentUserId,
  scenarioId,
  dispatch,
  currentScenarioReviewerId,
  index,
  totalFiles,
  uploadDocumentsForAllScenariosAsReviewer,
}: ISendFile) => {
  try {
    const numberOfFragments = calculateFragments(file.fileSize);
    dispatch(fileChunksAction(index, numberOfFragments));
    // maximum base64 characters to be sent and each character is of 6 bits
    const maxCharacters = Math.ceil((uploadFileSizeMaxBytes * 8) / 6);
    file.fileUID = getUniqueFileIdentifier(file.fileName);
    const promises = [];
    for (let fragmentIndex = 1; fragmentIndex < numberOfFragments; fragmentIndex++) {
      const fileChunk = JSON.parse(JSON.stringify(file));
      fileChunk.totalNumberOfFragments = numberOfFragments;
      fileChunk.fileType = "text";
      fileChunk.fragmentNumber = fragmentIndex;
      if (fragmentIndex < numberOfFragments) {
        fileChunk.fileBase64 = file.fileBase64.substr(
          (fragmentIndex - 1) * maxCharacters,
          maxCharacters
        );
        fileChunk.chunkSize = (fileChunk.fileBase64.length * 6) / 8;
        //these promises are asynchrounous so these request are dependent on the memory of the lambda function
        promises.push(
          sendFile({
            apiUrl,
            file: fileChunk,
            token,
            currentScenarioUserId,
            currentUserId,
            scenarioId,
            currentScenarioReviewerId,
            numberOfFragments,
            index,
            totalFiles,
            uploadDocumentsForAllScenariosAsReviewer,
          })
        );
      }
    }

    // sending last chunk or the complete file
    await Promise.all(promises);
    const fileChunk = JSON.parse(JSON.stringify(file));
    fileChunk.totalNumberOfFragments = numberOfFragments;
    fileChunk.fileType = file.fileType;
    fileChunk.fragmentNumber = numberOfFragments;
    fileChunk.fileBase64 = file.fileBase64.substr((numberOfFragments - 1) * maxCharacters);
    const result = await sendFile({
      apiUrl,
      file: fileChunk,
      token,
      currentScenarioUserId,
      currentUserId,
      scenarioId,
      currentScenarioReviewerId,
      numberOfFragments,
      index,
      totalFiles,
      uploadDocumentsForAllScenariosAsReviewer,
    });
    dispatch(uploadHistoryUpdateAction(result.data.payload.file_name));
    dispatch(
      successSnackBarHandleAction(
        `File: ${result.data.payload.file_name} uploaded successfully for scenario ID: ${scenarioId}`
      )
    );
    dispatch(uploadLoaderAction(false));
    if (index == totalFiles - 1) {
      setTimeout(() => dispatch(isAllFilesAttachedAction(true)), 10000);
    }
    return result;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.message) {
      dispatch(uploadLoaderAction(false));
      dispatch(fileChunksAction(index, 1));
      return dispatch(setToastsAction([{ text: error.response.data.message }], false));
    }
    throw new Error(error);
  }
};

const sendFile = async ({
  apiUrl,
  file,
  currentScenarioUserId,
  currentUserId,
  token,
  scenarioId,
  currentScenarioReviewerId,
  numberOfFragments,
  index,
  uploadDocumentsForAllScenariosAsReviewer,
}: ISendFile) => {
  try {
    const userActionForSendFile = () => {
      if (currentScenarioReviewerId === currentUserId) {
        return scenarioUploadDocumentAction[2];
      } else if (currentScenarioUserId === currentUserId) {
        return scenarioUploadDocumentAction[0];
      } else if (uploadDocumentsForAllScenariosAsReviewer) {
        return scenarioUploadDocumentAction[3];
      } else {
        return scenarioUploadDocumentAction[1];
      }
    };
    const result = await axios.post(apiUrl, JSON.stringify({ file: file }), {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      params: {
        timedata: Date.now(),
        user_action: userActionForSendFile(),
        scenario_id: scenarioId,
        reviewer_id: currentScenarioReviewerId,
      },
    });
    if (numberOfFragments) {
      store.dispatch(fileChunksAction(index, numberOfFragments));
    }
    return result;
  } catch (error) {
    throw error;
  }
};

const getUniqueFileIdentifier = (fileName: string) =>
  `${fileName}-date-${Date.now()}-random-${(Math.random() * 100000000).toFixed(0)}`;
