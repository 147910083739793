import React from "react";
import { Button, Chip } from "react-md";
import { connect } from "react-redux";
import {  DOMAIN_PROMOTIONAL_LINKS } from "../../constants";
import { helpMenuOpenAction } from "../../store/actions/helpActions/helpAction";
import { HelpMenuOpenActionType } from "../../store/actions/helpActions/helpActionType";
import { TAppStateType } from "../../store/reducers/reducers";
import history from "../../routes/history";

interface OwnProps {
  rightTitle?: string;
  showPromoBanner?: boolean;
}
interface StateProps {
  scenarioID: number | null;
  showReviewScreen: boolean;
}

interface DispatchProps {
  helpMenuOpenAction: HelpMenuOpenActionType;
}

type HeaderProps = StateProps & DispatchProps & OwnProps;
const domain = process.env.REACT_APP_DOMAIN ? process.env.REACT_APP_DOMAIN : "shore";

export const Header: React.FC<HeaderProps> = (props: HeaderProps) => {
  return (
    <>
      <header className="header full-width--row">
        <Button
          id="header-menu"
          className="header-menu"
          icon={true}
          onClick={() => props.helpMenuOpenAction(true)}
        >
          <span />
          <span />
          <span />
        </Button>
        <span className="app-logo" />
        <div className="header--content-2">
        {props.scenarioID && ( history.location.pathname.includes("property") ||
        history.location.pathname.includes("solution") ||
        history.location.pathname.includes("applicants") ||
        history.location.pathname.includes("affordability") || props.showReviewScreen) ? (<Chip className="chip-position" label={`Scenario ID: ${props.scenarioID}`}/>): (<></>)}
        
          <h2>Intelligent credit</h2>
          {props.rightTitle && <h2 className="text-green">{props.rightTitle}</h2>}
        </div>
      </header>
      {props.showPromoBanner && (
        <div className="promo-banner full-width--row">
          <b>Launch Promotion:</b> Special interest rates across all products for a limited time. For more information, see our{" "}
          <a href={DOMAIN_PROMOTIONAL_LINKS[domain]} target="_blank">
            terms &amp; conditions
          </a>
          .
        </div>
      )}
    </>
  );
};

export const mapStateToProps = (state: TAppStateType) => {
  return {
  scenarioID: state.scenario.currentScenarioId,
  showReviewScreen: state.help.showReviewScreen
  };
};
export const mapDispatchToProps = {
  helpMenuOpenAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
