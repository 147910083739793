import axios from "axios";
import { ThunkAction } from "redux-thunk";
import { Action } from "redux";
import { pdfGeneration } from "../../../constants";
import { errorGenericErrorHandleAction } from "../../actions/errorActions/errorAction";
import { TAppStateType } from "../../reducers/reducers";
import { reviewShowApproveLoaderAction } from "../../actions/reviewActions/reviewActions";
import { IPropertyPDF } from "amm-objective-interface";

interface IGeneratePDFThunkParams {
  state: TAppStateType | IPropertyPDF;
  token: string;
  scenarioId: number;
  userAction: string;
  pdfName: string;
  pdfType?: string;
}

export type TGeneratePDFThunkType = (
  params: IGeneratePDFThunkParams
) => ThunkAction<any, any, any, Action>;

const downloadPdf = (url: string, fileName: string) => {
  navigator.platform === "iPad"
    ? window.open(url)
    : fetch(url).then((response) => {
        response.blob().then((blob) => {
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${fileName}.pdf`);

          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        });
      });
};

export const generatePDFThunk: TGeneratePDFThunkType = (params) => {
  return async (dispatch) => {
    try {
      dispatch(reviewShowApproveLoaderAction({ toggleLoader: true }));

      const { state } = JSON.parse(JSON.stringify(params));

      const response: any = await axios.post(
        pdfGeneration,
        JSON.stringify({
          scenarioId: params.scenarioId,
          state,
          pdfName: params.pdfName,
          pdfType: params.pdfType,
        }),
        {
          headers: {
            Authorization: `Bearer ${params.token}`,
            "Content-Type": "application/json",
          },
          params: {
            user_action: params.userAction,
          },
        }
      );

      const downloadFileName =
        params.pdfName === "AVM Report"
          ? `Property Report-${state.propertyAddress}`
          : params.pdfName;

      downloadPdf(response.data.payload.signedPdfUrl, downloadFileName);
      return dispatch(reviewShowApproveLoaderAction({ toggleLoader: false }));
    } catch (error) {
      dispatch(reviewShowApproveLoaderAction({ toggleLoader: false }));
      return dispatch(errorGenericErrorHandleAction(error.message));
    }
  };
};
