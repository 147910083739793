import { ILRAS, IPropertyArray } from "amm-objective-interface";
import React from "react";
import { connect } from "react-redux";
import PropertyCard from "./propertyCard";
import dummyImg from "../../images/photo-gallery-placeholder-image.svg";
import { objectiveSetStatisticalValuationMessageAction } from "../../store/actions/objectivePageActions/objectivePageActions";
import { ObjectiveSetStatisticalValuationMessageActionType } from "../../store/actions/objectivePageActions/objectivePageActionTypes";
import { TAppStateType } from "../../store/reducers/reducers";

interface StateProps {
  depositAmount: number;
  loanAmount: number;
  lras: ILRAS;
  properties: IPropertyArray[];
  cashOutAmount: number;
}

interface DispatchProps {
  objectiveSetStatisticalValuationMessageAction: ObjectiveSetStatisticalValuationMessageActionType;
}
type PropertySummaryProps = StateProps & DispatchProps;

export const PropertySummary: React.FC<PropertySummaryProps> = (props: PropertySummaryProps) => {
  const statisticalValuation = (property: IPropertyArray, index: number) => {
    /** This logic is replaced with new volt bank logic */

    // const lvr = props.lras && props.lras.lvr ? props.lras.lvr : 0;
    // const propertyTypePurchase = property.purchaseOrRefinance === "Purchase";
    // const refianceAndZeroCashOut =
    //   property.purchaseOrRefinance === "Refinance" && props.cashOutAmount === 0;
    // const confidenceNotLow = property.confidenceLevel !== "Low";
    // const refianceAndPurchaseValueEqualEstimatedValue =
    //   property.purchaseOrRefinance === "Refinance" &&
    //   property.propertyValue === property.estimatedValue;
    // const purchaseValueBetweenEstimateLowAndHigh =
    //   property.purchaseOrRefinance === "Purchase" &&
    //   property.propertyValue > property.estimateRangeLow &&
    //   property.propertyValue < property.estimateRangeHigh;
    // const numberOfBedroomsBathroomsCarparksAsPerIDS =
    //   property.bedroom === property.initialIDSBedrooms &&
    //   property.bathrooms === property.initialIDSBathrooms &&
    //   property.carparks === property.initialIDSCarparks;
    // const propertyCategory = property.propertyCategory;
    // const isNotSuburb = property.purchaseType !== "I'm still looking";

    // const option1 =
    //   props.loanAmount <= 750000 &&
    //   lvr <= 0.7 &&
    //   (propertyTypePurchase || refianceAndZeroCashOut) &&
    //   propertyCategory === 1 &&
    //   confidenceNotLow &&
    //   (refianceAndPurchaseValueEqualEstimatedValue || purchaseValueBetweenEstimateLowAndHigh) &&
    //   numberOfBedroomsBathroomsCarparksAsPerIDS &&
    //   isNotSuburb;

    // const option3 = propertyCategory === 4;

    // const option2 = !(option1 || option3);

    // if (option1) {
    //   props.objectiveSetStatisticalValuationMessageAction("firstOption", index);
    //   return "firstOption";
    // } else if (option2) {
    //   props.objectiveSetStatisticalValuationMessageAction("secondOption", index);
    //   return "secondOption";
    // } else if (option3) {
    //   props.objectiveSetStatisticalValuationMessageAction("thirdOption", index);
    //   return "thirdOption";
    // } else return "";
    const avmAcceptableLVR = process.env.REACT_APP_AVM_ACCEPTABLE_LVR ? parseFloat(process.env.REACT_APP_AVM_ACCEPTABLE_LVR) : 0.7;
    const avmAcceptableLoanAmount = process.env.REACT_APP_AVM_ACCEPTABLE_LOAN_AMOUNT ? parseInt(process.env.REACT_APP_AVM_ACCEPTABLE_LOAN_AMOUNT, undefined) : 750000;
    const lvr = props.lras && props.lras.lvr ? props.lras.lvr : 0;
    const isApartment = property.propertyType === "Apartment";
    const isHouse = property.propertyType === "House";
    const isApartmentAndMoreBedrooms = isApartment && property.bedroom > 1;
    const isHouseLandAreaValid = isHouse && property.landArea <= 20000 && property.landArea >= 250;
    const isLVRValid = lvr <= avmAcceptableLVR;
    const isLoanAmountValid = props.loanAmount <= avmAcceptableLoanAmount;
    const isFSDValid = property.percentFSD <= 15;
    const isPropertyCategoryValid =
      property.propertyCategory === 1;
    const isIDSNotReQueried =
      property.bedroom === property.initialIDSBedrooms &&
      property.bathrooms === property.initialIDSBathrooms &&
      property.carparks === property.initialIDSCarparks;
    const isApartmentAndNotHighDensity = isApartment && !property.highDensity;
    const isPropertyValueValid = property.propertyValue < 2 * property.medianPrice;
    const isPropertyNotFound = property.noPropertyFound && property.suburbPropertyAddress;
    const isAVMAccepted =
      isLoanAmountValid &&
      isLVRValid &&
      isFSDValid &&
      isPropertyCategoryValid &&
      isIDSNotReQueried &&
      (isHouseLandAreaValid || !isHouse) &&
      (isApartmentAndMoreBedrooms || !isApartment) &&
      (isApartmentAndNotHighDensity || !isApartment) &&
      isPropertyValueValid && !isPropertyNotFound;

    if (isAVMAccepted) {
      props.objectiveSetStatisticalValuationMessageAction("firstOption", index);
      return "firstOption";
    } else {
      props.objectiveSetStatisticalValuationMessageAction("secondOption", index);
      return "secondOption";
    }
  };

  return (
    <React.Fragment>
      <h3 className="md-text--theme-secondary">Property</h3>
      <div className="property-card-container">
        {props.properties.map((property, index) => (
          <PropertyCard
            key={index}
            address={property.propertyAddress}
            type={property.propertyType}
            isNotSuburb={
              property.purchaseType === "I've found my property" &&
              property.propertyNewOrOfThePlan === "No"
            }
            bathrooms={property.bathrooms}
            carparks={property.carparks}
            bedrooms={property.bedroom}
            imageValue={property.gallery.length > 0 ? property.gallery[0].original : dummyImg}
            propertyValue={property.propertyValue}
            valuation="Statistical valuation accepted"
            statisticalValuation={statisticalValuation(property, index)}
          />
        ))}
      </div>
    </React.Fragment>
  );
};

export const mapStateToProps = (state: TAppStateType) => {
  return {
    depositAmount: state.loan.depositAmount,
    loanAmount: state.loan.loanAmount,
    lras: state.help.lras,
    properties: state.objective.properties,
    cashOutAmount: state.loan.cashOutAmount,
  };
};

const mapDispatchToProps = { objectiveSetStatisticalValuationMessageAction };

export default connect(mapStateToProps, mapDispatchToProps)(PropertySummary);
