import {
  IObjectState,
  IPropertyArray,
  IPropertiesAutoSuggestionsList,
  ISuburbAutoSuggestionsList,
} from "amm-objective-interface";
import { ISplitAddress } from "amm-applicant-interface";

import { moduleConstant, propertyMaxValue } from "../../../constants";
import {
  ObjectiveAddressAutoSuggestAction,
  ObjectiveStateUpdateAction,
  ObjectiveRemovePropertyAction,
} from "../../actions/objectivePageActions/objectivePageActionTypes";

// export const initialSplitAddress = {
//   city_name: "",
//   country_code: "",
//   postcode: "",
//   state: "",
//   street_name: "",
//   street_number: "",
//   suburb: ""
// };

export const propertyDefaultValue: IPropertyArray = {
  statisticalValuationMessageOption: "",
  initialIDSBathrooms: 0,
  initialIDSBedrooms: 0,
  initialIDSCarparks: 0,
  bathrooms: 2,
  bathroomsInitial: 0,
  bedroom: 2,
  bedroomInitial: 0,
  carparks: 2,
  carparksInitial: 0,
  confidenceLevel: "Medium",
  estimateRangeHigh: 130000,
  estimateRangeLow: 70000,
  estimatedDate: "1999-12-12",
  estimatedValue: 100000,
  gallery: [],
  hasSuggestResults: true,
  loader: false,
  mapLat: 0,
  mapLng: 0,
  medianPrice: 0,
  name: "",
  placeId: "",
  propertiesAutoSuggestions: [],
  propertyAddress: "",
  propertyBeUsed: "Home",
  propertyCategory: 1,
  highDensity:false,
  percentFSD:0,
  landArea:0,
  propertyNewOrOfThePlan: "No",
  propertyType: "House",
  propertyValue: 0,
  purchaseOrRefinance: Boolean(process.env.REACT_APP_DISABLE_PURCHASE === "true") ? "Refinance" : "Purchase",
  purchaseType: "I've found my property",
  sliderMaxValue: 200000,
  splitAddress: {},
  suburb: "",
  suburbAutoSuggestions: [],
  weeklyRentEstimate: 0,
  suburbPropertyAddress: "",
  noPropertyFound: false
};

export const objectiveAddressAutoSuggest = (
  action: ObjectiveAddressAutoSuggestAction,
  properties: IPropertyArray[],
  state: IObjectState
) => {
  if (
    action &&
    action.module &&
    action.module.filter((currentModule) => Object.values(moduleConstant).includes(currentModule))
      .length === 0
  ) {
    return { ...state };
  }
  const propertyResults: any = action.propertyAutoSuggestions;
  if (propertyResults.length > 0) {
    properties[action.currentTab].hasSuggestResults = true;
  } else {
    properties[action.currentTab].hasSuggestResults = false;
  }
  if (action.filter === "suburb") {
    const result: ISuburbAutoSuggestionsList[] = propertyResults.map(
      (data: { place_id: number; address: string; split_address: ISplitAddress }) => ({
        placeId: data.place_id,
        primaryText: data.address,
        splitAddress: data.split_address,
        suburb: data.address,
      })
    );
    properties[action.currentTab].suburbAutoSuggestions = result;
  } else {
    const result: IPropertiesAutoSuggestionsList[] = propertyResults.map(
      (data: { propertyId: number; address: string; splitAddress: ISplitAddress }) => ({
        datavalue: data.propertyId,
        primaryText: data.address,
        splitAddress: data.splitAddress,
      })
    );
    properties[action.currentTab].propertiesAutoSuggestions = result;
  }
  return {
    ...state,
    properties,
  };
};

export const objectiveStateUpdate = (
  action: ObjectiveStateUpdateAction,
  properties: IPropertyArray[],
  state: IObjectState,
  activeTab: number
) => {
  if (
    action &&
    action.module &&
    action.module.filter((currentModule) => Object.values(moduleConstant).includes(currentModule))
      .length === 0
  ) {
    return { ...state };
  }
  if (action.stateName === "purchaseType") {
    if (action.stateValue === "I've found my property") {
      properties[activeTab].propertyNewOrOfThePlan = "No";
    } else {
      properties[activeTab].bedroom = 2;
    }
  }
  properties[activeTab][action.stateName] = action.stateValue;
  if (action.stateName === "bedroom") {
    properties[activeTab].bedroomInitial = action.stateValue as number;
  } else if (action.stateName === "bathrooms") {
    properties[activeTab].bathroomsInitial = action.stateValue as number;
  } else if (action.stateName === "carparks") {
    properties[activeTab].carparksInitial = action.stateValue as number;
  }
  if (properties[activeTab].propertyValue >= propertyMaxValue) {
    properties[activeTab].propertyValue = propertyMaxValue;
  } else if (properties[activeTab].propertyValue <= 0) {
    properties[activeTab].propertyValue = 0;
  }
  return {
    ...state,
    properties,
  };
};

export const objectiveCurrentTabReducer: (
  state: IObjectState,
  currentTab: number
) => IObjectState = (state, currentTab) => {
  return {
    ...state,
    currentTab,
  };
};

export const objectiveRemoveProperty = (
  action: ObjectiveRemovePropertyAction,
  properties: IPropertyArray[],
  state: IObjectState,
  activeTab: number
) => {
  if (
    action &&
    action.module &&
    action.module.filter((currentModule) => Object.values(moduleConstant).includes(currentModule))
      .length === 0
  ) {
    return { ...state };
  }
  if (properties.length > 1) {
    if (activeTab > 0) {
      activeTab = activeTab - 1;
    }
    properties.splice(action.removeIndex, 1);
  }
  return {
    ...state,
    currentTab: activeTab,
    properties,
  };
};
