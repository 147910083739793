import React from "react";
import { FontIcon } from "react-md";
import BathIcon from "../common/icons/bathIcon";
import BedIcon from "../common/icons/bedIcon";
import CarIcon from "../common/icons/carIcon";
import DollarIcon from "../common/icons/dollarIcon";
import HouseIcon from "../common/icons/houseIcon";
import { TickIcon } from "../common/icons/tickIcon";

interface OwnProps {
  address: string;
  type: string;
  isNotSuburb: boolean;
  bathrooms: number;
  carparks: number;
  bedrooms: number;
  imageValue: string;
  valuation: string;
  propertyValue: number;
  statisticalValuation: string;
}
type PropertyCardProps = OwnProps;

const PropertyCard: React.FC<PropertyCardProps> = (props: PropertyCardProps) => (
  <div className={`property-list`}>
    <figcaption>
      <h6 className="heading-6-width-border">
        {props.address ? props.address : "Property address"}
      </h6>
      <div className="list-table-layout">
        <span>
          <HouseIcon className="review-house-icon" />
          Type:
        </span>

        <span>{props.type}</span>

        <span>
          <BedIcon />
          Bedrooms:
        </span>

        <span>{props.bedrooms}</span>

        {props.isNotSuburb && (
          <>
            <span>
              <BathIcon />
              Bathrooms:
            </span>
            <span>{props.bathrooms}</span>

            <span>
              <CarIcon />
              Car spaces:
            </span>
            <span>{props.carparks}</span>
          </>
        )}

        <span>
          <DollarIcon className="review-dollar-icon" />
          Property value:
        </span>

        <span>${props.propertyValue.toLocaleString("en")}</span>
      </div>

      {/* @Todo show the message on condition basic, for now it is a static Ui element */}
      <div
        className={`response-message statistical-message ${
          props.statisticalValuation === "firstOption"
            ? "response-success"
            : props.statisticalValuation === "secondOption" ||
              props.statisticalValuation === "thirdOption"
            ? "response-warning"
            : props.statisticalValuation === "fourthOption"
            ? "response-error"
            : ""
        }`}
      >
        {props.statisticalValuation === "firstOption" && (
          <React.Fragment>
            <h6 className="text-bold review-page-bold-text">
              <TickIcon className={"statistical-valuation-success-tick"} width={35} height={35} />
              Statistical Valuation is acceptable for this property
            </h6>
            <p className="review-page-text">
              A statistical valuation, estimated using an automated valuation model (AVM),
              <br />
              is acceptable subject to eligibility criteria.
            </p>
          </React.Fragment>
        )}
        {(props.statisticalValuation === "secondOption" ||
          props.statisticalValuation === "thirdOption") && (
          <React.Fragment>
            {props.statisticalValuation === "secondOption" && (
              <h6 className="text-bold review-page-bold-text">
                <FontIcon className={"tick-icon-amber"}>check_circle_outline</FontIcon>
                Security property is subject to eligibility and acceptance criteria
              </h6>
            )}
            <p className="review-page-text">
              We’ll arrange for a qualified property valuer to conduct <br />a full valuation at a
              time convenient for the applicants.
            </p>
          </React.Fragment>
        )}
        {props.statisticalValuation === "thirdOption" && (
          <React.Fragment>
            <FontIcon className="error-block-icon">block</FontIcon>
            <h6 className="text-bold">This property is not an acceptable security property'</h6>
            <p>{/* Additional API responses here. */}</p>
          </React.Fragment>
        )}
      </div>
    </figcaption>
  </div>
);

export default PropertyCard;
