import { moduleConstant } from "../../../constants";
import {
  ERROR_CREDIT_CARD_INDICATOR,
  ERROR_FORM_TRACKER,
  ERROR_LOAD_ENTIRE_STATE,
  ERROR_SNACKBAR_CLOSE,
  ERROR_GENERIC_ERROR_HANDLE,
  ErrorGenericErrorHandleActionType,
  ErrorCreditCardIndicatorActionType,
  ErrorSnackbarCloseActionType,
  ErrorFormTrackerActionType,
  ErrorLoadEntireStateActionType,
  ErrorGenericErrorHandleAction,
} from "./errorActionType";
import { TAppStateType } from "../../reducers/reducers";

export const errorGenericErrorHandleAction: ErrorGenericErrorHandleActionType = (
  errorMessage: string
): ErrorGenericErrorHandleAction => {
  return {
    errorMessage,
    module: [moduleConstant.error],
    type: ERROR_GENERIC_ERROR_HANDLE,
  };
};

export const errorCreditCardIndicatorAction: ErrorCreditCardIndicatorActionType = (
  value: boolean
) => {
  return {
    module: [moduleConstant.error],
    type: ERROR_CREDIT_CARD_INDICATOR,
    value,
  };
};
export const errorSnackBarCloseAction: ErrorSnackbarCloseActionType = () => {
  return {
    module: [moduleConstant.error],
    type: ERROR_SNACKBAR_CLOSE,
  };
};

export const errorFormTrackerAction: ErrorFormTrackerActionType = (
  page: string,
  section: string,
  location: string,
  status: boolean,
  description?: string
) => {
  return {
    description,
    location,
    module: [moduleConstant.error],
    page,
    section,
    status,
    type: ERROR_FORM_TRACKER,
  };
};

export const errorLoadEntireStateAction: ErrorLoadEntireStateActionType = (
  state: TAppStateType["error"],
  isCashOutError: boolean
) => {
  return {
    module: [moduleConstant.error],
    state,
    type: ERROR_LOAD_ENTIRE_STATE,
    isCashOutError
  };
};
