import React from "react";
import { TextField } from "react-md";
import {
  arrowDown,
  arrowUp,
  invalidInputStringRegex,
  onlyCommaRegex,
  validZeroIntegerWithDecimalRegex,
} from "../../constants";

interface CustomInputFieldProps {
  className: string;
  content?: string;
  id: string;
  label?: string;
  max?: number;
  maxLength?: number;
  min: number;
  name?: string;
  onBlur?: () => void;
  onChange: (e: number) => void;
  onClick?: (e: React.MouseEvent) => void;
  type: "number" | "password" | "text" | "email" | "search" | "tel" | "url" | undefined;
  stateName?: string;
  shiftStep: number;
  showDollar?: boolean;
  stateValue?: number | string;
  step?: number;
  unit?: string;
  value: number | string;
  disabled?: boolean;
}
interface State {
  value: string | number;
}
export class CustomInputField extends React.Component<CustomInputFieldProps, State> {
  //@ts-ignore
  private inputRef: React.RefObject<any>;
  constructor(props: CustomInputFieldProps) {
    super(props);
    this.state = {
      value: !isNaN(this.props.value as number) ? this.props.value : "",
    };
    this.inputRef = React.createRef();
  }

  public componentWillReceiveProps(nextProps: { value: string | number; id: string }) {
    this.setState({
      value: isNaN(nextProps.value as number) ? "" : nextProps.value,
    });
  }

  public onChangeHandler = (e: string | number) => {
    let valueString = e as string;
    if (onlyCommaRegex.test(valueString)) {
      return;
    } else {
      valueString = valueString.replace(/[^\d.]/g, "");
      const valueInDecimal = parseFloat(valueString);
      this.setState(
        {
          value: validZeroIntegerWithDecimalRegex.test(valueString)
            ? valueString
            : valueString.replace(/^0+/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        },
        () => {
          this.cursorPosition();
          if (!invalidInputStringRegex.test(valueString)) {
            this.props.onChange(valueInDecimal);
          }
        }
      );
    }
  };
  public cursorPosition = () => {
    if (this.props.unit) {
      const el = document.getElementById(this.inputRef.current.props.id) as HTMLInputElement;
      if (el !== null) {
        let cursorPosition = el.value.length - this.props.unit.length;
        const selectionEnd = el.selectionEnd;
        if (selectionEnd !== null && selectionEnd <= cursorPosition) {
          cursorPosition = selectionEnd;
        }
        el.focus();
        el.setSelectionRange(cursorPosition, cursorPosition);
      }
    }
  };
  public keyDownHandler = (key: string, shiftkey: boolean) => {
    let value = this.props.value ? this.props.value : 0;
    let step = this.props.step ? this.props.step : 1;

    if (shiftkey && this.props.shiftStep) {
      step = this.props.shiftStep;
    }
    if (key === arrowUp || key === arrowDown) {
      if (key === arrowUp) {
        value = (value as number) + step;
      } else if (key === arrowDown) {
        value = (value as number) - step;
      }
      if (this.props.min >= 0 && (value <= this.props.min || value <= 0)) {
        value = this.props.min;
      } else if (this.props.max && value >= this.props.max) {
        value = this.props.max;
      }
      this.onChangeHandler(value.toLocaleString());
    }
  };

  public onBlurHandler = () => {
    let value = this.props.value ? this.props.value : 0;
    const minVal = this.props.min ? this.props.min : 0;
    if (!value || value <= minVal || value <= 0) {
      value = this.props.min;
    } else if (this.props.max && value && value >= this.props.max) {
      value = this.props.max;
    }
    this.onChangeHandler(value.toLocaleString());
  };
  public onClick = (e: React.MouseEvent) => {
    if (this.props.onClick) {
      this.props.onClick(e);
    }
    this.cursorPosition();
  };
  public render() {
    return (
      <form className="disable-tag" autoComplete="off" onSubmit={(e) => e.preventDefault()}>
        <TextField
          onClick={(e) => this.onClick(e)}
          id={this.props.id}
          name={this.props.name}
          label={this.props.label}
          type={this.props.type}
          ref={this.inputRef}
          onBlur={() => (this.props.onBlur ? this.props.onBlur() : this.onBlurHandler())}
          value={`${this.props.showDollar ? "$ " : ""}${
            this.state.value ? (this.state.value as number).toLocaleString("en") : this.state.value
          }${this.props.unit ? this.props.unit : ""}`}
          className={this.props.className}
          onKeyDown={(e) => {
            this.keyDownHandler(e.key, e.shiftKey);
          }}
          onChange={(e) =>
            this.props.maxLength
              ? `${e}`.length <= this.props.maxLength
                ? this.onChangeHandler(e)
                : {}
              : this.onChangeHandler(e)
          }
          disabled={this.props.disabled}
        />
      </form>
    );
  }
}
