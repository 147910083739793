import { ISplitAddress } from "amm-applicant-interface";
import {
  IPropertyAutoSuggest,
  ISuburbAutoSuggest,
  IPropertyArray,
  IGallery,
  IObjectState,
} from "amm-objective-interface";

export const OBJECTIVE_CURRENT_TAB = "OBJECTIVE_CURRENT_TAB";
export const OBJECTIVE_ADD_NEW_PROPERTY = "OBJECTIVE_ADD_NEW_PROPERTY";
export const OBJECTIVE_REMOVE_PROPERTY = "OBJECTIVE_REMOVE_PROPERTY";
export const OBJECTIVE_STATE_UPDATE = "OBJECTIVE_STATE_UPDATE";
export const OBJECTIVE_ADDRESS_AUTO_SUGGEST = "OBJECTIVE_ADDRESS_AUTO_SUGGEST";
export const OBJECTIVE_CLEAR_ADDRESS_RESULT = "OBJECTIVE_CLEAR_ADDRESS_RESULT";
export const OBJECTIVE_CLEAR_ADDRESS_FIELD = "OBJECTIVE_CLEAR_ADDRESS_FIELD";
export const OBJECTIVE_ADDRESS_LOADER = "OBJECTIVE_ADDRESS_LOADER";
export const OBJECTIVE_GET_PROPERTY = "OBJECTIVE_GET_PROPERTY";
export const OBJECTIVE_GET_IMAGE_GALLERY = "OBJECTIVE_GET_IMAGE_GALLERY";
export const OBJECTIVE_LOAD_STATE = "OBJECTIVE_LOAD_STATE";
export const OBJECTIVE_PROPERTY_ESTIMATE_RESULT = "OBJECTIVE_PROPERTY_ESTIMATE_RESULT";
export const OBJECTIVE_SET_SUBURB_CHECKED_INDICATOR = "OBJECTIVE_SET_SUBURB_CHECKED_INDICATOR";
export const OBJECTIVE_INITIAL_IDS_VALUES = "OBJECTIVE_INITIAL_IDS_VALUES";
export const OBJECTIVE_SET_STATISTICAL_VALUATION_MESSAGE =
  "OBJECTIVE_SET_STATISTICAL_VALUATION_MESSAGE";
export const OBJECTIVE_PROPERTY_REPORT_PDF = "OBJECTIVE_PROPERTY_REPORT_PDF";

export interface ObjectiveSetStatisticalValuationMessageAction {
  message: string;
  currentTab: number;
  type: "OBJECTIVE_SET_STATISTICAL_VALUATION_MESSAGE";
  module: string[];
}
export interface ObjectiveInitialIDSValuesAction {
  bedrooms: number;
  carparks: number;
  bathrooms: number;
  currentTab: number;
  type: "OBJECTIVE_INITIAL_IDS_VALUES";
  module: string[];
}
export interface ObjectiveCurrentTabAction {
  currentTab: number;
  module: string[];
  type: "OBJECTIVE_CURRENT_TAB";
}

export interface ObjectiveAddNewPropertyAction {
  module: string[];
  type: "OBJECTIVE_ADD_NEW_PROPERTY";
}

export interface ObjectiveSetSuburbCheckedIndicatorAction {
  module: string[];
  objectiveInvalidArray: boolean[];
  type: "OBJECTIVE_SET_SUBURB_CHECKED_INDICATOR";
}

export interface ObjectiveRemovePropertyAction {
  module: string[];
  removeIndex: number;
  type: "OBJECTIVE_REMOVE_PROPERTY";
}

export interface ObjectiveStateUpdateAction {
  module: string[];
  stateName: string;
  stateValue: React.ReactNode | ISplitAddress;
  type: "OBJECTIVE_STATE_UPDATE";
}

export interface ObjectiveAddressAutoSuggestAction {
  module: string[];
  currentTab: number;
  propertyAutoSuggestions: IPropertyAutoSuggest[] | ISuburbAutoSuggest[];
  filter: string;
  type: "OBJECTIVE_ADDRESS_AUTO_SUGGEST";
}

export interface ObjectiveClearAddressResultAction {
  module: string[];
  type: "OBJECTIVE_CLEAR_ADDRESS_RESULT";
  calledFrom?: string;
}

export interface ObjectiveClearAddressFieldAction {
  module: string[];
  type: "OBJECTIVE_CLEAR_ADDRESS_FIELD";
  calledFrom?: string;
}

export interface ObjectiveGetPropertyAction {
  module: string[];
  currentTab: number;
  property: IPropertyArray;
  type: "OBJECTIVE_GET_PROPERTY";
}

export interface ObjectiveGetImageGalleryAction {
  module: string[];
  currentTab: number;
  gallery: IGallery[];
  type: "OBJECTIVE_GET_IMAGE_GALLERY";
}

export interface ObjectiveAddressLoaderAction {
  module: string[];
  currentTab: number;
  loader: boolean;
  type: "OBJECTIVE_ADDRESS_LOADER";
}

export interface ObjectivePeropertyEstimateResultAction {
  module: string[];
  propertyEstimateResult: boolean;
  type: "OBJECTIVE_PROPERTY_ESTIMATE_RESULT";
}

export interface ObjectiveLoadStateAction {
  module: string[];
  state: IObjectState;
  type: "OBJECTIVE_LOAD_STATE";
}

export type ObjectiveActions =
  | ObjectiveAddNewPropertyAction
  | ObjectiveAddressAutoSuggestAction
  | ObjectiveAddressLoaderAction
  | ObjectiveClearAddressFieldAction
  | ObjectiveClearAddressResultAction
  | ObjectiveCurrentTabAction
  | ObjectiveLoadStateAction
  | ObjectivePeropertyEstimateResultAction
  | ObjectiveRemovePropertyAction
  | ObjectiveSetSuburbCheckedIndicatorAction
  | ObjectiveGetPropertyAction
  | ObjectiveGetImageGalleryAction
  | ObjectiveStateUpdateAction
  | ObjectiveInitialIDSValuesAction
  | ObjectiveSetStatisticalValuationMessageAction;

export type ObjectiveSetStatisticalValuationMessageActionType = (
  message: string,
  currentTab: number
) => ObjectiveSetStatisticalValuationMessageAction;

export type ObjectiveInitialIDSValuesActionType = (
  bathrooms: number,
  bedrooms: number,
  carpakrs: number,
  currentTab: number
) => ObjectiveInitialIDSValuesAction;

export type ObjectiveCurrentTabActionType = (currentTab: number) => ObjectiveCurrentTabAction;

export type ObjectiveSetSuburbCheckedIndicatorActionType = (
  objectiveInvalidArray: boolean[]
) => ObjectiveSetSuburbCheckedIndicatorAction;

export type ObjectiveAddNewPropertyActionType = () => ObjectiveAddNewPropertyAction;

export type ObjectiveRemovePropertyActionType = (index: number) => ObjectiveRemovePropertyAction;

export type ObjectiveStateUpdateActionType = (
  stateName: string,
  stateValue: React.ReactNode | ISplitAddress
) => ObjectiveStateUpdateAction;

export type ObjectiveAddressAutoSuggestActionType = (
  currentTab: number,
  filter: string,
  propertyAutoSuggestions: IPropertyAutoSuggest[] | ISuburbAutoSuggest[]
) => ObjectiveAddressAutoSuggestAction;

export type ObjectiveClearAddressResultActionType = (
  calledFrom?: string
) => ObjectiveClearAddressResultAction;

export type ObjectiveClearAddressFieldActionType = (
  calledFrom?: string
) => ObjectiveClearAddressFieldAction;

export type ObjectiveGetPropertyActionType = (
  property: IPropertyArray,
  currentTab: number
) => ObjectiveGetPropertyAction;

export type ObjectiveGetImageGalleryActionType = (
  gallery: IGallery[],
  currentTab: number
) => ObjectiveGetImageGalleryAction;

export type ObjectiveAddressLoaderActionType = (
  loader: boolean,
  currentTab: number
) => ObjectiveAddressLoaderAction;

export type ObjectivePropertyEstimateResultActionType = (
  propertyEstimateResult: boolean
) => ObjectivePeropertyEstimateResultAction;

export type ObjectiveLoadStateActionType = (state: IObjectState) => ObjectiveLoadStateAction;
