import { moduleConstant } from "../../../constants";
import {
  HELP_STATE_UPDATE,
  HELP_SET_AFFORDABILITY_VISIT_COUNT,
  HELP_SAVE_LRAS_ACTION,
  HELP_SHOW_REVIEW_SCREEN,
  HELP_SIDEBAR_ANIMATION_CLASS,
  HelpStateUpdateActionType,
  HelpShowReviewScreenActionType,
  HelpSetAffordabilityVisitCountActionType,
  HelpSidebarAnimationClassActionType,
  HelpSaveLRASActionType,
  HelpLoadEntireStateActionType,
  HELP_LOAD_ENTIRE_STATE,
  HelpFundsToCompleteActionType,
  HELP_FUNDS_TO_COMPLETE,
  HelpFooterProgressWidthActionType,
  HELP_FOOTER_PROGRESS_WIDTH,
  HELP_MENU_OPEN,
  HelpMenuOpenActionType,
  HelpLRASMetricValidActionType,
  HELP_LRAS_METRIC_VALID,
} from "./helpActionType";
import { ILRAS } from "amm-objective-interface";
import { TAppStateType } from "../../reducers/reducers";

export const helpStateUpdateAction: HelpStateUpdateActionType = (responseObject, isSubheading) => {
  return {
    isSubheading,
    module: [moduleConstant.help],
    responseObject,
    type: HELP_STATE_UPDATE,
  };
};

export const helpMenuOpenAction: HelpMenuOpenActionType = (menuOpen) => {
  return {
    module: [moduleConstant.help],
    type: HELP_MENU_OPEN,
    menuOpen,
  };
};
export const helpShowReviewScreenAction: HelpShowReviewScreenActionType = (
  showReviewScreen: boolean
) => {
  return {
    module: [moduleConstant.help],
    showReviewScreen,
    type: HELP_SHOW_REVIEW_SCREEN,
  };
};

export const helpFooterProgressWidthAction: HelpFooterProgressWidthActionType = (width: number) => {
  return {
    module: [moduleConstant.help],
    width,
    type: HELP_FOOTER_PROGRESS_WIDTH,
  };
};


export const helpSetAffordabilityVisitCountAction: HelpSetAffordabilityVisitCountActionType = (
  showAffordabilityScreen
) => {
  return {
    module: [moduleConstant.help],
    type: HELP_SET_AFFORDABILITY_VISIT_COUNT,
    showAffordabilityScreen,
  };
};

export const helpSidebarAnimationClassAction: HelpSidebarAnimationClassActionType = (
  className,
  count
) => {
  return {
    className,
    count,
    module: [moduleConstant.help],
    type: HELP_SIDEBAR_ANIMATION_CLASS,
  };
};

export const helpSaveLRASAction: HelpSaveLRASActionType = (data) => {
  return {
    data,
    type: HELP_SAVE_LRAS_ACTION,
    module: [moduleConstant.help],
  };
};

export const helpLoadEntireStateAction: HelpLoadEntireStateActionType = (
  state: TAppStateType["help"]
) => {
  return {
    module: [moduleConstant.help],
    state,
    type: HELP_LOAD_ENTIRE_STATE,
  };
};

export const helpFundsToCompleteAction: HelpFundsToCompleteActionType = (
  fundsToCompleteCall,
  isCached
) => {
  return {
    module: [moduleConstant.help],
    fundsToCompleteCall,
    type: HELP_FUNDS_TO_COMPLETE,
    isCached,
  };
};

export const helpLrasMetricValidAction: HelpLRASMetricValidActionType = (lrasMetrics: ILRAS) => {
  return {
    module: [moduleConstant.help],
    lrasMetrics,
    type: HELP_LRAS_METRIC_VALID,
  };
};


