export const BRANDING_CSS_UPDATE = "BRANDING_CSS_UPDATE";
export const BRANDING_ADD_BRAND_NAME = "BRANDING_ADD_BRAND_NAME";

export interface BrandingCssUpdateAction {
  module: string[];
  css: string;
  brandName: string;
  type: "BRANDING_CSS_UPDATE";
}

export interface BrandingAddBrandNameAction {
  module: string[];
  brandName: string;
  type: "BRANDING_ADD_BRAND_NAME";
}

export type BrandingActions = BrandingCssUpdateAction | BrandingAddBrandNameAction;

export type BrandingCssUpdateActionType = (
  css: string,
  brandName: string
) => BrandingCssUpdateAction;

export type BrandingAddBrandNameActionType = (brandName: string) => BrandingAddBrandNameAction;
