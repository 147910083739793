import { Reducer } from "redux";
import { moduleConstant } from "../../../constants";
import { deepCopyObject } from "../../../utility";
import { LOGIN_USER_LOGOUT, LoginUserLogoutAction } from "../../actions/login/loginActionType";
import {
  TRIGGER_OPEN_MY_SCENARIOS_DASHBOARD,
  TRIGGER_OPEN_SCENARIO,
  TRIGGER_OPEN_USER_SCENARIOS_DASHBOARD,
  TRIGGER_PROFILE_LOAD,
  TriggerActions,
} from "../../actions/triggerActions/triggerActionType";

export interface ITriggerStateInterface {
  myScenarioDashboard: number;
  openScenario: number;
  profile: number;
  userIdForScenarioDashboard: number;
  userScenarioDashboard: number;
  [key: string]: number | string;
}

export interface ITriggerActionInterface {
  [key: string]: any;
}

export const initialState: ITriggerStateInterface = {
  myScenarioDashboard: 0,
  openScenario: 0,
  profile: 0,
  userIdForScenarioDashboard: 0,
  userScenarioDashboard: 0,
};

const triggerReducer: Reducer<ITriggerStateInterface, TriggerActions | LoginUserLogoutAction> = (
  state: ITriggerStateInterface = deepCopyObject(initialState),
  action: TriggerActions | LoginUserLogoutAction
) => {
  if (
    action &&
    action.module &&
    action.module.filter((currentModule) => Object.values(moduleConstant).includes(currentModule))
      .length === 0
  ) {
    return { ...state };
  }

  const currentState: ITriggerStateInterface = JSON.parse(JSON.stringify(state));

  if (
    action &&
    action.module &&
    action.module.filter((currentModule) => Object.values(moduleConstant).includes(currentModule))
      .length === 0
  ) {
    return { ...state };
  }

  switch (action.type) {
    case TRIGGER_PROFILE_LOAD:
      const profile = Math.random();
      return { ...currentState, profile };
    case TRIGGER_OPEN_SCENARIO:
      const openScenario = Math.random();
      return { ...currentState, openScenario };
    case TRIGGER_OPEN_MY_SCENARIOS_DASHBOARD:
      const myScenarioDashboard = Math.random();
      return { ...currentState, myScenarioDashboard };
    case TRIGGER_OPEN_USER_SCENARIOS_DASHBOARD:
      const userScenarioDashboard = Math.random();
      const userIdForScenarioDashboard = action.userId;
      return { ...currentState, userScenarioDashboard, userIdForScenarioDashboard };
    case LOGIN_USER_LOGOUT:
      const tempState = deepCopyObject(initialState);
      tempState.profile = currentState.profile;
      return { ...tempState };
    default:
      return { ...currentState };
  }
};

export default triggerReducer;
