import { IRetrieveBankData } from "amm-applicant-interface";
import axios, { AxiosResponse } from "axios";
import { provisoDataResponse, provisoPersonalInfoRequest } from "../../../constants";
import {
  applicantHideLoaderAction,
  applicantSetProvisoDataAction,
  applicantSetProvisoDataIsNewAction,
  applicantShowLoaderAction,
} from "../../actions/applicantActions/applicantActions";
import { errorGenericErrorHandleAction } from "../../actions/errorActions/errorAction";
import { affordabilityGetProvisoInfoAction } from "../../../constants/userActions";
import {
  ApplicantShowLoaderAction,
  ApplicantSetProvisioDataAction,
  ApplicantHideLoaderAction,
  ApplicantSetProvisoDataIsNewAction,
} from "../../actions/applicantActions/applicantActionTypes";
import { ErrorGenericErrorHandleAction } from "../../actions/errorActions/errorActionType";
import { ThunkAction } from "redux-thunk";
import { Action } from "redux";

interface IGetProvisoDataThunkParams {
  retrieveBankData: IRetrieveBankData[];
  provisoReferralCode: string;
  token: string;
  index: number;
  calledFrom?: string;
}

const checkProvisoData = async (
  //@ts-ignore
  response,
  dispatch: (arg: ErrorGenericErrorHandleAction | ApplicantHideLoaderAction) => void,
  index: number,
  childIndex: number,
  token: string,
  calledFrom?: string
) => {
  const search = provisoDataResponse;
  if (response.proviso_data) {
    !calledFrom && dispatch(applicantHideLoaderAction(index, childIndex));
    return response.proviso_data;
  }
  if (response !== "No data available.") {
    const parameters = {
      document_id: response.documentId,
      referrer_code: response.referrerCode,
      user_action: affordabilityGetProvisoInfoAction[0],
    };
    return axios
      .get(search, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: parameters,
      })
      .then((res: any) => {
        if (!res.data || (res.data.payload && !res.data.payload.proviso_data)) {
          checkProvisoData(parameters, dispatch, index, childIndex, token);
        } else {
          !calledFrom && dispatch(applicantHideLoaderAction(index, childIndex));
          return res.data.payload.proviso_data;
        }
      })
      .catch((error) => {
        !calledFrom && dispatch(applicantHideLoaderAction(index, childIndex));
        if (error && error.response && error.response.data && error.response.data.message) {
          return dispatch(errorGenericErrorHandleAction(error.response.data.message));
        } else {
          return dispatch(errorGenericErrorHandleAction(error.message));
        }
      });
  } else {
    return "No data available.";
  }
};

export type TGetProvisoDataThunkType = (
  params: IGetProvisoDataThunkParams
) => ThunkAction<any, any, any, Action>;

export const getProvisoDataThunk: TGetProvisoDataThunkType = (
  params
): ThunkAction<any, any, any, Action> => {
  const search = provisoPersonalInfoRequest;

  return (
    dispatch: (
      arg:
        | ApplicantShowLoaderAction
        | ErrorGenericErrorHandleAction
        | ApplicantSetProvisioDataAction
        | ApplicantHideLoaderAction
        | ApplicantSetProvisoDataIsNewAction
    ) => void
  ) => {
    params.retrieveBankData.forEach(async (row, key) => {
      if (row.isNew) {
        !params.calledFrom && dispatch(applicantShowLoaderAction(params.index, key));
        if (params.provisoReferralCode && row.bankDocumentId) {
          const parameters = {
            document_id: row.bankDocumentId,
            referral_code: params.provisoReferralCode,
            user_action: affordabilityGetProvisoInfoAction[0],
          };
          await axios
            .get(search, {
              headers: {
                Authorization: `Bearer ${params.token}`,
              },
              params: parameters,
            })
            .then((res: AxiosResponse<any>) => {
              return res.data.payload ? res.data.payload : "No data available.";
            })
            .then(async (res: string) => {
              const data = await checkProvisoData(
                res,
                dispatch,
                params.index,
                key,
                params.token,
                params.calledFrom
              );
              if (data !== "No data available.") {
                if (!params.calledFrom) {
                  dispatch(
                    applicantSetProvisoDataAction(
                      data,
                      params.index,
                      `${row.bankDocumentId}_${params.provisoReferralCode}`,
                      key
                    )
                  );
                }
              } else {
                return dispatch(errorGenericErrorHandleAction("No data available."));
              }
            })
            .catch((error) => {
              !params.calledFrom ? dispatch(applicantHideLoaderAction(params.index, key)) : "";
              if (error && error.response && error.response.data && error.response.data.message) {
                dispatch(applicantSetProvisoDataIsNewAction(false, key));
                return dispatch(
                  errorGenericErrorHandleAction(
                    `${error.response.data.message} - ${row.bankDocumentId}`
                  )
                );
              } else if (
                error.message ===
                "undefined is not iterable (cannot read property Symbol(Symbol.iterator))"
              ) {
                return;
              } else {
                return dispatch(errorGenericErrorHandleAction(error.message));
              }
            });
        } else if (!params.calledFrom) {
          return dispatch(
            errorGenericErrorHandleAction("Document Id or referral code can't be empty")
          );
        }
      }
    });
  };
};
