import { ILRAS, ILRASOutput } from "amm-objective-interface";

// check for the score to be less than or equal to 300
const isLRASScoreValid = (lrasOutput: ILRASOutput) =>
  lrasOutput.mortgageScore && Math.round(lrasOutput.mortgageScore) >= 700;

// check for the lvr to be less than or equal to 95
const isLVRValid = (lras: ILRAS) =>
  (lras.lvr ? lras.lvr : 0) <= parseFloat(process.env.REACT_APP_LVR_LIMIT as string);

// check for nsr to be greater than or equal to 101
const isNSRValid = (lras: ILRAS) => (lras.nsr ? lras.nsr : 0) * 100 >= 101;

// check for debtToIncomePercentage to be less than equal to 10
const isDebtToIncomePercentageValid = (lras: ILRAS) =>
  (lras.loanMetrics.debtToIncomePercentage ? lras.loanMetrics.debtToIncomePercentage : 0) <= 8;

export const isLRASMetricValid = (lras: ILRAS): boolean => {
  // check if all the sliders in the sidebar are green

  return Boolean(
    isLRASScoreValid(lras.lrasOutputs) &&
      isLVRValid(lras) &&
      isNSRValid(lras) &&
      isDebtToIncomePercentageValid(lras)
  );
};
