import { IHelpLinksInterface } from "../../reducers/helpReducers/helpReducer";
import { ILRAS } from "amm-objective-interface";
import { TAppStateType } from "../../reducers/reducers";
export const HELP_STATE_UPDATE = "HELP_STATE_UPDATE";
export const HELP_SHOW_REVIEW_SCREEN = "HELP_SHOW_REVIEW_SCREEN";
export const HELP_SIDEBAR_ANIMATION_CLASS = "HELP_SIDEBAR_ANIMATION_CLASS";
export const HELP_SIDEBAR_CLASS_NAME = "HELP_SIDEBAR_CLASS_NAME";
export const HELP_SAVE_LRAS_ACTION = "HELP_SAVE_LRAS_ACTION";
export const HELP_SET_AFFORDABILITY_VISIT_COUNT = "HELP_SET_AFFORDABILITY_VISIT_COUNT";
export const HELP_LOAD_ENTIRE_STATE = "HELP_LOAD_ENTIRE_STATE";
export const HELP_FUNDS_TO_COMPLETE = "HELP_FUNDS_TO_COMPLETE";
export const HELP_FOOTER_PROGRESS_WIDTH = "HELP_FOOTER_PROGRESS_WIDTH";
export const HELP_MENU_OPEN = "HELP_MENU_OPEN";
export const HELP_IS_REVIEW_APPROVED = "HELP_IS_REVIEW_APPROVED";
export const HELP_LRAS_METRIC_VALID = "HELP_LRAS_METRIC_VALID";
export const HELP_OPEN_ADD_NOTE_MODAL = "HELP_OPEN_ADD_NOTE_MODAL";

export interface HelpShowReviewScreenAction {
  module: string[];
  showReviewScreen: boolean;
  type: "HELP_SHOW_REVIEW_SCREEN";
}

export interface HelpSidebarAnimationClassAction {
  module: string[];
  count: number;
  className: string;
  type: "HELP_SIDEBAR_ANIMATION_CLASS";
}

export interface HelpFooterProgressWidthAction {
  module: string[];
  width: number;
  type: "HELP_FOOTER_PROGRESS_WIDTH";
}


export interface HelpMenuOpenAction {
  module: string[];
  type: "HELP_MENU_OPEN";
  menuOpen: boolean;
}

export interface HelpLoadEntireStateAction {
  module: string[];
  state: TAppStateType["help"];
  type: "HELP_LOAD_ENTIRE_STATE";
}
interface ResponseObject {
  helpSectionHeading: string;
  helpSectionContent: string;
  helpSectionLinks: IHelpLinksInterface[];
  helpSubcontent: string;
  helpSubheading: string;
}
export interface HelpStateUpdateAction {
  isSubheading: boolean;
  module: string[];
  responseObject: ResponseObject;
  type: "HELP_STATE_UPDATE";
}
export interface HelpSetAffordabilityVisitCountAction {
  module: string[];
  type: "HELP_SET_AFFORDABILITY_VISIT_COUNT";
  showAffordabilityScreen: boolean;
}

export interface HelpSaveLRASAction {
  data: ILRAS;
  type: "HELP_SAVE_LRAS_ACTION";
  module: string[];
}

export interface HelpFundsToCompleteAction {
  fundsToCompleteCall: boolean;
  isCached: boolean;
  type: "HELP_FUNDS_TO_COMPLETE";
  module: string[];
}

export interface HelpLRASMetricValidAction {
  lrasMetrics: ILRAS;
  type: "HELP_LRAS_METRIC_VALID";
  module: string[];
}

export type HelpActions =
  | HelpStateUpdateAction
  | HelpSetAffordabilityVisitCountAction
  | HelpShowReviewScreenAction
  | HelpSidebarAnimationClassAction
  | HelpShowReviewScreenAction
  | HelpSaveLRASAction
  | HelpLoadEntireStateAction
  | HelpFooterProgressWidthAction
  | HelpMenuOpenAction
  | HelpFundsToCompleteAction
  | HelpLRASMetricValidAction;

export type HelpLRASMetricValidActionType = (lrasMetrics: ILRAS) => HelpLRASMetricValidAction;
export type HelpFundsToCompleteActionType = (
  fundsToCompleteCall: boolean,
  isCached: boolean
) => HelpFundsToCompleteAction;
export type HelpShowReviewScreenActionType = (
  showReviewScreen: boolean
) => HelpShowReviewScreenAction;

export type HelpLoadEntireStateActionType = (
  state: TAppStateType["help"]
) => HelpLoadEntireStateAction;

export type HelpStateUpdateActionType = (
  responseObject: ResponseObject,
  isSubheading: boolean
) => HelpStateUpdateAction;

export type HelpSetAffordabilityVisitCountActionType = (
  showAffordabilityScreen: boolean
) => HelpSetAffordabilityVisitCountAction;


export type HelpFooterProgressWidthActionType = (width: number) => HelpFooterProgressWidthAction;

export type HelpMenuOpenActionType = (menuOpen: boolean) => HelpMenuOpenAction;

export type HelpSaveLRASActionType = (data: ILRAS) => HelpSaveLRASAction;

export type HelpSidebarAnimationClassActionType = (
  className: string,
  count: number
) => HelpSidebarAnimationClassAction;
