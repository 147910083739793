import React from "react";
import Chart from "./pieChart";

const IncomeExpense: React.FC = () => {
  return (
    <React.Fragment>
      <h5 className="income-expense-heading">
        <strong>Income / expenses</strong>
      </h5>
      <Chart />
    </React.Fragment>
  );
};

export default IncomeExpense;
