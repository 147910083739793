import React, { ReactText } from "react";
import { Paper } from "react-md";
import { getAlgoliaRecordsThunk } from "../../store/thunk/helpSectionThunk/getAlgoliaRecordsThunk";
import { connect } from "react-redux";
import QuestionMarkIcon from "../common/icons/questionMarkIcon";
import { TAppStateType } from "../../store/reducers/reducers";

interface OwnProps {
  headingClass?: string;
  heading: string | ReactText | number;
  className?: string;
  smallValue?: string;
  content: string;
  showComponent: boolean;
  showBanner?: boolean;
}

interface DispatchProps {
  getAlgoliaRecordsThunk: (clickId: string, subheading?: boolean) => void;
}

type RepaymentCardProps = OwnProps & DispatchProps;

const RepaymentCard: React.FC<RepaymentCardProps> = (props: RepaymentCardProps) => {
  const [shouldRender, setRender] = React.useState(props.showComponent);

  React.useEffect(() => {
    if (props.showComponent) {
      setRender(true);
    }
  }, [props.showComponent]);

  const onAnimationEnd = () => {
    if (!props.showComponent) {
      setRender(false);
    }
  };

  return shouldRender ? (
    <Paper
      onAnimationEnd={onAnimationEnd}
      className={`card-box ${props.className ? props.className : ""}`}
      style={{ animation: `${props.showComponent ? "fadeIn" : "fadeOut"} 1s ease 0s forwards` }}
    >
      <h4 className="repayment-heading">
        <strong className={props.headingClass ? props.headingClass : ""}>
          {props.heading}
          <span>{props.smallValue}</span>
        </strong>
        <small>
          {props.showBanner && <span className="review-promo">PROMOTION</span>}

          {props.content}
          {props.content === "Comparison Rate" && (
            <span onClick={() => props.getAlgoliaRecordsThunk("10025")}>
              {" "}
              <QuestionMarkIcon className="comparison-question-mark" />
            </span>
          )}
        </small>
      </h4>
    </Paper>
  ) : null;
};

export const mapStateToProps = (state: TAppStateType) => {
  return {};
};

export const mapDispatchToProps = {
  getAlgoliaRecordsThunk,
};

export default connect(mapStateToProps, mapDispatchToProps)(RepaymentCard);
