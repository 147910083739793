import gql from "graphql-tag";
export const BROKERS_QUERY = gql`
  query getAllBrokers($userAction: GetAllBrokersUserAction) {
    getAllBrokers(userAction: $userAction) {
      id
      firstName
      lastName
      title
      email
      mobile
      phone
      status
      role
      noOfScenarios
    }
  }
`;
export const ONE_BROKER_QUERY = gql`
  query userById($id: Int!, $userAction: UserByIdUserAction) {
    userById(id: $id, userAction: $userAction) {
      id
      firstName
      lastName
      middleName
      lastLogin
      createdAt
      noOfScenarios
      title
      mobile
      phone
      brokerPartyID
      originatorPartyID
      organisationLegalName
      organisationFullName
      provisoReferralCode
      provisoSmsLink
      status
      cognitoId
      email
      roleId
      uniqueUserID
      originatorID
      userNotifications {
        notification_id
        email_enabled
        sms_enabled
        notification {
          name
        }
      }
    }
  }
`;
export const ADD_OR_EDIT_BROKER = gql`
  mutation addOrEditBroker($input: BrokerType!, $userAction: AddOrEditBrokerUserAction) {
    addOrEditBroker(input: $input, userAction: $userAction) {
      id
      message
    }
  }
`;
export const SAVE_CAPITAL_CREDENTIALS = gql`
  mutation saveCapitalCredentials($input: CapitalType!, $userAction: SaveCapitalUserAction) {
    saveCapitalCredentials(input: $input, userAction: $userAction) {
      id
    }
  }
`;
export const SAVE_NOTIFICATION_DETAILS = gql`
  mutation saveNotificationDetails(
    $input: NotificationsType!
    $userAction: SaveNotificationUserAction
  ) {
    saveNotificationDetails(input: $input, userAction: $userAction) {
      id
    }
  }
`;
export const SAVE_PROVISO_CREDENTIALS = gql`
  mutation saveProvisoCredentials($input: ProvisoType!, $userAction: SaveProvisoUserAction) {
    saveProvisoCredentials(input: $input, userAction: $userAction) {
      id
    }
  }
`;
export const UPDATE_MOC_BP_PARTY_ID = gql`
  mutation updateMoCBPPartyID($input: MoCBPPartyType!, $userAction: UpdateMoCBPPartyIDAction) {
    updateMoCBPPartyID(input: $input, userAction: $userAction) {
      id
    }
  }
`;
export const UPDATE_LAST_LOGIN = gql`
  mutation updateLastLogin($input: LastLoginType!, $userAction: UpdateLastLoginUserAction) {
    updateLastLogin(input: $input, userAction: $userAction) {
      id
    }
  }
`;
export const GET_FUNDS_TO_COMPLETE_DUTY = gql`
  query($user_action: String, $properties: Any, $is_first_home: Boolean!, $loan_amount: Number!) {
    fundsToComplete(
      properties: $properties
      is_first_home: $is_first_home
      loan_amount: $loan_amount
      user_action: $user_action
    ) @rest(type: "get", path: "gbst/funds_to_complete?{args}") {
      payload
    }
  }
`;
export const DELETE_UPLOAD_BY_ID = gql`
  mutation deleteUploadById($id: Int!, $scenario_id: Int!, $userAction: DeleteUserByIdUserAction) {
    deleteUploadById(id: $id, scenario_id: $scenario_id, userAction: $userAction) {
      id
      fileName
    }
  }
`;
export const UPLOADS_BY_SCENARIO_ID = gql`
  query uploadsByScenarioId(
    $id: Int!
    $userAction: UploadsByScenarioIdUserAction
    $reviewerId: Int
  ) {
    uploadsByScenarioId(id: $id, userAction: $userAction, reviewerId: $reviewerId) {
      id
      scenarioUploads {
        id
        fileName
        createdAt
        tags
        uploadedToCapital
        fullName
        uploadedBySystem
        uploadStatus
      }
    }
  }
`;
export const SCENARIO_BY_ID = gql`
  query scenarioById($id: Int!, $userAction: ScenarioByIdUserAction) {
    scenarioById(id: $id, userAction: $userAction) {
      id
      docusignStatus
      applicantNames
      settlementDate
      owner
      uploadToCapitalStatus
      isTeamMember
      uploadCount
      scenarioStatus
      properties
      capitalUploadNotCompleteCount
      isAcceptanceLetterUploaded
      currentScenarioReviewerID
      loanAmount
      capitalLoanID
      legacyID
      reviewerID
      taggedFilesCount
      supportingDocs {
        applicantName
        isGuarantor
        isContractForSaleRequired
        isEmploymentConfirmationRequired
        isSelfEmployedIncomeTypeA
        isSelfEmployedIncomeTypeB
        isEmploymentAndIncomeConfirmationRequired
        isRegularOvertimeAndAllowances
        isBonusIncomeAndCommission
        isChildSupportOrMaintenance
        isInvestmentIncome
        isRentalIncome
        isGovernmentPensionsAndArmedServicesAllowance
        isBankStatements
        isLoanStatementsForRefinanced
        isFundsToComplete
        isAnyDebtRefinanced
        isAnyDebtNotRefinanced
        isHolidayLetIncome
        isPAYGBusinessOwnerConfirmation
        isPAYGContractorConfirmation
        isSelfEmployedContractorConfirmation
        isPAYGEmployee
        applicantIndex
      }
    }
  }
`;
