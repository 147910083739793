import React from "react";
import { createStyles, Theme, withStyles } from "@material-ui/core/styles";
import { LinearProgress } from "@material-ui/core";
import { useQuery } from "react-apollo-hooks";
import { Button, DataTable, TableBody, TableColumn, TableHeader, TableRow } from "react-md";
import { connect } from "react-redux";
import { UPLOADS_BY_SCENARIO_ID } from "../../constants/queryAndMutations";
import { uploadUploadsByScenarioId } from "../../constants/userActions";
import {
  UploadUpdateNumberOfUploadsActionType,
  DeleteFileModalOpenActionType,
  UploadDocumentPreviewActionType,
  UploadFileModalOpenActionType,
  UpdateTagsActionType,
} from "../../store/actions/uploadDocumentsActions/uploadDocumentsActionType";
import { TAppStateType } from "../../store/reducers/reducers";
import {
  uploadAcceptanceLetterThunk,
  UploadAcceptanceLetterThunkType,
} from "../../store/thunk/scenarioThunk/uploadAcceptanceLetterThunk";
import { CheckTickIcon } from "../common/icons/checkTickIcon";
import {
  deleteFileModalOpenAction,
  updateTagsAction,
  uploadFileModalOpenAction,
} from "../../store/actions/uploadDocumentsActions/uploadDocumentsAction";
import {
  uploadUpdateNumberOfUploadsAction,
  uploadDocumentPreviewAction,
} from "../../store/actions/uploadDocumentsActions/uploadDocumentsAction";
import { IScenarioUpload, IUploadsByScenarioIdData } from "amm-scenario-interface";
import { IAttachedFile, viewableDataTypes } from "../../constants";
import {
  viewDocumentThunk,
  ViewDocumentThunkType,
} from "../../store/thunk/scenarioThunk/viewDocumentThunk";

const headers = ["Date", "Filename", "Tags", "uploader", "Status", "Actions"];

const BorderLinearProgress = withStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 8,
      borderRadius: 5,
      backgroundColor: "#bbb",
    },
    bar: {
      borderRadius: 5,
      backgroundColor: "#47c68f",
    },
  })
)(LinearProgress);

interface IUserAction {
  actionPassed: string;
}
interface IVariables {
  id: number;
  userAction: IUserAction;
  reviewerId?: number;
}
interface OwnProps {
  scenarioId: number;
  currentScenarioUserId: number;
  currentScenarioReviewerId?: number;
  isDocusignCompleted: boolean;
  isTeamMemberOrOwnScenario: boolean;
  scenarioStatus: string;
  chooseFileButtonEnabled: boolean;
  owner?: string;
  isScenarioUnderReview: boolean;
}
interface StateProps {
  showUploadLoader: boolean;
  currentUserId: number;
  token: string;
  fileName: string;
  uploadTime: string;
  currentUser: string;
  totalUploads: number;
  attachedFiles: IAttachedFile[];
  uploadDocumentsForAllScenariosAsReviewer: boolean;
}

interface DispatchProps {
  uploadUpdateNumberOfUploadsAction: UploadUpdateNumberOfUploadsActionType;
  deleteFileModalOpenAction: DeleteFileModalOpenActionType;
  uploadAcceptanceLetterThunk: UploadAcceptanceLetterThunkType;
  uploadDocumentPreviewAction: UploadDocumentPreviewActionType;
  viewDocumentThunk: ViewDocumentThunkType;
  uploadFileModalOpenAction: UploadFileModalOpenActionType;
  updateTagsAction: UpdateTagsActionType;
}
type UploadHistoryProps = StateProps & OwnProps & DispatchProps;

const UploadDocumentHistory: React.FC<UploadHistoryProps> = (props: UploadHistoryProps) => {
  const getDate = (timestamp: string): string => {
    const intermediateDateString = new Date(+timestamp).toLocaleString("en-GB").split("/");
    return (
      intermediateDateString[0] +
      "/" +
      intermediateDateString[1] +
      "/" +
      intermediateDateString[2].substring(0, 4)
    );
  };

  const getFileType = (fileName: string) => {
    if (fileName.indexOf(".") !== -1) {
      const fileNameParts = fileName.split(".");
      return fileNameParts[fileNameParts.length - 1];
    } else {
      if (fileName.includes("_html")) {
        return "html";
      } else {
        return "json";
      }
    }
  };

  const sortDocs = (scenarioDocs: IScenarioUpload[]) => {
    let docsList: IScenarioUpload[] = [];
    let docsListWithTags: IScenarioUpload[] = [];
    scenarioDocs.sort((doc1, doc2) => {
      var keyA = new Date(+doc1.createdAt),
        keyB = new Date(+doc2.createdAt);
      if (keyA < keyB) return -1;
      if (keyA > keyB) return 1;
      return 0;
    });
    scenarioDocs.map((doc) => {
      if (doc.tags.length == 0) {
        docsList.push(doc);
      } else {
        docsListWithTags.push(doc);
      }
    });
    docsList = docsList.concat(docsListWithTags);
    return docsList;
  };

  const progressBar = (responseCount: number, chunks: number) => {
    if (!chunks) return 0;
    return (responseCount / chunks) * 100;
  };
  const getDocumentUrl = (fileName: string) => {
    const queryParams = `?fileName=${encodeURIComponent(fileName)}&scenarioId=${
      props.scenarioId
    }&fileType=${getFileType(fileName)}&currentUserId=${
      props.currentUserId
    }&currentScenarioUserId=${props.currentScenarioUserId}&isTeamMemberOrOwnScenario=${
      props.isTeamMemberOrOwnScenario
    }&isSelfReviewer=${props.currentScenarioReviewerId === props.currentUserId}`;
    if (viewableDataTypes.includes(getFileType(fileName))) {
      window.open(window.location.origin + "/view-document" + queryParams);
    } else {
      if (props.token) {
        props.viewDocumentThunk(
          props.scenarioId,
          encodeURIComponent(fileName),
          props.token,
          props.currentUserId,
          props.currentScenarioUserId,
          getFileType(fileName),
          true,
          false,
          true
        );
      }
    }
  };

  const currentUserIsReviewer = () =>
    props.currentScenarioReviewerId === props.currentUserId ||
    (props.uploadDocumentsForAllScenariosAsReviewer && props.isScenarioUnderReview);
  const isDeleteButtonEnabled = () =>
    currentUserIsReviewer() ? true : props.isTeamMemberOrOwnScenario;

  const getActionToBePassed = () => {
    return props.currentScenarioReviewerId === props.currentUserId
      ? uploadUploadsByScenarioId[2]
      : props.currentScenarioUserId === props.currentUserId
      ? uploadUploadsByScenarioId[0]
      : uploadUploadsByScenarioId[1];
  };

  const { loading, data, error } = useQuery<IUploadsByScenarioIdData, IVariables>(
    UPLOADS_BY_SCENARIO_ID,
    {
      variables: {
        id: props.scenarioId,
        userAction: {
          actionPassed: getActionToBePassed(),
        },
        reviewerId: props.currentScenarioReviewerId,
      },
      pollInterval: 5000,
      fetchPolicy: "no-cache",
    }
  );

  if (error) {
    if (error.graphQLErrors) {
      error.graphQLErrors.map(({ message, locations, path }) =>
        console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
      );
    }
    if (error.networkError) {
      console.log(`[Network error]: ${error.networkError}`);
    }
  }

  if (data && props.totalUploads !== data.uploadsByScenarioId.scenarioUploads.length) {
    props.uploadUpdateNumberOfUploadsAction(data.uploadsByScenarioId.scenarioUploads.length);
  }

  return (
    <React.Fragment>
      <DataTable
        baseId="simple-pagination"
        className={`upload-table no-effect amm--table upload-history-table ${
          loading ? `loader-show loader-file` : ""
        }`}
      >
        <TableHeader>
          <TableRow selectable={false}>
            {headers.map((header: string, key: number) => {
              return (
                <TableColumn className="upload-moodal-th" key={key}>
                  {header}
                </TableColumn>
              );
            })}
          </TableRow>
        </TableHeader>
        <TableBody>
          {data &&
            data.uploadsByScenarioId &&
            data.uploadsByScenarioId.scenarioUploads &&
            sortDocs(data.uploadsByScenarioId.scenarioUploads).map(
              (item: IScenarioUpload, key: number) => (
                <TableRow
                  key={key}
                  selectable={false}
                  className={`${item.uploadStatus === "Failed" ? "failed-upload" : ""}`}
                >
                  <TableColumn className="column-width80">{getDate(item.createdAt)}</TableColumn>
                  <TableColumn className="column-width2">{item.fileName}</TableColumn>
                  <TableColumn
                    className={`tags 
                      ${
                        item.tags.length
                          ? item.uploadedToCapital ||
                            item.uploadedBySystem ||
                            props.showUploadLoader
                            ? "tag-column-disabled"
                            : "tag-column"
                          : !props.chooseFileButtonEnabled
                          ? "tag-required-column-disabled"
                          : "tag-required-column"
                      }`}
                    onClick={() => {
                      if (
                        !item.uploadedToCapital &&
                        !item.uploadedBySystem &&
                        props.chooseFileButtonEnabled
                      ) {
                        props.uploadFileModalOpenAction(true, props.scenarioId.toString());
                        props.updateTagsAction(
                          item.fileName,
                          getDate(item.createdAt),
                          item.tags,
                          item.id
                        );
                      }
                    }}
                  >
                    {item.tags.length
                      ? Array.prototype.join.call(item.tags.sort(), ", ")
                      : "Tag Required"}
                  </TableColumn>
                  <TableColumn>{item.uploadedBySystem ? `System` : item.fullName}</TableColumn>
                  <TableColumn>
                    {item.uploadedToCapital ? (
                      <div className="upload-status">
                        <div className="upload-status-icon">
                          <CheckTickIcon
                            className="check-tick-icon-autoWidth"
                            width="20"
                            height="20"
                          />
                        </div>
                        Submitted
                      </div>
                    ) : item.uploadStatus === "Failed" ? (
                      item.uploadStatus
                    ) : item.tags.length ? (
                      `Uploaded`
                    ) : (
                      `Attached`
                    )}
                  </TableColumn>
                  <TableColumn className={`upload-table-btn column-width80`}>
                    <Button
                      className={`material-icons ${
                        item.uploadStatus === "Failed" ? "failed-upload-row-buttons" : ""
                      }`}
                      icon={true}
                      tooltipLabel="Launch"
                      tooltipDelay={300}
                      tooltipPosition="left"
                      onClick={() => getDocumentUrl(item.fileName)}
                    >
                      launch
                    </Button>
                    <Button
                      className={`delete-icon ${
                        item.uploadStatus === "Failed" ? "failed-upload-row-buttons" : ""
                      }`}
                      icon={true}
                      tooltipLabel="Delete"
                      tooltipDelay={300}
                      tooltipPosition="left"
                      disabled={item.tags[0] === "Acceptance Letter" || !isDeleteButtonEnabled()}
                      onClick={() => props.deleteFileModalOpenAction(true, item.id)}
                    >
                      close
                    </Button>
                  </TableColumn>
                </TableRow>
              )
            )}
        </TableBody>
        <>
          {props.attachedFiles &&
            props.attachedFiles.map((attachedFile) => {
              return (
                attachedFile.chunks !== attachedFile.responseCount - 1 && (
                  <TableRow selectable={false}>
                    <TableColumn className="column-width80">{attachedFile.uploadDate}</TableColumn>
                    <TableColumn className="column-width2">{attachedFile.fileName}</TableColumn>
                    <TableColumn className="tag-required-column-disabled">Tag Required</TableColumn>
                    <TableColumn>{props.currentUser}</TableColumn>
                    <TableColumn>
                      <BorderLinearProgress
                        variant="determinate"
                        value={progressBar(attachedFile.responseCount, attachedFile.chunks)}
                      />
                    </TableColumn>
                    <TableColumn className="upload-table-btn  column-width80">
                      <Button
                        disabled
                        className="material-icons"
                        icon={true}
                        tooltipLabel="Launch"
                        tooltipDelay={300}
                      >
                        launch
                      </Button>
                      <Button
                        disabled
                        className="delete-icon"
                        icon={true}
                        tooltipLabel="Delete"
                        tooltipDelay={300}
                      >
                        close
                      </Button>
                    </TableColumn>
                  </TableRow>
                )
              );
            })}
        </>
      </DataTable>
    </React.Fragment>
  );
};

export const mapStateToProps = (state: TAppStateType) => {
  const fullName = state.login.currentUser.firstName + " " + state.login.currentUser.lastName;
  return {
    showUploadLoader: state.upload.showUploadLoader,
    currentUserId: state.login.currentUser.id,
    token: state.login.token ? state.login.token : "",
    uploadTime: state.upload.uploadTime,
    fileName: state.upload.fileName,
    currentUser: fullName,
    totalUploads: state.upload.numberOfUploads,
    attachedFiles: state.upload.attachedFiles,
    uploadDocumentsForAllScenariosAsReviewer:
      state.login.userPermissions.uploadDocumentsForAllScenariosAsReviewer,
  };
};
export const mapDispatchToProps = {
  deleteFileModalOpenAction,
  uploadUpdateNumberOfUploadsAction,
  uploadAcceptanceLetterThunk,
  uploadDocumentPreviewAction,
  viewDocumentThunk,
  uploadFileModalOpenAction,
  updateTagsAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadDocumentHistory);