import { RolesWithSpaces, Roles } from "../utility/rolesEnum";
export const DOMAIN_NAME = "shore";

// server url
export const serverUrl = process.env.REACT_APP_SERVER_URL;
export const lrasUrl = process.env.REACT_APP_LRAS_URL;

// broker page urls
export const graphQl = `${serverUrl}graphql`;

// postgraphile url
export const pgGraphQl = `${serverUrl}pggraphql`;

// property page urls
export const addressSearchApi = `${serverUrl}property/address_search`;
export const suburbSearchApi = `${serverUrl}property/auto_suggest`;
export const suburbImagesApi = `${serverUrl}property/suburb_images`;
export const suburbGetPropertyApi = `${serverUrl}property/suburb_search`;
export const propertyEstimateApi = `${serverUrl}property/property_estimate`;
export const propertyImageApi = `${serverUrl}property/property_images`;
export const downloadAcceptanceLetter = `${serverUrl}document/download_acceptance_letter`;
export const downloadCsvReport = `${serverUrl}report/all/scenarios`;

// applicant page urls
export const getOtpApi = `${serverUrl}applicant/get_otp`;
export const confirmOtpApi = `${serverUrl}applicant/confirm_otp`;
export const creditScoreApi = `${serverUrl}applicant/credit_score`;
export const overrideCreditScoreApi = `${serverUrl}applicant/override_credit_score`;

// affordability page urls
export const provisoPersonalInfoRequest = `${serverUrl}affordability/personal_info_request`;
export const provisoDataResponse = `${serverUrl}affordability/personal_info_response`;
export const sendLinkSMS = `${serverUrl}affordability/send_link_sms`;

// leads apis
export const viewAllLeadsApi = `${serverUrl}ic/leads/all`;
export const assignLeadApi = `${serverUrl}ic/leads/assign_lead`;
export const deleteLeadApi = `${serverUrl}ic/leads/delete`;

// algolia urls
export const algoliaFetchApi = `${serverUrl}help/fetch_from_algolia`;

// reset password api url
export const resetPasswordApi = `${serverUrl}reset/password`;

// fetching profile on logging in
export const userProfileApi = `${serverUrl}authentication/profile`;
export const userPermissionsApi = `${serverUrl}authentication/permissions`;

// dashboard urls
export const dashboardScenarioLoad = `${serverUrl}dashboard/dashboard_scenario_load`;
export const dashboardScenarioDelete = `${serverUrl}dashboard/dashboard_scenario_delete`;
export const dashboardScenarioDuplicate = `${serverUrl}dashboard/dashboard_scenario_duplicate`;
export const dashboardScenarioSave = `${serverUrl}dashboard/dashboard_scenario_save`;
export const dashboardUploadCSS = `${serverUrl}dashboard/dashboard_upload_css`;
export const docusignTrigger = `${serverUrl}document/docusign_trigger`;
export const documentScenarioUploadFile = `${serverUrl}document/document_upload_files`;
export const updateTagsApiUrl = `${serverUrl}document/document_update_tags`;
export const dashboardScenarioHistory = `${serverUrl}dashboard/dashboard_scenario_history`;

// review page urls
export const uploadAcceptanceLetterApi = `${serverUrl}document/upload_acceptance_letter`;
export const capitalOrigination = `${serverUrl}document/capital_origination`;
export const capitaldocumentUpload = `${serverUrl}document/capital_document`;
export const backChannelAPIURL = `${serverUrl}document/back_channel`;
export const sendQuoteUrl = `${serverUrl}review/send_quote`;
export const pdfGeneration = `${serverUrl}review/generate_pdf`;
export const viewDocumentApi = `${serverUrl}document/view`;

// team page apis
export const assignScenarioApi = `${serverUrl}teams/reassign_team_member`;
export const viewTeamsApi = `${serverUrl}teams/view_teams`;
export const deleteTeamApi = `${serverUrl}teams/delete_team`;
export const viewTeamMembersApi = `${serverUrl}teams/view_team_members`;
export const viewTeamMembersScenarios = `${serverUrl}teams/view_team_members_scenarios`;
export const addTeamApi = `${serverUrl}teams/add_team`;
export const editTeamApi = `${serverUrl}teams/edit_team`;

// user apis
export const removeUserFromTeamsApi = `${serverUrl}user/remove_from_all_teams`;
export const assignUserScenariosApi = `${serverUrl}user/assign_scenarios`;
export const unassignReviewerFromCreditReviewScenarioApi = `${serverUrl}user/unassign_credit_review_scenarios`;

// creditReview apis
export const assignScenarioReviewApi = `${serverUrl}credit_review/assign_reviewer`;
export const creditReviewApi = `${serverUrl}credit_review/dashboard`;

// ARN apis
export const abnGetApi = `https://abr.business.gov.au/json/AbnDetails.aspx`;
export const namesGetApi = `https://abr.business.gov.au/json/MatchingNames.aspx`;

export const abnNotFoundMessage = "Search text is not a valid ABN or ACN";

// algolia information
export const algoliaIndexName = process.env.REACT_APP_ALGOLIA_INDEX_NAME
  ? process.env.REACT_APP_ALGOLIA_INDEX_NAME
  : "test_DEMO";
export const algoliaAppID = `86PC5SC0E1`;
export const algoliaApiKey = `a4540ae7da6eecf45b7ef1ec6cefbaf5`;

export const arrowUp = "ArrowUp";
export const arrowDown = "ArrowDown";
export const shiftKey = "Shift";

// dummy data for upload history table;
export const dates = [];

export const filenames = [];
export const supportedFileTypes = [
  "pdf",
  "xls",
  "xlsx",
  "xlsm",
  "csv",
  "jpg",
  "jpeg",
  "png",
  "tif",
  "tiff",
  "bmp",
  "gif",
  "odt",
  "ods",
  "txt",
  "json",
  "doc",
  "docx",
  "rtf",
  "html",
];
export const tags = [];

export const disabledArray = [[false], [false], [false], [false], [false], [false]];

// regex
export const emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const mobileRegex = ["QA", "local"].includes(process.env.REACT_APP_ENV!)
  ? /.*/
  : /^(?:\+?(61))? ?(?:\((?=.*\)))?(0?[4])\)? ?(\d\d(?:[ ](?=\d{3})|(?!\d\d[ ]?\d[ ]))\d\d[ ]?\d[ ]?\d{3})$/;
export const homePhoneRegex = /^(?:\+?(61))? ?(?:\((?=.*\)))?(0?[2-57-8])\)? ?(\d\d(?:[ ](?=\d{3})|(?!\d\d[ ]?\d[ ]))\d\d[ ]?\d[ ]?\d{3})$/;

export const monthInBusinessRegex = /^0*([0-9]|[1-8][0-9]|9[0-9]|[1-8][0-9]{2}|9[0-8][0-9]|99[0-9]|1[0-9]{3}|2000)$/;
export const nameRegex = /^[a-zA-Z][a-zA-Z ' , -]{0,29}$/;
export const accountNameRegex = /^(?=.{1,30}$)^[a-zA-Z0-9][.]*|^$/;
export const alphanumericRegexForDocumentId = /^[a-zA-Z0-9~.:_-]{1,15}$/;
export const strongCognitoRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;
export const mediumCognitoRegex = /^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})/;
export const validInputStringRegex = /^[\d,]+(\.(\d{1,3})?)?$/;
export const institutionName = /^[a-zA-Z#0-9 ]{3}[a-zA-Z#0-9\- ]*$/;
export const onlyCommaRegex = /^,+$/;
export const validZeroIntegerWithDecimalRegex = /^0\.(\d+)?$/;
export const invalidInputStringRegex = /\.(?:0{1,3}|[1-9]0{1,2}|[1-9]{1,2}0{1})?$/;
export const alphanumericRegexMaxLength30 = /^[a-zA-Z0-9]{1,30}$/;
export const stringOfLengthFour = /^[a-zA-Z0-9]{4}$/;
export const urlRegex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
export const maxLength100 = /^.{1,100}$/;
export const teamNameRegex = /^\w+([\s-_]\w+)*$/;

export const propertyMaxValue: number = 100000000;
export const COLORS = ["#ffff95", "#47c68f", "#999999", "#777777", "#555555"];
export const isLendingSpecAmm = process.env.REACT_APP_LENDING_SPEC === "amm";
export const isLendingSpecVolt = process.env.REACT_APP_LENDING_SPEC === "volt";

export const cashOutMaxValue = process.env.REACT_APP_CASH_OUT_MAX
  ? +process.env.REACT_APP_CASH_OUT_MAX
  : 200000;

interface IPageData {
  title: string;
  subHeading: string;
}
interface IHeader {
  assets: string[];
  creditCards: string[];
  financialObligation: string[];
  incomeSources: string[];
  livingExpenses: string[];
}

interface ISelectType {
  label: React.ReactNode;
  value: string | number;
  disabled: boolean;
}
export interface ISelectTypes extends Array<ISelectType> {}

interface IRadioType {
  label: React.ReactNode;
  value: string | number;
  disabled?: boolean;
}
interface IRadioTypes extends Array<IRadioType> {}

export const affordabilityPage: IPageData = {
  // @todo
  subHeading: "Information about your financial situation.",
  title: "AFFORDABILITY",
};

export const objectivePage: IPageData = {
  subHeading: "Tell us about your home or investment property goals.",
  title: "PROPERTY",
};

export const reviewPage: IPageData = {
  subHeading: "Let's review the solution we've created for you...",
  title: "REVIEW",
};

// user page
export const userList: IPageData = {
  subHeading: "User List",
  title: "My Account",
};

export const userScenarios: IPageData = {
  subHeading: "User Scenarios",
  title: "Dashboard",
};

export const leadsDetail: IPageData = {
  subHeading: "Leads Detail",
  title: "Leads",
};

export const adminScenarios: IPageData = {
  subHeading: "Admin Scenarios",
  title: "Dashboard",
};

export const userDetail: IPageData = {
  subHeading: "User Detail",
  title: "Dashboard",
};

// Objective page
export const purchaseOrRefinanceOption: IRadioTypes = [
  {
    label: "Purchase",
    value: "Purchase",
    disabled: Boolean(process.env.REACT_APP_DISABLE_PURCHASE === "true"),
  },
  {
    label: "Refinance",
    value: "Refinance",
  },
];

export const propertyUsedFor: IRadioTypes = [
  {
    label: "Home",
    value: "Home",
  },
  {
    label: "Investment",
    value: "Investment",
  },
];

export const propertyPurchaseType: IRadioTypes = [
  {
    label: "I've found my property",
    value: "I've found my property",
  },
  //Enable it when required to show the radio option on property screen
  //{
  //  label: "I'm still looking",
  //  value: "I'm still looking",
 // },
];

export const propertyType: IRadioTypes = [
  {
    label: "House",
    value: "House",
  },
  {
    label: "Apartment",
    value: "Apartment",
  },
];

export const propertyNewOrOffThePlan: IRadioTypes = [
  {
    label: "No",
    value: "No",
  },
  {
    label: "Yes",
    value: "Yes",
  },
];

export const bedroom: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9];
export const bathrooms: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9];
export const carparks: number[] = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];

// solution
export const doYouRequireCashOutOptions: IRadioTypes = [
  {
    label: "No",
    value: "No",
  },
  {
    label: "Yes",
    value: "Yes",
  },
];

export const cashOutUseDropdown: string[] = process.env.REACT_APP_CASH_ADVANCE_OPTIONS
  ? JSON.parse(process.env.REACT_APP_CASH_ADVANCE_OPTIONS)
  : [];

export const lendingSpecPropertyPercentage: number = process.env.REACT_APP_PERCENT_FOR_CASH_ADVANCE
  ? Number(process.env.REACT_APP_PERCENT_FOR_CASH_ADVANCE)
  : 0;

export const accountTypeOptions: IRadioTypes = [
  {
    label: "Principal & interest",
    value: "P&I",
  },
  {
    label: "Interest only",
    value: "IO",
  },
];

export const accountPurposeOptions: IRadioTypes = [
  {
    label: "Home loan",
    value: "Home loan",
  },
  {
    label: "Investment",
    value: "Investment",
  },
];

export const interestTypeOptions: IRadioTypes = [
  {
    label: "Variable",
    value: "Variable",
    disabled: false,
  },
  {
    label: "Fixed",
    value: "Fixed",
    disabled: true,
  },
];

// Applicants Page
export const privacyOptions: IRadioTypes = [
  {
    label: "I already have a signed privacy consent form",
    value: "I already have a signed privacy consent form",
  },
  {
    label: "Obtain digital privacy consent confirmation",
    value: "Obtain digital privacy consent confirmation",
  },
];

export const verifyBtnText: string[] = [
  "Verify this applicant's identity",
  "verifying...",
  "verified",
];
export const creditScoreBtnText: string[] = ["Request applicant's credit score", "calculating..."];
export const maxApplicantTab: number = 6;
export const howManyApplicant: IRadioTypes = [
  {
    label: "Single",
    value: "Single",
  },
  {
    label: "Couple",
    value: "Couple",
  },
  {
    label: "Two or more joint applicants",
    value: "Two or more joint applicants",
  },
];

export const applicantTitle: string[] = ["Mr", "Mrs", "Miss", "Ms", "Dr", "Other"];

export const brokerStatus: string[] = ["Active", "Disabled"];

export enum DOMAIN_NAMES {
  Amm = "amm",
  MoC = "mortgage-choice",
  Shore = "shore",
  RM = "rate-money",
  AFG = "afg",
  AMO = "amo",
  MOD = "mod",
  Shift = "shift",
  Crown = "crown",
  Volt = "volt",
}

export const apiIntegrationDomains: string[] = [DOMAIN_NAMES.AFG, DOMAIN_NAMES.MoC];

export const isDomainNotAFG = `${process.env.REACT_APP_DOMAIN}` !== DOMAIN_NAMES.AFG;

export enum DOMAIN_PROMOTIONAL_LINKS {
  "amm" = "https://resources.australianmortgage.com/australianmortgage/product/rates-fees",
  "mortgage-choice" = "https://resources.australianmortgage.com/mortgagechoice/product/rates-fees",
  "shore" = "https://resources.australianmortgage.com/shoreprivate/product/rates-fees",
  "rate-money" = "https://resources.australianmortgage.com/ratemoney/product/rates-fees",
  "shift" = "https://resources.australianmortgage.com/australianmortgage/product/rates-fees",
}

export enum ROLE_IDS {
  Frozen = 1,
  LoanWriter = 2,
  Admin = 3,
  BrandAdmin = 4,
  CreditManager = 5,
  ClientManager = 6,
  APILoanWriter = 8,
  APIClientManager = 9,
  BusinessDevelopmentManager = 10,
  AFGLoanWriter = 11,
}

export const ROLE_IDS_MAPPING = {
  1: RolesWithSpaces.Frozen,
  2: RolesWithSpaces.LoanWriter,
  3: RolesWithSpaces.Admin,
  4: RolesWithSpaces.BrandAdmin,
  5: RolesWithSpaces.CreditManager,
  6: RolesWithSpaces.ClientManager,
  8: RolesWithSpaces.APILoanWriter,
  9: RolesWithSpaces.APIClientManager,
  10: RolesWithSpaces.BusinessDevelopmentManager,
  11: RolesWithSpaces.AFGLoanWriter,
};
export const rolesExcludedForUsersPanel: string[] = [RolesWithSpaces.BrandAdmin, Roles.BrandAdmin];

export const rolesExcludedForTeams: string[] = [
  RolesWithSpaces.BrandAdmin,
  Roles.BrandAdmin,
  RolesWithSpaces.CreditManager,
  Roles.CreditManager,
  RolesWithSpaces.BusinessDevelopmentManager,
  Roles.BusinessDevelopmentManager,
];

export const rolesExcludedForSelectBrokerModel: string[] = [
  RolesWithSpaces.ClientManager,
  Roles.ClientManager,
  Roles.APIClientManager,
  RolesWithSpaces.APIClientManager,
];

export const rolesCanNotBePartOfTeam: string[] = [
  RolesWithSpaces.CreditManager,
  Roles.CreditManager,
  RolesWithSpaces.BusinessDevelopmentManager,
  Roles.BusinessDevelopmentManager,
];

export const incomeVerificationType: IRadioTypes = [
  {
    label: "Type A",
    value: "Type A",
  },
  {
    label: "Type B",
    value: "Type B",
  },
];

export const applicantStatus: IRadioTypes = [
  {
    label: "Owner",
    value: "Owner",
  },
  {
    label: "Mortgage holder",
    value: "Mortgage holder",
  },
  {
    label: "Renting",
    value: "Renting",
  },
  {
    label: "Paying board",
    value: "Paying board",
  },
];

export const documentType: ISelectTypes = [
  {
    label: "Driver license",
    value: "Driver license",
    disabled: false,
  },
  {
    label: "Medicare card",
    value: "Medicare card",
    disabled: false,
  },
  {
    label: "Passport",
    value: "Passport",
    disabled: false,
  },
];

export const documentTypeIndex = ["Driver license", "Medicare card", "Passport"];
export const documentAddressLocation: IRadioTypes = [
  {
    label: "Australian Capital Territory",
    value: "ACT",
  },
  {
    label: "New South Wales",
    value: "NSW",
  },
  {
    label: "Northern Territory",
    value: "NT",
  },
  {
    label: "South Australia",
    value: "SA",
  },
  {
    label: "Queensland",
    value: "QLD",
  },
  {
    label: "Victoria",
    value: "VIC",
  },
  {
    label: "Western Australia",
    value: "WA",
  },
  {
    label: "Tasmania",
    value: "TAS",
  },
];

export const country: IRadioTypes = [
  {
    label: "Australia",
    value: "AUS",
  },
];

export const referenceNumber: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9];

// Affordability page
export const affordabilityMaxRow: number = 20;
export const providerMenuItems: string[] = ["Illion Bank Statements"];
export const headers: IHeader = {
  assets: ["ASSETS:", "Value:", "Share:", "Funds to complete:"],
  creditCards: ["CREDIT CARDS:", "Credit limit:", "Period (days)", ""],
  financialObligation: ["FINANCIAL OBLIGATIONS:", "Balance:", "Interest rate:", "Payment:", ""],

  incomeSources: ["INCOME SOURCES:", "", "Amount:", "", ""],
  livingExpenses: ["LIVING EXPENSES:", "", "Amount:", "", ""],
};

export const duration: IRadioTypes = [
  { label: "Year", value: "Year" },
  { label: "Month", value: "Month" },
  { label: "Week", value: "Week" },
];

export const financialObligationPeriod: IRadioTypes = [
  { label: "Year", value: "Year" },
  { label: "Month", value: "Month" },
  { label: "Week", value: "Week" },
  { label: "Once off", value: "Once off" },
];

export const taxStatus: IRadioTypes = [
  { label: "Before tax", value: "Before" },
  { label: "After tax", value: "After" },
];

export const applicantFirstHome: IRadioTypes = [
  { label: "No", value: "No" },
  { label: "Yes", value: "Yes" },
];

export const earlyRepaymentStrategy = [
  { label: "Continuing rental or other income", value: "Continuing rental or other income" },
  {
    label: "Sufficient equity at application to downsize",
    value: "Sufficient equity at application to downsize",
  },
  { label: "Sale of other property", value: "Sale of other property" },
  { label: "Shares or managed funds", value: "Shares or managed funds" },
  {
    label: "Lump sum superannuation (maximum of 25% of fund)",
    value: "Lump sum superannuation (maximum of 25% of fund)",
  },
  { label: "Other", value: "Other" },
];

export const provisoOrManual: string[] = ["Proviso", "Manual"];

export const rentalIncome = "Rental income";

export const applicantPAYGIncomeTypeOptions: string[] = [
  "Base PAYG salary (full-time, permanent part-time)",
  "Base PAYG salary (casual)",
  "Rental income",
  "Allowances",
  "Overtime - Regular",
  "Overtime - Irregular",
  "Commissions",
  "Bonus income",
  "Second job income",
  "Investment income",
  "Government pensions",
  "Armed services allowance",
  "Child support income",
  "Holiday Let",
  "Uncategorised",
];
export const applicantSEIncomeTypeOptions: string[] = [
  "Regular Salary or Wages (Self-Employed, Type A Income Verification)",
  // "Regular Salary or Wages (Self-Employed, Type B Income Verification)",
  // "Net Income (Self-Employed, Type A Income Verification)",
  // "Net Income (Self-Employed, Type B Income Verification)",
  "Rental income",
  "Allowances",
  "Overtime - Regular",
  "Overtime - Irregular",
  "Commissions",
  "Bonus income",
  "Second job income",
  "Investment income",
  "Government pensions",
  "Armed services allowance",
  "Child support income",
  "Holiday Let",
  "Uncategorised",
];
export const applicantAssetsTypeOptions: string[] = [
  "Savings account",
  "Term deposit",
  "Real estate (not security property)",
  "Transaction account",
  "Superannuation",
  "Shares",
  "Motor vehicle",
  "Other assets",
  "Gift",
];

export const applicantFinancialObligationLoanTypeOptions: string[] = [
  "Home Loan",
  "Personal Loan",
  "Other Loan",
  "Investment Loan",
];

export const includedLoanFinanceOptions: string[] = [
  "Home Loan",
  "Investment Loan",
  "Personal Loan",
];

export const applicantFinancialObligationTypeOptions: string[] = applicantFinancialObligationLoanTypeOptions.concat(
  [
    "Lease",
    "Hire purchase",
    "HELP/HECS",
    "Child Support",
    "Overdraft",
    "Tax liability",
    "Other debt",
    "Rent",
  ]
);

export const applicantLivingExpenseTypeOptions: string[] = [
  "Utilities",
  "Groceries",
  "Dining Out",
  "Insurance",
  "Telecommunications",
  "Subscription TV",
  "Education and Childcare",
  "Vehicles and Transport",
  "Personal Care",
  "Health",
  "Department Stores",
  "Retail",
  "Home Improvement",
  "Entertainment",
  "Gyms and other memberships",
  "Travel",
  "Pet Care",
  "Uncategorised",
];

export enum ProvisoTypeMappings {
  DM035 = "Utilities",
  DM023 = "Groceries",
  DM058 = "Dining Out",
  DM024 = "Insurance",
  DM032 = "Telecommunications",
  DM029 = "Subscription TV",
  DM040 = "Education and Childcare",
  TF003 = "Vehicles and Transport",
  DM052 = "Personal Care",
  DM054 = "Health",
  DM053 = "Department Stores",
  DM062 = "Retail",
  DM056 = "Home Improvement",
  DM057 = "Entertainment",
  DM060 = "Gyms and other memberships",
  DM055 = "Travel",
  DM059 = "Pet Care",
  DM071 = "Uncategorised",
  DM028 = "Rent",
}

export const AWS_REGION = "ap-southeast-2";
export const AWS_COGNITO_USER_POOL_ID = process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID;
export const AWS_COGNITO_USER_POOL_WEB_CLIENT_ID =
  process.env.REACT_APP_AWS_COGNITO_USER_POOL_WEB_CLIENT_ID;

export const moduleConstant = {
  admin: "ADMIN",
  applicant: "APPLICANTS",
  branding: "BRANDING",
  error: "ERROR",
  help: "HELP",
  login: "LOGIN",
  objective: "OBJECTIVE_PAGE",
  review: "REVIEW_PAGE",
  scenario: "USER",
  solution: "SOLUTION_PAGE",
  trigger: "TRIGGER",
  upload: "UPLOAD_MODULE",
  user: "USER",
};

export const Status = ["Active", "Disabled"];

export const invalidCategory = process.env.REACT_APP_INVALID_PROPERTY_CATEGORY
  ? JSON.parse(process.env.REACT_APP_INVALID_PROPERTY_CATEGORY)
  : [3,4];

export const BRAND_NAME = process.env.REACT_APP_BRAND_NAME;

export enum STATUS_NAMES {
  scenario = "Scenario",
  approved = "Verified Approval",
  docusignInitiated = "Docusign Initiated",
  docusignInProgress = "Docusign In Progress",
  docusignComplete = "Docusign Complete",
  docusignCompletePendingUploads = "Docusign Complete + Pending uploads",
  scenarioReceived = "Scenario received",
  rejected = "Rejected",
  creditReview = "Credit Review",
  reviewComplete = "Unconditional Approval",
  approvedPending = "Approved Pending",
  reWork = "Re-Work",
}

export const scenarioStatusForDuplication: string[] = [
  STATUS_NAMES.approved,
  STATUS_NAMES.scenario,
  STATUS_NAMES.creditReview,
  STATUS_NAMES.reviewComplete,
  STATUS_NAMES.approvedPending,
  STATUS_NAMES.reWork,
];

export const scenarioStatusForCapitalIDVisibility: string[] = [
  STATUS_NAMES.reviewComplete,
  STATUS_NAMES.creditReview,
  STATUS_NAMES.approvedPending,
];

export const dependentType = [
  "Minor",
  "Adult Child",
  "Spouse",
  "Elder Relative",
  "Other Legal Dependent",
];

export enum LOAN_MAX_FOR_MOC {
  CategoryOne = 1250000,
  CategoryTwo = 1100000,
  CategoryThree = 750000,
}
export enum STATUS_IDS {
  scenario = 1,
  approved = 2,
  docusignInitiated = 3,
  docusignInProgress = 4,
  docusignComplete = 5,
  docusignCompletePendingUploads = 6,
  scenarioReceived = 9,
  rejected = 10,
  creditReview = 11,
  reviewComplete = 12,
  approvedPending = 13,
  reWork = 14,
}

export const creditReviewSaveActions = [
  "Re-work",
  "Unconditional Approval",
  "Review Save",
  "Approved Pending",
];
export enum CREDIT_REVIEW_SAVE_ACTIONS {
  reWork = "Re-work",
  reviewComplete = "Unconditional Approval",
  reviewSave = "Review Save",
  approvedPending = "Approved Pending",
}

export const scenarioMessage = {
  dashboard: "There are currently no scenarios to display.",
};

export const uploadToolTipText = {
  message: "Please save the scenario to upload documents.",
};

export enum APPROVE_LETTERS {
  completeReview = "Complete Review",
  approvedPending = "Approved Pending",
}

export enum APPROVE_LETTERS_CONDITIONS {
  completeReview = "Settlement Conditions",
  approvedPending = "Approval Conditions",
}

export const approvalLettersMapping = {
  [APPROVE_LETTERS.completeReview]: APPROVE_LETTERS_CONDITIONS.completeReview,
  [APPROVE_LETTERS.approvedPending]: APPROVE_LETTERS_CONDITIONS.approvedPending,
};

export const confirmPasswordText = "Confirm password and new password should be same.";
export const errorMessagesForResetPassword = [
  "User password cannot be reset in the current state.",
  "Cannot reset password for the user as there is no registered/verified email or phone_number",
];

export const capitalSuccessfulMessages = [
  "Data sent to capital. Now uploading the documents to Capital.",
  "Data sent to capital.",
  "Files upload to Capital finished.",
];

export const loanIsRefinancedText = "This loan will be refinanced as part of this application";

export const creditCardRefinancedText =
  "This credit card will be refinanced as part of this application";

export const autoAddedNotes = ["isLoanRefinanced", "isCreditCardRefinanced"];
export const uncategorisedErrorText =
  "The income source type: Uncategorised is not valid. Please select another type.";

export const livingArrangementItems = {
  Single: "Single",
  Couple: "Couple",
  Family: "Family",
};

export const mobilePhoneErrorMessage = [
  "This field is required",
  "Mobile Number must be at least 9 characters",
  "All applicants should have a unique mobile phone number.",
  "Please enter a phone number with area code.",
];

// maximum file chunk size allowed.
export const uploadFileSizeMaxBytes = 4 * 1000 * 1000;

export const ammLendingSpecPropertyPercentage: number = 0.2;
export const shoreLendingSpecPropertyPercentage: number = 0.15;

export enum ERROR_OBJECT {
  Message = "This is a required field.",
  ClassName = "password-error",
}

export enum ReviewNotesScreens {
  property = "property",
  solution = "solution",
  applicant = "applicant",
  affordability = "affordability",
  review = "review",
}

export const rentStatuses = ["Renting", "Paying board"];

export const rolesForCreditReview: string[] = [
  RolesWithSpaces.CreditManager,
  Roles.CreditManager,
  RolesWithSpaces.Admin,
];

export const MINIMUM_EMPLOYMENT_DETAILS = 1;
export const MINIMUM_INCOME_SOURCES = 1;
export const MINIMUM_LIVING_EXPENSES = 5;
export const MINIMUM_ASSETS = 1;
export const MINIMUM_REFINANCED_LOAN = 1;
export const FIXED_RATE_MIN = process.env.REACT_APP_FIXED_RATE_MIN_LIMIT
  ? Number(process.env.REACT_APP_FIXED_RATE_MIN_LIMIT)
  : 1;
export const FIXED_RATE_MAX = process.env.REACT_APP_FIXED_RATE_MAX_LIMIT
  ? Number(process.env.REACT_APP_FIXED_RATE_MAX_LIMIT)
  : 5;
export const MINIMUM_LOAN_AMOUNT_PER_ACCOUNT = 10000;

export const deletableStatuses: string[] = [
  STATUS_NAMES.scenarioReceived,
  STATUS_NAMES.scenario,
  STATUS_NAMES.rejected,
];

export const notSubmitableStatuses: string[] = [
  STATUS_NAMES.scenario,
  STATUS_NAMES.reWork,
  STATUS_NAMES.rejected,
];
export const prelimPDFOptions = ["Review Notes", "Serviceability Assessment"];
export const updateStatusOptions = ["Complete Review", "Approved Pending", "Re-Work"];

export const ACCOUNT_NAME_VALIDATION =
  "Account name must start with an alphabet or number and should be less than 30 characters";

export const industryTypes: string[] = [
  "Accounting/Finance/Insurance",
  "Administration/Support",
  "Art/Design/Media",
  "Aviation/Transport",
  "Building/Construction",
  "Education/Child Care",
  "Engineering/Manufacturing",
  "Emergency Services",
  "Legal/Law Enforcement",
  "Marketing/Public Relations",
  "Medical",
  "Real Estate",
  "Technology",
  "Other",
];

export const employmentBasisTypes: string[] = [
  "Full Time",
  "Part Time",
  "Casual",
  "Contractor",
  "Home Duties",
  "Investor",
  "Not Employed",
  "Pensioner",
  "Self Funded Retiree",
];

interface IEmploymentType {
  label: string;
  value: string;
  disabled?: boolean;
}

export interface IAttachedFile {
  uploadDate: string;
  fileName: string;
  responseCount: number;
  chunks: number;
}

export const employmentTypesPrimary: IEmploymentType[] = [
  { label: "PAYG Employee", value: "PAYG Employee" },
  { label: "Business Owner", value: "Business Owner" },
  { label: "PAYG Contractor", value: "PAYG Contractor" },
  { label: "Self Employed Contractor", value: "Self Employed Contractor" },
];

export const employmentTypesAll: IEmploymentType[] = employmentTypesPrimary.concat([
  { label: "Other", value: "Other" },
]);

export enum EmploymentTypes {
  PAYGEmployee = "PAYG Employee",
  BusinessOwner = "Business Owner",
  PAYGContractor = "PAYG Contractor",
  SelfEmployedContractor = "Self Employed Contractor",
  Other = "Other",
}

export const WEBSOCKET_URL = `${process.env.REACT_APP_WEBSOCKET_URL}`;

export const CAPITAL_SUBMIT_STATUS: string[] = [
  STATUS_NAMES.creditReview,
  STATUS_NAMES.approvedPending,
  STATUS_NAMES.reviewComplete,
];

export const viewableDataTypes: string[] = [
  "pdf",
  "html",
  "gif",
  "jpeg",
  "jpg",
  "docx",
  "png",
  "bmp",
  "txt",
  "json",
];

export enum DURATION_TYPE {
  Month = "Month",
  Year = "Year",
  Week = "Week",
}

export const acceptedUploadTypes = {
  "application/pdf": [".pdf"],
  "application/vnd.ms-excel": [".xls"],
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"],
  "text/csv": [".csv"],
  "image/jpeg": [".jpg", ".jpeg"],
  "image/png": [".png"],
  "image/tiff": [".tif", ".tiff"],
  "image/bmp": [".bmp"],
  "image/gif": [".gif"],
  "application/vnd.oasis.opendocument.text": [".odt"],
  "application/vnd.oasis.opendocument.spreadsheet": [".ods"],
  "text/plain": [".txt"],
  "application/json": [".json"],
  "application/msword": [".doc"],
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
  "application/rtf": [".rtf"],
  "text/html": [".html"],
  "application/vnd.ms-excel.sheet.macroEnabled.12": [".xlsm"],
};

export const maxFileSize = 2.5e7;

export const CashoutLRASError =
  '"cashOutAmount" up to 20% of the property value or $200,000 (lower of the two)';
