import { Reducer } from "redux";
import { ISolutionInitialState, IAccount } from "amm-solution-interface";
import {
  moduleConstant,
  accountPurposeOptions,
  accountTypeOptions,
  cashOutUseDropdown,
} from "../../../constants";
import { LoginUserLogoutAction, LOGIN_USER_LOGOUT } from "../../actions/login/loginActionType";
import * as solutionAction from "../../actions/solutionPageActions/solutionPageActionTypes";
import { solutionInitialState } from "./solutionInitialState";
import * as solution from "./solutionReducerUtility";
import {
  SolutionActions,
  SOLUTION_ACCOUNT_PURPOSE_CHANGE_ALL,
} from "../../actions/solutionPageActions/solutionPageActionTypes";
import { deepCopyObject } from "../../../utility";

const solutionReducer: Reducer<ISolutionInitialState, SolutionActions | LoginUserLogoutAction> = (
  state: ISolutionInitialState = deepCopyObject(solutionInitialState),
  action: SolutionActions | LoginUserLogoutAction
) => {
  if (
    action &&
    action.module &&
    action.module.filter((currentModule) => Object.values(moduleConstant).includes(currentModule))
      .length === 0
  ) {
    return { ...state };
  }

  const currentState = Object.assign({}, state);
  let account: ISolutionInitialState["account"] = [...currentState.account];
  const activeTab = state.currentTab;
  account = account.slice();

  if (
    action &&
    action.module &&
    action.module.filter((currentModule) => Object.values(moduleConstant).includes(currentModule))
      .length === 0
  ) {
    return { ...state };
  }

  switch (action.type) {
    case solutionAction.SOLUTION_ACCOUNT_CURRENT_TAB:
      return solution.currentTabFunction(currentState, action.currentTab);

    case solutionAction.SOLUTION_ACCOUNT_ADD_NEW:
      return solution.addNewAccount(currentState, account, action.accountPurpose);

    case solutionAction.SOLUTION_ACCOUNT_STATE_UPDATE:
      // TODO fix this
      return solution.accountStateUpdate(
        action,
        JSON.parse(JSON.stringify(account)),
        currentState,
        activeTab
      );

    case solutionAction.SOLUTION_REMOVE_ACCOUNT:
      return solution.RemoveAccount(action, account, currentState, activeTab);

    case solutionAction.SOLUTION_SOLUTION_STATE_UPDATE:
      return solution.solutionStateUpdate(action, currentState);

    case solutionAction.SOLUTION_GET_LOAN_AMOUNT:
      return solution.loanCalculator(currentState, action);

    case solutionAction.SOLUTION_CASHOUT_NOTE_UPDATE:
      return {
        ...currentState,
        cashOutNote: action.note,
      };

    case solutionAction.SOLUTION_ACCOUNT_AMOUNT_CHANGE:
      return solution.accountAmountChange(currentState);

    case solutionAction.SOLUTION_LOAD_ENTIRE_STATE:
      if (!cashOutUseDropdown.includes(action.state.cashOutUse)) {
        action.state.cashOutUse = "";
      }
      return {
        ...action.state,
      };

    case LOGIN_USER_LOGOUT:
      return { ...solutionInitialState };

    case SOLUTION_ACCOUNT_PURPOSE_CHANGE_ALL:
      const accounts: IAccount[] = state.account;
      accounts.map((act) => {
        act.accountPurpose = action.accountPurpose;
        act.accountType =
          action.accountPurpose === accountPurposeOptions[0].value
            ? (accountTypeOptions[0].value as string)
            : act.accountType;
      });
      return {
        ...state,
        account: accounts,
      };

    default:
      return { ...currentState };
  }
};

export default solutionReducer;
