import { ISolutionInitialState } from "amm-solution-interface";
import React, { ReactNode, useEffect, useState } from "react";
import Slider from "react-rangeslider";
import { connect } from "react-redux";
import { errorFormTrackerAction } from "../../store/actions/errorActions/errorAction";
import { ErrorFormTrackerActionType } from "../../store/actions/errorActions/errorActionType";
import { helpSidebarAnimationClassAction } from "../../store/actions/helpActions/helpAction";
import { HelpSidebarAnimationClassAction } from "../../store/actions/helpActions/helpActionType";
import { reviewSetValidationAction } from "../../store/actions/reviewActions/reviewActions";
import { ReviewSetValidationActionType } from "../../store/actions/reviewActions/reviewActionTypes";
import {
  solutionAccountAmountChangeAction,
  solutionAccountStateUpdateAction,
} from "../../store/actions/solutionPageActions/solutionPageActions";
import {
  SolutionAccountAmountChangeActionType,
  SolutionAccountStateUpdateActionType,
} from "../../store/actions/solutionPageActions/solutionPageActionTypes";
import { getAlgoliaRecordsThunk } from "../../store/thunk/helpSectionThunk/getAlgoliaRecordsThunk";
import { isLoanReviewProps, LoanAmountSectionProps } from "../../utility/loanAmountSectionUtility";
import { checkIfAllValidationIsCorrect } from "../../utility/validationPerPage";
import { CustomInputField } from "../common/customInputField";
import { TAppStateType } from "../../store/reducers/reducers";

const loanMin = Number(process.env.REACT_APP_LOAN_AMOUNT_MIN);
interface OwnProps {
  className: string;
  label: string;
  reviewPage: boolean;
  totalState: TAppStateType;
}

interface StateProps {
  count: number;
  loan: ISolutionInitialState;
  showReviewScreen: boolean;
}
interface DispatchProps {
  getAlgoliaRecordsThunk: (clickId: string, subheading?: boolean) => void;
  helpSidebarAnimationClassAction: (
    className: string,
    count: number
  ) => HelpSidebarAnimationClassAction;
  errorFormTrackerAction: ErrorFormTrackerActionType;
  reviewSetValidationAction: ReviewSetValidationActionType;
  solutionAccountAmountChangeAction: SolutionAccountAmountChangeActionType;
  solutionAccountStateUpdateAction: SolutionAccountStateUpdateActionType;
}

export type LoanAmountSectionReviewProps = StateProps & DispatchProps & OwnProps;

export const LoanAmountSectionComponent: React.FC<
  LoanAmountSectionProps | LoanAmountSectionReviewProps
  > = (props: LoanAmountSectionProps | LoanAmountSectionReviewProps) => {
    const loanMax = props.loan.loanMax;
  const sliderClassName = () => {
    let classValue: string = "";

    classValue = props.showReviewScreen
      ? isLoanReviewProps(props) && props.className === "md-text--error"
        ? "error-slider"
        : isLoanReviewProps(props) && props.className === "text-amber"
        ? "wrong-value"
        : ""
      : props.loan.propertiesTotal < props.loan.loanAmount ||
        props.loan.loanAmount !== props.loan.accountTotalAmount
      ? "wrong-value"
      : "";

    return classValue;
  };

  const [localLoanAmount,setLocalLoanAmount] = useState(props.loan.loanAmount);

  const handleLoanAmountChange = (e:ReactNode)=>{
    setLocalLoanAmount(Number(e))
  }

  useEffect(()=>{
    setLocalLoanAmount(props.loan.loanAmount);
  },[props.loan.loanAmount]);

  const inputValidator = () => {
    props.solutionAccountStateUpdateAction("loanAmount", Number(localLoanAmount), "");
    if ((props.count === 0 || props.count === 1) && !props.showReviewScreen) {
      props.helpSidebarAnimationClassAction("sidebar_show-score", 1);
    }
    if (Math.floor(props.loan.accountTotalAmount) > Math.floor(localLoanAmount)) {
      props.errorFormTrackerAction(
        "solution",
        "accountAmount",
        "slider",
        true,
        "Invalid account amount value"
      );
    } else {
      props.errorFormTrackerAction("solution", "accountAmount", "slider", false);
    }
    props.reviewSetValidationAction(
      checkIfAllValidationIsCorrect("solution", props.totalState),
      "solution"
    );

    if (!localLoanAmount || localLoanAmount <= loanMin) {
      props.solutionAccountStateUpdateAction("loanAmount", loanMin, "");
      
      props.solutionAccountAmountChangeAction();
    } else if (localLoanAmount >= loanMax) {
      props.solutionAccountStateUpdateAction("loanAmount", loanMax, "");
     
      props.solutionAccountAmountChangeAction();
    } else {
      props.solutionAccountStateUpdateAction("loanAmount", Math.floor(localLoanAmount), "");
    }
  };

  return (
    <div className={isLoanReviewProps(props) ? "review-page_loan-amount" : "loan_amount--section"}>
      <CustomInputField
        onClick={(e) => props.getAlgoliaRecordsThunk("10010")}
        id="loan-amount"
        name="loanAmount"
        label={isLoanReviewProps(props) ? props.label : "Loan amount:"}
        type="text"
        step={1000}
        shiftStep={10000}
        max={loanMax}
        min={loanMin}
        value={localLoanAmount}
        showDollar={true}
        className={`${
          isLoanReviewProps(props) && props.className
        } group-row label__animation_remove width-130 review-page-loan-amount`}
        onBlur={() => inputValidator()}
        onChange={(e: ReactNode) => handleLoanAmountChange(e)}
      />

      <div
        className={`range-slider-wrap rang-slide--label-fixed-width corner-align--label review-page-loan-slider ${sliderClassName()}`}
      >
        <Slider
          min={loanMin}
          tooltip={false}
          max={loanMax}
          value={localLoanAmount}
          step={10000}
          onChange={(e) => {
            handleLoanAmountChange(e);
          }}
          onChangeComplete={()=>{
            props.solutionAccountStateUpdateAction("loanAmount", Math.floor(localLoanAmount), "");
          }}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state: TAppStateType) => {
  return {
    count: state.help.count,
    loan: state.loan,
    totalState: state,
    showReviewScreen: state.help.showReviewScreen,
  };
};

const mapDispatchToProps = {
  errorFormTrackerAction,
  getAlgoliaRecordsThunk,
  reviewSetValidationAction,
  helpSidebarAnimationClassAction,
  solutionAccountAmountChangeAction,
  solutionAccountStateUpdateAction,
};

export const LoanAmountSection = connect(
  mapStateToProps,
  mapDispatchToProps
)(LoanAmountSectionComponent);

export const LoanAmountSectionReview = connect(
  mapStateToProps,
  mapDispatchToProps
)(LoanAmountSectionComponent as React.FC<LoanAmountSectionReviewProps>);
