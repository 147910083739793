import React from "react";
import { Snackbar } from "react-md";
import { connect } from "react-redux";
import { errorSnackBarCloseAction } from "../../store/actions/errorActions/errorAction";
import { IToastsInterface } from "../../store/reducers/successSnackbarReducer";
import { TAppStateType } from "../../store/reducers/reducers";
import { ErrorSnackbarCloseActionType } from "../../store/actions/errorActions/errorActionType";

interface StateProps {
  toasts: IToastsInterface[];
  autohide: boolean;
}
interface DispatchProps {
  errorSnackBarCloseAction: ErrorSnackbarCloseActionType;
}

type ErrorSnackbarProps = StateProps & DispatchProps;

export const ErrorSnackbar: React.FC<ErrorSnackbarProps> = (props: ErrorSnackbarProps) => {
  return (
    <Snackbar
      id="snackbar"
      className="error-snackbar"
      toasts={props.toasts as any}
      autohide={props.autohide}
      onDismiss={props.errorSnackBarCloseAction}
    />
  );
};

export const mapStateToProps = (state: TAppStateType) => {
  return {
    autohide: state.error.autohide,
    toasts: state.error.toasts,
  };
};

export const mapDispatchToProps = {
  errorSnackBarCloseAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(ErrorSnackbar);
