import React from "react";

const TickIconFill: React.FC = () => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 35 35"
      className="icon footer-icon footer-review-icon footer-solution-icon"
    >
      <path
        fill="inherit"
        d="M17.5,0C7.8,0,0,7.8,0,17.5S7.8,35,17.5,35S35,27.2,35,17.5S27.2,0,17.5,0z M15.9,25.2l-1.4,1.4L14,26.2l-1-1  l-5.7-5.7l1.4-1.4l5.7,5.7L27.2,11l1.4,1.4L15.9,25.2z"
      />
    </svg>
  );
};

export default TickIconFill;
