import { ILRAS, IPropertyArray } from "amm-objective-interface";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { calcLRASThunk } from "../../store/thunk/helpSectionThunk/calcLRASThunk";
import ProgressBar from "./progressBar";
import {
  IFinancialObligation,
  IApplicant,
  ICreditCards,
  IAssets,
  ILivingExpenses,
  IIncomeSources,
} from "amm-applicant-interface";
import { TAppStateType } from "../../store/reducers/reducers";
import { applicantFinancialObligationLoanTypeOptions } from "../../constants";
import { useLocation } from "react-router-dom";
import { CircularProgressReview } from "./CircularProgress";
import { styled } from "@material-ui/styles";
import { Accordion, AccordionDetails, AccordionSummary } from "../common/Accordian";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import IncomeExpense from "./incomeExpense";

interface ILoanMetricsState {
  lras: ILRAS;
  token: string;
  state: TAppStateType;
}

const CircleContainer = styled("div")({
  marginBottom: 25,
  display: "grid",
});

const CircleContent = styled("div")(({ theme }) => ({
  width: "100%",
  alignContent: "center",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: 10,

  "& .heading": {
    width: "60%",
    margin: 0,
    fontSize: 16,
    color: "inherit",
  },
  "& .mortgage-score": {
    fontSize: 80,
    margin: 0,
    color: "inherit",
  },
}));

interface ILoanMetricsDispatch {
  calcLRASThunk: (state: TAppStateType, token: string) => void;
}

type ILoanMetrics = ILoanMetricsState & ILoanMetricsDispatch;
const LoanMetrics: React.FC<ILoanMetrics> = (props: ILoanMetrics) => {
  const { pathname } = useLocation();
  useEffect(() => {
    const newState = Object.assign({}, props.state);

    // for financial obligation loan changes.
    const newApplicant = Object.assign({}, newState.applicant);
    let applicants = Object.assign([], newApplicant.applicants);
    applicants = JSON.parse(JSON.stringify(applicants));
    applicants.map((applicant: IApplicant) => {
      const financialDummy: IFinancialObligation[] = [];
      applicant.financialObligation.map((obligation) => {
        if (
          !(
            (applicantFinancialObligationLoanTypeOptions.includes(obligation.type) &&
              obligation.isLoanRefinanced) ||
            obligation.isDeleted
          ) &&
          obligation.type
        ) {
          financialDummy.push(obligation);
        }
      });
      applicant.financialObligation = JSON.parse(JSON.stringify(financialDummy));

      const creditDummy: ICreditCards[] = [];
      applicant.creditCards.map((creditCard) => {
        if (!creditCard.isDeleted && !creditCard.isCreditCardRefinanced) {
          creditDummy.push(creditCard);
        }
      });
      applicant.creditCards = JSON.parse(JSON.stringify(creditDummy));

      const assetsDummy: IAssets[] = [];
      applicant.assets.map((asset) => {
        if (!asset.isDeleted && asset.type) {
          assetsDummy.push(asset);
        }
      });
      applicant.assets = JSON.parse(JSON.stringify(assetsDummy));

      const livingExpenseDummy: ILivingExpenses[] = [];
      applicant.livingExpenses.map((expense) => {
        if (!expense.isDeleted && expense.type) {
          livingExpenseDummy.push(expense);
        }
      });
      applicant.livingExpenses = JSON.parse(JSON.stringify(livingExpenseDummy));

      const incomeSourcesDummy: IIncomeSources[] = [];
      applicant.incomeSources.map((source) => {
        if (!source.isDeleted && source.type) {
          incomeSourcesDummy.push(source);
        }
      });
      applicant.incomeSources = JSON.parse(JSON.stringify(incomeSourcesDummy));
    });
    newApplicant.applicants = applicants;
    newState.applicant = newApplicant;

    // for addition of another property LVR shouldn't change
    const objective = Object.assign({}, newState.objective);
    let properties = Object.assign([], objective.properties);
    properties = JSON.parse(JSON.stringify(properties));
    const newProperties: IPropertyArray[] = new Array();
    if (properties.length > 1) {
      properties.map((property: IPropertyArray) => {
        if (property.propertyValue !== 0 || property.propertyAddress !== "") {
          newProperties.push(property);
        }
      });
      objective.properties = JSON.parse(JSON.stringify(newProperties));
      newState.objective = objective;
    }

    // TODO: move this graphql to cache results and improve trigger filtering
    const timer = setTimeout(() => {
      if (
        pathname.includes("property") ||
        pathname.includes("solution") ||
        pathname.includes("applicants") ||
        pathname.includes("affordability")
      ) {
        props.calcLRASThunk(newState, props.token);
      }
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  }, [
    // todo clean this later
    props.state.objective.properties,
    props.state.loan.loanAmount,
    props.state.loan.account,
    props.state.loan.cashOutAmount,
    props.state.loan.cashOutUse,
    props.state.loan.cashOutUse,
    props.state.applicant.applicants,
  ]);

  const debtToIncomeClassName = () => {
    const dti = props.lras.loanMetrics.debtToIncomePercentage
      ? Number(props.lras.loanMetrics.debtToIncomePercentage.toFixed(1))
      : props.lras.loanMetrics.debtToIncomePercentage;
    if (
      dti > 6 && // changes from ticket number AMM-2238
      dti <= Number(process.env.REACT_APP_DEBT_TO_INCOME_PERCENTAGE)
    ) {
      return "limitWarning loan-metric-loan-to-income";
    } else if (
      props.lras.loanMetrics.debtToIncomePercentage >
      Number(process.env.REACT_APP_DEBT_TO_INCOME_PERCENTAGE)
    ) {
      return "overLimit loan-metric-loan-to-income";
    } else {
      return "loan-metric-loan-to-income";
    }
  };

  const score = Math.round(props.lras.lrasOutputs.mortgageScore);

  return (
    <React.Fragment>
      <h5 className="loan-metric-heading">
        <strong>Loan Metrics</strong>
      </h5>
      <CircleContainer>
        <CircularProgressReview
          outerValue={score / 10}
          size={240}
          outerThickness={1.2}
          warning={score < 700}
        >
          <CircleContent>
            <h3 className="heading">Intelligent Credit Mortgage Score&reg;</h3>
            <h1 className="mortgage-score">{score}</h1>
          </CircleContent>
        </CircularProgressReview>
      </CircleContainer>
      <ProgressBar
        className={
          props.lras.lvr > parseFloat(process.env.REACT_APP_LVR_LIMIT!)
            ? "overLimit loan-metric-loan-to-value-ratio"
            : "loan-metric-loan-to-value-ratio"
        }
        value={props.lras.lvr ? (props.lras.lvr * 100).toFixed(0) : 0}
        width={Math.min(100, props.lras.lvr ? props.lras.lvr * 100 : 0 * 100).toFixed(0) + "%"}
        labelValue="Loan to Value Ratio"
        percent={true}
      />
      <ProgressBar
        className={
          props.lras.nsr < parseFloat(process.env.REACT_APP_NSR_LIMIT!)
            ? "overLimit loan-metric-net-serviceability-ratio"
            : "loan-metric-net-serviceability-ratio"
        }
        value={props.lras.nsr ? props.lras.nsr.toFixed(2) : 0}
        width={(props.lras.nsr ? props.lras.nsr : 0) * 100 + "%"}
        labelValue="Net Serviceability Ratio"
      />
      <ProgressBar
        className={debtToIncomeClassName()}
        value={
          props.lras.loanMetrics.debtToIncomePercentage
            ? props.lras.loanMetrics.debtToIncomePercentage.toFixed(1)
            : 0
        }
        width={Math.min(100, props.lras.loanMetrics.debtToIncomePercentage * 10) + "%"}
        labelValue="Debt to Income"
      />

      <IncomeExpense />
      <div className="loan-metric-accordion-wrapper">
        <Accordion className="loan-metric-accordion-item">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon style={{ fill: "white" }} />}
            aria-controls="panel1d-content"
            id="panel1d-header"
          >
            <strong>Credit category</strong>
          </AccordionSummary>

          <AccordionDetails>
            <ul className="sidebar-expand-list">
              {props.state.applicant.applicants.map((applicant: IApplicant) => {
                const creditScore: number = applicant.privacyConsent.creditScore;
                const category =
                  creditScore > 699 ? 1 : creditScore > 649 ? 2 : creditScore > 599 ? 3 : 4;

                return (
                  <li>
                    {`${applicant.firstName} ${applicant.lastName}`} : {category}
                  </li>
                );
              })}
            </ul>
          </AccordionDetails>
        </Accordion>

        <Accordion className="loan-metric-accordion-item">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon style={{ fill: "white" }} />}
            aria-controls="panel1d-content"
            id="panel1d-header"
          >
            <strong>Property category</strong>
          </AccordionSummary>

          <AccordionDetails>
            <ul className="sidebar-expand-list">
              {props.state.objective.properties.map((property: IPropertyArray, index: number) => (
                <li>
                  {`Property ${index + 1}`} : {property.propertyCategory}
                </li>
              ))}
            </ul>
          </AccordionDetails>
        </Accordion>
      </div>
    </React.Fragment>
  );
};

export const mapStateToProps = (state: TAppStateType) => {
  return {
    lras: state.help.lras,
    token: state.login.token as string,
    state,
  };
};
export const dispatchStateToProps: ILoanMetricsDispatch = {
  calcLRASThunk,
};

export default connect(mapStateToProps, dispatchStateToProps)(LoanMetrics);
