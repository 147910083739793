import React from "react";

const QuestionMarkIcon = (props: { className: string }) => {
  return (
    <svg className={props.className} viewBox="0 0 13.7374 13.7374">
      <path
        d="M6.9722.09a6.8687,6.8687,0,1,0,6.8687,6.8687A6.8687,6.8687,0,0,0,6.9722.09Zm-.1593,12.599A1.3162,1.3162,0,1,1,8.13,11.3732,1.3314,1.3314,0,0,1,6.8129,12.6889ZM7.8307,7.9928v1.203H5.8157V7.749c0-1.9172,2.389-2.1447,2.389-3.2661,0-.5037-.39-.9263-1.2839-.9263A2.5472,2.5472,0,0,0,4.9222,4.5643L3.6055,3.0852A4.5963,4.5963,0,0,1,7.1647,1.5088c2.0964,0,3.38,1.0564,3.38,2.5678C10.5445,6.4492,7.8307,6.628,7.8307,7.9928Z"
        transform="translate(-0.1035 -0.0899)"
      />
    </svg>
  );
};

export default QuestionMarkIcon;
