import React, { useState, useEffect } from "react";
import { Dialog, DialogContent, DialogTitle, DialogActions } from "@material-ui/core";
import { Button } from "react-md";
import { connect } from "react-redux";
import RichTextEditor, { EditorValue } from "react-rte";
import { TAppStateType } from "../../store/reducers/reducers";
import { adminBrokerAddNotes } from "../../store/actions/adminActions/adminActions";
import { AdminBrokerAddNotesActionType } from "../../store/actions/adminActions/adminActionTypes";
import { STATUS_NAMES } from "../../constants";
import { toolbarConfig } from "../../utility/toolBarConfig";

interface OwnProps {
  isBrokerNotesModalOpen: boolean;
  setIsBrokerNotesModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
interface StateProps {
  currentUserId: number;
  currentScenarioOwnerID: number;
  brokerNotes: string;
  currentScenarioStatus: string;
  isTeamMember: boolean;
}
interface DispatchProps {
  adminBrokerAddNotes: AdminBrokerAddNotesActionType;
}
type BrokerNotesModalProps = OwnProps & StateProps & DispatchProps;

const brokerNotesModal: React.FC<BrokerNotesModalProps> = (props: BrokerNotesModalProps) => {
  const [textValue, setTextValue] = useState<EditorValue>(RichTextEditor.createEmptyValue());

  useEffect(() => {
    setTextValue(RichTextEditor.createValueFromString(props.brokerNotes, "html"));
  }, [props.isBrokerNotesModalOpen]);

 

  const disableHandler = () => {
    const isScenarioOwner = props.currentUserId === props.currentScenarioOwnerID;
    const isScenarioInScenarioOrReworkStatus =
      props.currentScenarioStatus === STATUS_NAMES.scenario ||
      props.currentScenarioStatus === STATUS_NAMES.reWork;

    if (isScenarioInScenarioOrReworkStatus) {
      if (isScenarioOwner) {
        return false;
      } else if (props.isTeamMember) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  const saveHandler = () => {
    const markdownValueTrimmed = textValue.toString("markdown").trimLeft();

    const matchedRegex =
      new RegExp(/\*\*( )+\*\*\n/).test(markdownValueTrimmed) || // If LW enters only spaces with bold
      new RegExp(/_( )+_\n/).test(markdownValueTrimmed) || // If LW enters only spaces with italic
      new RegExp(/- (​)\n/).test(markdownValueTrimmed) || // If LW enter only unordered list
      new RegExp(/-( )+\n/).test(markdownValueTrimmed) || // If LW enters only spaces with unordered list
      new RegExp(/\*\*( )+\*\*_\*\*( )+\*\*_\n/).test(markdownValueTrimmed) || // If LW enters only spaces with first bold and then italic
      new RegExp(/_( )+__\*\*( )+\*\*_\n/).test(markdownValueTrimmed); // If LW enters only spaces with first italic and then bold

    if (markdownValueTrimmed !== "" && markdownValueTrimmed !== "# ​\n" && !matchedRegex) {
      props.adminBrokerAddNotes(textValue.toString("html"));
    } else {
      props.adminBrokerAddNotes(RichTextEditor.createEmptyValue().toString("html"));
    }
    props.setIsBrokerNotesModalOpen(false);
  };

  return (
    <div id="broker-notes-modal">
      <Dialog
        id="broker-notes-modal"
        aria-labelledby="customized-dialog-title"
        open={props.isBrokerNotesModalOpen}
        className="broker-notes-modal"
        disableBackdropClick
      >
        <DialogTitle className="user-modal-title">Loan Writer Notes</DialogTitle>
        <DialogContent dividers>
          <RichTextEditor
            className={
              !disableHandler()
                ? "broker-notes-modal-text-area"
                : "broker-notes-modal-text-area-disabled"
            }
            value={textValue}
            onChange={(value) => setTextValue(value)}
            toolbarConfig={toolbarConfig}
            placeholder={!disableHandler() ? "Please type a note here..." : ""}
            readOnly={disableHandler()}
          />
        </DialogContent>
        <DialogActions>
          <Button
            className="broker-notes-modal-cancel-btn"
            flat={true}
            swapTheming={true}
            onClick={() => props.setIsBrokerNotesModalOpen(false)}
          >
            Cancel
          </Button>
          <Button
            className="broker-notes-modal-save-btn"
            flat={true}
            secondary={true}
            swapTheming={true}
            disabled={disableHandler()}
            onClick={saveHandler}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state: TAppStateType) => {
  const teamId = state.login.currentTeamID;
  return {
    currentUserId: state.login.id ? state.login.id : 0,
    currentScenarioOwnerID: state.scenario.ownerID ? state.scenario.ownerID : 0,
    brokerNotes: state.admin.brokerNotesData,
    currentScenarioStatus: state.scenario.currentScenarioStatus,
    isTeamMember: state.login.teams[teamId] ? state.login.teams[teamId].isMyTeam : false,
  };
};

const dispatchStateToProps = {
  adminBrokerAddNotes,
};

export default connect(mapStateToProps, dispatchStateToProps)(brokerNotesModal);
