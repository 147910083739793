import { moduleConstant } from "../../../constants";
import { LOGIN_USER_LOGOUT, LoginUserLogoutAction } from "../../actions/login/loginActionType";
import { IErrorStateInterface } from "./../errorReducers/errorReducer";
import { ErrorFormTrackerAction } from "../../actions/errorActions/errorActionType";

export const errorFormReducerUtility = (
  currentState: IErrorStateInterface,
  action: ErrorFormTrackerAction | LoginUserLogoutAction
) => {
  if (
    action &&
    action.module &&
    action.module.filter((currentModule) => Object.values(moduleConstant).includes(currentModule))
      .length === 0
  ) {
    return { ...currentState };
  }
  if (action.type === LOGIN_USER_LOGOUT) {
    return { ...currentState, errorTracker: {} };
  }

  if (action.status) {
    if (!currentState.errorTracker[action.page]) {
      currentState.errorTracker[action.page] = {};
    }
    if (!currentState.errorTracker[action.page][action.section]) {
      currentState.errorTracker[action.page][action.section] = {};
    }
    currentState.errorTracker[action.page][action.section][action.location] = {
      description: action.description as string,
      status: action.status,
    };
    if (
      currentState.errorTracker[action.page] &&
      currentState.errorTracker[action.page][action.section] &&
      currentState.errorTracker[action.page][action.section][action.location]
    ) {
      if (action.page === "objective") {
        currentState.objectiveErrorIndicator = true;
      } else if (action.page === "solution") {
        currentState.loanErrorIndicator = true;
      } else {
        currentState.affordabilityErrorIndicator = true;
      }
    }

    return currentState;
  } else {
    if (
      currentState.errorTracker &&
      currentState.errorTracker[action.page] &&
      currentState.errorTracker[action.page][action.section] &&
      currentState.errorTracker[action.page][action.section][action.location]
    ) {
      delete currentState.errorTracker[action.page][action.section][action.location];
      if (Object.keys(currentState.errorTracker[action.page][action.section]).length === 0) {
        if (action.page === "objective") {
          currentState.objectiveErrorIndicator = false;
        } else if (action.page === "solution") {
          currentState.loanErrorIndicator = false;
        } else {
          currentState.affordabilityErrorIndicator = false;
        }
      }
    }
    if (
      currentState.errorTracker &&
      currentState.errorTracker[action.page] &&
      currentState.errorTracker[action.page][action.section] &&
      Object.keys(currentState.errorTracker[action.page][action.section]).length === 0
    ) {
      delete currentState.errorTracker[action.page][action.section];
    }
    if (
      currentState.errorTracker &&
      currentState.errorTracker[action.page] &&
      Object.keys(currentState.errorTracker[action.page]).length === 0
    ) {
      delete currentState.errorTracker[action.page];
      currentState.errorTracker = {};
    }

    return currentState;
  }
};
