import { IAccount } from "amm-solution-interface";
import { IApplicant } from "amm-applicant-interface";
import { apiIntegrationDomains, notSubmitableStatuses, Status, STATUS_NAMES } from "../constants";
import { IPropertyArray } from "amm-objective-interface";
import { ITeam, ITeamMembers, TScenarioType } from "amm-admin-interface";
import { IScenarioPayloadUser, IScenarioPayloadUserScenarios } from "amm-scenario-interface";

export const stringToNumberTextConvert = (e: React.ReactText) => {
  if (!e) {
    e = "0";
  }
  let accountValString: string = "";
  if (typeof e === "string") {
    const splitStr = e.split("");
    for (const i of range(0, splitStr.length)) {
      if ("0123456789".indexOf(splitStr[i]) !== -1 || splitStr[i] === ".") {
        accountValString = accountValString + splitStr[i];
      }
    }
  }
  if (!accountValString) {
    accountValString = "0";
  }
  return parseFloat(accountValString);
};

export const suburbView = (property: IPropertyArray) => {
  return (
    property.purchaseOrRefinance === "Purchase" &&
    (property.purchaseType === "I'm still looking" || property.propertyNewOrOfThePlan === "Yes")
  );
};

export const isPropertyNotFound = (property: IPropertyArray) => {
  return property.noPropertyFound;
};

export const stringToNumberConvert = (e: React.ReactText) => {
  let accountVal: number = 0;
  if (typeof e === "string") {
    const splitStr = e.split("");
    let finalStr: string = "";
    for (let i = 0; i < splitStr.length; i++) {
      if (splitStr[i] === ".") {
        const afterDecimal = splitStr.length - (i + 1);
        // afterDecimal = afterDecimal - 3;
        if (afterDecimal > 3) {
          finalStr = e.substr(0, i + 4);
          break;
        } else {
          finalStr = e;
        }
      } else {
        finalStr = e;
      }
    }
    accountVal = parseFloat(finalStr);
    accountVal = accountVal > 0 ? accountVal : 0;
  } else {
    accountVal = e;
  }
  return accountVal;
};

export const kFormatter = (num: number) => {
  return num > 999 ? (num / 1000).toFixed(1) + "k" : num;
};

export const deepCopyObject = <T extends {}>(obj: T): T => {
  return JSON.parse(JSON.stringify(obj)) as T;
};

export const range = (start: number, end: number, step = 1) => {
  const list: number[] = [];
  for (let i = start; i < end; i += step) {
    list.push(i);
  }
  return list;
};

export const checkPropertiesHasAddress = (properties: IPropertyArray[]) => {
  let hasAddress: boolean = false;
  properties.map((property, key) => {
    if (!property.name) {
      hasAddress = true;
    }
  });

  return hasAddress;
};

export const checkPropertiesHaveValue = (properties: IPropertyArray[]) => {
  let hasValue: boolean = false;
  properties.map((property, key) => {
    if (property.propertyValue > 0) {
      hasValue = true;
    }
  });

  return hasValue;
};

export const checkApplicantTotalIncome = (applicants: IApplicant[]) => {
  let applicatnTotalIncome = 0;
  applicants.forEach((element) => {
    element.incomeSources.forEach((el) => {
      applicatnTotalIncome = applicatnTotalIncome + el.amount;
    });
  });
  return applicatnTotalIncome > 0;
};

export const loanAccounts = (accounts: IAccount[]) => {
  const accountsArray: IAccount[] = accounts.filter((account) => account.amount !== undefined);
  return accountsArray;
};

export const setRowType = (value: string, rowType: number | string) => {
  if (value === "brokerStatus") {
    return Status.includes(rowType as string) ? rowType : Status[0];
  } else if (value === "roles") {
    switch (rowType) {
      case 1:
        return "FROZEN";
      case 2:
        return "BROKER";
      case 3:
        return "ADMIN";
      default:
        return;
    }
  }
  return;
};

//@ts-ignore
export const padZero = (str, max) => {
  str = str.toString();
  return str.length < max ? padZero("0" + str, max) : str;
};

export const formatPhoneNumber = (mobileNumber: string) => {
  const numberStartsWith61 = new RegExp(/^61/).test(mobileNumber);
  const numberStartsWith0 = new RegExp(/^0/).test(mobileNumber);
  if (numberStartsWith61) {
    return `+${mobileNumber}`;
  } else if (numberStartsWith0) {
    return `+61${mobileNumber.slice(1)}`;
  } else {
    return `+61${mobileNumber}`;
  }
};

export const isMocked = (
  teams: ITeam,
  currentTeamID: number,
  selectedUserScenarios: IScenarioPayloadUserScenarios[],
  currentScenarioId: number,
  userIDForScenario: number,
  currentUserID: number
) => {
  const members =
    teams[currentTeamID] && teams[currentTeamID].isMyTeam
      ? (teams[currentTeamID].teamMembers as ITeamMembers[])
      : [];
  const currentScenario = selectedUserScenarios.find(
    (scenario) => scenario.id === currentScenarioId
  );
  if (currentScenario && currentScenario.statuses) {
    if (currentScenario.statuses[0] && currentScenario.statuses[0].status) {
      return (
        currentUserID !== userIDForScenario &&
        members.length &&
        members.find((member) => member.id === userIDForScenario) &&
        (currentScenario.statuses[0].status === STATUS_NAMES.scenario ||
          currentScenario.statuses[0].status === STATUS_NAMES.reWork)
      );
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const getMockedScenarioStatus = (
  teams: ITeam,
  currentTeamID: number,
  selectedUserScenarios: IScenarioPayloadUserScenarios[],
  currentScenarioId: number,
  userIDForScenario: number,
  currentUserID: number
) => {
  const members =
    teams[currentTeamID] && teams[currentTeamID].isMyTeam
      ? (teams[currentTeamID].teamMembers as ITeamMembers[])
      : [];
  const currentScenario = selectedUserScenarios.find(
    (scenario) => scenario.id === currentScenarioId
  );
  if (currentScenario && currentScenario.statuses) {
    if (currentScenario.statuses[0] && currentScenario.statuses[0].status) {
      return currentUserID !== userIDForScenario &&
        members &&
        members.length &&
        members.find((member) => member.id === userIDForScenario) &&
        currentScenario.statuses[0].status
        ? currentScenario.statuses[0].status
        : "";
    } else {
      return "";
    }
  } else {
    return "";
  }
};

export const isLegacyIDVisible = () =>
  apiIntegrationDomains.includes(`${process.env.REACT_APP_DOMAIN}`);
export const isSelectedUserAPILW = (users: IScenarioPayloadUser[], userID: string) => {
  const currentUserObj = users.find((el) => el.id === Number(userID));
  const isRoleMocLoanWriter = currentUserObj ? currentUserObj.roles[0].id === 8 : false;
  const isRoleAfgLoanWriter = currentUserObj ? currentUserObj.roles[0].id === 11 : false;
  return isRoleMocLoanWriter || isRoleAfgLoanWriter;
};

export const isSelectedTeamMemeberAPILW = (userID: string, teamMembers?: ITeamMembers[]) => {
  const currentUserObj = teamMembers ? teamMembers.find((el) => el.id === Number(userID)) : "";
  const isRoleMocLoanWriter = currentUserObj ? currentUserObj.roleID === 8 : false;
  const isRoleAfgLoanWriter = currentUserObj ? currentUserObj.roleID === 11 : false;

  return isRoleMocLoanWriter || isRoleAfgLoanWriter;
};

export const setMaxLoanAmount = (propertyCategories: number[]) => {
  const categoryOneLoanAmount = process.env.REACT_APP_CATEGORY_ONE_AMOUNT
    ? parseFloat(process.env.REACT_APP_CATEGORY_ONE_AMOUNT)
    : 2000000;
  const categoryTwoLoanAmount = process.env.REACT_APP_CATEGORY_TWO_AMOUNT
    ? parseFloat(process.env.REACT_APP_CATEGORY_TWO_AMOUNT)
    : 750000;
  const categoryThreeLoanAmount = process.env.REACT_APP_CATEGORY_THREE_AMOUNT
    ? parseFloat(process.env.REACT_APP_CATEGORY_THREE_AMOUNT)
    : 500000;

  let loanAmountMax = 0;

  propertyCategories.forEach((category: number) => {
    switch (category) {
      case 1:
        loanAmountMax += categoryOneLoanAmount;
        break;
      case 2:
        loanAmountMax += categoryTwoLoanAmount;
        break;
      case 3:
        loanAmountMax += categoryThreeLoanAmount;
        break;
      default:
        loanAmountMax += categoryThreeLoanAmount;
    }
  });

  return loanAmountMax > categoryOneLoanAmount ? categoryOneLoanAmount : loanAmountMax;
};

export const canScenarioNotBeSubmitted = (scenarioStatus: string) => {
  return notSubmitableStatuses.includes(scenarioStatus);
};

export const convertScenarios = (
  scenarios: IScenarioPayloadUserScenarios[],
  isCreditReviewDashboard?: boolean
) => {
  const scenariosArray: TScenarioType[] = scenarios
    .filter((scenario: IScenarioPayloadUserScenarios) => scenario.scenario_datas[0])
    .map((scenario: IScenarioPayloadUserScenarios) => {
      return {
        id: scenario.id,
        updated_at: scenario.updated_at ? scenario.updated_at : "",
        created_at: scenario.created_at ? scenario.created_at : "",
        applicantFirstName:
          scenario.scenario_datas.length > 0 && scenario.scenario_datas[0].applicantFirstName
            ? scenario.scenario_datas[0].applicantFirstName
            : "",
        applicantLastName:
          scenario.scenario_datas.length > 0 && scenario.scenario_datas[0].applicantLastName
            ? scenario.scenario_datas[0].applicantLastName
            : "",
        loanAmount:
          scenario.scenario_datas.length && scenario.scenario_datas[0].loanAmount
            ? scenario.scenario_datas[0].loanAmount
            : "",
        status:
          scenario.statuses.length > 0 && scenario.statuses[0].status
            ? scenario.statuses[0].status
            : "",
        uploadCount: scenario.uploads[0] ? scenario.uploads[0].count : 0,
        isLead: scenario.is_lead,
        legacyID: scenario.legacy_id,
        ownerName:
          isCreditReviewDashboard && scenario.owner
            ? `${scenario.owner.first_name} ${scenario.owner.last_name}`
            : "",
        reviewerName:
          isCreditReviewDashboard && scenario.reviewer
            ? `${scenario.reviewer.first_name} ${scenario.reviewer.last_name}`
            : "",
        ownerID: scenario.owner_id ? scenario.owner_id : undefined,
        reviewerID: scenario.reviewer_id ? scenario.reviewer_id : undefined,
        capitalLoanID: scenario.scenario_datas[0].capitalLoanId,
        capitalStatus: scenario.scenario_datas[0].capitalStatus,
      };
    });
  return scenariosArray;
};

export interface IISReviewer {
  hasRequiredPermission: boolean;
  reviewerID?: number;
  currentUserID: number;
}

export const isReviewer = (params: IISReviewer) => {
  return params.hasRequiredPermission && params.currentUserID === params.reviewerID;
};

export interface IAffordabilityTotalsActionType {
  rowType: string;
  currentTab: number;
  total: number;
  duration?: string;
  taxStatus?: string;
}
