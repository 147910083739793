import { Auth } from "aws-amplify";
import "../../config/amplify";
import { errorGenericErrorHandleAction } from "../actions/errorActions/errorAction";
import {
  loginIsAuthenticationCodeSentAction,
  loginISNewUserAction,
  loginIsVerificationCodeSentAction,
  loginUserLoginAction,
  loginUserLogoutAction,
} from "../actions/login/loginAction";
import { successSnackBarHandleAction } from "../actions/snackbarSuccessAction/successAction";
import { ThunkAction } from "redux-thunk";
import { Action } from "redux";

export type LoginThunkType = (
  username: string,
  password: string,
  verificationCode: string,
  authenticationCode: string,
  isUserNew?: boolean,
  userPassed?: any
) => ThunkAction<any, any, any, Action>;

export const loginThunk = (
  username: string,
  password: string,
  verificationCode: string,
  authenticationCode: string,
  isUserNew?: boolean,
  userPassed?: any
): ThunkAction<any, any, any, Action> => {
  return (dispatch: any) => {
    if (!username || !password) {
      return dispatch(errorGenericErrorHandleAction("Username and password are required"));
    }
    if (isUserNew) {
      try {
        // if the user phone is already verified then don't send phone_number again.
        const userParams =
          userPassed.challengeParam.userAttributes.phone_number_verified === "true"
            ? {
                email: userPassed.challengeParam.userAttributes.email,
              }
            : {
                email: userPassed.challengeParam.userAttributes.email,
                phone_number: userPassed.challengeParam.userAttributes.phone_number,
              };

        Auth.completeNewPassword(
          userPassed, // the Cognito User Object
          password, // the new password
          userParams
        )
          .then(() => {
            dispatch(successSnackBarHandleAction("Password changed successfully."));
            // if already verified user is trying to change the password.
            if (userPassed.challengeName === "SMS_MFA") {
              dispatch(loginISNewUserAction(false, userPassed));
              return dispatch(loginIsAuthenticationCodeSentAction(true, userPassed));
            }

            // if the user is the new onboarded user.
            Auth.verifyUserAttribute(userPassed, "phone_number")
              .then((res) => {
                dispatch(loginISNewUserAction(false, userPassed));
                return dispatch(loginIsVerificationCodeSentAction(true, userPassed));
              })
              .catch((error) => {
                dispatch(loginISNewUserAction(false, userPassed));
                return dispatch(errorGenericErrorHandleAction(error.message));
              });
          })
          .catch((error) => {
            return dispatch(errorGenericErrorHandleAction(error.message));
          });
      } catch (err) {
        if (err instanceof Error) {
          return dispatch(errorGenericErrorHandleAction(err.message));
        }
      }
    }

    // verifying the user for the first time.
    else if (verificationCode !== "") {
      Auth.verifyUserAttributeSubmit(
        userPassed,
        "phone_number",
        verificationCode // Confirmation code
      )
        .then(() => {
          dispatch(loginIsVerificationCodeSentAction(false, userPassed));
          return dispatch(successSnackBarHandleAction("User verified successfully."));
        })
        .catch((error) => {
          dispatch(loginIsVerificationCodeSentAction(false, userPassed));
          return dispatch(errorGenericErrorHandleAction(error.message));
        });
    }

    // authenticating the User
    else if (authenticationCode !== "") {
      if (userPassed.challengeParam.USER_ID_FOR_SRP) {
        userPassed.username = userPassed.challengeParam.USER_ID_FOR_SRP;
      }
      Auth.confirmSignIn(userPassed, authenticationCode, "SMS_MFA")
        .then(() => {
          dispatch(loginIsAuthenticationCodeSentAction(false, userPassed));
          return dispatch(
            loginUserLoginAction(userPassed.getSignInUserSession().getIdToken().getJwtToken(), true)
          );
        })
        .catch((error) => {
          if (error.code && error.code === "CodeMismatchException") {
            return dispatch(errorGenericErrorHandleAction("Please enter the latest auth code."));
          }
          dispatch(loginIsAuthenticationCodeSentAction(false, userPassed));
          return dispatch(errorGenericErrorHandleAction(error.message));
        });
    }

    // logging in
    else {
      Auth.signIn({
        password,
        username,
      })
        .then(async (user) => {
          // if user is new
          if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
            return dispatch(loginISNewUserAction(true, user));
          }

          // send authentication code for the user
          else if (user.challengeName === "SMS_MFA") {
            return dispatch(loginIsAuthenticationCodeSentAction(true, user));
          }

          // TODO TO BE REMOVED IN PROD, is present only for admin and broker login.
          return dispatch(
            loginUserLoginAction(user.getSignInUserSession().getIdToken().getJwtToken(), true)
          );
        })
        .then(() => {})
        .catch((err) => {
          return dispatch(errorGenericErrorHandleAction(err.message));
        });
    }
  };
};

export type LogoutThunkType = (token: string) => ThunkAction<any, any, any, Action>;

export const logoutThunk: LogoutThunkType = (token: string): ThunkAction<any, any, any, Action> => {
  return (dispatch) => {
    Auth.signOut()
      .then(() => {
        return dispatch(loginUserLogoutAction());
      })
      .catch(() => {
        return dispatch(loginUserLogoutAction());
      });
  };
};
