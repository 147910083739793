import { Auth } from "aws-amplify";
import React, { useEffect, useState } from "react";
import ReactJson from "react-json-view";
import FileViewer from "react-file-viewer";
import Header from "../components/common/header";
import { CognitoUserSession } from "amazon-cognito-identity-js";
import { loginUserLoginAction } from "../store/actions/login/loginAction";
import { LoginUserLoginActionType } from "../store/actions/login/loginActionType";
import { connect } from "react-redux";
import { TAppStateType } from "../store/reducers/reducers";
import {
  viewDocumentThunk,
  ViewDocumentThunkType,
} from "../store/thunk/scenarioThunk/viewDocumentThunk";
import { ErrorGenericErrorHandleActionType } from "../store/actions/errorActions/errorActionType";
import { errorGenericErrorHandleAction } from "../store/actions/errorActions/errorAction";
import { getCSSThunk, GetCSSThunkType } from "../store/thunk/scenarioThunk/getCssThunk";
interface StateProps {
  isLogin: boolean;
  token: string;
  url: string;
}
interface DispatchProps {
  loginUserLoginAction: LoginUserLoginActionType;
  viewDocumentThunk: ViewDocumentThunkType;
  errorGenericErrorHandleAction: ErrorGenericErrorHandleActionType;
  getCSSThunk: GetCSSThunkType;
}

interface IParamData {
  fileName: string;
  fileType: string;
  scenarioId: number;
  isSelfReviewer: string;
  currentUserId: number;
  currentScenarioUserId: number;
  isTeamMemberOrOwnScenario: string;
}

const initialParamData: IParamData = {
  fileName: "",
  fileType: "",
  scenarioId: 0,
  currentUserId: 0,
  currentScenarioUserId: 0,
  isSelfReviewer: "false",
  isTeamMemberOrOwnScenario: "false",
};

type ViewDocumentProps = StateProps & DispatchProps;

export const ViewDocument: React.FC<ViewDocumentProps> = (props: ViewDocumentProps) => {
  const [jsonData, setJsonData] = useState({});
  const [paramData, setParamData] = useState<IParamData>(initialParamData);
  const [url, setUrl] = useState("");

  useEffect(() => {
    const params = new URLSearchParams(window.location.search); // id=123
    loadUserInfo();
    setQueryParamValues(params);
  }, []);

  useEffect(() => {
    if (props.url) {
      setUrl(props.url);
      if (["json"].includes(paramData.fileType)) {
        getJsonObject();
      }
    }
  }, [props.url]);

  useEffect(() => {
    if (props.token) {
      props.viewDocumentThunk(
        +paramData.scenarioId,
        paramData.fileName,
        props.token,
        +paramData.currentUserId,
        +paramData.currentScenarioUserId,
        paramData.fileType,
        paramData.isTeamMemberOrOwnScenario === "true",
        paramData.isSelfReviewer === "true",
        false
      );
    }
  }, [props.token]);

  const setQueryParamValues = (params: URLSearchParams) => {
    Object.keys(initialParamData).forEach((key) => {
      paramData[key] = getParamsValue(params, key);
      setParamData({ ...paramData });
    });
  };

  const loadUserInfo = async () => {
    try {
      const currentSession: CognitoUserSession = await Auth.currentSession();
      props.getCSSThunk(currentSession.getIdToken().payload["custom:originator"]);
      if (
        currentSession.getIdToken().payload.email_verified ||
        currentSession.getIdToken().payload.phone_number_verified
      ) {
        props.loginUserLoginAction(currentSession.getIdToken().getJwtToken(), false);
      } else {
        props.loginUserLoginAction(currentSession.getIdToken().getJwtToken(), false);
      }
    } catch (error) {
      props.errorGenericErrorHandleAction(`${error}`);
    }
  };

  const getParamsValue = (params: URLSearchParams, fileName: string) => {
    const value = params.get(fileName);
    return value ? value : "";
  };

  const renderViewer = () => {
    if (paramData.fileType === "json") {
      return renderJsonView();
    } else if (paramData.fileType === "csv" || paramData.fileType === "docx") {
      return renderCsvDocxView();
    } else {
      return renderHtmlView();
    }
  };

  const renderCsvDocxView = () => {
    return (
      <React.Fragment key={Math.random()}>
        <FileViewer fileType={paramData.fileType} filePath={url} onError={handleError} />
      </React.Fragment>
    );
  };

  const renderJsonView = () => {
    return jsonData === {} ? (
      renderLoader()
    ) : (
      <ReactJson src={jsonData} name="Statement" displayDataTypes={false} collapsed={true} />
    );
  };
  const renderHtmlView = () => {
    return <object type="text/html" data={url} />;
  };

  const renderLoader = () => {
    return (
      <div className="loading-wrap-document">
        <span className="loader-show" />
        Loading...
      </div>
    );
  };

  const handleError = () => {
    props.errorGenericErrorHandleAction("Unable to view the file");
  };

  const getJsonObject = async () => {
    try {
      const response = await fetch(props.url);
      const result = await response.json();
      setJsonData(result);
    } catch (err) {
      handleError();
    }
  };
  return (
    <div className="main-container-full-width">
      <main>
        <Header />
        <h4 className="page-title">{paramData.fileName}</h4>
      </main>
      <div className={`display-inline`}>{renderViewer()}</div>
    </div>
  );
};

const mapStateToProps = (state: TAppStateType) => {
  return {
    isLogin: state.login.isLogin,
    token: state.login.token as string,
    url: state.upload.documentPreviewUrl,
  };
};

const mapDispatchToProps = {
  loginUserLoginAction,
  viewDocumentThunk,
  errorGenericErrorHandleAction,
  getCSSThunk,
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewDocument);
