import React from "react";

type Props = {
  isPublic?: boolean;
};

const FallbackSpinner = ({ isPublic = false }: Props) => {
  return <div className={`${isPublic ? "public-fallback-spinner" : "fallback-spinner"}`} />;
};

export default FallbackSpinner;
