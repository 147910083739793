import React, { useState } from "react";
import { Button } from "react-md";
import { connect } from "react-redux";
import { scenarioModalButtonLoaderAction } from "../../store/actions/scenarioActions/scenarioActions";
import { ScenarioModalButtonLoaderActionType } from "../../store/actions/scenarioActions/scenarioActionType";
import { TAppStateType } from "../../store/reducers/reducers";
import { Dialog, DialogContent, DialogActions } from "@material-ui/core";

interface OwnProps {
  modalShow: boolean;
  okayAction: any;
  discardAction: any;
  cancelAction: () => void;
}

interface StateProps {
  loader: boolean;
  saveAsOwnScenarioDescription: string;
  saveAsOwnScenarioModalShow?: boolean;
}

interface DispatchProps {
  scenarioModalButtonLoaderAction: ScenarioModalButtonLoaderActionType;
}

type ScenarioSaveDiscardModalProps = DispatchProps & OwnProps & StateProps;

export const ScenarioSaveDiscardModal: React.FC<ScenarioSaveDiscardModalProps> = (
  props: ScenarioSaveDiscardModalProps
) => {
  const [commid, setCommid] = useState(Math.random().toString(20));

  const hide = () => {
    props.cancelAction();
  };

  const okayAction = () => {
    props.scenarioModalButtonLoaderAction(true);
    setCommid(Math.random().toString(20));
    props.okayAction();
  };
  const discardAction = () => {
    setCommid(Math.random().toString(20));
    props.discardAction();
    setCommid(Math.random().toString(20));
    hide();
  };

  return (
    <React.Fragment>
      <Dialog
        id={`warning-modal-${commid}`}
        aria-labelledby="customized-dialog-title"
        open={props.modalShow || Boolean(props.saveAsOwnScenarioModalShow)}
        onClose={() => hide}
        className="modal-sm"
      >
        {!props.loader ? (
          <React.Fragment>
            <DialogContent>
              <p>
                {props.saveAsOwnScenarioModalShow
                  ? props.saveAsOwnScenarioDescription
                  : `Do you want to save the changes made to this scenario? Your changes will be lost if
          you do not save them.`}
              </p>
            </DialogContent>
            <DialogActions>
              {!props.saveAsOwnScenarioModalShow && (
                <Button
                  className="warning-modal-cancel-btn"
                  flat={true}
                  swapTheming={true}
                  disabled={props.loader}
                  onClick={() => (!props.loader ? hide() : {})}
                >
                  Cancel
                </Button>
              )}

              <Button
                className="warning-modal-cancel-btn"
                flat={true}
                swapTheming={true}
                onClick={() => discardAction()}
                disabled={props.loader}
              >
                Discard
              </Button>
              <Button
                className="warning-modal-ok-btn"
                flat={true}
                secondary={true}
                swapTheming={true}
                onClick={() => okayAction()}
                disabled={props.loader}
              >
                Save
              </Button>
            </DialogActions>
          </React.Fragment>
        ) : (
          <DialogContent>
            <div className="loading-wrap">
              <span className="loader-show" />
              Saving Scenario...
            </div>
          </DialogContent>
        )}
      </Dialog>
    </React.Fragment>
  );
};

export const mapStateToProps = (state: TAppStateType) => {
  return {
    loader: state.scenario.modal.loader,
    saveAsOwnScenarioModalShow: state.scenario.modal.saveAsOwnScenarioModalShow,
    saveAsOwnScenarioDescription: state.scenario.modal.saveAsOwnScenarioDescription
      ? state.scenario.modal.saveAsOwnScenarioDescription
      : "",
  };
};

export const mapDispatchToProps = {
  scenarioModalButtonLoaderAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(ScenarioSaveDiscardModal);
