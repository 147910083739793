import { Reducer } from "redux";
import {
  LOGIN_ACTIVE_USER_FOR_SCENARIO,
  LOGIN_HISTORY_MODAL_CLOSE,
  LOGIN_HISTORY_MODAL_OPEN,
  LOGIN_IS_AUTHENTICATION_CODE_SENT,
  LOGIN_IS_NEW_USER,
  LOGIN_SET_USER_PERMISSIONS,
  LOGIN_IS_VERIFICATION_CODE_SENT,
  LOGIN_SET_CURRENT_USER_STATE,
  LOGIN_SET_SETTINGS_TAB_VALUE,
  LOGIN_USER_LOGIN,
  LOGIN_USER_LOGOUT,
  LOGIN_USER_PROFILE,
  LoginActions,
  LOGIN_USER_PERMISSIONS,
  LOGIN_SET_TEAMS_ACTION,
  LOGIN_SET_TEAM_MEMBERS_ACTION,
  LOGIN_GET_SELECTED_USER_SCENARIO_ACTION,
  LOGIN_GET_TEAM_MEMBER_SCENARIO_ACTION,
  LOGIN_SET_CURRENT_TEAM_ACTION,
  LOGIN_TEAM_LOADER_ACTION,
  LOGIN_SET_TEAM_MEMBER_SCENARIO_ACTION,
  LOGIN_SET_BREAD_CRUMBS_VALUE,
  LOGIN_REMOVE_BREAD_CRUMBS_ELEMENT,
  LOGIN_CLEAR_BREAD_CRUMBS_VALUE,
  LOGIN_CAPITAL_STATUS_UPDATE,
} from "../../actions/login/loginActionType";

import { moduleConstant } from "../../../constants";
import { deepCopyObject } from "../../../utility";
import {
  setCurrentUserStateUtility,
  loginSetPermissionsUtility,
  removeBreadCrumbs,
} from "./loginUtility";
import { IPermissionsObject, ICurrentUser, ITeam } from "amm-admin-interface";
import {
  IScenarioPayloadUser,
  IScenarioPayload,
  IScenarioPayloadUserRole,
  IScenarioPayloadUserScenarios,
  IUserRoles,
} from "amm-scenario-interface";

export interface ILoginAction {
  data: any; // Todo fix the type
  key: string;
  module: string[];
  mobile: string;
  type: string;
  token?: string;
  isLogin: boolean;
  isNew: boolean;
  userId: string;
  user: any; // Todo fix the type
  profileData?: IScenarioPayload;
  isCodeSent: boolean;
  value: string;
  scenarioId?: number;
}

export interface ILoginInitialState {
  currentUser: ICurrentUser;
  currentUserPermissions: IPermissionsObject[];
  isVerificationCodeSent: boolean;
  isAuthenticationCodeSent: boolean;
  isLogin: boolean;
  isUserNew: boolean;
  token?: string;
  userIdForScenario?: number;
  historyModalVisibility: boolean;
  scenarioId?: number;
  // Todo fix the any type
  user: any;
  userRole: string;
  cognitoId: string;
  id: number | null;
  users: IScenarioPayloadUser[];
  roles: IScenarioPayloadUserRole[];
  isBrandAdmin?: boolean;
  userPermissions: { [key: string]: boolean };
  teams: ITeam;
  selectedUserScenarios: IScenarioPayloadUserScenarios[] | undefined;
  currentTeamID: number;
  teamLoader: boolean;
  setCurrentTeamMemberScenarios: boolean;
  breadCrumbsState: BreadCrumbObject;
  rolesForThisInstance: IUserRoles[];
}

export interface BreadCrumbObject {
  [key: string]: string;
}
export interface ILoginState extends ILoginInitialState {
  token: string;
}

export const loginInitialState: ILoginInitialState = {
  currentUser: {
    id: 1,
    firstName: "broker-first-name",
    lastName: "broker-last-name",
  } as ICurrentUser,
  currentUserPermissions: [],
  historyModalVisibility: false,
  isAuthenticationCodeSent: false,
  isLogin: false,
  isUserNew: false,
  isVerificationCodeSent: false,
  scenarioId: 1,
  token: undefined,
  user: {},
  userRole: "",
  cognitoId: "",
  id: null,
  users: [],
  userIdForScenario: 0,
  roles: [],
  userPermissions: {},
  teams: {},
  selectedUserScenarios: [],
  currentTeamID: 0,
  teamLoader: false,
  setCurrentTeamMemberScenarios: false,
  breadCrumbsState: {},
  rolesForThisInstance: [],
};

const loginReducer: Reducer<ILoginInitialState, LoginActions> = (
  state: ILoginInitialState = deepCopyObject(loginInitialState),
  action: LoginActions
): ILoginInitialState => {
  if (
    action &&
    action.module &&
    action.module.filter((currentModule) => Object.values(moduleConstant).includes(currentModule))
      .length === 0
  ) {
    return { ...state };
  }

  const currentState = Object.assign({}, state);
  let historyModalVisibility: boolean = false;
  switch (action.type) {
    case LOGIN_USER_LOGIN:
      return { ...currentState, isLogin: action.isLogin, token: action.token };

    case LOGIN_ACTIVE_USER_FOR_SCENARIO:
      return { ...currentState, userIdForScenario: action.userId };

    case LOGIN_USER_PERMISSIONS:
      return {
        ...currentState,
        currentUserPermissions: action.userPermissions,
      };
    case LOGIN_CAPITAL_STATUS_UPDATE:
      const foundScenarios = JSON.parse(JSON.stringify(currentState.selectedUserScenarios));
      const foundIndex = foundScenarios.findIndex(
        (scenario: any) => scenario.id === action.scenarioId
      );
      foundScenarios[foundIndex].scenario_datas[0].capitalStatus = action.capitalStatus;
      return {
        ...currentState,
        selectedUserScenarios: foundScenarios,
      };
    case LOGIN_USER_PROFILE:
      return {
        ...currentState,
        rolesForThisInstance: action.profileData.rolesForThisInstance,
        cognitoId: action.profileData.user.cognito_id,
        id: action.profileData.user.id,
        roles: action.profileData.user.roles,
        isBrandAdmin: action.profileData.isBrandAdmin,
        userIdForScenario: state.userIdForScenario
          ? state.userIdForScenario
          : action.profileData.user.id,
        users: action.profileData.allUsers,
      };
    case LOGIN_IS_NEW_USER:
      return { ...currentState, isUserNew: action.isNew, user: action.user };

    case LOGIN_IS_VERIFICATION_CODE_SENT:
      return {
        ...currentState,
        isVerificationCodeSent: action.isCodeSent,
        user: action.user,
      };

    case LOGIN_SET_CURRENT_USER_STATE:
      return setCurrentUserStateUtility(action.data, state);

    case LOGIN_SET_TEAM_MEMBER_SCENARIO_ACTION:
      return {
        ...currentState,
        setCurrentTeamMemberScenarios: action.setCurrentTeamMemberScenarios,
      };
    case LOGIN_IS_AUTHENTICATION_CODE_SENT:
      return {
        ...currentState,
        isAuthenticationCodeSent: action.isCodeSent,
        user: action.user,
      };

    case LOGIN_SET_USER_PERMISSIONS:
      return loginSetPermissionsUtility(action.data, currentState);

    case LOGIN_GET_SELECTED_USER_SCENARIO_ACTION:
      const user = currentState.users.find((item) => Number(action.userID) === item.id);
      let scenarios = user ? user.scenarios : [];

      let scenarioForReview: IScenarioPayloadUserScenarios[] = [];

      if (user && user.scenarios_reviewer) {
        scenarioForReview = user!.scenarios_reviewer.filter(
          (scenario: IScenarioPayloadUserScenarios) => scenario.owner_id != action.userID
        );
      }

      return {
        ...currentState,
        selectedUserScenarios: [...scenarios, ...scenarioForReview],
        setCurrentTeamMemberScenarios: false,
      };

    case LOGIN_GET_TEAM_MEMBER_SCENARIO_ACTION:
      return { ...currentState, selectedUserScenarios: action.scenarios };

    case LOGIN_SET_SETTINGS_TAB_VALUE:
      const currentUser = Object.assign(state.currentUser, {});
      currentUser[action.key] = action.value;
      return {
        ...currentState,
        currentUser,
      };
    case LOGIN_SET_TEAMS_ACTION:
      const teams = action.teams;
      return {
        ...currentState,
        teams,
      };

    case LOGIN_SET_TEAM_MEMBERS_ACTION:
      const existingTeams = Object.assign(currentState.teams, {});
      existingTeams[action.teamID] = {
        ...existingTeams[action.teamID],
        teamMembers: action.teamMembers.sort((a, b) => a.id - b.id),
      };

      return {
        ...currentState,
        teams: existingTeams,
      };

    case LOGIN_SET_CURRENT_TEAM_ACTION:
      currentState.currentTeamID = action.teamID;
      return currentState;

    case LOGIN_TEAM_LOADER_ACTION:
      currentState.teamLoader = action.loader;
      return currentState;

    case LOGIN_HISTORY_MODAL_CLOSE:
      historyModalVisibility = false;
      return { ...currentState, historyModalVisibility };

    case LOGIN_SET_BREAD_CRUMBS_VALUE:
      return {
        ...currentState,
        breadCrumbsState: { ...action.breadCrumbValue },
      };

    case LOGIN_REMOVE_BREAD_CRUMBS_ELEMENT:
      return removeBreadCrumbs(action, currentState);

    case LOGIN_CLEAR_BREAD_CRUMBS_VALUE:
      return {
        ...currentState,
        breadCrumbsState: {},
      };

    case LOGIN_HISTORY_MODAL_OPEN:
      historyModalVisibility = true;
      return {
        ...currentState,
        historyModalVisibility,
        scenarioId: action.scenarioId,
      };

    case LOGIN_USER_LOGOUT:
      return { ...loginInitialState };

    default:
      return { ...state };
  }
};

export default loginReducer;
