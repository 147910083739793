export enum Roles {
  ClientManager = "ClientManager",
  CreditManager = "CreditManager",
  Admin = "Admin",
  LoanWriter = "LoanWriter",
  BrandAdmin = "BrandAdmin",
  Frozen = "Frozen",
  APILoanWriter = "APILoanWriter",
  APIClientManager = "APIClientManager",
  BusinessDevelopmentManager = "BusinessDevelopmentManager",
  AFGLoanWriter = "AFGLoanWriter"
}

export enum RolesWithSpaces {
  ClientManager = "Client Manager",
  CreditManager = "Credit Manager",
  Admin = "Admin",
  LoanWriter = "Loan Writer",
  BrandAdmin = "Brand Admin",
  Frozen = "Frozen",
  APILoanWriter = "API Loan Writer",
  APIClientManager = "API Client Manager",
  BusinessDevelopmentManager = "Business Development Manager",
  AFGLoanWriter = "AFG Loan Writer"
}

export enum RoleIds {
  Admin = 3,
  LoanWriter = 2,
  ClientManager = 6,
  CreditManager = 5,
  APILoanWriter = 8,
  APIClientManager = 9,
  BusinessDevelopmentManager = 10,
  AFGLoanWriter = 11
}

export const hasSameRole = (userRole: string, rolesToCompare: Roles[]) => {
  return Boolean(rolesToCompare.find(role => (userRole.trim() === Roles[role] || userRole.trim() === RolesWithSpaces[role])));
};
