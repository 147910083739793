import {
  scenarioResetCurrentScenarioIdAction,
  scenarioSaveAsOwnScenarioModalShowAction,
} from "../../actions/scenarioActions/scenarioActions";
import history from "../../../routes/history";
import { errorGenericErrorHandleAction } from "../../actions/errorActions/errorAction";
import { rolesCanNotBePartOfTeam } from "../../../constants";
import { clearCurrentScenario } from "../../../utility/clearCurrentScenario";

/** 
 * function scenarioResetCurrentScenarioIdAction: To reset the currentScenarioID to null.
 * function scenarioSaveAsOwnScenarioModalShowAction: To open save/discard scenario modal.
 */
export const openSaveAsOwnScenario = (dispatch: any, message: string, currentUserRole: string,toPath?:string) => {
  dispatch(scenarioResetCurrentScenarioIdAction());
  history.push("/");
  if (!rolesCanNotBePartOfTeam.includes(currentUserRole)) {

    dispatch(
      scenarioSaveAsOwnScenarioModalShowAction(
        true,
        `Do you want to save this scenario as your own? Your changes will be lost if
            you do not save them.`
      )
    );
  }else{
    dispatch(clearCurrentScenario());
  }

  return dispatch(errorGenericErrorHandleAction(message));
};
