import {
  OBJECTIVE_ADD_NEW_PROPERTY,
  OBJECTIVE_ADDRESS_AUTO_SUGGEST,
  OBJECTIVE_ADDRESS_LOADER,
  OBJECTIVE_CLEAR_ADDRESS_FIELD,
  OBJECTIVE_CLEAR_ADDRESS_RESULT,
  OBJECTIVE_CURRENT_TAB,
  OBJECTIVE_GET_IMAGE_GALLERY,
  OBJECTIVE_GET_PROPERTY,
  OBJECTIVE_LOAD_STATE,
  OBJECTIVE_PROPERTY_ESTIMATE_RESULT,
  OBJECTIVE_REMOVE_PROPERTY,
  OBJECTIVE_STATE_UPDATE,
  ObjectiveCurrentTabActionType,
  ObjectiveAddNewPropertyActionType,
  ObjectiveRemovePropertyActionType,
  ObjectiveStateUpdateActionType,
  ObjectiveAddressAutoSuggestActionType,
  ObjectiveClearAddressResultActionType,
  ObjectiveClearAddressFieldActionType,
  ObjectiveGetPropertyActionType,
  ObjectiveGetImageGalleryActionType,
  ObjectiveAddressLoaderActionType,
  ObjectivePropertyEstimateResultActionType,
  ObjectiveLoadStateActionType,
  ObjectiveSetSuburbCheckedIndicatorActionType,
  OBJECTIVE_SET_SUBURB_CHECKED_INDICATOR,
  ObjectiveInitialIDSValuesActionType,
  OBJECTIVE_INITIAL_IDS_VALUES,
  ObjectiveSetStatisticalValuationMessageActionType,
  OBJECTIVE_SET_STATISTICAL_VALUATION_MESSAGE,
} from "./objectivePageActionTypes";

import { moduleConstant } from "../../../constants";
import { ISplitAddress } from "amm-applicant-interface";

export const objectiveCurrentTabAction: ObjectiveCurrentTabActionType = (currentTab) => {
  return {
    currentTab,
    module: [moduleConstant.objective],
    type: OBJECTIVE_CURRENT_TAB,
  };
};

export const objectiveAddNewPropertyAction: ObjectiveAddNewPropertyActionType = () => {
  return {
    module: [moduleConstant.objective],
    type: OBJECTIVE_ADD_NEW_PROPERTY,
  };
};

export const objectiveSetSuburbCheckedIndicator: ObjectiveSetSuburbCheckedIndicatorActionType = (
  objectiveInvalidArray: boolean[]
) => {
  return {
    module: [moduleConstant.objective],
    objectiveInvalidArray,
    type: OBJECTIVE_SET_SUBURB_CHECKED_INDICATOR,
  };
};

export const objectiveRemovePropertyAction: ObjectiveRemovePropertyActionType = (index: number) => {
  return {
    module: [moduleConstant.objective],
    removeIndex: index,
    type: OBJECTIVE_REMOVE_PROPERTY,
  };
};

export const objectiveStateUpdateAction: ObjectiveStateUpdateActionType = (
  stateName: string,
  stateValue: React.ReactNode | ISplitAddress
) => {
  return {
    module: [moduleConstant.objective],
    stateName,
    stateValue,
    type: OBJECTIVE_STATE_UPDATE,
  };
};

export const objectiveAddressAutoSuggestAction: ObjectiveAddressAutoSuggestActionType = (
  currentTab,
  filter,
  propertyAutoSuggestions
) => {
  return {
    currentTab,
    filter,
    module: [moduleConstant.objective],
    propertyAutoSuggestions,
    type: OBJECTIVE_ADDRESS_AUTO_SUGGEST,
  };
};

export const objectiveClearAddressResultAction: ObjectiveClearAddressResultActionType = (
  calledFrom?: string
) => {
  return {
    calledFrom,
    module: [moduleConstant.objective],
    type: OBJECTIVE_CLEAR_ADDRESS_RESULT,
  };
};

export const objectiveClearAddressFieldAction: ObjectiveClearAddressFieldActionType = (
  calledFrom?: string
) => {
  return {
    calledFrom,
    module: [moduleConstant.objective],
    type: OBJECTIVE_CLEAR_ADDRESS_FIELD,
  };
};

export const objectiveGetPropertyAction: ObjectiveGetPropertyActionType = (
  property,
  currentTab
) => {
  return {
    currentTab,
    module: [moduleConstant.objective],
    property,
    type: OBJECTIVE_GET_PROPERTY,
  };
};

export const objectiveGetImageGalleryAction: ObjectiveGetImageGalleryActionType = (
  gallery,
  currentTab
) => {
  return {
    currentTab,
    gallery,
    module: [moduleConstant.objective],
    type: OBJECTIVE_GET_IMAGE_GALLERY,
  };
};

export const objectiveAddressLoaderAction: ObjectiveAddressLoaderActionType = (
  loader,
  currentTab
) => {
  return {
    currentTab,
    loader,
    module: [moduleConstant.objective],
    type: OBJECTIVE_ADDRESS_LOADER,
  };
};

export const objectivePropertyEstimateResultAction: ObjectivePropertyEstimateResultActionType = (
  propertyEstimateResult
) => {
  return {
    module: [moduleConstant.objective],
    propertyEstimateResult,
    type: OBJECTIVE_PROPERTY_ESTIMATE_RESULT,
  };
};

export const objectiveLoadStateAction: ObjectiveLoadStateActionType = (state) => {
  return {
    module: [moduleConstant.objective],
    state,
    type: OBJECTIVE_LOAD_STATE,
  };
};

export const objectiveInitialIDSValuesAction: ObjectiveInitialIDSValuesActionType = (
  bathrooms,
  bedrooms,
  carparks,
  currentTab
) => {
  return {
    module: [moduleConstant.objective],
    bedrooms,
    bathrooms,
    carparks,
    currentTab,
    type: OBJECTIVE_INITIAL_IDS_VALUES,
  };
};

export const objectiveSetStatisticalValuationMessageAction: ObjectiveSetStatisticalValuationMessageActionType = (
  message: string,
  currentTab: number
) => {
  return {
    module: [moduleConstant.objective],
    message,
    currentTab,
    type: OBJECTIVE_SET_STATISTICAL_VALUATION_MESSAGE,
  };
};
