import React from "react";

const CarIcon = () => {
  return (
    <svg
      id="Car_icon"
      data-name="Car icon"
      className="car-icon"
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="10"
      viewBox="0 0 20 14"
    >
      <rect x="1" y="5" width="18" height="6" />
      <rect
        id="Rounded_Rectangle_11"
        data-name="Rounded Rectangle 11"
        x="1"
        y="10"
        width="4"
        height="4"
        rx="2"
        ry="2"
      />
      <rect
        id="Rounded_Rectangle_11_copy"
        data-name="Rounded Rectangle 11 copy"
        x="15"
        y="10"
        width="4"
        height="4"
        rx="2"
        ry="2"
      />
      <rect x="2" y="6" width="5" height="2" />
      <rect
        id="Rectangle_719_copy"
        data-name="Rectangle 719 copy"
        x="13"
        y="6"
        width="5"
        height="2"
      />
      <rect x="5" width="10" height="1" />
      <rect x="18" y="3" width="2" height="2" />
      <rect id="Rectangle_721_copy" data-name="Rectangle 721 copy" y="3" width="2" height="2" />
      <path
        id="Rectangle_720_copy"
        data-name="Rectangle 720 copy"
        d="M1005.82,1322.97l4.65-5.65,0.71,0.71-3.65,5.65Z"
        transform="translate(-1005 -1317)"
      />
      <path
        id="Rectangle_720_copy_3"
        data-name="Rectangle 720 copy 3"
        d="M1023.99,1322.32l-4.33-5.31-0.65.67,3.4,5.31Z"
        transform="translate(-1005 -1317)"
      />
    </svg>
  );
};

export default CarIcon;
