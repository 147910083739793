import {
  DURATION_TYPE,
  livingArrangementItems,
  MINIMUM_ASSETS,
  MINIMUM_INCOME_SOURCES,
  MINIMUM_LIVING_EXPENSES,
  MINIMUM_REFINANCED_LOAN,
} from "../../../constants/index";
import {
  IApplicant,
  IApplicantDocuments,
  IApplicantDocumentsRequired,
  IApplicantInitialState,
  IApplicantRequiredField,
  IAssets,
  ICreditCards,
  IFinancialObligation,
  IIncomeSources,
  ILivingExpenses,
  IModalData,
  IRetrieveBankData,
  IDependent,
} from "amm-applicant-interface";
import {
  applicantStatus,
  country,
  creditScoreBtnText,
  documentAddressLocation,
  documentType,
  duration,
  howManyApplicant,
  incomeVerificationType,
  privacyOptions,
  providerMenuItems,
  provisoOrManual,
  referenceNumber,
  taxStatus,
  verifyBtnText,
  dependentType,
} from "../../../constants";

export const assetsData: IAssets = {
  deletedNote: "",
  deletedNoteRequired: false,
  deletedPartialNoteValue: "",
  isDeleted: false,
  loader: false,
  myContribution: true,
  note: "",
  noteRequired: false,
  partialNoteValue: "",
  propertyAddress: "",
  propertyAutoSuggestList: [],
  propertyValue: 0,
  provisoOrManual: `${provisoOrManual[1]}`,
  share: 100,
  type: "",
  value: 0,
  assetNumber: "",
  illionId: "",
};

export const creditCardsData: ICreditCards = {
  creditLimit: 0,
  deletedNote: "",
  deletedNoteRequired: false,
  deletedPartialNoteValue: "",
  institution: "",
  isDeleted: false,
  note: "",
  noteRequired: false,
  partialNoteValue: "",
  provisoOrManual: `${provisoOrManual[1]}`,
  textError: false,
  isCreditCardRefinanced: false,
  period: 0,
  illionId: "",
};

export const financialObligationData: IFinancialObligation = {
  balance: 0,
  deletedNote: "",
  deletedNoteRequired: false,
  deletedPartialNoteValue: "",
  duration: `${duration[1].value}`,
  interestRate: 0,
  isDeleted: false,
  note: "",
  noteRequired: false,
  partialNoteValue: "",
  paymentAmount: 0,
  provisoOrManual: `${provisoOrManual[1]}`,
  isLoanRefinanced: false,
  type: "",
  illionId: "",
};

export const incomeSourcesData: IIncomeSources = {
  amount: 0,
  deletedNote: "",
  deletedNoteRequired: false,
  deletedPartialNoteValue: "",
  duration: `${duration[0].value}`,
  isDeleted: false,
  isFromInvestments: false,
  note: "",
  noteRequired: false,
  partialNoteValue: "",
  provisoOrManual: `${provisoOrManual[1]}`,
  taxStatus: `${taxStatus[0].value}`,
  type: "",
  illionId: "",
};

export const livingExpensesData: ILivingExpenses = {
  amount: 0,
  deletedNote: "",
  deletedNoteRequired: false,
  deletedPartialNoteValue: "",
  duration: `${duration[1].value}`,
  isDeleted: false,
  note: "",
  noteRequired: false,
  partialNoteValue: "",
  provisoOrManual: `${provisoOrManual[1]}`,
  type: "",
  illionId: "",
};

export const retrieveDataObject: IRetrieveBankData = {
  bankDocumentId: "",
  errorText: "",
  provider: `${providerMenuItems[0]}`,
  referralCode: "",
  showLoader: false,
  illionBankDataFileName: "",
  isNew: true,
};

export const currentModalData: IModalData = {
  category: "",
  explanation: "",
  newValue: 0,
  originalValue: 0,
  type: "",
};

export const applicantDocumentsData: IApplicantDocuments = {
  documentAddressLocation: "",
  documentType: `${documentType[0].value}`,
  licenseNumber: "",
  licenseCardNumber: "",
};

export const applicantDocumentsSecondData: IApplicantDocuments = {
  expiryDate: null,
  documentType: `${documentType[1].value}`,
  cardNumber: "",
  referenceNumber: referenceNumber[0],
  documentAddressLocation: `${country[0].value}`,
};

export const applicantDocumentsLastData: IApplicantDocuments = {
  documentAddressLocation: `${country[0].value}`,
  documentType: `${documentType[2].value}`,
  passportNumber: "",
};

export const applicantMedicareDate: IApplicantDocuments = {
  cardNumber: "",
  documentAddressLocation: `${documentAddressLocation[0].value}`,
  documentType: `${documentType[1].value}`,
  expiryDate: null,
  referenceNumber: 1,
};

export const dependentData: IDependent = {
  age: 0,
  dependentType: dependentType[0],
};

export const applicants: IApplicant = {
  addressAutoSuggest: [],
  applicantDocuments: [{ ...applicantDocumentsData }, { ...applicantMedicareDate }],
  applicantEmail: "",
  applicantLivingArrangement: livingArrangementItems.Single,
  earlyRepaymentStrategy: "",
  earlyRepaymentStrategyNote: "",
  applicantStatus: `${applicantStatus[0].value}`,
  applicantTitle: "",
  assets: [],
  assetsDeletedNoteRequired: [],
  assetsNoteRequired: [],
  clearTable: false,
  smsLoaderShow: false,
  smsResendTimer: 0,
  smsSent: false,
  creditCards: [],
  dependents: [],
  creditCardsDeletedNoteRequired: [],
  creditCardsNoteRequired: [],
  creditScoreBtnText: creditScoreBtnText[0], // credit score button text
  currentAddress: "",
  currentAddressLoader: false,
  currentSplitAddress: {},
  dataRetrieved: false,
  dateOfBirth: null,
  employment: [],
  financialObligation: [],
  financialObligationDeletedNoteRequired: [],
  financialObligationNoteRequired: [],
  firstName: "",
  homePhone: "",
  homePhoneInvalid: false,
  incomeSources: [],
  incomeSourcesDeletedNoteRequired: [],
  incomeSourcesNoteRequired: [],
  incomeVerificationType: `${incomeVerificationType[0].value}`,
  isApplicantFirstHomeSection: "No",
  isApplicantGuarantor: "No", //
  isCreditScore: false,
  isDocumentVerified: false,
  lastName: "",
  livingExpenses: [],
  totalExpensesNotes: [
    {
      note: "",
      noteRequired: false,
      noteValue: "",
      partialNoteValue: "",
      deletedNote: "",
      deletedNoteRequired: false,
      deletedPartialNoteValue: "",
      isDeleted: false,
      provisoOrManual: "",
      illionId: "",
    },
  ],
  livingExpensesDeletedNoteRequired: [],
  livingExpensesNoteRequired: [],
  middleName: "",
  mobilePhone: "",
  modalData: currentModalData,
  monthInBusiness: 0,
  movedInDate: null,
  movedInDateDiff: 0,
  noteModalVisibility: false,
  previousAddressAutoSuggest: [],
  previousAddress: "",
  previousAddressApplicantStatus: `${applicantStatus[0].value}`,
  previousAddressLoader: false,
  previousAddressMovedInDate: null,
  previousSplitAddress: {},
  privacyConsent: {
    confirmOtpLoader: false,
    confirmationCode: undefined,
    creditScore: 700,
    formLoader: false,
    formSubmit: false,
    idsMatrix: false,
    isFormValid: false,
    isInformationVerified: false,
    isNumberConfirmed: false,
    isOtpSent: false,
    privacyOptions: `${privacyOptions[0].value}`,
    sendOtpLoader: false,
    overrideLoader: false,
    isCreditCheckOverriden: false,
    isCreditCheckDoneManually: false,
  },
  provisoData: {
    assets: [],
    creditCards: [],
    financialObligation: [],
    incomeSources: [],
    livingExpenses: [],
  },
  retrieveBankData: [{ ...retrieveDataObject }],
  retrieveId: "",
  verifyBtnText: verifyBtnText[0], // verify button text
  isEmailDuplicate: false,
  isMobilePhoneDuplicate: false,
  affordabilityTotals: {
    incomeSources: { total: 0, duration: DURATION_TYPE.Month, taxStatus: `${taxStatus[1].value}` },
    assets: { total: 0 },
    creditCards: { total: 0, duration: DURATION_TYPE.Month },
    financialObligation: { total: 0, duration: DURATION_TYPE.Month },
    livingExpenses: { total: 0, duration: DURATION_TYPE.Month },
    hem: { total: 0, duration: DURATION_TYPE.Month },
  },
};

export const documentRequiredField: IApplicantDocumentsRequired = {
  licenseNumber: false,
  documentAddressLocation: false,
};

export const medicareRequiredField: IApplicantDocumentsRequired = {
  cardNumber: false,
  expiryDate: false,
};

export const passportRequiredField: IApplicantDocumentsRequired = {
  passportNumber: false,
};

export const applicantsRequiredFieldList: IApplicantRequiredField = {
  applicantDocuments: [{ ...documentRequiredField }, { ...medicareRequiredField }],
  employment: false,
  applicantTitle: false,
  applicantEmail: false,
  currentAddress: false,
  dateOfBirth: false,
  firstName: false,
  lastName: false,
  middleName: false,
  mobilePhone: false,
  monthInBusiness: false,
  movedInDate: false,
  previousAddress: false,
  previousAddressMovedInDate: false,
  earlyRepaymentStrategy: false,
  earlyRepaymentStrategyNote: false,
  isProbationOn: false,
};

export const applicantInitialState: IApplicantInitialState = {
  applicants: [{ ...applicants }],
  applicantsRequiredField: [{ ...applicantsRequiredFieldList }],
  primaryAffordabilityRequiredFields: {
    count: {
      incomeSources: MINIMUM_INCOME_SOURCES,
      livingExpenses: MINIMUM_LIVING_EXPENSES,
      refinancedLoan: MINIMUM_REFINANCED_LOAN,
      assets: MINIMUM_ASSETS,
    },
    required: {
      incomeSources: false,
      livingExpenses: false,
      refinancedLoan: false,
      assets: false,
    },
  },
  assetsRowAdded: [false],
  creditCardsRowAdded: [false],
  currentTab: 0,
  financialObligationRowAdded: [false],
  howManyApplicant: `${howManyApplicant[0].value}`,
  incomeSourcesRowAdded: [false],
  incomeRemovalIndexArray: [],
  incomeRemovalForPropertyIndexArray: [],
  livingExpensesRowAdded: [false],
  maxApplicantTab: 6,
  noteInformation: {
    index: 0,
    key: [],
    type: "incomeSources",
  },
};
