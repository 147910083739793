export const scenarioDuplicateActions = [
  "scenario_duplicateAll",
  "scenario_duplicateSelf",
  "scenario_duplicateTeam",
  "scenario_selfDuplicateAll",
  "scenario_duplicateForCreditReview",
];
export const addressGoogleAutoSuggestAction = ["address_googleAutoSuggest"];
export const propertyGetPropertyAddressAction = ["property_getPropertyAddress"];
export const suburbGetSuburbImageAction = ["suburb_getSuburbImage"];
export const propertyGetPropertyEstimateAction = ["property_getPropertyEstimate"];
export const suburbGetSuburbEstimateAction = ["suburb_getSuburbEstimate"];
export const applicantGetOtpAction = ["applicant_getOtp"];
export const applicantConfirmOtpAction = ["applicant_confirmOtp"];
export const applicantGetCreditScoreAction = ["applicant_getCreditScore"];
export const applicantSendSmsLinkAction = ["applicant_sendSmsLink"];
export const affordabilityGetProvisoInfoAction = ["affordability_getProvisoInfo"];
export const scenarioGeneratePdfSelfAction = [
  "scenario_generatePdfSelf",
  "scenario_generatePDFTeamScenario",
  "scenario_generatePDFAsReviewer",
  "scenario_generatePDFAll",
];
export const scenarioSendQuoteAction = ["scenario_sendQuote"];
export const capitalOriginationAction = [
  "document_capitalOriginateSelf",
  "document_capitalOriginationTeamScenario",
  "document_capitalOriginateAsReviewer",
];
export const capitalDocumentAction = [
  "document_capitalDocumentSelf",
  "document_capitalDocumentTeamScenario",
  "document_capitalDocumentAsReviewer",
];
export const backChannelAction = ["document_backChannelSelf", "document_backChannelTeamScenario"];
export const docusignTriggerAction = [
  "document_docusignTriggerSelf",
  "document_docusignTriggerTeamScenario",
];
export const downloadAcceptanceLetterAction = [
  "document_downloadSelf",
  "document_downloadAcceptanceLetterTeamScenario",
  "document_downloadAllAcceptanceLetter",
  "document_downloadAsReviewer",
];
export const viewDocumentAction = [
  "document_viewSelf",
  "document_viewTeamScenario",
  "document_viewAllDocumentsForScenario",
  "document_viewAsReviewer",
];
export const fundsToCompleteAction = ["review_fundsToComplete"];
export const scenarioDashboardSaveAction = [
  "scenario_save",
  "scenario_saveTeamScenario",
  "scenario_overrideScenario",
  "scenario_saveAsReviewer",
];
export const scenarioDashboardDeleteAction = ["scenario_delete"];
export const scenarioDashboardHistoryAction = ["scenario_viewHistoryAction"];
export const scenarioDashboardLoadAction = [
  "scenario_loadSelf",
  "scenario_loadAll",
  "scenario_loadTeamScenario",
  "scenario_loadAsReviewer",
];
export const brandUploadCssAction = ["branding_update"];
export const scenarioUploadDocumentAction = [
  "scenario_uploadDocumentSelf",
  "scenario_uploadDocumentsTeamScenario",
  "scenario_uploadDocumentsAsReviewer",
  "scenario_uploadDocumentsForAllScenario",
];
export const profileFetchAction = ["user_profileFetch"];
export const removeUserFromAllTeams = ["user_removeFromAllTeams"];
export const assignUserScenarios = ["user_assignUserScenarios"];
export const unassignReviewerFromCreditReview = ["user_unassignReviewerFromCreditReview"];
export const creditReviewScenarioAction = ["scenario_assignCreditReviewer"];

// teams actions
export const teamAddTeamAction = ["teams_add"];
export const teamEditTeamAction = ["teams_edit"];
export const teamDeleteTeamAction = ["teams_delete"];
export const teamViewTeamAction = ["teams_viewTeams"];
export const teamViewTeamMembersAction = ["teams_viewTeamMembers"];
export const teamViewTeamMembersScenariosAction = ["teams_viewTeamMembersScenarios"];
export const teamReassignTeamMemberAction = ["teams_reassignTeamMember"];

// graphql
export const adminAddBroker = ["admin_addBroker"];
export const brokerEditBroker = ["broker_editBroker"];
export const adminEditBroker = ["admin_editBroker"];
export const myAccountSaveNotificationDetails = ["myAccount_saveNotificationDetails"];
export const myAccountSaveCapitalCredentials = ["myAccount_saveCapitalCredentials"];
export const myAccountSaveProvisoCredentials = ["myAccount_saveProvisoCredentials"];
export const myAccountUpdateLastLogin = ["myAccount_updateLastLogin"];
export const brokerGetAllBrokers = ["broker_getAllBrokers"];
export const brokerUserById = ["broker_userById"];
export const getUploadedFilesByIdAction = [
  "upload_docusign",
  "upload_docusignTeamScenario",
  "upload_docusignAsReviewer",
];
export const uploadDeleteById = [
  "upload_deleteById",
  "upload_deleteByIdTeamScenario",
  "upload_deleteByIdAsReviewer",
  "upload_deleteForAllScenario",
];
export const uploadUploadsByScenarioId = [
  "upload_uploadsByScenarioId",
  "upload_uploadsByScenarioIdTeamScenario",
  "upload_uploadsByScenarioIdAsReviewer",
];
export const myAccountUpdateBPPartyID = ["myAccount_updateBPPartyID"];

// leads actions
export const leadsViewAll = ["leads_viewAllLeads"];
export const leadsLoadAll = ["leads_loadAllLead"];
export const leadAssign = ["leads_assignLead"];
export const leadDelete = ["leads_deleteLead"];
export const viewLegacyID = ["scenario_viewLegacyID"];
export const addEditLegacyID = ["scenario_addEditLegacyID"];

// side menu credit review actions
export const creditReviewAction = ["scenario_creditReview"];

export const viewAllScenarioReportAction = ["report_viewAllScenarios"];
