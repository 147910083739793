import { LoanAmountSectionReviewProps } from "../../src/components/solution/loanAmountSection";

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type LoanAmountSectionProps = Omit<
  LoanAmountSectionReviewProps,
  "className" | "reviewPage" | "label"
>;

export const isLoanReviewProps = (
  validator: LoanAmountSectionProps | LoanAmountSectionReviewProps
): validator is LoanAmountSectionReviewProps => {
  return (
    (validator as LoanAmountSectionReviewProps).className !== undefined &&
    (validator as LoanAmountSectionReviewProps).reviewPage !== undefined &&
    (validator as LoanAmountSectionReviewProps).label !== undefined
  );
};
