import algoliasearch from "algoliasearch";
import { algoliaApiKey, algoliaAppID, algoliaIndexName } from "../../../constants";
import { errorGenericErrorHandleAction } from "../../actions/errorActions/errorAction";
import { helpStateUpdateAction } from "../../actions/helpActions/helpAction";
import { Action } from "redux";
import { ThunkAction } from "redux-thunk";

export type GetAlgoliaRecordsThunkType = (
  clickId: string,
  subheading?: boolean
) => ThunkAction<any, any, any, Action>;

export const getAlgoliaRecordsThunk: GetAlgoliaRecordsThunkType = (
  clickId: string,
  subheading?: boolean
): ThunkAction<any, any, any, Action> => {
  return (dispatch) => {
    if (clickId !== "") {
      return fetch(dispatch, clickId, subheading);
    } else {
      return;
    }
  };
};

const fetch = (dispatch: any, clickId: string, subheading?: boolean) => {
  let client: any;
  const responseObject = {
    helpSectionContent: "",
    helpSectionHeading: "",
    helpSectionLinks: [],
    helpSubcontent: "",
    helpSubheading: "",
  };

  client = algoliasearch(algoliaAppID, algoliaApiKey);
  const index = client.initIndex(algoliaIndexName);

  index
    .getObject(clickId)
    //@ts-ignore
    .then((response) => {
      if (subheading) {
        responseObject.helpSubheading = response.heading;
        responseObject.helpSubcontent = response.content;
      } else {
        responseObject.helpSectionHeading = response.heading;
        responseObject.helpSectionContent = response.content;
        responseObject.helpSectionLinks = response.links ? response.links : [];
      }
      return dispatch(helpStateUpdateAction(responseObject, subheading ? subheading : false));
    })
    //@ts-ignore
    .catch((err) => {
      return dispatch(errorGenericErrorHandleAction(err.message));
    });
};
