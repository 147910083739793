import axios from "axios";
import { CashoutLRASError, EmploymentTypes, lrasUrl } from "../../../constants";
import { errorGenericErrorHandleAction } from "../../actions/errorActions/errorAction";
import { helpSaveLRASAction } from "../../actions/helpActions/helpAction";
import { ThunkAction } from "redux-thunk";
import { Action } from "redux";
import { TAppStateType } from "../../reducers/reducers";
import { livingArrangementItems, employmentTypesPrimary } from "../../../constants/index";
import { applicantInfoDisplacement } from "./utility";

interface ILrasAccountType {
  amount: number | undefined;
  interestOnlyTerm: number | undefined;
  accountType: string;
  accountPurpose: string;
  accountId: string;
}

interface ILrasPropertiesType {
  propertyBeUsed: string;
  propertyCategory: number;
  propertyNewOrOfThePlan: boolean;
  propertyType: string;
  propertyValue: number;
  purchaseOrRefinance: string;
}

interface ICreditCard {
  creditLimit: number;
}
interface IFinancialObligation {
  balance: number;
  payment: number;
  duration: string;
  interestRate: number;
  type: string;
}
interface IIncomeSources {
  amount: number;
  duration: string;
  type: string;
  taxStatus: string;
}
interface ILivingExpenses {
  amount: number;
  duration: string;
  type: string;
}
export interface ILrasApplicantsType {
  creditCards: ICreditCard[];
  creditScore: number;
  applicantLivingArrangement: string;
  financialObligations: IFinancialObligation[];
  incomeSources: IIncomeSources[];
  incomeVerificationType: string;
  isApplicantFirstHomeSection: boolean;
  livingExpenses: ILivingExpenses[];
  monthInBusiness: number;
  numberOfDependents: string;
  paygEmployeeOrSelfEmployee: string;
}
interface IFundsToCompleteObject {
  valuationFee: number;
}
interface ILrasLoanType {
  accounts: ILrasAccountType[];
  fundsToCompleteInformation: IFundsToCompleteObject;
  cashOutAmount: number;
  loanAmount: number;
  loanTermYear: number;
}

export const calcLRASThunk = (
  state: TAppStateType,
  token: string
): ThunkAction<any, any, any, Action> => {
  // TODO: make this less memory intensive or reduce LRAS triggers
  const stateFiltered: TAppStateType = JSON.parse(JSON.stringify(state));
  // @ts-ignore
  delete stateFiltered.login;
  // @ts-ignore
  delete stateFiltered.scenario;

  const loan: ILrasLoanType = {
    accounts: [],
    cashOutAmount: 0,
    loanAmount: 0,
    loanTermYear: 0,
    fundsToCompleteInformation: {
      valuationFee: 0,
    },
  };
  let properties: ILrasPropertiesType[] = [];
  let applicants: ILrasApplicantsType[] = [];
  loan.accounts = stateFiltered.loan.account.map((account) => {
    return {
      amount: account.amount,
      interestOnlyTerm: account.interestOnlyTerm,
      accountType: account.accountType,
      accountPurpose: account.accountPurpose,
      accountId: account.accountId,
      interestType: account.interestType,
      fixedTerm: account.fixedTerm,
      isOffsetRequired: account.isOffsetRequired,
    };
  });

  loan.cashOutAmount = stateFiltered.loan.cashOutAmount;
  loan.loanAmount = stateFiltered.loan.loanAmount;
  loan.loanTermYear = stateFiltered.loan.loanTermYear;

  loan.fundsToCompleteInformation.valuationFee =
    stateFiltered.review.fundsToCompleteTotal.valuationFee;
  if (stateFiltered.objective.properties.length >= 1) {
    properties = stateFiltered.objective.properties.map((property) => {
      return {
        propertyBeUsed: property.propertyBeUsed,
        propertyCategory: property.propertyCategory,
        propertyNewOrOfThePlan: property.propertyNewOrOfThePlan === "Yes",
        propertyType: property.propertyType,
        propertyValue: property.propertyValue,
        purchaseOrRefinance: property.purchaseOrRefinance,
      };
    });
  }

  /**applicant displacement array - each element of this array represent the displacement(applicantIndex + applicantOffset[applicantIndex]) represent the actual position of the response. this is done because LRAS only take applicants with affordability details into account and this array will help in mapping the LRAS per applicant response to its correct index */

  const applicantOffset: number[] = [];

  stateFiltered.applicant.applicants.forEach((applicant, index) => (applicantOffset[index] = 0));
  let applicantOffsetIndex = 0;

  const eligibleApplicants = stateFiltered.applicant.applicants.filter((applicant) => {
    const isValid =
      !(
        applicant.creditCards.length === 0 &&
        applicant.financialObligation.length === 0 &&
        applicant.incomeSources.length === 0 &&
        applicant.livingExpenses.length === 0
      ) && applicant.isApplicantGuarantor === "No";

    if (!isValid) {
      applicantOffset[applicantOffsetIndex] += 1;
    } else {
      applicantOffsetIndex += 1;
    }
    return isValid;
  });

  applicantOffset.forEach((item, index) => {
    if (index > 0) {
      applicantOffset[index] = applicantOffset[index] + applicantOffset[index - 1];
    }
  });

  applicants = eligibleApplicants.map((applicant) => {
    return {
      creditCards: applicant.creditCards.map((creditCard) => {
        return {
          creditLimit: creditCard.creditLimit,
        };
      }),
      applicantLivingArrangement:
        applicant.applicantLivingArrangement === livingArrangementItems.Family
          ? livingArrangementItems.Couple
          : applicant.applicantLivingArrangement,
      creditScore: applicant.privacyConsent.creditScore,
      financialObligations: applicant.financialObligation.map((financialObligation) => {
        return {
          balance: financialObligation.balance,
          payment: financialObligation.paymentAmount,
          duration: financialObligation.duration,
          interestRate: financialObligation.interestRate,
          type: financialObligation.type,
        };
      }),
      incomeSources: applicant.incomeSources.map((incomeSource) => {
        return {
          amount: incomeSource.amount,
          duration: incomeSource.duration,
          type: incomeSource.type,
          taxStatus: incomeSource.taxStatus,
        };
      }),
      incomeVerificationType: applicant.incomeVerificationType,
      isApplicantFirstHomeSection: applicant.isApplicantFirstHomeSection === "Yes",
      livingExpenses: applicant.livingExpenses.map((livingExpense) => {
        return {
          amount: livingExpense.amount ? livingExpense.amount : 0,
          duration: livingExpense.duration,
          type: livingExpense.type,
        };
      }),
      monthInBusiness: applicant.monthInBusiness,
      numberOfDependents: applicant.dependents.length.toString(),
      paygEmployeeOrSelfEmployee: applicant.employment[0]
        ? applicant.employment[0].employmentType === EmploymentTypes.PAYGEmployee ||
          applicant.employment[0].employmentType === EmploymentTypes.Other ||
          applicant.employment[0].employmentType === EmploymentTypes.PAYGContractor
          ? employmentTypesPrimary[0].value
          : "Self-employed"
        : employmentTypesPrimary[0].value,
    };
  });

  interface IFinalDataType {
    loan: ILrasLoanType;
    properties: ILrasPropertiesType[];
    applicants: ILrasApplicantsType[];
    howManyApplicant: string;
    applicantOffset: number[];
  }

  const data = {} as IFinalDataType;
  data.loan = loan;
  data.properties = properties;
  data.applicants = applicants;
  data.howManyApplicant = stateFiltered.applicant.howManyApplicant;
  data.applicantOffset = applicantOffset;
  return (dispatch) => {
    const url: string = lrasUrl ? lrasUrl : "";
    axios
      .post(url, JSON.stringify(data), {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        const lras = applicantInfoDisplacement(res.data.payload, applicantOffset, applicants);
        dispatch(helpSaveLRASAction(lras));
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          if (err.response.data.message.includes('"cashOutAmount" must be less than or equal')) {
            return dispatch(errorGenericErrorHandleAction(`LRAS Error: ${CashoutLRASError}`));
          }
          const errorMessage =
            err.response.data.message && typeof err.response.data.message !== "object"
              ? `LRAS Error: ${err.response.data.message}.`
              : "LRAS Error: Something went wrong.";
          return dispatch(errorGenericErrorHandleAction(errorMessage));
        } else {
          return dispatch(
            errorGenericErrorHandleAction(
              "LRAS Error: Network error. Please check your internet connection."
            )
          );
        }
      });
  };
};
