import { IReviewValidationState } from "amm-review-interface";
import { IFundsToCompleteDuty } from "amm-objective-interface";
import { TAppStateType } from "../../reducers/reducers";

export const REVIEW_SHOW_DOWNLOAD_STATUS = "REVIEW_SHOW_DOWNLOAD_STATUS";
export const REVIEW_FULL_FORM_VALIDATION = "REVIEW_FULL_FORM_VALIDATION";
export const REVIEW_SHOW_APPROVE_LOADER = "REVIEW_SHOW_APPROVE_LOADER";
export const REVIEW_SET_VALIDATION = "REVIEW_SET_VALIDATION";
export const REVIEW_FULL_FORM_VALIDATION_FOR_SEND_QUOTE =
  "REVIEW_FULL_FORM_VALIDATION_FOR_SEND_QUOTE";
export const REVIEW_SAVE_FUNDS_TO_COMPLETE = "REVIEW_SAVE_FUNDS_TO_COMPLETE";
export const REVIEW_CLEAR_VALIDATIONS_ARRAY = "REVIEW_CLEAR_VALIDATIONS_ARRAY";
export const REVIEW_LOAD_ENTIRE_STATE = "REVIEW_LOAD_ENTIRE_STATE";
export const REVIEW_SHOW_APPROVE_MODAL = "REVIEW_SHOW_APPROVE_MODAL";
export const REVIEW_SET_APPLICANT_FORM_SUBMITTED_FLAG = "REVIEW_SET_APPLICANT_FORM_SUBMITTED_FLAG";
export const REVIEW_PAGE_VISITED = "REVIEW_PAGE_VISITED";
export const REVIEW_SET_LEGACY_ID = "REVIEW_SET_LEGACY_ID";
interface Common {
  module: string[];
}
export interface ReviewShowDownloadStatusAction extends Common {
  type: "REVIEW_SHOW_DOWNLOAD_STATUS";
  status: string;
}

export interface ReviewSetApplicantFormSumittedFlagAction extends Common {
  type: "REVIEW_SET_APPLICANT_FORM_SUBMITTED_FLAG";
  isApplicantFormNotSubmitted: boolean;
}

export interface ReviewClearValidationsArrayAction extends Common {
  type: "REVIEW_CLEAR_VALIDATIONS_ARRAY";
}

export interface ReviewLoadEntireStateAction extends Common {
  state: TAppStateType["review"];
  type: "REVIEW_LOAD_ENTIRE_STATE";
}
export interface ReviewFullFormValidationAction extends Common {
  formInvalidArray: boolean[];
  type: "REVIEW_FULL_FORM_VALIDATION";
}

export interface ReviewShowApproveLoaderAction extends Common {
  toggleLoader?: boolean;
  uploadLoader?: boolean;
  scenarioApproveLoader?: boolean;
  isSubmittedToCapital?: boolean;
  type: "REVIEW_SHOW_APPROVE_LOADER";
}

export interface ReviewShowApproveModalAction extends Common {
  toggleShow: boolean;
  type: "REVIEW_SHOW_APPROVE_MODAL";
}

export interface ReviewSetValidationAction extends Common {
  type: "REVIEW_SET_VALIDATION";
  key: string;
  validations: IReviewValidationState;
}

export interface ReviewFullFormValidationForSendQuoteAction extends Common {
  formInvalidationArrayForSendQuote: boolean[];
  type: "REVIEW_FULL_FORM_VALIDATION_FOR_SEND_QUOTE";
}

export interface ReviewSaveFundsToCompleteAction extends Common {
  fundsToCompleteIndividual: IFundsToCompleteDuty[];
  fundsToCompleteTotal: IFundsToCompleteDuty;
  type: "REVIEW_SAVE_FUNDS_TO_COMPLETE";
}

export interface ReviewPageVisitedAction extends Common {
  isVisited: boolean;
  type: "REVIEW_PAGE_VISITED";
}
export interface reviewSetLegacyIDAction extends Common {
  legacyID: string;
  type: "REVIEW_SET_LEGACY_ID";
}

export type ReviewActions =
  | ReviewSaveFundsToCompleteAction
  | ReviewFullFormValidationForSendQuoteAction
  | ReviewSetValidationAction
  | ReviewShowApproveLoaderAction
  | ReviewShowApproveModalAction
  | ReviewFullFormValidationAction
  | ReviewShowDownloadStatusAction
  | ReviewLoadEntireStateAction
  | ReviewSetApplicantFormSumittedFlagAction
  | ReviewPageVisitedAction
  | ReviewClearValidationsArrayAction
  | reviewSetLegacyIDAction;

export type ReviewSetLegacyIDActionType = (legacyID: string) => reviewSetLegacyIDAction;

export type ReviewPageVisitedActionType = (isVisited: boolean) => ReviewPageVisitedAction;

export type ReviewShowDownloadStatusActionType = (status: string) => ReviewShowDownloadStatusAction;

export type ReviewFullFormValidationActionType = (
  formInvalidArray: boolean[]
) => ReviewFullFormValidationAction;

export type ReviewClearValidationsArrayActionType = () => ReviewClearValidationsArrayAction;

export type ReviewLoadEntireStateActionType = (
  state: TAppStateType["review"]
) => ReviewLoadEntireStateAction;

export type ReviewSetApplicantFormSumittedFlagActionType = (
  isApplicantFormNotSubmitted: boolean
) => ReviewSetApplicantFormSumittedFlagAction;

export type ReviewShowApproveLoaderActionType = (params: {
  toggleLoader?: boolean;
  uploadLoader?: boolean;
  scenarioApproveLoader?: boolean;
  isSubmittedToCapital?: boolean;
}) => ReviewShowApproveLoaderAction;

export type ReviewShowApproveModalActionType = (
  toggleShow: boolean
) => ReviewShowApproveModalAction;

export type ReviewSaveFundsToCompleteActionType = (
  fundsToCompleteIndividual: IFundsToCompleteDuty[],
  fundsToCompleteTotal: IFundsToCompleteDuty
) => ReviewSaveFundsToCompleteAction;

export type ReviewFullFormValidationForSendQuoteActionType = (
  formInvalidationArrayForSendQuote: boolean[]
) => ReviewFullFormValidationForSendQuoteAction;

export type ReviewSetValidationActionType = (
  validations: IReviewValidationState,
  key: string
) => ReviewSetValidationAction;
