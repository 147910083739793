import { ILRAS } from "amm-objective-interface";
import { ILrasApplicantsType } from "./calcLRASThunk";

/** This function will postion the applicant wise response from the LRAS to its correct applicant index because only some applicant info is sent to the LRAS and LRAS response for some objects is not in order. Responses need to be re-positioned applicantTaxBreakUp, applicantsIncomeInformation.assessedIncome, applicantsIncomeInformation.taxableIncome, totals.applicantsCombinedTotals.incomeSourcesAssessedPayments, totals.applicantsCombinedTotals.creditCardAssessedPayments, totals.applicantsCombinedTotals.financialObligationAssessedPayments. This function will displace the response per index of these mentioned objects as applicantIndex + applicantOffset[applicantIndex]
 * @param lrasPayload response from the LRAS
 * @param applicantOffset displacement array
 * @param applicants eligible applicants for the LRAS
 * @returns same LRAS response with few object values repositioneed
 *
 */
export const applicantInfoDisplacement = (
  lrasPayload: ILRAS,
  applicantOffset: number[],
  applicants: ILrasApplicantsType[]
) => {
  const lras = JSON.parse(JSON.stringify(lrasPayload));
  try {
    const applicantTaxBreakUp = JSON.parse(JSON.stringify(lras.applicantTaxBreakUp));
    lras.applicantTaxBreakUp = {};

    Object.keys(applicantTaxBreakUp).forEach((item, index) => {
      if (applicantTaxBreakUp[`applicant ${index + 1}`]) {
        const actualValue = applicantTaxBreakUp[`applicant ${index + 1}`];
        lras.applicantTaxBreakUp[`applicant ${index + applicantOffset[index] + 1}`] = actualValue;
      }
    });

    lras.applicantsIncomeInformation.assessedIncome = [];
    lras.applicantsIncomeInformation.taxableIncome = [];
    lras.totals.applicantsCombinedTotals.incomeSourcesAssessedPayments = {};
    lras.totals.applicantsCombinedTotals.creditCardAssessedPayments = {};
    lras.totals.applicantsCombinedTotals.financialObligationAssessedPayments = {};
    lras.nsrValues.applicantIndividualHem = [];

    applicants.forEach((applicant: ILrasApplicantsType, index: number) => {
      lras.applicantsIncomeInformation.assessedIncome[index + applicantOffset[index]] =
        lrasPayload.applicantsIncomeInformation.assessedIncome[index];

      lras.applicantsIncomeInformation.taxableIncome[index + applicantOffset[index]] =
        lrasPayload.applicantsIncomeInformation.taxableIncome[index];

      lras.totals.applicantsCombinedTotals.incomeSourcesAssessedPayments[
        index + applicantOffset[index]
      ] = lrasPayload.totals.applicantsCombinedTotals.incomeSourcesAssessedPayments[index];

      lras.totals.applicantsCombinedTotals.creditCardAssessedPayments[
        index + applicantOffset[index]
      ] = lrasPayload.totals.applicantsCombinedTotals.creditCardAssessedPayments[index];

      lras.totals.applicantsCombinedTotals.financialObligationAssessedPayments[
        index + applicantOffset[index]
      ] = lrasPayload.totals.applicantsCombinedTotals.financialObligationAssessedPayments[index];

      lras.nsrValues.applicantIndividualHem[index + applicantOffset[index]] =
        lrasPayload.nsrValues.applicantIndividualHem[index];
    });

    return lras;
  } catch (error) {
    return lras;
  }
};
