import React from "react";
import { Highlight } from "react-instantsearch-dom";
import { connect } from "react-redux";
import { getAlgoliaRecordsThunk } from "../../store/thunk/helpSectionThunk/getAlgoliaRecordsThunk";
import { Hit } from "react-instantsearch-core";

//TODO fix the any type
class HitProduct extends React.Component<{
  hit: Hit;
  getAlgoliaRecordsThunk: (arg: string) => void;
}> {
  public onHitItemClick = () => {
    this.toggleDisplay();
    const element: any = document.getElementsByClassName("ais-SearchBox-input")[0];
    element.value = "";
    this.props.getAlgoliaRecordsThunk(this.props.hit.objectID);
  };

  public toggleDisplay = () => {
    const element: any = document.getElementsByClassName("hits_display")[0];
    if (element.style.display === "block") {
      element.style.display = "none";
    } else {
      element.style.display = "block";
    }
  };
  public render() {
    return (
      <div onClick={this.onHitItemClick}>
        <Highlight attribute="heading" hit={this.props.hit} />
        <p>
          <Highlight attribute="content" hit={this.props.hit} />
        </p>
      </div>
    );
  }
}

const mapDispatchToProps = {
  getAlgoliaRecordsThunk,
};

const mapStateToProps = () => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(HitProduct);
