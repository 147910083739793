import {
  IEditBrokerState,
  IBrokerListType,
  ITeamAdmin,
  IReviewNoteData,
  IUserNotification,
} from "amm-admin-interface";
import { ILeadUsers, IOriginators, IScenarioPayloadUserScenarios } from "amm-scenario-interface";
import { IScenarioCapitalStatusUpdate } from "../scenarioActions/scenarioActionType";
import { IAssignUserScenariosModalData } from "./adminActions";
export const ADMIN_SET_EDIT_BROKER_STATE = "ADMIN_SET_EDIT_BROKER_STATE";
export const ADMIN_BROKER_VALUE_UPDATE = "ADMIN_BROKER_VALUE_UPDATE";
export const ADMIN_EDIT_BROKER_BUTTON_CLICK = "ADMIN_EDIT_BROKER_BUTTON_CLICK";
export const ADMIN_SET_BROKER_LIST = "ADMIN_SET_BROKER_LIST";
export const ADMIN_BROKER_SAVE_LOADER = "ADMIN_BROKER_SAVE_LOADER";
export const ADMIN_OPEN_SELECT_MODAL_ACTION = "ADMIN_OPEN_SELECT_MODAL_ACTION";
export const ADMIN_UPDATE_TEAM_MEMBER = "ADMIN_UPDATE_TEAM_MEMBER";
export const ADMIN_UPDATE_TEAM_DETAIL = "ADMIN_UPDATE_TEAM_DETAIL";
export const ADMIN_SET_TEAM = "ADMIN_SET_TEAM";
export const ADMIN_SET_TEAM_SAVE_DISABLE = "ADMIN_SET_TEAM_SAVE_DISABLE";
export const ADMIN_SET_TEAM_LOADER = "ADMIN_SET_TEAM_LOADER";
export const ADMIN_OPEN_MEMBER_MODAL = "ADMIN_OPEN_MEMBER_MODAL";
export const ADMIN_SET_SAVE_TEAM_LOADER = "ADMIN_SET_SAVE_TEAM_LOADER";
export const ADMIN_OPEN_ASSIGN_USER_SCENARIO_MODAL = "ADMIN_OPEN_ASSIGN_USER_SCENARIO_MODAL";
export const ADMIN_SET_ALL_SCENARIOS_LOADER = "ADMIN_SET_ALL_SCENARIOS_LOADER";
export const ADMIN_SET_ALL_SCENARIOS = "ADMIN_SET_ALL_SCENARIOS";
export const ADMIN_REVIEW_NOTES = "ADMIN_REVIEW_NOTES";
export const ADMIN_BROKER_NOTES = "ADMIN_BROKER_NOTES";
export const ADMIN_SET_CREDIT_REVIEW_SCENARIOS_LOADER = "ADMIN_SET_CREDIT_REVIEW_SCENARIOS_LOADER";
export const ADMIN_SET_CREDIT_REVIEW_SCENARIOS = "ADMIN_SET_CREDIT_REVIEW_SCENARIOS";
export const ADMIN_ADD_ORIGINATORS = "ADMIN_ADD_ORIGINATORS";
export const ADMIN_ADD_NOTIFICATIONS = "ADMIN_ADD_NOTIFICATIONS";
export const ADMIN_CAPITAL_STATUS_UPDATE = "ADMIN_CAPITAL_STATUS_UPDATE";
export const ADMIN_SET_LEADS = "ADMIN_SET_LEADS";
export const ADMIN_SET_LEADS_LOADER = "ADMIN_SET_LEADS_LOADER";
export const ADMIN_SET_ASSIGN_LEAD_MODAL_VISIBILITY = "ADMIN_SET_ASSIGN_LEAD_MODAL_VISIBILITY";

interface Common {
  module: string[];
}
export interface AdminSetEditBrokerStateAction extends Common {
  isNew: boolean;
  state: IEditBrokerState;
  type: "ADMIN_SET_EDIT_BROKER_STATE";
}

export interface AdminBrokerValueUpdateAction extends Common {
  key: string;
  value: React.ReactText | boolean;
  index?: number;
  child?: string;
  type: "ADMIN_BROKER_VALUE_UPDATE";
}

export interface AdminEditBrokerButtonClickAction extends Common {
  buttonType: string;
  referralCode?: string;
  type: "ADMIN_EDIT_BROKER_BUTTON_CLICK";
}

export interface AdminSetBrokerSaveLoaderAction extends Common {
  isLoading: boolean;
  type: "ADMIN_BROKER_SAVE_LOADER";
}

export interface AdminSetBrokerListAction extends Common {
  brokerList: IBrokerListType[];
  calledFrom: string;
  type: "ADMIN_SET_BROKER_LIST";
}

export interface AdminOpenSelectModalAction extends Common {
  isOpen: boolean;
  scenarioID: number;
  userID?: number;
  operation?: string;
  type: "ADMIN_OPEN_SELECT_MODAL_ACTION";
}

export interface AdminUpdateTeamMemberAction extends Common {
  broker: IBrokerListType;
  addOrRemove: string;
  type: "ADMIN_UPDATE_TEAM_MEMBER";
}

export interface AdminUpdateTeamDetailAction extends Common {
  teamName: string;
  type: "ADMIN_UPDATE_TEAM_DETAIL";
}

export interface AdminSetTeamAction extends Common {
  state: ITeamAdmin;
  type: "ADMIN_SET_TEAM";
}

export interface AdminSetTeamSaveDisableAction extends Common {
  isDisable: boolean;
  type: "ADMIN_SET_TEAM_SAVE_DISABLE";
}
export interface AdminSetTeamLoaderAction extends Common {
  showLoader: boolean;
  type: "ADMIN_SET_TEAM_LOADER";
}
export interface AdminOpenAddMemberModalAction extends Common {
  isOpen: boolean;
  type: "ADMIN_OPEN_MEMBER_MODAL";
}
export interface AdminSetSaveTeamButtonLoaderAction extends Common {
  saveTeamButtonLoader: boolean;
  type: "ADMIN_SET_SAVE_TEAM_LOADER";
}

export interface AdminOpenAssignUserScenariosAction extends Common {
  modalData: IAssignUserScenariosModalData;
  type: "ADMIN_OPEN_ASSIGN_USER_SCENARIO_MODAL";
}
export interface AdminSetLeadsAction extends Common {
  leads: IScenarioPayloadUserScenarios[];
  users: ILeadUsers[];
  type: "ADMIN_SET_LEADS";
}

export interface AdminSetLeadsLoaderAction extends Common {
  leadsLoader: boolean;
  type: "ADMIN_SET_LEADS_LOADER";
}

export interface AdminSetAllLeadsLoaderAction extends Common {
  allUserScenariosLoader: boolean;
  type: "ADMIN_SET_CREDIT_REVIEW_SCENARIOS_LOADER";
}

export interface AdminSetAssignLeadModalVisibilityAction extends Common {
  isAssignModalVisible: boolean;
  type: "ADMIN_SET_ASSIGN_LEAD_MODAL_VISIBILITY";
}
export interface AdminSetCreditReviewScenariosAction extends Common {
  scenariosForCreditReview: IScenarioPayloadUserScenarios[];
  type: "ADMIN_SET_CREDIT_REVIEW_SCENARIOS";
}

export interface AdminReviewNotesAction extends Common {
  reviewNotesData: IReviewNoteData;
  type: "ADMIN_REVIEW_NOTES";
}
export interface AdminBrokerAddNotesAction extends Common {
  brokerNotesData: string;
  type: "ADMIN_BROKER_NOTES";
}

export interface AdminAddOriginatorsAction extends Common {
  originators: IOriginators[];
  type: "ADMIN_ADD_ORIGINATORS";
}
export interface AdminAddNotificationsAction extends Common {
  notifications: IUserNotification[];
  type: "ADMIN_ADD_NOTIFICATIONS";
}

export interface AdminUpdateCapitalStatusAction extends Common {
  scenarioId: number;
  capitalStatus: string;
  type: "ADMIN_CAPITAL_STATUS_UPDATE";
}

export type AdminActions =
  | AdminSetEditBrokerStateAction
  | AdminBrokerValueUpdateAction
  | AdminEditBrokerButtonClickAction
  | AdminSetBrokerSaveLoaderAction
  | AdminSetBrokerListAction
  | AdminOpenSelectModalAction
  | AdminUpdateTeamMemberAction
  | AdminUpdateTeamDetailAction
  | AdminSetTeamAction
  | AdminSetTeamSaveDisableAction
  | AdminSetTeamLoaderAction
  | AdminOpenAddMemberModalAction
  | AdminSetSaveTeamButtonLoaderAction
  | AdminOpenAssignUserScenariosAction
  | AdminSetLeadsLoaderAction
  | AdminSetLeadsAction
  | AdminReviewNotesAction
  | AdminAddOriginatorsAction
  | AdminAddNotificationsAction
  | AdminUpdateCapitalStatusAction
  | AdminBrokerAddNotesAction
  | AdminSetCreditReviewScenariosAction
  | AdminSetAssignLeadModalVisibilityAction
  | AdminSetLeadsLoaderAction
  | AdminSetAllLeadsLoaderAction;

export type AdminUpdateCapitalStatusType = (
  params: IScenarioCapitalStatusUpdate
) => AdminUpdateCapitalStatusAction;
export type AdminAddOriginatorsActionType = (
  originators: IOriginators[]
) => AdminAddOriginatorsAction;

export type AdminSetEditBrokerStateActionType = (
  state: IEditBrokerState,
  isNew: boolean
) => AdminSetEditBrokerStateAction;

export type AdminBrokerValueUpdateActionType = (
  key: string,
  value: React.ReactText | boolean,
  index?: number,
  child?: string
) => AdminBrokerValueUpdateAction;

export type AdminEditBrokerButtonClickActionType = (
  buttonType: string,
  referralCode?: string
) => AdminEditBrokerButtonClickAction;

export type AdminSetBrokerSaveLoaderActionType = (
  isLoading: boolean
) => AdminSetBrokerSaveLoaderAction;

export type AdminSetBrokerListActionType = (
  brokerList: IBrokerListType[],
  calledFrom: string
) => AdminSetBrokerListAction;

export type AdminOpenSelectModalActionType = (
  isOpen: boolean,
  scenarioID: number,
  userID?: number,
  operation?: string
) => AdminOpenSelectModalAction;

export type AdminUpdateTeamMemberActionType = (
  broker: IBrokerListType,
  addOrRemove: string
) => AdminUpdateTeamMemberAction;

export type AdminUpdateTeamDetailActionType = (teamName: string) => AdminUpdateTeamDetailAction;

export type AdminSetTeamActionType = (state: ITeamAdmin) => AdminSetTeamAction;

export type AdminSetTeamSaveDisableActionType = (
  isDisable: boolean
) => AdminSetTeamSaveDisableAction;
export type AdminSetTeamLoaderActionType = (showLoader: boolean) => AdminSetTeamLoaderAction;
export type AdminOpenAddMemberModalActionType = (isOpen: boolean) => AdminOpenAddMemberModalAction;
export type AdminSetSaveTeamButtonLoaderActionType = (
  saveTeamButtonLoader: boolean
) => AdminSetSaveTeamButtonLoaderAction;
export type AdminOpenAssignUserScenariosActionType = (
  assignUserScenariosModalData: IAssignUserScenariosModalData
) => AdminOpenAssignUserScenariosAction;
export type AdminSetAllLeadsLoaderActionType = (
  allUserScenariosLoader: boolean
) => AdminSetAllLeadsLoaderAction;
export type AdminReviewNotesActionType = (
  reviewNotesData: IReviewNoteData
) => AdminReviewNotesAction;
export type AdminSetCreditReviewScenariosActionType = (
  scenariosForCreditReview: IScenarioPayloadUserScenarios[]
) => AdminSetCreditReviewScenariosAction;
export type AdminAddNotificationsActionType = (
  notifications: IUserNotification[]
) => AdminAddNotificationsAction;
export type AdminBrokerAddNotesActionType = (brokerNotesData: string) => AdminBrokerAddNotesAction;

export type AdminSetLeadsActionType = (
  leads: IScenarioPayloadUserScenarios[],
  users: ILeadUsers[]
) => AdminSetLeadsAction;
export type AdminSetLeadsLoaderActionType = (leadsLoader: boolean) => AdminSetLeadsLoaderAction;
export type AdminSetAssignLeadModalVisibilityActionType = (
  isAssignModalVisible: boolean
) => AdminSetAssignLeadModalVisibilityAction;
