import { Reducer } from "redux";
import {
  OBJECTIVE_ADD_NEW_PROPERTY,
  OBJECTIVE_ADDRESS_AUTO_SUGGEST,
  OBJECTIVE_ADDRESS_LOADER,
  OBJECTIVE_CLEAR_ADDRESS_FIELD,
  OBJECTIVE_CLEAR_ADDRESS_RESULT,
  OBJECTIVE_CURRENT_TAB,
  OBJECTIVE_GET_IMAGE_GALLERY,
  OBJECTIVE_GET_PROPERTY,
  OBJECTIVE_LOAD_STATE,
  OBJECTIVE_PROPERTY_ESTIMATE_RESULT,
  OBJECTIVE_REMOVE_PROPERTY,
  OBJECTIVE_STATE_UPDATE,
  ObjectiveActions,
  OBJECTIVE_SET_SUBURB_CHECKED_INDICATOR,
  OBJECTIVE_INITIAL_IDS_VALUES,
  OBJECTIVE_SET_STATISTICAL_VALUATION_MESSAGE,
} from "../../actions/objectivePageActions/objectivePageActionTypes";
import {
  objectiveAddressAutoSuggest,
  objectiveCurrentTabReducer,
  objectiveRemoveProperty,
  objectiveStateUpdate,
  propertyDefaultValue,
} from "../utility/objectiveReducerUtility";

import { IObjectState } from "amm-objective-interface";
import { moduleConstant } from "../../../constants";
import { deepCopyObject } from "../../../utility";
import { LOGIN_USER_LOGOUT, LoginUserLogoutAction } from "../../actions/login/loginActionType";

export const objectiveInitialState: IObjectState = {
  currentTab: 0,
  errorCount: 0,
  maxTab: 4,
  properties: [{ ...propertyDefaultValue }],
  propertyEstimateResult: false,
  suburbCheckedIndicator: false
};

const objectiveReducer: Reducer<IObjectState, ObjectiveActions | LoginUserLogoutAction> = (
  state: IObjectState = deepCopyObject(objectiveInitialState),
  action: ObjectiveActions | LoginUserLogoutAction
) => {
  const currentState = Object.assign({}, state);
  let properties: IObjectState["properties"] = [...currentState.properties];
  const activeTab = state.currentTab;
  properties = properties.slice();

  if (
    action &&
    action.module &&
    action.module.filter((currentModule) => Object.values(moduleConstant).includes(currentModule))
      .length === 0
  ) {
    return { ...state };
  }

  switch (action.type) {
    case OBJECTIVE_CURRENT_TAB:
      return objectiveCurrentTabReducer(currentState, action.currentTab);

    case OBJECTIVE_ADD_NEW_PROPERTY:
      properties = properties.concat([{ ...propertyDefaultValue }]); // add new array
      return {
        ...currentState,
        properties,
      };

    case OBJECTIVE_SET_SUBURB_CHECKED_INDICATOR:
      let suburbCheckedIndicator: boolean;
      if (action.objectiveInvalidArray.includes(true)) {
        suburbCheckedIndicator = true;
      } else {
        suburbCheckedIndicator = false;
      }
      return {
        ...currentState,
        suburbCheckedIndicator,
      };
    case OBJECTIVE_REMOVE_PROPERTY:
      return objectiveRemoveProperty(action, properties, currentState, activeTab);

    case OBJECTIVE_INITIAL_IDS_VALUES:
      currentState.properties[action.currentTab].initialIDSBedrooms = action.bedrooms;
      currentState.properties[action.currentTab].initialIDSCarparks = action.carparks;
      currentState.properties[action.currentTab].initialIDSBathrooms = action.bathrooms;
      return { ...currentState };

    case OBJECTIVE_SET_STATISTICAL_VALUATION_MESSAGE:
      currentState.properties[action.currentTab].statisticalValuationMessageOption = action.message;
      return { ...currentState };

    case OBJECTIVE_STATE_UPDATE:
      return objectiveStateUpdate(action, properties, currentState, activeTab);

    case OBJECTIVE_ADDRESS_AUTO_SUGGEST:
      return objectiveAddressAutoSuggest(action, properties, currentState);

    case OBJECTIVE_CLEAR_ADDRESS_RESULT:
    case OBJECTIVE_CLEAR_ADDRESS_FIELD: // clear the autosuggest state
      properties[activeTab].propertiesAutoSuggestions = [];
      properties[activeTab].suburbAutoSuggestions = [];
      properties[activeTab].propertyValue = 0;
      properties[activeTab].gallery = [];
      properties[activeTab].name = "";
      properties[activeTab].mapLat = 0;
      properties[activeTab].mapLng = 0;
      properties[activeTab].confidenceLevel = "Medium";
      properties[activeTab].suburb = "";
      properties[activeTab].placeId = "";
      properties[activeTab].medianPrice = 0;
      properties[activeTab].estimatedDate = "1999-12-12";
      properties[activeTab].estimateRangeHigh = 130000;
      properties[activeTab].estimateRangeLow = 70000;
      properties[activeTab].sliderMaxValue = 200000;
      properties[activeTab].estimatedValue = 100000;
      properties[activeTab].bathroomsInitial = 0;
      properties[activeTab].bedroomInitial = 0;
      properties[activeTab].carparksInitial = 0;
      if (!action.calledFrom) {
        properties[activeTab].noPropertyFound = false;
        properties[activeTab].suburbPropertyAddress = "";
      }
      if (properties[activeTab].purchaseType === "I've found my property" && !properties[activeTab].noPropertyFound) {
        properties[activeTab].bedroom = 2;
        properties[activeTab].propertyType = "House";
      }
      if (action.type === OBJECTIVE_CLEAR_ADDRESS_FIELD) {
        properties[activeTab].propertyAddress = "";
      }
      return {
        ...currentState,
        properties,
      };

    case OBJECTIVE_ADDRESS_LOADER:
      if (properties.length > action.currentTab) {
        properties[action.currentTab].loader = action.loader;
      }
      return {
        ...currentState,
        properties,
      };

    case OBJECTIVE_PROPERTY_ESTIMATE_RESULT:
      const propertyEstimateResult = action.propertyEstimateResult;
      return { ...currentState, propertyEstimateResult };

    case OBJECTIVE_GET_PROPERTY:
      const data = action.property;
      Object.keys(data).forEach((key) => (properties[action.currentTab][key] = data[key]));
      return {
        ...currentState,
        properties,
      };

    case OBJECTIVE_GET_IMAGE_GALLERY:
      properties[activeTab].gallery = action.gallery;
      return {
        ...currentState,
        properties,
      };

    case OBJECTIVE_LOAD_STATE:
      const newState = action.state;
      return {
        ...newState,
      };

    case LOGIN_USER_LOGOUT:
      return { ...objectiveInitialState };

    default:
      return { ...currentState };
  }
};
export default objectiveReducer;
