import React from "react";
import { Snackbar } from "react-md";
import { connect } from "react-redux";
import { successSnackBarCloseAction } from "../../store/actions/snackbarSuccessAction/successAction";
import { SuccessSnackBarCloseActionType } from "../../store/actions/snackbarSuccessAction/successActionType";
import { IToastsInterface } from "../../store/reducers/successSnackbarReducer";
import { TAppStateType } from "../../store/reducers/reducers";

interface StateProps {
  autohide: boolean;
  toasts: IToastsInterface[];
  autoHideTimeout?: number;
}

interface DispatchProps {
  successSnackBarCloseAction: SuccessSnackBarCloseActionType;
}
type SuccessSnackbarProps = StateProps & DispatchProps;

export const SuccessSnackbar: React.FC<SuccessSnackbarProps> = (props: SuccessSnackbarProps) => {
  return (
    <Snackbar
      id="successSnackbar"
      className="success-snackbar"
      toasts={props.toasts as any}
      autohide={props.autohide}
      autohideTimeout={props.autoHideTimeout}
      onDismiss={props.successSnackBarCloseAction}
    />
  );
};

export const mapStateToProps = (state: TAppStateType) => {
  return {
    autohide: state.successSnackbar.autohide,
    autoHideTimeout: state.successSnackbar.autoHideTimeout as number,
    toasts: state.successSnackbar.toasts,
  };
};

export const mapDispatchToProps = {
  successSnackBarCloseAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(SuccessSnackbar);
