import React from "react";

const BrandIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className="brand-icon" viewBox="0 0 44.99 45">
      <g id="DwOyQY.tif">
        <g>
          <path d="M4.84,29.86C13.64,21.05,21.79,12.9,30.59,4.09l10.31,10.31c-8.8,8.8-16.95,16.95-25.75,25.75L4.84,29.86Z" />
          <path d="M13.57,41.72c-.7,.62-1.31,1.73-2.35,1.9-3.09,.46-6.18,.91-9.27,1.35-1.23,.18-2.12-.77-1.93-2.01,.45-3.07,.89-6.14,1.34-9.21,.16-1.04,1.26-1.63,1.88-2.37l10.33,10.34Z" />
          <path d="M42.55,12.74L32.22,2.42c1.14-1.08,2.66-3.51,4.32-1.87,2.63,2.63,5.27,5.26,7.9,7.9,1.66,1.73-.82,3.13-1.88,4.29Z" />
        </g>
      </g>
    </svg>
  );
};

export default BrandIcon;
