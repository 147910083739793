import React from "react";
import AccountSummary from "./accountSummary";
import PropertySummary from "./propertySummary";

const PropertyAccountSummary: React.FC = () => {
  return (
    <React.Fragment>
      <div className="property-account-summary">
        <PropertySummary />
      </div>
      <div className="property-account-summary white-bg">
        <AccountSummary />
      </div>
    </React.Fragment>
  );
};

export default PropertyAccountSummary;
