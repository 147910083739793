import { ThunkAction } from "redux-thunk";
import { Action } from "redux";
import axios from "axios";
import { supportedFileTypes, viewDocumentApi } from "../../../constants";
import { viewDocumentAction } from "../../../constants/userActions";
import { uploadDocumentPreviewAction } from "../../actions/uploadDocumentsActions/uploadDocumentsAction";
import { errorGenericErrorHandleAction } from "../../actions/errorActions/errorAction";

const downloadFile = (fileName: string, fileType: string, url: string) => {
  navigator.platform === "iPad"
    ? window.open(url)
    : fetch(url).then((response) => {
        response.blob().then((blob) => {
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${fileName}.${fileType}`);

          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        });
      });
};

export type ViewDocumentThunkType = (
  scenarioId: number,
  fileName: string,
  token: string,
  currentUserId: number,
  currentScenarioUserId: number,
  fileType: string,
  isTeamMemberOrOwnScenario: boolean,
  isOpenedByReviewer: boolean,
  isDownload: boolean
) => ThunkAction<any, any, any, Action>;
export const viewDocumentThunk: ViewDocumentThunkType = (
  scenarioId: number,
  fileName: string,
  token: string,
  currentUserId: number,
  currentScenarioUserId: number,
  fileType: string,
  isTeamMemberOrOwnScenario: boolean,
  isOpenedByReviewer: boolean,
  isDownload: boolean
): ThunkAction<any, any, any, Action> => {
  const apiUrl = viewDocumentApi;
  return async (dispatch) => {
    try {
      if (!supportedFileTypes.includes(fileType)) {
        dispatch(uploadDocumentPreviewAction(true, fileName, "", fileType));
      } else {
        dispatch(uploadDocumentPreviewAction(true, "", "", ""));
        const resp = await axios.get(apiUrl, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            scenario_id: scenarioId,
            file_name: fileName,
            user_action: isOpenedByReviewer
              ? viewDocumentAction[3]
              : currentScenarioUserId === currentUserId
              ? viewDocumentAction[0]
              : isTeamMemberOrOwnScenario
              ? viewDocumentAction[1]
              : viewDocumentAction[2],
          },
        });

        if (resp) {
          /**
           * This condition is for files which are pdf but doesnt have any identifier in name.
           * Default type received is json. So we are checking if the signed url has `.pdf` included in it
           */
          if (`${resp.data.payload.documentUrl}`.includes(".pdf?") && fileType === "json") {
            fileType = "pdf";
          }
          dispatch(
            uploadDocumentPreviewAction(true, fileName, resp.data.payload.documentUrl, fileType)
          );
          if (isDownload) {
            downloadFile(fileName, fileType, resp.data.payload.documentUrl);
          }
        } else {
          throw new Error("Unable to fetch the document");
        }
      }
    } catch (error) {
      dispatch(uploadDocumentPreviewAction(false, "", "", ""));
      if (error instanceof Error) {
        dispatch(errorGenericErrorHandleAction(error.message));
      }
    }
  };
};
