import React from "react";

const GearIcon = () => {
  return (
    <svg
      className="gear-icon"
      width="26"
      height="24"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 25 25"
    >
      <title>icon-gear</title>
      <path
        d="M10.7424,0H14.258a.93.93,0,0,1,.5965.9083c.04.5822.1327,1.1607.1992,1.7412a.2957.2957,0,0,0,.2174.2737q1.0383.4077,2.063.8495a.2446.2446,0,0,0,.3-.0333c.5-.4027,1.0085-.796,1.5141-1.1922a.7467.7467,0,0,1,1.1232.0594q1.0539,1.0518,2.1052,2.1065a.7476.7476,0,0,1,.07,1.1407c-.4323.5485-.8634,1.098-1.2505,1.5905.3038.7747.599,1.5013.8693,2.2369a.3355.3355,0,0,0,.3106.2685c.5728.0627,1.143.1564,1.7171.1957A.9262.9262,0,0,1,25,10.742v3.5156a.9265.9265,0,0,1-.9079.5965c-.5741.04-1.1455.1242-1.7161.2034a.3642.3642,0,0,0-.2577.152c-.31.7634-.6028,1.534-.9177,2.3475.39.4961.8267,1.0516,1.263,1.6076a.7369.7369,0,0,1-.0524,1.0863q-1.076,1.0819-2.1563,2.1592a.7378.7378,0,0,1-1.087.0553c-.499-.3914-1.0032-.7765-1.4936-1.1786a.3086.3086,0,0,0-.3834-.0379c-.6358.2815-1.2718.5679-1.9268.7983a.4172.4172,0,0,0-.3247.4237c-.0616.6055-.1273,1.2109-.2083,1.814a.758.758,0,0,1-.8532.7144q-1.4524.0016-2.905,0c-.5989,0-.8659-.2623-.9209-.8585-.0552-.5979-.1379-1.1932-.2052-1.79a.2946.2946,0,0,0-.2168-.2741c-.6924-.271-1.38-.5559-2.0636-.8479a.2446.2446,0,0,0-.3.0281c-.506.4088-1.0207.8067-1.5325,1.2083a.7419.7419,0,0,1-1.104-.0685Q3.6683,21.336,2.61,20.271a.7479.7479,0,0,1-.0572-1.1236c.4319-.5492.8629-1.0991,1.2533-1.5966-.3014-.7615-.599-1.4787-.8645-2.2074a.3659.3659,0,0,0-.34-.295c-.6052-.0643-1.209-.1415-1.8144-.2041a.7859.7859,0,0,1-.7871-.8653q-.0018-1.4524,0-2.905A.8088.8088,0,0,1,.86,10.1521c.6059-.0563,1.2093-.14,1.8141-.2078A.2793.2793,0,0,0,2.9227,9.73q.41-1.0373.85-2.0631a.2436.2436,0,0,0-.0276-.2992c-.4083-.5063-.8061-1.0211-1.2073-1.5332A.74.74,0,0,1,2.6058,4.73Q3.6659,3.6669,4.729,2.607a.7448.7448,0,0,1,1.1238-.0558c.5489.4319,1.0983.863,1.5927,1.2513.7729-.3025,1.5-.5973,2.2354-.8673a.3354.3354,0,0,0,.2691-.31c.0626-.5728.1561-1.143.1958-1.7171A.9266.9266,0,0,1,10.7424,0ZM18.75,12.5229A6.25,6.25,0,1,0,12.49,18.75,6.2437,6.2437,0,0,0,18.75,12.5229Z"
        transform="translate(0)"
      />
    </svg>
  );
};

export default GearIcon;
