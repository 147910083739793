import React, { Component } from "react";
import { Provider } from "react-redux";
import { StoreContext } from "redux-react-hook";
import Snackbar from "./components/common/errorSnackbar";
import SuccessSnackbar from "./components/common/successSnackbar";
import Routes from "./routes";
import store from "./store/configureStore";
import "./style/app.scss";
import { Helmet } from "react-helmet";
import Favicon from "react-favicon";
import { unstable_HistoryRouter as HistoryRouter } from "react-router-dom";
import history from "./routes/history";

class App extends Component {
  getAppTitle() {
    if (process.env.REACT_APP_DOMAIN_TITLE && process.env.REACT_APP_VERSION)
      return (
        <title>
          {process.env.REACT_APP_DOMAIN_TITLE}
          {` ${process.env.REACT_APP_VERSION}`}
        </title>
      );
    else if (process.env.REACT_APP_DOMAIN_TITLE)
      return <title>{process.env.REACT_APP_DOMAIN_TITLE}</title>;
    else return <title>AMM</title>;
  }

  render() {
    return (
      // @ts-ignore
      <HistoryRouter history={history}>
        <StoreContext.Provider value={store}>
          <Provider store={store}>
            <React.Fragment>
              {process.env.REACT_APP_DOMAIN && (
                <Favicon
                  url={`https://${process.env.REACT_APP_AWS_S3_BUCKET}.s3-ap-southeast-2.amazonaws.com/fav-icons/favicon-${process.env.REACT_APP_DOMAIN}.png`}
                />
              )}
              <Helmet>
                <meta name="description" content="Helmet application" />
                {this.getAppTitle()}
              </Helmet>
              <Routes />
              <Snackbar />
              <SuccessSnackbar />
            </React.Fragment>
          </Provider>
        </StoreContext.Provider>
      </HistoryRouter>
    );
  }
}

export default App;
