import {
  IApplicant,
  IApplicantInitialState,
  IApplicantRequiredField,
} from "amm-applicant-interface";
import { IPropertyArray } from "amm-objective-interface";
import React, { useEffect, useState } from "react";
import { Button } from "react-md";
import Tooltip from "@material-ui/core/Tooltip";
import { connect } from "react-redux";
import {
  APPROVE_LETTERS,
  CREDIT_REVIEW_SAVE_ACTIONS,
  invalidCategory,
  prelimPDFOptions,
  STATUS_NAMES,
  updateStatusOptions,
  uploadToolTipText,
} from "../../constants";
import { helpShowReviewScreenAction } from "../../store/actions/helpActions/helpAction";
import { HelpShowReviewScreenActionType } from "../../store/actions/helpActions/helpActionType";
import { IErrorStateInterface } from "../../store/reducers/errorReducers/errorReducer";
import { fullFormValidationThunk } from "../../store/thunk/reviewThunk/fullFormValidationThunk";
import { reviewSubmitThunk } from "../../store/thunk/reviewThunk/reviewSubmitThunk";
import { sendQuoteThunk } from "../../store/thunk/reviewThunk/sendQuoteThunk";
import {
  saveScenarioThunk,
  SaveScenarioThunkType,
} from "../../store/thunk/scenarioThunk/saveScenarioThunk";
import {
  checkPropertiesHasAddress,
  checkPropertiesHaveValue,
  checkApplicantTotalIncome,
  isReviewer,
  IISReviewer,
  deepCopyObject,
} from "../../utility/index";
import {
  AffordabilityProps,
  ApplicantProps,
  isAffordabilityProps,
  isObjectiveProps,
  isReviewProps,
  isSolutionOrApplicantProps,
  ObjectiveProps,
  ReviewProps,
} from "../../utility/nextAndBackButtonSectionUtility";
import {
  applicantClearAffordabilityPageData,
  applicantClearDocumentIdAction,
  applicantAffordabilityClearTableAction,
} from "../../store/actions/applicantActions/applicantActions";
import {
  ApplicantAffordabilityClearTableActionType,
  ApplicantClearAffordabilityPageDataActionType,
  ApplicantClearDocumentIdActionType,
} from "../../store/actions/applicantActions/applicantActionTypes";
import { TAppStateType } from "../../store/reducers/reducers";
import { IReviewValidationState } from "amm-review-interface";
import { IScenarioPayloadUserScenarios } from "amm-scenario-interface";
import { IReviewNoteData, ITeam } from "amm-admin-interface";
import { checkIfAllValidationIsCorrect } from "../../utility/validationPerPage";
import {
  reviewSetValidationAction,
  reviewSetLegacyID,
} from "../../store/actions/reviewActions/reviewActions";
import {
  ReviewSetValidationActionType,
  ReviewSetLegacyIDActionType,
} from "../../store/actions/reviewActions/reviewActionTypes";
import { ScenarioModalShowActionType } from "../../store/actions/scenarioActions/scenarioActionType";
import { scenarioModalShowAction } from "../../store/actions/scenarioActions/scenarioActions";
import { getSaveScearioUserAction } from "./utility";
import { uploadModalVisibleAction } from "../../store/actions/uploadDocumentsActions/uploadDocumentsAction";
import { UploadModalVisibleActionType } from "../../store/actions/uploadDocumentsActions/uploadDocumentsActionType";
import UploadDocumentModal from "../uploadDocuments/uploadDocumentModal";
import {
  scenarioDashboardSaveAction,
  scenarioGeneratePdfSelfAction,
} from "../../constants/userActions";
import { TextField } from "@material-ui/core";
import {
  generatePDFThunk,
  TGeneratePDFThunkType,
} from "../../store/thunk/reviewThunk/generatePDFThunk";
import { Button as MaterialButton, Menu, MenuItem } from "@material-ui/core";
import { KeyboardArrowDown } from "@material-ui/icons";
import ReviewModal from "../login/reviewModal";
import { IReviewCondition } from "amm-review-interface";
import { useNavigate } from "react-router-dom";
import LoanWriterNotesModal from "../login/loanWriterNotesModal";

interface OwnProps {
  preApprove: boolean;
  showReview: boolean;
  hideReview: boolean;
  nextUrl: string;
  backUrl: string;
  nextBtnName: string;
}

interface StateProps {
  affordabilityValidation: boolean;
  allScenarios: IScenarioPayloadUserScenarios[];
  applicantState: IApplicantInitialState;
  applicants: IApplicant[];
  applicantsRequiredField: IApplicantRequiredField[];
  currentScenarioId: number;
  error: IErrorStateInterface;
  formInvalidArray: boolean[];
  formInvalidationArrayForSendQuote: boolean[];
  fundsToCompleteSum: number;
  isSaveButtonLoaderShow: boolean;
  isLRASMetricValid: boolean;
  loggedInUserScenarios: IScenarioPayloadUserScenarios[];
  properties: IPropertyArray[];
  suburbCheckedIndicator: boolean;
  toggleLoader?: boolean;
  scenarioApproveLoader?: boolean;
  token: string;
  totalFee: number;
  totalState: TAppStateType;
  footerValidations: IReviewValidationState;
  teams: ITeam;
  currentTeamID: number;
  saveTeamScenarioPermission: boolean;
  userIDForScenario: number;
  currentUserID: number;
  selectedUserScenarios: IScenarioPayloadUserScenarios[];
  editTeamPermission: boolean;
  viewSaveButtonAsReviewerPermission: boolean;
  saveAsOwnScenarioModalShow: boolean;
  currentUserRoleID: number;
  scenarioLegacyID: string;
  currentScenarioReviewerID?: number;
  currentScenarioOwnerID: number;
  currentScenarioStatus?: string;
  isUploadModalVisible: boolean;
  tagsModalVisibility: boolean;
  viewEditLegacyID?: boolean;
  reviewNotesData: IReviewNoteData;
  approvedPendingConditions: IReviewCondition[];
  uploadDocumentsForAllScenariosAsReviewer: boolean;
  isCreditReviwerScenario: IScenarioPayloadUserScenarios;
}

interface DispatchProps {
  fullFormValidationThunk: (
    applicantState: IApplicantInitialState,
    applicants: IApplicant[],
    applicantsRequiredField: IApplicantRequiredField[],
    properties: IPropertyArray[],
    error: IErrorStateInterface,
    state: any
  ) => void;
  reviewSetValidationAction: ReviewSetValidationActionType;
  reviewSubmitThunk: (
    state: TAppStateType,
    token: string,
    scenarioId: number,
    saveTeamPermission: boolean,
    editTeamPermission: boolean,
    scenarioLegacyID?: string
  ) => void;
  saveScenarioThunk: SaveScenarioThunkType;
  sendQuoteThunk: (token: string, applicants: IApplicant[]) => void;
  helpShowReviewScreenAction: HelpShowReviewScreenActionType;
  applicantClearAffordabilityPageData: ApplicantClearAffordabilityPageDataActionType;
  applicantClearDocumentIdAction: ApplicantClearDocumentIdActionType;
  applicantAffordabilityClearTableAction: ApplicantAffordabilityClearTableActionType;
  scenarioModalShowAction: ScenarioModalShowActionType;
  uploadModalVisibleAction: UploadModalVisibleActionType;
  reviewSetLegacyID: ReviewSetLegacyIDActionType;
  generatePDFThunk: TGeneratePDFThunkType;
}
export type NextAndBackButtonSectionProps = OwnProps & StateProps & DispatchProps;

export const NextAndBackButtonSection: React.FC<
  AffordabilityProps | ApplicantProps | ObjectiveProps | ReviewProps
> = (props: AffordabilityProps | ApplicantProps | ObjectiveProps | ReviewProps) => {
  const navigate = useNavigate();
  const backButton = (backUrl: string, hideReview: boolean) => {
    window.scrollTo(0, 0);
    if (hideReview) {
      props.helpShowReviewScreenAction(false);
      navigate(backUrl);
    } else {
      navigate(backUrl);
    }
  };

  const isScenarioUnderReview =
    props.currentScenarioStatus === STATUS_NAMES.creditReview ||
    props.currentScenarioStatus === STATUS_NAMES.approvedPending;

  const isCreditReviewer = props.uploadDocumentsForAllScenariosAsReviewer && isScenarioUnderReview;

  const isThisLoggedInUserScenario =
    !props.currentScenarioId ||
    props.loggedInUserScenarios.find((scenario) => scenario.id === props.currentScenarioId) ||
    (props.isCreditReviwerScenario &&
      props.isCreditReviwerScenario.reviewer_id &&
      props.isCreditReviwerScenario.reviewer_id === props.userIDForScenario);

  const [propertyCategoryIsInvalid, setPropertyCategoryIsInvalid] = useState<boolean>(false);
  const [isReviewModalOpen, setIsReviewModalOpen] = useState<boolean>(false);
  const [conditions, setConditions] = useState<IReviewCondition[]>([]);
  const [calledFrom, setCalledFrom] = useState<string>("");
  const [isBrokerNotesModalOpen, setIsBrokerNotesModalOpen] = useState<boolean>(false);

  useEffect(() => {
    props.reviewSetValidationAction(
      checkIfAllValidationIsCorrect("affordability", props.totalState),
      "affordability"
    );
  }, [props.totalFee]);

  useEffect(() => {
    setPropertyCategoryIsInvalid(
      Boolean(
        props.properties.filter(
          (property) => invalidCategory.includes(property.propertyCategory) || property.highDensity
        ).length
      )
    );
  }, [props.properties.map((property) => property.propertyCategory)]);

  const affordabilityNextBtnDisabled = () => {
    const hasPropertiesAddress = checkPropertiesHasAddress(props.properties);
    const hasPropertiesValue = checkPropertiesHaveValue(props.properties);
    const isApplicantsHaveIncomeSource = checkApplicantTotalIncome(props.applicants);

    return (
      hasPropertiesAddress ||
      propertyCategoryIsInvalid ||
      !hasPropertiesValue ||
      !isApplicantsHaveIncomeSource
    );
  };

  const uploadButtonDisabled = () => {
    return !props.currentScenarioId;
  };

  const hasPropertiesAddress = checkPropertiesHasAddress(props.properties);

  const isStatusNotApproveRequired =
    props.currentScenarioStatus !== STATUS_NAMES.scenario &&
    props.currentScenarioStatus !== STATUS_NAMES.reWork;

  const isScenarioLegacyIDRequired =
    props.viewEditLegacyID && props.scenarioLegacyID.trim().replace(/ +/g, " ") === "";

  const isApproveDisabled = () => {
    return (
      props.formInvalidArray.includes(true) ||
      props.suburbCheckedIndicator ||
      (props.fundsToCompleteSum < props.totalFee && !props.affordabilityValidation) ||
      (props.nextBtnName === "Approve" && !props.footerValidations.applicant) ||
      !props.footerValidations.solution ||
      props.toggleLoader ||
      props.scenarioApproveLoader ||
      // if for AFGLW legacy ID is not added
      isScenarioLegacyIDRequired
    );
  };

  const areReviewCheckboxChecked = () =>
    props.reviewNotesData.affordability.isReviewCompleted &&
    props.reviewNotesData.applicant.isReviewCompleted &&
    props.reviewNotesData.solution.isReviewCompleted &&
    props.reviewNotesData.property.isReviewCompleted &&
    props.reviewNotesData.review.isReviewCompleted;

  const reviewerObject: IISReviewer = {
    reviewerID: props.currentScenarioReviewerID,
    currentUserID: props.currentUserID,
    hasRequiredPermission: props.viewSaveButtonAsReviewerPermission,
  };

  const isCurrentUserReviewer = isReviewer(reviewerObject) && isScenarioUnderReview;

  const isSaveDisabled =
    props.currentScenarioStatus !== STATUS_NAMES.scenario &&
    props.currentScenarioStatus !== STATUS_NAMES.reWork &&
    !isCurrentUserReviewer;

  const getSaveScenarioUserAction = () => {
    let userAction = scenarioDashboardSaveAction[0];

    if (isCurrentUserReviewer) {
      userAction = scenarioDashboardSaveAction[3];
      return userAction;
    }

    return props.saveTeamScenarioPermission && props.currentUserID !== props.userIDForScenario
      ? scenarioDashboardSaveAction[1]
      : scenarioDashboardSaveAction[0];
  };

  const getToolTipMessage = (isDisabled: boolean) => {
    return isDisabled ? "The scenario is locked because it has been approved or rejected." : "";
  };

  const getToolTipForReviewComplete = () => {
    return !props.isLRASMetricValid || isApproveDisabled()
      ? "Please check scenario validations."
      : !areReviewCheckboxChecked()
      ? "Credit review incomplete."
      : "";
  };

  const handleCompleteReviewClick = () => {
    const reviewerID = isCurrentUserReviewer ? props.currentScenarioReviewerID : undefined;
    props.scenarioModalShowAction({
      description:
        "You won't be able to make any changes to the scenario data. Are you sure you want to proceed?",
      loadingText: "Saving scenario...",
      okayAction: () => {
        const state = props.totalState;
        state.review.completeReviewConditions = conditions;
        props.saveScenarioThunk({
          state,
          token: props.token,
          scenarioId: props.currentScenarioId,
          calledFrom: "nextAndBackButton",
          userAction: getSaveScenarioUserAction(),
          saveAsOwnScenarioModalShow: props.saveAsOwnScenarioModalShow,
          operation: CREDIT_REVIEW_SAVE_ACTIONS.reviewComplete,
          reviewerIdForScenario: reviewerID,
          scenarioLegacyID: props.scenarioLegacyID,
          currentUser: props.currentUserID,
        });
        setIsReviewModalOpen(false);
      },
    });
    props.totalState.review.completeReviewConditions = [];
  };

  const handleReworkClick = () => {
    const reviewerID = isCurrentUserReviewer ? props.currentScenarioReviewerID : undefined;
    props.scenarioModalShowAction({
      description:
        "Scenario will go back to the Loan writer for re-work. Are you sure you want to proceed?",
      loadingText: "Saving scenario...",
      okayAction: () => {
        props.saveScenarioThunk({
          state: props.totalState,
          token: props.token,
          scenarioId: props.currentScenarioId,
          calledFrom: "nextAndBackButton",
          userAction: getSaveScenarioUserAction(),
          saveAsOwnScenarioModalShow: props.saveAsOwnScenarioModalShow,
          operation: CREDIT_REVIEW_SAVE_ACTIONS.reWork,
          reviewerIdForScenario: reviewerID,
          scenarioLegacyID: props.scenarioLegacyID,
        });
      },
    });
  };

  const handleApprovedPendingClick = () => {
    const reviewerID = isCurrentUserReviewer ? props.currentScenarioReviewerID : undefined;
    props.scenarioModalShowAction({
      description: "Scenario will go in Approved Pending state. Are you sure you want to proceed?",
      loadingText: "Saving scenario...",
      okayAction: () => {
        const state = props.totalState;
        state.review.approvedPendingConditions = conditions;
        props.saveScenarioThunk({
          state,
          token: props.token,
          scenarioId: props.currentScenarioId,
          calledFrom: "nextAndBackButton",
          userAction: getSaveScenarioUserAction(),
          saveAsOwnScenarioModalShow: props.saveAsOwnScenarioModalShow,
          operation: CREDIT_REVIEW_SAVE_ACTIONS.approvedPending,
          reviewerIdForScenario: reviewerID,
          scenarioLegacyID: props.scenarioLegacyID,
        });
        setIsReviewModalOpen(false);
      },
    });
  };

  const ownScenario = !isCreditReviewer || isThisLoggedInUserScenario;

  const showSaveApproveButtons =
    isThisLoggedInUserScenario ||
    (props.teams[props.currentTeamID] && props.teams[props.currentTeamID].isMyTeam) ||
    isCurrentUserReviewer ||
    isCreditReviewer;

  const handleOnPreliminaryButtonClick = async (pdfName: string, pdfType: string) => {
    props.generatePDFThunk({
      state: props.totalState,
      token: props.token,
      scenarioId: props.currentScenarioId,
      userAction: scenarioGeneratePdfSelfAction[2],
      pdfName,
      pdfType,
    });
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleUpdateStatusButtonClick = (option: string) => {
    switch (option) {
      case updateStatusOptions[0]:
        setCalledFrom(APPROVE_LETTERS.completeReview);
        setConditions([]);
        setIsReviewModalOpen(true);
        break;

      case updateStatusOptions[1]:
        setCalledFrom(APPROVE_LETTERS.approvedPending);
        setConditions(deepCopyObject(props.approvedPendingConditions));
        setIsReviewModalOpen(true);
        break;

      case updateStatusOptions[2]:
        handleReworkClick();
        break;
    }
  };

  const [updateStatusAnchorEl, setUpdateStatusAnchorEl] = useState(null);
  const updateStatusOpen = Boolean(updateStatusAnchorEl);

  const updateStatusDisableHandler = (option: string) => {
    switch (option) {
      case updateStatusOptions[0]:
        return !props.isLRASMetricValid || isApproveDisabled() || !areReviewCheckboxChecked();

      case updateStatusOptions[1]:
        return !props.isLRASMetricValid || isApproveDisabled() || props.toggleLoader;

      case updateStatusOptions[2]:
        return props.toggleLoader;

      default:
        return true;
    }
  };

  const toolTipOptions = (option: string) => {
    switch (option) {
      case updateStatusOptions[0]:
        return getToolTipForReviewComplete();
      default:
        return "";
    }
  };

  return (
    <div className="page_next-prev--btn group-row">
      <div className="buttons__group">
        {/* back button */}
        {isReviewProps(props) && (
          <Button
            id="back-button"
            className="form-back-button"
            flat={true}
            swapTheming={true}
            disabled={props.toggleLoader}
            onClick={() => backButton("/scenario/affordability", props.hideReview)}
          >
            Back
          </Button>
        )}
        {(isSolutionOrApplicantProps(props) || isAffordabilityProps(props)) && (
          <Button
            id="back-button"
            className="form-back-button"
            flat={true}
            swapTheming={true}
            disabled={props.toggleLoader}
            onClick={() => backButton(props.backUrl, false)}
          >
            Back
          </Button>
        )}

        {/* objective, solution, applicant page button */}
        {(isObjectiveProps(props) || isSolutionOrApplicantProps(props)) && (
          <Button
            id={`next-button-${props.nextBtnName}`}
            className="form-next-button"
            onClick={() => !hasPropertiesAddress && navigate(props.nextUrl)}
            disabled={hasPropertiesAddress || propertyCategoryIsInvalid}
            flat={true}
            secondary={true}
            swapTheming={true}
          >
            NEXT: {props.nextBtnName}
          </Button>
        )}

        {/* affordability page button */ isCurrentUserReviewer}
        {isAffordabilityProps(props) && (
          <Button
            id={`next-button-${props.nextBtnName}`}
            className="form-next-button"
            disabled={affordabilityNextBtnDisabled()}
            onClick={() => {
              props.helpShowReviewScreenAction(true),
                props.reviewSetValidationAction(
                  checkIfAllValidationIsCorrect("objective", props.totalState),
                  "objective"
                ),
                props.fullFormValidationThunk(
                  props.applicantState,
                  props.applicants,
                  props.applicantsRequiredField,
                  props.properties,
                  props.error,
                  props.totalState
                );
            }}
            flat={true}
            secondary={true}
            swapTheming={true}
          >
            NEXT: {props.nextBtnName}
          </Button>
        )}

        {/* pre-approve button */}
        {showSaveApproveButtons && isReviewProps(props) && !isCurrentUserReviewer && (
          <>
            <Tooltip title={getToolTipMessage(isSaveDisabled)}>
              <span>
                <Button
                  id={`next-button-${props.nextBtnName}`}
                  className="form-next-button"
                  onClick={() =>
                    props.reviewSubmitThunk(
                      props.totalState,
                      props.token,
                      props.currentScenarioId,
                      props.saveTeamScenarioPermission,
                      props.editTeamPermission,
                      props.scenarioLegacyID
                    )
                  }
                  flat={true}
                  secondary={true}
                  swapTheming={true}
                  tooltipLabel={
                    isSaveDisabled ? "The scenario is locked because it has been approved." : ""
                  }
                  tooltipPosition="top"
                  // check if the form is valid, if suburb is checked, if funds to complete check is in place
                  disabled={
                    !props.isLRASMetricValid || isApproveDisabled() || isStatusNotApproveRequired
                  }
                >
                  NEXT: {props.nextBtnName}
                </Button>
                {props.scenarioApproveLoader ? <span className="loader-show approve-loader" /> : ""}
              </span>
            </Tooltip>

            {props.viewEditLegacyID && (
              <span className="review-legacy-id">
                <TextField
                  label="Legacy ID"
                  placeholder="Add application CRM ID"
                  value={props.scenarioLegacyID}
                  defaultValue="Default Value"
                  variant="outlined"
                  error={isScenarioLegacyIDRequired}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    props.reviewSetLegacyID(event.target.value);
                  }}
                />
              </span>
            )}
          </>
        )}

        {/* update status button */}
        {isCurrentUserReviewer && isReviewProps(props) && (
          <span className="send-quote-margin">
            <MaterialButton
              id="update-statuses"
              className="update-status-button"
              aria-controls="basic-menu"
              aria-haspopup="true"
              aria-expanded={updateStatusOpen ? "true" : undefined}
              onClick={(event: any) => setUpdateStatusAnchorEl(event.currentTarget)}
              disabled={props.toggleLoader}
            >
              UPDATE STATUS
              <KeyboardArrowDown />
            </MaterialButton>
            <Menu
              id="basic-menu"
              anchorEl={updateStatusAnchorEl}
              open={updateStatusOpen}
              onClose={() => setUpdateStatusAnchorEl(null)}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              {updateStatusOptions.map((option: string) => (
                <Tooltip title={updateStatusDisableHandler(option) ? toolTipOptions(option) : ""}>
                  <span>
                    <MenuItem
                      onClick={() => {
                        handleUpdateStatusButtonClick(option);
                        setUpdateStatusAnchorEl(null);
                      }}
                      disabled={updateStatusDisableHandler(option)}
                    >
                      {option}
                    </MenuItem>
                  </span>
                </Tooltip>
              ))}
            </Menu>
          </span>
        )}
      </div>

      {showSaveApproveButtons && (
        <div className="buttons__group">
          {isCurrentUserReviewer && isReviewProps(props) && ownScenario && (
            <>
              {props.toggleLoader ? <span className="loader-show preliminary-pdf-loader" /> : ""}
              <MaterialButton
                id="preliminary-pdf"
                className="preliminary-pdf-button"
                aria-controls="basic-menu"
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                disabled={props.toggleLoader}
              >
                PDF
                <KeyboardArrowDown />
              </MaterialButton>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                {prelimPDFOptions.map((option: string) => (
                  <MenuItem
                    onClick={() => {
                      handleOnPreliminaryButtonClick(`${option}`, "Preliminary");
                      handleClose();
                    }}
                  >
                    {option}
                  </MenuItem>
                ))}
              </Menu>
            </>
          )}
          {/* send quote button */}
          {isReviewProps(props) && !isCurrentUserReviewer && ownScenario && (
            <Tooltip title={getToolTipMessage(isSaveDisabled)}>
              <span className="send-quote-margin">
                <Button
                  id="send-quote-button"
                  flat={true}
                  swapTheming={true}
                  disabled={true}
                  onClick={() => props.sendQuoteThunk(props.token, props.applicants)}
                  className="for-send-quote-button"
                >
                  SEND QUOTE
                </Button>
              </span>
            </Tooltip>
          )}
          {isAffordabilityProps(props) && ownScenario && (
            <Button
              id="clear-tables-button"
              flat={true}
              className="default-btn clear-button"
              swapTheming={true}
              disabled={isStatusNotApproveRequired}
              onClick={() => {
                props.applicantAffordabilityClearTableAction(props.applicantState.currentTab, true);
              }}
            >
              CLEAR TABLES
            </Button>
          )}
          {/* Loan Writer Notes Button */}
          {ownScenario && (
            <span className="send-quote-margin">
              <Button
                id="loan-writer-notes-button"
                className="loan-writer-notes-button"
                flat={true}
                secondary={true}
                swapTheming={false}
                disabled={props.toggleLoader || !props.currentScenarioId}
                onClick={() => setIsBrokerNotesModalOpen(true)}
              >
                Notes
              </Button>
            </span>
          )}
          {/* save button */}
          {ownScenario && (
            <Tooltip
              title={
                isSaveDisabled
                  ? "The scenario is locked because it has been approved or rejected."
                  : ""
              }
            >
              <span className="send-quote-margin">
                <Button
                  id="save-button"
                  flat={true}
                  className={
                    props.isSaveButtonLoaderShow
                      ? "loader-show form-save-button"
                      : "form-save-button"
                  }
                  disabled={props.toggleLoader || isSaveDisabled}
                  swapTheming={true}
                  onClick={(e) =>
                    !props.isSaveButtonLoaderShow &&
                    props.saveScenarioThunk({
                      state: props.totalState,
                      token: props.token,
                      scenarioId: props.currentScenarioId,
                      calledFrom: "nextAndBackButton",
                      userAction: getSaveScearioUserAction({
                        isReviewer: isCurrentUserReviewer,
                        currentUserID: props.currentUserID,
                        userIDForScenario: props.userIDForScenario,
                        saveTeamScenarioPermission: props.saveTeamScenarioPermission,
                        saveAsOwnScenarioModalShow: props.saveAsOwnScenarioModalShow,
                      }),
                      operation: "Save",
                      saveAsOwnScenarioModalShow: props.saveAsOwnScenarioModalShow,
                      scenarioLegacyID: props.scenarioLegacyID,
                    })
                  }
                >
                  Save
                </Button>
              </span>
            </Tooltip>
          )}
          {/* Upload Button */}
          <Tooltip title={!props.currentScenarioId ? uploadToolTipText.message : ""}>
            <div
              className={`${
                uploadButtonDisabled() || props.toggleLoader
                  ? "wrapper-upload-button-disabled"
                  : "wrapper-upload-button"
              }`}
            >
              <Button
                id="upload-button"
                className={`${
                  uploadButtonDisabled() || props.toggleLoader ? "scenario-upload-button" : ""
                }`}
                flat={true}
                secondary={false}
                swapTheming={true}
                disabled={uploadButtonDisabled() || props.toggleLoader}
                onClick={() =>
                  props.uploadModalVisibleAction(true, props.currentScenarioId.toString())
                }
              >
                Upload
              </Button>
            </div>
          </Tooltip>

          {/* Upload modal */}
          {props.isUploadModalVisible && (
            <UploadDocumentModal
              scenarioID={props.currentScenarioId}
              currentScenarioOwnerID={props.currentScenarioOwnerID}
              reviewerID={props.currentScenarioReviewerID}
            />
          )}

          {/* Review Modal */}
          <ReviewModal
            conditions={conditions}
            setConditions={setConditions}
            calledFrom={calledFrom}
            setCalledFrom={setCalledFrom}
            isVisible={isReviewModalOpen}
            setIsVisible={setIsReviewModalOpen}
            handleCompleteReviewClick={handleCompleteReviewClick}
            handleApprovedPendingClick={handleApprovedPendingClick}
          />
          {/* Loan Writer Notes Modal */}
          <LoanWriterNotesModal
            isBrokerNotesModalOpen={isBrokerNotesModalOpen}
            setIsBrokerNotesModalOpen={setIsBrokerNotesModalOpen}
          />
        </div>
      )}
    </div>
  );
};

export const mapStateToProps = (state: TAppStateType) => {
  const currentScenarioId = state.scenario.currentScenarioId ? state.scenario.currentScenarioId : 0;
  const isCreditReviwerScenario = state.admin.scenariosForCreditReview.find(
    (scenario) => scenario.id === currentScenarioId
  );
  return {
    affordabilityValidation: state.review.validations.affordability,
    applicantState: state.applicant,
    applicants: state.applicant.applicants,
    applicantsRequiredField: state.applicant.applicantsRequiredField,
    error: state.error,
    formInvalidArray: state.review.formInvalidArray,
    footerValidations: state.review.validations,
    formInvalidationArrayForSendQuote: state.review.formInvalidationArrayForSendQuote,
    fundsToCompleteSum: state.review.validations.fundsToCompleteSum,
    isSaveButtonLoaderShow: state.scenario.isSaveButtonLoaderShow,
    loggedInUserScenarios: state.scenario.scenarios,
    isLRASMetricValid: state.help.isLRASMetricValid,
    properties: state.objective.properties,
    allScenarios: state.scenario.scenarios,
    suburbCheckedIndicator: state.objective.suburbCheckedIndicator,
    toggleLoader: state.review.toggleLoader,
    scenarioApproveLoader: state.review.scenarioApproveLoader,
    token: state.login.token,
    totalFee: state.review.fundsToCompleteTotal.totalFee,
    totalState: state,
    teams: state.login.teams,
    currentTeamID: state.login.currentTeamID,
    saveTeamScenarioPermission: state.login.userPermissions.saveTeamScenarioPermission,
    editTeamPermission: state.login.userPermissions.editTeamPermission,
    viewSaveButtonAsReviewerPermission: state.login.userPermissions.viewSaveButtonAsReviewer,
    currentUserID: state.login.currentUser.id,
    userIDForScenario: state.login.userIdForScenario
      ? state.login.userIdForScenario
      : state.login.currentUser.id,
    selectedUserScenarios: state.login.selectedUserScenarios
      ? state.login.selectedUserScenarios
      : [],
    isCreditReviwerScenario,
    currentScenarioId,
    saveAsOwnScenarioModalShow: state.scenario.modal.saveAsOwnScenarioModalShow
      ? state.scenario.modal.saveAsOwnScenarioModalShow
      : false,
    currentUserRoleID: state.login.currentUser.roleId ? state.login.currentUser.roleId : -1,
    currentScenarioReviewerID: state.scenario.reviewerID,
    currentScenarioOwnerID: state.scenario.ownerID,
    currentScenarioStatus: state.scenario.currentScenarioStatus,
    isUploadModalVisible: state.upload.isUploadModalVisible,
    tagsModalVisibility: state.upload.tagsModalVisibility,
    viewEditLegacyID: state.login.userPermissions.viewEditLegacyID,
    scenarioLegacyID: state.review.scenarioLegacyID ? state.review.scenarioLegacyID : "",
    reviewNotesData: state.admin.reviewNotesData,
    approvedPendingConditions: state.review.approvedPendingConditions
      ? state.review.approvedPendingConditions
      : [],
    uploadDocumentsForAllScenariosAsReviewer:
      state.login.userPermissions.uploadDocumentsForAllScenariosAsReviewer,
  };
};
export const mapDispatchToProps = {
  fullFormValidationThunk,
  reviewSubmitThunk,
  saveScenarioThunk,
  sendQuoteThunk,
  helpShowReviewScreenAction,
  applicantClearAffordabilityPageData,
  applicantClearDocumentIdAction,
  applicantAffordabilityClearTableAction,
  reviewSetValidationAction,
  scenarioModalShowAction,
  uploadModalVisibleAction,
  reviewSetLegacyID,
  generatePDFThunk,
};

export const ApplicantNextAndBackButtonSection = connect(
  mapStateToProps,
  mapDispatchToProps
  //@ts-ignore
)(NextAndBackButtonSection as React.FC<ApplicantProps>);

export const AffordabilityNextAndBackButtonSection = connect(
  mapStateToProps,
  mapDispatchToProps
  //@ts-ignore
)(NextAndBackButtonSection as React.FC<AffordabilityProps>);

export const ObjectiveNextAndBackButtonSection = connect(
  mapStateToProps,
  mapDispatchToProps
  //@ts-ignore
)(NextAndBackButtonSection as React.FC<ObjectiveProps>);

export const SolutionNextAndBackButtonSection = connect(
  mapStateToProps,
  mapDispatchToProps
  //@ts-ignore
)(NextAndBackButtonSection as React.FC<ApplicantProps>);

export const ReviewNextAndBackButtonSection = connect(
  mapStateToProps,
  mapDispatchToProps
  //@ts-ignore
)(NextAndBackButtonSection as React.FC<ReviewProps>);
