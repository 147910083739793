import { Auth } from "aws-amplify";
import React from "react";
import { connect } from "react-redux";
import { Route, Navigate } from "react-router-dom";
import "../config/amplify";
import Sidebar from "../components/sidebar/sidebar";

const AddEditBroker = React.lazy(() => import("../pages/admin/addEditBroker"));
const Broker = React.lazy(() => import("../pages/admin/broker"));
const LRASConfiguration = React.lazy(() => import("../pages/admin/lrasConfiguration"));
const Branding = React.lazy(() => import("../pages/branding"));
const LoggedInUserScenarios = React.lazy(() => import("../pages/loggedInUserScenarios"));
const NotFound = React.lazy(() => import("../pages/notFound"));
const SubmitScenario = React.lazy(() => import("../pages/submitScenario/submitScenario"));
const UserScenarios = React.lazy(() => import("../pages/userScenarios"));
import {
  loginUserLoginAction,
  loginSetBreadCrumbsValueAction,
} from "../store/actions/login/loginAction";
import {
  LoginUserLoginActionType,
  LoginSetBreadCrumbsValueActionType,
} from "../store/actions/login/loginActionType";
import { profileThunk, ProfilethunkType } from "../store/thunk/profileThunk";

const ScenarioContainer = React.lazy(() => import("../components/layout/scenarioContainer"));
import history from "./history";
import SideMenu from "../components/common/sideMenu";
import { AMMProvider } from "../components/apolloProvider";
import { CognitoUserSession } from "amazon-cognito-identity-js";
import { TAppStateType } from "../store/reducers/reducers";
const Users = React.lazy(() => import("../pages/users"));
const Teams = React.lazy(() => import("../components/teams/teams"));
const Leads = React.lazy(() => import("../components/leads/leads"));
const TeamMembers = React.lazy(() => import("../components/teams/teamMembers"));
const ViewEditTeam = React.lazy(() => import("../pages/admin/viewEditTeamMembers"));
const LandingPage = React.lazy(() => import("../pages/landingPage"));
import { ITeam, IBrokerListType } from "amm-admin-interface";
import { setBreadCrumbsValue } from "../utility/breadCrumbsUtility";
const CreditReview = React.lazy(() => import("../components/creditReview/creditReview"));
const ViewReport = React.lazy(() => import("../components/report/report"));
import { Routes } from "react-router-dom";
const ForgetPassword = React.lazy(() => import("../pages/forgetPassword"));
const Help = React.lazy(() => import("../pages/help"));
const Login = React.lazy(() => import("../pages/login"));
const TwoFactorAuthentication = React.lazy(() => import("../pages/twoFactorAuthentication"));
import ViewDocument from "../pages/viewDocument";

import FallbackSpinner from "../components/common/fallbackSpinner";

interface StateProps {
  isLogin: boolean;
  myScenarioDashboardTrigger: number;
  openScenarioTrigger: number;
  openUserScenarioDashboardTrigger: number;
  profileTrigger: number;
  userIdForScenarioDashboard: number;
  token: string;
  showReviewScreen: boolean;
  editTeamPermission: boolean;
  teams: ITeam;
  pathname: string;
  brokerList: IBrokerListType[];
}

interface DispatchProps {
  profileThunk: ProfilethunkType;
  loginUserLoginAction: LoginUserLoginActionType;
  loginSetBreadCrumbsValueAction: LoginSetBreadCrumbsValueActionType;
}

type AllRoutesProps = StateProps & DispatchProps;

class AllRoutes extends React.Component<AllRoutesProps> {
  constructor(props: AllRoutesProps) {
    super(props);
  }

  public componentWillReceiveProps(nextProps: AllRoutesProps) {
    if (this.props.pathname !== nextProps.pathname && this.props.isLogin) {
      const breadCrumbsObject = setBreadCrumbsValue(this.props.teams, this.props.brokerList);
      this.props.loginSetBreadCrumbsValueAction(breadCrumbsObject);
    }
    // track profile refetch trigger here as this will allow retrigger anywhere
    if (this.props.profileTrigger !== nextProps.profileTrigger && this.props.isLogin) {
      this.props.profileThunk(this.props.token, this.props.editTeamPermission);
    } else if (nextProps.token && this.props.token !== nextProps.token && this.props.isLogin) {
      this.props.profileThunk(nextProps.token, this.props.editTeamPermission);
    }
  }

  public componentDidMount() {
    Auth.currentSession().then((currentSession: CognitoUserSession) => {
      if (
        currentSession.getIdToken().payload.email_verified ||
        currentSession.getIdToken().payload.phone_number_verified
      ) {
        this.props.loginUserLoginAction(currentSession.getIdToken().getJwtToken(), true);
      } else {
        this.props.loginUserLoginAction(currentSession.getIdToken().getJwtToken(), false);
      }
    });
    this.setState({
      currentPath: history.location.pathname,
    });
  }

  public render() {
    return (
      // ToDO: Check why these are not covered in test coverage

      <React.Fragment>
        <AMMProvider token={this.props.token}>
          <Routes>
            {this.props.isLogin ? (
              // Private Routes
              <React.Fragment>
                <Route
                  path="submit/:user_id/:scenario_id"
                  element={
                    <React.Suspense fallback={<FallbackSpinner />}>
                      <SubmitScenario />
                    </React.Suspense>
                  }
                />
                <Route
                  path="dashboard/user-list"
                  element={
                    <React.Suspense fallback={<FallbackSpinner />}>
                      <Users />
                    </React.Suspense>
                  }
                />
                <Route
                  path="teams"
                  element={
                    <React.Suspense fallback={<FallbackSpinner />}>
                      <Teams />
                    </React.Suspense>
                  }
                />
                <Route
                  path="leads"
                  element={
                    <React.Suspense fallback={<FallbackSpinner />}>
                      <Leads />
                    </React.Suspense>
                  }
                />

                <Route
                  path="teams/add-edit/:team_id/:team_name"
                  element={
                    <React.Suspense fallback={<FallbackSpinner />}>
                      <ViewEditTeam />
                    </React.Suspense>
                  }
                />
                <Route
                  path="teams/add-edit/:team_id"
                  element={
                    <React.Suspense fallback={<FallbackSpinner />}>
                      <ViewEditTeam />
                    </React.Suspense>
                  }
                />
                <Route
                  path="dashboard/my-scenarios"
                  element={
                    <React.Suspense fallback={<FallbackSpinner />}>
                      <LoggedInUserScenarios />
                    </React.Suspense>
                  }
                />
                <Route
                  path="dashboard/user-scenarios/:user_id"
                  element={
                    <React.Suspense fallback={<FallbackSpinner />}>
                      <UserScenarios />
                    </React.Suspense>
                  }
                />
                <Route
                  path="teams/team-members/:team_id"
                  element={
                    <React.Suspense fallback={<FallbackSpinner />}>
                      <TeamMembers />
                    </React.Suspense>
                  }
                />
                <Route
                  path="teams/team-members/:team_id/:user_id"
                  element={
                    <React.Suspense fallback={<FallbackSpinner />}>
                      <UserScenarios />
                    </React.Suspense>
                  }
                />
                <Route
                  path="settings/branding"
                  element={
                    <React.Suspense fallback={<FallbackSpinner />}>
                      <Branding />
                    </React.Suspense>
                  }
                />
                <Route
                  path="administration/lras-configuration"
                  element={
                    <React.Suspense fallback={<FallbackSpinner />}>
                      <LRASConfiguration />
                    </React.Suspense>
                  }
                />
                <Route
                  path="administration/users"
                  element={
                    <React.Suspense fallback={<FallbackSpinner />}>
                      <Broker />
                    </React.Suspense>
                  }
                />
                <Route
                  path="administration/add-edit-user"
                  element={
                    <React.Suspense fallback={<FallbackSpinner />}>
                      <AddEditBroker />
                    </React.Suspense>
                  }
                />
                {/* Neasted Routes */}
                <Route
                  path="scenario/*"
                  element={
                    <React.Suspense fallback={<FallbackSpinner />}>
                      <ScenarioContainer />
                    </React.Suspense>
                  }
                />

                <Route
                  path="/"
                  element={
                    <React.Suspense fallback={<FallbackSpinner />}>
                      <LandingPage />
                    </React.Suspense>
                  }
                />
                <Route
                  path="credit_review"
                  element={
                    <React.Suspense fallback={<FallbackSpinner />}>
                      <CreditReview />
                    </React.Suspense>
                  }
                />
                <Route
                  path="report"
                  element={
                    <React.Suspense fallback={<FallbackSpinner />}>
                      <ViewReport />
                    </React.Suspense>
                  }
                />
                {/* Redirection */}
                <Route path="login" element={<Navigate to="/" />} />
                <Route path="forget-password" element={<Navigate to="/" />} />
                <Route path="help" element={<Navigate to="/" />} />
                <Route path="2fa" element={<Navigate to="/" />} />

                <Route path="*" element={<NotFound />} />
              </React.Fragment>
            ) : (
              // Public Routes
              <>
                <Route
                  path="login"
                  element={
                    <React.Suspense fallback={<FallbackSpinner isPublic={true} />}>
                      <Login />
                    </React.Suspense>
                  }
                />
                <Route
                  path="forget-password"
                  element={
                    <React.Suspense fallback={<FallbackSpinner isPublic={true} />}>
                      <ForgetPassword />
                    </React.Suspense>
                  }
                />
                <Route
                  path="help"
                  element={
                    <React.Suspense fallback={<FallbackSpinner isPublic={true} />}>
                      <Help />
                    </React.Suspense>
                  }
                />
                <Route
                  path="2fa"
                  element={
                    <React.Suspense fallback={<FallbackSpinner isPublic={true} />}>
                      <TwoFactorAuthentication />
                    </React.Suspense>
                  }
                />
                <Route path="view-document" element={<ViewDocument />} />
                <Route path="*" element={<Navigate to="/login" />} />
              </>
            )}
          </Routes>
          {this.props.isLogin && <Sidebar />}

          {this.props.isLogin && <SideMenu />}
        </AMMProvider>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: TAppStateType) => {
  return {
    isLogin: state.login.isLogin,
    myScenarioDashboardTrigger: state.trigger.myScenarioDashboard,
    openScenarioTrigger: state.trigger.openScenario,
    openUserScenarioDashboardTrigger: state.trigger.userScenarioDashboard,
    profileTrigger: state.trigger.profile,
    state,
    token: state.login.token as string,
    userIdForScenarioDashboard: state.trigger.userIdForScenarioDashboard,
    showReviewScreen: state.help.showReviewScreen,
    editTeamPermission: state.login.userPermissions.editTeamPermission,
    teams: state.login.teams,
    pathname: history.location.pathname,
    brokerList: state.admin.brokerList,
  };
};

const mapDispatchToProps = {
  profileThunk,
  loginUserLoginAction,
  loginSetBreadCrumbsValueAction,
};

// ToDo: Check and fix object element placement wrt linting rules
export default connect(mapStateToProps, mapDispatchToProps)(AllRoutes);
