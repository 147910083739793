import { IAccount, ISolutionInitialState } from "amm-solution-interface";
import { accountTypeOptions, accountPurposeOptions, cashOutUseDropdown, cashOutMaxValue, interestTypeOptions } from "../../../constants";

export const accountState = {
  accountType: accountTypeOptions[0].value,
  amount: 0,
  accountId: Math.random().toString(36).substr(2, 9),
  interestOnlyTerm: Number(process.env.REACT_APP_IOT_MAX_LIMIT),
  accountPurpose: accountPurposeOptions[0].value,
  fixedTerm: 0,
  interestType: interestTypeOptions[0].value,
  isOffsetRequired: false
} as IAccount;

export const solutionInitialState: ISolutionInitialState = {
  account: [{ ...accountState }],
  accountTotalAmount: Number(process.env.REACT_APP_LOAN_AMOUNT_MIN),
  cashOutAmount: 0,
  cashOutAmountMax: cashOutMaxValue,
  cashOutNote: "",
  cashOutUse: cashOutUseDropdown[0],
  currentTab: 0,
  depositAmount: 0,
  doYouRequireCashOut: "No",
  loanAmount: Number(process.env.REACT_APP_LOAN_AMOUNT_MIN),
  loanMax: Number(process.env.REACT_APP_LOAN_AMOUNT_MAX),
  loanMin: Number(process.env.REACT_APP_LOAN_AMOUNT_MIN),
  loanTermMaxYear: 30,
  loanTermMinYear: 10,
  loanTermYear: 30,
  propertiesTotal: 0,
  refinancePropertyTotal: 0,
};
