import React from "react";

const PersonIcon = () => {
  return (
    <svg id="Layer_1" className="person-icon" viewBox="0 0 25 25" width="26" height="24">
      <title>icon-account</title>
      <path
        d="M12.5,12.5A6.25,6.25,0,1,0,6.25,6.25,6.2483,6.2483,0,0,0,12.5,12.5Zm0,3.125c-4.1719,0-12.5,2.0938-12.5,6.25V25H25V21.875C25,17.7188,16.6719,15.625,12.5,15.625Z"
        transform="translate(0)"
      />
    </svg>
  );
};

export default PersonIcon;
