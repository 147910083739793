import React from "react";

const DollarIcon = (props: { className: string }) => {
  return (
    <svg className={props.className} width="14" height="14" viewBox="0 0 8.0508 13.9194">
      <title>icon-dollar</title>
      <path
        d="M6.0923,12.5381a5.1588,5.1588,0,0,1-3.6568-1.6846l.8018-1.0264a4.3846,4.3846,0,0,0,2.855,1.5079V7.4375c-1.6358-.4478-3.32-.9932-3.32-2.9658,0-1.6519,1.395-2.8228,3.32-2.9668V.0454H7.07V1.521a4.8557,4.8557,0,0,1,3.1758,1.4751l-.8027.9785A3.8962,3.8962,0,0,0,7.07,2.74V6.2358c1.6524.4649,3.416,1.0738,3.416,3.1744,0,1.4433-.9306,2.9511-3.416,3.1279v1.4267h-.978Zm0-6.5591V2.7075c-1.1387.08-1.94.7539-1.94,1.6841C4.1519,5.2734,5.0015,5.6582,6.0923,5.979ZM7.07,7.6943v3.6563c1.5235-.16,2.0371-1.0742,2.0371-1.8438C9.1074,8.4639,8.1934,8.0312,7.07,7.6943Z"
        transform="translate(-2.4355 -0.0454)"
      />
    </svg>
  );
};

export default DollarIcon;
