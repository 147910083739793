import { IEditBrokerState, IReviewNote, IReviewNoteData } from "amm-admin-interface";
import { applicantTitle, brokerStatus } from "../../../constants";

export const editBrokerState: IEditBrokerState = {
  provisoSMSLink: "",
  organisationFullName: "",
  organisationLegalName: "",
  originatorPartyID: "",
  brokerPartyID: "",
  cognitoId: "",
  createdAt: "",
  email: "",
  firstName: "",
  id: -1,
  lastLogin: "",
  lastName: "",
  middleName: "",
  mobile: "",
  noOfScenarios: 0,
  partialEmail: "",
  partialFirstName: "",
  partialLastName: "",
  partialMiddleName: "",
  partialMobile: "",
  partialPhone: "",
  partialStatus: "Active",
  phone: "",
  provisoReferralCode: "",
  roleId: -1,
  status: brokerStatus[0],
  title: applicantTitle[0],
  uniqueUserID: "",
  originatorID: 1,
  userNotifications: [],
};

export const defaultReviewNoteValue: IReviewNote = {
  reviewNoteText: "",
  isReviewCompleted: false,
  reviewerID: 0,
};

export const reviewNoteDefaultState: IReviewNoteData = {
  property: defaultReviewNoteValue,
  solution: defaultReviewNoteValue,
  applicant: defaultReviewNoteValue,
  affordability: defaultReviewNoteValue,
  review: defaultReviewNoteValue,
  final: defaultReviewNoteValue,
};
