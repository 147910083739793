import { acceptedUploadTypes, IAttachedFile } from "../../constants";
import {
  AttachedFilesActionType,
  isAllFilesAttachedActionType,
  SetToastsActionType,
} from "../../store/actions/uploadDocumentsActions/uploadDocumentsActionType";
import { UploadFileScenarioThunkType } from "../../store/thunk/scenarioThunk/uploadFileScenarioThunk";
import { SetUploadingFilesActionType } from "../../store/actions/uploadDocumentsActions/uploadDocumentsActionType";

const maxNumberOfFiles = 50;

const handleUploadTime = () => {
  const todayDate = new Date();
  const date = todayDate.getDate();
  const month = todayDate.getMonth() + 1;
  const year = todayDate.getFullYear();

  const uploadDate =
    (date < 10 ? "0" + date : date) + "/" + (month < 10 ? "0" + month : month) + "/" + year;
  return uploadDate;
};

const readFileStream: (file: any) => Promise<string> = async (file: any) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (event: any) => {
      resolve(event.target.result);
    };

    reader.onerror = (err) => {
      reject(err);
    };

    reader.readAsDataURL(file);
  });

export const handleSubmit = async (
  files: any,
  numberOfUploads: number,
  uploadFileScenarioThunk: UploadFileScenarioThunkType,
  token: string,
  scenarioId: string,
  currentUserId: number,
  currentScenarioUserId: number,
  currentScenarioReviwerID: number,
  attachedFilesAction: AttachedFilesActionType,
  setToastsAction: SetToastsActionType,
  setUploadingFilesAction: SetUploadingFilesActionType,
  allFilesUploaded: number,
  isAllFilesAttachedAction: isAllFilesAttachedActionType,
  uploadDocumentsForAllScenariosAsReviewer: boolean
) => {
  if (numberOfUploads <= maxNumberOfFiles && numberOfUploads + files.length <= maxNumberOfFiles) {
    let attachedFiles: IAttachedFile[] = [];
    const uploadTime: string = handleUploadTime();
    files.forEach(async (e: File) => {
      if (e.size < 2.5e7 && e.type in acceptedUploadTypes) {
        const attachedFile: IAttachedFile = {
          uploadDate: uploadTime,
          fileName: e.name,
          chunks: 0,
          responseCount: 0,
        };
        attachedFiles.push(attachedFile);
      }
    });
    attachedFilesAction(attachedFiles);
    var index = 0;
    for await (const e of files) {
      if (e.size < 2.5e7 && e.type in acceptedUploadTypes) {
        const data: any = {};
        const fileBase64 = await readFileStream(e);
        data.fileBase64 = fileBase64.substr(fileBase64.indexOf(",") + 1);
        if (e) {
          data.fileName = e.name;
          data.fileType = e.type;
          data.fileSize = e.size;
        }
        isAllFilesAttachedAction(false);
        uploadFileScenarioThunk(
          data,
          token,
          scenarioId,
          currentUserId,
          currentScenarioUserId,
          index,
          files.length,
          uploadDocumentsForAllScenariosAsReviewer,
          currentScenarioReviwerID
        );
        index++;
      } else {
        setToastsAction([{ text: `File upload failed: File - ${e.name} invalid type` }], false);
      }
    }
  } else {
    setToastsAction([{ text: "File upload rejected. You can't upload more than 50 files" }], false);
  }
};

export const isUploadDisabled = (
  isFileUploading: boolean,
  numberOfUploads: number,
  chooseFileButtonEnabled: boolean,
  allFilesAttached: boolean
) => {
  return (
    isFileUploading ||
    numberOfUploads >= maxNumberOfFiles ||
    !chooseFileButtonEnabled ||
    !allFilesAttached
  );
};
