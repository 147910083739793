import { IScenarioPayloadUserScenarios } from "amm-scenario-interface";
import { CurrentScenarioHistory } from "../../reducers/scenarioReducers/scenarioReducer";

export const SCENARIO_LOAD = "SCENARIO_LOAD";
export const SCENARIO_DUPLICATE = "SCENARIO_DUPLICATE";
export const SCENARIO_DELETE = "SCENARIO_DELETE";
export const SCENARIO_SAVE = "SCENARIO_SAVE";
export const SCENARIO_LIST = "SCENARIO_LISTS";
export const SCENARIO_LOADER = "SCENARIO_LOADER";
export const SCENARIO_SAVE_BUTTON_LOADER = "SCENARIO_SAVE_BUTTON_LOADER";
export const SCENARIO_MODAL_SHOW = "SCENARIO_MODAL_SHOW";
export const SCENARIO_MODAL_HIDE = "SCENARIO_MODAL_HIDE";
export const SCENARIO_MODAL_BUTTON_LOADER = "SCENARIO_MODAL_BUTTON_LOADER";
export const SCENARIO_RESET_CURRENT_SCENARIO_ID = "SCENARIO_RESET_CURRENT_SCENARIO_ID";
export const SCENARIO_SAVE_DISCARD_MODAL_SHOW = "SCENARIO_SAVE_DISCARD_MODAL_SHOW";
export const SCENARIO_SAVE_AS_OWN_SCENARIO_MODAL_SHOW = "SCENARIO_SAVE_AS_OWN_SCENARIO_MODAL_SHOW";
export const SCENARIO_SET_CURRENT_SCENARIO_HISTORY = "SCENARIO_SET_CURRENT_SCENARIO_HISTORY";
export const START_DOCUSIGN_DISABLED = "START_DOCUSIGN_DISABLED";
export const SET_QUERY_DATA_LOADER = "SET_QUERY_DATA_LOADER";
export const SCENARIO_CAPITAL_STATUS_UPDATE = "SCENARIO_CAPITAL_STATUS_UPDATE";
export const SET_REVIEWER_ID_RE_WORK = "SET_REVIEWER_ID_RE_WORK";
interface ICommon {
  module: string[];
}

export interface IScenarioLoadParams {
  scenarioId: number;
  scenarioStatus: string;
  reviewerID?: number;
  ownerID?: number;
  newSavedScenario?: IScenarioPayloadUserScenarios;
}
export interface IScenarioCapitalStatusUpdate {
  scenarioId: number;
  capitalStatus: string;
}
export interface ScenarioLoadAction extends ICommon {
  scenarioId: number;
  scenarioStatus: string;
  reviewerID?: number;
  ownerID?: number;
  newSavedScenario?: IScenarioPayloadUserScenarios;
  type: "SCENARIO_LOAD";
}

export interface ScenariosUpdateCapitalStatusAction extends ICommon {
  scenarioId: number;
  capitalStatus: string;
  type: "SCENARIO_CAPITAL_STATUS_UPDATE";
}

export interface ScenarioDeleteAction extends ICommon {
  state: any;
  type: "SCENARIO_DELETE";
}

export interface ScenarioDuplicateAction extends ICommon {
  state: any;
  type: "SCENARIO_DUPLICATE";
}

export interface ScenarioSaveAction extends ICommon {
  state: any;
  type: "SCENARIO_SAVE";
}

export interface ScenarioListAction extends ICommon {
  data: any;
  from?: string;
  type: "SCENARIO_LISTS";
}

export interface ScenarioLoaderAction extends ICommon {
  visibility: boolean;
  type: "SCENARIO_LOADER";
}
export interface ScenarioSaveButtonLoaderAction extends ICommon {
  loader: boolean;
  type: "SCENARIO_SAVE_BUTTON_LOADER";
}

export interface ScenarioModalHideAction extends ICommon {
  type: "SCENARIO_MODAL_HIDE";
}
export interface ScenarioModalButtonLoaderAction extends ICommon {
  loader: boolean;
  type: "SCENARIO_MODAL_BUTTON_LOADER";
}
export interface ScenarioSaveDiscardModalShowAction extends ICommon {
  show: boolean;
  type: "SCENARIO_SAVE_DISCARD_MODAL_SHOW";
}
export interface ScenarioSaveAsOwnScenarioModalShowAction extends ICommon {
  show: boolean;
  description: string;
  type: "SCENARIO_SAVE_AS_OWN_SCENARIO_MODAL_SHOW";
}
export interface StartDocusignDisabledAction extends ICommon {
  setIsStartDocusignDisabled: boolean;
  type: "START_DOCUSIGN_DISABLED";
}
export interface SetQueryDataLoaderAction extends ICommon {
  queryDataLoader: boolean;
  type: "SET_QUERY_DATA_LOADER";
}

interface IModalShowAction {
  cancelAction?: () => void;
  okayAction?: any;
  button1Name?: string;
  button2Name?: string;
  description?: string;
  loadingText?: string;
}
export interface ScenarioModalShowAction extends ICommon {
  button1Name?: string;
  button2Name?: string;
  description?: string;
  okayAction?: () => void;
  cancelAction?: () => void;
  loadingText?: string;
  type: "SCENARIO_MODAL_SHOW";
}

export interface ScenarioResetCurrentScenarioId extends ICommon {
  button1Name?: string;
  button2Name?: string;
  description?: string;
  loadingText?: string;
  okayAction?: () => void;
  cancelAction?: () => void;
  type: "SCENARIO_RESET_CURRENT_SCENARIO_ID";
}

export interface ScenarioSetCurrentScenarioHistoryAction extends ICommon {
  currentScenarioHistory: CurrentScenarioHistory[];
  type: "SCENARIO_SET_CURRENT_SCENARIO_HISTORY";
}
export interface SetReviewerIdReWorkAction extends ICommon {
  reviewerID: number;
  type: "SET_REVIEWER_ID_RE_WORK";
}

export type ScenarioActions =
  | ScenarioLoadAction
  | ScenariosUpdateCapitalStatusAction
  | ScenarioDeleteAction
  | ScenarioDuplicateAction
  | ScenarioSaveAction
  | ScenarioListAction
  | ScenarioLoaderAction
  | ScenarioSaveButtonLoaderAction
  | ScenarioModalHideAction
  | ScenarioModalButtonLoaderAction
  | ScenarioModalShowAction
  | ScenarioResetCurrentScenarioId
  | ScenarioSaveDiscardModalShowAction
  | ScenarioSaveAsOwnScenarioModalShowAction
  | ScenarioSetCurrentScenarioHistoryAction
  | StartDocusignDisabledAction
  | SetQueryDataLoaderAction
  | SetReviewerIdReWorkAction;

export type ScenarioSetCurrentScenarioHistoryActionType = (
  currentScenarioHistory: CurrentScenarioHistory[]
) => ScenarioSetCurrentScenarioHistoryAction;

export type ScenarioLoadActionType = (params: IScenarioLoadParams) => ScenarioLoadAction;
export type ScenariosUpdateCapitalStatusType = (
  params: IScenarioCapitalStatusUpdate
) => ScenariosUpdateCapitalStatusAction;
export type ScenarioDeleteActionType = (state: any) => ScenarioDeleteAction;

export type ScenarioDuplicateActionType = (state: any) => ScenarioDuplicateAction;

export type ScenarioSaveActionType = (state: any) => ScenarioSaveAction;

export type ScenarioListActionType = (data: any, from?: string) => ScenarioListAction;

export type ScenarioLoaderActionType = (visibility: boolean) => ScenarioLoaderAction;

export type ScenarioSaveButtonLoaderActionType = (
  loader: boolean
) => ScenarioSaveButtonLoaderAction;

export type ScenarioModalHideActionType = () => ScenarioModalHideAction;

export type ScenarioModalButtonLoaderActionType = (
  loader: boolean
) => ScenarioModalButtonLoaderAction;

export type ScenarioSaveDiscardModalShowActionType = (
  show: boolean
) => ScenarioSaveDiscardModalShowAction;

export type ScenarioSaveAsOwnScenarioModalShowActionType = (
  show: boolean,
  description: string
) => ScenarioSaveAsOwnScenarioModalShowAction;

export type ScenarioModalShowActionType = (key: IModalShowAction) => ScenarioModalShowAction;

export type ScenarioResetCurrentScenarioIdType = () => ScenarioResetCurrentScenarioId;

export type StartDocusignDisabledActionType = (
  setIsStartDocusignDisabled: boolean
) => StartDocusignDisabledAction;

export type SetQueryDataLoaderActionType = (queryDataLoader: boolean) => SetQueryDataLoaderAction;

export type SetReviewerIdReWorkActionType = (reviewerID: number) => SetReviewerIdReWorkAction;
