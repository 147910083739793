import MuiAccordion from "@material-ui/core/ExpansionPanel";
import MuiAccordionSummary from "@material-ui/core/ExpansionPanelSummary";
import MuiAccordionDetails from "@material-ui/core/ExpansionPanelDetails";
import { withStyles } from "@material-ui/core/styles";

const Accordion = withStyles({
  root: {
    boxShadow: "none",
    backgroundColor: "inherit",
    marginBottom: -18,
    "&:before": {
      display: "none",
    },
    "&:expanded": {
      margin: "auto",
    },
    color: "inherit",
  },
})(MuiAccordion);

const AccordionSummary = withStyles((theme) => ({
  root: {
    padding: 0,
    flexDirection: "row-reverse",
    marginLeft: -18,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
}))(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    paddingRight: "16px",
    paddingTop: 0,
    paddingLeft: 0,
    paddingBottom: 0,
  },
}))(MuiAccordionDetails);

export { Accordion, AccordionSummary, AccordionDetails };
