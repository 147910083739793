import {
  IBrokerListType,
  IEditBrokerState,
  IReviewNoteData,
  ITeamAdmin,
  IUserNotification,
} from "amm-admin-interface";
import { moduleConstant } from "../../../constants";
import * as types from "./adminActionTypes";
import { IModalShowAction } from "../scenarioActions/scenarioActions";
import { ILeadUsers, IOriginators, IScenarioPayloadUserScenarios } from "amm-scenario-interface";
import { IScenarioCapitalStatusUpdate } from "../scenarioActions/scenarioActionType";

export interface IAssignUserScenariosModalData extends IModalShowAction {
  isOpen: boolean;
  calledFrom?: string;
  scenarioId?: number;
}

export const adminSetEditBrokerStateAction: types.AdminSetEditBrokerStateActionType = (
  state: IEditBrokerState,
  isNew: boolean
) => {
  return {
    isNew,
    module: [moduleConstant.admin],
    state,
    type: types.ADMIN_SET_EDIT_BROKER_STATE,
  };
};

export const adminBrokerValueUpdateAction: types.AdminBrokerValueUpdateActionType = (
  key: string,
  value: React.ReactText | boolean,
  index?: number,
  child?: string // for sms & email
) => {
  return {
    key,
    module: [moduleConstant.admin],
    type: types.ADMIN_BROKER_VALUE_UPDATE,
    value,
    index,
    child,
  };
};

export const adminEditBrokerButtonClickAction: types.AdminEditBrokerButtonClickActionType = (
  buttonType: string,
  referralCode?: string
) => {
  return {
    buttonType,
    module: [moduleConstant.admin],
    referralCode,
    type: types.ADMIN_EDIT_BROKER_BUTTON_CLICK,
  };
};

export const adminSetBrokerSaveLoaderAction: types.AdminSetBrokerSaveLoaderActionType = (
  isLoading: boolean
) => {
  return {
    isLoading,
    module: [moduleConstant.admin],
    type: types.ADMIN_BROKER_SAVE_LOADER,
  };
};

export const adminSetBrokerListAction: types.AdminSetBrokerListActionType = (
  brokerList: IBrokerListType[],
  calledFrom: string
) => {
  return {
    brokerList,
    calledFrom,
    module: [moduleConstant.admin],
    type: types.ADMIN_SET_BROKER_LIST,
  };
};

export const adminOpenSelectModalAction: types.AdminOpenSelectModalActionType = (
  isOpen: boolean,
  scenarioID: number,
  userID?: number,
  operation?: string
) => {
  return {
    isOpen,
    scenarioID,
    userID,
    operation,
    module: [moduleConstant.admin],
    type: types.ADMIN_OPEN_SELECT_MODAL_ACTION,
  };
};

export const adminOpenAssignUserScenariosAction: types.AdminOpenAssignUserScenariosActionType = (
  modalData: IAssignUserScenariosModalData
) => {
  return {
    modalData,
    module: [moduleConstant.admin],
    type: types.ADMIN_OPEN_ASSIGN_USER_SCENARIO_MODAL,
  };
};

export const adminUpdateTeamMemberAction: types.AdminUpdateTeamMemberActionType = (
  broker: IBrokerListType,
  addOrRemove: string
) => {
  return {
    broker,
    addOrRemove,
    module: [moduleConstant.admin],
    type: types.ADMIN_UPDATE_TEAM_MEMBER,
  };
};

export const adminUpdateTeamDetailAction: types.AdminUpdateTeamDetailActionType = (
  teamName: string
) => {
  return {
    teamName,
    module: [moduleConstant.admin],
    type: types.ADMIN_UPDATE_TEAM_DETAIL,
  };
};

export const adminSetTeamAction: types.AdminSetTeamActionType = (state: ITeamAdmin) => {
  return {
    state,
    module: [moduleConstant.admin],
    type: types.ADMIN_SET_TEAM,
  };
};

export const adminSetTeamSaveDisableAction: types.AdminSetTeamSaveDisableActionType = (
  isDisable: boolean
) => {
  return {
    isDisable,
    module: [moduleConstant.admin],
    type: types.ADMIN_SET_TEAM_SAVE_DISABLE,
  };
};

export const adminSetTeamLoaderAction: types.AdminSetTeamLoaderActionType = (
  showLoader: boolean
) => {
  return {
    showLoader,
    module: [moduleConstant.admin],
    type: types.ADMIN_SET_TEAM_LOADER,
  };
};

export const adminSetSaveTeamButtonLoaderAction: types.AdminSetSaveTeamButtonLoaderActionType = (
  saveTeamButtonLoader: boolean
) => {
  return {
    saveTeamButtonLoader,
    module: [moduleConstant.admin],
    type: types.ADMIN_SET_SAVE_TEAM_LOADER,
  };
};

export const adminOpenAddMemberModalAction: types.AdminOpenAddMemberModalActionType = (
  isOpen: boolean
) => {
  return {
    isOpen,
    module: [moduleConstant.admin],
    type: types.ADMIN_OPEN_MEMBER_MODAL,
  };
};

export const adminSetAllScenariosLoaderAction: types.AdminSetAllLeadsLoaderActionType = (
  allUserScenariosLoader: boolean
) => {
  return {
    allUserScenariosLoader,
    module: [moduleConstant.admin],
    type: types.ADMIN_SET_CREDIT_REVIEW_SCENARIOS_LOADER,
  };
};
export const adminSetCreditReviewScenariosAction: types.AdminSetCreditReviewScenariosActionType = (
  scenariosForCreditReview: IScenarioPayloadUserScenarios[]
) => {
  return {
    scenariosForCreditReview,
    module: [moduleConstant.admin],
    type: types.ADMIN_SET_CREDIT_REVIEW_SCENARIOS,
  };
};
export const adminReviewAddNotes: types.AdminReviewNotesActionType = (
  reviewNotesData: IReviewNoteData
) => {
  return {
    module: [moduleConstant.admin],
    reviewNotesData,
    type: types.ADMIN_REVIEW_NOTES,
  };
};

export const adminBrokerAddNotes: types.AdminBrokerAddNotesActionType = (
  brokerNotesData: string
) => {
  return {
    module: [moduleConstant.admin],
    brokerNotesData,
    type: types.ADMIN_BROKER_NOTES,
  };
};

export const adminAddOriginators: types.AdminAddOriginatorsActionType = (
  originators: IOriginators[]
) => {
  return {
    module: [moduleConstant.admin],
    originators,
    type: types.ADMIN_ADD_ORIGINATORS,
  };
};

export const adminAddNotifications: types.AdminAddNotificationsActionType = (
  notifications: IUserNotification[]
) => {
  return {
    module: [moduleConstant.admin],
    notifications,
    type: types.ADMIN_ADD_NOTIFICATIONS,
  };
};

export const adminUpdateCapitalStatus: types.AdminUpdateCapitalStatusType = ({
  scenarioId,
  capitalStatus,
}: IScenarioCapitalStatusUpdate) => {
  return {
    module: [moduleConstant.scenario],
    scenarioId,
    capitalStatus,
    type: types.ADMIN_CAPITAL_STATUS_UPDATE,
  };
};

export const adminSetLeadsAction: types.AdminSetLeadsActionType = (
  leads: IScenarioPayloadUserScenarios[],
  users: ILeadUsers[]
) => {
  return {
    leads,
    users,
    module: [moduleConstant.admin],
    type: types.ADMIN_SET_LEADS,
  };
};

export const adminSetLeadsLoaderAction: types.AdminSetLeadsLoaderActionType = (
  leadsLoader: boolean
) => {
  return {
    leadsLoader,
    module: [moduleConstant.admin],
    type: types.ADMIN_SET_LEADS_LOADER,
  };
};

export const adminSetAssignLeadModalVisibilityAction: types.AdminSetAssignLeadModalVisibilityActionType = (
  isAssignModalVisible: boolean
) => {
  return {
    isAssignModalVisible,
    module: [moduleConstant.admin],
    type: types.ADMIN_SET_ASSIGN_LEAD_MODAL_VISIBILITY,
  };
};
