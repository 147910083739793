import React from "react";

const HouseIcon = (props: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="11"
      viewBox="0 0 14 11"
      className={props.className ? props.className : "house-icon"}
    >
      <rect x="8" y="6" width="4" height="5" />
      <rect
        id="Rectangle_709_copy"
        data-name="Rectangle 709 copy"
        x="2"
        y="6"
        width="4"
        height="5"
      />
      <path
        id="Rectangle_709_copy_2"
        data-name="Rectangle 709 copy 2"
        d="M548,1257h2l6,6H542Z"
        transform="translate(-542 -1257)"
      />
    </svg>
  );
};

export default HouseIcon;
