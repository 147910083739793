import React from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { connect } from "react-redux";
import { TAppStateType } from "../../store/reducers/reducers";
import { ISupportingDocs } from "amm-scenario-interface";
import { Accordion, AccordionDetails, AccordionSummary } from "../common/Accordian";

interface OwnProps {
  supportingDocs: ISupportingDocs[];
}

interface StateProps {
  brandName: string;
}

type SupportingDocsProps = OwnProps & StateProps;

const SupportingDocs: React.FC<SupportingDocsProps> = (props: SupportingDocsProps) => {
  return (
    <div className="support-doc-container">
      <p className="title">REQUIRED</p>

      {props.supportingDocs.map((data: ISupportingDocs) => {
        return (
          !data.isGuarantor && (
            <React.Fragment>
              <p className="heading">
                APPLICANT {data.applicantIndex}: {data.applicantName}
              </p>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1d-content"
                  id="panel1d-header"
                >
                  <span>Identification</span>
                </AccordionSummary>
                <AccordionDetails>
                  <span>
                    Certified copies of your Photo Identification. Every applicant and guarantor (if
                    applicable) must provide true copies of any two of:
                    <ul>
                      <li>A current Australian Passport: </li>
                      <li>A current Australian Driver’s Licence; and/or</li>
                      <li>A current Australian ("Green") Medicare Card.</li>
                    </ul>
                  </span>
                </AccordionDetails>
              </Accordion>
              {data.isContractForSaleRequired && (
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2d-content"
                    id="panel2d-header"
                  >
                    <span>Contract of sale</span>
                  </AccordionSummary>
                  <AccordionDetails>
                    <span>
                      Confirmation of the purchase of your security property evidenced by a copy of
                      the signed Contract of Sale confirming the purchase price and amount of
                      deposit paid.
                    </span>
                  </AccordionDetails>
                </Accordion>
              )}
              {data.isPAYGEmployee && (
                <React.Fragment>
                  {data.isEmploymentConfirmationRequired && (
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3d-content"
                        id="panel3d-header"
                      >
                        <span>Employment Confirmation</span>
                      </AccordionSummary>
                      <AccordionDetails>
                        <span>
                          Confirmation of employment evidenced by a copy of 2 of your 3 most recent
                          payslips, confirming your employer’s name and that you are still employed.
                        </span>
                      </AccordionDetails>
                    </Accordion>
                  )}
                  {data.isEmploymentAndIncomeConfirmationRequired && (
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel4d-content"
                        id="panel4d-header"
                      >
                        <span>Employment and Income verification</span>
                      </AccordionSummary>
                      <AccordionDetails>
                        <span>
                          Confirmation of employment and income evidenced by:
                          <ul>
                            <li>
                              Two most recent payslips (the most recent being less than 30 days old)
                              showing your name, employer details, pay period, gross income and YTD
                              income
                            </li>
                          </ul>
                        </span>
                      </AccordionDetails>
                    </Accordion>
                  )}
                </React.Fragment>
              )}
              {data.isPAYGBusinessOwnerConfirmation && (
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel5d-content"
                    id="panel5d-header"
                  >
                    <span>Employment and Income Confirmation</span>
                  </AccordionSummary>
                  <AccordionDetails>
                    <span>
                      Confirmation of employment and income evidenced by:
                      <ul>
                        <li>
                          The most recent Payslip (the most recent being less than 30 days old)
                          showing your name, employer details, pay period, gross income and YTD
                          income; and
                        </li>
                        <li>
                          A letter in the approved format from your accountant on company
                          letterhead, which confirms:
                          <ul>
                            <li>The date your business began trading; and</li>

                            <li>
                              Your company has made sufficient profits to meet the business
                              commitments; and
                            </li>

                            <li>
                              Your company can continue to pay the salary you have declared on a
                              consistent frequency.{" "}
                            </li>
                          </ul>
                          <p>
                            The approved letter template can be downloaded{" "}
                            {props.brandName === "amm" && (
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://resources.australianmortgage.com/australianmortgage/documents/business-owners-accountants-letter/"
                              >
                                here
                              </a>
                            )}
                            {props.brandName === "shift" && (
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://resources.australianmortgage.com/australianmortgage/documents/business-owners-accountants-letter/"
                              >
                                here
                              </a>
                            )}
                            {props.brandName === "afg" && (
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://resources.australianmortgage.com/afg/documents/business-owners-accountants-letter/"
                              >
                                here
                              </a>
                            )}
                            {props.brandName === "shore" && (
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://resources.australianmortgage.com/shoreprivate/documents/business-owners-accountants-letter/"
                              >
                                here
                              </a>
                            )}
                            {props.brandName === "volt" && (
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://resources.australianmortgage.com/shoreprivate/documents/business-owners-accountants-letter/"
                              >
                                here
                              </a>
                            )}
                            {props.brandName === "mortgage-choice" && (
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://resources.australianmortgage.com/mortgagechoice/documents/business-owners-accountants-letter/"
                              >
                                here
                              </a>
                            )}
                            {props.brandName === "rate-money" && (
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://resources.australianmortgage.com/ratemoney/documents/business-owners-accountants-letter/"
                              >
                                here
                              </a>
                            )}
                            {props.brandName === "amo" && (
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://resources.australianmortgage.com/australianmortgage/documents/business-owners-accountants-letter/"
                              >
                                here
                              </a>
                            )}
                            {props.brandName === "crown" && (
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://resources.australianmortgage.com/australianmortgage/documents/business-owners-accountants-letter/"
                              >
                                here
                              </a>
                            )}
                            {props.brandName === "mod" && (
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://resources.australianmortgage.com/australianmortgage/documents/business-owners-accountants-letter/"
                              >
                                here
                              </a>
                            )}
                          </p>
                        </li>
                      </ul>
                    </span>
                  </AccordionDetails>
                </Accordion>
              )}
              {data.isPAYGContractorConfirmation && (
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel6d-content"
                    id="panel6d-header"
                  >
                    <span>Employment and Income Confirmation</span>
                  </AccordionSummary>
                  <AccordionDetails>
                    <span>
                      Confirmation of employment and income evidenced by :
                      <ul>
                        <li>Most recent Payslip (being not less than 30 days old); and</li>
                        <li>
                          Current and (if required) recently expired or future employment
                          contract(s), evidencing 6 months tenure and 3 months remaining term or 12
                          months of stable employment; or
                        </li>
                        <li>
                          Most recent PAYG Summary evidencing 12 months of stable employment in the
                          previous financial year
                        </li>
                      </ul>
                    </span>
                  </AccordionDetails>
                </Accordion>
              )}
              {data.isSelfEmployedContractorConfirmation && (
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel7d-content"
                    id="panel7d-header"
                  >
                    <span>Employment and Income Confirmation</span>
                  </AccordionSummary>
                  <AccordionDetails>
                    <span>
                      Confirmation of employment and income evidenced by :
                      <ul>
                        <li>
                          Current and (if required) recently expired or future employment
                          contract(s), evidencing 6 months tenure and 3 months remaining term or 12
                          months of stable employment; or
                        </li>
                        <li>
                          Completed quarterly Business Activity Statements (BAS) for the most recent
                          12-month period, evidencing 12 months of stable employment.
                        </li>
                      </ul>
                    </span>
                  </AccordionDetails>
                </Accordion>
              )}
              {/* {data.isSelfEmployedIncomeTypeA && (
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel8d-content"
                    id="panel8d-header"
                  >
                    <span>Self Employed Income</span>
                  </AccordionSummary>
                  <AccordionDetails>
                    <span>
                      Confirmation of self-employed income evidenced by each of the following:
                      <ul>
                        <li>
                          Your most recent Personal Tax Return and Notice of Assessment, being less
                          than 365 days old;
                        </li>
                        <li>
                          If you are relying on income from a company, your most recent Company Tax
                          Return and Notice of Assessment, being less than 365 days old; and
                        </li>
                        <li>
                          Your most recent Company Financial Statements (Balance Sheet and Profit
                          &amp; Loss), being less than 365 days old.
                        </li>
                      </ul>
                      Important: please check the income declared by you in this letter is correct.
                    </span>
                  </AccordionDetails>
                </Accordion>
              )}
              {data.isSelfEmployedIncomeTypeB && (
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel9d-content"
                    id="panel9d-header"
                  >
                    <span>Self Employed Income</span>
                  </AccordionSummary>
                  <AccordionDetails>
                    <span>
                      Confirmation of self-employed income evidenced by each of the following:
                      <ul>
                        <li>
                          Your most recent Business Activity Statements (being less than 90 days
                          old);
                        </li>
                        <li>
                          Your most recent interim Financial Statements and Management Accounts
                          (being less than 90 days old); and
                        </li>
                        <li>An Accountant’s declaration of regular salary, wages or net income.</li>
                      </ul>
                      Important: please check the income declared by you in this letter is correct.
                    </span>
                  </AccordionDetails>
                </Accordion>
              )} */}
              {data.isRegularOvertimeAndAllowances && (
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel10d-content"
                    id="panel10d-header"
                  >
                    <span>Regular Overtime and Allowances</span>
                  </AccordionSummary>
                  <AccordionDetails>
                    <span>
                      Confirmation of your regular overtime and/or allowances income from your
                      current employer, evidenced by one of the following:
                      <ul>
                        <li>
                          Your most recent Personal Tax Return, being less than 365 days old; or
                        </li>
                        <li>
                          Your most recent annual Income Statement, being less than 365 days old
                        </li>
                      </ul>
                      Important: please check the income declared by you in this letter is correct.
                    </span>
                  </AccordionDetails>
                </Accordion>
              )}
              {data.isBonusIncomeAndCommission && (
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel11d-content"
                    id="panel11d-header"
                  >
                    <span>Bonus Income and Commission</span>
                  </AccordionSummary>
                  <AccordionDetails>
                    <span>
                      Confirmation of your regular bonus and/or commission income from your current
                      employer, evidenced by one of the following:
                      <ul>
                        <li>
                          Your two most recent Personal Tax Returns and Notices of Assessment, the
                          most recent being less than 365 days old; or
                        </li>
                        <li>
                          Your two most recent annual Income Statements, the most recent being less
                          than 365 days old.
                        </li>
                      </ul>
                      Important: please check the income declared by you in this letter is correct.
                    </span>
                  </AccordionDetails>
                </Accordion>
              )}
              {data.isChildSupportOrMaintenance && (
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel12d-content"
                    id="panel12d-header"
                  >
                    <span>Child Support or Maintenance</span>
                  </AccordionSummary>
                  <AccordionDetails>
                    <span>
                      Confirmation of your regular child support or maintenance income evidenced by:
                      <ul>
                        <li>An agency child support or maintenance agreement; and</li>
                        <li>
                          A bank statement showing at least 6 months of regular payments being
                          received.
                        </li>
                      </ul>
                      Important: please check the income declared by you in this letter is
                      correct.We are able to adopt this income for dependents {"<"} 11 years old.
                    </span>
                  </AccordionDetails>
                </Accordion>
              )}
              {data.isInvestmentIncome && (
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel13d-content"
                    id="panel13d-header"
                  >
                    <span>Investment Income</span>
                  </AccordionSummary>
                  <AccordionDetails>
                    <span>
                      Confirmation of your regular investment income, evidenced by your two most
                      recent Personal Tax Returns and Notices of Assessment, the most recent being
                      less than 365 days old.
                      <p>
                        Important: please check the income declared by you in this letter is
                        correct.
                      </p>
                    </span>
                  </AccordionDetails>
                </Accordion>
              )}
              {data.isRentalIncome && (
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel14d-content"
                    id="panel14d-header"
                  >
                    <span>Rental Income</span>
                  </AccordionSummary>
                  <AccordionDetails>
                    <span>
                      Confirmation of your regular rental income on a property not being used as
                      security, evidenced by one of the following:
                      <ul>
                        <li>
                          A letter from your current leasing manager confirming the current gross
                          rental for the property; or
                        </li>
                        <li>
                          The rental ledger issued by real estate agent or property manager showing
                          4 or more weeks of rental payments (the most recent being less than 60
                          days old); or
                        </li>
                        <li>
                          The current Residential Tenancy Agreement (run via a real estate); or
                        </li>
                        <li>A bank statement showing 4 or more regular rental receipts.</li>
                      </ul>
                      Confirmation of rental income on a security property will be assessed by
                      reference to the statistical valuation.
                      <p>
                        Important: please check the income declared by you in this letter is
                        correct.
                      </p>
                    </span>
                  </AccordionDetails>
                </Accordion>
              )}
              {data.isGovernmentPensionsAndArmedServicesAllowance && (
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel15d-content"
                    id="panel15d-header"
                  >
                    <span>Government benefits and armed services allowance</span>
                  </AccordionSummary>
                  <AccordionDetails>
                    <span>
                      Confirmation of your Government benefits and/or armed services allowance
                      evidenced by a Centrelink Statement of Benefits (being less than 60 days old)
                      showing your full name, benefit payments and the date from which benefits
                      commenced.
                      <p>
                        If you are in receipt of a Family Tax Benefit, you must be entitled to
                        receive the benefit for at least the next 5 years.
                      </p>
                    </span>
                  </AccordionDetails>
                </Accordion>
              )}
              {data.isBankStatements && (
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel16d-content"
                    id="panel16d-header"
                  >
                    <span>Bank Statements</span>
                  </AccordionSummary>
                  <AccordionDetails>
                    <span>
                      Confirmation of your financial position evidenced by 6 months of statements on
                      all bank accounts held by you to which you receive revenue and/or pay expenses
                      (the most recent being less than 60 days old) showing your name, address and
                      current balance.
                    </span>
                  </AccordionDetails>
                </Accordion>
              )}
              {data.isLoanStatementsForRefinanced && (
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel17d-content"
                    id="panel17d-header"
                  >
                    <span>Loan Statements for Refinance</span>
                  </AccordionSummary>
                  <AccordionDetails>
                    <span>
                      Confirmation of your timely repayment history on loans being refinanced,
                      evidenced by your most recent 6 months of loan statements for loan/s being
                      refinanced, confirming there have been no payments more than 30 days overdue.
                    </span>
                  </AccordionDetails>
                </Accordion>
              )}
              {data.isAnyDebtNotRefinanced && (
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel18d-content"
                    id="panel18d-header"
                  >
                    <span>Loans statements for remaining liabilities</span>
                  </AccordionSummary>
                  <AccordionDetails>
                    <span>
                      Confirmation of your timely payment history with your remaining commitments,
                      evidenced by your most recent 1 months rental ledger or bank statements.
                    </span>
                  </AccordionDetails>
                </Accordion>
              )}
              {data.isAnyDebtRefinanced && (
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel19d-content"
                    id="panel19d-header"
                  >
                    <span>Personal Loan/ Credit Cards for Refinance</span>
                  </AccordionSummary>
                  <AccordionDetails>
                    <span>
                      Confirmation of the conduct to your existing credit facilities evidenced by
                      the most recent 3 month’s statement for all of your accounts, confirming clear
                      conduct.
                    </span>
                  </AccordionDetails>
                </Accordion>
              )}
              {data.isHolidayLetIncome && (
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel20d-content"
                    id="panel20d-header"
                  >
                    <span>Holiday Let Income</span>
                  </AccordionSummary>
                  <AccordionDetails>
                    <span>
                      12 months transaction history/statements from the service provider (Airbnb,
                      Stayz etc) confirming consistent income.
                    </span>
                  </AccordionDetails>
                </Accordion>
              )}
              {data.isFundsToComplete && (
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel21d-content"
                    id="panel21d-header"
                  >
                    <span>Funds to Complete</span>
                  </AccordionSummary>
                  <AccordionDetails>
                    <span>
                      Confirmation of your funds to complete, evidenced by:
                      <ul>
                        <li>
                          If your funds to complete are sourced from deposit/s held with an
                          Australian financial institution, statements of accounts held by you
                          (being less than 60 days old) showing your name, address and current
                          balance;
                        </li>
                        <li>
                          If your funds to complete a purchase are sourced from a gift, a statutory
                          declaration from the donor, stating that the funds are a gift and there is
                          no obligation to repay must be received;
                        </li>
                        <li>
                          If your funds to complete are sourced from the sale of property, a copy of
                          the signed Contract of Sale;
                        </li>
                        <li>
                          If your funds to complete are sourced from the sale of shares or
                          investments, a copy of the share or investment holdings certificate; or
                        </li>
                        <li>
                          If you have already paid a deposit on a property being purchased, a copy
                          of the deposit receipt or a copy of the signed Contract of Sale.
                        </li>
                      </ul>
                    </span>
                  </AccordionDetails>
                </Accordion>
              )}
            </React.Fragment>
          )
        );
      })}
    </div>
  );
};

const mapStateToProps = (state: TAppStateType) => {
  return {
    brandName: state.branding.brandName,
  };
};

export default connect(mapStateToProps)(SupportingDocs);
