import { moduleConstant } from "../../../constants";
import {
  BRANDING_CSS_UPDATE,
  BrandingCssUpdateActionType,
  BrandingCssUpdateAction,
  BRANDING_ADD_BRAND_NAME,
  BrandingAddBrandNameAction,
  BrandingAddBrandNameActionType,
} from "./brandingActionTypes";

export const brandingCssUpdateAction: BrandingCssUpdateActionType = (
  css: string,
  brandName: string
): BrandingCssUpdateAction => {
  return {
    css,
    brandName,
    module: [moduleConstant.branding],
    type: BRANDING_CSS_UPDATE,
  };
};

export const brandingAddBrandName: BrandingAddBrandNameActionType = (
  brandName:string
): BrandingAddBrandNameAction => {
  return {
    brandName,
    module: [moduleConstant.branding],
    type: BRANDING_ADD_BRAND_NAME,
  };
};

