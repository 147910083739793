import { TAppStateType } from "../../reducers/reducers";
import { ISolutionInitialState } from "amm-solution-interface";

export const SOLUTION_ACCOUNT_CURRENT_TAB = "SOLUTION_ACCOUNT_CURRENT_TAB";
export const SOLUTION_ACCOUNT_ADD_NEW = "SOLUTION_ACCOUNT_ADD_NEW";
export const SOLUTION_REMOVE_ACCOUNT = "SOLUTION_REMOVE_ACCOUNT";
export const SOLUTION_ACCOUNT_STATE_UPDATE = "SOLUTION_ACCOUNT_STATE_UPDATE";
export const SOLUTION_SOLUTION_STATE_UPDATE = "SOLUTION_SOLUTION_STATE_UPDATE";
export const SOLUTION_GET_LOAN_AMOUNT = "SOLUTION_GET_LOAN_AMOUNT";
export const SOLUTION_ACCOUNT_AMOUNT_CHANGE = "SOLUTION_ACCOUNT_AMOUNT_CHANGE";
export const SOLUTION_LOAN_TERM = "SOLUTION_LOAN_TERM";
export const SOLUTION_LOAD_ENTIRE_STATE = "SOLUTION_LOAD_ENTIRE_STATE";
export const SOLUTION_ACCOUNT_PURPOSE_CHANGE_ALL = "SOLUTION_ACCOUNT_PURPOSE_CHANGE_ALL";
export const SOLUTION_CASHOUT_NOTE_UPDATE = "SOLUTION_CASHOUT_NOTE_UPDATE";

export interface SolutionAcountCurrentTabAction {
  currentTab: number;
  module: string[];
  type: "SOLUTION_ACCOUNT_CURRENT_TAB";
}

export interface SolutionAccountAddNewAction {
  accountPurpose: string;
  module: string[];
  type: "SOLUTION_ACCOUNT_ADD_NEW";
}

export interface SolutionAccountRemoveAction {
  module: string[];
  removeIndex: number;
  type: "SOLUTION_REMOVE_ACCOUNT";
}

export interface SolutionAccountStateUpdateAction {
  index?: number;
  functionName?: string;
  module: string[];
  stateName: string;
  stateValue: number | string | boolean;
  type: "SOLUTION_ACCOUNT_STATE_UPDATE";
}

export interface SolutionCashOutNoteUpdateAction {
  note: string;
  module: string[];
  type: "SOLUTION_CASHOUT_NOTE_UPDATE";
}
export interface SolutionSolutionStateUpdateAction {
  index?: number;
  functionName?: string;
  module: string[];
  stateName: string;
  stateValue: number | string |boolean;
  type: "SOLUTION_SOLUTION_STATE_UPDATE";
}

export interface SolutionGetLoanAmountAction {
  loanAmount: number;
  cashOutAmount: number;
  module: string[];
  refinancePropertyTotal: number;
  type: "SOLUTION_GET_LOAN_AMOUNT";
}

export interface SolutionAccountAmountChangeAction {
  module: string[];
  type: "SOLUTION_ACCOUNT_AMOUNT_CHANGE";
}

export interface SolutionLoanTermAction {
  module: string[];
  type: "SOLUTION_LOAN_TERM";
}

export interface SolutionLoadEntireStateAction {
  module: string[];
  state: TAppStateType["loan"];
  type: "SOLUTION_LOAD_ENTIRE_STATE";
}

export interface SolutionAccountPurposeUpdateAllAction {
  accountPurpose: string;
  module: string[];
  type: "SOLUTION_ACCOUNT_PURPOSE_CHANGE_ALL";
}

export type SolutionActions =
  | SolutionAccountAddNewAction
  | SolutionAccountAmountChangeAction
  | SolutionAccountRemoveAction
  | SolutionAccountStateUpdateAction
  | SolutionCashOutNoteUpdateAction
  | SolutionAcountCurrentTabAction
  | SolutionGetLoanAmountAction
  | SolutionLoadEntireStateAction
  | SolutionLoanTermAction
  | SolutionSolutionStateUpdateAction
  | SolutionAccountPurposeUpdateAllAction;

export type SolutionAccountCurrentTabActionType = (
  currentTab: number
) => SolutionAcountCurrentTabAction;

export type SolutionAccountAddNewActionType = (accountPurpose: string) => SolutionAccountAddNewAction;

export type SolutionAccountRemoveActionType = (index: number) => SolutionAccountRemoveAction;

export type SolutionAccountStateUpdateActionType = (
  stateName: string,
  stateValue: number | string | boolean,
  functionName?: string,
  index?: number
) => SolutionAccountStateUpdateAction | SolutionSolutionStateUpdateAction;

export type SolutionCashOutNoteUpdateActionType = (note: string) => SolutionCashOutNoteUpdateAction;

export type SolutionGetLoanAmountActionType = (
  loanAmount: number,
  refinancePropertyTotal: number,
  cashOutAmount: number
) => SolutionGetLoanAmountAction;

export type SolutionAccountAmountChangeActionType = () => SolutionAccountAmountChangeAction;

export type solutionLoanTermActionType = () => SolutionLoanTermAction;

export type SolutionLoadEntireStateActionType = (
  state: ISolutionInitialState
) => SolutionLoadEntireStateAction;

export type SolutionAccountPurposeUpdateAllActionType = (accountPurpose: string) => SolutionAccountPurposeUpdateAllAction;
