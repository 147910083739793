import { Reducer } from "redux";
import { dates, filenames, moduleConstant, tags, IAttachedFile } from "../../../constants";
import { deepCopyObject } from "../../../utility";
import { LOGIN_USER_LOGOUT, LoginUserLogoutAction } from "../../actions/login/loginActionType";
import {
  UPLOAD_FILE_MODAL_CLOSE,
  UPLOAD_FILE_MODAL_OPEN,
  UPLOAD_HISTORY_UPDATE,
  UPLOAD_LOADER,
  UploadActions,
  UPLOAD_UPDATE_NUMBER_OF_UPLOADS,
  DELETE_FILE_MODAL_OPEN,
  DELETE_FILE_MODAL_CLOSE,
  DISABLE_SUBMIT_BUTTON,
  UPDATE_IS_SUBMIT_MODAL_VISIBILE,
  UPDATE_SETTLEMENT_DATE,
  UPLOAD_DOCUMENT_PREVIEW,
  IS_UPLOAD_MODAL_VISIBLE,
  FILE_CHUNKS,
  SET_TOASTS,
  ATTACHED_FILES,
  IS_ALL_FILES_UPLOADED,
  UPDATE_TAGS_ACTION,
  SELECTED_TAGS_ACTION,
  SET_UPLOADING_FILES,
  UPLOAD_LOAD_ENTIRE_STATE,
} from "../../actions/uploadDocumentsActions/uploadDocumentsActionType";
import { IToastsInterface } from "../successSnackbarReducer";
interface IUploadStateInterface {
  tagsModalVisibility: boolean;
  deleteModalVisibility: boolean;
  isUploadModalVisible: boolean;
  showUploadLoader: boolean;
  file: any;
  scenarioId: string;
  deleteFileId: number;
  dates: string[];
  filenames: string[];
  tags: string[];
  numberOfUploads: number;
  isSubmitDisabled: boolean;
  isSubmitModalVisible: boolean;
  settlementDate: string | null;
  documentPreviewIsOpen: boolean;
  documentPreviewFileName: string;
  documentPreviewUrl: string;
  documentPreviewFileType: string;
  fileName: string;
  fileExtension: string;
  loadFile: File | File[] | any;
  selectedTags: string[];
  uploadTime: string;
  toasts: IToastsInterface[];
  isFileSuccess: boolean;
  attachedFiles: IAttachedFile[];
  uploadId: number;
  allFilesUploaded: number;
  allFilesAttached: boolean;
}
export const initialState: IUploadStateInterface = {
  dates,
  file: "",
  scenarioId: "",
  filenames,
  showUploadLoader: false,
  deleteModalVisibility: false,
  isUploadModalVisible: false,
  deleteFileId: -1,
  tags,
  tagsModalVisibility: false,
  numberOfUploads: 0,
  isSubmitDisabled: true,
  isSubmitModalVisible: false,
  settlementDate: null,
  documentPreviewIsOpen: false,
  documentPreviewFileName: "",
  documentPreviewUrl: "",
  documentPreviewFileType: "",
  fileName: "",
  fileExtension: "",
  loadFile: "",
  selectedTags: [],
  uploadTime: "",
  toasts: [],
  isFileSuccess: false,
  attachedFiles: [],
  uploadId: 0,
  allFilesUploaded: 0,
  allFilesAttached: true,
};

const scenarioReducer: Reducer<IUploadStateInterface, UploadActions | LoginUserLogoutAction> = (
  state: IUploadStateInterface = deepCopyObject(initialState),
  action: UploadActions | LoginUserLogoutAction
) => {
  if (
    action &&
    action.module &&
    action.module.filter((currentModule) => Object.values(moduleConstant).includes(currentModule))
      .length === 0
  ) {
    return { ...state };
  }
  const currentState = Object.assign({}, state);
  let tagsModalVisibility = false;

  switch (action.type) {
    case UPLOAD_FILE_MODAL_CLOSE:
      tagsModalVisibility = false;
      return { ...currentState, tagsModalVisibility };

    case UPLOAD_FILE_MODAL_OPEN:
      tagsModalVisibility = true;
      const scenarioId = action.scenarioId;
      return { ...currentState, tagsModalVisibility, scenarioId };

    case DELETE_FILE_MODAL_OPEN:
      const deleteModalVisibility = action.deleteModalVisibility;
      const deleteFileId = action.deleteFileId;
      return { ...currentState, deleteModalVisibility, deleteFileId };

    case DELETE_FILE_MODAL_CLOSE:
      return {
        ...currentState,
        deleteModalVisibility: action.deleteModalVisibility,
      };

    case UPDATE_SETTLEMENT_DATE:
      return {
        ...currentState,
        settlementDate:
          action.settlementDate && action.settlementDate.toString() !== "Invalid Date"
            ? action.settlementDate.toISOString()
            : "",
      };

    case IS_UPLOAD_MODAL_VISIBLE:
      return {
        ...currentState,
        isUploadModalVisible: action.isUploadModalVisible,
        scenarioId: action.scenarioId,
      };

    case UPDATE_IS_SUBMIT_MODAL_VISIBILE:
      return {
        ...currentState,
        isSubmitModalVisible: action.isSubmitModalVisible,
      };

    case UPLOAD_UPDATE_NUMBER_OF_UPLOADS:
      const numberOfUploads = action.numberOfUploads;

      return {
        ...currentState,
        numberOfUploads,
      };

    case UPLOAD_LOADER:
      const showUploadLoader = action.showUploadLoader;
      return { ...currentState, showUploadLoader };

    case UPLOAD_HISTORY_UPDATE:
      const fileName = action.fileName;
      // to be changed.
      const date = "01/02/2019";
      const newFilenames = [...currentState.filenames];
      newFilenames.push(fileName);
      const newDates = [...currentState.dates];
      newDates.push(date);
      return { ...currentState, dates: newDates, filenames: newFilenames };

    case UPLOAD_LOAD_ENTIRE_STATE:
      const uploadState = action.state;
      return { ...uploadState };

    case LOGIN_USER_LOGOUT:
      return { ...initialState };

    case DISABLE_SUBMIT_BUTTON:
      return {
        ...currentState,
        isSubmitDisabled: action.isDisabled,
      };
    case UPLOAD_DOCUMENT_PREVIEW:
      return {
        ...currentState,
        documentPreviewIsOpen: action.isOpen,
        documentPreviewFileName: action.fileName,
        documentPreviewUrl: action.url,
        documentPreviewFileType: action.fileType,
      };

    case FILE_CHUNKS:
      const newState: IUploadStateInterface = JSON.parse(JSON.stringify(currentState));
      newState.attachedFiles[action.index].chunks = action.chunks;
      newState.attachedFiles[action.index].responseCount =
        newState.attachedFiles[action.index].responseCount + 1;
      return { ...newState };

    case SET_TOASTS:
      return {
        ...currentState,
        toasts: action.toasts,
        isFileSuccess: action.isFileSuccess,
      };

    case ATTACHED_FILES:
      return {
        ...currentState,
        attachedFiles: action.attachedFiles,
      };

    case UPDATE_TAGS_ACTION:
      return {
        ...currentState,
        fileName: action.fileName,
        uploadTime: action.uploadTime,
        tags: action.tags,
        uploadId: action.uploadId,
      };

    case SELECTED_TAGS_ACTION:
      return {
        ...currentState,
        selectedTags: action.selectedTags,
      };

    case IS_ALL_FILES_UPLOADED:
      return {
        ...currentState,
        allFilesAttached: action.allFilesAttached,
      };

    case SET_UPLOADING_FILES:
      let currentFiles: number = state.allFilesUploaded;
      return {
        ...currentState,
        allFilesUploaded: currentFiles + action.allFilesUploaded,
      };

    default:
      return { ...currentState };
  }
};

export default scenarioReducer;
