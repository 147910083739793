import { Action } from "redux";
import {
  IAssets,
  ICreditCards,
  IIncomeSources,
  ILivingExpenses,
  IFinancialObligation,
  ITotalLivingExpenses,
} from "amm-applicant-interface";
import { IAffordabilityRequiredFields } from "amm-applicant-interface";
import { IApplicantRequiredField, IApplicant } from "amm-applicant-interface";
import { IPropertyArray } from "amm-objective-interface";
import { useState, useEffect } from "react";
import { ThunkAction } from "redux-thunk";
import { purchaseOrRefinanceOption } from "../constants";
import {
  applicantPrimaryRequiredFieldsUpdateAction,
  applicantRequiredFieldsCheckAction,
} from "../store/actions/applicantActions/applicantActions";
import { remainingFinancialObligations } from "../store/reducers/applicantReducers/applicantReducerUtility";

export const checkApplicantErrors = (
  fields: IApplicantRequiredField[],
  applicants: IApplicant[]
) => {
  const applicantErrorIndicator: boolean[] = [];
  for (const field of fields) {
    for (const key in field) {
      if (key !== "applicantDocuments") {
        if (field[key] === true) {
          applicantErrorIndicator.push(true);
        } else {
          applicantErrorIndicator.push(false);
        }
      } else {
        for (const document of field[key]) {
          for (const keyInside in document) {
            if (document[keyInside]) {
              applicantErrorIndicator.push(true);
            } else {
              applicantErrorIndicator.push(false);
            }
          }
        }
      }
    }
  }
  for (const applicant of applicants) {
    if (
      applicant.homePhoneInvalid ||
      (applicant.privacyConsent.formSubmit && !applicant.privacyConsent.idsMatrix) ||
      applicant.isEmailDuplicate ||
      applicant.isMobilePhoneDuplicate
    ) {
      applicantErrorIndicator.push(true);
    } else {
      applicantErrorIndicator.push(false);
    }
  }
  return applicantErrorIndicator;
};

const isIncomeSourceNoteRequired = (element: IIncomeSources[]) => {
  return Boolean(
    element.find(
      (item: IIncomeSources) => (item.noteRequired && !item.isDeleted) || item.deletedNoteRequired
    )
  );
};

const isLivingExpenseNoteRequired = (element: ILivingExpenses[]) => {
  return Boolean(
    element.find(
      (item: ILivingExpenses) => (item.noteRequired && !item.isDeleted) || item.deletedNoteRequired
    )
  );
};

const isTotalLivingExpensesNoteRequired = (element: ITotalLivingExpenses[]) => {
  if (!element) {
    return false;
  }
  return Boolean(element[0].noteRequired && element[0].note === "");
};

const isFinancialObligationNoteRequired = (element: IFinancialObligation[]) => {
  return Boolean(
    element.find(
      (item: IFinancialObligation) =>
        (item.noteRequired && !item.isDeleted) || item.deletedNoteRequired
    )
  );
};

const isCreditCardNoteRequired = (element: ICreditCards[]) => {
  return Boolean(
    element.find(
      (item: ICreditCards) => (item.noteRequired && !item.isDeleted) || item.deletedNoteRequired
    )
  );
};

const isAssetsNoteRequired = (element: IAssets[]) => {
  return Boolean(
    element.find(
      (item: IAssets) => (item.noteRequired && !item.isDeleted) || item.deletedNoteRequired
    )
  );
};

export const checkAffordabilityError = (
  applicants: IApplicant[],
  primaryAffordabilityRequiredFields: IAffordabilityRequiredFields,
  isReviewPageVisited?: boolean
) => {
  if (
    Object.values(primaryAffordabilityRequiredFields.required).includes(true) &&
    isReviewPageVisited
  ) {
    return true;
  }
  for (const applicant of applicants) {
    if (applicant.isApplicantGuarantor === "Yes") {
      continue;
    }
    if (
      isIncomeSourceNoteRequired(applicant.incomeSources) ||
      isLivingExpenseNoteRequired(applicant.livingExpenses) ||
      isFinancialObligationNoteRequired(applicant.financialObligation) ||
      isCreditCardNoteRequired(applicant.creditCards) ||
      isAssetsNoteRequired(applicant.assets) ||
      isTotalLivingExpensesNoteRequired(applicant.totalExpensesNotes)
    ) {
      return true;
    }

    for (const income of applicant.incomeSources) {
      if (income.type === "" || (income.type === "Uncategorised" && !income.isDeleted)) {
        return true;
      }
    }

    for (const expense of applicant.livingExpenses) {
      if (expense.type === "") {
        return true;
      }
    }

    for (const credit of applicant.creditCards) {
      if (credit.institution === "") {
        return true;
      }
    }

    for (const asset of applicant.assets) {
      if (asset.type === "") {
        return true;
      }
    }

    for (const obligation of applicant.financialObligation) {
      if (obligation.type === "") {
        return true;
      }
    }
  }

  return false;
};

export const checkCreditCardError = (applicants: IApplicant[]) => {
  const affordabilityErrors: boolean[] = [];
  for (const applicant of applicants) {
    for (const card of applicant.creditCards) {
      if (card.textError) {
        affordabilityErrors.push(true);
      } else {
        affordabilityErrors.push(false);
      }
    }
  }
  return affordabilityErrors;
};

export const checkCreditCardErrorFromReview = (applicants: IApplicant[]) => {
  const affordabilityErrors: boolean[] = [];
  for (const applicant of applicants) {
    for (const card of applicant.creditCards) {
      if (card.textError || card.institution === "") {
        affordabilityErrors.push(true);
      } else {
        affordabilityErrors.push(false);
      }
    }
  }
  return affordabilityErrors;
};

export const useWindowSize = () => {
  const isClient = typeof window === "object";

  function getSize() {
    return {
      width: isClient ? window.screen.availWidth : undefined,
      height: isClient ? window.screen.availHeight : undefined,
      widgetExpended: window.document.getElementsByClassName("widget-expend").length,
    };
  }

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    if (!isClient) {
      return;
    }

    const handleResize = () => {
      setWindowSize(getSize());
    };

    window.addEventListener("resize", handleResize);
    window.addEventListener("click", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("click", handleResize);
    };
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return windowSize;
};

export type PrimaryApplicantFieldsCheckType = (
  properties: IPropertyArray[],
  primaryApplicant: IApplicant
) => ThunkAction<any, any, any, Action>;

export const primaryApplicantFieldsCheck: PrimaryApplicantFieldsCheckType = (
  properties: IPropertyArray[],
  primaryApplicant: IApplicant
) => {
  return (dispatch) => {
    dispatch(applicantRequiredFieldsCheckAction());
    if (
      properties.find(
        (property) => property.purchaseOrRefinance === purchaseOrRefinanceOption[1].value
      ) &&
      remainingFinancialObligations(primaryApplicant) > 0
    ) {
      dispatch(
        applicantPrimaryRequiredFieldsUpdateAction({ fieldName: "refinancedLoan", count: 1 })
      );
    } else {
      dispatch(
        applicantPrimaryRequiredFieldsUpdateAction({ fieldName: "refinancedLoan", count: 0 })
      );
    }
  };
};
