import Amplify from "aws-amplify";
import {
  AWS_COGNITO_USER_POOL_ID,
  AWS_COGNITO_USER_POOL_WEB_CLIENT_ID,
  AWS_REGION,
} from "../constants";

Amplify.configure({
  Auth: {
    authenticationFlowType: "USER_SRP_AUTH",
    identityPoolId: "",
    mandatorySignIn: false,
    region: AWS_REGION,
    userPoolId: AWS_COGNITO_USER_POOL_ID,
    userPoolWebClientId: AWS_COGNITO_USER_POOL_WEB_CLIENT_ID,
  },
});
