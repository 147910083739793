import React from "react";

const BrokerIcon = (props: { className?: string }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      className={props.className ? props.className : "broker-icon house-icon"}
      viewBox="0 0 26 24"
    >
      <path
        d="M6,18c1.7,0,3-1.3,3-3s-1.3-3-3-3s-3,1.3-3,3C3,16.7,4.3,18,6,18C6,18,6,18,6,18z M6,19.5c-2,0-6,1-6,3V24h12v-1.5
      C12,20.5,8,19.5,6,19.5z"
      />
      <path
        d="M20,18c1.7,0,3-1.3,3-3s-1.3-3-3-3s-3,1.3-3,3C17,16.7,18.3,18,20,18C20,18,20,18,20,18z M20,19.5c-2,0-6,1-6,3V24h12v-1.5
      C26,20.5,22,19.5,20,19.5z"
      />
      <path d="M13,7.5c-2,0-6,1-6,3v0.1c2,0.5,3.5,2.3,3.5,4.4h5c0-2.2,1.5-3.9,3.5-4.4v-0.1C19,8.5,15,7.5,13,7.5z" />
      <path d="M13,6C13,6,13,6,13,6c1.7,0,3-1.3,3-3s-1.3-3-3-3s-3,1.3-3,3C10,4.7,11.3,6,13,6z" />
    </svg>
  );
};

export default BrokerIcon;
