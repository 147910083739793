import React, { ReactText } from "react";
import { useWindowSize } from "../../utility/reviewUtility";

interface OwnProps {
  className: string;
  value: ReactText;
  width: string;
  labelValue: string;
  percent?: boolean;
}

type ProgressBarProps = OwnProps;
const ProgressBar: React.FC<ProgressBarProps> = (props: ProgressBarProps) => {
  const windowSize = useWindowSize();

  return (
    <div className="progress-bar">
      <h6 id="progress-bar-heading">
        <strong>{props.labelValue}</strong>
      </h6>
      <div className="progress-bar-container">
        {windowSize.width && windowSize.width < 1100 && !windowSize.widgetExpended ? (
          <React.Fragment>
            <span className="progress-bar-label-tab-screen">
              {props.value}
              {props.percent ? "%" : ""}
            </span>
            <div className="progress-bar-fll-container-tab-screeen">
              <div
                className={`progress-bar-fill-tab-screen ${props.className}`}
                style={{ width: props.width }}
              ></div>
            </div>
          </React.Fragment>
        ) : (
          <div className={`progress-bar-fill ${props.className}`} style={{ width: props.width }}>
            <span className="progress-bar-label">
              {props.value}
              {props.percent ? "%" : ""}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProgressBar;
