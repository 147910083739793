import { ILRAS } from "amm-objective-interface";
import { ISolutionInitialState } from "amm-solution-interface";
import React from "react";
import { connect } from "react-redux";
import { kFormatter } from "../../utility";
import RepaymentCard from "./repaymentCard";
import { TAppStateType } from "../../store/reducers/reducers";
import { isDomainNotAFG } from "../../constants";

interface StateProps {
  loan: ISolutionInitialState;
  lras: ILRAS;
}

type RepaymentsProps = StateProps;
export const Repayments: React.FC<RepaymentsProps> = (props: RepaymentsProps) => {
  let monthlyPayments: string[] | number | string = props.lras.monthlyPayments
    ? props.lras.monthlyPayments.toFixed(2)
    : 0.0;
  monthlyPayments = monthlyPayments.toString();
  monthlyPayments = monthlyPayments.split(".");
  const interestRate = props.lras.lrasOutputs.rateVariable
    ? (props.lras.lrasOutputs.rateVariable * 100).toFixed(2)
    : 0;
  const comparisonRate = props.lras.lrasOutputs.rateComparison
    ? (props.lras.lrasOutputs.rateComparison * 100).toFixed(2)
    : 0;
  const loanAmount = `$${kFormatter(props.loan.loanAmount)}`;

  return (
    <div className="papers__container">
      <RepaymentCard
        showComponent={true}
        className={`visible-in-sidebar`}
        heading={loanAmount}
        headingClass={
          props.lras.lrasOutputs.mortgageScore < 700
            ? "md-text--error"
            : Math.floor(props.loan.accountTotalAmount) != Math.floor(props.loan.loanAmount)
            ? "text-amber"
            : "md-text--theme-secondary"
        }
        content={"Loan Amount"}
      />
      <RepaymentCard
        showComponent={props.lras.lrasOutputs.mortgageScore >= 700 ? true : false}
        className="hide-in-sidebar side-bar-monthly-payments"
        headingClass="md-text--theme-secondary review-monthly-payment"
        heading={`$${monthlyPayments[0]}`}
        smallValue={"." + (monthlyPayments[1] ? monthlyPayments[1] : "00")}
        content={"Monthly Payments"}
        
      />
      <RepaymentCard
        showComponent={props.lras.lrasOutputs.mortgageScore >= 700 ? true : false}
        className="side-bar-interest-rate"
        headingClass="md-text--theme-secondary review-interest-rate"
        heading={interestRate}
        smallValue="%"
        content={"Interest Rate"}
        showBanner={isDomainNotAFG}
      />
      <RepaymentCard
        showComponent={props.lras.lrasOutputs.mortgageScore >= 700 ? true : false}
        className="side-bar-comaprison-rates"
        headingClass="md-text--theme-secondary review-comparison-rate"
        heading={comparisonRate}
        smallValue="%"
        content={"Comparison Rate"}
      />
    </div>
  );
};

export const mapStateToProps = (state: TAppStateType) => {
  return {
    loan: state.loan,
    lras: state.help.lras,
  };
};

export default connect(mapStateToProps, {})(Repayments);
