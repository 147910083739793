export const SUCCESS_SNACKBAR_CLOSE = "SUCCESS_SNACKBAR_CLOSE";
export const SUCCESS_GENERIC_SUCCESS_HANDLE = "SUCCESS_GENERIC_SUCCESS_HANDLE";

export interface SuccesSnackBarHandleAction {
  message: string;
  autoHideTimeout?: number;
  type: "SUCCESS_GENERIC_SUCCESS_HANDLE";
}

export interface SuccessSnackBarCloseAction {
  type: "SUCCESS_SNACKBAR_CLOSE";
}

export type SuccessActions = SuccesSnackBarHandleAction | SuccessSnackBarCloseAction;

export type SuccessSnackBarHandleActionType = (
  message: string,
  autoHideTimeout?: number
) => SuccesSnackBarHandleAction;

export type SuccessSnackBarCloseActionType = () => SuccessSnackBarCloseAction;
