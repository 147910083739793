import React from "react";

const LeadsIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45 40.84" className="leads-icon">
      <path d="M43.41,0H1.59C.24,0-.49,1.57,.37,2.61L18.04,23.78v13.09l8.92,3.97V23.78h0L44.63,2.61c.86-1.04,.13-2.61-1.22-2.61Z" />
    </svg>
  );
};

export default LeadsIcon;
