import { Reducer } from "redux";
import { moduleConstant, STATUS_NAMES } from "../../../constants";
import { deepCopyObject } from "../../../utility";
import { LOGIN_USER_LOGOUT, LoginUserLogoutAction } from "../../actions/login/loginActionType";
import { IScenarioPayloadUserScenarios } from "amm-scenario-interface";
import {
  SCENARIO_DELETE,
  SCENARIO_DUPLICATE,
  SCENARIO_LIST,
  SCENARIO_LOAD,
  SCENARIO_LOADER,
  SCENARIO_MODAL_BUTTON_LOADER,
  SCENARIO_MODAL_HIDE,
  SCENARIO_MODAL_SHOW,
  SCENARIO_RESET_CURRENT_SCENARIO_ID,
  SCENARIO_SAVE,
  SCENARIO_SAVE_BUTTON_LOADER,
  ScenarioActions,
  SCENARIO_SAVE_DISCARD_MODAL_SHOW,
  SCENARIO_SAVE_AS_OWN_SCENARIO_MODAL_SHOW,
  SCENARIO_SET_CURRENT_SCENARIO_HISTORY,
  START_DOCUSIGN_DISABLED,
  SET_QUERY_DATA_LOADER,
  SCENARIO_CAPITAL_STATUS_UPDATE,
  SET_REVIEWER_ID_RE_WORK,
} from "../../actions/scenarioActions/scenarioActionType";

export interface CurrentScenarioHistory {
  userID: number;
  scenarioID: number;
  userName: string;
  userRole: string;
  action: string;
  date: string;
}

export interface IModal {
  button1Name: string;
  button2Name: string;
  description: string;
  loader: boolean;
  loadingText: string;
  modalshow: boolean;
  okayAction?: () => void;
  cancelAction?: () => void;
  saveDiscardModalShow?: boolean;
  saveAsOwnScenarioModalShow?: boolean;
  saveAsOwnScenarioDescription?: string;
}
export interface IScenarioStateInterface {
  isSaveButtonLoaderShow: boolean;
  modal: IModal;
  loader: boolean;
  scenarios: IScenarioPayloadUserScenarios[];
  stateItem: string;
  currentScenarioId: number | null;
  currentScenarioStatus: string;
  currentScenarioHistory: CurrentScenarioHistory[];
  reviewerID?: number;
  ownerID?: number;
  setIsStartDocusignDisabled: boolean;
  queryDataLoader: boolean;
}

export const initialState: IScenarioStateInterface = {
  isSaveButtonLoaderShow: false,
  modal: {
    button1Name: "",
    button2Name: "",
    description: "",
    loadingText: "Loading...",
    loader: false,
    modalshow: false,
    saveDiscardModalShow: false,
  },
  loader: false,
  scenarios: [],
  stateItem: "",
  currentScenarioId: null,
  currentScenarioHistory: [],
  currentScenarioStatus: "",
  setIsStartDocusignDisabled: false,
  queryDataLoader: false,
};

const scenarioReducer: Reducer<IScenarioStateInterface, ScenarioActions | LoginUserLogoutAction> = (
  state: IScenarioStateInterface = deepCopyObject(initialState),
  action: ScenarioActions | LoginUserLogoutAction
) => {
  if (
    action &&
    action.module &&
    action.module.filter((currentModule) => Object.values(moduleConstant).includes(currentModule))
      .length === 0
  ) {
    return { ...state };
  }
  const currentState = Object.assign({}, state);
  switch (action.type) {
    // Todo: delete, duplicate,save has not defined any functionalitty
    case SCENARIO_DELETE:
    case SCENARIO_DUPLICATE:
    case SCENARIO_SAVE:
      return { ...currentState };
    case SCENARIO_RESET_CURRENT_SCENARIO_ID:
      currentState.currentScenarioId = null;
      currentState.currentScenarioStatus = STATUS_NAMES.scenario;
      currentState.reviewerID = undefined;
      currentState.ownerID = undefined;
      return currentState;
    case SCENARIO_LOAD:
      currentState.currentScenarioId = action.scenarioId;
      currentState.currentScenarioStatus = action.scenarioStatus;
      currentState.reviewerID = action.reviewerID ? action.reviewerID : currentState.reviewerID;
      currentState.ownerID = action.ownerID ? action.ownerID : currentState.ownerID;
      if (action.newSavedScenario) {
        currentState.scenarios.push(action.newSavedScenario);
      }

      return currentState;
    case SCENARIO_LIST:
      if (action.from) {
        currentState.scenarios = action.data;
        return currentState;
      }
      currentState.scenarios = action.data.user.scenarios;
      return currentState;
    case SCENARIO_CAPITAL_STATUS_UPDATE:
      const newScenarios = JSON.parse(JSON.stringify(currentState.scenarios));
      const foundScenarioIndex = newScenarios.findIndex(
        (scenario: any) => scenario.id === action.scenarioId
      );
      if (foundScenarioIndex >= 0) {
        newScenarios[foundScenarioIndex].scenario_datas[0].capitalStatus = action.capitalStatus;
      }
      return {
        ...currentState,
        scenarios: newScenarios,
      };

    case SCENARIO_LOADER:
      currentState.loader = action.visibility;
      return currentState;
    case LOGIN_USER_LOGOUT:
      return { ...initialState };

    case SCENARIO_SAVE_BUTTON_LOADER:
      currentState.isSaveButtonLoaderShow = action.loader;
      return { ...currentState };

    case SCENARIO_MODAL_SHOW:
      if (action.description) {
        currentState.modal.description = action.description;
      }
      if (action.button1Name) {
        currentState.modal.button1Name = action.button1Name;
      }
      if (action.loadingText) {
        currentState.modal.loadingText = action.loadingText;
      }
      if (action.button2Name) {
        currentState.modal.button2Name = action.button2Name;
      }

      if (action.cancelAction) {
        currentState.modal.cancelAction = action.cancelAction;
      }
      if (action.okayAction) {
        currentState.modal.okayAction = action.okayAction;
      }

      currentState.modal.modalshow = true;
      return { ...currentState };

    case SCENARIO_MODAL_HIDE:
      currentState.modal = {
        button1Name: "",
        button2Name: "",
        description: "",
        loadingText: "Loading...",
        loader: false,
        modalshow: false,
      };
      return { ...currentState };

    case SCENARIO_MODAL_BUTTON_LOADER:
      currentState.modal.loader = action.loader;
      return { ...currentState };

    case SCENARIO_SAVE_DISCARD_MODAL_SHOW:
      currentState.modal.saveDiscardModalShow = action.show;
      return { ...currentState };

    case SCENARIO_SAVE_AS_OWN_SCENARIO_MODAL_SHOW:
      currentState.modal.saveAsOwnScenarioModalShow = action.show;
      currentState.modal.saveAsOwnScenarioDescription = action.description;
      return { ...currentState };

    case SCENARIO_SET_CURRENT_SCENARIO_HISTORY:
      currentState.currentScenarioHistory = action.currentScenarioHistory;
      return { ...currentState };

    case START_DOCUSIGN_DISABLED:
      currentState.setIsStartDocusignDisabled = action.setIsStartDocusignDisabled;
      return { ...currentState };

    case SET_QUERY_DATA_LOADER:
      currentState.queryDataLoader = action.queryDataLoader;
      return { ...currentState };

    case SET_REVIEWER_ID_RE_WORK:
      currentState.reviewerID = action.reviewerID;
      return { ...currentState };

    default:
      return { ...currentState };
  }
};

export default scenarioReducer;
