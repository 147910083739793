import { Reducer } from "redux";
import {
  SUCCESS_GENERIC_SUCCESS_HANDLE,
  SUCCESS_SNACKBAR_CLOSE,
  SuccessActions,
} from "../../actions/snackbarSuccessAction/successActionType";

export interface IToastsInterface {
  text: string;
}

export interface ISuccessStateInterface {
  toasts: IToastsInterface[];
  autohide: boolean;
  autoHideTimeout?: number;
}

export const initialState: ISuccessStateInterface = {
  autohide: true,
  autoHideTimeout: 1000,
  toasts: [],
};

const successSnackbarReducer: Reducer<ISuccessStateInterface, SuccessActions> = (
  state: ISuccessStateInterface = initialState,
  action: SuccessActions
) => {
  const currentState = Object.assign({}, state);
  let toasts = [...currentState.toasts];
  let autoHideTimeout = currentState.autoHideTimeout;
  toasts.slice();
  switch (action.type) {
    case SUCCESS_GENERIC_SUCCESS_HANDLE:
      toasts.push({ text: action.message });
      autoHideTimeout =
        action.autoHideTimeout === undefined ? autoHideTimeout : action.autoHideTimeout;
      return { ...currentState, toasts, autoHideTimeout };
    case SUCCESS_SNACKBAR_CLOSE:
      [, ...toasts] = state.toasts;
      return { ...currentState, toasts };
    default:
      return { ...currentState };
  }
};

export default successSnackbarReducer;
