import React from "react";

const TableIcon = (props: { className?: string }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 17"
      className={props.className ? props.className : "table-icon"}
    height="24" width="26">
      <rect width="6" height="3" />
      <rect y="7" width="6" height="3" />
      <rect y="14" width="6" height="3" />
      <rect x="8" y="14" width="18" height="3" />
      <rect x="8" y="7" width="18" height="3" />
      <rect x="8" width="18" height="3" />
    </svg>
  );
};

export default TableIcon;
