import React from "react";

const BedIcon = () => {
  return (
    <svg
      className="bed-icon"
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="10"
      viewBox="0 0 12 10"
    >
      <rect y="6" width="12" height="2" />
      <rect id="Rectangle_708_copy_5" data-name="Rectangle 708 copy 5" width="2" height="10" />
      <rect
        id="Rectangle_708_copy_6"
        data-name="Rectangle 708 copy 6"
        x="10"
        y="6"
        width="2"
        height="4"
      />
      <rect
        id="Rounded_Rectangle_8"
        data-name="Rounded Rectangle 8"
        x="3"
        y="2"
        width="9"
        height="3"
        rx="3"
        ry="3"
      />
    </svg>
  );
};

export default BedIcon;
