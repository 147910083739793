import { IApplicant } from "amm-applicant-interface";
import { IFundsToCompleteDuty } from "amm-objective-interface";
import { ApolloError } from "apollo-client";
import React, { useEffect, useState } from "react";
import { Query, QueryResult } from "react-apollo";
import { connect } from "react-redux";
import { GET_FUNDS_TO_COMPLETE_DUTY } from "../../constants/queryAndMutations";
import { helpFundsToCompleteAction } from "../../store/actions/helpActions/helpAction";
import { HelpFundsToCompleteActionType } from "../../store/actions/helpActions/helpActionType";
import {
  reviewSaveFundsToCompleteAction,
  reviewSetValidationAction,
  reviewShowApproveLoaderAction,
} from "../../store/actions/reviewActions/reviewActions";
import {
  ReviewSaveFundsToCompleteActionType,
  ReviewSetValidationActionType,
  ReviewShowApproveLoaderActionType,
} from "../../store/actions/reviewActions/reviewActionTypes";
import { checkIfAllValidationIsCorrect } from "../../utility/validationPerPage";
import { FontIcon } from "react-md";
import ReviewCrossIcon from "../common/icons/reviewCrossIcon";
import { TAppStateType } from "../../store/reducers/reducers";
import { fundsToCompleteAction } from "../../constants/userActions";
import { useApolloClient } from "react-apollo-hooks";
import {
  getAlgoliaRecordsThunk,
  GetAlgoliaRecordsThunkType,
} from "../../store/thunk/helpSectionThunk/getAlgoliaRecordsThunk";

interface IMapDispatchToProps {
  reviewSaveFundsToCompleteAction: ReviewSaveFundsToCompleteActionType;
  helpFundsToCompleteAction: HelpFundsToCompleteActionType;
  reviewSetValidationAction: ReviewSetValidationActionType;
  getAlgoliaRecordsThunk: GetAlgoliaRecordsThunkType;
}

export interface IFundsToCompleteDutyData {
  fundsToComplete: {
    payload: {
      data: IFundsToCompleteDuty[];
    };
    message: string;
  };
}

interface IFundsToCompleteView {
  cachedData?: IFundsToCompleteDuty;
  data?: IFundsToCompleteDutyData;
  properties: IPartialProperty[];
  loanAmount: number;
  error?: ApolloError | undefined;
  loading: boolean;
  isCached: boolean;
  debounceHandler: (value: boolean) => void;
  totalState: TAppStateType;

  fundsToCompleteSum: number;
}

const FundsToCompleteViewInternal: React.FC<IFundsToCompleteView & IMapDispatchToProps> = (
  props: IFundsToCompleteView & IMapDispatchToProps
) => {
  const { error, loading, data } = props;
  let totalFees: IFundsToCompleteDuty;

  if (!props.isCached) {
    if (loading) {
      return (
        <div className="funds-to-complete-loader" data-testid="loader">
          <span className="loader-show"></span>
        </div>
      );
    }
    if (error || data === undefined) {
      return (
        <div className="funds-to-complete-error" data-testid="funds-to-complete-error">
          Funds to Complete could not be fetched.
        </div>
      );
    }
    if (
      data.fundsToComplete &&
      data.fundsToComplete.payload &&
      data.fundsToComplete.payload.data.length === 0
    ) {
      return (
        <div className="funds-to-complete-error" data-testid="funds-to-complete-error">
          Funds to Complete could not be fetched.
        </div>
      );
    }

    const [firstPropertyFee, ...totalFeesForRestProperty] = data.fundsToComplete.payload.data;

    if (totalFeesForRestProperty.length > 0) {
      totalFees = totalFeesForRestProperty.reduce((totalFeesSum, propertyFee) => {
        return {
          deposit: totalFeesSum.deposit + propertyFee.deposit,
          dischargeFee: totalFeesSum.dischargeFee + propertyFee.dischargeFee,
          mortgageRegistrationFee:
            totalFeesSum.mortgageRegistrationFee + propertyFee.mortgageRegistrationFee,
          settlementFee: totalFeesSum.settlementFee + propertyFee.settlementFee,
          stampDutyOnProperty: totalFeesSum.stampDutyOnProperty + propertyFee.stampDutyOnProperty,
          totalFee: totalFeesSum.totalFee + propertyFee.totalFee,
          transferFee: totalFeesSum.transferFee + propertyFee.transferFee,
          valuationFee: totalFeesSum.valuationFee + propertyFee.valuationFee,
        };
      }, firstPropertyFee);
    } else {
      totalFees = firstPropertyFee;
    }
    const depositAdjust = totalFees.deposit > 0 ? 0 : 0 - totalFees.deposit;

    totalFees.deposit = totalFees.deposit + depositAdjust;
    totalFees.totalFee = totalFees.totalFee + depositAdjust;

    props.reviewSaveFundsToCompleteAction(
      [firstPropertyFee, ...totalFeesForRestProperty],
      totalFees
    );

    props.debounceHandler(false);
    props.helpFundsToCompleteAction(false, true);
    props.reviewSetValidationAction(
      checkIfAllValidationIsCorrect("affordability", props.totalState),
      "fundsToCompleteSum"
    );
  } else {
    totalFees = props.cachedData as IFundsToCompleteDuty;
    props.debounceHandler(false);
  }

  const allRefinance = props.properties.reduce(
    (onlyRefinance, property) => onlyRefinance && property.purchaseOrRefinance === "Refinance",
    true
  );

  return (
    <ul>
      {
        <React.Fragment>
          <h6
            className="heading-6-width-border algolia-records"
            onClick={(e) => props.getAlgoliaRecordsThunk("2891768002")}
            data-testid="funds-to-complete-table"
          >
            FUNDS TO COMPLETE
          </h6>
          <div className="property-value-tab  le list-table-layout">
            {totalFees.deposit !== 0 && <span>Deposit:</span>}
            {totalFees.deposit !== 0 && (
              <span>
                $
                {Number(parseFloat(totalFees.deposit.toString()).toFixed(2)).toLocaleString("en", {
                  minimumFractionDigits: 2,
                })}
              </span>
            )}
            {!allRefinance && <span>Estimated Stamp Duty</span>}
            {!allRefinance && (
              <span>
                $
                {Number(
                  parseFloat(totalFees.stampDutyOnProperty.toString()).toFixed(2)
                ).toLocaleString("en", {
                  minimumFractionDigits: 2,
                })}
              </span>
            )}
            {!allRefinance && <span>Estimated transfer fee:</span>}
            {!allRefinance && (
              <span>
                $
                {Number(parseFloat(totalFees.transferFee.toString()).toFixed(2)).toLocaleString(
                  "en",
                  {
                    minimumFractionDigits: 2,
                  }
                )}
              </span>
            )}
            <span>Estimated mortgage registration fee:</span>
            <span>
              $
              {totalFees.mortgageRegistrationFee
                ? Number(
                    parseFloat(totalFees.mortgageRegistrationFee.toString()).toFixed(2)
                  ).toLocaleString("en", {
                    minimumFractionDigits: 2,
                  })
                : "0.00"}
            </span>
            <span>Estimated valuation costs:</span>
            <span>
              $
              {totalFees.valuationFee
                ? Number(parseFloat(totalFees.valuationFee.toString()).toFixed(2)).toLocaleString(
                    "en",
                    {
                      minimumFractionDigits: 2,
                    }
                  )
                : "0.00"}
            </span>
            <span>Estimated loan documentation and settlement costs:</span>
            <span>
              $
              {totalFees.settlementFee
                ? Number(parseFloat(totalFees.settlementFee.toString()).toFixed(2)).toLocaleString(
                    "en",
                    {
                      minimumFractionDigits: 2,
                    }
                  )
                : "0.00"}
            </span>
            <span>Estimated discharge costs:</span>
            <span>
              $
              {totalFees.dischargeFee
                ? Number(parseFloat(totalFees.dischargeFee.toString()).toFixed(2)).toLocaleString(
                    "en",
                    {
                      minimumFractionDigits: 2,
                    }
                  )
                : "0.00"}
            </span>
            {/* <span>Lender settlement fee:</span>
            <span>${totalFees.settlementFee.toLocaleString("en")}</span> */}

            <span className="text-bold">
              {props.fundsToCompleteSum >= totalFees.totalFee ? (
                <FontIcon primary={true} className="review-done-icon">
                  done
                </FontIcon>
              ) : (
                <ReviewCrossIcon className="review-cross-icon" />
              )}
              Estimated funds to complete:
            </span>
            <span className="text-bold">
              $
              {totalFees.totalFee
                ? Number(parseFloat(totalFees.totalFee.toString()).toFixed(2)).toLocaleString(
                    "en",
                    {
                      minimumFractionDigits: 2,
                    }
                  )
                : ""}
            </span>
          </div>
        </React.Fragment>
      }
    </ul>
  );
};

export const mapDispatchToProps = {
  reviewSaveFundsToCompleteAction,
  helpFundsToCompleteAction,
  reviewSetValidationAction,
  reviewShowApproveLoaderAction,
  getAlgoliaRecordsThunk,
};

export const FundsToCompleteView = connect(
  (state: TAppStateType) => ({ totalState: state }),
  mapDispatchToProps
)(FundsToCompleteViewInternal);

export interface IPartialProperty {
  propertyValue: number;
  splitAddress: { state: string };
  propertyBeUsed: "Home" | "Investment";
  purchaseOrRefinance: "Purchase" | "Refinance";
  landArea: number;
}
export interface IFundsToCompleteOwnProps {
  properties: IPartialProperty[];
  loanAmount: number;
  applicants: IApplicant[];
}
interface IFundsToCompleteStateProps {
  cachedData: IFundsToCompleteDuty;
  fundsToCompleteCall: boolean;
  fundsToCompleteIsCached: boolean;

  fundsToCompleteSum: number;
  toggleLoader: boolean;
  reviewShowApproveLoaderAction: ReviewShowApproveLoaderActionType;
}

type IFundsToCompleteProps = IFundsToCompleteOwnProps & IFundsToCompleteStateProps;

const FundsToCompleteInternal: React.FC<IFundsToCompleteProps> = (props: IFundsToCompleteProps) => {
  const [debounce, setDebounce] = useState<boolean>(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebounce(true);
    }, 1000);

    return () => {
      clearTimeout(timer);
      props.reviewShowApproveLoaderAction({ toggleLoader: false });
    };
  }, [props.loanAmount]);

  const debounceHandler = (value: boolean) => {
    setDebounce(value);
  };

  const apolloClient = useApolloClient();

  if (debounce && (props.fundsToCompleteCall || !props.fundsToCompleteIsCached)) {
    const isNotFirstHomeBuyer = Boolean(
      props.applicants.find(
        (applicant) =>
          applicant.isApplicantFirstHomeSection === "No" && applicant.isApplicantGuarantor === "No"
      )
    );

    return (
      <Query
        client={apolloClient}
        query={GET_FUNDS_TO_COMPLETE_DUTY}
        variables={{
          user_action: fundsToCompleteAction[0],
          is_first_home: !isNotFirstHomeBuyer,
          loan_amount: props.loanAmount,

          properties: props.properties.map((property) => {
            return {
              investmentOrHome: property.propertyBeUsed === "Investment" ? "investment" : "home",
              propertyValue: property.propertyValue,
              purchaseOrRefinance:
                property.purchaseOrRefinance === "Purchase" ? "purchase" : "refinance",
              landArea: property.landArea >= 0 ? property.landArea : 0,
              state:
                ["qld", "nsw", "vic", "act", "sa", "wa", "nt", "tas"].indexOf(
                  property.splitAddress.state.toLowerCase()
                ) > -1
                  ? (property.splitAddress.state.toLowerCase() as
                      | "qld"
                      | "nsw"
                      | "vic"
                      | "act"
                      | "sa"
                      | "wa"
                      | "nt"
                      | "tas")
                  : "nsw",
            };
          }),
        }}
        fetchPolicy={"no-cache"}
      >
        {({ data, error, loading }: QueryResult<IFundsToCompleteDutyData>) => {
          if (loading) {
            props.reviewShowApproveLoaderAction({ toggleLoader: loading });
          }
          return (
            <FundsToCompleteView
              fundsToCompleteSum={props.fundsToCompleteSum}
              loanAmount={props.loanAmount}
              properties={props.properties}
              data={data}
              error={error}
              loading={loading}
              debounceHandler={debounceHandler}
              isCached={false}
            />
          );
        }}
      </Query>
    );
  } else if (props.fundsToCompleteIsCached) {
    if (props.toggleLoader) {
      props.reviewShowApproveLoaderAction({ toggleLoader: false });
    }
    return (
      <FundsToCompleteView
        fundsToCompleteSum={props.fundsToCompleteSum}
        loanAmount={props.loanAmount}
        properties={props.properties}
        cachedData={props.cachedData}
        isCached={props.fundsToCompleteIsCached}
        error={undefined}
        loading={false}
        debounceHandler={debounceHandler}
      />
    );
  } else {
    props.reviewShowApproveLoaderAction({ toggleLoader: true });
    return (
      <React.Fragment>
        <div className="funds-to-complete-loader">
          <span className="loader-show"></span>
        </div>
      </React.Fragment>
    );
  }
};

const mapStateToProps = (state: TAppStateType) => ({
  fundsToCompleteSum: state.review.validations.fundsToCompleteSum,

  cachedData: state.review.fundsToCompleteTotal,
  fundsToCompleteCall: state.help.fundsToCompleteCall,
  fundsToCompleteIsCached: state.help.fundsToCompleteIsCached,
  toggleLoader: state.review.toggleLoader as boolean,
});

export const FundsToComplete = connect(mapStateToProps, { reviewShowApproveLoaderAction })(
  FundsToCompleteInternal
);
