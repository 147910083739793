import React from "react";

const SignOutIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="sign-out-icon"
      width="25.531"
      height="22"
      viewBox="0 0 25.531 22"
    >
      <g id="Sign_out_icon" data-name="Sign out icon">
        <rect
          id="Rectangle_632_copy_3"
          data-name="Rectangle 632 copy 3"
          x="23.531"
          width="2"
          height="22"
        />
        <rect
          id="Rectangle_632_copy_10"
          data-name="Rectangle 632 copy 10"
          x="13.531"
          y="20"
          width="12"
          height="2"
        />
        <rect
          id="Rectangle_632_copy_9"
          data-name="Rectangle 632 copy 9"
          x="13.531"
          width="12"
          height="2"
        />
        <g id="arrowhead">
          <rect
            id="Rectangle_632_copy_5"
            data-name="Rectangle 632 copy 5"
            x="1.531"
            y="10"
            width="16"
            height="2"
          />
          <path
            id="Rectangle_632_copy_6"
            data-name="Rectangle 632 copy 6"
            d="M62.465,496.121l5.657-5.657,1.414,1.415-5.657,5.657Z"
            transform="translate(-62.469 -485)"
          />
          <path
            id="Rectangle_632_copy_7"
            data-name="Rectangle 632 copy 7"
            d="M63.879,494.464l5.657,5.657-1.414,1.415-5.657-5.657Z"
            transform="translate(-62.469 -485)"
          />
        </g>
      </g>
    </svg>
  );
};

export default SignOutIcon;
