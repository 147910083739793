import axios from "axios";
import { uploadAcceptanceLetterApi } from "../../../constants";
import { errorGenericErrorHandleAction } from "../../actions/errorActions/errorAction";
import {
  uploadLoaderAction,
} from "../../actions/uploadDocumentsActions/uploadDocumentsAction";
import { successSnackBarHandleAction } from "../../actions/snackbarSuccessAction/successAction";
import { scenarioUploadDocumentAction } from "../../../constants/userActions";
import { Action } from "redux";
import { ThunkAction } from "redux-thunk";

export type UploadAcceptanceLetterThunkType = (
  scenarioId: number,
  token: string,
  currentUserId: number,
  currentScenarioUserId: number
) => ThunkAction<any, any, any, Action>;

export const uploadAcceptanceLetterThunk: UploadAcceptanceLetterThunkType = (
  scenarioId: number,
  token: string,
  currentUserId: number,
  currentScenarioUserId: number
): ThunkAction<any, any, any, Action> => {
  const apiUrl = uploadAcceptanceLetterApi;
  return async (dispatch) => {
    dispatch(uploadLoaderAction(true));
    try {
      const resp = await axios.post(
        apiUrl,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            timedata: Date.now(),
            scenario_id: scenarioId,
            user_action:
              currentScenarioUserId === currentUserId
                ? scenarioUploadDocumentAction[0]
                : scenarioUploadDocumentAction[1],
          },
        }
      );

      if (resp) {
        dispatch(uploadLoaderAction(false));
        dispatch(successSnackBarHandleAction("Acceptance letter has been uploaded successfully"));
      }
    } catch (error) {
      dispatch(uploadLoaderAction(false));
      if (error instanceof Error) {
        dispatch(errorGenericErrorHandleAction(error.message));
      }
    }
  };
};
