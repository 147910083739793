import { IPermissionsObject, ITeam, ITeamMembers, IUserNotification } from "amm-admin-interface";
import {
  IScenarioPayloadUser,
  IScenarioPayload,
  IScenarioPayloadUserScenarios,
} from "amm-scenario-interface";
import { BreadCrumbObject } from "../../reducers/loginReducers/loginReducer";

export const LOGIN_STATE_UPDATE = "LOGIN_STATE_UPDATE";
export const LOGIN_USER_LOGOUT = "LOGIN_USER_LOGOUT";
export const LOGIN_USER_LOGIN = "LOGIN_USER_LOGIN";
export const LOGIN_USER_PROFILE = "LOGIN_USER_PROFILE";
export const LOGIN_ACTIVE_USER_FOR_SCENARIO = "LOGIN_ACTIVE_USER_FOR_SCENARIO";
export const LOGIN_HISTORY_MODAL_OPEN = "LOGIN_HISTORY_MODAL_OPEN";
export const LOGIN_HISTORY_MODAL_CLOSE = "LOGIN_HISTORY_MODAL_CLOSE";
export const LOGIN_IS_NEW_USER = "LOGIN_IS_NEW_USER";
export const LOGIN_IS_AUTHENTICATION_CODE_SENT = "LOGIN_IS_AUTHENTICATION_CODE_SENT";
export const LOGIN_IS_VERIFICATION_CODE_SENT = "LOGIN_IS_VERIFICATION_CODE_SENT";
export const LOGIN_SET_USER_PERMISSIONS = "LOGIN_SET_USER_PERMISSIONS";
export const LOGIN_SET_CURRENT_USER_STATE = "LOGIN_SET_CURRENT_USER_STATE";
export const LOGIN_SET_SETTINGS_TAB_VALUE = "LOGIN_SET_SETTINGS_TAB_VALUE";
export const LOGIN_SET_CURRENT_USER_STATE_MOBILE = "LOGIN_SET_CURRENT_USER_STATE_MOBILE";
export const LOGIN_USER_PERMISSIONS = "LOGIN_USER_PERMISSIONS";
export const LOGIN_SET_TEAMS_ACTION = "LOGIN_SET_TEAMS_ACTION";
export const LOGIN_SET_TEAM_MEMBERS_ACTION = "LOGIN_SET_TEAM_MEMBERS_ACTION";
export const LOGIN_GET_SELECTED_USER_SCENARIO_ACTION = "LOGIN_GET_SELECTED_USER_SCENARIO_ACTION";
export const LOGIN_GET_TEAM_MEMBER_SCENARIO_ACTION = "LOGIN_GET_TEAM_MEMBER_SCENARIO_ACTION";
export const LOGIN_SET_CURRENT_TEAM_ACTION = "LOGIN_SET_CURRENT_TEAM_ACTION";
export const LOGIN_TEAM_LOADER_ACTION = "LOGIN_TEAM_LOADER_ACTION";
export const LOGIN_SET_USER_ID_FOR_SCENARIO_ACTION = "LOGIN_SET_USER_ID_FOR_SCENARIO_ACTION";
export const LOGIN_SET_TEAM_MEMBER_SCENARIO_ACTION = "LOGIN_SET_TEAM_MEMBER_SCENARIO_ACTION";
export const LOGIN_SET_BREAD_CRUMBS_VALUE = "LOGIN_SET_BREAD_CRUMBS_VALUE";
export const LOGIN_REMOVE_BREAD_CRUMBS_ELEMENT = "LOGIN_REMOVE_BREAD_CRUMBS_ELEMENT";
export const LOGIN_CLEAR_BREAD_CRUMBS_VALUE = "LOGIN_CLEAR_BREAD_CRUMBS_VALUE";
export const LOGIN_UPDATE_USER_NOTIFICATIONS = "LOGIN_UPDATE_USER_NOTIFICATIONS";
export const LOGIN_CAPITAL_STATUS_UPDATE = "LOGIN_CAPITAL_STATUS_UPDATE";

export interface LoginUserLogoutAction {
  type: "LOGIN_USER_LOGOUT";
  isLogin: boolean;
  module: string[];
}

export interface LoginSetTeamMemberScenarioAction {
  type: "LOGIN_SET_TEAM_MEMBER_SCENARIO_ACTION";
  setCurrentTeamMemberScenarios: boolean;
  module: string[];
}
export interface LoginUserLoginAction {
  isLogin: boolean;
  module: string[];
  type: "LOGIN_USER_LOGIN";
  token: string;
}

export interface LoginSetBreadCrumbsValueAction {
  module: string[];
  breadCrumbValue: BreadCrumbObject;
  type: "LOGIN_SET_BREAD_CRUMBS_VALUE";
}

export interface LoginRemoveBreadCrumbsElementAction {
  module: string[];
  keyIndex?: number;
  type: "LOGIN_REMOVE_BREAD_CRUMBS_ELEMENT";
}

export interface LoginClearBreadCrumbsValueAction {
  module: string[];
  type: "LOGIN_CLEAR_BREAD_CRUMBS_VALUE";
}

export interface LoginISNewUserAction {
  isNew: boolean;
  module: string[];
  type: "LOGIN_IS_NEW_USER";
  user: any;
}

export interface LoginIsVerificationCodeSentAction {
  isCodeSent: boolean;
  module: string[];
  type: "LOGIN_IS_VERIFICATION_CODE_SENT";
  user: any;
}

export interface LoginIsAuthenticationCodeSentAction {
  isCodeSent: boolean;
  module: string[];
  type: "LOGIN_IS_AUTHENTICATION_CODE_SENT";
  user: any;
}

export interface loginSetUserPermissionsAction {
  data: IPermissionsObject[];
  module: string[];
  type: "LOGIN_SET_USER_PERMISSIONS";
}

export interface LoginSetSettingTabValueAction {
  key: string;
  value: string | IUserNotification[];
  module: string[];
  type: "LOGIN_SET_SETTINGS_TAB_VALUE";
}

export interface LoginUserProfileAction {
  module: string[];
  profileData: IScenarioPayload;
  type: "LOGIN_USER_PROFILE";
}

export interface LoginUserPermissionsAction {
  module: string[];
  userPermissions: any;
  type: "LOGIN_USER_PERMISSIONS";
}

export interface LoginCapitalStatusScenarioUpdateAction {
  scenarioId: number;
  capitalStatus: string;
  module: string[];
  type: "LOGIN_CAPITAL_STATUS_UPDATE"
}
export interface LoginSetCurrentUserStateAction {
  data: IScenarioPayloadUser;
  module: string[];
  type: "LOGIN_SET_CURRENT_USER_STATE";
}

export interface LoginActiveUserForScenarioAction {
  module: string[];
  type: "LOGIN_ACTIVE_USER_FOR_SCENARIO";
  userId: number;
}

export interface LoginHistoryModalOpenAction {
  scenarioId: number;
  historyModalVisibility: boolean;
  module: string[];
  type: "LOGIN_HISTORY_MODAL_OPEN";
}

export interface LoginHistoryModalCloseAction {
  historyModalVisibility: boolean;
  module: string[];
  type: "LOGIN_HISTORY_MODAL_CLOSE";
}

export interface LoginSetTeamsAction {
  teams: ITeam;
  module: string[];
  type: "LOGIN_SET_TEAMS_ACTION";
}

export interface LoginSetTeamMembersAction {
  teamMembers: ITeamMembers[];
  teamID: number;
  module: string[];
  type: "LOGIN_SET_TEAM_MEMBERS_ACTION";
}

export interface LoginGetSelectedUserScenariosAction {
  userID: number;
  module: string[];
  type: "LOGIN_GET_SELECTED_USER_SCENARIO_ACTION";
}

export interface LoginGetTeamMemberScenarioAction {
  scenarios: IScenarioPayloadUserScenarios[];
  module: string[];
  type: "LOGIN_GET_TEAM_MEMBER_SCENARIO_ACTION";
}

export interface LoginSetCurrentTeamAction {
  teamID: number;
  module: string[];
  type: "LOGIN_SET_CURRENT_TEAM_ACTION";
}

export interface LoginTeamLoaderAction {
  loader: boolean;
  module: string[];
  type: "LOGIN_TEAM_LOADER_ACTION";
}

export type LoginActions =
  | LoginUserLogoutAction
  | LoginUserLoginAction
  | LoginISNewUserAction
  | LoginIsVerificationCodeSentAction
  | LoginIsAuthenticationCodeSentAction
  | loginSetUserPermissionsAction
  | LoginSetSettingTabValueAction
  | LoginUserProfileAction
  | LoginUserPermissionsAction
  | LoginCapitalStatusScenarioUpdateAction
  | LoginActiveUserForScenarioAction
  | LoginSetCurrentUserStateAction
  | LoginHistoryModalOpenAction
  | LoginHistoryModalCloseAction
  | LoginSetTeamsAction
  | LoginSetTeamMembersAction
  | LoginGetSelectedUserScenariosAction
  | LoginGetTeamMemberScenarioAction
  | LoginSetCurrentTeamAction
  | LoginTeamLoaderAction
  | LoginSetBreadCrumbsValueAction
  | LoginRemoveBreadCrumbsElementAction
  | LoginClearBreadCrumbsValueAction
  | LoginSetTeamMemberScenarioAction;

export type LoginTeamLoaderActionType = (loader: boolean) => LoginTeamLoaderAction;
export type LoginSetTeamMemberScenarioActionType = (
  LoginSetTeamMemberScenarioActionType: boolean
) => LoginSetTeamMemberScenarioAction;
export type LoginSetCurrentTeamActionType = (teamID: number) => LoginSetCurrentTeamAction;

export type LoginGetSelectedUserScenariosActionType = (
  userID: number
) => LoginGetSelectedUserScenariosAction;

export type LoginGetTeamMemberScenarioActionType = (
  scenarios: IScenarioPayloadUserScenarios[]
) => LoginGetTeamMemberScenarioAction;

export type LoginSetTeamsActionType = (teams: ITeam) => LoginSetTeamsAction;

export type LoginSetBreadCrumbsValueActionType = (
  breadCrumbValue: BreadCrumbObject
) => LoginSetBreadCrumbsValueAction;
export type LoginRemoveBreadCrumbsElementActionType = (
  keyIndex?: number
) => LoginRemoveBreadCrumbsElementAction;
export type LoginClearBreadCrumbsValueActionType = () => LoginClearBreadCrumbsValueAction;
export type LoginSetTeamMembersActionType = (
  teamMembers: ITeamMembers[],
  teamID: number
) => LoginSetTeamMembersAction;

export type LoginHistoryModalCloseActionType = (
  historyModalVisibility: boolean
) => LoginHistoryModalCloseAction;

export type LoginActiveUserForScenarioActionType = (
  userId: number
) => LoginActiveUserForScenarioAction;

export type LoginUserLogoutActionType = () => LoginUserLogoutAction;

export type LoginUserLoginActionType = (token: string, isLogin: boolean) => LoginUserLoginAction;

export type LoginISNewUserActionType = (isNew: boolean, user: any) => LoginISNewUserAction;

export type LoginIsVerificationCodeSentActionType = (
  isCodeSent: boolean,
  user: any
) => LoginIsVerificationCodeSentAction;

export type LoginIsAuthenticationCodeSentActionType = (
  isCodeSent: boolean,
  user: any
) => LoginIsAuthenticationCodeSentAction;

export type LoginSetUserPermissionsActionType = (
  data: IPermissionsObject[]
) => loginSetUserPermissionsAction;

export type LoginSetSettingTabValueActionType = (
  key: string,
  value: string | IUserNotification[]
) => LoginSetSettingTabValueAction;

export type LoginUserProfileActionType = (data: IScenarioPayload) => LoginUserProfileAction;

export type LoginUserPermissionsActionType = (userPermissions: any) => LoginUserPermissionsAction;
export type LoginCapitalStatusScenarioUpdateType = (scenarioId: number, capitalStatus: string) => LoginCapitalStatusScenarioUpdateAction;
export type LoginSetCurrentUserStateActionType = (data: any) => LoginSetCurrentUserStateAction;

export type LoginHistoryModalOpenActionType = (
  scenarioId: number,
  historyModalVisibility: boolean
) => LoginHistoryModalOpenAction;
