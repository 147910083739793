import { applicantValidateApplicantFieldsAction } from "../../actions/applicantActions/applicantActions";
import { errorCreditCardIndicatorAction } from "../../actions/errorActions/errorAction";
import {
  checkAffordabilityError,
  checkCreditCardErrorFromReview,
  primaryApplicantFieldsCheck,
} from "../../../utility/reviewUtility";
import {
  reviewFullFormValidationAction,
  reviewFullFormValidationForSendQuoteAction,
  reviewSetValidationAction,
  reviewSetApplicantFormSumittedFlag,
  reviewPageVisitedAction,
} from "../../actions/reviewActions/reviewActions";

import {
  IApplicantInitialState,
  IApplicantRequiredField,
  IApplicant,
} from "amm-applicant-interface";
import dayjs from "dayjs";
import { IPropertyArray } from "amm-objective-interface";
import { objectiveSetSuburbCheckedIndicator } from "../../actions/objectivePageActions/objectivePageActions";
import { checkIfAllValidationIsCorrect } from "../../../utility/validationPerPage";
import { Action } from "redux";
import { ThunkAction } from "redux-thunk";
import { IErrorStateInterface } from "../../reducers/errorReducers/errorReducer";
import { TAppStateType } from "../../reducers/reducers";
import { checkEmploymentFieldHandler } from "../utility";

export type FullFormValidationThunkType = (
  applicantState: IApplicantInitialState,
  applicants: IApplicant[],
  applicantsRequiredField: IApplicantRequiredField[],
  properties: IPropertyArray[],
  error: IErrorStateInterface,
  totalState: TAppStateType
) => ThunkAction<any, any, any, Action>;

export const fullFormValidationThunk: FullFormValidationThunkType = (
  applicantState,
  applicants,
  applicantsRequiredField,
  properties,
  error,
  totalState
): ThunkAction<any, any, any, Action> => {
  const formInvalidArray: boolean[] = [];
  const formInvalidArrayForSendQuote: boolean[] = [];
  const formApplicantArray: boolean[] = [];
  const applicantFormSubmitted: boolean[] = [];
  const formObjectiveArray: boolean[] = [];
  let isCheckRequired = false;

  for (let i = 0; i < applicants.length; i++) {
    //check affordability validations for primary applicant
    if (i === 0) {
      const primaryRequiredFields = applicantState.primaryAffordabilityRequiredFields;
      primaryRequiredFields.required.incomeSources =
        primaryRequiredFields.count.incomeSources !== 0;
      primaryRequiredFields.required.assets = primaryRequiredFields.count.assets !== 0;
      primaryRequiredFields.required.refinancedLoan =
        primaryRequiredFields.count.refinancedLoan !== 0;
      primaryRequiredFields.required.livingExpenses =
        primaryRequiredFields.count.livingExpenses !== 0;
    }
    let movedInDateDiff;
    if (applicants[i].movedInDate !== null) {
      movedInDateDiff = dayjs().diff(dayjs(applicants[i].movedInDate as string), "year", true);
    }

    if (
      (applicants[i].incomeSources.length > 0 ||
        applicants[i].financialObligation.length > 0 ||
        applicants[i].creditCards.length > 0 ||
        applicants[i].assets.length > 0 ||
        applicants[i].livingExpenses.length > 0) &&
      !isCheckRequired
    ) {
      isCheckRequired = true;
    }
    // check if credit check on applicant page is complete
    if (
      applicants[i].privacyConsent.formSubmit ||
      applicants[i].isEmailDuplicate ||
      applicants[i].isMobilePhoneDuplicate
    ) {
      applicantFormSubmitted.push(false);
      formApplicantArray.push(false);
      formInvalidArray.push(false);
      formInvalidArrayForSendQuote.push(false);
    } else {
      applicantFormSubmitted.push(true);
      formApplicantArray.push(true);
      formInvalidArray.push(true);
      formInvalidArrayForSendQuote.push(true);
    }

    // check if all the required fields in applicant page are filled.
    for (const key in applicantsRequiredField[i]) {
      if (
        key !== "applicantDocuments" &&
        key !== "errorMessage" &&
        key !== "previousAddress" &&
        key !== "previousAddressMovedInDate" &&
        key !== "employment" &&
        key !== "isProbationOn"
      ) {
        if (applicantsRequiredField[i][key]) {
          formApplicantArray.push(true);
          formInvalidArray.push(true);
          formInvalidArrayForSendQuote.push(true);
        } else if (
          !applicants[i][key] &&
          applicants[i][key] !== 0 &&
          key !== "middleName" &&
          key !== "earlyRepaymentStrategy" &&
          key !== "earlyRepaymentStrategyNote"
        ) {
          applicantsRequiredField[i][key] = true;
          formApplicantArray.push(true);
          formInvalidArray.push(true);
          formInvalidArrayForSendQuote.push(true);
        } else {
          formApplicantArray.push(false);
          formInvalidArray.push(false);
          formInvalidArrayForSendQuote.push(false);
        }
      } else if (key === "applicantDocuments") {
        applicantsRequiredField[i].applicantDocuments.forEach((list, index) => {
          for (const keyName in applicants[i].applicantDocuments[index]) {
            if (list[keyName]) {
              formApplicantArray.push(true);
              formInvalidArray.push(true);
              formInvalidArrayForSendQuote.push(true);
            } else if (
              !applicants[i].applicantDocuments[index][keyName] &&
              keyName != "licenseCardNumber"
            ) {
              applicantsRequiredField[i].applicantDocuments[index][keyName] = true;
              formApplicantArray.push(true);
              formInvalidArray.push(true);
              formInvalidArrayForSendQuote.push(true);
            } else {
              formApplicantArray.push(false);
              formInvalidArray.push(false);
              formInvalidArrayForSendQuote.push(false);
            }
          }
        });
      } else if (key === "previousAddress" || key === "previousAddressMovedInDate") {
        if ((movedInDateDiff as number) < 3) {
          if (applicants[i][key] === "") {
            applicantsRequiredField[i][key] = true;
            formApplicantArray.push(true);
            formInvalidArray.push(true);
            formInvalidArrayForSendQuote.push(true);
          } else {
            formApplicantArray.push(false);
            formInvalidArray.push(false);
            formInvalidArrayForSendQuote.push(false);
          }
        }
      } else if (applicants[i].homePhoneInvalid) {
        formApplicantArray.push(true);
        formInvalidArray.push(true);
        formInvalidArrayForSendQuote.push(true);
      } else if (key === "isProbationOn") {
        if (applicantsRequiredField[i][key]) {
          formApplicantArray.push(true);
        } else {
          formApplicantArray.push(false);
        }
      } else if (key === "employment") {
        if (applicants[i].isApplicantGuarantor === "No") {
          if (applicants[i].employment.length === 0) {
            applicantsRequiredField[i][key] = true;
            formApplicantArray.push(false);
          } else {
            applicantsRequiredField[i][key] = false;
            formApplicantArray.push(true);
            for (let index = 0; index < applicants[i].employment.length; index++) {
              if (checkEmploymentFieldHandler(applicants[i].employment[index])) {
                applicantsRequiredField[i][key] = true;
                formApplicantArray.push(false);
                break;
              }
            }
          }
        }
      }
    }
  }

  // check if the I'm still looking is checked
  for (const property of properties) {
    if (
      property.purchaseType === "I'm still looking" &&
      property.purchaseOrRefinance === "Purchase"
    ) {
      formObjectiveArray.push(true);
    } else {
      formObjectiveArray.push(false);
    }
  }

  // check if there's any error on affordability page
  if (isCheckRequired) {
    if (
      checkAffordabilityError(
        applicants,
        applicantState.primaryAffordabilityRequiredFields,
        totalState.review.isVisited
      ) ||
      error.affordabilityErrorIndicator
    ) {
      formInvalidArray.push(true);
      formInvalidArrayForSendQuote.push(true);
    } else {
      formInvalidArray.push(false);
      formInvalidArrayForSendQuote.push(false);
    }
  }
  if (error.loanErrorIndicator) {
    formInvalidArray.push(true);
    formInvalidArrayForSendQuote.push(true);
  } else {
    formInvalidArray.push(false);
    formInvalidArrayForSendQuote.push(false);
  }

  return (dispatch) => {
    dispatch(
      primaryApplicantFieldsCheck(
        totalState.objective.properties,
        totalState.applicant.applicants[0]
      )
    );
    dispatch(reviewPageVisitedAction(true));
    dispatch(objectiveSetSuburbCheckedIndicator(formObjectiveArray));
    dispatch(reviewFullFormValidationAction(formInvalidArray));
    dispatch(reviewFullFormValidationForSendQuoteAction(formInvalidArrayForSendQuote));

    if (isCheckRequired && checkCreditCardErrorFromReview(applicants).includes(true)) {
      dispatch(errorCreditCardIndicatorAction(true));
    }
    if (formApplicantArray.includes(true)) {
      dispatch(applicantValidateApplicantFieldsAction(applicantsRequiredField));
    }
    applicantFormSubmitted.includes(true)
      ? dispatch(reviewSetApplicantFormSumittedFlag(true))
      : dispatch(reviewSetApplicantFormSumittedFlag(false));
    dispatch(
      reviewSetValidationAction(
        checkIfAllValidationIsCorrect("affordability", totalState),
        "fundsToCompleteSum"
      )
    );
    dispatch(
      reviewSetValidationAction(
        checkIfAllValidationIsCorrect("affordability", totalState),
        "affordability"
      )
    );
    dispatch(
      reviewSetValidationAction(checkIfAllValidationIsCorrect("solution", totalState), "solution")
    );
    dispatch(
      reviewSetValidationAction(checkIfAllValidationIsCorrect("applicant", totalState), "applicant")
    );
  };
};
