import React from "react";

const ReportsIcon = (props: { className?: string }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 24"
    className={props.className ? props.className : "reports-icon"}
    height="24" width="26">
      <path d="M1,15H6V25H1Z" transform="translate(-1 -1)" style={{
        fillRule:"evenodd"}} />
      <path d="M10,8h5V25H10Z" transform="translate(-1 -1)" style={{
        fillRule:"evenodd"}} />
      <path d="M19,1h5V25H19Z" transform="translate(-1 -1)" style={{
        fillRule:"evenodd"}} />
    </svg>
  );
};

export default ReportsIcon;
