import {
  SUCCESS_GENERIC_SUCCESS_HANDLE,
  SUCCESS_SNACKBAR_CLOSE,
  SuccessSnackBarHandleActionType,
  SuccessSnackBarCloseActionType,
} from "./successActionType";

export const successSnackBarHandleAction: SuccessSnackBarHandleActionType = (
  message: string,
  autoHideTimeout?: number
) => {
  return {
    message,
    autoHideTimeout,
    type: SUCCESS_GENERIC_SUCCESS_HANDLE,
  };
};

export const successSnackBarCloseAction: SuccessSnackBarCloseActionType = () => {
  return {
    type: SUCCESS_SNACKBAR_CLOSE,
  };
};
