import { Reducer } from "redux";
import { IApplicantInitialState, IApplicant } from "amm-applicant-interface";

import * as type from "../../actions/applicantActions/applicantActionTypes";
import { LOGIN_USER_LOGOUT, LoginUserLogoutAction } from "../../actions/login/loginActionType";
import {
  applicantAddNew,
  applicantAddressAutoSuggest,
  applicantAddRetrieveDataItem,
  applicantChildAddNewItem,
  applicantChildRemoveItem,
  applicantRemove,
  applicantRemoveRetrieveDataItem,
  applicantStateUpdate,
  applicantUndoReducer,
  autoSuggestLoader,
  changeCreditCardError,
  clearAddressResult,
  clearApplicantPageDataReducer,
  clearDocumentIdReducer,
  copyToAllReducer,
  getCreditScoreAndVerifyIdentity,
  getProperty,
  noteValueUpdateReducer,
  setProvisoDataReducer,
  validateApplicantFields,
  applicantValidationUpdation,
  affordabilityRentalIncomeUpdate,
  copyAddressOfApplicant,
  updateReferralCode,
  updateTypeForUncategorised,
  autoPopulateNote,
  applicantUpdateLivingArragementFunction,
  applicantAddNote,
  applicantOverrideCreditScore,
  financialObligationRentUpdate,
  affordabilityRequiredFields,
  applicantRequiredFields,
  affordabilityTotalsUpdate,
  setProbationWarning,
} from "./applicantReducerUtility";

import {
  applicantPAYGIncomeTypeOptions,
  applicantSEIncomeTypeOptions,
  EmploymentTypes,
  moduleConstant,
  provisoOrManual,
  rentalIncome,
} from "../../../constants";
import { applicantInitialState } from "./applicantInitialState";
import {
  hideLoaderReducer,
  modalCloseReducer,
  modalOpenReducer,
  showLoaderReducer,
} from "./modalReducer";

const applicantReducer: Reducer<
  IApplicantInitialState,
  type.ApplicantActions | LoginUserLogoutAction
> = (
  state: IApplicantInitialState = applicantInitialState,
  action: type.ApplicantActions | LoginUserLogoutAction
) => {
  if (
    action &&
    action.module &&
    action.module.filter((currentModule) => Object.values(moduleConstant).includes(currentModule))
      .length === 0
  ) {
    return { ...state };
  }

  switch (action.type) {
    case type.APPLICANT_REQUIRED_FIELDS_CHECK:
      if (action.loanTerm) {
        return applicantRequiredFields(state, action.loanTerm);
      } else {
        state.primaryAffordabilityRequiredFields = affordabilityRequiredFields({
          applicant: state.applicants[0],
          primaryAffordabilityRequiredFields: state.primaryAffordabilityRequiredFields,
          checkAll: true,
        });
        return state;
      }

    case type.APPLICANT_REQUIRED_FIELDS_UPDATE:
      state.primaryAffordabilityRequiredFields.count[action.fieldName] = action.count;
      if (action.fieldName === "refinancedLoan") {
        state.primaryAffordabilityRequiredFields.required[action.fieldName] = action.count > 0;
      }
      return state;
    case type.APPLICANT_ADD_NOTE:
      return applicantAddNote(state, action);

    case type.APPLICANT_SET_PROVISO_DATA:
      return setProvisoDataReducer(state, action);

    case type.APPLICANT_CURRENT_TAB:
      return {
        ...state,
        currentTab: action.currentTab,
      };

    case type.APPLICANT_OVERRIDE_CREDIT_SCORE:
      return applicantOverrideCreditScore(state, action);

    case type.APPLICANT_OVERRIDE_LOADER:
      let currentState: IApplicantInitialState = Object.assign(state);
      let applicants: IApplicant[] = [...currentState.applicants];
      applicants[action.currentTab].privacyConsent.overrideLoader = action.overrideLoader;
      applicants[action.currentTab].privacyConsent.isCreditCheckOverriden = Boolean(
        action.isCreditCheckOverriden
      );

      return {
        ...state,
        applicants,
      };

    case type.APPLICANT_UPDATE_LIVING_ARRAGEMENT:
      return applicantUpdateLivingArragementFunction(state, action);

    case type.AFFORDABILITY_RENTAL_INCOME_UPDATE:
      return affordabilityRentalIncomeUpdate(state, action);

    case type.APPLICANT_CHANGE_RESEND_TIMER:
      currentState = Object.assign(state);
      applicants = [...currentState.applicants];
      applicants[currentState.currentTab]["smsResendTimer"] = action.smsResendTimer;
      return {
        ...state,
        applicants,
      };

    case type.APPLICANT_NOTE_VALUE_UPDATE:
      return noteValueUpdateReducer(action, state);

    case type.APPLICANT_STATE_UPDATE:
      return applicantStateUpdate(action, state);

    case type.APPLICANT_ADD_NEW:
      return applicantAddNew(action, state);

    case type.APPLICANT_REMOVE:
      return applicantRemove(action, state);

    case type.APPLICANT_ADD_NEW_ITEM:
      return applicantChildAddNewItem(action, state);

    case type.APPLICANT_SET_PROBATION_WARNING:
      return setProbationWarning(action, state);

    case type.APPLICANT_REMOVE_ITEM:
      return applicantChildRemoveItem(action, state);

    case type.APPLICANT_ADD_RETRIEVE_DATA:
      return applicantAddRetrieveDataItem(action, state);

    case type.APPLICANT_UNDO_ROW:
      return applicantUndoReducer(action, state);

    case type.APPLICANT_REMOVE_RETRIEVE_DATA:
      return applicantRemoveRetrieveDataItem(action, state);

    case type.APPLICANT_VALIDATION_UPDATION:
      return applicantValidationUpdation(action, state);

    case type.APPLICANT_CLEAR_DOCUMENT_ID:
      return clearDocumentIdReducer(action, state);

    case type.APPLICANT_REFERRAL_CODE_UPDATE:
      return updateReferralCode(action, state);

    case type.APPLICANT_UPDATE_VALUE_FOR_UNCATEGORISED:
      return updateTypeForUncategorised(action, state);

    case type.APPLICANT_CLEAR_APPLICANT_PAGE_DATA:
      return clearApplicantPageDataReducer(action, state);

    case type.APPLICANT_SHOW_LOADER:
      return showLoaderReducer(state, action);

    case type.APPLICANT_HIDE_LOADER:
      return hideLoaderReducer(state, action);

    case type.APPLICANT_MODAL_OPEN:
      return modalOpenReducer(state, action);

    case type.APPLICANT_MODAL_CLOSE:
      return modalCloseReducer(state, action);

    case type.APPLICANT_ADDRESS_AUTO_SUGGEST:
      return applicantAddressAutoSuggest(action, state);

    case type.APPLICANT_CLEAR_ADDRESS_RESULT:
      return clearAddressResult(action, state);

    case type.APPLICANT_COPY_ADDRESS:
      return copyAddressOfApplicant(action, state);

    case type.APPLICANT_ADDRESS_LOADER:
      return autoSuggestLoader(action, state);

    case type.APPLICANT_GET_PROPERTY:
      return getProperty(action, state);

    case type.APPLICANT_AFFORDABILITY_TOTAL_UPDATE:
      return affordabilityTotalsUpdate(action, state);
    case type.APPLICANT_LOAD_STATE:
      const applicantState = action.state;

      const actionApplicants = action.state.applicants;
      actionApplicants.forEach((applicant) => {
        if (!applicant.smsResendTimer) {
          applicant.smsResendTimer = 0;
        }
      });

      return { ...applicantState, applicants: actionApplicants };

    case type.APPLICANT_CREDIT_CARD_TEXT_ERROR:
      return changeCreditCardError(action, state);

    case type.APPLICANT_VALIDATE_APPLICANT_FIELD:
      return validateApplicantFields(action, state);

    case type.APPLICANT_GET_CREDIT_SCORE_AND_VERIFY_IDENTITY:
      return getCreditScoreAndVerifyIdentity(action, state);

    case type.APPLICANT_SEND_OTP_LOADER:
      currentState = Object.assign(state);
      applicants = [...currentState.applicants];
      let privacyConsent = {
        ...applicants[currentState.currentTab].privacyConsent,
      };
      privacyConsent.sendOtpLoader = action.sendOtpLoader;
      applicants[currentState.currentTab].privacyConsent = privacyConsent;
      return { ...currentState, applicants };

    case type.APPLICANT_AFFORDABILITY_CLEAR_TABLE:
      currentState = Object.assign(state);
      applicants = [...currentState.applicants];
      applicants[action.currentTab].clearTable = action.clearTable;
      return { ...currentState, applicants };

    case type.APPLICANT_SEND_SMS_LOADER:
      currentState = Object.assign(state);
      applicants = [...currentState.applicants];
      applicants[action.currentTab].smsLoaderShow = action.smsLoaderShow;
      applicants[action.currentTab].smsSent = action.smsSent;
      applicants[action.currentTab].smsResendTimer = action.smsResendTimer;
      return { ...currentState, applicants };

    case type.APPLICANT_IS_OTP_SENT:
      currentState = Object.assign(state);
      applicants = [...currentState.applicants];
      privacyConsent = {
        ...applicants[currentState.currentTab].privacyConsent,
      };
      privacyConsent.isOtpSent = action.isOtpSent;
      applicants[currentState.currentTab].privacyConsent = privacyConsent;
      return { ...currentState, applicants };

    case type.APPLICANT_CONFIRM_OTP_LOADER:
      currentState = Object.assign(state);
      applicants = [...currentState.applicants];
      privacyConsent = {
        ...applicants[currentState.currentTab].privacyConsent,
      };
      privacyConsent.confirmOtpLoader = action.confirmOtpLoader;
      applicants[currentState.currentTab].privacyConsent = privacyConsent;
      return { ...state, applicants };

    case type.APPLICANT_FORM_LOADER:
      currentState = Object.assign(state);
      applicants = [...currentState.applicants];
      privacyConsent = {
        ...applicants[currentState.currentTab].privacyConsent,
      };
      privacyConsent.formLoader = action.formLoader;
      applicants[currentState.currentTab].privacyConsent = privacyConsent;
      return { ...state, applicants };

    case type.APPLICANT_ADD_NOTE_FOR_RENTAL_INCOME:
      currentState = Object.assign(state);
      applicants = [...currentState.applicants];
      applicants[action.applicantIndex].incomeSources[action.rowIndex].noteRequired = true;
      applicants[action.applicantIndex].incomeSources[action.rowIndex].type = rentalIncome;
      if (
        applicants[action.applicantIndex].incomeSources[action.rowIndex].provisoOrManual ===
        provisoOrManual[1]
      ) {
        applicants[action.applicantIndex].incomeSources[action.rowIndex].note = "";
        applicants[action.applicantIndex].incomeSources[action.rowIndex].partialNoteValue = "";
      }
      return { ...state, applicants };

    case type.APPLICANT_CONFIRM_OTP_NUMBER:
      currentState = Object.assign(state);
      applicants = [...currentState.applicants];
      privacyConsent = {
        ...applicants[currentState.currentTab].privacyConsent,
      };
      privacyConsent.isNumberConfirmed = action.isNumberConfirmed;
      applicants[currentState.currentTab].privacyConsent = privacyConsent;
      return { ...state, applicants };

    case type.APPLICANT_CREDIT_CHECK_DONE_MANUALLY:
      currentState = Object.assign(state);
      applicants = [...currentState.applicants];
      privacyConsent = {
        ...applicants[currentState.currentTab].privacyConsent,
      };
      privacyConsent.isCreditCheckDoneManually = !privacyConsent.isCreditCheckDoneManually;
      applicants[currentState.currentTab].privacyConsent = privacyConsent;
      return {
        ...state,
        applicants,
      };
    case type.APPLICANT_COPY_TO_ALL:
      return copyToAllReducer(state, action);

    case type.APPLICANT_AUTO_POPULATE_NOTE:
      return autoPopulateNote(action, state);

    case type.APPLICANT_SET_EMAIL_DUPLICATE:
      currentState = Object.assign(state);
      applicants = [...currentState.applicants];
      applicants[action.index].isEmailDuplicate = action.isEmailDuplicate;
      return { ...currentState, applicants };

    case type.APPLICANT_SET_MOBILE_PHONE_DUPLICATE:
      currentState = Object.assign(state);
      applicants = [...currentState.applicants];
      applicants[action.index].isMobilePhoneDuplicate = action.isMobilePhoneDuplicate;
      return { ...currentState, applicants };

    case type.APPLICANT_SET_CURRENT_TAB_ZERO:
      currentState = Object.assign(state);
      return { ...currentState, currentTab: 0 };

    case type.APPLICANT_STATUS_RENT_UPDATE:
      return financialObligationRentUpdate(state, action);

    case type.APPLICANT_EMPLOYMENT_INCOME_SOURCE:
      currentState = Object.assign(state);
      const incomeSources = [...currentState.applicants[currentState.currentTab].incomeSources];
      incomeSources.forEach((incomeSource) => {
        let newIncomeSource;
        if (incomeSource.provisoOrManual === "Proviso") {
          if (
            action.employmentType === EmploymentTypes.PAYGEmployee ||
            action.employmentType === EmploymentTypes.Other ||
            action.employmentType === EmploymentTypes.PAYGContractor
          ) {
            if (incomeSource.type === applicantSEIncomeTypeOptions[0]) {
              newIncomeSource = applicantPAYGIncomeTypeOptions[0];
            }
          } else if (incomeSource.type === applicantPAYGIncomeTypeOptions[0]) {
            newIncomeSource = applicantSEIncomeTypeOptions[0];
          }
        } else {
          newIncomeSource =
            action.employmentType === EmploymentTypes.PAYGEmployee ||
            action.employmentType === EmploymentTypes.Other ||
            action.employmentType === EmploymentTypes.PAYGContractor
              ? applicantPAYGIncomeTypeOptions.includes(incomeSource.type)
                ? incomeSource.type
                : applicantPAYGIncomeTypeOptions[0]
              : applicantSEIncomeTypeOptions.includes(incomeSource.type)
              ? incomeSource.type
              : applicantSEIncomeTypeOptions[0];
        }
        if (newIncomeSource !== undefined) incomeSource.type = newIncomeSource;
      });

      return { ...currentState };

    case type.APPLICANT_SET_PROVISO_DATA_IS_NEW:
      currentState = Object.assign(state);
      applicants = [...currentState.applicants];
      if (applicants[currentState.currentTab].retrieveBankData[action.index]) {
        applicants[currentState.currentTab].retrieveBankData[action.index].isNew = action.isNew;
      } else {
        applicants[currentState.currentTab].retrieveBankData[0].isNew = action.isNew;
      }

      return { ...currentState, applicants };

    case LOGIN_USER_LOGOUT:
      return { ...applicantInitialState };

    default:
      return {
        ...state,
      };
  }
};

export default applicantReducer;
