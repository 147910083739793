import React from "react";
import { connect } from "react-redux";
import { ResponsiveContainer } from "recharts";
import { ILRAS } from "amm-objective-interface";
import { TAppStateType } from "../../store/reducers/reducers";
// import { COLORS } from "../../constants";
// import { ApexChart } from "./ApexChart";
import HSBar from "react-horizontal-stacked-bar-chart";
import BarChartLabelIcon from "../common/icons/barChartLabelIcon";

interface StateProps {
  lras: ILRAS;
}

type ChartProps = StateProps;

const Chart: React.FC<ChartProps> = (props: ChartProps) => {
  const [shouldRender, setRender] = React.useState(false);

  React.useEffect(() => {
    if ({ ...props.lras }.nsr >= 1 && props.lras.lrasOutputs.mortgageScore >= 700) {
      setRender(true);
    }
  }, [props.lras.nsr, props.lras.lrasOutputs.mortgageScore]);

  const onAnimationEnd = () => {
    if (props.lras.nsr <= 0 || props.lras.lrasOutputs.mortgageScore < 700) {
      setRender(false);
    }
  };

  const data = [
    {
      name: "Loan repayments",
      value: props.lras.yearlyPayments
        ? props.lras.disposableIncome > 0
          ? parseFloat(props.lras.yearlyPayments.toFixed(2))
          : 0
        : 0,
      color: "rgb(255, 255, 149)",
    },
    {
      name: "Disposable income",
      value: props.lras.disposableIncome
        ? props.lras.disposableIncome > 0
          ? parseFloat(props.lras.disposableIncome.toFixed(2))
          : -1 * parseFloat(props.lras.disposableIncome.toFixed(2))
        : 0,
      color: "rgb(71, 198, 143)",
    },
    {
      name: "Living expenses",
      value: props.lras.totals
        ? props.lras.totals.applicantsCombinedTotals.applicantsCombinedTotalsExpense
          ? props.lras.totals.applicantsCombinedTotals.applicantsCombinedTotalsExpense > 0
            ? parseFloat(
                props.lras.totals.applicantsCombinedTotals.applicantsCombinedTotalsExpense.toFixed(
                  2
                )
              )
            : 0
          : 0
        : 0,
      color: "rgb(153, 153, 153)",
    },
    {
      name: "Financial obligations",
      value: props.lras.totals
        ? props.lras.totals.applicantsCombinedTotals.applicantsCombinedTotalsObligation
          ? props.lras.totals.applicantsCombinedTotals.applicantsCombinedTotalsObligation > 0
            ? parseFloat(
                props.lras.totals.applicantsCombinedTotals.applicantsCombinedTotalsObligation.toFixed(
                  2
                )
              )
            : 0
          : 0
        : 0,
      color: "rgb(119, 119, 119)",
    },
    {
      name: "Credit cards",
      value: props.lras.totals
        ? props.lras.totals.applicantsCombinedTotals.applicantsCombinedTotalsCreditCard
          ? props.lras.totals.applicantsCombinedTotals.applicantsCombinedTotalsCreditCard > 0
            ? parseFloat(
                props.lras.totals.applicantsCombinedTotals.applicantsCombinedTotalsCreditCard.toFixed(
                  2
                )
              )
            : 0
          : 0
        : 0,
      color: "rgb(85, 85, 85)",
    },
  ];
  return shouldRender ? (
    <div
      className="pie-chart-container"
      style={{
        animation: `${
          props.lras.nsr >= 1 && props.lras.lrasOutputs.mortgageScore >= 700 ? "fadeIn" : "fadeOut"
        } 1s ease 0s forwards`,
      }}
      onAnimationEnd={onAnimationEnd}
    >
      <ResponsiveContainer>
        <div className="income-expense-bar-chart">
          <HSBar data={data} />
          <div className="barchart-label-wrapper">
            {data.map((item) => {
              return (
                <span className="barchart-label-item">
                  <BarChartLabelIcon fill={item.color} />
                  {item.name}
                </span>
              );
            })}
          </div>
        </div>
      </ResponsiveContainer>
    </div>
  ) : (
    <React.Fragment />
  );
};

export const mapStateToProps = (state: TAppStateType) => {
  return {
    lras: state.help.lras,
  };
};

export default connect(mapStateToProps, {})(Chart);
