import { IApplicantInitialState } from "amm-applicant-interface";
import {
  ApplicantModalCloseAction,
  ApplicantModalOpenAction,
  ApplicantShowLoaderAction,
  ApplicantHideLoaderAction,
} from "../../actions/applicantActions/applicantActionTypes";

export const modalOpenReducer = (
  state: IApplicantInitialState,
  action: ApplicantModalOpenAction
) => {
  const applicant = [...Object.assign(state).applicants];
  applicant[state.currentTab].noteModalVisibility = true;
  const noteInformation = action.noteInformation;
  return { ...state, applicants: applicant, noteInformation };
};

export const modalCloseReducer = (
  state: IApplicantInitialState,
  action: ApplicantModalCloseAction
) => {
  const currentState: IApplicantInitialState = Object.assign(state);
  let noteRequired: any = []; // Todo fix the type
  const applicant = [...currentState.applicants];
  if (action.buttonClicked === "cancel") {
    applicant[currentState.currentTab].noteModalVisibility = false;
    if (applicant[currentState.currentTab][action.rowType][action.currentTab].deletedNoteRequired) {
      if (
        applicant[currentState.currentTab][action.rowType][action.currentTab].deletedNote === "" &&
        applicant[currentState.currentTab][action.rowType][action.currentTab]
          .deletedPartialNoteValue !== ""
      ) {
        applicant[currentState.currentTab][action.rowType][
          action.currentTab
        ].deletedPartialNoteValue = "";
      } else if (
        applicant[currentState.currentTab][action.rowType][action.currentTab].deletedNote !== "" &&
        applicant[currentState.currentTab][action.rowType][action.currentTab]
          .deletedPartialNoteValue !==
          applicant[currentState.currentTab][action.rowType][action.currentTab].deletedNote
      ) {
        applicant[currentState.currentTab][action.rowType][
          action.currentTab
        ].deletedPartialNoteValue =
          applicant[currentState.currentTab][action.rowType][action.currentTab].deletedNote;
      }
    } else {
      if (
        applicant[currentState.currentTab][action.rowType][action.currentTab].note === "" &&
        applicant[currentState.currentTab][action.rowType][action.currentTab].partialNoteValue !==
          ""
      ) {
        applicant[currentState.currentTab][action.rowType][action.currentTab].partialNoteValue = "";
      } else if (
        applicant[currentState.currentTab][action.rowType][action.currentTab].note !== "" &&
        applicant[currentState.currentTab][action.rowType][action.currentTab].partialNoteValue !==
          applicant[currentState.currentTab][action.rowType][action.currentTab].note
      ) {
        applicant[currentState.currentTab][action.rowType][action.currentTab].partialNoteValue =
          applicant[currentState.currentTab][action.rowType][action.currentTab].note;
      }
    }

    return { ...state, applicants: applicant };
  } else if (action.buttonClicked === "accept") {
    if (applicant[currentState.currentTab][action.rowType][action.currentTab].deletedNoteRequired) {
      if (
        applicant[currentState.currentTab][action.rowType][action.currentTab]
          .deletedPartialNoteValue !== ""
      ) {
        applicant[currentState.currentTab][action.rowType][action.currentTab].deletedNote =
          applicant[currentState.currentTab][action.rowType][
            action.currentTab
          ].deletedPartialNoteValue;
        applicant[currentState.currentTab][action.rowType][
          action.currentTab
        ].deletedNoteRequired = false;

        const noteRequiredType = action.rowType + "DeletedNoteRequired";
        noteRequired = [...applicant[currentState.currentTab][noteRequiredType]];
        noteRequired[action.currentTab] = false;
        applicant[currentState.currentTab][noteRequiredType] = noteRequired;
      }
    } else if (
      applicant[currentState.currentTab][action.rowType][action.currentTab].noteRequired &&
      applicant[currentState.currentTab][action.rowType][action.currentTab].partialNoteValue !== ""
    ) {
      const noteRequiredType = action.rowType + "NoteRequired";
      if (action.rowType !== "totalExpensesNotes") {
        noteRequired = [...applicant[currentState.currentTab][noteRequiredType]];
      }

      // if any changes are made to the uncategorised row other than the type change.
      if (
        action.rowType === "incomeSources" &&
        applicant[currentState.currentTab][action.rowType][action.currentTab].type ===
          "Uncategorised"
      ) {
        applicant[currentState.currentTab][action.rowType][action.currentTab].noteRequired = true;
        noteRequired[action.currentTab] = true;
      } else {
        applicant[currentState.currentTab][action.rowType][action.currentTab].noteRequired = false;
        noteRequired[action.currentTab] = false;
      }
      applicant[currentState.currentTab][action.rowType][action.currentTab].note =
        applicant[currentState.currentTab][action.rowType][action.currentTab].partialNoteValue;

      applicant[currentState.currentTab][noteRequiredType] = noteRequired;
    } else if (
      !applicant[currentState.currentTab][action.rowType][action.currentTab].noteRequired &&
      applicant[currentState.currentTab][action.rowType][action.currentTab].partialNoteValue !== ""
    ) {
      applicant[currentState.currentTab][action.rowType][action.currentTab].note =
        applicant[currentState.currentTab][action.rowType][action.currentTab].partialNoteValue;
    }
    applicant[state.currentTab].noteModalVisibility = false;
  }

  return { ...state, applicants: applicant };
};

export const showLoaderReducer = (
  state: IApplicantInitialState,
  action: ApplicantShowLoaderAction
) => {
  const applicant = [...Object.assign(state).applicants];
  if (applicant[action.index].retrieveBankData[action.childIndex]) {
    applicant[action.index].retrieveBankData[action.childIndex].showLoader = true;
  } else {
    applicant[action.index].retrieveBankData[0].showLoader = true;
  }
  return { ...state, applicants: applicant };
};

export const hideLoaderReducer = (
  state: IApplicantInitialState,
  action: ApplicantHideLoaderAction
) => {
  const applicant = [...Object.assign(state).applicants];
  if (applicant[action.index].retrieveBankData[action.childIndex]) {
    applicant[action.index].retrieveBankData[action.childIndex].showLoader = false;
  } else {
    applicant[action.index].retrieveBankData[0].showLoader = false;
  }
  return { ...state, applicants: applicant };
};
