import React from "react";

const HouseIcon = (props: { className?: string }) => {
  return (
    <svg
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 26 24"
      className={props.className ? props.className : "house-icon"}
    >
      <rect x="15" y="12.7" style={{ fill: "inherits" }} width="8" height="11.3" />
      <rect
        id="Rectangle_709_copy"
        x="3"
        y="12.7"
        style={{ fill: "inherits" }}
        width="8"
        height="11.3"
      />
      <path id="Rectangle_709_copy_2" style={{ fill: "inherits" }} d="M13,0l13,13H0L13,0z" />
    </svg>
  );
};

export default HouseIcon;
