import React from "react";
interface IProps {
  className?: string;
  width: number;
  height: number;
}

export const TickIcon = ({ className, width, height }: IProps) => {
  return (
    <svg className={className} width={width} height={height} viewBox="0 0 36 36">
      <g id="ticks" className="cls-1">
        <circle className="cls-2" cx="18" cy="18" r="17" />
        <g id="tick_copy" data-name="tick copy">
          <path
            className="cls-3"
            d="M1235.17,912.757l7.07,7.071-1.41,1.415-7.07-7.071Z"
            transform="translate(-1227 -895)"
          />
          <path
            id="Rectangle_656_copy"
            data-name="Rectangle 656 copy"
            className="cls-3"
            d="M1240.22,920.364l14.14-14.142,1.42,1.414-14.14,14.142Z"
            transform="translate(-1227 -895)"
          />
        </g>
      </g>
    </svg>
  );
};
