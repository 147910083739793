import React from "react";

const SolutionIcon: React.FC = () => {
  return (
    <svg
      className="icon footer-icon footer-solution-icon"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 35 35"
    >
      <title>icon-solution</title>
      <path
        className="cls-1"
        d="M17.5,2A15.5,15.5,0,1,1,2,17.5,15.5176,15.5176,0,0,1,17.5,2m0-2A17.5,17.5,0,1,0,35,17.5,17.5,17.5,0,0,0,17.5,0Z"
      />
      <rect className="cls-2" x="9" y="7" width="4" height="18" />
      <polygon className="cls-2" points="19 13 15 12.958 15 25 19 25 19 13" />
      <polygon className="cls-2" points="25 19 21 18.958 21 25 25 25 25 19" />
    </svg>
  );
};

export default SolutionIcon;
