import { Reducer } from "redux";
import { IBrandingInitialState } from "amm-branding-interface";
import { moduleConstant } from "../../../constants";
import {
  BRANDING_CSS_UPDATE,
  BrandingActions,
  BRANDING_ADD_BRAND_NAME,
} from "../../actions/brandingActions/brandingActionTypes";

export const brandingInitialState: IBrandingInitialState = {
  css: "",
  brandName: process.env.REACT_APP_DOMAIN ? process.env.REACT_APP_DOMAIN : "",
};

const brandingPageReducer: Reducer<
  IBrandingInitialState,
  BrandingActions 
> = (
  state: IBrandingInitialState = brandingInitialState,
  action: BrandingActions 
) => {
  if (
    action &&
    action.module &&
    action.module.filter((currentModule) => Object.values(moduleConstant).includes(currentModule))
      .length === 0
  ) {
    return { ...state };
  }
  switch (action.type) {
    case BRANDING_CSS_UPDATE:
      return {
        ...state,
        css: action.css,
        brandName: action.brandName,
      };

    case BRANDING_ADD_BRAND_NAME:
      return {
        ...state,
        brandName:action.brandName
      }

    default:
      return {
        ...state,
      };
  }
};

export default brandingPageReducer;
