import React from "react";

const AffordabilityIcon: React.FC = () => {
  return (
    <svg
      className="footer-affordability-icon icon footer-icon"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 35 35"
    >
      <title>icon-affordability</title>
      <path
        className="cls-1"
        d="M17.5,2A15.5,15.5,0,1,1,2,17.5,15.5176,15.5176,0,0,1,17.5,2m0-2A17.5,17.5,0,1,0,35,17.5,17.5,17.5,0,0,0,17.5,0Z"
      />
      <path
        className="cls-1"
        d="M21.4886,21.5679a2.1139,2.1139,0,0,0-.8691-1.7094,8.4459,8.4459,0,0,0-2.9317-1.2577,10.1242,10.1242,0,0,1-4.1845-1.87A3.7259,3.7259,0,0,1,12.19,13.8019a3.8114,3.8114,0,0,1,1.2871-2.9675,6.0117,6.0117,0,0,1,3.5371-1.379V7.0394h2.0078v2.427a5.5888,5.5888,0,0,1,3.5244,1.5555,4.9076,4.9076,0,0,1,1.26,3.5633h-2.48a3.3851,3.3851,0,0,0-.9023-2.4793,3.2674,3.2674,0,0,0-2.4395-.9185,3.8516,3.8516,0,0,0-2.4531.68,2.2934,2.2934,0,0,0-.8487,1.8956,2.1261,2.1261,0,0,0,.8965,1.7958,9.0347,9.0347,0,0,0,2.9512,1.2159,13.8313,13.8313,0,0,1,3.207,1.203,4.6694,4.6694,0,0,1,1.6983,1.5281,3.7637,3.7637,0,0,1,.5459,2.0414,3.7094,3.7094,0,0,1-1.3682,3.0007,6.3225,6.3225,0,0,1-3.6969,1.3692L18.9525,28H16.9583l-.0358-2.0833a6.8613,6.8613,0,0,1-4.0657-1.4853,4.409,4.409,0,0,1-1.4219-3.4582h2.4932a2.9118,2.9118,0,0,0,.9834,2.36,4.2114,4.2114,0,0,0,2.79.8279,4.8324,4.8324,0,0,0,2.7764-.6955A2.1838,2.1838,0,0,0,21.4886,21.5679Z"
      />
    </svg>
  );
};

export default AffordabilityIcon;
