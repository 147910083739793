export const TRIGGER_PROFILE_LOAD = "TRIGGER_PROFILE_LOAD";
export const TRIGGER_OPEN_SCENARIO = "TRIGGER_OPEN_SCENARIO";
export const TRIGGER_OPEN_MY_SCENARIOS_DASHBOARD = "TRIGGER_OPEN_MY_SCENARIOS_DASHBOARD";
export const TRIGGER_OPEN_USER_SCENARIOS_DASHBOARD = "TRIGGER_OPEN_USER_SCENARIOS_DASHBOARD";

interface ICommon {
  module: string[];
}
export interface TriggerProfileLoadAction extends ICommon {
  type: "TRIGGER_PROFILE_LOAD";
}

export interface TriggerOpenScenarioAction extends ICommon {
  type: "TRIGGER_OPEN_SCENARIO";
}

export interface TriggerOpenMyScenariosDashboard extends ICommon {
  type: "TRIGGER_OPEN_MY_SCENARIOS_DASHBOARD";
}

export interface TriggerOpenUserScenariosDashboard extends ICommon {
  userId: number;
  type: "TRIGGER_OPEN_USER_SCENARIOS_DASHBOARD";
}

export type TriggerActions =
  | TriggerOpenUserScenariosDashboard
  | TriggerOpenMyScenariosDashboard
  | TriggerOpenScenarioAction
  | TriggerProfileLoadAction;

export type TriggerOpenUserScenariosDashboardType = (
  userId: number
) => TriggerOpenUserScenariosDashboard;

export type TriggerOpenMyScenariosDashboardType = () => TriggerOpenMyScenariosDashboard;

export type TriggerOpenScenarioActionType = () => TriggerOpenScenarioAction;

export type TriggerProfileLoadActionType = () => TriggerProfileLoadAction;
