import { combineReducers } from "redux";
import adminReducer from "./adminReducers";
import applicantReducer from "./applicantReducers";
import brandingPageReducer from "./branding";
import errorReducer from "./errorReducers/errorReducer";
import helpReducer from "./helpReducers/helpReducer";
import loginReducer from "./loginReducers/loginReducer";
import objectiveReducer from "./objectivePageReducers/objectiveReducer";
import reviewReducer from "./reviewReducers/reviewReducer";
import scenarioReducer from "./scenarioReducers/scenarioReducer";
import solutionReducer from "./solutionReducers";
import successSnackbarReducer from "./successSnackbarReducer";
import triggerReducers from "./triggerReducers";
import uploadDocumentsReducers from "./uploadDocumentsReducers/uploadDocumentReducer";

const mergeReducers = combineReducers({
  admin: adminReducer,
  applicant: applicantReducer,
  branding: brandingPageReducer,
  error: errorReducer,
  help: helpReducer,
  loan: solutionReducer,
  login: loginReducer,
  objective: objectiveReducer,
  review: reviewReducer,
  scenario: scenarioReducer,
  successSnackbar: successSnackbarReducer,
  trigger: triggerReducers,
  upload: uploadDocumentsReducers,
});

export type TAppStateType = ReturnType<typeof mergeReducers>;

export default mergeReducers;
