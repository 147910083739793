import React from "react";

const ReviewCrossIcon = (props: { className: string }) => {
  return (
    <svg className={props.className} viewBox="0 0 16 16">
      <circle className="reviewCrossIcon-cls1" cx="8" cy="8" r="8" />
      <rect
        className="reviewCrossIcon-cls2"
        x="7.0049"
        y="1.9978"
        width="1.9901"
        height="12.0045"
        transform="translate(-3.3137 8) rotate(-45)"
      />
      <rect
        className="reviewCrossIcon-cls2"
        x="7.0049"
        y="1.9978"
        width="1.9901"
        height="12.0045"
        transform="translate(8 19.3137) rotate(-135)"
      />
    </svg>
  );
};

export default ReviewCrossIcon;
